import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { CreateDiscussionDto, DiscussionClient, DiscussionDto, FileParameter } from '../../../services/generated/BackOffice-api';
import { DispatchDossierAction, DossierActionsEnum } from './dossierAction';

const discussionClient = new DiscussionClient('', axiosInstance);

export const fetchDossierMessages = async (dispatch: any, id: string, userId?: string) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_MESSAGES_REQUEST });
	try {
		const response = await discussionClient.getDiscussionsDossier(id);
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_MESSAGES_SUCCESS,
			payload: {
				dossierDiscussions: response,
				dossierDiscussionsLu: response.filter(x =>
					x.messages?.filter(m => m.message?.auteur?.id !== userId).every(m => m.estLu)
				).map(x => x.id)
			}
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_MESSAGES_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les discussions");
	}
}

export const createDossierDiscussion = async (dispatch: any, dossierId: string, contenu: CreateDiscussionDto) => {
	dispatch({ type: DossierActionsEnum.CREATE_DOSSIER_DISCUSSION_REQUEST });
	try {
		let response = await discussionClient.createDiscussionDossier(dossierId, contenu);
		dispatch({
			type: DossierActionsEnum.CREATE_DOSSIER_DISCUSSION_SUCCESS,
			payload: {dossierDiscussion:  response }
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.CREATE_DOSSIER_DISCUSSION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de créer une discusison");
	}
}

export const sendDossierMessages = async (dispatch: any, discussion: DiscussionDto, contenu: string, doc:FileParameter[]) => {
	dispatch({ type: DossierActionsEnum.POST_DOSSIER_MESSAGES_REQUEST });
	try {
		let newMessages = discussion.messages || [];
		let response = await discussionClient.createMessageDiscussion((discussion.id || ''), contenu, doc);
		newMessages.unshift(response);
		dispatch({
			type: DossierActionsEnum.POST_DOSSIER_MESSAGES_SUCCESS,
			payload: {
				dossierMessage:  response,
				dossierDiscussion: {...discussion, messages: newMessages }
			}
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.POST_DOSSIER_MESSAGES_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible d'envoyer un message");
	}
}

export const sendDossierMultipleMessages = async (dispatch: any, contenu: string, doc:FileParameter[], filters?: any) => {
	const filtersString = Object.entries(filters)
		.filter(([key, value]) => value !== null && value !== undefined && value !== '')
		.map(x => x.join('')).join();
	
	dispatch({ type: DossierActionsEnum.POST_DOSSIER_MULTIPLE_MESSAGES_REQUEST });
	try {
		await discussionClient.createMultipleDossierDiscussion(filtersString, undefined, undefined, undefined, true, contenu, doc);
		dispatch({ type: DossierActionsEnum.POST_DOSSIER_MULTIPLE_MESSAGES_SUCCESS });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.POST_DOSSIER_MULTIPLE_MESSAGES_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible d'envoyer plusieurs messages");
	}
}

export const setDiscussionLu = async (dispatch: any, discussionId: string) => {
	try {
		await discussionClient.setDiscussionLu(discussionId);
		dispatch({
			type: DossierActionsEnum.UPDATE_DOSSIER_DISCUSSION_LU_SUCCESS,
			payload: { dossierDiscussionId: discussionId }
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à lu la discussion");
		throw error;
	}
}

export const getObjetsDiscussionsDossier = async (dispatch:Dispatch<DispatchDossierAction>) => {
	try {
		const result = await discussionClient.getObjetsDiscussionsDossier();
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_MESSAGES_OBJECT,
			payload: { objectDiscussionDossier: result }
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les objets de discussion");
		throw error;
	}
}
