import { Reducer } from "redux";
import { BordereauActionsEnum, DispatchBordereauAction } from "../actions/bordereauActions";
import { BordereauState, initialBordereauState } from "../states/bordereauStates";


export const bordereauReducer: Reducer<Partial<BordereauState>, DispatchBordereauAction> = (state = initialBordereauState, action) => {
    switch(action.type) {
        case BordereauActionsEnum.GET_BORDEREAUX_SUCCESS:
            return {
                ...state,
                bordereaux: action.payload.bordereaux
            };
        case BordereauActionsEnum.GET_BORDEREAU_VERSEMENT_SUCCESS:
            return {
                ...state,
                bordereauVersement: action.payload.bordereauVersement
            };
        case BordereauActionsEnum.DELETE_BORDEREAU_VERSEMENT_MOUVEMENT_SUCCESS:
            return {
                ...state,
                bordereauVersement: {
                    ...state.bordereauVersement,
                    mouvements: state.bordereauVersement?.mouvements?.filter(x => x.id !== action.payload.mouvementId)
                }
            };
        case BordereauActionsEnum.GET_BORDEREAU_REAFFECTATION_SUCCESS:
            return {
                ...state,
                bordereauReaffectation: action.payload.bordereauReaffectation
            };
		
		case BordereauActionsEnum.GET_BORDEREAU_REAFFECTATION_AMENDABLE_SUCCESS:
			return {
				...state,
				bordereauReaffectationAmendable: action.payload.bordereauReaffectationAmendable
			};
        case BordereauActionsEnum.DELETE_BORDEREAU_REAFFECTATION_MOUVEMENT_SUCCESS:
            return {
                ...state,
                bordereauReaffectation: {
                    ...state.bordereauReaffectation,
                    mouvements: state.bordereauReaffectation?.mouvements?.filter(x => x.id !== action.payload.mouvementId)
                }
            };
		case BordereauActionsEnum.GET_DEMANDES_REMBOURSEMENT:
			return {
				...state,
				demandesRemboursement: action.payload.demandesRemboursement
			};
        default:
            return state;
    }
};