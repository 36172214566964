import { Action, Dispatch } from 'redux';
import { PageVitrineState } from '../states/pageVitrineState';
import { PageVitrineClient, PageVitrineDto, CreatePageVitrineDto, FileParameter } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum PageVitrineActionsEnum {
	GET_PAGES_REQUEST = "GET_PAGES_REQUEST",
	GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS",
	PUT_PAGE_REQUEST = "PUT_PAGE_REQUEST",
	PUT_PAGE_SUCCESS = "PUT_PAGE_SUCCESS",
	SEND_PAGE_REQUEST = "SEND_PAGE_REQUEST",
	SEND_PAGE_SUCCESS = "SEND_PAGE_SUCCESS",
	SEND_PAGE_ERROR = "SEND_PAGE_ERROR",
	POST_REORDER_REQUEST = "POST_REORDER_REQUEST",
	POST_REORDER_SUCCESS = "POST_REORDER_SUCCESS",
	POST_REORDER_ERROR = "POST_REORDER_ERROR",
	DELETE_PAGE_REQUEST = "DELETE_PAGE_REQUEST",
	DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS",
	POST_ADDFILE_REQUEST = "POST_ADDFILE_REQUEST",
	POST_ADDFILE_SUCCESS = "POST_ADDFILE_SUCCESS",
	POST_ADDFILE_ERROR = "POST_ADDFILE_ERROR",
}

export interface DispatchPageVitrineAction extends Action<PageVitrineActionsEnum> {
	payload: PageVitrineState;
}

// get all page
export const requestPages = () => {
	return {
		type: PageVitrineActionsEnum.GET_PAGES_REQUEST
	}
}

export const getPages = (result: PageVitrineDto[]) => {
	return {
		type: PageVitrineActionsEnum.GET_PAGES_SUCCESS,
		payload: { pages: result }
	}
}

// Send page
export const sendPage = () => {
	return {
		type: PageVitrineActionsEnum.SEND_PAGE_REQUEST
	}
}
export const savedPage = (result: PageVitrineDto) => {
	return {
		type: PageVitrineActionsEnum.SEND_PAGE_SUCCESS,
		payload: { page: result }
	}
}
export const errorSavedPage = () => {
	return {
		type: PageVitrineActionsEnum.SEND_PAGE_ERROR
	}
}
//put one page
export const requestPutPage = () => {
	return {
		type: PageVitrineActionsEnum.PUT_PAGE_REQUEST
	}
}

export const putPage = (result: any) => {
	toast.success(i18n.t('toast.page-vitrine-edited'));
	return {
		type: PageVitrineActionsEnum.PUT_PAGE_SUCCESS,
		payload: { page: result }
	}
}

//delete one page
export const requestDeletePage = () => {
	return {
		type: PageVitrineActionsEnum.DELETE_PAGE_REQUEST
	}
}

export const deletePage = (result: any) => {
	toast.success(i18n.t('toast.page-vitrine-deleted'));
	return {
		type: PageVitrineActionsEnum.DELETE_PAGE_SUCCESS,
	}
}

//reorder pages
export const sendReorderPage = () => {
	return {
		type: PageVitrineActionsEnum.POST_REORDER_REQUEST
	}
}
export const savedReorderPage = (result: any) => {
	return {
		type: PageVitrineActionsEnum.POST_REORDER_SUCCESS,
		payload: { pages: result }
	}
}
export const errorSavedReorderPage = (error:any) => {
	if(error.response?.exception?.message)
		toast.error(error.response.exception.message);
	else if(error.exception?.message)
		toast.error(error.exception.message);
	else
		toast.error("Impossible d'enregistrer le changement d'ordre des pages");
	return {
		type: PageVitrineActionsEnum.POST_REORDER_ERROR
	}
}

//add file pages
export const sendAddPageVitrineFile = () => {
	return {
		type: PageVitrineActionsEnum.POST_ADDFILE_REQUEST
	}
}
export const savedAddPageVitrineFile = (result: any) => {
	return {
		type: PageVitrineActionsEnum.POST_ADDFILE_SUCCESS,
		payload: { file: result }
	}
}
export const errorAddPageVitrineFile = (error:any) => {
	if(error.response?.exception?.message)
		toast.error(error.response.exception.message);
	else if(error.exception?.message)
		toast.error(error.exception.message);
	else
		toast.error("Impossible d'ajouter une page vitrine");
	return {
		type: PageVitrineActionsEnum.POST_ADDFILE_ERROR
	}
}



/** Tasks */
const pageVitrineClient = new PageVitrineClient('', axiosInstance);



//get pages
export const fetchPageVitrine = (
	dispatch: any,
) => {

	return () => {
		dispatch(requestPages());
		return pageVitrineClient.getPagesVitrine()
			.then(response => dispatch(getPages(response)))
			.catch(error => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les pages vitrines");
		})
	}
}

//save page
export const savePage = (
	dispatch: Dispatch,
	body: CreatePageVitrineDto
) => {
	dispatch(sendPage());
	return pageVitrineClient.createPageVitrine(body)
		.then(response => {
			dispatch(savedPage(response))
			return response;
		})
		.catch(error => {
			dispatch(errorSavedPage());
			if(error.response?.exception?.message)
				toast.error(error.response.exception.message);
			else if(error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error("Impossible de créer la page vitrine");
			throw error;
		});
}
//put page
export const editPageVitrine = (
	dispatch: any,
	id: string,
	body: any
) => {

	return () => {
		dispatch(requestPutPage());
		return pageVitrineClient.updatePageVitrine(id, body)
			.then((response: any) => dispatch(putPage(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de mettre à jour la vitrine");
			})
	}
}

//delete critère
export const delPageVitrine = (
	dispatch: Dispatch,
	id: string
) => {

	return () => {
		dispatch(requestDeletePage());
		return pageVitrineClient.deletePageVitrine(id)
			.then((response: any) => dispatch(deletePage(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer la page vitrine");
			})
	}
}


//reorder
export const reorderPage = (
	dispatch: Dispatch,
	body: any
) => {
	return () => {
		dispatch(sendReorderPage());
		return pageVitrineClient.reorderPagesVitrine(body)
			.then((response: any) => dispatch(savedReorderPage(response)))
			.catch((error: any) => dispatch(errorSavedReorderPage(error)))
	}
}


//uploadPageVitrineFile

export const addPageVitrineFile = (
	dispatch: any,
	file: FileParameter
) => {
	return () => {
		dispatch(sendAddPageVitrineFile());
		return pageVitrineClient.uploadPageVitrineFile(file)
			.then((response: any) => dispatch(savedAddPageVitrineFile(response)))
			.catch((error: any) => dispatch(errorAddPageVitrineFile(error)))
	}
}
