import { Link } from '@reach/router';
import Pagination, { PageNumbers } from "adel-shared/dist/components/basics/Pagination";
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { BODossierDtoSortedFilteredPage } from '../../services/generated/BackOffice-api';
import { updateFilter } from '../../store/dossier-store/actions/dossierAction';
import { useDossierSelector } from '../../store/store-helpers';
import { IDossierFilters } from './DossiersListe';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface DossiersTableProps {
	setSort: (value:string) => void;
	setPage: (value: number) => void;
	setPageSize: (value: number) => void;
	pageSize: number;
	page: number;
	listeDossiers?: BODossierDtoSortedFilteredPage;
	paginationNumbers: PageNumbers;
	filters?: IDossierFilters
}

const DossiersTable:React.FunctionComponent<DossiersTableProps> = ({
	setSort,
	setPage,
	setPageSize,
	pageSize,
	page,
	listeDossiers,
	paginationNumbers,
	filters
}) => {
	const { t } = useTranslation();
    const dispatch = useDispatch()
	const [data, setData] = useState<Array<any>>([]);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "N° de dossier", accessor: "Numero", sortType: "basic" },
					{ Header: "Nom dossier", accessor: "Nom", sortType: "basic" },
					{ Header: "Structure", accessor: "RaisonSociale", sortType: "basic" },
					{ Header: "Code structure", accessor: "CodeStructure", sortType: "basic" },
					{ Header: "Chargé d'instruction", accessor: "ChargeInstructionNom", sortType: "basic" },
					{ Header: "Catégorie", accessor: "CategorieNom", sortType: "basic" },
					{ Header: "Statut", accessor: "Statut", sortType: "basic" },
					{ Header: "Date début du projet", accessor: "DateDebutProjet", sortType: "basic" },
					{ Header: "Date soumission", accessor: "DateSoumission", sortType: "basic" },
					{ Header: "Date début commission", accessor: "DateDebutCommission", sortType: "basic" },
					{ Header: "DDE acompte", accessor: "DateDemandeVersement", sortType: "basic" },
					{ Header: "DDE solde ou versement total", accessor: "DatesDemandeVersementSoldeTotal", sortType: "basic" },
					{ Header: "Alertes", accessor: "Alertes", disableSortBy: true },
					{ accessor: "Id" }
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	useEffect(() => {
		if (listeDossiers) {
			if (listeDossiers.number && listeDossiers.totalPageCount && listeDossiers.number > listeDossiers.totalPageCount)
				setPage(1);
			listeDossiers.hasNext && setHasNext(listeDossiers.hasNext);
			listeDossiers.hasPrevious && setHasPrevious(listeDossiers.hasPrevious);
			listeDossiers.isFirst && setIsFirst(listeDossiers.isFirst);
			listeDossiers.isLast && setIsLast(listeDossiers.isLast);
			listeDossiers.totalPageCount && setTotalPageCount(listeDossiers.totalPageCount);
			listeDossiers.totalItemCount && setTotalItemCount(listeDossiers.totalItemCount);

			const data = listeDossiers?.items?.map(dossier => ({
				Numero: dossier.numero,
				Nom: <div><Link to={`/Dossier/${dossier.id}`} onClick={()=> dispatch(updateFilter(filters)) }>{dossier.nom}</Link></div>,
				DateDebutProjet: dossier.dateDebutProjet ? formaterDate(dossier.dateDebutProjet) : '',
				DateDebutCommission: dossier.dateDebutCommission ? formaterDate(dossier.dateDebutCommission) : '',
				DateDemandeVersement: dossier.datesDemandeVersement ? dossier.datesDemandeVersement.map(x => formaterDate(x)).join(', ') : '',
				DatesDemandeVersementSoldeTotal: dossier.datesDemandeVersementSoldeTotal ? formaterDate(dossier.datesDemandeVersementSoldeTotal) : '',
				RaisonSociale: dossier.raisonSociale,
				CodeStructure: dossier.codeStructure,
				ChargeInstructionNom: dossier.chargeInstructionId ? `${dossier.chargeInstructionNom} ${dossier.chargeInstructionPrenom}` : '',
				CategorieNom: dossier.categorieNomCourt,
				Statut: (
					<span className={`table__statutItem table__statut--${dossier.statut}`}>
						{t(`dossier.statut.${dossier.statut}`)}
					</span>//
				),
				DateSoumission: dossier.dateSoumission ? formaterDate(dossier.dateSoumission) : '',
				Id: dossier.id,
				Alertes: (
					<>
						{dossier.alerteRouge && (
							<i className="fas fa-siren-on dossiersAlertes dossiersAlertes--alerteRouge" title="Alerte rouge" />
						)}
						{dossier.alerteVerte && (
							<i className="fas fa-siren-on dossiersAlertes dossiersAlertes--alerteVerte" title="Alerte verte" />
						)}
						{dossier.blocageDSV && (
							<i className="fab fa-creative-commons-nc-eu dossiersAlertes dossiersAlertes--blocageDsv" title="Blocage DSV" />
						)}
						{dossier.blocageJuridique && (
							<i className="fab fa-creative-commons-nc-eu dossiersAlertes dossiersAlertes--blocageJuridique" title="Blocage juridique" />
						)}
						{dossier.blocageTriangle && (
							<i className="fas fa-triangle dossiersAlertes dossiersAlertes--blocageTriangle" title="Blocage Triangle" />
						)}
					</>
				)
			}));
			data && setData(data);
		}
	}, [listeDossiers]);

	return (<>
		<Table
			data={data}
			getTableProps={getTableProps}
			getTableBodyProps={getTableBodyProps}
			headerGroups={headerGroups}
			rows={rows}
			prepareRow={prepareRow}
			styles={styleTable.page}
		/>
		<Pagination
			hasNext={hasNext}
			hasPrevious={hasPrevious}
			isFirst={isFirst}
			isLast={isLast}
			totalPageCount={totalPageCount}
			totalItemCount={totalItemCount}
			page={setPage}
			pageSize={setPageSize}
			initPageSize={pageSize}
			initPage={page}
			pageNumbers={paginationNumbers}
		/>
	</>);
}

export default DossiersTable;