import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Synthese from './details/Synthese';
import Evenements from './details/Evenements';
import {
	getDossierMouvements,
	getDossierSyntheseFinanciere,
	getEvenementsFinanciersViewModel,
} from '../../../store/dossier-store/actions/dossierDetailsActions';
import { toast } from 'react-toastify';


interface FluxTabProps {
	dossierId: string | undefined;
}

const FluxTab: React.FC<FluxTabProps> = ({ dossierId }) => {
	const dispatch = useDispatch();
	
	useEffect(() => {
		(async() => {
			if(dossierId) {
				await getDossierSyntheseFinanciere(dispatch, dossierId);
				await getDossierMouvements(dispatch, dossierId);
				await getEvenementsFinanciersViewModel(dispatch, dossierId);
			}
		})();
	}, []);

	return (
		<div className="fluxTab">
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Synthèse</h3>
				<Synthese />
			</div>
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Évènements</h3>
				<Evenements dossierId={dossierId} />
			</div>
		</div>
	);
}

export default FluxTab;