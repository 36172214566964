import React, { FunctionComponent, useEffect, useState } from 'react';
import ModalDoublonStructure from './ModalDoublonStructure';
import ModalDoublonArtisteLieuDate from './ModalDoublonArtisteLieuDate';
import ModalDoublonArtisteAPI from './ModalDoublonArtisteAPI';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { useDossierSelector } from '../../store/store-helpers';
import { CategorieDossier } from '../../enums/Dossiers';
import {
	DossierClient,
	DoublonApiDto,
	DoublonArtisteDto,
	DoublonStructureDto,
	StructureClient
} from '../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../custom-hooks/useAxios';

interface DoublonsProps {
}

const Doublons: FunctionComponent<DoublonsProps> = () => {
	const {
		dossierDetails: dossier = {}
	} = useDossierSelector();
	const structureClient = new StructureClient('', axiosInstance);
	const dossierClient = new DossierClient('', axiosInstance);
	const [isOpenModalStructure, openModalStructure, closeModalStructure] = useBooleanState(false);
	const [isOpenModalArtistes, openModalArtistes, closeModalArtistes] = useBooleanState(false);
	const [isOpenModalAPI, openModalAPI, closeModalAPI] = useBooleanState(false);

	const [doublonsStructure, setDoublonsStructure] = useState<DoublonStructureDto[]>([]);
	const [doublonsArtistes, setDoublonsArtistes] = useState<DoublonArtisteDto[]>([]);
	const [doublonsAPI, setDoublonsAPI] = useState<DoublonApiDto[]>([]);

	const doublonsStructureLength = doublonsStructure.length;
	const doublonsArtistesLength = doublonsArtistes.length;
	const doublonsAPILength = doublonsAPI.length; 

	useEffect(() => {
		(async() => {
			if(!dossier.structure?.id) {
				return;
			}

			try {
				const result = await structureClient.getDoublonsStructure(dossier.structure.id);
				setDoublonsStructure(result);
			} catch(error) {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error('Une erreur est survenue pendant la récupération des doublons "Membres de structure".');
			}
		})();

		(async() => {
			if(!dossier.id) {
				return;
			}

			try {
				const result = await dossierClient.getDoublonsArtiste(dossier.id);
				setDoublonsArtistes(result);
			}
			catch(error) {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error('Une erreur est survenue pendant la récupération des doublons de "Artiste/Lieu/Date".');
			}
		})();

		(async() => {
			if(!dossier.id || dossier.categorie?.code !== CategorieDossier.AidePromotionImage) {
				return;
			}

			try {
				const result = await dossierClient.getDoublonsApi(dossier.id);
				setDoublonsAPI(result);
			} catch(error) {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error('Une erreur est survenue pendant la récupération des doublons "Artiste API".');
			}
		})();
	}, [dossier]);

	if(!doublonsStructureLength && !doublonsArtistesLength && !doublonsAPILength) {
		return null;
	}

    return (
		<div className="sideInfo sideInfo__doublons">
			<ul>
				{doublonsStructureLength > 0 && (
					<li className="sideInfo__doublonsItem">
						<button type="button" className="button--link" onClick={openModalStructure}>
							Doublons "Membre de structure"
						</button>
					</li>
				)}
				{doublonsArtistesLength > 0 && (
					<li className="sideInfo__doublonsItem">
						<button type="button" className="button--link" onClick={openModalArtistes}>
							Doublons "Artiste/Lieu/Date"
						</button>
					</li>
				)}
				{(doublonsAPILength > 0 && dossier.categorie?.code === CategorieDossier.AidePromotionImage) && (
					<li className="sideInfo__doublonsItem">
						<button type="button" className="button--link" onClick={openModalAPI}>
							Doublons "Artiste API"
						</button>
					</li>
				)}
			</ul>
			{doublonsStructureLength > 0 && (
				<ModalDoublonStructure
					isOpen={isOpenModalStructure}
					closeModal={closeModalStructure}
					doublons={doublonsStructure}
				/>
			)}
			{doublonsArtistesLength > 0 && (
				<ModalDoublonArtisteLieuDate
					isOpen={isOpenModalArtistes}
					closeModal={closeModalArtistes}
					doublons={doublonsArtistes}
				/>
			)}
			{(doublonsAPILength > 0 && dossier.categorie?.code === CategorieDossier.AidePromotionImage) && (
				<ModalDoublonArtisteAPI
					isOpen={isOpenModalAPI}
					closeModal={closeModalAPI}
					doublons={doublonsAPI}
				/>
			)}
		</div>
	)
};

export default Doublons;