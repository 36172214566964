import { Action } from 'redux';
import { DossierPayload } from '../states/dossierState';
import { DossierClient, ValidateDossierDto } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum DossierActionsEnum {
	// Dossier list
	GET_DOSSIER_REQUEST = "GET_DOSSIER_REQUEST",
	CLEANUP_DOSSIER_REQUEST = "CLEANUP_DOSSIER_REQUEST",
	GET_DOSSIER_SUCCESS = "GET_DOSSIER_SUCCESS",
	GET_DOSSIER_ERROR = "GET_DOSSIER_ERROR",

	// Dossier details
	GET_DOSSIER_DETAILS_REQUEST = "GET_DOSSIER_DETAILS_REQUEST",
	GET_DOSSIER_DETAILS_SUCCESS = "GET_DOSSIER_DETAILS_SUCCESS",
	GET_DOSSIER_DETAILS_ERROR = "GET_DOSSIER_DETAILS_ERROR",

	// Dossier relations
	GET_DOSSIER_RELATIONS_REQUEST = "GET_DOSSIER_RELATIONS_REQUEST",
	GET_DOSSIER_RELATIONS_SUCCESS = "GET_DOSSIER_RELATIONS_SUCCESS",
	GET_DOSSIER_RELATIONS_ERROR = "GET_DOSSIER_RELATIONS_ERROR",
	
	ADD_DOSSIER_RELATIONS_REQUEST = "ADD_DOSSIER_RELATIONS_REQUEST",
	ADD_DOSSIER_RELATIONS_SUCCESS = "ADD_DOSSIER_RELATIONS_SUCCESS",
	ADD_DOSSIER_RELATIONS_ERROR = "ADD_DOSSIER_RELATIONS_ERROR",
	
	DELETE_DOSSIER_RELATIONS_REQUEST = "DELETE_DOSSIER_RELATIONS_REQUEST",
	DELETE_DOSSIER_RELATIONS_SUCCESS = "DELETE_DOSSIER_RELATIONS_SUCCESS",
	DELETE_DOSSIER_RELATIONS_ERROR = "DELETE_DOSSIER_RELATIONS_ERROR",

	// Dossier Additional Info
	GET_DOSSIER_ADDITIONALINFO_REQUEST = "GET_DOSSIER_ADDITIONALINFO_REQUEST",
	GET_DOSSIER_ADDITIONALINFO_SUCCESS = "GET_DOSSIER_ADDITIONALINFO_SUCCESS",
	GET_DOSSIER_ADDITIONALINFO_ERROR = "GET_DOSSIER_ADDITIONALINFO_ERROR",

	// Dossier autocomplete
	GET_AUTOCOMPLETE_NOM_REQUEST = "GET_AUTOCOMPLETE_NOM_REQUEST",
	GET_AUTOCOMPLETE_NOM_SUCCESS = "GET_AUTOCOMPLETE_NOM_SUCCESS",

	// Dossier history
	GET_DOSSIER_HISTORY_REQUEST = "GET_DOSSIER_HISTORY_REQUEST",
	GET_DOSSIER_HISTORY_SUCCESS = "GET_DOSSIER_HISTORY_SUCCESS",

	// Dossier note
	GET_DOSSIER_NOTE_REQUEST = "GET_DOSSIER_NOTE_REQUEST",
	GET_DOSSIER_NOTE_SUCCESS = "GET_DOSSIER_NOTE_SUCCESS",
	GET_DOSSIER_NOTE_ERROR = "GET_DOSSIER_NOTE_ERROR",
	PUT_DOSSIER_NOTE_REQUEST = "PUT_DOSSIER_NOTE_REQUEST",
	PUT_DOSSIER_NOTE_SUCCESS = "PUT_DOSSIER_NOTE_SUCCESS",
	PUT_DOSSIER_NOTE_ERROR = "PUT_DOSSIER_NOTE_ERROR",

	GET_DOSSIER_NOTATION_REQUEST = "GET_DOSSIER_NOTATION_REQUEST",
	GET_DOSSIER_NOTATION_SUCCESS = "GET_DOSSIER_NOTATION_SUCCESS",
	GET_DOSSIER_NOTATION_ERROR = "GET_DOSSIER_NOTATION_ERROR",
	POST_NOTE_PROFESSIONALISME_REQUEST = "POST_NOTE_PROFESSIONALISME_REQUEST",
	POST_NOTE_PROFESSIONALISME_SUCCESS = "POST_NOTE_PROFESSIONALISME_SUCCESS",
	POST_NOTE_PROFESSIONALISME_ERROR = "POST_NOTE_PROFESSIONALISME_ERROR",

	// Dossier DSV
	GET_DOSSIER_DSV_REQUEST = "GET_DOSSIER_DSV_REQUEST",
	GET_DOSSIER_DSV_SUCCESS = "GET_DOSSIER_DSV_SUCCESS",
	GET_DOSSIER_DSV_ERROR = "GET_DOSSIER_DSV_ERROR",
	PUT_DOSSIER_DSV_REQUEST = "PUT_DOSSIER_DSV_REQUEST",
	PUT_DOSSIER_DSV_SUCCESS = "PUT_DOSSIER_DSV_SUCCESS",
	PUT_DOSSIER_DSV_ERROR = "PUT_DOSSIER_DSV_ERROR",

	// Dossier instruction
	GET_DOSSIER_INSTRUCTION_REQUEST = "GET_DOSSIER_INSTRUCTION_REQUEST",
	GET_DOSSIER_INSTRUCTION_SUCCESS = "GET_DOSSIER_INSTRUCTION_SUCCESS",
	GET_DOSSIER_INSTRUCTION_ERROR = "GET_DOSSIER_INSTRUCTION_ERROR",

	PUT_DOSSIER_INSTRUCTION_REQUEST = "PUT_DOSSIER_INSTRUCTION_REQUEST",
	PUT_DOSSIER_INSTRUCTION_SUCCESS = "PUT_DOSSIER_INSTRUCTION_SUCCESS",
	PUT_DOSSIER_INSTRUCTION_ERROR = "PUT_DOSSIER_INSTRUCTION_ERROR",

	GET_DOSSIER_SUBCATEGORIES_REQUEST = "GET_DOSSIER_SUBCATEGORIES_REQUEST",
	GET_DOSSIER_SUBCATEGORIES_SUCCESS = "GET_DOSSIER_SUBCATEGORIES_SUCCESS",
	GET_DOSSIER_SUBCATEGORIES_ERROR = "GET_DOSSIER_SUBCATEGORIES_ERROR",

	// Dossier Aides history
	GET_DOSSIER_AIDES_HISTORY_REQUEST = "GET_DOSSIER_AIDES_HISTORY_REQUEST",
	GET_DOSSIER_AIDES_HISTORY_SUCCESS = "GET_DOSSIER_AIDES_HISTORY_SUCCESS",
	GET_DOSSIER_AIDES_HISTORY_ERROR = "GET_DOSSIER_AIDES_HISTORY_ERROR",

	// Dossier versement
	PUT_DOSSIER_AGREEMENT_REQUEST = "PUT_DOSSIER_AGREEMENT_REQUEST",
	PUT_DOSSIER_AGREEMENT_SUCCESS = "PUT_DOSSIER_AGREEMENT_SUCCESS",
	PUT_DOSSIER_AGREEMENT_ERROR = "PUT_DOSSIER_AGREEMENT_ERROR",

	// Dossier documents validation
	PUT_DOSSIER_DOCUMENTS_VALIDATION_REQUEST = "PUT_DOSSIER_DOCUMENTS_VALIDATION_REQUEST",
	PUT_DOSSIER_DOCUMENTS_VALIDATION_SUCCESS = "PUT_DOSSIER_DOCUMENTS_VALIDATION_SUCCESS",
	PUT_DOSSIER_DOCUMENTS_VALIDATION_ERROR = "PUT_DOSSIER_DOCUMENTS_VALIDATION_ERROR",
	
	// Dossier courriers
	GET_DOSSIER_CONVENTION_COURRIER_REQUEST = "GET_DOSSIER_CONVENTION_COURRIER_REQUEST",
	GET_DOSSIER_CONVENTION_COURRIER_SUCCESS = "GET_DOSSIER_CONVENTION_COURRIER_SUCCESS",
	GET_DOSSIER_CONVENTION_COURRIER_ERROR = "GET_DOSSIER_CONVENTION_COURRIER_ERROR",

	// Dossier versements
	GET_DOSSIER_VERSEMENTS_REQUEST = "GET_DOSSIER_VERSEMENTS_REQUEST",
	GET_DOSSIER_VERSEMENTS_SUCCESS = "GET_DOSSIER_VERSEMENTS_SUCCESS",
	GET_DOSSIER_VERSEMENTS_ERROR = "GET_DOSSIER_VERSEMENTS_ERROR",

	PUT_INSTRUCTION_VERSEMENT_DSV_REQUEST = "PUT_INSTRUCTION_VERSEMENT_DSV_REQUEST",
	PUT_INSTRUCTION_VERSEMENT_DSV_SUCCESS = "PUT_INSTRUCTION_VERSEMENT_DSV_SUCCESS",
	PUT_INSTRUCTION_VERSEMENT_DSV_ERROR = "PUT_INSTRUCTION_VERSEMENT_DSV_ERROR",

	// Validate dossier
	GET_VALIDATION_DOSSIER_REQUEST = "GET_VALIDATION_DOSSIER_REQUEST",
	GET_VALIDATION_DOSSIER_SUCCESS = "GET_VALIDATION_DOSSIER_SUCCESS",
	GET_VALIDATION_DOSSIER_ERROR = "GET_VALIDATION_DOSSIER_ERROR",

	VALIDATE_DOSSIER_REQUEST = "VALIDATE_DOSSIER_REQUEST",
	VALIDATE_DOSSIER_SUCCESS = "VALIDATE_DOSSIER_SUCCESS",
	VALIDATE_DOSSIER_ERROR = "VALIDATE_DOSSIER_ERROR",

	// Dossier message 
	GET_DOSSIER_MESSAGES_REQUEST = "GET_DOSSIER_MESSAGES_REQUEST",
	GET_DOSSIER_MESSAGES_SUCCESS = "GET_DOSSIER_MESSAGES_SUCCESS",
	GET_DOSSIER_MESSAGES_ERROR = "GET_DOSSIER_MESSAGES_ERROR",
	POST_DOSSIER_MESSAGES_REQUEST = "POST_DOSSIER_MESSAGES_REQUEST",
	POST_DOSSIER_MESSAGES_SUCCESS = "POST_DOSSIER_MESSAGES_SUCCESS",
	POST_DOSSIER_MESSAGES_ERROR = "POST_DOSSIER_MESSAGES_ERROR",
	CREATE_DOSSIER_DISCUSSION_REQUEST = "CREATE_DOSSIER_DISCUSSION_REQUEST",
	CREATE_DOSSIER_DISCUSSION_SUCCESS = "CREATE_DOSSIER_DISCUSSION_SUCCESS",
	CREATE_DOSSIER_DISCUSSION_ERROR = "CREATE_DOSSIER_DISCUSSION_ERROR",
	UPDATE_DOSSIER_DISCUSSION_LU_SUCCESS = "UPDATE_DOSSIER_DISCUSSION_LU_SUCCESS",
	POST_DOSSIER_MULTIPLE_MESSAGES_REQUEST = "POST_DOSSIER_MULTIPLE_MESSAGES_REQUEST",
	POST_DOSSIER_MULTIPLE_MESSAGES_SUCCESS = "POST_DOSSIER_MULTIPLE_MESSAGES_SUCCESS",
	POST_DOSSIER_MULTIPLE_MESSAGES_ERROR = "POST_DOSSIER_MULTIPLE_MESSAGES_ERROR",
	GET_DOSSIER_MESSAGES_OBJECT = "GET_DOSSIER_MESSAGES_OBJECT",
	UPDATE_DOSSIER_FILTRE_SUCCESS = "UPDATE_DOSSIER_FILTRE_SUCCESS",

	// Other
	RECALCUL_MASSE_SALARIALE_SUCCESS = "RECALCUL_MASSE_SALARIALE_SUCCESS",
	RECALCUL_MASSE_SALARIALE_ERROR = "RECALCUL_MASSE_SALARIALE_ERROR",

	GET_VERSEMENT_DETAILS_REQUEST = "GET_VERSEMENT_DETAILS_REQUEST",
	GET_VERSEMENT_DETAILS_SUCCESS = "GET_VERSEMENT_DETAILS_SUCCESS",
	GET_VERSEMENT_DETAILS_ERROR = "GET_VERSEMENT_DETAILS_ERROR",

	GET_VERSEMENT_MASSE_SALARIALE_SUCCESS = "GET_VERSEMENT_MASSE_SALARIALE_SUCCESS",
	GET_VERSEMENT_MASSE_SALARIALE_ERROR = "GET_VERSEMENT_MASSE_SALARIALE_ERROR",
	
	PRENDRE_EN_CHARGE_DOSSIER_SUCCESS = "PRENDRE_EN_CHARGE_DOSSIER_SUCCESS",

	PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_SUCCESS = "PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_SUCCESS",
	PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_ERROR = "PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_ERROR",

	GET_DOSSIER_SYNTHESE_FINANCIERE = "GET_DOSSIER_SYNTHESE_FINANCIERE",
	GET_DOSSIER_MOUVEMENTS = "GET_DOSSIER_MOUVEMENTS",
	AJOUT_EVENEMENT_FINANCIER_REQUEST = "AJOUT_EVENEMENT_FINANCIER_REQUEST",
	AJOUT_EVENEMENT_FINANCIER_SUCCESS = "AJOUT_EVENEMENT_FINANCIER_SUCCESS",
	AJOUT_EVENEMENT_FINANCIER_ERROR = "AJOUT_EVENEMENT_FINANCIER_ERROR",

	GET_EVENEMENTS_FINANCIERS_SUCCESS = "GET_EVENEMENTS_FINANCIERS_SUCCESS",
	GET_EVENEMENTS_FINANCIERS_ERROR = "GET_EVENEMENTS_FINANCIERS_ERROR",
	
}


export interface DispatchDossierAction extends Action<DossierActionsEnum> {
	payload: DossierPayload;
}

export const requestAutocompleteNom = () => {
	return {
		type: DossierActionsEnum.GET_AUTOCOMPLETE_NOM_REQUEST,
		payload: {}
	}
}

export const updateFilter = (result: any) => {
	return {
		type: DossierActionsEnum.UPDATE_DOSSIER_FILTRE_SUCCESS,
		payload: result
	}
}

export const getAutocompleteNom = (result: any) => {
	return {
		type: DossierActionsEnum.GET_AUTOCOMPLETE_NOM_SUCCESS,
		payload: result
	}
}

/** Task */
const dossierClient = new DossierClient('', axiosInstance);

export const fetchDossiers = async (
	dispatch: any,
	disablePagination: boolean = false,
	filters?: string,
	page: number = 1,
	pageSize: number = 3,
	sort?: string,
	artisteNom?: string,
	artistePrenom?: string
) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_REQUEST });
	try {
		const result = await dossierClient.getDossiers(filters, sort, page, pageSize, disablePagination, artisteNom, artistePrenom);
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_SUCCESS, payload: { listeDossiers: result } });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les dossiers");
		throw error;
	}
}

export const fetchSubCategories = async (dispatch: any) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_SUBCATEGORIES_REQUEST });
    try {
		let result = await dossierClient.getSubCategoriesDossier();
        dispatch({ type: DossierActionsEnum.GET_DOSSIER_SUBCATEGORIES_SUCCESS, payload: { subCategories: result } })
		return result;
    } catch (error) {
        dispatch({ type: DossierActionsEnum.GET_DOSSIER_SUBCATEGORIES_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les sous-catégories de dossier");
        throw error;
    }
}

export const programmerEnCommissionDossier = async (id: string) => {
	try {
		await dossierClient.programmerEnCommissionDossier(id);
	} catch (error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de programmer en commission");
		throw error;
	}
}

export const getDossierValidationViewModel = async (dispatch: any, dossierId: string) => {
	dispatch({ type: DossierActionsEnum.GET_VALIDATION_DOSSIER_REQUEST });
    try {
		let result = await dossierClient.getDossierValidationViewModel(dossierId);
        dispatch({ type: DossierActionsEnum.GET_VALIDATION_DOSSIER_SUCCESS, payload: { dossierValidation: result } })
    } catch (error) {
        dispatch({ type: DossierActionsEnum.GET_VALIDATION_DOSSIER_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les validations de dossier");
        throw error;
    }
}

export const validateDossier = async (dispatch: any, dossierId: string, body: ValidateDossierDto) => {
	dispatch({ type: DossierActionsEnum.VALIDATE_DOSSIER_REQUEST });
    try {
		await dossierClient.validateDossier(dossierId, body);
        dispatch({ type: DossierActionsEnum.VALIDATE_DOSSIER_SUCCESS })
    } catch (error) {
        dispatch({ type: DossierActionsEnum.VALIDATE_DOSSIER_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de valider le dossier");
        throw error;
    }
}

export const getDossiersFilteredAsExcel = async(
	filters: string,
	sorts: string
) => {
	try {
		const result = await dossierClient.getDossierFilteredAsExcel(filters, sorts, undefined, undefined, true);
		const link = document.createElement('a');
		if(result.fileName) {
			link.download = result.fileName;
		}
		link.href = `data:${result.contentType};base64,${result.content}`;
		link.click();
	} catch(error) {
		throw error;
	}
};