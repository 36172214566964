import React, { useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import { TypePrestation } from '../../../models/generated/BackOffice-api';
import { formatNumber } from '../../../utils/functions';
import Table, { styleTable } from '../../basics/Table';
const ArtisteModalTableSalaire = ({ salaires, montantChargeSalaire }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (salaires.length) {
            setData(salaires.map(s => {
                var _a, _b, _c, _d;
                return ({
                    montantBrut: s.montantBrut,
                    tauxChargesPatronales: s.tauxChargesPatronales,
                    repetitionCount: ((_a = s.datesByType.find(d => d.typePrestation === TypePrestation.Repetition)) === null || _a === void 0 ? void 0 : _a.dates.length) || 0,
                    representationCount: ((_b = s.datesByType.find(d => d.typePrestation === TypePrestation.Representation)) === null || _b === void 0 ? void 0 : _b.dates.length) || 0,
                    enregistrementCount: ((_c = s.datesByType.find(d => d.typePrestation === TypePrestation.Enregistrement)) === null || _c === void 0 ? void 0 : _c.dates.length) || 0,
                    concertCount: ((_d = s.datesByType.find(d => d.typePrestation === TypePrestation.Concert)) === null || _d === void 0 ? void 0 : _d.dates.length) || 0
                });
            }));
        }
    }, [salaires]);
    const columns = useMemo(() => {
        return [
            {
                Header: "Table",
                Footer: "Table",
                columns: [
                    {
                        Header: "Salaire brut",
                        Footer: (value) => {
                            const totalMontant = React.useMemo(() => data.reduce((acc, current) => acc + (current.montantBrut * current.count), 0), [data]);
                            return React.createElement(React.Fragment, null,
                                "Total montant : ",
                                formatNumber(totalMontant),
                                " \u20AC");
                        },
                        Cell: (value) => React.createElement("span", null,
                            formatNumber(value.value),
                            " \u20AC"),
                        accessor: "montantBrut",
                        disableSortBy: true
                    },
                    {
                        Header: "Taux de charges patronales",
                        Footer: () => {
                            return React.createElement(React.Fragment, null,
                                "Montant total des charges patronales : ",
                                formatNumber(montantChargeSalaire),
                                " \u20AC");
                        },
                        Cell: (value) => {
                            return React.createElement(React.Fragment, null, Math.round(value.value * 10000) / 100);
                        },
                        accessor: "tauxChargesPatronales",
                        disableSortBy: true
                    },
                    { Header: "Nombre jours Répétitions", accessor: "repetitionCount", disableSortBy: true },
                    { Header: "Nombre jours Représentations", accessor: "representationCount", disableSortBy: true },
                    { Header: "Nombre jours Enregistrements", accessor: "enregistrementCount", disableSortBy: true },
                    { Header: "Nombre jours Concerts", accessor: "concertCount", disableSortBy: true }
                ]
            }
        ];
    }, [salaires]);
    const hiddenColumns = () => {
        const array = [];
        if (!salaires.find(s => s.datesByType.find(d => d.typePrestation === TypePrestation.Concert)))
            array.push("concertCount");
        if (!salaires.find(s => s.datesByType.find(d => d.typePrestation === TypePrestation.Representation)))
            array.push("representationCount");
        if (!salaires.find(s => s.datesByType.find(d => d.typePrestation === TypePrestation.Repetition)))
            array.push("repetitionCount");
        if (!salaires.find(s => s.datesByType.find(d => d.typePrestation === TypePrestation.Enregistrement)))
            array.push("enregistrementCount");
        return array;
    };
    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow, state } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: hiddenColumns(),
        },
        manualSortBy: true
    }, useSortBy);
    return (React.createElement(Table, { data: data, getTableProps: getTableProps, getTableBodyProps: getTableBodyProps, headerGroups: headerGroups, footerGroups: footerGroups, rows: rows, prepareRow: prepareRow, styles: styleTable.page }));
};
export default ArtisteModalTableSalaire;
