import { TypeDeDocumentWithCategoriesDto } from '../../../services/generated/BackOffice-api';

export interface TypeDeDocumentState {
	documentsIsLoading: boolean;
	documentAddIsLoading: boolean;
	documentDeleteIsLoading: boolean;
	documents?: TypeDeDocumentWithCategoriesDto[];
	document?: TypeDeDocumentWithCategoriesDto;
	id?: string;
}

export const initialTypeDeDocumentState: TypeDeDocumentState = {
	documentsIsLoading: false,
	documentAddIsLoading: false,
	documentDeleteIsLoading: false,
	documents: [],
	document: {},
	id: "",
};
