import React, { useState, useRef, FunctionComponent } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import clsx from 'clsx';

interface DropdownProps {
    label: string;
    className?: string;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
    label,
    children,
    className
}) => {
    const dropdownRef = useRef(null);
	const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
	useOnClickOutside(dropdownRef, () => setIsDropdownOpened(false));

    const handleLabelClick = () => {
        if(isDropdownOpened) {
            setIsDropdownOpened(false);
        } else {
            setIsDropdownOpened(true);
        }
    };

    return (
        <div
            className={clsx('dropdown', className)}
            ref={dropdownRef}
        >
            <div
                className="dropdown__label"
                onClick={handleLabelClick}
            >
                {label}
            </div>
                {isDropdownOpened && (
                    <div className="dropdown__menu">
                        {children}
                    </div>
                )}
            </div>
    );
};

export default Dropdown;