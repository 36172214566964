import Pagination from 'adel-shared/dist/components/basics/Pagination';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';
import { paginationNumbers } from '../../../constants/config.constant';
import { StructureHistoryDto, StructureHistoryModel } from '../../../services/generated/BackOffice-api';
import { useStructureDispatcher } from '../../../store/store-helpers';
import { fetchStructureHistory } from '../../../store/structure-store/actions/structureHistoryActions';
import { dicoHasModif, hasModif, objectHasModif, renderHistoryDico, renderHistoryObject, renderHistoryValue } from '../../../utils/history';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface StructureHistoriqueTabProps {
	id: string;
}

const StructureHistoriqueTab:React.SFC<StructureHistoriqueTabProps> = ({ id }) => {
	const dispatch = useStructureDispatcher();
	const [histories, setHistories] = useState<StructureHistoryDto[]>([]);
	const [datas, setDatas] = useState<Array<any>>([]);
	const [sort, setSort] = useState<string>("");

	const { t } = useTranslation();

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	useEffect(() => {
		id && fetchStructureHistory(dispatch, id, sort, page, pageSize)().then((result:any) => {
			if (result?.payload?.structureHistory?.number > result?.payload?.structureHistory?.totalPageCount) setPage(1);
			setHistories(result?.payload?.structureHistory?.items);
			setHasNext(result?.payload?.structureHistory?.hasNext);
			setHasPrevious(result?.payload?.structureHistory?.hasPrevious);
			setIsFirst(result?.payload?.structureHistory?.isFirst);
			setIsLast(result?.payload?.structureHistory?.isLast);
			setTotalPageCount(result?.payload?.structureHistory?.totalPageCount);
			setTotalItemCount(result?.payload?.structureHistory?.totalItemCount);
		})
	}, [fetchStructureHistory, page, pageSize, sort]);

	/** Get the datas for the table */
	useEffect(() => {
		const data = histories?.map(history => {
			return {
				DateTime: history.dateTime && formaterDate(history.dateTime),
				actions: history.before && history.after && {
					before: history.before,
					after: history.after
				},
				User: history.userFullName,
				id: history.id
			}
		});
		setDatas(data);
	}, [histories]);

	const renderHistorique = (value: { before: StructureHistoryModel, after: StructureHistoryModel }) => {
		const before = value.before;
		const after = value.after;

		// un seul niveau d'objet
		const status = (before.statut || after.statut) && hasModif("statut", before.statut, after.statut, value => t(`history.statut.${value}`));
		const note = (before.note || after.note) && hasModif("note", before.note, after.note);

		// deux niveaux d'objet
		const president = (before.president || after.president) && objectHasModif("président", before.president, after.president, t);
		const correspondant = (before.correspondant || after.correspondant) && objectHasModif("correspondant", before.correspondant, after.correspondant, t);
		const adresse = (before.adresse || after.adresse) && objectHasModif("adresse", before.adresse, after.adresse, t);
		const adresseCorrespondance = (before.adresseCorrespondance || after.adresseCorrespondance) && objectHasModif("adresse de correspondance", before.adresseCorrespondance, after.adresseCorrespondance, t);
		const donneesBancaires = (before.donneesBancaires || after.donneesBancaires) && objectHasModif("données bancaires", before.donneesBancaires, after.donneesBancaires, t);

		// ce sont des dicos
		const categoriesDossier = (before.categoriesDossier && after.categoriesDossier) && dicoHasModif("catégorie de dossier", before.categoriesDossier, after.categoriesDossier, "nom");
		const documents = (before.documents && after.documents) && dicoHasModif("documents", before.documents, after.documents, "fileName");

		return (
			<div className="detailsTab__table-row">
				{renderHistoryValue(status)}
				{renderHistoryValue(note)}
				{renderHistoryObject(president)}
				{renderHistoryObject(correspondant)}
				{renderHistoryObject(adresse)}
				{renderHistoryObject(adresseCorrespondance)}
				{renderHistoryObject(donneesBancaires)}
				{renderHistoryDico(categoriesDossier)}
				{renderHistoryDico(documents)}
			</div>
		);
	}

	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Date de création",
						accessor: "DateTime",
						sortType: "basic"
					},
					{
						Header: "Actions des chargés d'instructions",
						accessor: "actions",
						disableSortBy: true,
						Cell: (props:any) => <>
							{
								renderHistorique(props.value)
							}
						</>
					},
					{
						Header: "Modifié par",
						accessor: "User",
						sortType: "basic"
					},
					{
						Header: "Id",
						accessor: "id"
					}
				]
			}
		];
	}, []);

	/** Sort */
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data: datas || [],
			initialState: {
				hiddenColumns: ["id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);


	return (
		<div className="detailsTab">
			<Table
				data={datas}
				getTableProps={getTableProps}
				getTableBodyProps={getTableBodyProps}
				headerGroups={headerGroups}
				rows={rows}
				prepareRow={prepareRow}
				styles={styleTable.onglet}
			/>
			<Pagination
				hasNext={hasNext}
				hasPrevious={hasPrevious}
				isFirst={isFirst}
				isLast={isLast}
				totalPageCount={totalPageCount}
				totalItemCount={totalItemCount}
				page={setPage}
				pageSize={setPageSize}
				initPageSize={pageSize}
				initPage={page}
				pageNumbers={paginationNumbers}
			/>
		</div>
	);
}

export default StructureHistoriqueTab;