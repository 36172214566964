import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useParams } from '@reach/router';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import PagesContainer from '../../../containers/PagesContainer';
import {
    fetchCommission,
    getCommissionDossiersCountForCourriers,
} from '../../../store/commission-store/actions/commissionActions';
import { useDispatch } from 'react-redux';
import { useCommissionSelector } from '../../../store/store-helpers';
import { downloadFile, downloadFileFromDataURI } from '../../../utils/functions';
import { CommissionClient, StatutCommission } from '../../../services/generated/BackOffice-api';
import { CommissionDossiersCountForCourriersDto } from 'adel-shared/dist/models/generated/BackOffice-api';
import { toast } from 'react-toastify';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import moment from 'moment';
import Loader from 'react-loader-spinner';

interface CommissionExports extends RouteComponentProps {
}

const CommissionExports: FunctionComponent<CommissionExports> = () => {
    const { commissionId } = useParams();
	const { commission = {} } = useCommissionSelector();
    const [dossiersCount, setDossiersCount] = useState<CommissionDossiersCountForCourriersDto>({});
	const dispatch = useDispatch();
    const commissionClient = new CommissionClient();
    const permGetCommissionDossiersAsExcel = useHasPermission("GetCommissionDossiersAsExcel");
	const permExportCourriersCommunication = useHasPermission("GetCommissionCourrierCommunication");
	const permExportPlanComptable = useHasPermission("GetCommissionPlanComptable");
    const permExportDossiersDSV = useHasPermission("GetDossiersDSVFromCommissionAsExcel");
    const permExportDossiersAidesCreation = useHasPermission("GetDossiersAidesCreationFromCommissionAsExcel");
    const permExportDossierUS7222 = useHasPermission("GetDossiersUS7222FromCommissionAsExcel");
    const permExportDossiersPVCommission = useHasPermission("GetDossiersPVCommissionAsExcel");
    const permExportDossiersAvignon = useHasPermission("GetDossiersAvignonFromCommissionAsExcel")
    const permExportDossiersAccepte = useHasPermission("GetCommissionDossiersAccepteAsExcel");
    const permExportEcritureAttribution = useHasPermission("GetCommissionEcritureAttribution");
    const permExportEcritureReaffectation = useHasPermission("GetCommissionEcritureReaffectation");
    const [isLoadingCommission, setIsLoadingCommission] = useState<boolean>(false);
    const [isLoadingYear, setIsLoadingYear] = useState<boolean>(false);

    const isAnyAvailableComExport = dossiersCount.boApiCount !== 0 || 
								dossiersCount.deplacementEcoleDramaMusicalCount !== 0 ||
								dossiersCount.festivalCount !== 0 ||
								dossiersCount.soireeArtisteFortissimoCount !== 0;

	useEffect(() => {
		fetchCommission(dispatch, commissionId);
        (async() => {
			const count = await getCommissionDossiersCountForCourriers(commissionId);
			setDossiersCount(count);
        })();
	}, []);

    const handleExportDossiersList = async() => {
        try {
            const result = await commissionClient.getCommissionDossiersAsExcel(commissionId);
            downloadFile(result);
        } catch(error) {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
	};

    const handleExportDossiersDsvList = async() => {
        try {
            const result = await commissionClient.getDossiersDSVFromCommissionAsExcel(commissionId);
            downloadFileFromDataURI(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportDossiersAidesCreationList = async () => {
        try {
            let commissionDate: Date | undefined;
            if (commission && commission.dateDebut) {
                commissionDate = commission?.dateDebut
            }
             const result = await commissionClient.getDossiersAidesCreationFromCommissionAsExcel(commissionId,commissionDate);
            downloadFileFromDataURI(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportDossiersAvignonList = async () => {
        try {
            let commissionDate: Date | undefined;
            if (commission && commission.dateDebut) {
                commissionDate = commission?.dateDebut
            }
            const result = await commissionClient.getDossiersAvignonFromCommissionAsExcel(commissionId, commissionDate);
            downloadFileFromDataURI(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportDossiersUS7222ListByCommissionId = async () => {
        try {
            let commissionDate: Date | undefined;
            if (commission && commission.dateDebut) {
                commissionDate = commission?.dateDebut
            }
            setIsLoadingCommission(true);
            const result = await commissionClient.getDossiersUS7222FromCommissionAsExcel(commissionId, undefined);
            downloadFileFromDataURI(result);
            setIsLoadingCommission(false);

        } catch {
            setIsLoadingCommission(false);
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportDossiersUS7222ListByYear = async () => {
        try {
            let commissionDate: Date | undefined;
            if (commission && commission.dateDebut) {
                commissionDate = commission?.dateDebut
            }
            setIsLoadingYear(true);
            const result = await commissionClient.getDossiersUS7222FromCommissionAsExcel(commissionId, commissionDate);
            downloadFileFromDataURI(result);
            setIsLoadingYear(false);
        } catch {
            setIsLoadingYear(false);
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportDossiersPVCommissionList = async () => {
        try {
            let commissionDate: Date | undefined;
            if (commission && commission.dateDebut) {
                commissionDate = commission?.dateDebut
            }
            const result = await commissionClient.getDossierPVCommissionAsExcel(commissionId, commissionDate);
            downloadFileFromDataURI(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

	const handleExportPlanComptable = async() => {
        try {
            const result = await commissionClient.getCommissionPlanComptable(commissionId);
            downloadFile(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
	};

    const handleExportAffiche = async() => {
        try {
            const result = await commissionClient.getCommissionDossiersAccepteAsExcel(commissionId);
            downloadFile(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportCourrierClick = async(code: string) => {
        try {
            const result = await commissionClient.getCommissionCourrierCommunication(commissionId, code);
            downloadFile(result, false);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportEcrituresAttribution = async(onCA: boolean) => {
        try {
            const result = await commissionClient.getCommissionEcritureAttribution(commissionId, onCA);
            downloadFile(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    const handleExportEcrituresReaffectation = async() => {
        try {
            const result = await commissionClient.getCommissionEcritureReaffectation(commissionId);
            downloadFile(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

    return (
        <PagesContainer
            title={`${commission.nom} - Exports`}
            pageClass="exports"
        >
            <>
                <div className="navigationFil">
                    <Link className="navigationFil__item" to="/Commission/ListeCommissions">
                        <i className="fas fa-chevron-left"></i>
                        Retour à la liste des commissions
                    </Link>
                </div>
                {(
                    permGetCommissionDossiersAsExcel &&
                    typeof commission.dossier !== 'undefined' &&
                    commission.dossier > 0
                ) && (
                    <>
                        <h2>Liste des dossiers</h2>
                        <FormButton
                            type="button"
                            icon="fas fa-download"
                            value="Export liste des dossiers"
                            onClick={handleExportDossiersList}
                        />
                    </>
                )}
                {(
                    permExportDossiersDSV &&
                    typeof commission.dossier !== 'undefined' &&
                    commission.dossier > 0
                ) && (
                    <>
                        <h2>Liste des dossiers droit exclusif</h2>
                        <FormButton
                            type="button"
                            icon="fas fa-download"
                            value="Export liste des dossiers droit exclusif"
                            onClick={handleExportDossiersDsvList}
                        />
                    </>
                    )}

                {(
                    permExportDossiersAidesCreation &&
                    typeof commission.dossier !== 'undefined' &&
                    commission.dossier > 0
                ) && (
                        <>
                            <h2>Liste des dossiers Aides Creation</h2>
                            <FormButton
                                type="button"
                                icon="fas fa-download"
                                value={"Export liste des dossiers Aides Creation de l'année " + moment(commission?.dateDebut).format("YYYY")}
                                onClick={handleExportDossiersAidesCreationList}
                            />
                        </>
                    )}

                {(
                    permExportDossierUS7222 &&
                    typeof commission.dossier !== 'undefined' &&
                    commission.dossier > 0
                ) && (
                        <>
                            <h2>Liste des dossiers gestion conflit d'intérêt</h2>
                            { isLoadingYear
                            ? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader> :
                            <FormButton
                                type="button"
                                icon="fas fa-download"
                                value={"Export gestion conflit d'intérêt de l'année " + moment(commission?.dateDebut).format("YYYY")}
                                onClick={handleExportDossiersUS7222ListByYear}
                            />
                            }
                            <br />
                            <br />
                            { isLoadingCommission
                            ? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader> :
                            <FormButton
                                type="button"
                                icon="fas fa-download"
                                value={"Export gestion conflit d'intérêt de la commission  " + commission?.nom}
                                onClick={handleExportDossiersUS7222ListByCommissionId}
                            />
                            }
                        </>
                    )}

                {(
                    permExportDossiersPVCommission &&
                    typeof commission.dossier !== 'undefined' &&
                    commission.dossier > 0
                ) && (
                        <>
                            <h2>Liste des dossiers PV de Commission</h2>
                            
                            <FormButton
                                type="button"
                                icon="fas fa-download"
                                value="Export liste des dossiers PV de Commission"
                                onClick={handleExportDossiersPVCommissionList}
                            />
                        </>
                    )}

{(
                    permExportDossiersAvignon &&
                    typeof commission.dossier !== 'undefined' &&
                    commission.dossier > 0
                ) && (
                        <>
                            <h2>Liste des dossiers Avignon</h2>
                            <FormButton
                                type="button"
                                icon="fas fa-download"
                                value="Export liste des dossiers Avignon"
                            onClick={handleExportDossiersAvignonList}
                            />
                        </>
                    )}


                {(
                    (
                        permExportCourriersCommunication ||
                        permExportDossiersAccepte
                    ) &&
                    commission.statut === StatutCommission.Terminee
                ) && (
                    <>
                        <h2>Courriers communication</h2>
                        <ul>
                            {permExportDossiersAccepte && (
                                <li>
                                    <FormButton
                                        type="button"
                                        icon="fas fa-download"
                                        value="Export fichier communication"
                                        onClick={handleExportAffiche}
                                    />
                                </li>
                            )}
                            {(isAnyAvailableComExport && permExportCourriersCommunication) && (
                                <>
                                    {dossiersCount.festivalCount !== 0 && (
                                        <li>
                                            <FormButton
                                                type="button"
                                                icon="fas fa-download"
                                                value="Dossiers acceptés de type « Festival »"
                                                onClick={() => handleExportCourrierClick('festival')}
                                            />
                                        </li>
                                    )}

                                    {dossiersCount.soireeArtisteFortissimoCount !== 0 && (
                                        <li>
                                            <FormButton
                                                type="button"
                                                icon="fas fa-download"
                                                value="Dossiers acceptés de types « Soirée des artistes » et « Fortissimo »"
                                                onClick={() => handleExportCourrierClick('soiree-fortissimo')}
                                            />
                                        </li>   
                                    )}

                                    {dossiersCount.deplacementEcoleDramaMusicalCount !== 0 && (
                                        <li>
                                            <FormButton
                                                type="button"
                                                icon="fas fa-download"
                                                value="Dossiers acceptés de types « Déplacement », « Ecole », « Spectacle dramatique, chorégraphique, cirque » et « Spectacle musical »"
                                                onClick={() => handleExportCourrierClick('deplacement-ecole-drama-musical')}
                                            />
                                        </li>
                                    )}

                                    {dossiersCount.boApiCount !== 0 && (
                                        <li>
                                            <FormButton
                                                type="button"
                                                icon="fas fa-download"
                                                value="Dossiers acceptés de types « BO » et « API/EPK »"
                                                onClick={() => handleExportCourrierClick('bo-api')}
                                            />
                                        </li>
                                    )}
                                </>
                            )}
                        </ul>
                    </>
                )}

                {(
                    (
                        permExportPlanComptable ||
                        permExportEcritureAttribution
                    ) &&
                    typeof commission.dossier !== 'undefined' &&
                    commission.dossier > 0 &&
                    commission.statut === StatutCommission.Terminee
                ) && (
                    <>
                        <h2>Comptabilité</h2>
                        <ul>
                            {permExportPlanComptable && (
                                <li>
                                    <FormButton
                                        type="button"
                                        icon="fas fa-download"
                                        value="Export plan comptable"
                                        onClick={handleExportPlanComptable}
                                    />
                                </li>
                            )}
                            {permExportEcritureAttribution && (
                                <>
                                    <li>
                                        <FormButton
                                            type="button"
                                            icon="fas fa-download"
                                            value="Export écritures d'attribution avec validation CA"
                                            onClick={() => handleExportEcrituresAttribution(true)}
                                        />
                                    </li>
                                    <li>
                                        <FormButton
                                            type="button"
                                            icon="fas fa-download"
                                            value="Export écritures d'attribution sans validation CA"
                                            onClick={() => handleExportEcrituresAttribution(false)}
                                        />
                                    </li>
                                </>
                            )}
                            {permExportEcritureReaffectation && (
                                <li>
                                    <FormButton
                                        type="button"
                                        icon="fas fa-download"
                                        value="Export écritures de réaffectation"
                                        onClick={handleExportEcrituresReaffectation}
                                    />
                                </li>
                            )}
                        </ul>
                    </>
                )}
            </>
        </PagesContainer>
    );
};

export default CommissionExports;