import React, { useCallback, useEffect } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import Input from 'adel-shared/dist/components/basics/Input';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import { DossierDSVDto } from '../../../services/generated/BackOffice-api';
import { fetchDossierDsv, sendDossierDsv } from '../../../store/dossier-store/actions/dossierDsvActions';
import { useDossierSelector } from '../../../store/store-helpers';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

interface DossierDsvTabProps {
	dossierId: string | undefined;
	validator:any;
}

const DossierDsvTab: React.FunctionComponent<DossierDsvTabProps> = ({
	dossierId,
	validator
}) => {
	const dispatch = useDispatch();
    const { t } = useTranslation();

	const { register, getValues, setValue, triggerValidation, errors, control } = useForm<DossierDSVDto>({ defaultValues: {
		signatureALD: false,
		instructionDossierEnCours: false
	}});
	const { dossierDSV, dossierDetails, isLoading } = useDossierSelector();

	useEffect(() => {
		dossierId && fetchDossierDsv(dispatch, dossierId)()
	}, [fetchDossierDsv]);

	useEffect(() => {
		if (dossierDSV?.id) {
			setValue([
				{ signatureALD: dossierDSV?.signatureALD ?? dossierDetails?.structure?.signatureALD},
				{ instructionDossierEnCours: dossierDSV.instructionDossierEnCours },
				{ commentaires: dossierDSV.commentaires },
				{ propositionMontant: dossierDSV.propositionMontant }
			]);
		} else {
			setValue([
				{ instructionDossierEnCours: false }
			]);
		}

		const codeSpectacleVivant = dossierDSV?.codeSpectacleVivant ?? dossierDetails?.structure?.codeSpectacleVivant;
		if (codeSpectacleVivant) {
			setValue([{codeSpectacleVivant: codeSpectacleVivant}]);
		}

		const signatureALD = dossierDSV?.signatureALD ?? dossierDetails?.structure?.signatureALD;
		if (signatureALD) {
			setValue([{signatureALD: signatureALD}]);
		}

	}, [dossierDSV, setValue]);



	/** Envoi */
	const send = useCallback(async () => {
		let result = await triggerValidation();
		if (result && dossierId) {
			let body: DossierDSVDto = {
				signatureALD: getValues().signatureALD,
				instructionDossierEnCours: getValues().instructionDossierEnCours,
				codeSpectacleVivant: getValues().codeSpectacleVivant,
				commentaires: getValues().commentaires,
				propositionMontant: parseInt(getValues().propositionMontant?.toString() || "0")
			}

			if (dossierDSV?.id)
				body = {...body, id: dossierDSV.id }

			try{
				sendDossierDsv(dispatch, dossierId, body)();
				toast.success(t('common.success'));
			}catch{
				toast.success('Erreur lors de la mise à jour du droit exclusif.');
			}
		}
	}, [sendDossierDsv, triggerValidation, dispatch, dossierDSV]);

	
	/** Permissions */
	const usePermUpdateDossierDSV = useHasPermission("UpdateDossierDSV");

	return (
		<div className="detailsTab dossierDsv">
			<div className="detailsTab__section">
				<div className="detailsTab__content">
					<Controller control={control}
						name="instructionDossierEnCours"
						as={({ onChange, value, name }) => (<div className="dossierDsv__checkbox">
							<div className="checkbox">
								<label htmlFor="instruction">
									<input disabled={!usePermUpdateDossierDSV} name={name} id="instruction" type="checkbox" checked={getValues().instructionDossierEnCours} onChange={(e) => onChange(e.target.checked)} />
									<div className="newCheckbox"></div>
									Dossier en cours d'instruction : {value}
								</label>
							</div>
							{errors &&
								<ErrorMessage errors={errors} name={name}>
									{({ message }) => <p className="input__errorMessage">{message}</p>}
								</ErrorMessage>
							}
						</div>)}
					/>
					<Controller control={control}
						name="signatureALD"
						as={({ onChange, value, name }) => (<div className="dossierDsv__checkbox">
							<div className="checkbox">
								<label htmlFor="ald">
									<input disabled={!usePermUpdateDossierDSV} name={name} id="ald" type="checkbox" checked={getValues().signatureALD} onChange={(e) => onChange(e.target.checked)} />
									<div className="newCheckbox"></div>
									ALD signé
								</label>
							</div>
							{errors &&
								<ErrorMessage errors={errors} name={name}>
									{({ message }) => <p className="input__errorMessage">{message}</p>}
								</ErrorMessage>
							}
						</div>)}
					/>
				</div>
				<div className="detailsTab__content">
					<Input
						label="Code Spectacle Vivant"
						type="text"
						name="codeSpectacleVivant"
						reference={validator && register(validator["CodeSpectacleVivant"])}
						errors={errors}
						disabled={!usePermUpdateDossierDSV}
					/>
				</div>
				<div className="detailsTab__content">
					<Input
						label="Montant proposé"
						type="number"
						name="propositionMontant"
						reference={validator && register(validator["Montant"])}
						errors={errors}
						disabled={!usePermUpdateDossierDSV}
					/>
				</div>
				<div className="detailsTab__content">
					<div className="input">
						<label className="input__label">Commentaires</label>
					</div>
					<Controller control={control}
						name="commentaires"
						as={({ onChange, value, name }) => (<>
							<textarea
								name={name}
								className="instructionTab__motif"
								onChange={(e) => onChange(e)}
								value={value}
								disabled={!usePermUpdateDossierDSV}
							/>
							{errors &&
								<ErrorMessage errors={errors} name={name}>
									{({ message }) => <p className="input__errorMessage">{message}</p>}
								</ErrorMessage>
							}
						</>)}
						rules={validator && validator["Commentaires"]}
					/>
				</div>
			</div>
			<div className="detailsTab__footer">
				{isLoading
				&& <Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					></Loader>
				}
				{usePermUpdateDossierDSV && <FormButton
					disabled={!isLoading ? false : true}
					type="submit"
					value={"Valider"}
					onClick={send}
				/>}
			</div>
		</div>
	);
}

export default DossierDsvTab;