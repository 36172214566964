import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import PagesContainer from "../../containers/PagesContainer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useCategorieFormeJuridiqueDispatcher, useCategorieFormeJuridiqueSelector } from '../../store/store-helpers';
import { fetchCategoriesFormeJuridique, sendFormesJuridiques, sendCategorie, deleteCategorie } from '../../store/categorie-forme-juridique-store/actions/categoryFormeJuridiqueAction';
import { CategorieFormeJuridiqueDto, FormeJuridiqueDto, UpdateFormeJuridiqueCategorieDto } from '../../services/generated/BackOffice-api';
import Loader from 'react-loader-spinner';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Modal from 'react-modal';
import ModalAttentionModificationEnCours from './ModalAttentionModificationEnCours';
import { modalCustomStyles } from "../../constants/config.constant";

import Input from 'adel-shared/dist/components/basics/Input';

import DocumentAffectationTab from './affectation-tab/DocumentAffectationTab';
import { useNavigationControl } from '../../custom-hooks/useNavigationControl';
import useHasPermission from '../../custom-hooks/useHasPermission';

interface CategorieFormeJuridiqueProps extends RouteComponentProps {
}

var navigationCallback: (() => void) | undefined = undefined;

enum DocumentsAdministratifsTabs {
	CATEGORIES = 0,
	AFFECTATION_DOCUMENTS = 1
}

const CategorieFormeJuridique: React.FC<CategorieFormeJuridiqueProps> = () => {
	const categorieSelector = useCategorieFormeJuridiqueSelector();
	const dispatch = useCategorieFormeJuridiqueDispatcher();

	const [isCategoryTabSelected, setIsCategoryTabSelected] = useState<boolean>(true);
	const [categories, setCategories] = useState<CategorieFormeJuridiqueDto[]>([]);
	const [formesJuridiques, setFormesJuridiques] = useState<FormeJuridiqueDto[]>([]);
	const [formesJuridiquesInitial, setFormesJuridiquesInitial] = useState<FormeJuridiqueDto[]>([]);
	const [isDragOver, setIsDragOver] = useState<boolean>(false);

	const [addCategorieModalOpen, setAddCategorieModalOpen] = useState<boolean>(false);
	const [categorieName, setCategorieName] = useState<string>('');

	const { blockNavigation, allowNavigation, isNavigationAllowed } = useNavigationControl();

	//ajout document
	const [modalAddDocumentIsOpen, setModalAddDocumentIsOpen] = useState<boolean>(false);

	const [modalAttentionModificationEnCoursIsOpen, setModalAttentionModificationEnCoursIsOpen] = useState<boolean>(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
	const usePermGetCategoriesFormeJuridique = useHasPermission("GetCategoriesFormeJuridique");

	// Get all categories
	useEffect(() => {
		if(usePermGetCategoriesFormeJuridique) {
			fetchCategoriesFormeJuridique(dispatch)();
		}
	}, [usePermGetCategoriesFormeJuridique]);

	useEffect(() => {
		if (categorieSelector.categoriesFormeJuridique) {
			setCategories(categorieSelector.categoriesFormeJuridique);

			let array = reinitFormesJuridiquesFromCategories(categorieSelector.categoriesFormeJuridique);

			// Pour comparer après avec ce qu'on reçoit de l'api comme FormeJuridique[]
			setFormesJuridiquesInitial(array);
		}
	}, [categorieSelector.categoriesFormeJuridique]);


	// Add categorie
	const addCategorie = (value: string) => {
		if (value) {
			sendCategorie(dispatch, value)();
			setAddCategorieModalOpen(false);
			setCategorieName('');
		}
	}

	useEffect(() => {
		if (categorieSelector.newCategorie) {
			setCategories([...categories, categorieSelector.newCategorie])
		}
	}, [categorieSelector.newCategorie]);


	// Delete categorie
	const deleteEmptyCategorie = (id?: string) => {
		if (id) {
			deleteCategorie(dispatch, id)();
			let array = categories.filter(cat => cat.id !== id)
			setCategories(array);
		}
	}


	//Drag & drop
	const onDragOver = (ev: any) => {
		ev.preventDefault();
		setIsDragOver(true);
	}

	const onDragStart = (ev: any, id?: string) => {
		if (id) {
			ev.dataTransfer.setData("text/plain", id);
		}
	}

	const onDrop = (ev: any, categorieId?: string) => {
		ev.preventDefault();
		let id = ev.dataTransfer.getData("text");
		let array = [...formesJuridiques];

		categorieId && formesJuridiques && formesJuridiques.filter(fj => {
			if (fj.id === id) {
				setFormesJuridiques([...array.filter(item => item.id !== fj.id),
				{
					id: id,
					categorieId: categorieId,
					nom: fj.nom,
					code: fj.code
				}
				]);
				preventExitWithoutSaving();
			}
		});
		setIsDragOver(false);
	}

	const preventExitWithoutSaving = () => {
		blockNavigation((callback) => {
			setModalAttentionModificationEnCoursIsOpen(true);
			navigationCallback = callback;
		});
	}

	const submitFormesJuridiques = () => {
		const dataToSend = formesJuridiques.map(forme => ({
			formeJuridiqueId: forme.id,
			categorieId: forme.categorieId
		}))
		sendFormesJuridiques(dispatch, dataToSend)();
		allowNavigation();
	}

	useEffect(() => {
		if (categorieSelector.formesJuridiques) {
			setFormesJuridiques(categorieSelector.formesJuridiques);
			setFormesJuridiquesInitial(categorieSelector.formesJuridiques);
		}
	}, [categorieSelector.formesJuridiques]);

	const onTabSelect = (index: number, lastIndex: number, event: Event): boolean | void => {
		if (!isNavigationAllowed()) {
			setModalAttentionModificationEnCoursIsOpen(true);
			navigationCallback = () => {
				navigateToTab(index, lastIndex);
			}
			return false;
		}
		else {
			navigateToTab(index, lastIndex);
			return true;
		}
	};

	const navigateToTab = (index: number, lastIndex: number) => {
		setSelectedTabIndex(index);
		setIsCategoryTabSelected(index !== DocumentsAdministratifsTabs.AFFECTATION_DOCUMENTS);
		if (index === DocumentsAdministratifsTabs.CATEGORIES && index !== lastIndex) {
			if (categorieSelector.categoriesFormeJuridique) {
				reinitFormesJuridiquesFromCategories(categorieSelector.categoriesFormeJuridique);
			}
			fetchCategoriesFormeJuridique(dispatch)();
		}
	}

	const reinitFormesJuridiquesFromCategories = (categories: CategorieFormeJuridiqueDto[]): FormeJuridiqueDto[] => {
		let newFormesJuridiques: FormeJuridiqueDto[] = [];
		categories.forEach(category => {
			if (category.formesJuridiques) newFormesJuridiques = newFormesJuridiques.concat(category.formesJuridiques);
		});
		setFormesJuridiques(newFormesJuridiques);
		return newFormesJuridiques;
	}

	const onWarningModalValidated = () => {
		setModalAttentionModificationEnCoursIsOpen(false);
		allowNavigation();
		if (navigationCallback) {
			navigationCallback();
		} else {
			console.error("No callback was defined. Please contact your dev team.");
		}
	}

	return (
		<PagesContainer title="Administration - Gestion des documents administratifs" pageClass="categoriesFormeJuridique" >
			<Tabs className="react-tabs--page" onSelect={onTabSelect} selectedIndex={selectedTabIndex}>
				<div className="categorieFormeJuridique__header">
					<TabList>
						<Tab>Gestion des catégories</Tab>
						<Tab>Affectation des documents</Tab>
					</TabList>

					{isCategoryTabSelected ?
						<div
							className="categorieFormeJuridique-header__button"
							onClick={() => setAddCategorieModalOpen(true)}
						>
							<label>Ajouter une catégorie</label>
							<button className="categorieFormeJuridique-header__icon">
								<i className="fas fa-plus"></i>
							</button>
						</div>
						:
						<div
							className="categorieFormeJuridique-header__button"
							onClick={() => setModalAddDocumentIsOpen(true)}
						>
							<label>Ajouter un document</label>
							<button className="categorieFormeJuridique-header__icon">
								<i className="fas fa-plus"></i>
							</button>
						</div>
					}
				</div>

				<div className="categorieFormeJuridique__content">
					<TabPanel>
						<div className="categorieFormeJuridique__items">
							{categorieSelector.categoriesIsLoading ?
								<div className="loaderBlock">
									<Loader
										type="TailSpin"
										width={35}
										height={35}
										color="#d93943"
									></Loader>
								</div>
								:
								<>
									{categories?.map((categorie, index) => {
										return (
											<div className="categorieTuile" key={index}>
												<div className="categorieTuile__header">
													<div className="categorieTuile-header__title">
														{categorie.nom}
														{formesJuridiques &&
															formesJuridiques.filter(fj => fj.categorieId === categorie.id).length === 0 &&
															<div className="categorieTuile-header__button" onClick={() => deleteEmptyCategorie(categorie.id)}><i className="fas fa-trash-alt"></i></div>
														}
													</div>
												</div>
												<div className="categorieTuile__body"
													id={categorie.id}
													onDrop={(event) => onDrop(event, categorie.id)}
													onDragOver={(event) => onDragOver(event)}
												>
													{formesJuridiques?.map((fj, i) => {
														return (
															categorie.id === fj.categorieId &&
															<div key={i} className={`categorieTuile__item ${isDragOver ? 'categorieTuile__item--isGrabbed' : ''}`}
																draggable
																onDragStart={(event) => onDragStart(event, fj.id)}
															>
																<span className="categorieTuile__icon">=</span>
																{fj.nom}
															</div>
														)
													})}
													{isDragOver &&
														<div className="categorieTuile__item categorieTuile__item--isDragOver">+</div>
													}
												</div>
											</div>
										)
									})
									}
								</>
							}
						</div>

						<div className="categorieFormeJuridique__footer">
							<FormButton
								disabled={categorieSelector.categoriesIsLoading}
								type="submit"
								value="Valider"
								onClick={submitFormesJuridiques}
							/>
						</div>
						{
							<Modal
								isOpen={addCategorieModalOpen}
								style={modalCustomStyles}
								className="normal__modal"
							>
								<div className="modal__header">
									<h3>Ajouter une catégorie</h3>
								</div>

								<div className="modal__content">
									<div className="modal__item">
										<Input
											name="addCategorie"
											label="Nom de la catégorie"
											type="text"
											onChange={(value: string) => setCategorieName(value)}
										/>
									</div>
								</div>

								<div className="modal__footer">
									<FormButton
										type="button"
										value="Annuler"
										onClick={() => {
											setCategorieName('');
											setAddCategorieModalOpen(false);
										}}
									/>

									<FormButton
										type="submit"
										value="Ajouter"
										onClick={() => addCategorie(categorieName)}
									/>
								</div>
							</Modal>
						}
					</TabPanel>

					<TabPanel>
						<DocumentAffectationTab
							modalAddDocumentIsOpen={modalAddDocumentIsOpen}
							setModalAddDocumentIsOpen={setModalAddDocumentIsOpen}
							preventExitWithoutSaving={preventExitWithoutSaving}
							categoriesList={categories}
						/>
					</TabPanel>
				</div>
				{
					<ModalAttentionModificationEnCours
						isOpen={modalAttentionModificationEnCoursIsOpen}
						close={() => setModalAttentionModificationEnCoursIsOpen(false)}
						onValidate={onWarningModalValidated}
					/>
				}
			</Tabs>
		</PagesContainer>
	);
}

export default CategorieFormeJuridique;