import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import Toggle from 'adel-shared/dist/components/Toggle';
import Input from 'adel-shared/dist/components/basics/Input';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { ObjetDiscussion, VueDto, VueType } from '../../../services/generated/BackOffice-api';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useUserSelector, useVueDispatcher, useVueSelector } from '../../../store/store-helpers';
import { deleteVue, fetchVues, saveVue } from '../../../store/vue-store/actions/vueAction';
import { modalCustomStyles } from "../../../constants/config.constant";
import WysiwygEditor from "adel-shared/dist/components/WysiwygEditor";
import { sendDossierMultipleMessages } from '../../../store/dossier-store/actions/dossierMessagesActions';
import {
	blocageJuridiqueFilterKey,
	canVersementFilterKey,
	chargeInstructionNomFilterKey,
	chargeInstructionPrenomFilterKey,
	dateCommissionFilterKey,
	dateSoumissionMinFilterKey,
	dateSoumissionMaxFilterKey,
	dsvFilterKey,
	enCoursValidationDSVFilterKey,
	modifiableParStructureFilterKey,
	nomFilterKey,
	numeroFilterKey,
	responsableNomFilterKey,
	responsablePrenomFilterKey,
	statutFilterKey,
	structureFilterKey,
	typeFilterKey,
	typeVersementFilterKey,
	valideParDSVFilterKey,
	villeFilterKey,
	alerteVerteFilterKey,
	alerteRougeFilterKey,
	ribValideFilterKey,
	ribModifiableFilterKey,
	blocageAvisFilterKey,
	filtersDefault
} from "../../../constants/filters.constant";
import { toast } from 'react-toastify';
import {IDossierFilters} from '../DossiersListe';
import {getSerializedFilters} from '../DossiersListe';

interface DossierVuesProps {
	setFilters: (value: any) => void;
	isLoading?: boolean;
    filters: IDossierFilters;
}

const DossierVues: React.FC<DossierVuesProps> = ({ setFilters, isLoading, filters }) => {
	const { isConnected: isUserConnected } = useUserSelector();
	const [vues, setVues] = useState<VueDto[]>([]);
	const { t } = useTranslation();
	const [vueName, setVueName] = useState<string>("");
	const [vueEstPartagee, setVueEstPartagee] = useState<boolean>(false);
	const saveVueOptionDefault = [
		{
			value: "Enregistrer",
			label: "Enregistrer la vue courante",
			icon: "far fa-plus",
			button: <></>,
			hasClass: "inputSelect__option--red"
		},
		{
			value: "default",
			label: "Vue par défaut"
		}
	];
	const [vuesOptions, setVuesOptions] = useState<AdelOption<string>[]>(saveVueOptionDefault);
	const [vueSelected, setVueSelected] = useState<string>("default");
	const [vueToDeleteSelected, setVueToDeleteSelected] = useState<VueDto>();

	const {vueIsLoading, vueDeleteIsLoading} = useVueSelector();
	const dispatch = useDispatch();
	const vueDispatch = useVueDispatcher();


	/** Get the vues list */
	useEffect(() => {
		if (isUserConnected) {
			fetchVues(vueDispatch, VueType.Dossier)().then(result => {
				setVues(result.payload);
				vueOptionsList(result.payload);
			});
		}
	}, [isUserConnected]);

	const vueOptionsList = (vues: VueDto[]) => {
		const options = saveVueOptionDefault;
		if (vues?.length > 0) {
			vues.map(vue =>
				options.push(
					{
						value: vue.filtre ? vue.filtre : '',
						label: vue.nom ? vue.nom : '',
						icon: vue.estPartagee ? "fas fa-share" : '',
						button: <div className="filtres__inputSelectButtons">
							<button onClick={() => { handleMessageModalOpener(vue) }}>
								<i className="far fa-envelope"></i>
							</button>
							<button onClick={() => { handleDeleteVueModalOpener(vue) }}>
								<i className="far fa-trash-alt"></i>
							</button>
						</div>,
						hasClass: '',
					}
				)
			);
		}
		setVuesOptions(options);
	};

	/** Modal Vue */
	const [modalVueIsOpen, setModalVueIsOpen] = useState<boolean>(false);
	const [modalDeleteVueIsOpen, setModalDeleteVueIsOpen] = useState<boolean>(false);
	const [modalMessageVueIsOpen, setModalMessageVueIsOpen] = useState<boolean>(false);

	const resetFilterFields = () => {
		setFilters({type: 'reset', payload: filtersDefault});
	}

	// Ajouter une vue
	const handleSendVue = () => {
		if (vueName) {
			const vueToSend: VueDto = {
				type: VueType.Dossier,
				nom: vueName,
				estPartagee: vueEstPartagee,
				filtre: getSerializedFilters({
					...filters
				})
			};

			saveVue(vueDispatch, vueToSend)().then(result => {
				const vuesArray = vues;
				vuesArray.push(result?.payload);

				setVues(vuesArray);
				vueOptionsList(vuesArray);
				setVueSelected(result.payload.filtre);
				setVueEstPartagee(false);
			});

			if (!vueIsLoading) {
				setModalVueIsOpen(false);
			}
		}
	};

	// Supprimer une vue
	const handleDeleteVueModalOpener = useCallback((selectedVue) => {
		if (selectedVue) {
			setVueToDeleteSelected(selectedVue);
			setModalDeleteVueIsOpen(true);
		}
	}, []);

	const handleDeleteVue = () => {
		deleteVue(vueDispatch, vueToDeleteSelected?.id ? vueToDeleteSelected?.id : '')().then(() => {
			const newVueArray = vues.filter(vue => vue.id !== vueToDeleteSelected?.id)
			setVues(newVueArray);
			vueOptionsList(newVueArray);
			setVueSelected("default");
			resetFilterFields();
			setModalDeleteVueIsOpen(false);
		});
	};

	// Envoyer un message groupé
	const handleMessageModalOpener = useCallback((selectedVue) => {
		if (selectedVue) {
			setVueSelected(selectedVue);
			setModalMessageVueIsOpen(true);
		}
	}, []);

	const resetMessageModal = () => {
		setModalMessageVueIsOpen(false);
		setEditorValueContent('');
		setFiles([]);
	}

	const handleSendMessage = async () => {
		try {
			await sendDossierMultipleMessages(
				dispatch,
				editorValueContent ? editorValueContent : "<p></p>",
				files.map(file => ({
					data: file,
					fileName: file.name
				})),
				Object.assign(filters)
			);

		} catch(error) {
			toast.error("Une erreur est survenue à l'envoi du message");
		}
		resetMessageModal();
	}

	const [editorValueContent, setEditorValueContent] = useState<string>('');
	const [files, setFiles] = useState<Array<File>>([]);
	const [messageObjectSelect, setMessageObjectSelect] = useState<ObjetDiscussion>(ObjetDiscussion.InformationGeneral);
	const optionsUtilisateur = () => {
		const allOptions = Object.keys(ObjetDiscussion).filter(x => x !== 'None').map(x => ({
			value: ObjetDiscussion[x as keyof typeof ObjetDiscussion],
			label: t(`objetDiscussion.${x}`)
		}));
		return allOptions;
	}
	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
		if (value && value.target && value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
			setFiles([...files, value.target.files[0]]);
		}
	}
	const deleteFile = (file: File) => {
		if (files.length > 0) {
			let array = files.filter(f => f.name !== file.name);
			return setFiles(array);
		}
	}


	const filtersArray = [
		blocageJuridiqueFilterKey,
		canVersementFilterKey,
		chargeInstructionNomFilterKey,
		chargeInstructionPrenomFilterKey,
		dateCommissionFilterKey,
		dateSoumissionMinFilterKey,
		dateSoumissionMaxFilterKey,
		dsvFilterKey,
		enCoursValidationDSVFilterKey,
		modifiableParStructureFilterKey,
		nomFilterKey,
		numeroFilterKey,
		responsableNomFilterKey,
		responsablePrenomFilterKey,
		statutFilterKey,
		structureFilterKey,
		typeFilterKey,
		typeVersementFilterKey,
		valideParDSVFilterKey,
		villeFilterKey,
		alerteVerteFilterKey,
		alerteRougeFilterKey,
		ribValideFilterKey,
		ribModifiableFilterKey,
		blocageAvisFilterKey
	]
	// Modifier les champs quand on a sélectionné un filtre
	const handleInputSelectChange = (selectedOption: any) => {
		setVueSelected(selectedOption);
		if (selectedOption === "Enregistrer") {
			setModalVueIsOpen(true);
		} else if (selectedOption === "default") {
			resetFilterFields();
		} else {
			const filters = selectedOption.split(',');
			resetFilterFields();

			filters.map((filter: string) => {
				filtersArray.forEach(f => {
					if(filter.match(new RegExp(`^${f}`))) {

						const result = (f === statutFilterKey ||
								f === typeFilterKey ||
								f === dateCommissionFilterKey ||
								f === dateSoumissionMinFilterKey ||
								f === dateSoumissionMaxFilterKey) ||
								f === typeVersementFilterKey
							? filter.substring(f.length).split('|')
							: filter.substring(f.length)

						setFilters({
							type: 'update',
							payload: {
								[f]: result
							}
						});
					}
				});
			});
		}
	};

	return (<>
		<InputSelect
			name="vueSelect"
			classname="filtres__inputSelect inputSelect--large inputSelect--right"
			options={vuesOptions}
			onChange={handleInputSelectChange}
			value={vueSelected}
		/>

		<Modal isOpen={modalVueIsOpen} style={modalCustomStyles} className="normal__modal" >
			<div className="modal__header">
				<h3>Enregistrer la vue courante</h3>
			</div>
			<div className="modal__content">
				<div className="modal__item">
					<Input
						name="newVue"
						label="Nom de ma nouvelle vue"
						type="text"
						onChange={(value: string) => setVueName(value)}
					/>
				</div>
				<div className="modal__item">
					<Toggle
						label="Partager ma vue"
						value={vueEstPartagee}
						setCheck={setVueEstPartagee}
					/>
				</div>
			</div>
			<div className="modal__footer">
				<FormButton
					type="button"
					value="Annuler"
					onClick={() => { setModalVueIsOpen(false) }}
				/>
				{vueIsLoading ? (
					<Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					/>
				) : (
					<FormButton
						type="submit"
						value="Enregistrer"
						onClick={handleSendVue}
					/>
				)}
			</div>
		</Modal>

		<Modal isOpen={modalDeleteVueIsOpen} style={modalCustomStyles} className="normal__modal">
			<div className="modal__header">
				<h3>Supprimer la vue</h3>
			</div>
			<div className="modal__content">
				<div className="modal__item">
					Voulez-vous vraiment supprimer la vue "{vueToDeleteSelected?.nom}" ?
				</div>
			</div>
			<div className="modal__footer">
				<FormButton
					type="button"
					value="Annuler"
					onClick={() => { setModalDeleteVueIsOpen(false) }}
				/>
				{vueDeleteIsLoading ? (
					<Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					/>
				) : (
					<FormButton
						type="submit"
						value="Supprimer"
						onClick={handleDeleteVue}
					/>
				)}
			</div>
		</Modal>

		<Modal isOpen={modalMessageVueIsOpen} style={modalCustomStyles} className="normal__modal modal--large">
			<div className="modal__header">
				<h3>Démarrer une discussion de masse</h3>
			</div>
			<div className="modal__content">
				<div className="modal__item modal__itemSelect">
					<InputSelect name="" classname="inputSelect" label="Type d'objet"
						readonly={true}
						options={optionsUtilisateur()}
						onChange={(elem) => { setMessageObjectSelect(elem) }}
						value={messageObjectSelect}
					/>
				</div>
				<div className="modal__item">
					<WysiwygEditor
						editorValueContent={editorValueContent}
						setEditorValueContent={setEditorValueContent}
					/>
				</div>
				<div className="modal__item item--flexBetween">
					{files && files.length > 0
						? <div className="modal__itemFiles">
							<label>Pièces jointes :</label>
							{files.map((file, i) => (
								<span key={i}>
									{file.name}
									<i className="far fa-trash-alt" onClick={() => deleteFile(file)}></i>
									{files.length === 1 || files.indexOf(file) === files.length - 1 ? '' : ', '}
								</span>
							))}
						</div>
						: <div></div>
					}
					<div className="inputFile__singleButton">
						<label htmlFor="uploadDocument"><i className="fas fa-paperclip"></i></label>
						<input id="uploadDocument" type="file" onChange={handleFileChange} />
					</div>
				</div>
			</div>
			<div className="modal__footer">
				<FormButton
					type="button"
					value="Annuler"
					onClick={resetMessageModal}
				/>
				{isLoading ? (
					<Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					/>
				) : (
					<FormButton
						type="submit"
						value="Envoyer"
						onClick={handleSendMessage}
					/>
				)}
			</div>
		</Modal>
	</>);
}

export default DossierVues;
