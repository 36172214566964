import React, { useState, useCallback, useEffect, ChangeEvent } from "react";

interface MembreCheckboxTabProps {
	checked: boolean;
	idMembre: string;
	index: number;
	onCheck: (tableId: string, membreId: string, isChecked: boolean) => void;
	tableId: string;
	disabled?: boolean;
}

const MembreCheckboxTab: React.FunctionComponent<MembreCheckboxTabProps> = ({
	idMembre,
	checked,
	index,
	onCheck,
	tableId,
	disabled
}) => {
	const id = `${index}-${idMembre}`;
	const [isChecked, setIsChecked] = useState<boolean>(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);
		onCheck(tableId, idMembre, e.target.checked);
	}, [setIsChecked, onCheck, tableId, idMembre]);
 
	return (
		<div className="checkbox table__check">
			<label htmlFor={id}>
				<input
					name={id} 
					id={id} 
					type="checkbox"
					checked={isChecked}
					disabled={disabled}
					onChange={onChange}
				/>
				<div className="newCheckbox"></div>
			</label>
		</div>
	);
};

export default MembreCheckboxTab;
