import { Reducer } from "redux";
import { CritereDossierState, initialCritereDossier } from "../states/critereDossierState";
import { DispatchCritereDossierAction, CritereDossierActionsEnum } from "../actions/critereDossierAction";

export const criteresDossierReducer: Reducer<Partial<CritereDossierState>, DispatchCritereDossierAction> = (state = initialCritereDossier, action) => {
	switch (action.type) {
		case CritereDossierActionsEnum.GET_CRITERES_REQUEST:
			return {
				...state,
				categoriesIsLoading: true
			};
		case CritereDossierActionsEnum.GET_CRITERES_SUCCESS:
			return {
				...state,
				criteres: action.payload.criteres,
				criteresIsLoading: false
			};
		case CritereDossierActionsEnum.GET_CRITERE_REQUEST:
			return {
				...state,
				categorieIsLoading: true
			};
		case CritereDossierActionsEnum.GET_CRITERE_SUCCESS:
			return {
				...state,
				critere: action.payload.critere,
				critereIsLoading: false
			};
		case CritereDossierActionsEnum.SEND_CRITERE_REQUEST:
			return {
				...state
			}
		case CritereDossierActionsEnum.SEND_CRITERE_SUCCESS: {
			return {
				...state,
				critere: action.payload.critere
			}
		}
		default:
			return state;
	}
};
