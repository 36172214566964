import React, { FunctionComponent, useEffect, useState } from 'react';
import PagesContainer from '../../containers/PagesContainer';
import { ClotureBordereauVersementDto, CompteBancaireDto, StatutBordereau } from '../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import BordereauxTabs from './BordereauxTabs';
import {
    archiveBordereau,
    clotureBordereauVersement,
    getBordereauBlocageVersement,
    getBordereauVersement,
    getBordereauVersementAsExcel,
    getBordereauVersementAsPDF,
    getBordereauVersementAsTxt,
    getExportSEPA,
    removeBlockedVersement,
    soumissionSignatureBordereau
} from '../../store/bordereau-store/actions/bordereauActions';
import { useDispatch } from 'react-redux';
import { useBordereauSelector } from '../../store/store-helpers';
import { useTranslation } from 'react-i18next';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Modal from 'react-modal';
import { formatNumber, normalizeDate } from 'adel-shared/dist/utils/functions';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { modalCustomStyles } from '../../constants/config.constant';
import { useForm } from 'react-hook-form';
import { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { getAllComptesBancaires } from '../../store/compte-bancaire-store/actions/compteBancaireAction';
import { getTotalMouvements } from './BordereauUtils';
import { downloadFile, downloadFileFromDataURI } from '../../utils/functions';

interface BordereauVersementProps {
    bordereauId: string;
}

const BordereauVersement: FunctionComponent<BordereauVersementProps> = ({
    bordereauId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { bordereauVersement = {} } = useBordereauSelector();
	const [isModalClotureOpen, openModalCloture, closeModalCloture] = useBooleanState(false);
    const [comptesBancaireOptions, setComptesBancaireOptions] = useState<AdelOption<CompteBancaireDto['id']>[]>([]);
    const form = useForm<ClotureBordereauVersementDto>({
        defaultValues: {
            dateReglement: new Date(),
        }
    });

    /** React table config */
	const columns = React.useMemo(() => {
        if(bordereauVersement.statutBordereau === StatutBordereau.Verse) {
            return  [
                {
                    Header: "Références bancaires",
                    accessor: "iban",
                    sortType: "basic"
                },
                {
                    Header: "Bénéficiaire",
                    accessor: "beneficiaire",
                    sortType: "basic"
                },
                {
                    Header: "Code structure",
                    accessor: "userName",
                    sortType: "basic"
                },
                {
                    Header: "Dossier",
                    accessor: "dossierNom",
                    sortType: "basic"
                },
                {
                    Header: "RIB",
                    accessor: "validIBAN",
                    sortType: "basic"
                },
                {
                    Header: "Montant",
                    accessor: "montant",
                    sortType: "basic",
					className:'table__cell--montants'
                }
            ];
        }

		return [
            {
                Header: "Numéro de dossier",
                accessor: "dossierNumero",
                sortType: "basic"
            },
            {
                Header: "Dossier",
                accessor: "dossierNom",
                sortType: "basic"
            },
            {
                Header: "Bénéficiaire",
                accessor: "beneficiaire",
                sortType: "basic"
            },
            {
                Header: "Code structure",
                accessor: "userName",
                sortType: "basic"
            },
            {
                Header: "Type de mouvement",
                accessor: "typeMouvement",
                sortType: "basic"
            },
            {
                Header: "RIB",
                accessor: "validIBAN",
                sortType: "basic"
            },
            {
                Header: "Montant",
                accessor: "montant",
                sortType: "basic",
				className:'table__cell--montants'
            }
        ]
	}, [bordereauVersement.statutBordereau]);

    useEffect(() => {
        (async() => {
            await getBordereauVersement(bordereauId, dispatch);

            const comptesBancaires = await getAllComptesBancaires();
            setComptesBancaireOptions(
                comptesBancaires.map(x => ({
                    label: x.banque || '',
                    value: x.id || ''
                }))
            );
        })();
    }, []);

    const handleSignatureClick = async() => {
        if(!bordereauVersement.id) return;

        try {
            await soumissionSignatureBordereau(dispatch, bordereauVersement.id);
            toast.success('Le bordereau a été soumis à la signature.');
        } catch(error) {
            if(error.code === 'invalidBordereauStatus') {
                toast.error(`Un bordereau en statut ${bordereauVersement.statutBordereau} ne peut être passé en amendable.`);
            } else if(error.code === 'bordereauAmendableMaxExceeded') {
                toast.error('Un bordereau amendable existe déjà.');
            } else {
                toast.error('Une erreur est survenue durant la signature.');
            }
        }
    };

    const handleCloture = async() => {
        try {
            const { compteBancaireId, dateReglement } = form.getValues();
            const body: ClotureBordereauVersementDto = { compteBancaireId };
            if(dateReglement) {
                body.dateReglement = normalizeDate(dateReglement);
            }

            if(bordereauVersement?.id) {
                await clotureBordereauVersement(bordereauVersement.id, body);
				await getBordereauVersement(bordereauId, dispatch);
                toast.success('Le bordereau a été clôturé avec succès.');
            }
        } catch(error) {
            if(error.code === 'cannotCloseBordereau')
                toast.error(error.exception.message);
			else
                toast.error('Une erreur s\'est produite pendant la clôture du bordereau.');
        }
    };

    const handleClotureClick = async() => {
        if(!bordereauVersement.id) return;

        const { compteBancaireId, dateReglement } = form.getValues();
        const body: ClotureBordereauVersementDto = { compteBancaireId };
        if(dateReglement) {
            body.dateReglement = normalizeDate(dateReglement);
        }

        const blockedVersement = await getBordereauBlocageVersement(dispatch, bordereauVersement.id);

        if(blockedVersement) {
            openModalCloture();
        } else {
            handleCloture();
        }
    };

    const handleRemoveBlockedVersements = async() => {
        if(!bordereauVersement.id) return;
        await removeBlockedVersement(dispatch, bordereauVersement.id);
        await handleCloture();
        closeModalCloture();
    };

    const handleArchive = async() => {
        if(!bordereauVersement.id) return;
        try {
            await archiveBordereau(dispatch, bordereauVersement.id);
            toast.success('Le bordereau a bien été archivé');
        } catch(error) {
            toast.error('Impossible d\'archiver le bordereau.');
        }
    };

    const handleExport = async() => {
        if(!bordereauVersement.id) return;
        try {
            const result = await getBordereauVersementAsExcel(bordereauVersement.id);
            downloadFileFromDataURI(result);
        } catch {
            toast.error('Une erreur est survenue pendant le téléchargement de l\'export.');
        }
    };

	const handleExportSepa = async() => {
        if(!bordereauVersement.id) return;
        try {
            const result = await getExportSEPA(bordereauVersement.id);
            downloadFile(result, false);
        } catch {
            toast.error("Une erreur est survenue lors de la récupération de l'export SEPA");
        }
    };

	const handleExportBordereauTxt = async() => {
        if(!bordereauVersement.id) return;
        try {
            const result = await getBordereauVersementAsTxt(bordereauVersement.id);
            downloadFile(result);
        } catch {
            toast.error("Une erreur est survenue lors de la récupération du bordereau de versement en .txt");
        }
    };

	const handleExportBordereauPdf = async() => {
        if(!bordereauVersement.id) return;
        try {
            const result = await getBordereauVersementAsPDF(bordereauVersement.id);
            downloadFile(result);
        } catch {
            toast.error("Une erreur est survenue lors de la récupération du bordereau de versement en .pdf")
        }
    };

    return (
        <PagesContainer
            title={`Comptabilité - Gestion du bordereau de versement ${bordereauVersement.statutBordereau ? t(`bordereau.statut.${bordereauVersement?.statutBordereau}`) : ''}`}
            additionalHeaderElem={
                <>
                    <div className="bordereau-number">
                        Numéro du bordereau : {bordereauVersement.numero}
                    </div>
                    <div className="total-amount">
                      
                        Montant total actuel :{(Math.round(getTotalMouvements(bordereauVersement.mouvements) * 100) / 100).toString().replace('.', ',')}€


                    </div>
                </>
            }
            pageClass="bordereaux-versement"
        >
            <>
                <BordereauxTabs
                    bordereau={bordereauVersement}
                    tableColumns={columns}
                    form={form}
                    comptesBancaireOptions={comptesBancaireOptions}
                    onCloture={handleClotureClick}
                    onSignature={handleSignatureClick}
                    onArchive={handleArchive}
                    onExport={handleExport}
					onExportSepa={handleExportSepa}
					onExportBordereauPdf={handleExportBordereauPdf}
					onExportBordereauTxt={handleExportBordereauTxt}
                />

                <Modal isOpen={isModalClotureOpen} style={modalCustomStyles} className="normal__modal">
                    <div className="modal__header">
                        <h3>Clôturer le bordereau</h3>
                    </div>
                    <div className="modal__content">
                        <div className="modal__item">
                            <p>Des versements sont actuellement bloqués.</p>
                            <p>Voulez-vous supprimer ces versements du bordereau ?</p>
                        </div>
                    </div>
                    <div className="modal__footer">
                        <FormButton type="button" value="Annuler" onClick={closeModalCloture} />
                        <FormButton type="submit" value="Supprimer" onClick={handleRemoveBlockedVersements} />
                    </div>
                </Modal>
            </>
        </PagesContainer>
    )
};

export default BordereauVersement;