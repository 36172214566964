import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { CellProps, CellValue, useRowSelect, useSortBy, useTable } from 'react-table';
import { DocumentValidationDto, DocumentWithValidationDto, TypeDeDocumentDto } from '../../services/generated/BackOffice-api';
import CheckboxTable from '../basics/CheckboxTable';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import ModalCommentaire from './ModalCommentaire';
import InputFileSingle, { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import { DocumentValidationDtoExtends } from '../structures/details-tab/StructureDetailsTab';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface DocumentValidationEditableProps {
	documents?: DocumentWithValidationDto[];
	onlyName?: boolean;
	documentsToSend: DocumentValidationDtoExtends[]
	setDocumentsToSend: (value:DocumentValidationDtoExtends[]) => void;
	canUpdateDocumentsValidation: boolean;
	IsRequired?: boolean
}

interface DocumentValidationTableData {
	Type?: TypeDeDocumentDto;
	Date?: string;
	Nom?: string;
	Validee?: boolean;
	Commentaires?: string;
	Id?: string;
	Uri?: string;
	IsRequired?: boolean
}

const DocumentValidationEditable: FunctionComponent<DocumentValidationEditableProps> = ({
	documents,
	documentsToSend,
	setDocumentsToSend,
	onlyName = false,
	canUpdateDocumentsValidation,
}) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<DocumentValidationTableData[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [idDoc, setIdDoc] = useState<string>();
    const [commDoc, setCommDoc] = useState<string>("");
	const tableId = useRef<string>(uniqueId());

	const handleOpenModal = useCallback((id: string, value:string) => {
		const comm = documentsToSend.find(e => e.id === id)?.commentaires;
		setIdDoc(id);
		if (comm)
			setCommDoc(comm);
		else
			setCommDoc(value);
		setOpenModal(true);
	}, [documentsToSend, setOpenModal, setIdDoc, setCommDoc]);

	const handleComment = useCallback((id?:string, value?:string) => {
		if (documentsToSend.find(e => e.id === id)) {
			const entry = documentsToSend.find(e => e.id === id);
			const newItem: DocumentValidationDto[] = [{
				...entry,
				commentaires: value
			}];
			setDocumentsToSend(documentsToSend.map(e => newItem.find(item => item?.id === e.id) || e));
		} else {
			setDocumentsToSend([...documentsToSend, {
				id: id,
				commentaires: value
			}]);
		}
	}, [setDocumentsToSend, documentsToSend]);

	const handleCheck = useCallback((id?:string, value?:boolean, all?: boolean) => {
		const ref = [...documentsToSend];

		if(all) {
			setDocumentsToSend(documentsToSend.map(x => ({
				...x,
				validee: value
			})));
		} else {
			if (ref.find(e => e.id === id)) {
				const entry = ref.find(e => e.id === id);
				const newItem: DocumentValidationDto[] = [{
					...entry,
					validee: value
				}]
				setDocumentsToSend(ref.map(e => newItem.find(item => item?.id === e.id) || e));
			} else {
				setDocumentsToSend([...documentsToSend, {
					id,
					validee: value
				}]);
			}
		}
	}, [setDocumentsToSend, documentsToSend]);

	const handleFileChange = (document: DocumentValidationDtoExtends, file?: DocumentWithFile) => {
		const docs = [...documentsToSend];
		const index = docs.findIndex(d => d.id === document.id);
		const newData = [...data];
		const indexData = newData.findIndex(d => d.Id === document.id);

		if(file) {
			docs[index].fileName = file.fileName;
			docs[index].partname = docs[index].type?.id;
			docs[index].file = file.file;
			setDocumentsToSend(docs);
			newData[indexData].Nom = file.fileName;
			setData(newData);
		} else {
			docs[index].fileName = undefined;
			docs[index].uri = undefined;
			docs[index].id = undefined;
			docs[index].validee = false;
			docs[index].commentaires = undefined;
			setDocumentsToSend(docs);
			newData[indexData].Nom = undefined;
			newData[indexData].Uri = undefined;
			newData[indexData].Id = undefined;
			newData[indexData].Validee = false;
			newData[indexData].Commentaires = '';
			setData(newData);
		}
	};


	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Type document",
						accessor: "Type",
						disableSortBy: true,
						Cell: ({value: {type, extraInfo}}: {value: CellValue<DocumentValidationDtoExtends>}) => {
							if(type?.isChoix) {
								return (
									<>
										<p>{type?.nom?.[i18n.language]}</p>
										{(type.extraInfos && type.extraInfos.length > 0) && (
											<ul className="table__list">
												{type.extraInfos.map((x, i) => {
													const info = extraInfo?.find(y => y.code === x.code);
													let value = info?.value;

													if(info?.valueType === 'date') {
														value = formaterDate(value);
													}

													return (
														<li key={i}>
															{x.nom?.[i18n.language]} : {value || '-'}
														</li>
													)}
												)}
											</ul>
										)}
									</>
								);
							}

							if(type?.nom?.[i18n.language]) {
								return type.nom[i18n.language];
							}

							if(type?.code && i18n.exists(`documents.${type.code}`)) {
								return t(`documents.${type.code}`)
							}

							return '';
						}
					},
					{
						Header: "Date",
						accessor: "Date",
						disableSortBy: true,
						Cell: ({value}: {value: CellValue<DocumentValidationTableData['Date']>}) => (
							<span>{value && formaterDate(new Date(value))}</span>
						)
					},
					{
						Header: "Nom",
						accessor: "Nom",
						disableSortBy: true,
						Cell: (value: CellProps<DocumentValidationTableData, DocumentValidationTableData['Nom']>) => {
							if(!value.cell.row.values.HasAttachment)
								return <span></span>
							return <InputFileSingle
								labelButton="Télécharger"
								onChange={(e?:DocumentWithFile) => handleFileChange(value.cell.row.values.Type, e)}
								isRequired={false}
								currentValue={
									value.value
									? {
										id: value.cell.row.values.Id,
										fileName: value.value,
										url: value.cell.row.values.Uri
									}
									: undefined
							}/>
						}
					},
					{
						Header: ({ getToggleAllRowsSelectedProps }: any) => {
							const props = getToggleAllRowsSelectedProps();
							return (
								<div className="table__select-all">
									<CheckboxTable
										id={`select-all-${tableId.current}`}
										indeterminate={props.indeterminate}
										onCheck={(id: string, check: boolean, e: ChangeEvent<HTMLInputElement>) => {
											props.onChange(e);
											handleCheck(id, check, true);
										}}
										defaultCheck={props.checked || false}
									/>
									<span>Valider PJ</span>
								</div>
							)
						},
						accessor: "Validee",
						disableSortBy: true,
						Cell: (value: any) => {
							const props = value.cell.row.getToggleRowSelectedProps();
							return (
								<CheckboxTable
									id={value.cell.row.values.Id}
									onCheck={(id: string, check: boolean, e: ChangeEvent<HTMLInputElement>) => {
										props.onChange(e);
										handleCheck(id, check);
									}}
									defaultCheck={documentsToSend.find(e => e.id === value.cell.row.values.Id)?.validee || props.checked}
								/>
						)}
					},
					{
						Header: "Commentaires",
						accessor: "Commentaires",
						disableSortBy: true,
						Cell: (value: CellProps<DocumentValidationTableData, DocumentValidationTableData['Commentaires']>) => {
							const hasComment = documentsToSend.find(e => e.id === value.cell.row.values.Id)?.commentaires;
							return (
								<span
									className={clsx("commentIcon", {"commentIcon--hasValue": hasComment})}
									onClick={() => handleOpenModal(value.cell.row.values.Id, value.value || '')}
								>
									<i
										className={clsx({
												"far": !hasComment,
												"fas": hasComment
											},
											"fa-message"
										)}
									></i>
								</span>
						)}
					},
					{ accessor: "Id" },
					{ accessor: "Uri" },
					{ accessor: "HasAttachment" }
				]
			}
		];
	}, [documentsToSend, canUpdateDocumentsValidation]);

	useEffect(() => {
		if (documents?.length) {
			const docModified:DocumentValidationDtoExtends[] = documents.map(d => {
				const doc:DocumentValidationDtoExtends = d;
				doc.extraInfos = d.extraInfo?.reduce((acc:any, val) => { /** acc: type 'extraInfos?: { [key: string]: any; }' */
					if(val.code)
						acc[val.code] = val.value;
					return acc; 
				}, {});
				return doc;
			});
			setDocumentsToSend(docModified);
			setData(documents.map(doc => ({
				Type: doc,
				Date: doc.extraInfo && doc.extraInfo.length > 0 && doc.extraInfo[0]?.valueType === "date" && doc.extraInfo[0]?.value,
				Nom: doc.fileName,
				Validee: doc.validee,
				Commentaires: doc.commentaires,
				Id: doc.id,
				Uri: doc.uri,
				HasAttachment: doc.type?.hasAttachment
			})));
		}
	}, [documents]);

	const hiddenCols = () => {
		let array = ["Id", "Uri"];
		if(onlyName)
			array.push("Date", "Type");
		if(!canUpdateDocumentsValidation)
			array.push("Validee", "Commentaires");
		return array;
	}

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: hiddenCols()
			}
		},
		useSortBy,
		useRowSelect
    );

    return (
        <>
			<Table
				className="table--documents"
                data={data}
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
                styles={styleTable.onglet}
            />
			<ModalCommentaire
				id={idDoc}
				setOpenModal={setOpenModal}
				openModal={openModal}
				setCommentToSend={handleComment}
				defaultValue={commDoc}
			/>
        </>
    );
};

export default DocumentValidationEditable;