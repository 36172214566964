import { Reducer } from "redux";
import { CommissionMembreActionsEnum, DispatchCommissionMembreAction } from '../actions/commissionMembreActions';
import { CommissionMembreState, initialCommissionMembreState } from '../states/commissionMembreState';

export const commissionMembreReducer: Reducer<Partial<CommissionMembreState>, DispatchCommissionMembreAction> = (state = initialCommissionMembreState, action) => {
	switch (action.type) {
		/** Commissions membre listing */
		case CommissionMembreActionsEnum.GET_MEMBRES_COMMISSIONS_REQUEST:
			return { ...state, isLoading: true };
		case CommissionMembreActionsEnum.GET_MEMBRES_COMMISSIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				commissionMembres: action.payload.commissionMembres
			};
		case CommissionMembreActionsEnum.GET_MEMBRES_COMMISSIONS_ERROR:
			return { ...state, isLoading: false };

		/** Commissions membre création */
		case CommissionMembreActionsEnum.CREATE_MEMBRE_COMMISSION_REQUEST:
			return { ...state, isCreateLoading: true };
		case CommissionMembreActionsEnum.CREATE_MEMBRE_COMMISSION_SUCCESS:
			return {
				...state,
				isCreateLoading: false,
				commissionMembre: action.payload.commissionMembre
			};
		case CommissionMembreActionsEnum.CREATE_MEMBRE_COMMISSION_ERROR:
			return { ...state, isCreateLoading: false };

		/** Commissions membre */
		case CommissionMembreActionsEnum.GET_MEMBRE_COMMISSION_REQUEST:
			return { ...state, isLoading: true };
		case CommissionMembreActionsEnum.GET_MEMBRE_COMMISSION_SUCCESS:
			return {
				...state,
				isLoading: false,
				commissionMembre: action.payload.commissionMembre
			};
		case CommissionMembreActionsEnum.GET_MEMBRE_COMMISSION_ERROR:
			return { ...state, isLoading: false };

		/** Commissions membre update */
		case CommissionMembreActionsEnum.PUT_MEMBRE_COMMISSION_REQUEST:
			return { ...state, isLoading: true };
		case CommissionMembreActionsEnum.PUT_MEMBRE_COMMISSION_SUCCESS:
			return {
				...state,
				isLoading: false,
				commissionMembre: action.payload.commissionMembre
			};
		case CommissionMembreActionsEnum.PUT_MEMBRE_COMMISSION_ERROR:
			return { ...state, isLoading: false };

		default:
			return state;
	}
};