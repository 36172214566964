import { Reducer } from "redux";
import { DiscussionDto, DocumentValidationDto, UpdateDossierNoteDto } from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from '../actions/dossierAction';
import { DispatchDossierAction } from '../actions/dossierAction';
import { DossierState, initialDossierState } from '../states/dossierState';

//@ts-ignore
export const dossierReducer: Reducer<Partial<DossierState>, DispatchDossierAction> = (state = initialDossierState, action) => {
	switch (action.type) {
		case DossierActionsEnum.GET_DOSSIER_REQUEST:
			return { ...state, isLoading: true };
			case DossierActionsEnum.CLEANUP_DOSSIER_REQUEST:
				return {...initialDossierState};
		case DossierActionsEnum.GET_DOSSIER_SUCCESS:
			return { ...state, isLoading: false, listeDossiers: action.payload.listeDossiers };
		case DossierActionsEnum.GET_DOSSIER_ERROR:
			return { ...state, isLoading: false };
		case DossierActionsEnum.UPDATE_DOSSIER_FILTRE_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierFilter: action.payload
			};

		/** Details */
		case DossierActionsEnum.GET_DOSSIER_DETAILS_REQUEST:
			return { ...state, isDossierDetailsLoading: true };
		case DossierActionsEnum.GET_DOSSIER_DETAILS_SUCCESS:
			return { ...state, isDossierDetailsLoading: false, dossierDetails: action.payload.dossierDetails };
		case DossierActionsEnum.GET_DOSSIER_DETAILS_ERROR:
			return { ...state, isDossierDetailsLoading: false, dossierDetails: {} };


		/** Relations */
		case DossierActionsEnum.GET_DOSSIER_RELATIONS_REQUEST:
			return { ...state, isRelationsLoading: true };
		case DossierActionsEnum.GET_DOSSIER_RELATIONS_SUCCESS:
			return {
				...state,
				isRelationsLoading: false,
				dossierRelations: action.payload.dossierRelations
			};
		case DossierActionsEnum.GET_DOSSIER_RELATIONS_ERROR:
			return { ...state, isRelationsLoading: false };

		case DossierActionsEnum.ADD_DOSSIER_RELATIONS_REQUEST:
			return { ...state, isRelationsLoading: true };
		case DossierActionsEnum.ADD_DOSSIER_RELATIONS_SUCCESS:
			return { ...state, isRelationsLoading: false };
		case DossierActionsEnum.ADD_DOSSIER_RELATIONS_ERROR:
			return { ...state, isRelationsLoading: false };

		case DossierActionsEnum.DELETE_DOSSIER_RELATIONS_REQUEST:
			return { ...state, isRelationsLoading: true };
		case DossierActionsEnum.DELETE_DOSSIER_RELATIONS_SUCCESS:
			return { ...state, isRelationsLoading: false };
		case DossierActionsEnum.DELETE_DOSSIER_RELATIONS_ERROR:
			return { ...state, isRelationsLoading: false };


		/** Note */
		case DossierActionsEnum.GET_DOSSIER_NOTE_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_DOSSIER_NOTE_SUCCESS:
			let note:UpdateDossierNoteDto;
			if(action.payload.dossierNote && Object.keys(action.payload.dossierNote).length > 0)
				note = action.payload.dossierNote
			else
				note = {
					note: 0,
					commentairesNote: ''
				}
			return {
				...state,
				isLoading: false,
				dossierNote: note
			};
		case DossierActionsEnum.GET_DOSSIER_NOTE_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.PUT_DOSSIER_NOTE_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.PUT_DOSSIER_NOTE_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierNote: action.payload.dossierNote
			};
		case DossierActionsEnum.PUT_DOSSIER_NOTE_ERROR:
			return { ...state, isLoading: false };

		/** DSV */
		case DossierActionsEnum.GET_DOSSIER_DSV_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_DOSSIER_DSV_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierDSV: action.payload.dossierDSV
			};
		case DossierActionsEnum.GET_DOSSIER_DSV_ERROR:
			return { ...state, isLoading: false, dossierDSV: {} };
		case DossierActionsEnum.PUT_DOSSIER_DSV_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.PUT_DOSSIER_DSV_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierDSV: action.payload.dossierDSV
			};
		case DossierActionsEnum.PUT_DOSSIER_DSV_ERROR:
			return { ...state, isLoading: false };

		/** Instruction */
		case DossierActionsEnum.GET_DOSSIER_INSTRUCTION_REQUEST:
			return { ...state, isInstructionLoading: true };
		case DossierActionsEnum.GET_DOSSIER_INSTRUCTION_SUCCESS:
			return {
				...state,
				isInstructionLoading: false,
				dossierInstruction: action.payload.dossierInstruction
			};
		case DossierActionsEnum.GET_DOSSIER_INSTRUCTION_ERROR:
			return { ...state, isInstructionLoading: false };
		case DossierActionsEnum.PUT_DOSSIER_INSTRUCTION_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.PUT_DOSSIER_INSTRUCTION_SUCCESS:
			return { ...state, isLoading: false }
		case DossierActionsEnum.PUT_DOSSIER_INSTRUCTION_ERROR:
			return { ...state, isLoading: false, errorCode: action.payload.errorCode }
		case DossierActionsEnum.GET_DOSSIER_SUBCATEGORIES_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_DOSSIER_SUBCATEGORIES_SUCCESS:
			return {
				...state,
				isLoading: false,
				subCategories: action.payload.subCategories
			};
		case DossierActionsEnum.GET_DOSSIER_SUBCATEGORIES_ERROR:
			return { ...state, isLoading: false }
		case DossierActionsEnum.GET_DOSSIER_ADDITIONALINFO_REQUEST:
			return { ...state, isLoading: true, dossierAdditionalInfo: undefined };
		case DossierActionsEnum.GET_DOSSIER_ADDITIONALINFO_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierAdditionalInfo: action.payload.dossierAdditionalInfo
			};
		case DossierActionsEnum.GET_DOSSIER_ADDITIONALINFO_ERROR:
			return { ...state, isLoading: false };
		
		/** Aides history */
		case DossierActionsEnum.GET_DOSSIER_AIDES_HISTORY_REQUEST:
			return { ...state, isAidesLoading: true };
		case DossierActionsEnum.GET_DOSSIER_AIDES_HISTORY_SUCCESS:
			return {
				...state,
				dossierAidesHistory: action.payload.dossierAidesHistory,
				isAidesLoading: false
			};
		case DossierActionsEnum.GET_DOSSIER_AIDES_HISTORY_ERROR:
			return { ...state, isAidesLoading: false };

			

		/** Versement */
		case DossierActionsEnum.PUT_DOSSIER_AGREEMENT_REQUEST:
			return { ...state, isAgreementLoading: true };
		case DossierActionsEnum.PUT_DOSSIER_AGREEMENT_SUCCESS:
			return {
				...state,
				isAgreementLoading: false
			};
		case DossierActionsEnum.PUT_DOSSIER_AGREEMENT_ERROR:
			return { ...state, isAgreementLoading: false };

		case DossierActionsEnum.PUT_DOSSIER_DOCUMENTS_VALIDATION_REQUEST:
			return { ...state, isDocValLoading: true };
		case DossierActionsEnum.PUT_DOSSIER_DOCUMENTS_VALIDATION_SUCCESS:
			const documents = state.dossierDetails?.documents?.reduce((acc: DocumentValidationDto[], curr, i) => {
				const doc = action.payload.dossierDocuments?.find(d => d.id === curr.id);

				acc[i] = {
					...curr,
					...doc
				};

				return acc;
			}, []);

			return {
				...state,
				dossierDetails: {
					...state.dossierDetails,
					documents
				},
				isDocValLoading: false
			};
		case DossierActionsEnum.PUT_DOSSIER_DOCUMENTS_VALIDATION_ERROR:
			return { ...state, isDocValLoading: false };

		case DossierActionsEnum.GET_DOSSIER_CONVENTION_COURRIER_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_DOSSIER_CONVENTION_COURRIER_SUCCESS:
			return {
				...state,
				isLoading: false,
				conventionCourrier: action.payload.conventionCourrier
			};
		case DossierActionsEnum.GET_DOSSIER_CONVENTION_COURRIER_ERROR:
			return { ...state, isLoading: false };


		case DossierActionsEnum.GET_DOSSIER_VERSEMENTS_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_DOSSIER_VERSEMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierVersements: action.payload.dossierVersements
			};
		case DossierActionsEnum.GET_DOSSIER_VERSEMENTS_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.PUT_INSTRUCTION_VERSEMENT_DSV_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.PUT_INSTRUCTION_VERSEMENT_DSV_SUCCESS:
			return { ...state, isLoading: false };
		case DossierActionsEnum.PUT_INSTRUCTION_VERSEMENT_DSV_ERROR:
			return { ...state, isLoading: false };
		

		case DossierActionsEnum.GET_VALIDATION_DOSSIER_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_VALIDATION_DOSSIER_SUCCESS:
			return { ...state, isLoading: false, dossierValidation: action.payload.dossierValidation };
		case DossierActionsEnum.GET_VALIDATION_DOSSIER_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.VALIDATE_DOSSIER_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.VALIDATE_DOSSIER_SUCCESS:
			return { ...state, isLoading: false };
		case DossierActionsEnum.VALIDATE_DOSSIER_ERROR:
			return { ...state, isLoading: false };

		// messages
		case DossierActionsEnum.GET_DOSSIER_MESSAGES_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_DOSSIER_MESSAGES_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierDiscussions: action.payload.dossierDiscussions,
				dossierDiscussionsLu: action.payload.dossierDiscussionsLu
			};
		case DossierActionsEnum.GET_DOSSIER_MESSAGES_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.POST_DOSSIER_MESSAGES_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.POST_DOSSIER_MESSAGES_SUCCESS:
			return {
				...state,
				isLoading: false,
				dossierMessage: action.payload.dossierMessage,
				dossierDiscussion: action.payload.dossierDiscussion
				
			};
		case DossierActionsEnum.POST_DOSSIER_MESSAGES_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.POST_DOSSIER_MULTIPLE_MESSAGES_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.POST_DOSSIER_MULTIPLE_MESSAGES_SUCCESS:
			return { ...state, isLoading: false };
		case DossierActionsEnum.POST_DOSSIER_MULTIPLE_MESSAGES_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.CREATE_DOSSIER_DISCUSSION_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.CREATE_DOSSIER_DISCUSSION_SUCCESS:
			let d:DiscussionDto[] = state.dossierDiscussions || [];
			let newDiscussions = [...d]
			newDiscussions.unshift(action.payload.dossierDiscussion as DiscussionDto)
			return {
				...state,
				isLoading: false,
				dossierDiscussion: action.payload.dossierDiscussion,
				dossierDiscussions: newDiscussions
			};
		case DossierActionsEnum.CREATE_DOSSIER_DISCUSSION_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.UPDATE_DOSSIER_DISCUSSION_LU_SUCCESS:
			if(action.payload.dossierDiscussionId) {
				return {
					...state,
					dossierDiscussionsLu: [
						...state.dossierDiscussionsLu || [],
						action.payload.dossierDiscussionId
					]
				};
			}
			return state;

		case DossierActionsEnum.GET_DOSSIER_MESSAGES_OBJECT:
			return { ...state, objectDiscussionDossier: action.payload.objectDiscussionDossier };

		case DossierActionsEnum.GET_DOSSIER_NOTATION_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.GET_DOSSIER_NOTATION_SUCCESS:
			return { ...state, isLoading: false, dossierNotation: action.payload.dossierNotation };
		case DossierActionsEnum.GET_DOSSIER_NOTATION_ERROR:
			return { ...state, isLoading: false };

		case DossierActionsEnum.RECALCUL_MASSE_SALARIALE_SUCCESS:
			return { ...state, masseSalariale: action.payload.masseSalariale };
		case DossierActionsEnum.RECALCUL_MASSE_SALARIALE_ERROR:
			return { ...state };
		case DossierActionsEnum.GET_VERSEMENT_DETAILS_REQUEST:
			return { ...state, isDossierDetailsLoading: true };
		case DossierActionsEnum.GET_VERSEMENT_DETAILS_SUCCESS:
			return { ...state, isDossierDetailsLoading: false, detailsVersement: action.payload.detailsVersement };
		case DossierActionsEnum.GET_VERSEMENT_DETAILS_ERROR:
			return { ...state, isDossierDetailsLoading: false };

		case DossierActionsEnum.GET_VERSEMENT_MASSE_SALARIALE_SUCCESS:
			return { ...state, masseSalarialeVersement: action.payload.masseSalarialeVersement };
		case DossierActionsEnum.GET_VERSEMENT_MASSE_SALARIALE_ERROR:
			return { ...state };

		case DossierActionsEnum.PRENDRE_EN_CHARGE_DOSSIER_SUCCESS:
			return {
				...state,
				dossierDetails: {
					...state.dossierDetails,
					chargeInstructionId: action.payload.chargeInstructionId
				}
			};

		case DossierActionsEnum.PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_SUCCESS:
			return { ...state };
		case DossierActionsEnum.PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_ERROR:
			return { ...state };
		
		case DossierActionsEnum.GET_DOSSIER_SYNTHESE_FINANCIERE:
			return { ...state, dossierSynthese: action.payload.dossierSynthese };
		case DossierActionsEnum.GET_DOSSIER_MOUVEMENTS:
			return { ...state, dossierMouvements: action.payload.dossierMouvements };
 

		case DossierActionsEnum.GET_EVENEMENTS_FINANCIERS_SUCCESS:
		case DossierActionsEnum.GET_EVENEMENTS_FINANCIERS_ERROR:
			return {
				...state,
				evenementFinancier: action.payload.evenementFinancier
			};

		case DossierActionsEnum.AJOUT_EVENEMENT_FINANCIER_REQUEST:
			return { ...state, isLoading: true };
		case DossierActionsEnum.AJOUT_EVENEMENT_FINANCIER_SUCCESS:
			const dossierMouvements = [...state.dossierMouvements || []];
			action.payload.dossierMouvement && dossierMouvements.push(action.payload.dossierMouvement);
			return { ...state, isLoading: false, dossierMouvements };
		case DossierActionsEnum.AJOUT_EVENEMENT_FINANCIER_ERROR:
			return { ...state, isLoading: false };
			
	
		default:
			return state;
	}
};