import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { fetchAutocompleteNom, fetchAutocompleteEmail, fetchAutocompleteResponsable, fetchAutocompleteSiret } from '../../../store/structure-store/actions/structureAction';
import { useStructureDispatcher } from '../../../store/store-helpers';

import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';

import { StatutStructure } from '../../../services/generated/BackOffice-api';
import { useTranslation } from 'react-i18next';

interface FiltersProps {
	setNom: (value: string) => void;
	setEmail: (value: string) => void;
	setStatut: (value: string) => void;
	setUsername: (value: string) => void;
	setResponsable: (value: string) => void;
	setSiret: (value: string) => void;
	nom: string;
	email: string;
	statut: string;
	username: string;
	responsable: string;
	siret: string;
}

const Filters: React.SFC<FiltersProps> = ({
	setNom,
	setEmail,
	setStatut,
	setUsername,
	setResponsable,
	setSiret,
	nom,
	siret,
	email,
	statut,
	username,
	responsable
}) => {
	const dispatch = useStructureDispatcher();
	const { t } = useTranslation();
	const refInput = useRef<HTMLInputElement>(null);

	const debounceNom = useMemo(() => _.debounce<(value: any) => void>((value) => {
		fetchAutocompleteNom(dispatch, value)().then((result: any) => {
			setNomList(result.payload);
		})
	}, 1000), [])

	const debounceEmail = useMemo(() => _.debounce<(value: any) => void>((value) => {
		fetchAutocompleteEmail(dispatch, value)().then((result: any) => {
			setEmailList(result.payload);
		});
	}, 1000), [])

	const debounceResponsableDossier = useMemo(() => _.debounce<(value: any) => void>((value) => {
		fetchAutocompleteResponsable(dispatch, value)().then((result: any) => {
			setResponsableList(result.payload);
		});
	}, 1000), [])
	const debounceSiret = useMemo(() => _.debounce<(value: any) => void>((value) => {
		fetchAutocompleteSiret(dispatch, value)().then((result: any) => {
			setSiretList(result.payload);
		});
	}, 1000), [])

	/** Nom de la structure */
	const [nomList, setNomList] = useState<string[]>([]);

	const searchNom = (value: string) => {
		if (!value) {
			setNom("");
			return;
		}
		else {
			setNom(value);

			debounceNom(value);
		}
	}


	/** Email */
	const [emailList, setEmailList] = useState<string[]>([]);
	const searchEmail = (value: string) => {
		if (!value) {
			setEmail("");
			return;
		}
		else {
			setEmail(value);

			debounceEmail(value);
		}
	}


	/** Responsable dossier */
	const [responsableList, setResponsableList] = useState<string[]>([]);
	const searchResponsableDossier = (value: string) => {
		if (!value) {
			setResponsable("");
			return;
		}
		else {
			setResponsable(value);

			debounceResponsableDossier(value);
		}
	}

	/** Siret */
	const [siretList, setSiretList] = useState<string[]>([]);
	const searchSiret = (value: string) => {
		if (!value) {
			setSiret("");
			return;
		}
		else {
			setSiret(value);

			debounceSiret(value);
		}
	}

	/** Statut du compte */
	const options = () => {
		let allOptions: AdelOption<StatutStructure | string>[] = [];
		allOptions.push(
			{
				value: "all",
				label: "Tous les statuts"
			}
		);
		for (let status in StatutStructure) {
			const statusValue = StatutStructure[status as keyof typeof StatutStructure];
			if (statusValue !== StatutStructure.None) {

				allOptions.push(
					{
						value: statusValue,
						label: t(`structure.status.${statusValue}`)
					}
				)
			}
		}
		return allOptions;
	};

	const onChangeStatut = (elem: any) => {
		setStatut(elem);
	}


	/** Username - Code compte */
	const onChangeUsername = (value: string) => {
		if (value !== "" && value !== " ") {
			setUsername(value);
			return;
		} else {
			setUsername("");
		}
	};

	// TODO: Régler cette histoire de debounce sur les champs !!!
	return (
		<div className="filtres__item">
			<label>Recherches avancées :</label>
			<div className="filtres__search">
				<Input
					name="username"
					className="filter__input"
					reference={null}
					label=""
					placeHolder="Code compte"
					type="text"
					maxLength={11}
					// onChange={_.debounce((value) => onChangeUsername(value), 1000)}
					value={username}
					onChange={(value: string) => onChangeUsername(value)}
				/>
				<Input
					autoComplete={'off'}
					name="nom"
					reference={refInput}
					className="filter__input"
					placeHolder="Nom de la structure"
					type="text"
					maxLength={60}
					dataList={nomList}
					// onChange={_.debounce((value) => searchNom(value), 1000)}
					value={nom}
					onChange={(value: string) => searchNom(value)}
				/>

				<Input
					autoComplete={'off'}
					name="email"
					reference={null}
					className="filter__input"
					placeHolder="Email du responsable"
					type="text"
					maxLength={120}
					dataList={emailList}
					// onChange={_.debounce((value) => searchEmail(value), 1000)}
					value={email}
					onChange={(value: string) => searchEmail(value)}
				/>

				<InputSelect
					name="statut"
					classname={`filtreItem__inputSelect ${statut !== "all" && "filtreItem__inputSelect--selected"}`}
					options={options()}
					onChange={(elem) => {
						onChangeStatut(elem)
					}}
					value={statut}
				/>

				<Input
					autoComplete={'off'}
					name="responsable"
					reference={null}
					className="filter__input"
					placeHolder="Responsable dossier"
					type="text"
					maxLength={60}
					dataList={responsableList}
					// onChange={_.debounce((value) => searchResponsableDossier(value), 1000)}
					value={responsable}
					onChange={(value: string) => searchResponsableDossier(value)}
				/>
				<Input
					name="siret"
					className="filter__input"
					reference={null}
					label=""
					placeHolder="Siret"
					type="text"
					maxLength={20}
					// onChange={_.debounce((value) => onChangeUsername(value), 1000)}
					value={siret}
					dataList={siretList}
					onChange={(value: string) => searchSiret(value)}
				/>
			</div>
		</div>
	);
}

export default Filters;
