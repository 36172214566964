import { Dictionary } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CategorieDossier } from '../../../../enums/Dossiers';
import { DemandeVersementMasseSalarialeDto, DocumentValidationDto, VersementDetailsDto } from '../../../../services/generated/BackOffice-api';
import { recalculMasseSalariale } from '../../../../store/dossier-store/actions/dossierVersementActions';
import { DossierState } from '../../../../store/dossier-store/states/dossierState';
import { useDossierDispatcher } from '../../../../store/store-helpers';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import MasseSalarialeTable from "./MasseSalarialeTable";
import Depenses from "./artistes/Depenses";
import { formatNumber } from 'adel-shared/dist/utils/functions';
import TotalAmountsTable from 'adel-shared/dist/components/basics/TotalAmountsTable';
import { VersementInformations } from '../VersementTab';
import { ModeVersement, TypeVersement } from 'adel-shared/dist/models/generated/BackOffice-api';

interface VersementMasseSalarialeProps {
	versementInfos: VersementInformations;
	details?: VersementDetailsDto;
	masseSalariale: DemandeVersementMasseSalarialeDto;
	isDisabled?: boolean;
	setMasseSalariale: (value:DemandeVersementMasseSalarialeDto) => void;
	dossierSelector: Partial<DossierState>;
}

const VersementMasseSalariale: React.FunctionComponent<VersementMasseSalarialeProps> = ({
	versementInfos,
	details,
	masseSalariale,
	isDisabled,
	setMasseSalariale,
	dossierSelector,
}) => {
	const dispatch = useDossierDispatcher();

	useEffect(() => {
		(async() => {
			await recalculMasseSalariale(dispatch, versementInfos.versementId);
		})();
	}, []);
	
	const recalcul = async() => {
		await recalculMasseSalariale(dispatch, versementInfos.versementId);
	}
	const categoriesBulletinsPaie = [
		CategorieDossier.Festival, 
		CategorieDossier.SpectacleMusical, 
		CategorieDossier.SpectacleDramatiqueChoregraphiqueCirque
	]
	const getMasseSalarialeTitle =() => {
		//@ts-ignore
		return dossierSelector.dossierDetails?.dsv && categoriesBulletinsPaie.includes(dossierSelector.dossierDetails.categorie?.code) 
					&& versementInfos.type == TypeVersement.Acompte && versementInfos.modeVersement == ModeVersement.Manuel ? 
					'Le tableau récapitulatif de la masse salariale justifiée par les contrats d’artistes fournis *' :
					'Le tableau récapitulatif de la masse salariale justifiée par bulletins de salaires fournis *'
	}

	const isBulletin = () => {
		//@ts-ignore
		return !(dossierSelector.dossierDetails?.dsv && categoriesBulletinsPaie.includes(dossierSelector.dossierDetails.categorie?.code) 
					&& versementInfos.type == TypeVersement.Acompte && versementInfos.modeVersement == ModeVersement.Manuel);
	}

	const isLabelSped = () => details && (details.categorieCode === 'DIFFSPLAB1' || details.categorieCode === 'DIFFSPLAB2') ;

	return (
		<section className="versementTab__masse">
			<h3 className="detailsTab__title">Masse salariale</h3>
			<p>{getMasseSalarialeTitle()}</p>

			<MasseSalarialeTable
				masseSalariale={masseSalariale}
				versementInfos={versementInfos}
				setMasseSalariale={setMasseSalariale}
				isBulletin={isBulletin}
			/>

			{masseSalariale &&
				<TotalAmountsTable
				data={[
					{
							label: "Total montant prévisionnel",
							value: isLabelSped() ? 
							`${formatNumber(masseSalariale?.depenses?.reduce((cumul, current) => {
								return cumul + (current?.montant ?? 0)
							},0)
							)}` : 
							`${formatNumber(masseSalariale.totalMontantPrevisionnel)} €`
					},
					{
						label: "Total rémunérations brut",
						value: `${formatNumber(masseSalariale.totalRemunerationBrut)} €`,
					},
					{
						label: "Total charges patronales",
						value: `${formatNumber(masseSalariale.totalChargePatronales)} €`,
					},
					{
						label: "Total masse salariale réalisée",
						value: `${
							
							formatNumber((masseSalariale.totalRemunerationBrut ?? 0)+ (masseSalariale.totalChargePatronales ?? 0))
						} €`,
					}
				]}
				/>
			}

			{(masseSalariale?.depenses && masseSalariale.depenses.length > 0) && (
				<div className="versementTab__depenses">
					<h3 className="detailsTab__title">Dépenses</h3>
						<Depenses
							depenses={masseSalariale.depenses}
							isDepenseAutre={details?.categorieDossier === CategorieDossier.InteretGeneral || details?.categorieDossier === CategorieDossier.AideSpecifique}
							versementId={versementInfos.versementId}
						/>
				</div>
			)}

			<div className="versementTab__calcul">
				<p>Vous justifiez de <b>{formatNumber((dossierSelector?.masseSalariale || 0) * 100)} %</b> de la Base de calcul prévisionnelle</p>
				<FormButton
					type="button"
					value="Recalculer"
					onClick={recalcul}
					disabled={isDisabled}
				/>
			</div>

			<div className="versementTab__masseCommentaire">
				<label className="input__label">Commentaire</label>
				<p>{details?.commentaireStructure || '-'}</p>
			</div>
		</section>
	);
}

export default VersementMasseSalariale;