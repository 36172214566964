import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { StructureClient, StructureNoteDto, UpdateStructureNoteDto, UpdateStructureNoteProDto } from '../../../services/generated/BackOffice-api';
import { StructureActionsEnum } from './structureAction';

/** Actions */
export const requestStructureNote = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_NOTE_REQUEST,
		payload: {}
	}
}

export const getStructureNote = (note: StructureNoteDto) => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_NOTE_SUCCESS,
		payload: { structureNote: note }
	}
}

export const requestPostStructureNote = () => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_NOTE_REQUEST
	}
}

export const postStructureNote = (note: UpdateStructureNoteDto) => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_NOTE_SUCCESS,
		payload: { structureNote: note }
	}
}

/** Task */
const structureClient = new StructureClient('', axiosInstance);

export const fetchStructureNote = (dispatch: any, value: string) => {
	return () => {
		dispatch(requestStructureNote());
		structureClient.getStructureNote(value)
			.then((response: StructureNoteDto) => dispatch(getStructureNote(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer la note de structure");
		})
	}
}

export const sendStructureNote = (dispatch: any, structureId: string, value: UpdateStructureNoteDto) => {
	return () => {
		dispatch(requestPostStructureNote());
		structureClient.updateStructureNote(structureId, value)
			.then(() => {
				dispatch(postStructureNote(value));
				toast.success("La note a bien été mise à jour");
			})
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de mettre à jour la note");
		})
	}
}

export const updateStructureNotePro = async (
	dispatch: any,
	structureId: string,
	body: UpdateStructureNoteProDto
) => {
	dispatch({ type: StructureActionsEnum.POST_STRUCTURE_NOTE_PRO_REQUEST });
	try {
		await structureClient.updateStructureNotePro(structureId, body);
		dispatch({ type: StructureActionsEnum.POST_STRUCTURE_NOTE_PRO_SUCCESS });
		toast.success("La note de professionnalisme de la structure a bien été modifiée.")
	} catch(error) {
		dispatch({ type: StructureActionsEnum.POST_STRUCTURE_NOTE_PRO_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors de l'envoi de la note de professionnalisme.")
		throw error;
	}
}
