import React, { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useCommissionDispatcher, useCommissionSelector } from '../../../store/store-helpers';
import Input from "adel-shared/dist/components/basics/Input";
import { updateCommissionEnveloppesBudgetaires } from "../../../store/commission-store/actions/commissionActions";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { navigate, RouteComponentProps } from '@reach/router';
import { toast } from "react-toastify";
import { useFieldArray, useForm } from "react-hook-form";
import { CategorieEnveloppeBudgetaireDto, CommissionDto } from '../../../services/generated/BackOffice-api';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import useHasPermission from '../../../custom-hooks/useHasPermission';

interface BudgetTabProps extends RouteComponentProps {
	commission: CommissionDto | undefined;
	getBudget: () => void;
}

const BudgetsTab: React.FunctionComponent<BudgetTabProps> = ({
	commission,
	getBudget
}) => {
	const usePermUpdateCommissionEnveloppesBudgetaires = useHasPermission("UpdateCommissionEnveloppesBudgetaires");
	const {
		commissionEnveloppesBudgetaires: enveloppesBudgetaires
	} = useCommissionSelector();

	const { register, getValues, setValue, triggerValidation, control, watch, errors } = useForm();
	const { append } = useFieldArray({
		control,
		name: 'categoriesEnveloppesBudgetaires'
	});
	const { t } = useTranslation();
	const dispatch = useCommissionDispatcher();
	const watchAllFields = watch({nest:true});
	const formValues = getValues({nest: true});


	useEffect(() => {
		if(enveloppesBudgetaires) {
			setValue('montantMax', enveloppesBudgetaires?.montantMax);
			if (enveloppesBudgetaires.categoriesEnveloppesBudgetaires) {
				renderFields(enveloppesBudgetaires.categoriesEnveloppesBudgetaires);
			}
		}
	}, [enveloppesBudgetaires]);

	const renderFields = (result: CategorieEnveloppeBudgetaireDto[]) => {
		append(result.map(ceb => ({
			categorieDossierId: ceb.categorieDossier?.id,
			totalDisponible: ceb.totalDisponible,
			coefficientCorrecteur: ceb.coefficientCorrecteur
		})));
	}

	const handleGetBudget = async () => {
		await getBudget();
	}

	const handleSubmit = async() => {
		const result = await triggerValidation();
		if(!commission?.id || !result) return;

		const totalDisponibleSum = formValues.categoriesEnveloppesBudgetaires.reduce((acc: number, currentVal: any) =>
			acc + parseFloat(currentVal.totalDisponible || 0)
		, 0);

		if(parseFloat(totalDisponibleSum.toFixed(5)) !== parseFloat(formValues.montantMax)) {
			toast.error("La somme des totaux disponibles doit être égale au montant max");
			return;
		}

		const body = {
			montantMax: parseFloat(formValues.montantMax),
			categoriesEnveloppesBudgetaires: formValues.categoriesEnveloppesBudgetaires.map((ceb: any) => {
				const totalDemande = enveloppesBudgetaires?.categoriesEnveloppesBudgetaires?.find(e => e.categorieDossier?.id === ceb.categorieDossierId)?.totalDemande || 0;
				return {
					...ceb,
					pourcentageAttribution: parseFloat(ceb.totalDisponible) / parseFloat(totalDemande?.toString()),
					totalDisponible: parseFloat(ceb.totalDisponible),
					coefficientCorrecteur: parseFloat(ceb.coefficientCorrecteur) / 100,
				}
			})
		};

		try {
			usePermUpdateCommissionEnveloppesBudgetaires && await updateCommissionEnveloppesBudgetaires(dispatch, commission.id, body);
			await handleGetBudget();
		} catch {
			toast.error(t('common.errors.send'));
		}
	};

	const totalDemandeWatch = (id?:string) => {
		if(id)
			return enveloppesBudgetaires?.categoriesEnveloppesBudgetaires?.find(e => e.categorieDossier?.id === id)?.totalDemande || 0
		return 0
	}

	const getAttributionPercentageByCategorie = (x: CategorieEnveloppeBudgetaireDto) => {
		const categoriesEnveloppesBudgetaires = watchAllFields.categoriesEnveloppesBudgetaires;

		if(categoriesEnveloppesBudgetaires && parseFloat(`${totalDemandeWatch(x.categorieDossier?.id)}`) > 0) {
			const totalDisponible = categoriesEnveloppesBudgetaires
			.find((ceb:any) => ceb.categorieDossierId === x.categorieDossier?.id).totalDisponible || 0;

			return formatNumber(
				parseFloat(totalDisponible)
				/ parseFloat(`${totalDemandeWatch(x.categorieDossier?.id)}`)
				* 100
			);
		}
		return 0;
	};

	return (
		<section className="modal__item budgetTab">
			<div className="budget__input">
				<span>€</span>
				<Input
					name="montantMax"
					label="Montant max"
					type="number"
					reference={register()}
					defaultValue={formValues.montantMax}
				/>
			</div>

			<table className="budget__table">
				<thead>
					<tr>
						<th></th>
						<th>Total demandé</th>
						<th>Total disponible</th>
						<th>% attribution par catégorie</th>
						<th>Note administrative moyenne</th>
						<th>Coefficient correcteur</th>
					</tr>
				</thead>
				<tbody>
					{enveloppesBudgetaires?.categoriesEnveloppesBudgetaires?.map((x, i) => (
						<Fragment key={x.categorieDossier?.id}>
							<tr>
								<td style={{display: 'none'}}>
									<input
										type="hidden"
										name={`categoriesEnveloppesBudgetaires[${i}].categorieDossierId`}
										ref={register()}
										value={x.categorieDossier?.id}
									/>
								</td>
							</tr>
							<tr>
								<td>{x.categorieDossier?.nom}</td>
								<td>{formatNumber(x.totalDemande)} €</td>
								<td>
									<div className="budget__input">
										<span>€</span>
										<Input
											type="number"
											name={`categoriesEnveloppesBudgetaires[${i}].totalDisponible`}
											reference={register()}
											defaultValue={x.totalDisponible}
										/>
									</div>
								</td>
								<td>
									{getAttributionPercentageByCategorie(x)} %
								</td>
								<td>{formatNumber(x.noteMoyenne)}</td>
								<td>
									<div className="budget__input">
										<span>%</span>
										<Input
											type="number"
											name={`categoriesEnveloppesBudgetaires[${i}].coefficientCorrecteur`}
											reference={register({max: 100})}
											max={100}
											errors={errors}
											defaultValue={Math.round((x.coefficientCorrecteur || 0)* 100*100)/100}
										/>
									</div>
								</td>
							</tr>
						</Fragment>
					))}
					<tr>
						<td><b>TOTAUX :</b></td>
						<td>
							<b>
								{formatNumber(enveloppesBudgetaires?.categoriesEnveloppesBudgetaires?.reduce((acc:number, currentVal:CategorieEnveloppeBudgetaireDto) => {
									const total = acc + (currentVal.totalDemande || 0);
									return total;
								}, 0))} €
							</b>
						</td>
						<td>
							<b>
								{formatNumber(formValues.categoriesEnveloppesBudgetaires?.reduce((acc:number, currentVal:CategorieEnveloppeBudgetaireDto) => {
									const total = acc + parseFloat((currentVal.totalDisponible || 0).toString())
									return total;
								}, 0))} €
							</b>
						</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
			<div className="editCommission__footer">
				{Object.keys(errors).length > 0 &&
					<div className="input__errorMessage">Le coefficient correcteur doit faire moins de 100%.</div>
				}
				<FormButton
					className="button__cancel"
					type="button"
					value="Annuler"
					onClick={() => navigate("/Commission/ListeCommissions")}
				/>
				{usePermUpdateCommissionEnveloppesBudgetaires && (
					<FormButton
						type="submit"
						value="Enregistrer"
						onClick={handleSubmit}
					/>
				)}
			</div>
		</section>
	);
};

export default BudgetsTab;
