import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { Controller, useForm } from 'react-hook-form';
import Input from 'adel-shared/dist/components/basics/Input';
import InputCalendar from 'adel-shared/dist/components/basics/InputCalendar';
import InputRadioYesNo from 'adel-shared/dist/components/basics/InputRadioYesNo';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { CreateOrUpdateCommissionDto, GroupeCommission } from '../../../services/generated/BackOffice-api';
import { fetchCategoriesDossier } from '../../../store/categorie-dossier-store/actions/categorieDossierAction';
import { useCategorieDossierSelector } from '../../../store/store-helpers';
import { useCommissionSelector } from '../../../store/store-helpers';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { createCommission } from '../../../store/commission-store/actions/commissionActions';
import { toast } from 'react-toastify';
import { normalizeDate, formaterDate } from 'adel-shared/dist/utils/functions';

interface ModalAjoutCommissionProps extends RouteComponentProps {
	setModalAjout: (value: boolean) => void;
	validator: any;
}

const ModalAjoutCommission: React.FunctionComponent<ModalAjoutCommissionProps> = ({ setModalAjout, validator }) => {
	const commissionSelector = useCommissionSelector();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { register, getValues, setValue, triggerValidation, errors, control, watch } = useForm<any>({ defaultValues: {
		visibleCalendrier: true,
		commissionSpecifique: false,
		decalageGroupes: false
	}});
	const dateDebut = watch('dateDebut');
	const commissionSpecifique = watch('commissionSpecifique');


	/** Catégories */
	const [categorieOptions, setCategorieOptions] = useState<AdelOption<string>[]>([]);
	const categorieSelector = useCategorieDossierSelector();

	useEffect(() => {
		fetchCategoriesDossier(dispatch)();
	}, []);

	useEffect(() => {
		const categories = categorieSelector?.categories?.map(category => ({
			value: category.id || '',
			label: category.nom || ''
		})) || [];
		setCategorieOptions(categories);
		
		const dossierCategories = categorieSelector.categories?.map(cd => ({
			label: cd.nom,
			value: cd.id
		}));
		setValue([
			{ categorieDossierIds: dossierCategories },
		]);
	}, [categorieSelector.categories]);



	/** Options select */
	const groupeOptions = () => {
		const options = Object.entries(GroupeCommission)
		.filter(([,value]) => value !== GroupeCommission.None && value !== GroupeCommission.Suppleant)
		.map(([label, value]) => ({
			value,
			label
		}));
		return options;
	}


	/** Envoi des données */
	const submit = async () => {
		let result = await triggerValidation();

		if (result) {
			let body: CreateOrUpdateCommissionDto = {
				nom: getValues().nom,
				dateDebut: normalizeDate(getValues().dateDebut),
				dateFin: normalizeDate(getValues().dateFin),
				dateFinSoumission: normalizeDate(getValues().dateFinSoumission),
				groupe: getValues().groupe,
				commissionSpecifique: getValues().commissionSpecifique,
				commissionSpecifiqueCommentaire: getValues().commissionSpecifiqueCommentaire,
				decalageGroupes: getValues().decalageGroupes,
				visibleCalendrier: getValues().visibleCalendrier,
				categorieDossierIds: getValues().categorieDossierIds?.map((e:any) => e.value)
			}

			if (getValues().dateCA) {
				body.dateCA = normalizeDate(getValues().dateCA);
			}
			createCommission(dispatch, body, setModalAjout)();
		}
	};

	useEffect(() => {
		if (commissionSelector && commissionSelector.errorCommissionCode !== "")
			toast.error(t(`errorApi.${commissionSelector.errorCommissionCode}`));
	}, [commissionSelector]);

	const handleDateDebutSelected = (date: Date, onChange: (date: Date) => void) => {
		onChange(date);
		if(!getValues().dateFin || moment(date).isAfter(getValues().dateFin)) {
			setValue('dateFin', date);
		}
	};

	const handleDateFinSelected = (date: Date, onChange: (date: Date) => void) => {
		onChange(date);
		if(!getValues().dateDebut || moment(date).isBefore(getValues().dateDebut)) {
			setValue('dateDebut', date);
		}
	};

	useEffect(() => {
		if(dateDebut && !commissionSpecifique) {
			setValue('nom', `Commission du ${formaterDate(dateDebut)}`);
		}
		else {
			setValue('nom', '');
		}
	}, [dateDebut, commissionSpecifique]);

	return (
		<section className="commission__modal">
			<div className="commission__modalContent">
				<div className="commission__modalHeader">
					<h2>Ajouter une commission</h2>
				</div>

				<div className="commission__modalItem">
					<div className="commission__modalTab">
						<section className="modal__item information">
							<div className="information__item information__calendar">
								<div className="calendar__periode">
									<h6>Période de la commission*</h6>

									<div className="calendar__periodeContent">
										<Controller control={control}
											name="dateDebut"
											as={({ onChange, value, name }) => (
												<InputCalendar
													name={name}
													defaultDate={value}
													errors={errors}
													onDateSelected={(date: Date) => handleDateDebutSelected(date, onChange)}
													unlock
												/>
											)}
											rules={{
												required: {
													value: true,
													message: "Ce champ est obligatoire."
												},
											}}
										/>

										<span>au</span>

										<Controller control={control}
											name="dateFin"
											as={({ onChange, value, name }) => (
												<InputCalendar
													name={name}
													defaultDate={value}
													errors={errors}
													onDateSelected={(date: Date) => handleDateFinSelected(date, onChange)}
													unlock
												/>
											)}
											rules={validator && validator["DateFin"]}
										/>
									</div>
								</div>

								<div className="calendar__soumission">
									<h6>Dernier jour pour la soumission d’un dossier*</h6>
									<Controller control={control}
											name="dateFinSoumission"
											as={({ onChange, value, name }) => (
												<InputCalendar
													name={name}
													defaultDate={value}
													errors={errors}
													onDateSelected={(v:Date) => onChange(v) }
													unlock
												/>
											)}
											rules={{
												required: { value: true, message: "Ce champ est obligatoire."},
												validate: { lessThanDateDebut : value => value.getTime() < getValues().dateDebut.getTime() || "La date du dernier jour pour la soumission doit être inférieure à la date de début." }
											}}
										/>
								</div>

								{/* <div className="calendar__CA">
									<h6>Date de session CA</h6>
									<Controller control={control}
										name="dateCA"
										as={({ onChange, value, name }) => (
											<InputCalendar
												name={name}
												errors={errors}
												defaultDate={value}
												onDateSelected={(v:Date) => onChange(v) }
												unlock
											 />
										)}
										rules={{
											validate: { moreThanDateDebut : value => !value || (value && (value.getTime() > getValues().dateDebut.getTime())) || "La date CA doit être supérieure à la date de début." }
										}}
									/>
								</div> */}
							</div>

							<div className="information__item information__groupe">
								<Controller control={control}
									name="groupe"
									as={({ onChange, value, name }) => (
										<InputSelect<GroupeCommission>
											name={name}
											label="Groupe rattaché*"
											classname="inputSelect"
											options={groupeOptions()}
											errors={errors}
											onChange={(group) => {
												onChange(group);
											}}
											value={value}
											placeholder={t("common.select")}
										/>
									)}
									rules={validator && validator["Groupe"]}
								/>
								<Controller control={control}
									name="visibleCalendrier"
									as={({ onChange, value, name }) => (
										<InputRadioYesNo
											name={"visibleCalendrier"}
											label="Visible sur le calendrier"
											onChange={(v) => {
												onChange(v);
											}}
											value={!getValues().commissionSpecifique}
										/>
									 )}
									rules={validator && validator["VisibleCalendrier"]}
								 />
							</div>

							<div className="information__item information__specifique">
								<Controller control={control}
									name="commissionSpecifique"
									as={({ onChange, value, name }) => (
										<InputRadioYesNo
											name={name}
											label="Commission spécifique"
											onChange={(info) => {
												onChange(info);
												setValue([{visibleCalendrier: !info}])
 												if (!info) {
														setValue('nom', `Commission du ${moment(dateDebut).format("DD/MM/YYYY")}`);
												} else {
													setValue('nom', '');
												}
											}}
											value={getValues().commissionSpecifique}
										/>
									)}
									rules={validator && validator["CommissionSpecifique"]}
								/>
								<Controller control={control}
									name="nom"
									as={({ onChange, value, name }) => (
										<Input
											name={name}
											type="text"
											label="Libellé de la commission"
											readOnly={!getValues().commissionSpecifique}
											errors={errors}
											onChange={(value:string) => {
												onChange(value);
											}}
											value={value}
										/>
									)}
									rules={validator && validator["Nom"]}
								/>
								{commissionSpecifique ? (
									<Input
										name="commissionSpecifiqueCommentaire"
										type="text"
										label="Commission spécifique détail"
										reference={validator && register(validator["CommissionSpecifique"])}
										errors={errors}
									/>
								) : (
									<Controller control={control}
										name="decalageGroupes"
										as={({ onChange, checked, name }) => (
											<InputRadioYesNo
												name={name}
												label="Décaler le groupe des commissions suivantes"
												onChange={onChange}
												value={checked}
											/>
										)}
										rules={validator && validator["DecalageGroupes"]}
									/>
								)}
							</div>

							<div className="information__item information__categorie">
								<Controller control={control}
									name="categorieDossierIds"
									as={({ onChange, value, name }) => (
										<InputSelect<any> // string[]
											name={name}
											label="Catégorie de dossiers pris en charge par cette commission*"
											classname="inputSelect inputSelect--fullWidth inputSelect--multi"
											options={categorieOptions}
											errors={errors}
											onChange={(value) => onChange(value) }
											value={value}
											isMulti={true}
											selectAll="Toutes les catégories"
											placeholder={t("common.select")}
										/>
									)}
									rules={validator && validator["CategorieDossierIds"]}
								/>
							</div>
						</section>
					</div>
					
					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalAjout(false)
							}}
						/>
						{commissionSelector?.isCreateLoading
							? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
							: <FormButton
								type="submit"
								value="Ajouter"
								onClick={submit}
							/>
						}
					</div>
				</div>
			</div>
		</section>
	);
}

export default ModalAjoutCommission;