import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import InputFileSingle, { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import clsx from 'clsx';
import { useSortBy, useTable } from 'react-table';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import CheckboxTable from '../basics/CheckboxTable';
import { DocumentWithValidationDto } from '../../services/generated/BackOffice-api';
import ModalCommentaire from './ModalCommentaire';

interface SingleDocumentValidationUpdateProps {
	documentWithValidation: DocumentWithValidationDto;
	setDocumentWithValidation: (value:DocumentWithValidationDto) => void
	pj?: DocumentWithFile;
	setPj: (value:DocumentWithFile|undefined) => void;
}

interface DocumentValidationFile {
	validee?: boolean;
	commentaires?: string;
	file?: DocumentWithFile;
}

const SingleDocumentValidationUpdate: React.FunctionComponent<SingleDocumentValidationUpdateProps> = ({
	documentWithValidation,
	setDocumentWithValidation,
	setPj,
	pj,
}) => {
    const [commDoc, setCommDoc] = useState<string>("");
	const [openModal, setOpenModal] = useState<boolean>(false);
    const [data, setData] = useState<DocumentValidationFile[]>([]);

	const handleComment = (id?:string, value?:string) => {
		setDocumentWithValidation({...documentWithValidation, commentaires: value});
	};

	const handleCheck = (value:boolean) => {
		setDocumentWithValidation({...documentWithValidation, validee: value})
	}

	const handleOpenModal = useCallback((documentWithValidation: DocumentWithValidationDto, value:string) => {
		if (documentWithValidation.commentaires)
			setCommDoc(documentWithValidation.commentaires);
		else
			setCommDoc(value);
		setOpenModal(true);
	}, [setOpenModal, setCommDoc]);

	const handleFileChange = (file?: DocumentWithFile) => {
		if(file) {
			setPj({fileName: file.fileName, url:file.url, file: file.file, id:undefined });
			setDocumentWithValidation({...documentWithValidation, fileName: file.fileName, uri: file.url, id:undefined });
		} else {
			setDocumentWithValidation({...documentWithValidation, fileName: undefined, uri: undefined, id:undefined });
			setPj(undefined);
		}
	};


	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Document", accessor: "file", disableSortBy: true,
						Cell: () => {
							if(documentWithValidation?.validee) {
								return <span className="documentsIcon">
									<a target="_blank" href={pj?.url}>
										<i className="fas fa-file-upload"></i> {pj?.fileName}
									</a>
								</span>
							} else {
								return <InputFileSingle
									labelButton="Télécharger"
									onChange={handleFileChange}
									isRequired={false}
									currentValue={pj && {
										id: pj.id,
										fileName: pj.fileName || '',
										url: pj.url
									}}
								/>
							}
						}
					},
					{ Header: "Valider", accessor: "validee", disableSortBy: true,
						Cell: (value: any) => {
							return (
								<CheckboxTable
									id={value.cell.row.values.Id}
									onCheck={(id: string, check: boolean, e: ChangeEvent<HTMLInputElement>) => {
										handleCheck(check);
									}}
									defaultCheck={value.value}
								/>
						)}
					},
					{ accessor: "commentaires", disableSortBy: true,
						Cell: (value: any) => (
							<span 
								className={clsx("commentIcon",
									{"commentIcon--hasValue": documentWithValidation?.commentaires }
								)}
								onClick={() => handleOpenModal(documentWithValidation, value.value)}
							>
								<i
									className={clsx({
											"far": !documentWithValidation?.commentaires,
											"fas": documentWithValidation?.commentaires
										},
										"fa-message"
									)}
								></i>
							</span>
						)
					},
				]
			}
		];
	}, [
		pj,
		handleOpenModal,
		documentWithValidation
	]);
	
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {},
			manualSortBy: true
		} as any,
		useSortBy
    );

    useEffect(() => {
		if(documentWithValidation) {
			if(documentWithValidation.fileName) {
				setPj({...pj,
					id: documentWithValidation.id,
					fileName: documentWithValidation.fileName,
					url: documentWithValidation.uri
				});
				
				setData([{
					...data,
					file: {
						id: documentWithValidation.id,
						fileName: documentWithValidation.fileName || '',
						url: documentWithValidation.uri
					}
				}]);
			}
			setData([{
				validee: documentWithValidation.validee,
				commentaires: documentWithValidation.commentaires
			}]);
		}
    }, [documentWithValidation]);

    return (
		<div className="detailsTab__content">
			<Table
				className="table--documentsRib"
				data={data}
				getTableProps={getTableProps}
				getTableBodyProps={getTableBodyProps}
				headerGroups={headerGroups}
				rows={rows}
				prepareRow={prepareRow}
				styles={styleTable.onglet}
			/>
			<ModalCommentaire
				id={pj?.id}
				setOpenModal={setOpenModal}
				openModal={openModal}
				setCommentToSend={handleComment}
				defaultValue={commDoc}
			/>
		</div>
    )
};

export default SingleDocumentValidationUpdate;