import { toast } from 'react-toastify';
import { Action, Dispatch } from 'redux';
import { searchCommissionMemberFilterKey } from '../../../constants/filters.constant';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { CommissionMemberClient, CommissionMemberDto, CreateCommissionMemberDto, UpdateCommissionMemberDto, CommissionMemberDtoSortedFilteredPage } from '../../../services/generated/BackOffice-api';
import { CommissionMembreState } from '../states/commissionMembreState';

export enum CommissionMembreActionsEnum {
	// Liste membres
	GET_MEMBRES_COMMISSIONS_REQUEST = "GET_MEMBRES_COMMISSIONS_REQUEST",
	GET_MEMBRES_COMMISSIONS_SUCCESS = "GET_MEMBRES_COMMISSIONS_SUCCESS",
	GET_MEMBRES_COMMISSIONS_ERROR = "GET_MEMBRES_COMMISSIONS_ERROR",

	// Créer membre
	CREATE_MEMBRE_COMMISSION_REQUEST = "CREATE_MEMBRE_COMMISSION_REQUEST",
	CREATE_MEMBRE_COMMISSION_SUCCESS = "CREATE_MEMBRE_COMMISSION_SUCCESS",
	CREATE_MEMBRE_COMMISSION_ERROR = "CREATE_MEMBRE_COMMISSION_ERROR",

	// Membre detail
	GET_MEMBRE_COMMISSION_REQUEST = "GET_MEMBRE_COMMISSION_REQUEST",
	GET_MEMBRE_COMMISSION_SUCCESS = "GET_MEMBRE_COMMISSIONS_SUCCESS",
	GET_MEMBRE_COMMISSION_ERROR = "GET_MEMBRE_COMMISSIONS_ERROR",

	// Membre update
	PUT_MEMBRE_COMMISSION_REQUEST = "PUT_MEMBRE_COMMISSION_REQUEST",
	PUT_MEMBRE_COMMISSION_SUCCESS = "PUT_MEMBRE_COMMISSION_SUCCESS",
	PUT_MEMBRE_COMMISSION_ERROR = "PUT_MEMBRE_COMMISSION_ERROR"
}

export interface DispatchCommissionMembreAction extends Action<CommissionMembreActionsEnum> {
	payload: CommissionMembreState;
}

export const requestCommissionMembres = () => {
	return {
		type: CommissionMembreActionsEnum.GET_MEMBRES_COMMISSIONS_REQUEST,
		payload: {}
	}
}

export const getCommissionMembres = (result: CommissionMemberDtoSortedFilteredPage) => {
	return {
		type: CommissionMembreActionsEnum.GET_MEMBRES_COMMISSIONS_SUCCESS,
		payload: { commissionMembres: result }
	}
}

export const errorCommissionMembres = (error:any) => {
	if(error.response?.exception?.message)
		toast.error(error.response.exception.message);
	else if(error.exception?.message)
		toast.error(error.exception.message);
	else
		toast.error("Impossible de récupérer les membres de commission");
	return {
		type: CommissionMembreActionsEnum.GET_MEMBRES_COMMISSIONS_ERROR,
		payload: {}
	}
}

export const requestCreateCommissionMembre = () => {
	return {
		type: CommissionMembreActionsEnum.CREATE_MEMBRE_COMMISSION_REQUEST,
		payload: {}
	}
}

export const createCommissionMembreAction = (result: CommissionMemberDto) => {
	return {
		type: CommissionMembreActionsEnum.CREATE_MEMBRE_COMMISSION_SUCCESS,
		payload: { commissionMembre: result }
	}
}

export const errorCreateCommissionMembre = () => {
	return {
		type: CommissionMembreActionsEnum.CREATE_MEMBRE_COMMISSION_ERROR,
		payload: {}
	}
}

export const requestCommissionMembre = () => {
	return {
		type: CommissionMembreActionsEnum.GET_MEMBRE_COMMISSION_REQUEST,
		payload: {}
	}
}

export const getCommissionMembre = (result: CommissionMemberDto) => {
	return {
		type: CommissionMembreActionsEnum.GET_MEMBRE_COMMISSION_SUCCESS,
		payload: { commissionMembre: result }
	}
}

export const errorCommissionMembre = (error:any) => {
	if(error.response?.exception?.message)
		toast.error(error.response.exception.message);
	else if(error.exception?.message)
		toast.error(error.exception.message);
	else
		toast.error("Impossible de récupérer le membre de commission");
	return {
		type: CommissionMembreActionsEnum.GET_MEMBRE_COMMISSION_ERROR,
		payload: {}
	}
}

export const updateRequestCommissionMembre = () => {
	return {
		type: CommissionMembreActionsEnum.PUT_MEMBRE_COMMISSION_REQUEST,
		payload: {}
	}
}

export const updateCommissionMembreAction = (result: UpdateCommissionMemberDto) => {
	return {
		type: CommissionMembreActionsEnum.PUT_MEMBRE_COMMISSION_SUCCESS,
		payload: { commissionMembre: result }
	}
}

export const errorUpdateCommissionMembre = () => {
	return {
		type: CommissionMembreActionsEnum.PUT_MEMBRE_COMMISSION_ERROR,
		payload: {}
	}
}


/** Task */
const commissionMembreClient = new CommissionMemberClient('', axiosInstance);

export const fetchCommissionMembres = (
	dispatch: Dispatch<DispatchCommissionMembreAction>,
	disablePagination: boolean = false,
	search?: string,
	sort?: string,
	page: number = 1,
	pageSize: number = 3,
) => {
	const filters: string[] = [];

	/**
	 * Le comparateur (@= ou ==) doit directement être inclu dans la variable
	 * @= --> CONTAINS
	 * == --> STRICTEMENT EGAL
	 *
	 * Cf. https://github.com/Biarity/Sieve#send-a-request
	 *
	 */

	if(search) {
		filters.push(`${searchCommissionMemberFilterKey}${search}`);
	}

	return () => {
		dispatch(requestCommissionMembres());
		return commissionMembreClient.getCommissionsMembers(filters.join(), sort, page, pageSize, disablePagination)
			.then(response => dispatch(getCommissionMembres(response)))
			.catch(error => dispatch(errorCommissionMembres(error)));
	}
}

export const createCommissionMembre = (
	dispatch: Dispatch<DispatchCommissionMembreAction>,
	body: CreateCommissionMemberDto,
	onClose: () => void
) => {
	return () => {
		dispatch(requestCreateCommissionMembre());

		return commissionMembreClient.createCommissionMember(body)
			.then(response => {
				dispatch(createCommissionMembreAction(response));
				onClose();
			})
			.catch(error => {
				dispatch(errorCreateCommissionMembre());
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de créer le membre de commission");
				return Promise.reject(error);
			});
	}
}

export const fetchCommissionMembre = (
	dispatch: Dispatch<DispatchCommissionMembreAction>,
	id: string
) => {
	return () => {
		dispatch(requestCommissionMembre());
		return commissionMembreClient.getCommissionsMember(id)
			.then(response => dispatch(getCommissionMembre(response)))
			.catch(error => dispatch(errorCommissionMembre(error)));
	}
}

export const updateCommissionMembre = (
	dispatch: Dispatch<DispatchCommissionMembreAction>,
	id: string,
	body: UpdateCommissionMemberDto,
	onClose: () => void
) => {
	return () => {
		dispatch(updateRequestCommissionMembre());
		return commissionMembreClient.updateCommissionMember(id, body)
			.then(response => {
				dispatch(updateCommissionMembreAction(response));
				onClose();
			})
			.catch(error => {
				dispatch(errorCreateCommissionMembre());
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de mettre à jour le membre de commission");
				return Promise.reject(error);
			});
	}
}