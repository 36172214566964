import { Action } from 'redux';
import { StructureState } from '../states/structureState';
import { StructureClient, UpdateRIBValidationDto } from '../../../services/generated/BackOffice-api';
import { nomFilterKey, emailFilterKey, statutFilterKey, userNameFilterKey, responsableFilterKey, siretFilterKey } from "../../../constants/filters.constant";
import { toast } from 'react-toastify';
import { AdelStructureClient, CreateOrUpdateDonneesBancairesDto } from '../../../clients/AdelStructureClient';
import { Dictionary } from 'lodash';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum StructureActionsEnum {
	// Structures list
	GET_STRUCTURE_REQUEST = "GET_STRUCTURE_REQUEST",
	GET_STRUCTURE_SUCCESS = "GET_STRUCTURE_SUCCESS",
	GET_STRUCTURE_ERROR = "GET_STRUCTURE_ERROR",

	//Structure filter	
	UPDATE_STRUCTURE_FILTRE_SUCCESS = "UPDATE_STRUCTURE_FILTRE_SUCCESS",

	// Structure autocomplete
	GET_AUTOCOMPLETE_NOM_REQUEST = "GET_AUTOCOMPLETE_NOM_REQUEST",
	GET_AUTOCOMPLETE_NOM_SUCCESS = "GET_AUTOCOMPLETE_NOM_SUCCESS",
	GET_AUTOCOMPLETE_EMAIL_REQUEST = "GET_AUTOCOMPLETE_EMAIL_REQUEST",
	GET_AUTOCOMPLETE_EMAIL_SUCCESS = "GET_AUTOCOMPLETE_EMAIL_SUCCESS",
	GET_AUTOCOMPLETE_RESPONSABLE_REQUEST = "GET_AUTOCOMPLETE_RESPONSABLE_REQUEST",
	GET_AUTOCOMPLETE_SIRET_REQUEST = "GET_AUTOCOMPLETE_SIRET_REQUEST",
	GET_AUTOCOMPLETE_RESPONSABLE_SUCCESS = "GET_AUTOCOMPLETE_RESPONSABLE_SUCCESS",
	GET_AUTOCOMPLETE_SIRET_SUCCESS = "GET_AUTOCOMPLETE_SIRET_SUCCESS",
	// Structure détails
	GET_STRUCTURE_DETAILS_REQUEST = "GET_STRUCTURE_DETAILS_REQUEST",
	GET_STRUCTURE_DETAILS_SUCCESS = "GET_STRUCTURE_DETAILS_SUCCESS",
	GET_STRUCTURE_DETAILS_ERROR = "GET_STRUCTURE_DETAILS_ERROR",
	UPDATE_STRUCTURE_REQUEST = "UPDATE_STRUCTURE_REQUEST",
	UPDATE_STRUCTURE_SUCCESS = "UPDATE_STRUCTURE_SUCCESS",
	UPDATE_STRUCTURE_ERROR = "UPDATE_STRUCTURE_ERROR",
	DELETE_STRUCTURE_SUCCESS = "DELETE_STRUCTURE_SUCCESS",
	DELETE_STRUCTURE_ERROR = "DELETE_STRUCTURE_ERROR",
	DELETE_STRUCTURE_REQUEST = "DELETE_STRUCTURE_REQUEST",

	// Structure relations
	GET_STRUCTURE_RELATIONS_REQUEST = "GET_STRUCTURE_RELATIONS_REQUEST",
	GET_STRUCTURE_RELATIONS_SUCCESS = "GET_STRUCTURE_RELATIONS_SUCCESS",
	GET_STRUCTURE_RELATIONS_ERROR = "GET_STRUCTURE_RELATIONS_ERROR",
	
	ADD_STRUCTURE_RELATIONS_REQUEST = "ADD_STRUCTURE_RELATIONS_REQUEST",
	ADD_STRUCTURE_RELATIONS_SUCCESS = "ADD_STRUCTURE_RELATIONS_SUCCESS",
	ADD_STRUCTURE_RELATIONS_ERROR = "ADD_STRUCTURE_RELATIONS_ERROR",
	
	DELETE_STRUCTURE_RELATIONS_REQUEST = "DELETE_STRUCTURE_RELATIONS_REQUEST",
	DELETE_STRUCTURE_RELATIONS_SUCCESS = "DELETE_STRUCTURE_RELATIONS_SUCCESS",
	DELETE_STRUCTURE_RELATIONS_ERROR = "DELETE_STRUCTURE_RELATIONS_ERROR",

	// Structure instruction
	GET_STRUCTURE_INSTRUCTION_REQUEST = "GET_STRUCTURE_INSTRUCTION_REQUEST",
	GET_STRUCTURE_INSTRUCTION_SUCCESS = "GET_STRUCTURE_INSTRUCTION_SUCCESS",
	GET_STRUCTURE_INSTRUCTION_ERROR = "GET_STRUCTURE_INSTRUCTION_ERROR",
	POST_STRUCTURE_INSTRUCTION_REQUEST = "POST_STRUCTURE_INSTRUCTION_REQUEST",
	POST_STRUCTURE_INSTRUCTION_SUCCESS = "POST_STRUCTURE_INSTRUCTION_SUCCESS",
	POST_STRUCTURE_INSTRUCTION_ERROR = "POST_STRUCTURE_INSTRUCTION_ERROR",

	// Structure Documents
	GET_STRUCTURE_DOCUMENTS_REQUEST = "GET_STRUCTURE_DOCUMENTS_REQUEST",
	GET_STRUCTURE_DOCUMENTS_SUCCESS = "GET_STRUCTURE_DOCUMENTS_SUCCESS",
	GET_STRUCTURE_DOCUMENTS_ERROR = "GET_STRUCTURE_DOCUMENTS_ERROR",
	POST_STRUCTURE_DOCUMENTS_REQUEST = "POST_STRUCTURE_DOCUMENTS_REQUEST",
	POST_STRUCTURE_DOCUMENTS_SUCCESS = "POST_STRUCTURE_DOCUMENTS_SUCCESS",
	POST_STRUCTURE_DOCUMENTS_ERROR = "POST_STRUCTURE_DOCUMENTS_ERROR",
	RESET_FILES = "RESET_FILES",

	// Structure note
	GET_STRUCTURE_NOTE_REQUEST = "GET_STRUCTURE_NOTE_REQUEST",
	GET_STRUCTURE_NOTE_SUCCESS = "GET_STRUCTURE_NOTE_SUCCESS",
	GET_STRUCTURE_NOTE_ERROR = "GET_STRUCTURE_NOTE_ERROR",
	POST_STRUCTURE_NOTE_REQUEST = "POST_STRUCTURE_NOTE_REQUEST",
	POST_STRUCTURE_NOTE_SUCCESS = "POST_STRUCTURE_NOTE_SUCCESS",
	POST_STRUCTURE_NOTE_ERROR = "POST_STRUCTURE_NOTE_ERROR",
	
	POST_STRUCTURE_NOTE_PRO_REQUEST = "POST_STRUCTURE_NOTE_PRO_REQUEST",
	POST_STRUCTURE_NOTE_PRO_SUCCESS = "POST_STRUCTURE_NOTE_PRO_SUCCESS",
	POST_STRUCTURE_NOTE_PRO_ERROR = "POST_STRUCTURE_NOTE_PRO_ERROR",

	// structure message 
	GET_STRUCTURE_MESSAGES_REQUEST = "GET_STRUCTURE_MESSAGES_REQUEST",
	GET_STRUCTURE_MESSAGES_SUCCESS = "GET_STRUCTURE_MESSAGES_SUCCESS",
	GET_STRUCTURE_MESSAGES_ERROR = "GET_STRUCTURE_MESSAGES_ERROR",
	POST_STRUCTURE_MESSAGES_REQUEST = "POST_STRUCTURE_MESSAGES_REQUEST",
	POST_STRUCTURE_MESSAGES_SUCCESS = "POST_STRUCTURE_MESSAGES_SUCCESS",
	POST_STRUCTURE_MESSAGES_ERROR = "POST_STRUCTURE_MESSAGES_ERROR",
	CREATE_STRUCTURE_DISCUSSION_REQUEST = "CREATE_STRUCTURE_DISCUSSION_REQUEST",
	CREATE_STRUCTURE_DISCUSSION_SUCCESS = "CREATE_STRUCTURE_DISCUSSION_SUCCESS",
	CREATE_STRUCTURE_DISCUSSION_ERROR = "CREATE_STRUCTURE_DISCUSSION_ERROR",
	UPDATE_STATUT_DISCUSSION_REQUEST = "UPDATE_STATUT_DISCUSSION_REQUEST",
	UPDATE_STATUT_DISCUSSION_SUCCESS = "UPDATE_STATUT_DISCUSSION_SUCCESS",
	UPDATE_STATUT_DISCUSSION_ERROR = "UPDATE_STATUT_DISCUSSION_ERROR",
	UPDATE_STRUCTURE_DISCUSSION_LU_SUCCESS = "UPDATE_STRUCTURE_DISCUSSION_LU_SUCCESS",
	POST_STRUCTURE_MULTIPLE_MESSAGES_REQUEST = "POST_STRUCTURE_MULTIPLE_MESSAGES_REQUEST",
	POST_STRUCTURE_MULTIPLE_MESSAGES_SUCCESS = "POST_STRUCTURE_MULTIPLE_MESSAGES_SUCCESS",
	POST_STRUCTURE_MULTIPLE_MESSAGES_ERROR = "POST_STRUCTURE_MULTIPLE_MESSAGES_ERROR",
	GET_STRUCTURE_MESSAGES_OBJECT = "GET_STRUCTURE_MESSAGES_OBJECT",


	// structure history 
	GET_STRUCTURE_HISTORY_REQUEST = "GET_STRUCTURE_HISTORY_REQUEST",
	GET_STRUCTURE_HISTORY_SUCCESS = "GET_STRUCTURE_HISTORY_SUCCESS",

	// structure documents validation
	PUT_STRUCTURE_DOCUMENTS_VALIDATION_REQUEST = "PUT_STRUCTURE_DOCUMENTS_VALIDATION_REQUEST",
	PUT_STRUCTURE_DOCUMENTS_VALIDATION_SUCCESS = "PUT_STRUCTURE_DOCUMENTS_VALIDATION_SUCCESS",
	PUT_STRUCTURE_DOCUMENTS_VALIDATION_ERROR = "PUT_STRUCTURE_DOCUMENTS_VALIDATION_ERROR",
	
	DOC_VALIDATION_DONNEES_BANCAIRES_ERROR = "DOC_VALIDATION_DONNEES_BANCAIRES_ERROR",
	DOC_VALIDATION_DONNEES_BANCAIRES_SUCCESS = "DOC_VALIDATION_DONNEES_BANCAIRES_SUCCESS",

	// Données bancaires
	UPDATE_DONNEES_BANCAIRES_ERROR = "UPDATE_DONNEES_BANCAIRES_ERROR",
	UPDATE_DONNEES_BANCAIRES_SUCCESS = "UPDATE_DONNEES_BANCAIRES_SUCCESS"
}


export interface DispatchStructureAction extends Action<StructureActionsEnum> {
	payload: StructureState;
}


export const requestStructures = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_REQUEST,
		payload: {}
	}
}

export const getStructures = (result: any) => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_SUCCESS,
		payload: result
	}
}

export const requestAutocompleteNom = () => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_NOM_REQUEST,
		payload: {}
	}
}

export const getAutocompleteNom = (result: any) => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_NOM_SUCCESS,
		payload: result
	}
}

export const requestAutocompleteEmail = () => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_EMAIL_REQUEST,
		payload: {}
	}
}

export const getAutocompleteEmail = (result: any) => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_EMAIL_SUCCESS,
		payload: result
	}
}

export const requestAutocompleteResponsable = () => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_RESPONSABLE_REQUEST,
		payload: {}
	}
}

export const requestAutocompleteSiret = () => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_SIRET_REQUEST,
		payload: {}
	}
}

export const getAutocompleteResponsable = (result: any) => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_RESPONSABLE_SUCCESS,
		payload: result
	}
}

export const getAutocompleteSiret = (result: any) => {
	return {
		type: StructureActionsEnum.GET_AUTOCOMPLETE_SIRET_SUCCESS,
		payload: result
	}
}

/** Task */
const structureClient = new StructureClient('', axiosInstance);
const adelStructureClient = new AdelStructureClient('', axiosInstance);

export const fetchStructures = (
	dispatch: any,
	disablePagination: boolean = false,
	nom?: string,
	siret?: string,
	email?: string,
	statut?: string,
	username?: string,
	responsable?: string,
	page: number = 1,
	pageSize: number = 3,
	sort?: string
) => {
	let usernameFilter = "";
	let nomFilter = "";
	let siretFilter = "";
	let emailFilter = "";
	let statutFilter = "";
	let responsableFilter = "";

	/**
	 * Le comparateur (@= ou ==) doit directement être inclu dans la variable
	 * @= --> CONTAINS
	 * == --> STRICTEMENT EGAL
	 * 
	 * Cf. https://github.com/Biarity/Sieve#send-a-request
	 * 
	 */
	if (nom) {
		nomFilter = `${nomFilterKey}${nom},`;
	}

	if (email) {
		emailFilter = `${emailFilterKey}${email},`;
	}

	if (statut) {
		statutFilter = `${statutFilterKey}${statut},`;
	}

	if (username) {
		usernameFilter = `${userNameFilterKey}${username},`;
	}

	if (responsable) {
		responsableFilter = `${responsableFilterKey}${responsable}`;
	}

	if (siret) {
		siretFilter = `${siretFilterKey}${siret}`;
	}

	const filters: string = `${nomFilter} ${siretFilter} ${emailFilter} ${statutFilter} ${usernameFilter} ${responsableFilter}`;

	return () => {
		dispatch(requestStructures());
		return structureClient.getStructures(filters, sort, page, pageSize, disablePagination)
			.then((response: any) => dispatch(getStructures(response)))
			.catch((error: any) => {
				dispatch({ type: StructureActionsEnum.GET_STRUCTURE_ERROR });
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les structures");
		})
	}
}

export const updateFilter = (result: any) => {
	return {
		type: StructureActionsEnum.UPDATE_STRUCTURE_FILTRE_SUCCESS,
		payload: result
	}
}

export const fetchAutocompleteNom = (dispatch: any, nom: string) => {
	return () => {
		dispatch(requestAutocompleteNom());
		return structureClient.autocompleteStructureName(nom)
			.then((response: any) => dispatch(getAutocompleteNom(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de faire l'autocomplétion du nom de structure");
			})
	}
}

export const fetchAutocompleteEmail = (dispatch: any, email: string) => {
	return () => {
		dispatch(requestAutocompleteEmail());
		return structureClient.autocompleteResponsableEmail(email)
			.then((response: any) => dispatch(getAutocompleteEmail(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de faire l'autocomplétion de l'email du responsable");
			})
	}
}

export const fetchAutocompleteResponsable = (dispatch: any, responsable: string) => {
	return () => {
		dispatch(requestAutocompleteResponsable());
		return structureClient.autocompleteResponsableName(responsable)
			.then((response: any) => dispatch(getAutocompleteSiret(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de faire l'autocomplétion du nom de responsable");
		})
	}
}


export const fetchAutocompleteSiret = (dispatch: any, siret: string) => {
	return () => {
		dispatch(requestAutocompleteSiret());
		return structureClient.autocompleteSiret(siret)
			.then((response: any) => dispatch(getAutocompleteResponsable(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de faire l'autocomplétion du nom de responsable");
		})
	}
}

export const updateDonneesBancaires = async (dispatch: any, structureId: string, value: CreateOrUpdateDonneesBancairesDto, documents?:Dictionary<File>) => {
	try {
		await adelStructureClient.createOrUpdateDonneesBancaires(structureId, value, documents);
		dispatch({ type: StructureActionsEnum.UPDATE_DONNEES_BANCAIRES_SUCCESS });
	} catch(error) {
		dispatch({ type: StructureActionsEnum.UPDATE_DONNEES_BANCAIRES_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour les données bancaires");
		throw error;
	}
}

export const updateRIBValidation = async (dispatch: any, structureId: string, value: UpdateRIBValidationDto) => {
	try {
		await structureClient.updateRIBValidation(structureId, value);
		dispatch({ type: StructureActionsEnum.DOC_VALIDATION_DONNEES_BANCAIRES_SUCCESS });
	} catch(error) {
		dispatch({ type: StructureActionsEnum.DOC_VALIDATION_DONNEES_BANCAIRES_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour les données bancaires");
		throw error;
	}
}
