import React, { useState, useCallback, forwardRef, useEffect, ChangeEvent } from "react";
import { RouteComponentProps } from "@reach/router";
import clsx from "clsx";
interface CheckboxTableProps extends RouteComponentProps {
	defaultCheck: boolean;
	id: string;
	onCheck: (id: string, isChecked: boolean, e: ChangeEvent<HTMLInputElement>) => void;
	indeterminate?: boolean;
	className?: string;
}

const CheckboxTable: React.FunctionComponent<CheckboxTableProps> = forwardRef<HTMLInputElement, CheckboxTableProps>(({
	indeterminate,
	defaultCheck,
	id,
	onCheck,
	className
}, 
ref: any
) => {
	const [isChecked, setIsChecked] = useState<boolean>(defaultCheck);
	const defaultRef = React.useRef<HTMLInputElement>(null);
	const resolvedRef = ref || defaultRef;

	const onChange = useCallback((check: boolean, e: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(check);
		if(onCheck)
		onCheck(id, check, e);
	}, [setIsChecked, onCheck]);

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	  }, [resolvedRef, indeterminate])

	useEffect(() => {
		setIsChecked(defaultCheck);
	}, [defaultCheck]);
 
	return (
		<div className={clsx("checkbox table__check", className)}>
			<label htmlFor={id}>
				<input
					name={id} 
					id={id}
					ref={resolvedRef}
					type="checkbox"
					checked={isChecked}
					onChange={(e) => onChange(e.target.checked, e) }
				/>
				<div className="newCheckbox"></div>
			</label>
		</div>
	);
});

export default CheckboxTable;
