import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DiscussionDto, ObjetDiscussion } from '../../../services/generated/BackOffice-api';
import { createStructureDiscussion, getObjetsDiscussionsStructure, setDiscussionLu } from '../../../store/structure-store/actions/structureMessagesActions';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import clsx from 'clsx';
import { useStructureSelector } from '../../../store/store-helpers';

interface StructureMessagesListProps {
	structureId: string,
	discussions: DiscussionDto[],
	modalNewMsg: boolean;
	setModalNewMsg: (value: any) => void;
	selectedDiscussion?: DiscussionDto;
	setSelectedDiscussion: (value:DiscussionDto) => void;
}

const StructureMessagesList: React.FC<StructureMessagesListProps> = ({
	structureId,
	discussions,
	modalNewMsg,
	setModalNewMsg,
	selectedDiscussion,
	setSelectedDiscussion
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {structureDiscussionsLu, objectDiscussionStructure} = useStructureSelector();

	const desactivateAllStates = () => {
		setModalNewMsg(false);
		setModaleSelect(undefined);
	}

	const [modaleSelect, setModaleSelect] = useState<ObjetDiscussion>();
	const [optionsUtilisateur, setOptionsUtilisateur] = useState<AdelOption<ObjetDiscussion>[]>([]);

	const openModal = async () => {
		await getObjetsDiscussionsStructure(dispatch);
		setModalNewMsg(true);
	}

	useEffect(() => {
		if (objectDiscussionStructure) {
			const allOptions = objectDiscussionStructure.filter(x => x !== "none").map(x => ({
				value: x,
				label: t(`objetDiscussion.${x}`)
			}));
			setOptionsUtilisateur(allOptions);
		}
	}, [objectDiscussionStructure]);

	const addDiscussion = async () => {
		try {
			await createStructureDiscussion(dispatch, structureId, {objet: modaleSelect });
			desactivateAllStates();
		} catch(error) {
            toast.error(t('common.errors.fetch'));
		}
	}

	const handleDiscussionClick = (id?: string) => {
		if(id) {
			const discussion = discussions.find(d => d.id === id);
			if(discussion) {
				setSelectedDiscussion(discussion);
				setDiscussionLu(dispatch, id);
			}
		}
	};

	return (
		<div className="messageList">
			<div className="commission__contentHeaderButton" onClick={openModal} >
				<label>Entamer une discussion</label>

				<button className="categorieFormeJuridique-header__icon">
					<i className="far fa-plus"></i>
				</button>
			</div>

			{modalNewMsg &&
				<section className="userModal customModal">
					<div className="customModal__card">
						<div className="customModal__header">
							<h2>Entamer une discussion</h2>
						</div>
						<div className="customModal__main">
							<div className="customModal__item">
								<InputSelect
									name=""
									classname="inputSelect"
									label="Type d'objet"
									options={optionsUtilisateur}
									onChange={(elem) => {
										setModaleSelect(elem)
									}}
									value={modaleSelect}
								/>
							</div>
						</div>
						<div className="customModal__footer">
							<FormButton type="button" value="Annuler" onClick={desactivateAllStates}/>
							<FormButton type="submit" value="Ajouter" onClick={addDiscussion} disabled={!modaleSelect} />
						</div>
					</div>
				</section>
			}

			{discussions?.map((discussion) => (
				<div
					className={clsx(
						{
							"active": selectedDiscussion?.id === discussion.id,
							"nonLu": discussion.messages && discussion.messages.length > 0 && !structureDiscussionsLu?.includes(discussion.id || '')
						},
						"cardList"
					)}
					key={discussion.id}
				>
					<div className="cardList__item">
						<div className="cardList__header">
							<h4>{t(`objetDiscussion.${discussion.objet}`)}</h4>
						</div>
						<button className="isNotActive">{t(`statutDiscussion.${discussion.statut}`)}</button>
					</div>
					<a href="#" onClick={() => handleDiscussionClick(discussion.id)}></a>
				</div>
			))}

		</div>
	);
}

export default StructureMessagesList;