import React, { useState, useEffect, useCallback, useReducer, useRef, ChangeEventHandler, ChangeEvent, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MembreTableTab from './membres-tab/MembreTableTab';
import MembreDeleteTab from './membres-tab/MembreDeleteTab';
import MembreAddTab from './membres-tab/MembreAddTab';
import { useCommissionSelector } from '../../../store/store-helpers';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import CheckboxGeneric from '../../basics/CheckboxGeneric';
import { fetchCommissionMembres, fetchSigningSheets } from "../../../store/commission-store/actions/commissionActions";
import { fetchCommissionMembres as fetchCommissionMembresList } from '../../../store/commission-membre-store/actions/commissionMembreActions';
import { CommissionMemberDeroulementDto, CommissionMemberDto, CommissionMemberParticipationDto, CommissionTimeSlot } from "../../../services/generated/BackOffice-api";
import moment from "moment";
import { navigate, RouteComponentProps } from "@reach/router";
import { toast } from "react-toastify";
import { updateCommissionMembres } from "../../../store/commission-store/actions/commissionActions";
import { normalizeDate } from 'adel-shared/dist/utils/functions';
import useOnClickOutside from 'use-onclickoutside';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import Loader from 'react-loader-spinner';
import { sendStructureMultipleMessages } from "../../../store/structure-store/actions/structureMessagesActions";
import Checkbox from "../../basics/Checkbox";
import { downloadFile } from "../../../utils/functions";

export interface CommissionMembre extends CommissionMemberDeroulementDto {
	commissionsMembersId?: string;
	commissionMemberReplacedId?: string;
	commissionMemberReplacingId?: string;
	presence?: boolean;
}
export interface CommissionMembresList {
	id: string;
	date: string;
	timeslot: CommissionTimeSlot;
	membres: CommissionMembre[];
}

export interface CommissionMembreParticipation extends CommissionMemberParticipationDto {
	commissionMemberId?: string;
}

interface CommissionMembresActionPayload {
	tableId?: string;
	membreId?: string;
	isChecked?: boolean;
	tables?: CommissionMembresList[];
	membreToAdd?: CommissionMemberDto;
	memberToDelete?: CommissionMemberDto & CommissionMembre | null;
	replacingMember?: CommissionMemberDto & CommissionMembre | null;
	replacedMember?: CommissionMemberDto & CommissionMembre | null;
}

interface CommissionMembresAction {
	type: string;
	payload: CommissionMembresActionPayload;
}

const replaceMembre = (membres: CommissionMembre[], replacedMembreIndex: number, action: CommissionMembresAction) => {
	const replacingMember = {
		...action.payload.replacingMember,
		commissionMemberId: action.payload.replacingMember?.id,
		commissionMemberReplacedId: action.payload.replacedMember?.commissionMemberReplacedId || membres[replacedMembreIndex].commissionMemberId
	};

	membres[replacedMembreIndex].commissionMemberReplacingId = action.payload.replacingMember?.id;
	membres[replacedMembreIndex].presence = false;

	const existingReplacingMember = membres.findIndex(x => x.commissionMemberReplacedId === (action.payload.replacedMember?.commissionMemberReplacedId || action.payload.replacedMember?.commissionMemberId));

	if(existingReplacingMember > -1) {
		membres[existingReplacingMember] = replacingMember;
	} else {
		membres = [
			...membres,
			replacingMember
		];
	}

	return [...membres];
};

const commissionMembresReducer = (state: CommissionMembresList[], action: CommissionMembresAction): CommissionMembresList[] => {
	const currentTableIndex = state.findIndex(x => x.id === action.payload.tableId);
	switch(action.type) {
		case 'setTables':
			return action.payload.tables || [];
		case 'addMember':
			state[currentTableIndex] = {
				...state[currentTableIndex],
				membres: [
					...state[currentTableIndex]?.membres || [],
					{
						...action.payload.membreToAdd,
						commissionMemberId: action.payload.membreToAdd?.id,
						presence: false
					}
				]
			}
			return state;
		case 'deleteMember':
			state[currentTableIndex].membres = state[currentTableIndex].membres.filter(x => x.commissionMemberId !== action.payload.memberToDelete?.commissionMemberId);
			const replacedMembre = state[currentTableIndex].membres.find(x => x.commissionMemberId === action.payload.memberToDelete?.commissionMemberReplacedId);
			if(replacedMembre) {
				replacedMembre.commissionMemberReplacingId = undefined;
			}
			return state;
		case 'replaceMember':
			const replacedMembreIndex = state[currentTableIndex].membres.findIndex(x => x.commissionMemberId === (action.payload.replacedMember?.commissionMemberReplacedId || action.payload.replacedMember?.commissionMemberId));
			state[currentTableIndex].membres = replaceMembre(state[currentTableIndex].membres, replacedMembreIndex, action);
			return state;
		case 'deleteMemberForNextDays':
			for(let i = currentTableIndex; i < state.length; i++) {
				state[i].membres = state[i].membres.filter(x => x.commissionMemberId !== action.payload.memberToDelete?.commissionMemberId);
				const replacedMembre = state[i].membres.find(x => x.commissionMemberId === action.payload.memberToDelete?.commissionMemberReplacedId);
				if(replacedMembre) {
					replacedMembre.commissionMemberReplacingId = undefined;
				}
			}
			return state;
		case 'replaceMemberForNextDays':
			for(let i = currentTableIndex; i < state.length; i++) {
				const replacedMembreIndex = state[i]?.membres.findIndex(x => x.commissionMemberId === (action.payload.replacedMember?.commissionMemberReplacedId || action.payload.replacedMember?.commissionMemberId));
				if(replacedMembreIndex < 0) {
					continue;
				}
				state[i].membres = replaceMembre(state[i].membres, replacedMembreIndex, action);
			}
			return state;
		case 'changeMemberPresence':
			const currentMembreIndex = state[currentTableIndex].membres.findIndex(x => x.commissionMemberId === action.payload.membreId);
			state[currentTableIndex].membres[currentMembreIndex].presence = action.payload.isChecked;
			return state;
		default:
			return state;
	}
};

const groupMembresByParticipations = (acc: CommissionMembresList[], currentVal: CommissionMembre) => {
	if(!currentVal.participations?.length) return acc;

	for(const participation of currentVal.participations) {
		const id = `${moment(participation.date).format('DDMMYYYY')}-${participation.commissionTimeSlot}`;
		const currentTable = acc.find(x => x.id === id);

		if(currentTable && !currentTable.membres.some(x => x.commissionMemberId === currentVal.commissionMemberId)) {
			const membreToPush = {
				...currentVal,
				commissionsMembersId: participation.commissionsMembersId,
				presence: participation.presence
			};

			currentTable.membres.push(membreToPush);
		}
	}
	return acc;
};

const groupParticipationsByMembres = (acc: CommissionMembre[], currentVal: CommissionMembresList, i: number, src: CommissionMembresList[]) => {
	for(const membre of currentVal.membres) {

		if(!acc.some(x => x.commissionMemberId === membre.commissionMemberId)) {
			const membreToPush = membre;

			membreToPush.participations = src.reduce(
				(acc: CommissionMembreParticipation[], currentVal: CommissionMembresList) => {
					for(const membre of currentVal.membres) {
						if(!acc.some(x => moment(x.date).format('YYYY-MM-DD') === currentVal.date && x.commissionTimeSlot === currentVal.timeslot && x.commissionMemberId === membre.commissionMemberId)) {
							let participationToPush: CommissionMembreParticipation = {
								commissionMemberId: membre.commissionMemberId,
								date: normalizeDate(new Date(currentVal.date)),
								commissionTimeSlot: currentVal.timeslot,
								presence: membre.presence
							};
							
							const existingParticipation = src.find(x => moment(x.date).format('YYYY-MM-DD') === currentVal.date && x.timeslot === currentVal.timeslot)?.membres.find(x => x.commissionMemberId === membre.commissionMemberId)?.participations?.find(x => moment(x.date).format('YYYY-MM-DD') === currentVal.date && x.commissionTimeSlot === currentVal.timeslot);

							if(existingParticipation) {
								participationToPush.commissionsMembersId = existingParticipation.commissionsMembersId;
								participationToPush.commissionMemberReplacedId = existingParticipation.commissionMemberReplacedId;
							}

							if(membre.commissionMemberReplacedId && !participationToPush.commissionMemberReplacedId) {
								participationToPush.commissionMemberReplacedId = membre.commissionMemberReplacedId;
							}

							acc.push(participationToPush);
						}
					}
					return acc;
				}
			, []).filter(x => x.commissionMemberId === membreToPush.commissionMemberId);

			acc.push(membreToPush);
		}
	}
	return acc;
};

const setTables = (result: CommissionMemberDeroulementDto[], commissionDates: CommissionMembresList[]) => {
	const tables = result.reduce(groupMembresByParticipations, commissionDates);
	
	// Set replaced and replacing ids
	for(const table of tables) {
		for(const membre of table.membres) {
			if(membre.participations) {
				for(const participation of membre.participations) {
					if(participation.commissionMemberReplacedId && (participation.commissionsMembersId === membre.commissionsMembersId)) {
						const replacedMember = table.membres.find(x => x.commissionMemberId === participation.commissionMemberReplacedId);
						if(replacedMember) {
							replacedMember.commissionMemberReplacingId = membre.commissionMemberId;
							membre.commissionMemberReplacedId = participation.commissionMemberReplacedId;
						}
					}
				}
			}
		}
	}

	return tables;
};

const MembresTab: React.FunctionComponent<RouteComponentProps> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const commissionSelector = useCommissionSelector();
	const commission = commissionSelector.commission;
	const [tableId, setTableId] = useState<string>('');
	const [membersToExclude, setMembersToExclude] = useState<CommissionMembre[]>([]);
	const [membreToDelete, setMembreToDelete] = useState<CommissionMemberDto & CommissionMembre | null>(null);
	const [commissionMembres, dispatchCommissionMembres] = useReducer(commissionMembresReducer, []);
	const [options, setOptions] = useState<CommissionMemberDto[]>([]);
	const telechargementMenuRef = useRef(null);
	useOnClickOutside(telechargementMenuRef, () => setTelechargement(false));
	const allTimeslotsIndexes = useRef<string[]>([]);
	const [allTimeslotsChecked, setAllTimeslotsChecked] = useState<boolean>(false);

	const fetchMembresList = async() => {
		const result = await fetchCommissionMembresList(dispatch, true)();

		if(!("commissionMembres" in result.payload)) {
			return;
		}
		const options = result.payload.commissionMembres.items?.filter(i => i.estActif);
		if(options) {
			setOptions(options);
		}
	};

	useEffect(() => {
		(async () => {
			if(!commission?.id) return;

			let commissionDebut = moment(commission?.dateDebut);
			const commissionFin = moment(commission?.dateFin);
			const commissionNbDays = commissionFin.diff(commissionDebut, 'days') + 1;
			let commissionDates: CommissionMembresList[] = [];

			for(let i = 0; i < commissionNbDays; i++) {
				commissionDates = [...commissionDates, {
					id: `${commissionDebut.format('DDMMYYYY')}-${CommissionTimeSlot.Matin}`,
					date: commissionDebut.format('YYYY-MM-DD'),
					timeslot: CommissionTimeSlot.Matin,
					membres: []
				}, {
					id: `${commissionDebut.format('DDMMYYYY')}-${CommissionTimeSlot.ApresMidi}`,
					date: commissionDebut.format('YYYY-MM-DD'),
					timeslot: CommissionTimeSlot.ApresMidi,
					membres: []
				}];
				commissionDebut = commissionDebut.add(1, 'days');
			}

			const result = await fetchCommissionMembres(dispatch, commission.id);

			dispatchCommissionMembres({
				type: 'setTables',
				payload: {
					tables: setTables(result, commissionDates)
				}
			});
		})();
	}, [commission]);

	useEffect(() => {
		fetchMembresList();
	}, []);

	/** Suppression membre */
	const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const openDeleteModal = useCallback((tableId: string, membreToDelete: CommissionMemberDto & CommissionMembre | null, membersToExclude: CommissionMembre[] = []) => {
		setOpenDeleteModal(true);
		setMembreToDelete(membreToDelete);
		setMembersToExclude(membersToExclude);
		setTableId(tableId);
	},[]);
	const closeDeleteModal = useCallback(() => {
		setOpenDeleteModal(false);
	},[]);

	/** Ajout membre */
	const [isOpenAddModal, setOpenAddModal] = useState<boolean>(false);
	const openAddModal = useCallback((id: string, membersToExclude: CommissionMembre[] = []) => {
		setTableId(id);
		setMembersToExclude(membersToExclude);
		setOpenAddModal(true);
	},[]);
	const closeAddModal = useCallback(() => {
		setOpenAddModal(false);
	},[]);

	/** Telechargement */
	const [openTelechargement, setTelechargement] = useState<boolean>(false);
	const toggleOpenTelechargement = useCallback(() => {
		setTelechargement(!openTelechargement);

		if(openTelechargement === false) {
			setTelechargementSelected([]);
		}
	}, [setTelechargement, openTelechargement]);

	const [telechargementSelected, setTelechargementSelected] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const downloadDocuments = useCallback(async() => {
		if(!commission?.id) {
			return;
		}

		setIsLoading(true);
		const result = await fetchSigningSheets(commission.id, true, undefined, undefined, undefined, telechargementSelected.join('|'));
		setIsLoading(false);

		downloadFile(result, true);
	}, [telechargementSelected, commission]);

	const handleSubmit = async() => {
		if(!commission?.id) return;

		try {
			usePermUpdateCommissionMemberList && await updateCommissionMembres(dispatch, commission.id, {
				commissionId: commission.id,
				membres: commissionMembres.reduce(groupParticipationsByMembres, [])
			});
			toast.success(t('common.success'));
		} catch(error) {
			toast.error(t('common.errors.send'));
		}
	};

	const handleAddMembreSubmit = (membreToAdd: CommissionMemberDto & CommissionMembre | null) => {
		const currentTable = commissionMembres.find(x => x.id === tableId);
		if(!currentTable || !membreToAdd) return;

		delete membreToAdd?.estActif;
        delete membreToAdd?.estInterne;
		delete membreToAdd?.emploi;

		dispatchCommissionMembres({
			type: 'addMember',
			payload: {
				tableId,
				membreToAdd
			}
		});
		closeAddModal();
	};

	const handleDeleteMembreSubmit = (memberToDelete: CommissionMemberDto & CommissionMembre | null, allNext: boolean) => {
		dispatchCommissionMembres({
			type: allNext ? 'deleteMemberForNextDays' : 'deleteMember',
			payload: {
				tableId,
				memberToDelete
			}
		});
		closeDeleteModal();
	};

	const handleReplaceMembre = (replacedMember: CommissionMemberDto & CommissionMembre | null, replacingMember: CommissionMemberDto | null, allNext: boolean) => {
		dispatchCommissionMembres({
			type: allNext ? 'replaceMemberForNextDays' : 'replaceMember',
			payload: {
				tableId,
				replacedMember,
				replacingMember
			}
		});
		closeDeleteModal();
	};

	const handleMembreCheck = (tableId: string, membreId: string, isChecked: boolean) => {
		dispatchCommissionMembres({
			type: 'changeMemberPresence',
			payload: {
				tableId,
				membreId,
				isChecked
			}
		});
	};



	/** Permissions */
	const usePermGetCommissionSigningSheetsPdf = useHasPermission("GetCommissionSigningSheetsPdf");
	const usePermUpdateCommissionMemberList = useHasPermission("UpdateCommissionMemberList");

	const handleAllTimeslotsChange = (e: ChangeEvent<HTMLInputElement>) => {
		setAllTimeslotsChecked(!allTimeslotsChecked);
		setTelechargementSelected(allTimeslotsIndexes.current);
	};

	return (
		<section className="membresTab">
			<div className="membresTab__telechargement">
				<div className="membresTab__telechargementContent">
					<FormButton
						type="button"
						value="Télécharger les feuilles d'émargement"
						onClick={toggleOpenTelechargement}
					/>
					{openTelechargement && (
						<div className="membresTab__telechargementSelect" ref={telechargementMenuRef}>
							<ul>
								<li>
									<CheckboxGeneric
										label="Tout sélectionner"
										id="selectAllTimeslots"
										onChange={handleAllTimeslotsChange}
										checked={allTimeslotsChecked}
									/>
								</li>
								{commissionMembres.map(x => {
									const timeslotIndex = Object.keys(CommissionTimeSlot).indexOf(`${x.timeslot.charAt(0).toUpperCase()}${x.timeslot.substr(1)}`);
									const timeslotValue = `${moment(x.date).format('YYYY-MM-DD')}$${timeslotIndex}`;

									if(!allTimeslotsIndexes.current.includes(timeslotValue)) {
										allTimeslotsIndexes.current.push(timeslotValue);
									}

									return (
										<li key={x.id}>
											<CheckboxGeneric
												label={`${moment(x.date).format('DD.MM.YYYY')} - ${t(`commission.timeslot.${x.timeslot}`)}`}
												id={timeslotValue}
												items={[...telechargementSelected]}
												setItems={setTelechargementSelected}
												checked={allTimeslotsChecked}
											/>
										</li>
									)
								})}
							</ul>
							{usePermGetCommissionSigningSheetsPdf &&
								<div className="membresTab__telechargementButton">
									{isLoading
										? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
										: <FormButton
											type="button"
											value="Télécharger"
											onClick={downloadDocuments}
										/>
									}
								</div>
							}
						</div>
					)}
				</div>
			</div>

			{commissionMembres.map((x, i) => (
				<div key={x.id} className="membresTab__item">
					<MembreTableTab
						title={`Commission du ${moment(x.date).format('DD.MM.YYYY')} - ${t(`commission.timeslot.${x.timeslot}`)}`}
						dataArray={x.membres}
						index={i}
						openAddModal={openAddModal}
						openDeleteModal={openDeleteModal}
						tableId={x.id}
						onCheck={handleMembreCheck}
					/>
				</div>
			))}

			<MembreDeleteTab
				isOpen={isOpenDeleteModal}
				onClose={closeDeleteModal}
				membreToDelete={membreToDelete}
				onReplace={handleReplaceMembre}
				onSubmit={handleDeleteMembreSubmit}
				membres={options}
				membersToExclude={membersToExclude}
			/>

			<MembreAddTab
				isOpen={isOpenAddModal}
				onClose={closeAddModal}
				onSubmit={handleAddMembreSubmit}
				membres={options}
				membersToExclude={membersToExclude}
			/>

			<div className="editCommission__footer">
				<FormButton
					className="button__cancel"
					type="button"
					value="Annuler"
					onClick={() => navigate("/Commission/ListeCommissions")}
				/>
				{usePermUpdateCommissionMemberList && <FormButton
					type="submit"
					value="Enregistrer"
					onClick={handleSubmit}
				/>}
			</div>
		</section>
	);
};

export default MembresTab;
