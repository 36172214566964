import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import React from 'react';
import { useStructureSelector } from '../../../store/store-helpers';

interface JuridiqueProps {
}

const Juridique: React.FC<JuridiqueProps> = ({}) => {
	const {structureDetails={}} = useStructureSelector();

	return (
		<>
			<div className="detailsTab__row">
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Raison sociale de la structure"
							content={structureDetails.raisonSociale || '-'}
						/>
					</div>
				</div>
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Statut juridique"
							content={structureDetails.formeJuridique?.nom || '-'}
						/>
					</div>
				</div>
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Siret"
							content={structureDetails.siret || '-'}
						/>
					</div>
				</div>
			</div>
			<div className="detailsTab__row">
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Code APE"
							content={structureDetails.codeAPE || '-'}
						/>
					</div>
				</div>
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Nomenclature APE"
							content={structureDetails.nomenclatureAPE || '-'}
						/>
					</div>
				</div>
				<div className="detailsTab__column"></div>
			</div>
			<div className="detailsTab__row">
				<div className="detailsTab__column detailsTab__column--fullWith">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Informations relatives à votre structure juridique (texte reproduit sur tous vos futurs dossiers de demande d'aide)"
							content={structureDetails.informations || '-'}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default Juridique;
