import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import PagesContainer from '../../containers/PagesContainer';
import Modal from 'react-modal';
import { modalLargeCustomStyles } from '../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import WysiwygEditor from 'adel-shared/dist/components/WysiwygEditor';
import { TemplateClient, TemplateDto } from '../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from '../../custom-hooks/useAxios';
import useHasPermission from '../../custom-hooks/useHasPermission';
interface LettresProps extends RouteComponentProps {
}

interface Template extends TemplateDto {
	title?: string;
}

const templates = [
	{
		id: '1',
		code: 'festival',
		title: "Dossiers acceptés de type « Festival »"
	},
	{
		id: '2',
		code: 'soiree-fortissimo',
		title: "Dossiers acceptés de types « Soirée des artistes » et « Fortissimo »"
	},
	{
		id: '3',
		code: 'deplacement-ecole-drama-musical',
		title: "Dossiers acceptés de types « Déplacement », « Ecole », « Spectacle dramatique, chorégraphique, cirque » et « Spectacle musical »"
	},
	{
		id: '4',
		code: 'bo-api',
		title: "Dossiers acceptés de types « BO » et « API/EPK »"
	}
];

const editorCustomButtons = [
	{
		name: 'tags',
		list: {
			'{{raisonsociale}}': 'Raison sociale',
			'{{civiliteresponsabledossier}}': 'Civilité du responsable dossier',
			'{{nomresponsabledossier}}': 'Nom du responsable dossier',
			'{{prenomresponsabledossier}}': 'Prénom du responsable dossier',
			'{{ligne1adressecorrespondance}}': 'Ligne 1 de l\'adresse de correspondance',
			'{{ligne2adressecorrespondance}}': 'Ligne 2 de l\'adresse de correspondance',
			'{{codepostalcorrespondance}}': 'Code postal de correspondance',
			'{{villecorrespondance}}': 'Ville de correspondance',
			'{{datejour}}': 'Date du jour de téléchargement du PDF',
			'{{numerodossier}}': 'Numéro de dossier',
			'{{numeropp}}': 'Numéro de structure',
			'{{datecommission}}': 'Date de commission'
		},
		exec: function (control: any, originalEvent: any, button: any) {
			const [value] = button.control.args;
			control.s.insertHTML(value);
		}
	}
];

const Lettres: FunctionComponent<LettresProps> = () => {
	const { t } = useTranslation(); 
	const templateClient = new TemplateClient('', axiosInstance);
	const [isOpenModal, openModal, closeModal] = useBooleanState(false);
	const [currentLettre, setCurrentLettre] = useState<Template>({});

	const usePermGetTemplateByCode = useHasPermission("GetTemplateByCode");
	const usePermUpdateTemplate = useHasPermission("UpdateTemplate");

	const openEdit = async(code: string, title: string) => {
		if(!usePermGetTemplateByCode)
			return;
		const { id, content } = await templateClient.getTemplateByCode(code);

		setCurrentLettre({
			id,
			title,
			content
		});
		openModal();
	}

	const handleChange = (content: string) => {
		setCurrentLettre({
			...currentLettre,
			content: content
		});
	};

	const handleSubmit = async () => {
		if(!currentLettre.id) return;

		if(usePermUpdateTemplate) {
			try {
				await templateClient.updateTemplate(
					currentLettre.id,
					{ content: currentLettre.content }
				);
				toast.success('Le template a été mis à jour avec succès.');
			} catch {
				toast.error(t('common.errors.send'));
			}
	
			closeModal();
		}
	}

    return (
		<PagesContainer title="Administration - Gestion des lettres" pageClass="lettres">
			<div className="lettres">
				<ul>
					{templates.map(({ id, code, title }) => (
						<li key={id}>
							<div className="lettres__item" role="button" onClick={() => openEdit(code, title)}>
								{title}
							</div>
						</li>
					))}
				</ul>

				<Modal
					isOpen={isOpenModal}
					style={modalLargeCustomStyles}
					className="normal__modal lettresModal"
				>
					<div className="modal__header">
						<h3>Edition - {currentLettre.title}</h3>
					</div>
					<div className="modal__content">
						<WysiwygEditor
							editorValueContent={currentLettre.content || ''}
							setEditorValueContent={handleChange}
							customButtons={editorCustomButtons}
						/>
					</div>
					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={closeModal}
						/>
						<FormButton
							type="submit"
							value="Valider"
							onClick={handleSubmit}
						/>
					</div>
				</Modal>
			</div>
		</PagesContainer>
	)
};

export default Lettres;