import React, { useState, useCallback, useEffect, FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import InputRadioButton from 'adel-shared/dist/components/basics/InputRadioButton';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import ModalValidateCancel from '../../../basics/ModalValidateCancel';
import { CommissionMemberDto } from "../../../../services/generated/BackOffice-api";
import { useCommissionSelector } from "../../../../store/store-helpers";
import { CommissionMembre } from '../MembresTab';

interface MembreDeleteTabProps extends RouteComponentProps {
	isOpen: boolean;
	onClose: () => void;
	membreToDelete: CommissionMemberDto & CommissionMembre | null;
	onReplace: (replacedMember: CommissionMemberDto & CommissionMembre | null, replacingMember: CommissionMemberDto | null, allNext: boolean) => void;
	onSubmit: (deletedMember: CommissionMemberDto & CommissionMembre | null, allNext: boolean) => void;
	membres: CommissionMemberDto[];
	membersToExclude: CommissionMembre[];
}

const MembreDeleteTab: FunctionComponent<MembreDeleteTabProps> = ({
	isOpen,
	onClose,
	membreToDelete,
	onReplace,
	onSubmit,
	membres,
	membersToExclude
}) => {
	const [remplacerMembre, setRemplacerMembre] = useState<boolean>(false);
	const [appliquerModif, setAppliquerModif] = useState<boolean>(false);
	const [options, setOptions] = useState<AdelOption<CommissionMemberDto>[]>([]);
	const [membre, setMembre] = useState<CommissionMemberDto | null>(null);
	const commissionSelector = useCommissionSelector();
	const commission = commissionSelector.commission;

	const handleRemplacerMembreChange = useCallback((e) => {
		if(!e.target.checked) return;
		if(e.target.value === "true")
            setRemplacerMembre(true);
        else
            setRemplacerMembre(false);
	}, [setRemplacerMembre]);

	const handleAppliquerModifChange = useCallback((e) => {
		if(!e.target.checked) return;
		if(e.target.value === "true")
            setAppliquerModif(true);
        else
            setAppliquerModif(false);
	}, [setAppliquerModif]);

	const handleSubmit = () => {
		if(remplacerMembre) {
			if(membre?.id)
				onReplace(membreToDelete, membre, appliquerModif);
		} else {
			onSubmit(membreToDelete, appliquerModif);
		}
		setMembre(null);
	};

	const handleCancel = () => {
		setMembre(null);
		onClose();
	};

	useEffect(() => {
		setOptions(membres.filter(x => !membersToExclude.some(y => y.commissionMemberId === x.id)).map(x => ({
			label: `${x.prenom} ${x.nom} - groupe ${x.groupe?.toUpperCase()}`,
			value: x
		})) || []);
	}, [membres, membersToExclude]);

	return (
		<ModalValidateCancel
			isOpen={isOpen}
			onCancel={handleCancel}
			onValidate={handleSubmit}
			isLoading={false}
			title="Supprimer le membre"
			validateButtonLabel="Appliquer"
		>
			<div className="modal__item modalMembreTab">
				<p>Voulez-vous remplacer ce membre de la commission par un autre ?</p>
				<div className="modalMembreTab__buttons">
					<InputRadioButton
						label="Oui"
						id="remplacerMembreOui"
						checked={remplacerMembre === true}
						name="remplacerMembre"
						value="true"
						onChange={handleRemplacerMembreChange}
					/>
					<InputRadioButton
						label="Non"
						id="remplacerMembreNon"
						checked={remplacerMembre === false}
						name="remplacerMembre"
						value="false"
						onChange={handleRemplacerMembreChange}
					/>
				</div>

				{remplacerMembre && (
					<div className="modalMembreTab__select">
						<InputSelect
							name="membre"
							classname="inputSelect"
							options={options}
							onChange={(elem) => {
                            	setMembre(elem);
							}}
							value={membre}
							isSearchable
						/>
					</div>
				)}

				<p>{remplacerMembre ? "Souhaitez-vous appliquer cette modification pour l'ensemble des demi-journées restantes ?" : "Souhaitez vous supprimer ce membre pour l'ensemble des demi-journées restantes  ?"}</p>

				<div className="modalMembreTab__buttons">
					<InputRadioButton
						label="Oui"
						id="appliquerModifOui"
						checked={appliquerModif === true}
						name="appliquerModif"
						value="true"
						onChange={handleAppliquerModifChange}
					/>
					<InputRadioButton
						label="Non"
						id="appliquerModifNon"
						checked={appliquerModif === false}
						name="appliquerModif"
						value="false"
						onChange={handleAppliquerModifChange}
					/>
				</div>
			</div>
		</ModalValidateCancel>
	);
};

export default MembreDeleteTab;
