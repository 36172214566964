import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { fetchUser } from '../../store/user-store/actions/userAction';
import { useGeneralSelector, useUserDispatcher, useUserSelector } from '../../store/store-helpers';
import useOnClickOutside from 'use-onclickoutside';

import TopBar from "./TopBar";
import MenuBurger from "./MenuBurger";
import {BOCurrentUserDto } from '../../services/generated/BackOffice-api';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { useDispatch } from "react-redux";
import { closeMenu, toggleMenu } from "../../store/general-store/actions/generalAction";
import { Link } from "@reach/router";
import Logo from "../../assets/logo/logo_spedidam_blanc_sans_txt.svg";

const Menu = () => {
	const userSelector = useUserSelector();
	const {isMenuOpen} = useGeneralSelector();
	const dispatch = useDispatch();

	const [isOpenPagesVitrines, showPagesVitrines, hidePagesVitrines] = useBooleanState(false);
	const [isOpenPagesWarning, showPagesWarning, hidePagesWarning] = useBooleanState(false);

	const [user, setUser] = useState<BOCurrentUserDto>({});
	const dispatchUser = useUserDispatcher();

	useEffect(() => {
		if (userSelector && userSelector.isConnected) {
			fetchUser(dispatchUser)().then((result: any) => {
				setUser(result?.payload.user)
			});
		}
	}, [userSelector.isConnected]);

	const handleAnim = () => {
		if (isMenuOpen) {
			gsap.fromTo(".sideMenu__background",
				{ width: "0px" },
				{ width: "390px", duration: 0.2 }
			)

			gsap.fromTo(".sideMenu",
				{ display: "none", opacity: 0 },
				{ display: "block", opacity: 1, duration: 0.2, delay: 0.2 }
			)
		} else {
			gsap.killTweensOf(".sideMenu");
			gsap.to(".sideMenu",
				{ display: "none", opacity: 0, duration: 0.1 }
			)

			gsap.to(".sideMenu__background",
				{ width: "0px", duration: 0.2, delay: 0.1 }
			)
		}
	}

	useEffect(() => {
		handleAnim();
	}, [isMenuOpen]);

	const menuRef = useRef(null);
	useOnClickOutside(menuRef, (e) => {
		if (
			!isOpenPagesVitrines && 
			!isOpenPagesWarning && 
			!(e.target instanceof Element && e.target.matches('.menuBurger__checkbox')) &&
			isMenuOpen
		) {
			dispatch(closeMenu());
		}
	});

	const handleBurgerClick = () => {
		dispatch(toggleMenu());
		hidePagesVitrines();
		hidePagesWarning();
	};

	return (
		<>
			<TopBar
				user={user}
				onBurgerClick={handleBurgerClick}
			/>

			<div className={isMenuOpen && !showPagesVitrines ? "darkLayout" : ""}></div>

			<div className="menu" ref={menuRef}>
				<div className={`menuShadow ${isMenuOpen && "menuShadow__isOpen"}`}>
					<div className={isMenuOpen ? "sideMenu__background sideMenu__background--isOpen" : "sideMenu__background"}>
						<Link to="/" className="logo">
							<img src={Logo} alt="Spedidam" />
						</Link>
						<MenuBurger
							showPagesVitrines={showPagesVitrines}
							isOpenPagesVitrines={isOpenPagesVitrines}
							isOpenPagesWarning={isOpenPagesWarning}
							showPagesWarning={showPagesWarning}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Menu;