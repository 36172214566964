import React, { useState, FunctionComponent } from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import InputSelect from 'adel-shared/dist/components/basics/InputSelect';

import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Checkbox from '../../basics/Checkbox';
import { StatutStructure, DocumentDto, DocumentUploadInfoDto } from '../../../services/generated/BackOffice-api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sendStructureInstruction } from '../../../store/structure-store/actions/structureInstructionActions';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { toast } from 'react-toastify';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { Dictionary } from 'lodash';
import { useStructureSelector } from '../../../store/store-helpers';

interface StructureInstructionTabProps {
	structureId: string;
}

const StructureInstructionTab: FunctionComponent<StructureInstructionTabProps> = ({
	structureId
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	
	const {
		structureInstruction: instruction = {},
		structureInstructionFiles: instructionFiles = []
	} = useStructureSelector();
	const [statut, setStatut] = useState(instruction.statut);
	const [categoriesDossierSelectionnees, setCategoriesDossierSelectionnees] = useState(instruction.categoriesDossierSelectionnees || []);
    const [files, setFiles] = useState<File[]>(instructionFiles);
	const [documents, setDocuments] = useState<DocumentDto[]>(instruction.documents || []);
    const [isLoading, setIsLoading] = useState<boolean>(false);

	const options = () => {
		return Object.values(StatutStructure)
		.filter(x => x !== StatutStructure.None)
		.map(x => ({
			value: x,
			label: t(`structure.validation-status.${x}`)
		}))
	};

	const changeStatut = (elem: StatutStructure) => {
		setStatut(elem)
	}

	const addToCategoriesDossier = (id: string, isCheck: boolean) => {
		let index = -1;
		const tab = [...categoriesDossierSelectionnees]

		for (let i = 0; i < tab.length; i++) {
			if (tab[i] === id) {
				index = i;
			}
		}

		if (index === -1) {
			if (isCheck) {
				tab.push(id)
			}
		}

		if (index !== -1) {
			if (!isCheck) {
				tab.splice(index, 1);
			}
		}
		setCategoriesDossierSelectionnees(tab)
	}


	const send = async() => {
		if(structureId) {
			setIsLoading(true);

			const fileDictionary: Dictionary<File> = {};
			const documentsToSend: DocumentUploadInfoDto[] = documents.map(d => ({ id: d.id }));

			files.forEach(file => {
				const escapedFileName = file.name.replace(/\W/g, '');
				documentsToSend.push({
					partName: escapedFileName
				});
				fileDictionary[escapedFileName] = file;
			});

			try {
				await sendStructureInstruction(dispatch, structureId, statut, categoriesDossierSelectionnees, documentsToSend, fileDictionary, files);
				toast.success(t("toast.success"));
			} catch(error) {
				toast.error(t("toast.errors.send"));
			}

			setIsLoading(false);
		}
	}

	
	/** Permissions */
	const usePermUpdateStructureInstruction = useHasPermission("UpdateStructureInstruction");

	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        if (value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
            const escapedName = value.target.files[0].name.replace(/\W/g, '');
            if (files.findIndex(file => {
                const comparedEscapedName = file.name.replace(/\W/g, '');
                if (comparedEscapedName === escapedName) return true;
            }) > -1) {
                toast.error(t('dossier.errors.fileExisting'))
            } else {
                setFiles([...files, value.target.files[0]]);
            }
        } else {
            console.error("file added error");
        }
    }

    const handleFileDelete = (fileName: string) => {
        setFiles(files.filter(file => file.name !== fileName));
    }

    const handleExistingFileDelete = (documentId?: string) => {
        if (documentId === undefined) throw "No document id was found on selected document."
		setDocuments(documents.filter(d => d.id !== documentId));
    }

	return (
		<div className="detailsTab">
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Statut du compte</h3>
				<div className="detailsTab__instructionRow">

					<div className="detailsTab__column">
						<div className="detailsTab__content">
							<InputReadOnly
								label="Login"
								content={instruction.userName || '-'}
							/>
						</div>
						<div className="detailsTab__content">
							<InputReadOnly
								label="Date d'activation"
								content={instruction.dateActivation ? moment(instruction.dateActivation).format('DD/MM/YY à H:mm').toLocaleString() : '-'}
							/>
						</div>
					</div>

					<div className="detailsTab__column">
						<InputSelect
							name="statut"
							label="Statut"
							classname="inputSelect"
							options={options()}
							onChange={(elem) => changeStatut(elem)}
							value={statut}
						/>
						<div className="detailsTab__content">
							<InputReadOnly
								label="Date de modification"
								content={instruction.lastModifiedAt ? moment(instruction.lastModifiedAt).format('DD/MM/YY à H:mm').toLocaleString() : "-"}
							/>
						</div>
					</div>

					<div className="detailsTab__column">
						<div className="detailsTab__content">
							<InputReadOnly
								label="Date de création"
								content={instruction.createdAt ? moment(instruction.createdAt).format('DD/MM/YY à H:mm').toLocaleString() : "-"}
							/>
						</div>
					</div>

				</div>
			</div>

			<div className="detailsTab__section">
                <div className="collapsibleSection">
                    <h3 className="instructionTab__title">
                        <span>Pièces-jointes complémentaires</span>
                    </h3>
                    {documents.map(document => (
						<div key={document.id} className="instructionTab__file">
                            <a target="_blank" href={document.uri}>
								<i className="fas fa-file-upload"></i> {document.fileName}
							</a> <i
								className="far fa-trash-alt"
								onClick={() => handleExistingFileDelete(document.id)}
							></i>
                        </div>
					))}
                    {files.map((file, i) => (
						<div key={i} className="instructionTab__file">
                            <i className="far fa-file"></i> {file.name} <i
								className="far fa-trash-alt"
								onClick={() => handleFileDelete(file.name)}
							></i>
                        </div>
					))}
                    <div className="inputFile__buttons">
                        <label htmlFor="uploadDocument">
							Télécharger une pièce-jointe
						</label>
                        <input
                            type="file"
                            id="uploadDocument"
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
            </div>

			<div className="detailsTab__section">
				<h3 className="detailsTab__title">
					Type de dossier
				</h3>

				<div className="detailsTab__content">
					{instruction.categoriesDossier?.map(item => (
						<Checkbox
							key={item.id}
							id={item.id}
							value={item.id ? categoriesDossierSelectionnees.includes(item.id) : false}
							label={item.nom}
							addToCategoriesDossier={addToCategoriesDossier}
						/>
					))}
				</div>
			</div>

			{usePermUpdateStructureInstruction && (
				<div className="detailsTab__footer">
					{isLoading
						? <Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
						: <FormButton
							disabled={isLoading}
							type="submit"
							value="Valider"
							onClick={send}
						/>
					}
				</div>
			)}
		</div>
	);
}

export default StructureInstructionTab;