import React, { useState, useCallback, ChangeEvent } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { closeCommission } from '../../../store/commission-store/actions/commissionActions';
import Commentaires from 'adel-shared/dist/components/DossierDetails/Commentaires';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import { modalCustomStyles } from '../../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useCommissionSelector } from '../../../store/store-helpers';

interface ModalCloturerCommissionProps extends RouteComponentProps {
	id: string;
	setOpenModal: (value: boolean) => void;
	openModal: boolean
	handleRefreshCommissionsList?: () => void;
}

const ModalCloturerCommission: React.FunctionComponent<ModalCloturerCommissionProps> = ({
	id,
	openModal,
	setOpenModal,
	handleRefreshCommissionsList
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [commentaire, setCommentaire] = useState<string>("");
	const commissionSelector = useCommissionSelector();

	const cloturerSession = useCallback(async () => {
		try {		
			await closeCommission(dispatch, id, commentaire);
			setOpenModal(false);
			if(handleRefreshCommissionsList)
				handleRefreshCommissionsList();
			else 
				navigate('/Commission/ListeCommissions');
		} catch(error) {
		}
	}, [closeCommission, navigate, dispatch, commentaire]);

	return (
		<Modal
			isOpen={openModal}
			style={modalCustomStyles}
			className="normal__modal membresModal"
		>
			<>
				<div className="modal__header">
					<h3>Clôturer la phase d'étude des dossiers</h3>
				</div>

				<div className="modal__content">
					<div className="input">
						<label className="input__label">Commentaires (facultatif)</label>
						<Commentaires onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentaire(e.target.value)} />
					</div>
				</div>

				<div className="modal__footer">
					<FormButton
						type="button"
						value="Annuler"
						onClick={() => setOpenModal(false)}
					/>
					{
						commissionSelector.isLoading
						? <Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
						: <FormButton
							type="submit"
							value="Clôturer"
							onClick={cloturerSession}
						/>

					}
				</div>
			</>
		</Modal>
	);
}

export default ModalCloturerCommission;