import React from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useDossierSelector } from '../../../store/store-helpers';
import { formatNumber } from 'adel-shared/dist/utils/functions';


const DossiersNonSoldes: React.FunctionComponent = () => {
    const { dossierAdditionalInfo = {} } = useDossierSelector();

    return (
        <div className="sideInfo">
            <div className="sideInfo__header">
                <h3>Dossiers non soldés</h3>
            </div>

            <ul>
                {dossierAdditionalInfo.autreDossiersNonSoldes?.map(item => (
                    <li key={item.id}>
                        {item.nom} (N°{item.numero})

                        <div className="sideInfo__item">
                            <InputReadOnly
                                label="Type de dossier"
                                content={item.categorieNom || '-'}
                                theme="light"
                            />

                            <InputReadOnly
                                label="Montant attribué"
                                content={`${formatNumber(item.montantAttribue)} €` || '-'}
                                theme="light"
                            />

                            <InputReadOnly
                                label="Solde"
                                content={`${formatNumber(item.solde)} €` || '-'}
                                theme="light"
                            />
                        </div>
                    </li>
                ))}
			</ul>
        </div>
    );
}

export default DossiersNonSoldes;