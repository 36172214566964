import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { modalLargerCustomStyles } from '../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import { useTable, useSortBy, useRowSelect, CellProps } from 'react-table';
import { DoublonStructureDto } from '../../services/generated/BackOffice-api';
import moment from 'moment';
import { Link, navigate } from '@reach/router';

interface ModalDoublonStructureProps {
	isOpen: boolean;
	closeModal: () => void;
	doublons: DoublonStructureDto[];
}

const ModalDoublonStructure: FunctionComponent<ModalDoublonStructureProps> = ({
	isOpen,
	closeModal,
	doublons
}) => {
    const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		if(!doublons.length) return;

		setData(doublons.map(x => ({
			...x,
			numeroStructure: (
				<a href={`/Structure/${x.structureId}`} target="_blank">
					{x.numeroStructure}
				</a>
			),
			dateCreation: moment(x.dateCreation).format('DD/MM/YYYY'),
			correspondant: x.correspondant || '-',
		})));
	}, [doublons]);

	const renderDoublon = ({value}: CellProps<any>) => {
		const regex = /(<([^>]+)>)/g;
		if(regex.test(value)) {
			return <strong>{value.replace(regex, '')}</strong>
		}
		return value;
	};

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Identifiant structure",
						accessor: "numeroStructure",
						disableSortBy: true
					},
					{
						Header: "Nom structure",
						accessor: "nomStructure",
						disableSortBy: true
					},
					{
						Header: "Date de création",
						accessor: "dateCreation",
						disableSortBy: true
					},
					{
						Header: "Représentant légal (RL)",
						accessor: "responsable",
						disableSortBy: true,
						Cell: renderDoublon
					},
					{
						Header: "Correspondant administratif (CA)",
						accessor: "correspondant",
						disableSortBy: true,
						Cell: renderDoublon
					}
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			manualSortBy: true
		} as any,
		useSortBy,
		useRowSelect
    );

    return (
		<Modal isOpen={isOpen} style={modalLargerCustomStyles} className="normal__modal doublonsModal">
			<div className="modal__header">
				<h3>Doublons "Membre de structure"</h3>
			</div>
			<div className="modal__content">
				<Table
					className="table--doublons"
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.onglet}
				/>
			</div>
			<div className="modal__footer">
				<FormButton
					type="button"
					value="Fermer"
					onClick={closeModal}
				/>
			</div>
	</Modal>
	)
};

export default ModalDoublonStructure;