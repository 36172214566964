import { Action } from 'redux';
import { UserState, Permission } from '../states/userState';
import {
	UserClient,
	RoleClient,
	RoleDto,
	CreateUserDto,
	UpdateUserDto
} from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { Dispatch } from 'react';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum UserActionsEnum {
	CONNECT_USER = "CONNECT_USER",
	GET_USER_SUCCESS = "GET_USER_SUCCESS",
	LOGOUT_USER = "LOGOUT_USER",

	// Utilisateurs
	GET_USERS_REQUEST = "GET_USERS_REQUEST",
	GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
	GET_USERS_ERROR = "GET_USERS_ERROR",
	UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",

	// Roles
	GET_ROLES_REQUEST = "GET_ROLES_REQUEST",
	GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS",
	GET_ROLES_ERROR = "GET_ROLES_ERROR",

	// Créer utilisateur
	CREATE_USER_REQUEST = "CREATE_USER_REQUEST",
	CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
	CREATE_USER_ERROR = "CREATE_USER_ERROR",

	// Permissions
	GET_PERMISSIONS_REQUEST = "GET_PERMISSIONS_REQUEST",
	GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS",
	GET_PERMISSIONS_ERROR = "GET_PERMISSIONS_ERROR",
}

export interface DispatchUserAction extends Action<UserActionsEnum> {
	payload?: UserState;
}

/** */
export const getUser = (result: any) => {
	return {
		type: UserActionsEnum.GET_USER_SUCCESS,
		payload: { user: result }
	}
}

/** */
export const getUserRoles = (result: RoleDto[]) => {
	return {
		type: UserActionsEnum.GET_ROLES_SUCCESS,
		payload: { roles: result }
	}
}

/** */
export const requestCreateUser = () => {
	return {
		type: UserActionsEnum.CREATE_USER_REQUEST,
	}
}

export const createUserAction = () => {
	return {
		type: UserActionsEnum.CREATE_USER_SUCCESS
	}
}

export const errorCreateUser = (result: string) => {
	return {
		type: UserActionsEnum.CREATE_USER_ERROR,
		payload: { errorUserCode: result }
	}
}

/** */
export const getUserPermissions = (result: Permission) => {
	return {
		type: UserActionsEnum.GET_PERMISSIONS_SUCCESS,
		payload: { permissions: result }
	}
};

/** Tasks */
const client = new UserClient('', axiosInstance);

export const fetchUser = (dispatch: any) => {
	return () => {
		return client.getMe()
			.then((response: any) => dispatch(getUser(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer l'utilisateur");
			})
	}
}

export const fetchUsersByRole = async (dispatch: Dispatch<DispatchUserAction>, roles: string[] | any, search: string, actifs?: boolean) => {
	dispatch({ type: UserActionsEnum.GET_USERS_REQUEST });
	try {
		let result = await client.getUsers(roles?.find((e: string) => e === "all") ? [] : roles, search, actifs);
		dispatch({ type: UserActionsEnum.GET_USERS_SUCCESS, payload: { users: result } })
	} catch (error) {
		dispatch({ type: UserActionsEnum.GET_USERS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les utilisateurs par rôle");
	}
}

export const fetchUsersInstructionByRole = async (dispatch: Dispatch<DispatchUserAction>, roles: string[] | any, search: string, actifs?: boolean) => {
	dispatch({ type: UserActionsEnum.GET_USERS_REQUEST });
	try {
		let result = await client.getUsersInstruction(roles?.find((e: string) => e === "all") ? [] : roles, search, actifs);
		dispatch({ type: UserActionsEnum.GET_USERS_SUCCESS, payload: { users: result } })
	} catch (error) {
		dispatch({ type: UserActionsEnum.GET_USERS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les utilisateurs par rôle");
	}
}

const roles = new RoleClient('', axiosInstance);

export const fetchUserRoles = (dispatch: Dispatch<DispatchUserAction>) => {
	return roles.getRoles()
		.then(response => {
			dispatch(getUserRoles(response));
			return response;
		})
		.catch(error => {
			if(error.response?.exception?.message)
				toast.error(error.response.exception.message);
			else if(error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error("Impossible de récupérer les rôles");
			throw error;
		})
}

export const createUser = async(
	dispatch: Dispatch<DispatchUserAction>,
	body: CreateUserDto
) => {
	dispatch(requestCreateUser());

	try {
		const result = await client.createUser(body);
		dispatch(createUserAction());
		return result;
	} catch(error) {
		dispatch(errorCreateUser(error.response.data.code));
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de créer un utilisateur");
		throw error;
	}
};

export const updateUser = async(
	id: string,
	body: UpdateUserDto,
	dispatch: Dispatch<DispatchUserAction>
) => {
	try {
		const result = await client.updateUser(id, body);
		dispatch({ type: UserActionsEnum.UPDATE_USER_SUCCESS, payload: { updatedUser: result } })
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour l'utilisateur");
		throw error;
	}
};

export const fetchUserPermissions = async(dispatch: Dispatch<DispatchUserAction>) => {
	try {
		const result = await client.getActionPermissions();
		dispatch(getUserPermissions(result));
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les permissions");
		throw error;
	}
};