import React, { useState, useEffect } from 'react';
import { formatNumber } from '../../../utils/functions';
import TotalAmountsTable from '../../basics/TotalAmountsTable';
const ArtisteModalMontantsTotaux = ({ artiste }) => {
    const [totalsData, setTotalsData] = useState([]);
    useEffect(() => {
        if (!artiste)
            return;
        const updatedData = [];
        if (artiste.montantEnregistrement !== 0) {
            updatedData.push({
                label: 'Cachets d\'enregistrement',
                value: `${formatNumber(artiste.montantEnregistrement)} €`
            });
        }
        if (artiste.montantRepetitions !== 0) {
            updatedData.push({
                label: 'Cachets de répétition',
                value: `${formatNumber(artiste.montantRepetitions)} €`
            });
        }
        if (artiste.montantRepresentations !== 0) {
            updatedData.push({
                label: 'Cachets de représentation',
                value: `${formatNumber(artiste.montantRepresentations)} €`
            });
        }
        if (artiste.montantConcert !== 0) {
            updatedData.push({
                label: 'Cachets de concert',
                value: `${formatNumber(artiste.montantConcert)} €`
            });
        }
        if (artiste.montantCharge !== 0) {
            updatedData.push({
                label: 'Charges patronales',
                value: `${formatNumber(artiste.montantCharge)} €`
            });
        }
        if (artiste.mensualisations !== 0) {
            updatedData.push({
                label: 'Mensualisations',
                value: `${formatNumber(artiste.mensualisations)} €`
            });
        }
        setTotalsData(updatedData);
    }, [artiste]);
    return (React.createElement(TotalAmountsTable, { data: totalsData }));
};
export default ArtisteModalMontantsTotaux;
