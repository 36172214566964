import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import Pagination from 'adel-shared/dist/components/basics/Pagination';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import useValidation from '../../custom-hooks/useValidation';
import { GroupeCommission, StatutCommission, TypeBordereau } from '../../services/generated/BackOffice-api';
import { fetchCommissions, figerCommission, getCommissionHasParticipation, openCommission, updateFilter } from '../../store/commission-store/actions/commissionActions';
import { useCommissionSelector, useUserSelector } from '../../store/store-helpers';
import CommissionMenuTable from './CommissionMenuTable';
import ModalAjoutCommission from './modal/ModalAjoutCommission';
import useHasPermission from '../../custom-hooks/useHasPermission';
import { formatNumber, formaterDate } from 'adel-shared/dist/utils/functions';
import { paginationNumbers } from '../../constants/config.constant';

interface CommissionsProps extends RouteComponentProps {
}

const Commissions: React.FunctionComponent<CommissionsProps> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [modalAjout, setModalAjout] = useState<boolean>(false);
	const { commissions, commissionFilter } = useCommissionSelector();
	const { getRootValidator } = useValidation();
	const commissionValidator = getRootValidator("CreateOrUpdateCommissionDto");

	const {user = {}} = useUserSelector();

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	/** Filtres */
	const [anneeFiltre, setAnneeFiltre] = useState<string>(commissionFilter.annee);
	const [groupeFiltre, setGroupeFiltre] = useState<string>(commissionFilter.groupe);
	const [nomFiltre, setNomFiltre] = useState<string>(commissionFilter.nom);

	/** Table */
	const [data, setData] = useState<Array<any>>([]);
	const [sort, setSort] = useState<string>("");

	/** Permissions */
	const usePermCreateCommission = useHasPermission("CreateCommission");
	const usePermGetCommissions = useHasPermission("GetCommissions");
	
	/** Démarrer la commission*/
	const startComm = async (id:string) => {
		const canOpenCommission = await openCommission(dispatch, id, true);
		if (canOpenCommission) {
			navigate(`/Commission/${id}`)
			toast.success("La commission vient d'être démarrée");
		} else {
			navigate(`/Commission/Edit/${id}/membres`);
		}
	};
	
	/** Ouvrir la commission */
	const goToCommission = async (id:string) => {
		const hasMembers = await getCommissionHasParticipation(id);
		if (hasMembers) {
			dispatch(updateFilter(
				{
					annee: anneeFiltre,
					groupe: groupeFiltre,	
					nom: nomFiltre,						
				}))			
			navigate(`/Commission/${id}`)
		} else {
			navigate(`/Commission/Edit/${id}/membres`);
		}
	};

	/** figer commission */
	const figerComm = async (id:string) => {
		await figerCommission(dispatch, id);
	};

	/** refresh le tableau avec les states actuels de pagination etc */
	const refreshCommissionsList = async () => {
		await refreshCommissions(anneeFiltre, groupeFiltre, nomFiltre, page, pageSize, sort);
	};

	useEffect(() => {
		if (commissions?.items) {
			if (commissions.number &&
			commissions.totalPageCount &&
			(commissions.number > commissions.totalPageCount)) {
				setPage(1);
			}
			setHasNext(commissions.hasNext ?? false);
			setHasPrevious(commissions.hasPrevious ?? false);
			setIsFirst(commissions.isFirst ?? false);
			setIsLast(commissions.isLast ?? false);
			setTotalPageCount(commissions.totalPageCount ?? 0);
			setTotalItemCount(commissions.totalItemCount ?? 0);

			const array = commissions.items.map(com => ({
				Statut: <span className={`table__statutItem table__statut--${com.statut}`}>{t(`commission.status.${com.statut}`)}</span>,
				StatutOrigin: com.statut,
				DateDebut: formaterDate(com.dateDebut),
				DateFin: formaterDate(com.dateFin),
				Nom: com.nom,
				Id: com.id,
				Groupe: <span className="table__groupe">{com.groupe}</span>,
				PV: com.pv ? (<a href={com.pv} target="_blank"><i className="fas fa-file-upload"></i></a>) : '-',
				PVCA: com.pvca ? (<a href={com.pvca} target="_blank"><i className="fas fa-file-upload"></i></a>) : '-',
				Convocation: com.convocation ? (<a href={com.convocation} target="_blank"><i className="fas fa-file-upload"></i></a>) : '-',
				Dossier: com.dossier && (<Link to={`/Commission/Dossiers/${com.id}`} className="dossiers-link" data-nb-dossiers={com.dossier}>{com.dossier}</Link>),
				MontantDemande: `${com.montantDemande ? formatNumber(com.montantDemande) : '-'} €`,
				MontantAttribue: `${com.montantAttribue ? formatNumber(com.montantAttribue) : '-'} €`,
				Reaffectation: com.bordereauId && com.reaffectation && (<Link to={`/Comptabilite/Bordereau/${TypeBordereau.Reaffectation}/${com.bordereauId}`} className="dossiers-link">{formatNumber(com.reaffectation)} €</Link>),
				Actions: com
			}));
			setData(array);
		}
	}, [commissions]);

	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Statut", accessor: "Statut", sortType: "basic" },
					{ Header: "Nom", accessor: "Nom", sortType: "basic",
						Cell: (value:any) => {
							if (usePermGetCommissions && (value.cell.row.values.StatutOrigin === StatutCommission.EnCours
								|| value.cell.row.values.StatutOrigin === StatutCommission.CaEnCours
								|| value.cell.row.values.StatutOrigin === StatutCommission.EnAttenteCA
								|| value.cell.row.values.StatutOrigin === StatutCommission.Figee
								|| value.cell.row.values.StatutOrigin === StatutCommission.Terminee
								))
								return <span className="button--link" onClick={() => goToCommission(value.cell.row.values.Id)}>{value.value}</span>
							else
								return value.value
						}
					},
					{ Header: "Date de début", accessor: "DateDebut", sortType: "basic" },
					{ Header: "Date de fin", accessor: "DateFin", sortType: "basic" },
					{ Header: "Groupe", accessor: "Groupe", sortType: "basic" },
					{ Header: "PV", accessor: "PV", disableSortBy: true },
					// { Header: "PV CA", accessor: "PVCA", disableSortBy: true },
					{ Header: "Convocation", accessor: "Convocation", disableSortBy: true },
					{ Header: "Dossier", accessor: "Dossier", sortType: "basic" },
					{ Header: "Montant demandé", accessor: "MontantDemande", sortType: "basic", className:'table__cell--montants' },
					{ Header: "Montant accordé", accessor: "MontantAttribue", sortType: "basic", className:'table__cell--montants' },
					{ Header: "Réaffectation", accessor: "Reaffectation", sortType: "basic", className:'table__cell--montants' },
					{ Header: "", accessor: "Actions", disableSortBy: true,
						Cell: (value: any) => (
							<CommissionMenuTable
								id={value.value.id}
								statut={value.value.statut}
								startComm={startComm}
								figerComm={figerComm}
								dateDebut={value.value.dateDebut}
								handleRefreshCommissionsList={refreshCommissionsList}
							/>
						)
					},
					{ accessor: "Id" },
					{ accessor: "StatutOrigin" }
				]
			}
		];
	}, [user, usePermGetCommissions, page, pageSize, anneeFiltre, groupeFiltre, nomFiltre, page, pageSize, sort]);

	const hiddenColumns = () => {
		const array = ["Id", "StatutOrigin"];

		if(
			user.roles &&
			user.roles.includes("Membre de commission") &&
			!user.roles.includes("Responsable de commissions") &&
			!user.roles.includes("Chargé d'instruction Division Culturelle") &&
			!user.roles.includes("Chargé d'instruction Spectacle Vivant (DSV)")
		)
			array.push("PV", "PVCA", "Convocation", "Actions")
		return array;
	}

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: hiddenColumns(),
				sortBy: [
					{
						id: 'DateDebut',
						desc: true
					}
				]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);



	const optionsGroupe = () => {
		const options = Object.entries(GroupeCommission)
		.filter(([,value]) => value !== GroupeCommission.None && value !== GroupeCommission.Suppleant)
		.map(([label, value]) => ({
			value,
			label
		}));
		return [
			{ value: "all", label: "Tous les groupes" },
			...options
		];
	}

	const onChangeGroupe = (elem: any) => {
		if(elem !== "all") {
			setGroupeFiltre(elem);
		} else {
			setGroupeFiltre("all");
		}
	}

	const optionsAnnee = () => {
		let allOptions: AdelOption<number | string>[] = [];
		const currentYear = new Date().getFullYear();
		let startYear = 2010;
		while ( startYear <= currentYear ) {
			allOptions.push({
				value: startYear,
				label: `${startYear}`
			});
			startYear++;
		}
		return [{value: "all", label: "Toutes les années"}, ...allOptions.sort((a:any,b:any) => b.value - a.value)];
	}
	const onChangeAnnee = (elem: any) => {
		if(elem !== "all") {
			setAnneeFiltre(elem);
		} else {
			setAnneeFiltre("all");
		}
	}



	/** Get commissions */
	useEffect(() => {
		if(!usePermGetCommissions)
			return;
		(async () => {
			if(modalAjout === false)
            	await refreshCommissions(anneeFiltre, groupeFiltre, nomFiltre, page, pageSize, sort);
        })()
	}, [usePermGetCommissions, fetchCommissions, anneeFiltre, groupeFiltre, nomFiltre, page, pageSize, sort, modalAjout ]);

	const refreshCommissions  = useMemo(() => _.debounce(async (anneeFiltre:string, groupeFiltre:string, nomFiltre:string, page:number, pageSize:number, sort:string) => {
		try {
			let year;
			if (anneeFiltre !== "all") {
				year = parseInt(anneeFiltre);
			} else {
				year = undefined;
			}
			await fetchCommissions(dispatch, false, year, groupeFiltre !== "all" ? groupeFiltre : undefined, nomFiltre, page, pageSize, sort)();
        } catch (error) {
            toast.error(t('common.errors.fetch'));
		}
	}, 1000), []);

	return (
		<div className="commission">
			<div className="commission__header">
				{/** TODO: graph partie en rouge etc */}
			</div>
			<div className="commission__content">
				<div className="commission__contentHeader">
					<h1 className="commission__contentHeaderTitle">Liste des commissions</h1>
					{usePermCreateCommission &&
					<div className="commission__contentHeaderButton" onClick={() => { setModalAjout(true) } }>
						<label>Ajouter une commission</label>
						<button className="categorieFormeJuridique-header__icon">
							<i className="far fa-plus"></i>
						</button>
					</div>}
				</div>
				<div className="commission__filtres">
					<div className="filtres__item">
						<label>Filtres :</label>
						<div className="filtres__search">
							<InputSelect
								name="annee"
								classname={`filtreItem__inputSelect ${anneeFiltre !== "all" && "filtreItem__inputSelect--selected"}`}
								options={optionsAnnee()}
								onChange={onChangeAnnee}
								value={anneeFiltre}
							/>
							<InputSelect
								name="groupe"
								classname={`filtreItem__inputSelect ${groupeFiltre !== "all" && "filtreItem__inputSelect--selected"}`}
								options={optionsGroupe()}
								onChange={onChangeGroupe}
								value={groupeFiltre}
							/>
						</div>
					</div>
					<div className="filtres__item">
						<div className="filtres__search">
							<i className="fas fa-search"></i>
							<Input
								autoComplete={'off'}
								name="recherche"
								reference={null}
								className="filter__input"
								placeHolder="Recherche"
								type="text"
								maxLength={120}
								defaultValue={nomFiltre}
								onChange={setNomFiltre}
							/>
						</div>
					</div>
				</div>
				<div className="commission__table commissionList__table">
					<div className="commission__tableWrapper">
						<Table
							data={data}
							getTableProps={getTableProps}
							getTableBodyProps={getTableBodyProps}
							headerGroups={headerGroups}
							rows={rows}
							prepareRow={prepareRow}
							styles={styleTable.page}
						/>
						<Pagination
							hasNext={hasNext}
							hasPrevious={hasPrevious}
							isFirst={isFirst}
							isLast={isLast}
							totalPageCount={totalPageCount}
							totalItemCount={totalItemCount}
							page={setPage}
							pageSize={setPageSize}
							initPageSize={pageSize}
							initPage={page}
							pageNumbers={paginationNumbers}
						/>
					</div>
				</div>
			</div>
			{usePermCreateCommission && modalAjout &&
				<ModalAjoutCommission
					setModalAjout={setModalAjout}
					validator={commissionValidator}
				/>
			}
		</div>
	);
}

export default Commissions;