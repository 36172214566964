import { Reducer } from "redux";
import { TypeDeDocumentState, initialTypeDeDocumentState } from "../states/typeDeDocumentState";
import { DispatchTypeDeDocumentAction, TypeDeDocumentActionsEnum } from "../actions/typeDeDocumentAction";

export const typeDeDocumentReducer: Reducer<Partial<TypeDeDocumentState>, DispatchTypeDeDocumentAction> = (state = initialTypeDeDocumentState, action) => {
	switch (action.type) {
		case TypeDeDocumentActionsEnum.GET_DOCUMENTS_REQUEST:
			return {
				...state,
				documentsIsLoading: true
			};
		case TypeDeDocumentActionsEnum.GET_DOCUMENTS_SUCCESS:
			return {
				...state,
				documents: action.payload.documents,
				documentsIsLoading: false
			};
		case TypeDeDocumentActionsEnum.DELETE_DOCUMENT_REQUEST:
			return { ...state, documentDeleteIsLoading: true };
		case TypeDeDocumentActionsEnum.DELETE_DOCUMENT_SUCCESS:
			return {
				...state,
				documentDeleteIsLoading: false,
				id: action.payload.id
			};
		case TypeDeDocumentActionsEnum.DELETE_DOCUMENT_ERROR:
			return { ...state, documentDeleteIsLoading: false };

		case TypeDeDocumentActionsEnum.POST_DOCUMENT_REQUEST:
			return { ...state, documentAddIsLoading: true };
		case TypeDeDocumentActionsEnum.POST_DOCUMENT_SUCCESS:
			return {
				...state,
				documentIsLoading: false,
				document: action.payload.document
			};
		case TypeDeDocumentActionsEnum.POST_DOCUMENT_ERROR:
			return { ...state, documentAddIsLoading: false };

		case TypeDeDocumentActionsEnum.PUT_DOCUMENT_REQUEST:
			return { ...state, documentAddIsLoading: true };
		case TypeDeDocumentActionsEnum.PUT_DOCUMENT_SUCCESS:
			return {
				...state,
				documentIsLoading: false,
				document: action.payload.document
			};
		case TypeDeDocumentActionsEnum.PUT_DOCUMENT_ERROR:
			return { ...state, documentAddIsLoading: false };
		default:
			return state;
	}
};
