import Pagination from 'adel-shared/dist/components/basics/Pagination';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { StatutCommission } from '../../../services/generated/BackOffice-api';
import { fetchSessionsCA } from '../../../store/commission-store/actions/commissionCAActions';
import { useCommissionSelector } from '../../../store/store-helpers';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { openSessionCA } from '../../../store/commission-store/actions/commissionActions';
import { paginationNumbers } from '../../../constants/config.constant';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface ListeSessionCAProps extends RouteComponentProps {
}

const ListeSessionCA: React.FunctionComponent<ListeSessionCAProps> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	/** Table */
	const commissionSelector = useCommissionSelector();
	const [data, setData] = useState<Array<any>>([]);
	const [sort, setSort] = useState<string>("");

	/** Permissions */
	const usePermGetSessionCADossiers = useHasPermission("GetSessionCADossiers");
	const usePermOpenCommission = useHasPermission("OpenCommission");

	useEffect(() => {
		if (commissionSelector.sessionsCA?.items) {
			if (commissionSelector.sessionsCA.number &&
			commissionSelector.sessionsCA.totalPageCount &&
			(commissionSelector.sessionsCA.number > commissionSelector.sessionsCA.totalPageCount)) {
				setPage(1);
			}
			setHasNext(commissionSelector.sessionsCA.hasNext ?? false);
			setHasPrevious(commissionSelector.sessionsCA.hasPrevious ?? false);
			setIsFirst(commissionSelector.sessionsCA.isFirst ?? false);
			setIsLast(commissionSelector.sessionsCA.isLast ?? false);
			setTotalPageCount(commissionSelector.sessionsCA.totalPageCount ?? 0);
			setTotalItemCount(commissionSelector.sessionsCA.totalItemCount ?? 0);

			setData(commissionSelector.sessionsCA.items.map(com => ({
				Statut: <span className={`table__statutItem table__statut--${com.statut}`}>{t(`commission.status.${com.statut}`)}</span>,
				StatutOrigin: com.statut,
				DateDebut: formaterDate(com.dateDebut),
				DateFin: formaterDate(com.dateFin),
				DateClotureCA: com.dateClotureCA,
				Nom: com.nom,
				Id: com.id,
				Dossier: com.dossier,
				MontantDemande: com.montantDemande ? formatNumber(com.montantDemande) + ' €' : '- €',
				MontantAttribue: com.montantAttribue ? formatNumber(com.montantAttribue) + ' €' : '- €'
			})));
		}
	}, [commissionSelector]);

	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Statut", accessor: "Statut", sortType: "basic" },
					{ Header: "Date de début", accessor: "DateDebut", sortType: "basic" },
					{ Header: "Date de fin", accessor: "DateFin", sortType: "basic" },
					{ Header: "Date clôture CA", accessor: "DateClotureCA", sortType: "basic" },
					{ Header: "Nom", accessor: "Nom", sortType: "basic"},
					{ Header: "Dossier", accessor: "Dossier", sortType: "basic" },
					{ Header: "Montant demandé", accessor: "MontantDemande", disableSortBy: true },
					{ Header: "Montant accordé", accessor: "MontantAttribue", disableSortBy: true },
					{ Header: "", accessor: "Actions", disableSortBy: true,
						Cell: (value: any) => (
							<span className="commission__tableButtons">
								{(
									(
										value.cell.row.values.StatutOrigin === StatutCommission.EnCours ||
										value.cell.row.values.StatutOrigin === StatutCommission.EnAttenteCA
									) &&
									!value.cell.row.values.DateClotureCA
								) ? (
									<FormButton
										type="button"
										value="Ouvrir la session"
										onClick={() => openSession(value.cell.row.values.Id)}
									/>
								) : (
									usePermGetSessionCADossiers && (
										<Link to={`/Commission/DossiersSessionCA/${value.cell.row.values.Id}`}>
											<i className="far fa-eye"></i> Visualiser
										</Link>
									)
								)}
							</span>
						)
					},
					{ Header: "Id", accessor: "Id" },
					{ Header: "", accessor: "StatutOrigin" }
				]
			}
		];
	}, [usePermGetSessionCADossiers, usePermOpenCommission]);

	const openSession = async (id:string) => {
		if(usePermOpenCommission)
			await openSessionCA(dispatch, id);
		navigate(`/Commission/DossiersSessionCA/${id}`);
	}

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id", "DateClotureCA", "StatutOrigin"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);



	/** Get commissions */
	useEffect(() => {
		(async () => {
			await fetchSessionsCA(dispatch, false, page, pageSize, sort);
		})()
	}, [page, pageSize, sort]);


	return (
		<div className="commission listeSessionsCA">
			<div className="commission__header"></div>
			<div className="commission__content">
				<div className="commission__contentHeader">
					<h1 className="commission__contentHeaderTitle">Liste des sessions CA</h1>
				</div>
				<div className="listeSessionsCA__table">
					<Table
						data={data}
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						headerGroups={headerGroups}
						rows={rows}
						prepareRow={prepareRow}
						styles={styleTable.page}
					/>
					<Pagination
						hasNext={hasNext}
						hasPrevious={hasPrevious}
						isFirst={isFirst}
						isLast={isLast}
						totalPageCount={totalPageCount}
						totalItemCount={totalItemCount}
						page={setPage}
						pageSize={setPageSize}
						initPageSize={pageSize}
						initPage={page}
						pageNumbers={paginationNumbers}
					/>
				</div>
			</div>
		</div>
	);
}

export default ListeSessionCA;
