import React from 'react';
import { usePageVitrineSelector } from '../../store/store-helpers';
import ModalValidateCancel from '../basics/ModalValidateCancel';

interface ModalDeletePageVitrineProps {
	isOpen: boolean;
	onCancel: () => void;
	onValidate: () => void;
}

const ModalDeletePageVitrine: React.SFC<ModalDeletePageVitrineProps> = ({
	isOpen,
	onCancel,
	onValidate,
}) => {
	const { pageIsLoading } = usePageVitrineSelector();
	return (
		<ModalValidateCancel
			isOpen={isOpen}
			onCancel={onCancel}
			onValidate={onValidate}
			isLoading={pageIsLoading}
			title="Supprimer la page vitrine"
			validateButtonLabel="Supprimer"
		>
			<div className="modal__item">
				<p>Voulez vous vraiment supprimer cette page vitrine ?</p>
			</div>
		</ModalValidateCancel>
	);
}

export default ModalDeletePageVitrine;