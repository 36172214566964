import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { useDispatch } from 'react-redux';

import {
	cleanupDossierDetails,
	fetchDossierAidesHistory,
	fetchDossierDetails,
	getDescriptionProjet,
	getInfosProjet
} from '../../../store/dossier-store/actions/dossierDetailsActions';
import {
	ArtisteClient,
	ArtisteModalDto,
	BudgetDto,
	DepenseDto,
	DescriptionProjetViewModelDto,
	DocumentWithValidationDto,
	DossierClient,
	DossierCommissionDto,
	DossierInfosProjetViewModelDto,
	FinancementDto,
	LieuDateDetailsDto,
	Role,
	RoleDto,
	StatutCommission
} from '../../../services/generated/BackOffice-api';
import NoFolder from "../../../assets/img/undraw-not-found.png";

import Informations from 'adel-shared/dist/components/DossierDetails/Informations';
import Artistes from 'adel-shared/dist/components/DossierDetails/Artistes';
import ArtisteModal from 'adel-shared/dist/components/DossierDetails/ArtisteModal';
import BudgetsPrevisionnels from 'adel-shared/dist/components/DossierDetails/BudgetsPrevisionnels';
import InformationsBudget from 'adel-shared/dist/components/DossierDetails/InformationsBudget';
import ModalAideDecision from './ModalAideDecision';
import CollapsibleSection from '../../basics/CollapsibleSection';
import Contenu from 'adel-shared/dist/components/DossierDetails/Contenu';
import Commentaires from 'adel-shared/dist/components/DossierDetails/Commentaires';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import ModalDeleteReaffectation from './ModalDeleteReaffectation';
import ModalValidationDossier from './ModalValidationDossier';
import {
	useCategorieDossierSelector,
	useCommissionSelector,
	useDossierSelector,
	useUserSelector
} from '../../../store/store-helpers';
import Logo from "../../../assets/logo/logo_spedidam_blanc_sans_txt.svg";
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { fetchCommissionDetailsDossiers, fetchCommissionTableauBord } from '../../../store/commission-store/actions/commissionActions';
import { fetchCategoriesDossier } from '../../../store/categorie-dossier-store/actions/categorieDossierAction';
import clsx from 'clsx';
import Loader from 'react-loader-spinner';
import InformationsSupplementaires from './InformationsSupplementaires';
import InformationsDernierDossier from './InformationsDernierDossier';
import { RolesValues } from '../../../enums/RolesValues';
import { Controller, useForm } from 'react-hook-form';
import DeplacementsTable from 'adel-shared/dist/components/DossierDetails/DeplacementsTable';
import DocumentValidationLight from '../../document-validation/DocumentValidationLight';
import Structure from 'adel-shared/dist/components/DossierDetails/Structure';
import Programmation from 'adel-shared/dist/components/DossierDetails/Programmation';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import InfosProjet from 'adel-shared/dist/components/DossierDetails/InfosProjet';
import Enseignants from 'adel-shared/dist/components/DossierDetails/Enseignants';
import ChiffresCles from 'adel-shared/dist/components/DossierDetails/ChiffresCles';
import { DossierActionsEnum } from '../../../store/dossier-store/actions/dossierAction';
import DescriptionProjet from 'adel-shared/dist/components/DossierDetails/DescriptionProjet';
import { CategorieDossier } from '../../../enums/Dossiers';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import WysiwygEditor from 'adel-shared/dist/components/WysiwygEditor';
import { fetchUser, fetchUserRoles } from '../../../store/user-store/actions/userAction';


interface DossierDetailsCommissionProps extends RouteComponentProps {
	id: string;
}

const DossierDetailsCommission: React.FunctionComponent<DossierDetailsCommissionProps> = ({
	id
}) => {
	const { t, i18n } = useTranslation();
    const [idDossier, setIdDossier] = useState<string | null>();
    const dispatch = useDispatch();
    const artisteClient = new ArtisteClient('', axiosInstance);
	const categorieSelector = useCategorieDossierSelector();
	const {
		dossierDetails = {},
		dossierAidesHistory,
		isDossierDetailsLoading
	} = useDossierSelector();
	const {
		commission = {},
		commissionDetailsDossiers: commissionDossiers = [],
		isDossierLoading
	} = useCommissionSelector();

	/** Permissions */
	const usePermGetDossierNotation = useHasPermission("GetDossierNotation");
	const usePermValidateDossier = useHasPermission("ValidateDossier");

	const { user = {} } = useUserSelector();
	const [isOpenModal, openModal, closeModal] = useBooleanState(false);
    const [budget, setBudget] = useState<BudgetDto>();
    const [infosProjet, setInfosProjet] = useState<DossierInfosProjetViewModelDto>();
    const [descrProj, setDescrProj] = useState<DescriptionProjetViewModelDto>();
    const [isVoteReadonly, setIsVoteReadonly] = useState(false);
	const [firstDossierCateg, setFirstDossierCateg ] = useState<string | undefined>("")
	const dossierClient = new DossierClient('', axiosInstance);


	useEffect(() => {
	
		// Cleanup function when the component unmounts
		return () => {
		  // Unload or clean up data when the component unmounts
		  // For example, cancel any pending requests or clear data
		  dispatch(cleanupDossierDetails())
		};
	  }, []);
	
	useEffect(()=> {
		setIsVoteReadonly(commission.statut === StatutCommission.Terminee && user.roles?.includes(RolesValues.RespService) ? true : false)
	}, [commission, user, isVoteReadonly])
    const [artisteSelected, setArtisteSelected] = useState<ArtisteModalDto>();
	const handleOpenArtisteDetails = (id:string) => {
		openartiste(id);
	}
	const openartiste = async(id:string) => {
		try {
			if(id) {
				const artiste = await artisteClient.getArtiste(id);
				setArtisteSelected(artiste);
				openModal();
			}
		} catch(error) {
			if(error.response?.exception?.message)
				toast.error(error.response.exception.message);
			else if(error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error("Une erreur est survenue lors de la récupération de l'artiste");
		}
	}

	/** Filtre */
	const [categorieOptions, setCategorieOptions] = useState<AdelOption<string>[]>();
	const {control, watch} = useForm<any>({defaultValues: {categorie: 'all'}});
	const selectedCategorie = watch('categorie');
	const [commentaire, setCommentaire] = useState<string>("");

	useEffect(() => {
		searchCommission('all');
		fetchCategoriesDossier(dispatch)();
	}, []);

	const searchCommission = async (categorie: string) => {
		const categorieCode = categorie === 'all' ? undefined : categorie;
		const commissions = await fetchCommissionDetailsDossiers(dispatch, id, categorieCode);
		selectCommission(commissions);
	}

	const selectCommission = (dossiersList:DossierCommissionDto[]) => {
		if(dossiersList.length > 0) {
			// Sélectionner le premier dossier qui est non validé
			const isFirstDossierNonValide = dossiersList.find(item => !item.valideEnCommission);
			const firstDossier = isFirstDossierNonValide ? isFirstDossierNonValide : dossiersList[0];
			selectDossier(firstDossier.id);
			setFirstDossierCateg(firstDossier?.categorieCode);
			
		}
		else 
			selectDossier();
	}

	const selectDossier = async (idDossierSelected?:string) => {
		setIdDossier(idDossierSelected);

		if(!idDossierSelected) {
			dispatch({
				type: DossierActionsEnum.GET_DOSSIER_DETAILS_SUCCESS,
				payload: {dossierDetails: {}}
			});
			setIdDossier(null);

		} else {
			await fetchDossierDetails(dispatch, idDossierSelected);
			await fetchDossierAidesHistory(dispatch, idDossierSelected);
			const descr = await getDescriptionProjet(idDossierSelected);
			setDescrProj(descr);
			const info = await getInfosProjet(idDossierSelected);
			setInfosProjet(info);
			const result = await dossierClient.getDossierDetails(idDossierSelected);
			setFirstDossierCateg(result?.categorie?.code)
		}
	}



	useEffect(() => {
		if(dossierDetails.budget && dossierDetails.budgetViewModel) {
			//setBudget via viewmodel
			const arrayDepenses = dossierDetails.budgetViewModel.depenses?.reduce((acc:DepenseDto[], curr) => {
				curr.depenses?.map(depViewModel => {
					dossierDetails.budget?.depenses?.map(dep => {
						if(depViewModel.champ === dep.champ && dep.type === curr.type)
							acc.push(dep)
					})
				});
				return acc;
			}, []);
			const arrayFinances = dossierDetails.budgetViewModel.financements?.reduce((acc:FinancementDto[], curr) => {
				curr.financements?.map(financeViewModel => {
					dossierDetails.budget?.financements?.map(finance => {
						if(financeViewModel.champ === finance.champ && finance.type === curr.type)
							acc.push(finance)
					})
				});
				return acc;
			}, []);

			setBudget({
				dossierId: dossierDetails.budget.dossierId,
				commentaire: dossierDetails.budget.commentaire,
				documents:dossierDetails.budget.documents,
				depenses: arrayDepenses,
				financements: arrayFinances
			});
		}
	}, [
		dossierDetails.budget,
		dossierDetails.budgetViewModel
	]);


	useEffect(() => {
		if (commission && commission?.categoriesDossier) {
			const categories:AdelOption<string[]> | any = commission?.categoriesDossier.map(category => ({
				value: category.code,
				label: category.nomCourt,
				labelLong: category.nom
			})).filter(c => c.label != null);			
			categories.unshift({ value: "all", label: "Toutes les catégories"});
			setCategorieOptions(categories);
		}
	}, [commission]);



	/** Menu Liste */
	const listItems = commissionDossiers.map((item: DossierCommissionDto, idx: number) => (
		<li key={idx} className={clsx(idDossier === item.id && "activeListe")}>
			{(item.valideEnCommission) && (
				<i className="fas fa-check"></i>
			)}
			<div className="liste__item">
				<span>{item.nom} N°{item.numero}</span>
				<span>{item.raisonSociale}</span>
			</div>
			<span
				className="liste__button"
				onClick={() => selectDossier(item.id)}
			></span>
		</li>
	));



	/** Modals */
	const [isOpenAideDecision, openAideDecision, closeAideDecision] = useBooleanState(false);
    const [isOpenDeleteReaffectation,, closeDeleteReaffectation] = useBooleanState(false);
    const [isOpenValidationDossier, openValidationDossier, closeValidationDossier] = useBooleanState(false);

	const datesByPrestation = dossierDetails.dates?.length ? dossierDetails.dates.reduce((accumulator: LieuDateDetailsDto[][], currentVal) => {
        const date = accumulator.filter(
            x => x.some(
                y => y.typePrestation === currentVal.typePrestation
            )
        );

        if (date.length) {
            date[0].push(currentVal);
        } else {
            accumulator.push([currentVal]);
        }

        return accumulator;
    }, []) : [];



	// Rafraîchissement suite à validation dossier
	const goToNextDossier = async () => {
		try {
			await fetchCommissionTableauBord(dispatch, id);
			const newDossierIndex = commissionDossiers.findIndex(d => d.id === idDossier) + 1;
			if(commissionDossiers[newDossierIndex]?.id) {
				selectDossier(commissionDossiers[newDossierIndex].id)
			}
		} catch(error) {
			toast.error("Une erreur est survenue lors de la récupération des données du tableau de bord");
		}
	}


	/** Documents validation */
	const [pieceObligatoires, setPieceObligatoires] = useState<DocumentWithValidationDto[]>([]);
    const [pieceJointe, setPieceJointe] = useState<DocumentWithValidationDto[]>([]);

	useEffect(() => {
		if (dossierDetails.documents) {
			const docO = dossierDetails.documents.filter(d => d.type?.isRequired) || [];
			const docJ = dossierDetails.documents.filter(d => !d.type?.isRequired) || [];
			setPieceObligatoires(docO);
			setPieceJointe(docJ);
		}
	}, [dossierDetails.documents]);

	const [isMemberCommission, setIsMemberCommission] = useState<boolean>(true);

	useEffect(() => {
		fetchUser(dispatch)().then((data: any) => {
			const userRoles = data?.payload?.user?.roles;
			const memberCommissionPermission = userRoles?.length == 1 && userRoles.includes("Membre de commission");
			setIsMemberCommission(memberCommissionPermission);
		})
	}, [dispatch]);

    return (
        <div className="dossierDetails">
			<div className="commissionListeDossier">
				<section className="commissionListe">
					<Link to="/">
						<img src={Logo} alt="Logo Spedidam" />
					</Link>
					<h3>Dossiers ({commissionDossiers.length})</h3>
					<div className="liste__cat">
						<Controller control={control}
							name="categorie"
							as={({ onChange, value, name }) => (
								<InputSelect
									styles={{
										singleValue: (provided:any) => {
											const color = 'white';
											return { ...provided, color };
										}
									}}
									name={name}
									label=""
									classname="inputSelect"
									options={categorieOptions}
									onChange={(selectedCat) => {
										onChange(selectedCat);
										searchCommission(selectedCat);
									}}
									value={value}
									isNomcourt={true}
								/>
							)}
						/>
						{isDossierLoading
							? <div className="liste__catLoader"><Loader type="TailSpin" width={35} height={35} color="white" ></Loader></div>
							: <nav><ul>{listItems}</ul></nav>
						}
					</div>
				</section>
			</div>

            <div className="dossierDetails__item">
				<div className="navigationFil" style={{padding: 10}}>
            	    <Link className="navigationFil__item" to="/Commission/ListeCommissions">
             	       <i className="fas fa-chevron-left"></i>
             	       Retour à la liste des commissions
              	 	</Link>
         	    </div>
				<h2>Détails de la commission</h2>
				
				{isDossierDetailsLoading
					? <div className="dossierDetails__loader"><Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader></div>
					: <>{dossierDetails && Object.keys(dossierDetails).length > 0 ? (
						<>
						<div className="detailsTab__section">
							<section className="detailsTab__section">
								<CollapsibleSection title="Structure du porteur de projet" isOpenByDefault={true}>
									<Structure
										details={dossierDetails}
									/>
								</CollapsibleSection>
							</section>
							<section className="detailsTab__section">
								<CollapsibleSection title="Informations dossier">
									<>
										<Informations
											details={dossierDetails}
										/>

										{infosProjet && (
											<InfosProjet
												className="dossier-details"
												showResponsableDossier={false}
												showBlockTitle={false}
												//@ts-ignore
												details={infosProjet}
												lang={i18n.language}
											/>
										)}
									</>
								</CollapsibleSection>
							</section>

							{(dossierAidesHistory?.dernierDossierId && dossierAidesHistory.dernierDossierId > 0)
								? <section className="detailsTab__section">
									<CollapsibleSection title="Informations du dernier dossier passé en commission ayant la même catégorie">
										<div className="detailsTab__content">
											<InformationsDernierDossier />
										</div>
									</CollapsibleSection>
								</section>
								: null
							}

							{pieceObligatoires.length > 0 &&
								<section className="detailsTab__section">
									<CollapsibleSection title="Pièces obligatoires">
										<div className="detailsTab__content">
											<DocumentValidationLight
												documents={pieceObligatoires}
											/>
										</div>
									</CollapsibleSection>
								</section>
								}
							{pieceJointe.length > 0 &&
								<section className="detailsTab__section">
									<CollapsibleSection title="Pièces jointes">
										<div className="detailsTab__content">
											<DocumentValidationLight
												documents={pieceJointe}
											/>
										</div>
									</CollapsibleSection>
								</section>
							}

								{(dossierDetails.categorie?.code != CategorieDossier.Festival && dossierDetails.categorie?.code != CategorieDossier.SoireeArtistes) ?
									dossierDetails?.artistes && dossierDetails.artistes.length > 0 &&
									<section className="detailsTab__section">
										<CollapsibleSection title="Artistes">
											<>
												<Artistes
													details={dossierDetails}
													isBo
													/**@ts-ignore */
													isResponsableAndMembreCommission={user.roles?.includes(RolesValues.RespCommission) || user.roles?.includes(RolesValues.MembreCommission)}
													openModal={handleOpenArtisteDetails}
												/>
												<ArtisteModal
													artiste={artisteSelected}
													setArtiste={setArtisteSelected}
													dossierCategorie={dossierDetails.categorie}
													isOpen={isOpenModal}
													close={closeModal}
													isCommissionMember={isMemberCommission}
												/>
											</>
										</CollapsibleSection>
									</section>
									:
									<>
										{dossierDetails?.artistes && dossierDetails.artistes.filter(a => a.isEngagementDirect).length > 0 &&
											(dossierDetails.artistes && dossierDetails.artistes.filter(a => a.isEngagementDirect).length > 0) && (
												<section className="detailsTab__section">
													<CollapsibleSection title="Artistes engagement direct">
														<><Artistes
															details={dossierDetails}
															isBo
															isEngagementDirect={true}
															isResponsableAndMembreCommission={user.roles?.includes(RolesValues.RespCommission) || user.roles?.includes(RolesValues.MembreCommission)}
															openModal={handleOpenArtisteDetails}
														/>
															<ArtisteModal
																artiste={artisteSelected}
																setArtiste={setArtisteSelected}
																dossierCategorie={dossierDetails.categorie}
																isOpen={isOpenModal}
																close={closeModal}
																isCommissionMember={isMemberCommission}
															/></>
													</CollapsibleSection>
												</section>
											)}
										{(dossierDetails.artistes && dossierDetails.artistes.filter(a => !a.isEngagementDirect).length > 0) && (
											<section className="detailsTab__section">
												<CollapsibleSection title="Artistes issus des contrats de cession ">
													<>
														<Artistes
															details={dossierDetails}
															isBo
															isEngagementDirect={false}
															/**@ts-ignore */
															isResponsableAndMembreCommission={user.roles?.includes(RolesValues.RespCommission) || user.roles?.includes(RolesValues.MembreCommission)}
															openModal={handleOpenArtisteDetails}
														/>
														<ArtisteModal
															artiste={artisteSelected}
															setArtiste={setArtisteSelected}
															dossierCategorie={dossierDetails.categorie}
															isOpen={isOpenModal}
															close={closeModal}
															isCommissionMember={isMemberCommission}
														/>
													</>
												</CollapsibleSection>
											</section>
										)}
									</>
								}
							{datesByPrestation?.map((x, i) => (
								<section key={i} className="detailsTab__section">
									<CollapsibleSection title={`Programmation des ${t(`structure.prestation.${x[0].typePrestation}`)}s (${x.length})`}>
										<Programmation dates={x} categorieDossier={dossierDetails?.categorie} />
									</CollapsibleSection>
								</section>
							))}
							{(dossierDetails.trajets && dossierDetails.trajets.length > 0) && (
								<section className="detailsTab__section">
									<CollapsibleSection title="Déplacements">
										<DeplacementsTable trajets={dossierDetails.trajets} />
									</CollapsibleSection>
								</section>
							)}
							{(dossierDetails.enseignants && dossierDetails.enseignants.length > 0) && (
								<section className="detailsTab__section">
									<CollapsibleSection title="Enseignants">
										<Enseignants enseignants={dossierDetails.enseignants} />
									</CollapsibleSection>
								</section>
							)}
							{dossierDetails.chiffresCles && (
								<section className="detailsTab__section">
									<CollapsibleSection title="Chiffres clés">
										<ChiffresCles chiffres={dossierDetails.chiffresCles} />
									</CollapsibleSection>
								</section>
							)}
							<section className="detailsTab__section">
								<CollapsibleSection title="Budgets prévisionnels de dépenses et de financement">
									<BudgetsPrevisionnels
										budget={budget}
										categorie={dossierDetails.categorie}
									/>
								</CollapsibleSection>
							</section>
							<section className="detailsTab__section">
								<CollapsibleSection title="Informations complémentaires relatives au budget">
									<InformationsBudget
										budget={dossierDetails.budget}
									/>
								</CollapsibleSection>
							</section>
							<section className="detailsTab__section">
								<CollapsibleSection title="Informations supplémentaires">
									<InformationsSupplementaires
										details={dossierAidesHistory}
										dsv={dossierDetails.dsv}
									/>
								</CollapsibleSection>
							</section>							
							<section className="detailsTab__section">
								<CollapsibleSection title="Description du projet"><>
									<Contenu details={dossierDetails} />
									{descrProj && <div className="detailsTab__content">
										{/* @ts-ignore */}
										<DescriptionProjet details={descrProj} lang={i18n.language} />
									</div>}
								</></CollapsibleSection>
							</section>
							{user.roles?.includes(RolesValues.RespCommission) && (<>
								<section className="detailsTab__section">
									<CollapsibleSection title="Commentaire instruction">
											<WysiwygEditor
                                            editorValueContent={dossierDetails.commentairesInstruction ?? ""}
                                            setEditorValueContent={setCommentaire}
											isReadonly = {true}
                                        />
									</CollapsibleSection>
								</section>
								<section className="detailsTab__section">
									<CollapsibleSection title="Commentaire du dossier">
										<Commentaires
											commentaire={dossierDetails.commentaires}
											readOnly
										/>
									</CollapsibleSection>
								</section>
							</>)}
							{(dossierAidesHistory?.dernierDossierId && dossierAidesHistory.dernierDossierId > 0)
								? <section className="detailsTab__section">
									<CollapsibleSection title="Informations du dernier dossier passé en commission ayant la même catégorie">
										<div className="detailsTab__content">
											<InformationsDernierDossier />
										</div>
									</CollapsibleSection>
								</section>
								: null
							}
						</div>
						
						<div className="dossierDetails__footer">
							<div></div>
							<div>
								{usePermGetDossierNotation &&
									<FormButton 
										className="button--light"
										type="button"
										value="Aide à la décision"
										icon="far fa-plus"
										onClick={openAideDecision}
									/>
								}
								{((usePermValidateDossier && 
								(commission.statut === StatutCommission.EnCours || commission.statut === StatutCommission.CaEnCours)) || (isVoteReadonly))  && (
									<FormButton 
										type="button"
										value={isVoteReadonly ? "Visualiser les votes du dossier" : "Valider le dossier"}
										onClick={openValidationDossier}
									/>
								)}
							</div>
						</div>
					</>
				) : (
					<div className="noFolder">
						<div className="noFolder__item">
							<img src={NoFolder} />
							<p>Aucune donnée disponible</p>
						</div>
					</div>
				)}
			</>}
            </div>
			{isOpenAideDecision && (
				<ModalAideDecision
					isOpen={isOpenAideDecision}
					onClose={closeAideDecision}
					detailsDossier={dossierDetails}
				/>
            )}
            {isOpenDeleteReaffectation && (
			(commission.statut === StatutCommission.EnCours || commission.statut === StatutCommission.CaEnCours) && (
                <ModalDeleteReaffectation
                    isOpen={isOpenDeleteReaffectation}
                    onCancel={closeDeleteReaffectation}
                    onValidate={closeDeleteReaffectation}
                />
            ))}
			{isOpenValidationDossier && (
				<ModalValidationDossier
					id={idDossier}
					idComm={id}
					isOpen={isOpenValidationDossier}
					isVoteReadonly={isVoteReadonly}
					onClose={closeValidationDossier}
					goToNextDossier={goToNextDossier}
					selectedCategorie={(selectedCategorie == 'all') ? firstDossierCateg : selectedCategorie}
				/>
			)}
        </div>
    );
};

export default DossierDetailsCommission;