import React, { useCallback, useEffect, useState } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { getDossierVersement, getDossierVersements, putDossierAgreement } from '../../../store/dossier-store/actions/dossierVersementActions';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useDossierSelector } from '../../../store/store-helpers';
import InputCalendar from 'adel-shared/dist/components/basics/InputCalendar';
import moment from 'moment';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSortBy, useTable } from 'react-table';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { VersementInformations, View } from './VersementTab';
import { BOVersementDto, StatutDossier, StatutVersement, TypeVersement } from '../../../services/generated/BackOffice-api';
import { normalizeDate, formaterDate } from 'adel-shared/dist/utils/functions';

interface VersementListeProps {
	dossierId: string | undefined;
	setCurrentView: (value:View) => void;
	setVersementInformations: (value:VersementInformations) => void;
	statutDossier?: StatutDossier;
}

const VersementListe: React.FunctionComponent<VersementListeProps> = ({
	dossierId,
	setCurrentView,
	setVersementInformations
}) => {
	const { t } = useTranslation();
	const {conventionCourrier, dossierDetails, dossierVersements, isAgreementLoading } = useDossierSelector();
	const dispatch = useDispatch();
	const [dateConvention, setDateConvention] = useState<Date | undefined>();

	const [data, setData] = useState<Array<any>>([]);
	const [sort, setSort] = useState<string>("");

	const [courrierIntitule, setCourrierIntitule] = useState<string>('');


	useEffect(() => {
		(async () => {
			if(dossierId) {
				try {
					await getDossierVersements(dispatch, dossierId, sort);
					await getDossierVersement(dispatch, dossierId);
				} catch(error) {
					toast.error(t('common.errors.fetch'));
				}
			}
		})();
	}, [dossierId, sort]);



	/** Courrier convention */
	useEffect(() => {
		setDateConvention(conventionCourrier?.dateSignature);
	}, [conventionCourrier]);

    const handleDateConventionSelected = useCallback((date: Date) => {
        setDateConvention(date);
	}, [setDateConvention])
	
	const send = async() => {
		if (dossierId && dateConvention) {
			try {
				await putDossierAgreement(dispatch, dossierId, normalizeDate(dateConvention));
				toast.success(t('common.success'));
			} catch(error) {
				toast.error(t('common.errors.send'));
			}
		}
	}

	useEffect(() => {
		if (dossierDetails?.statut) {
			switch(dossierDetails.statut) {
				case StatutDossier.HorsCriteres:
					setCourrierIntitule('Lettre de refus (avec mention hors critères)'); break;
				case StatutDossier.Refuse:
					setCourrierIntitule('Lettre de refus'); break;
				case StatutDossier.Accepte:
					setCourrierIntitule("Courrier d'agrément"); break;
				default:
					setCourrierIntitule("Courrier"); break; // TODO: quel intitulé mettre si statut n'est pas Accepté?
			}
		}
	}, [dossierDetails?.statut]);


	
	/** Permissions */
	const usePermUpdateCritereDossier = useHasPermission("UpdateCritereDossier");

	const etat = (versement:BOVersementDto) => {
		let blocageDsv:string | undefined;
		let enCours:string | undefined;

		if(versement.valideParDSV == false)
			blocageDsv = "Blocage droit exclusif";

		if(versement.enCoursValidationDSV == true)
			enCours = "En cours de validation droit exclusif";
		
		if(blocageDsv && enCours)
			return enCours;
		else if (!blocageDsv && enCours)
			return "Validé droit exclusif";
		else if(blocageDsv)
			return blocageDsv;
		else if(enCours)
			return enCours;
		else 
			return "";
	}


	/** Versements */
	useEffect(() => {
		if (dossierVersements?.items) {
			const array = dossierVersements.items.map(versement => ({
				Statut: 
				        <span className={`table__statutItem table__statut--${versement.statutVersement}`}>
							{t(`versements.statut.${versement.dateDerniereSoumission && versement.statutVersement == StatutVersement.Soumis ? 'modifie' : versement.statutVersement}`)}
						</span>,
				DateSoumission: versement.dateSoumission ? formaterDate(versement.dateSoumission) : formaterDate(conventionCourrier?.dateSignature),
				DateDerniereSoumission: versement.dateDerniereSoumission ? formaterDate(versement.dateDerniereSoumission) : '-',
				Type: versement.typeVersement,
				Etat: etat(versement),
				NecessiteInstruction: versement.necessiteInstruction,
				Id: versement.id,
				DossierId: versement.dossierId,
				modeVersement: versement.modeVersement
			}));

			setData(array);
		}
	}, [dossierVersements]);

	const editVersement = (versementId:string, dossierId: string, necessiteInstruction:boolean, type:TypeVersement, originalDossierId?:string, modeVersement?: string) => {
		setCurrentView(View.Edit);
		if(originalDossierId) {
			setVersementInformations({
				versementId,
				dossierId,
				originalDossierId,
				necessiteInstruction,
				type,
				modeVersement
			});
		}
	}

	const columns = React.useMemo(() => {
		return [{
			Header: "Table",
			columns: [
				{ Header: "Date de la demande", accessor: "DateSoumission", sortType: "basic" },
				{ Header: t('Date de la dernière demande'), accessor: "DateDerniereSoumission", disableSortBy: true  },
				{ Header: "Type", accessor: "Type", disableSortBy: true,
				Cell: (value: any) => (
					<span>{t(`common.typesVersementSingle.${value.value}`)}</span>
				)},
				{ Header: "Statut", accessor: "Statut", disableSortBy: true },
				{ Header: "Etat", accessor: "Etat", disableSortBy: true },
				{ Header: "", accessor: "NecessiteInstruction", disableSortBy: true },
				{ Header: "", accessor: "DossierId", disableSortBy: true },
				{ Header: "", accessor: "Id", disableSortBy: true,
				Cell: (value: any) => 
				  (
					<i className="far fa-edit" onClick={() => 
						editVersement(value.cell.row.values.Id, value.cell.row.values.DossierId, value.cell.row.values.NecessiteInstruction, value.cell.row.values.Type, dossierId, value.cell.row.original?.modeVersement)
					}></i>
				)}
			]
		}];
	}, [dossierId]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["NecessiteInstruction", "DossierId"],
				sortBy: [
					{
						id: 'DateSoumission',
						desc: true
					}
				]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	return (
		<div className="versementTab detailsTab">
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Convention</h3>
					<div className="detailsTab__row">
						<div className="detailsTab__column">
							<div className="detailsTab__content">
								<label>Date de réception de la convention signée</label>
								{usePermUpdateCritereDossier 
									? <InputCalendar
										label=""
										maxDate={new Date()}
										defaultDate={dateConvention && moment(dateConvention).toDate()}
										onDateSelected={handleDateConventionSelected}
										unlock={true}
									/>
									: <div>{moment(dateConvention).format('DD.MM.YYYY')}</div>

								}
							</div>
						</div>
						<div className="detailsTab__column detailsTab__column--last">
							<div className="detailsTab__content versementTabLinks">
							{conventionCourrier?.convention
								? <div className="versementTab__link hasLink">
									<a href={conventionCourrier.convention} target="_blank" rel="">Visualiser la convention</a>
								</div>
								: <div className="versementTab__link">Visualiser la convention</div>
							}
							{conventionCourrier?.courrierAgreement
								&& <div className="versementTab__link hasLink" >
									<a href={conventionCourrier.courrierAgreement} target="_blank" rel="">{courrierIntitule}</a>
								</div>
							}
							{conventionCourrier?.lettreRefus
								&& <div className="versementTab__link hasLink" >
									<a href={conventionCourrier.lettreRefus} target="_blank" rel="">{courrierIntitule}</a>
								</div>
							}
						</div>
					</div>
				</div>
				<div className="versementTabTable">
					<Table
						data={data}
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						headerGroups={headerGroups}
						rows={rows}
						prepareRow={prepareRow}
						styles={styleTable.onglet}
					/>
				</div>
			</div>
			<div className="detailsTab__footer">
				{isAgreementLoading
				&& <Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					></Loader>
				}
				{usePermUpdateCritereDossier && <FormButton
					disabled={isAgreementLoading}
					type="submit"
					value={"Valider"}
					onClick={() => send()}
				/>}
			</div>
		</div>
	);
}

export default VersementListe;