export enum RolesValues {
    Admin = "Administrateur",
    Gerant = "Gérant",
    RespService = "Responsable de service",
    RespCommission = "Responsable de commissions",
    DivCulturelleCI = "Chargé d'instruction Division Culturelle",
    DSVCI = "Chargé d'instruction Spectacle Vivant (DSV)",
    Stagiaire = "Stagiaire Division Culturelle",
    MembreCommission = "Membre de commission",
    RespJuridique = "Responsable Juridique",
    RespCommunication = "Responsable Communication",
    CommissaireComptes = "Commissaire aux comptes",
    RespComptable = "Responsable comptable",
}