import { useEffect } from 'react';
import { Dictionary } from 'adel-shared/dist/models';
import PropertyValidatorInfo from 'adel-shared/dist/models/validation/PropertyValidatorInfo';
import { axiosInstance } from './useAxios';
import { ValidationClient } from '../services/generated/BackOffice-api';
import useValidationBase from 'adel-shared/dist/custom-hooks/useValidationBase';
import { useUserSelector } from '../store/store-helpers';

function useValidation() {
	const {
		getRootValidator,
		validationData,
		setValidationData
	} = useValidationBase();
	const client = new ValidationClient("", axiosInstance);
	const {isConnected: isUserConnected} = useUserSelector();

	useEffect(() => {
		if(isUserConnected) {
			if(validationData) return;

			client.getValidationDescriptors().then(r => {
				setValidationData(r as Dictionary<Dictionary<PropertyValidatorInfo[]>>);
			});
		}
	}, [isUserConnected]);

	return { getRootValidator };
}

export default useValidation;