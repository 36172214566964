import FormButton from "adel-shared/dist/components/basics/FormButton";
import { Dictionary } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DocumentDto, DocumentUploadInfoDto } from "../../../services/generated/BackOffice-api";
import { useStructureSelector } from "../../../store/store-helpers";
import { sendStructureDocuments } from "../../../store/structure-store/actions/structureDocumentsActions";
import { maxFileSize, maxFileSizeMb, maxTotalFilesSizeMb } from "../../../constants/config.constant";

interface StructureDocumentsTabProps {
    structureId: string;
}

const StructureDocumentsTab: FunctionComponent<StructureDocumentsTabProps> = ({
    structureId
}) => {
    const {
		structureDocuments = {},
		structureDocumentsFiles = []
	} = useStructureSelector();
    const [files, setFiles] = useState<File[]>(structureDocumentsFiles);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [documents, setDocuments] = useState<DocumentDto[]>(structureDocuments.documents || []);
    const { t } = useTranslation();
    const dispatch = useDispatch();
   
    const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        if (value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
            const escapedName = value.target.files && value.target.files[0].name.replace(/\W/g, '');

            if (value.target.files[0].name.length > 100) {
                toast.error('Le nom du fichier ne doit pas excéder 100 caractères.')
            }
            else if (value.target.files[0].size > maxFileSizeMb) {
                toast.error(t(`common.errors.largeInputFile`, { maxFileSize })); 
            }
            else if (files.findIndex(file => file.name.replace(/\W/g, '') === escapedName) > -1) {
                toast.error('Ce fichier existe déjà')
            } else {
                setFiles([...files, value.target.files[0]]);
            }
        } else {
            console.error("file added error");
        }
    }
    const handleFileDelete = (fileName: string) => {
        files && setFiles(files.filter(file => file.name !== fileName));
    }

    const send = async () => { 
        var totalSize = files && files.reduce((prev, current) => prev + current.size, 0)  
        if(totalSize > maxTotalFilesSizeMb) {
            toast.error(t(`common.errors.largeFile`, { maxFileSize })); 
        }    
        else if (structureId) {
            setIsLoading(true);

            const fileDictionary: Dictionary<File> = {};
            const documentsToSend: DocumentUploadInfoDto[] = documents.map(d => ({ id: d.id }));

            files.forEach(file => {
                const escapedFileName = file.name.replace(/\W/g, '');
                documentsToSend.push({
                    partName: escapedFileName
                });
                fileDictionary[escapedFileName] = file;
            });

            try {
                await sendStructureDocuments(dispatch, structureId, documentsToSend, fileDictionary, files);
                toast.success(t("toast.success"));
            } catch (error) {
                toast.error(t("toast.errors.send"));
            }

            setIsLoading(false);
        }
    }
    const handleExistingFileDelete = (documentId?: string) => {
        if (documentId === undefined) throw "No document id was found on selected document."
		setDocuments(documents.filter(d => d.id !== documentId));
    }

    return (
        <div className="detailsTab">
            <div className="detailsTab__section">
                <div className="collapsibleSection">
                    <h3 className="instructionTab__title">
                        <span>Pièces-jointes complémentaires</span>
                    </h3>
                    {documents.map(document => (
						<div key={document.id} className="instructionTab__file">
                            <a target="_blank" href={document.uri}>
								<i className="fas fa-file-upload"></i> {document.fileName}
							</a> <i
								className="far fa-trash-alt"
								onClick={() => handleExistingFileDelete(document.id)}
							></i>
                        </div>
					))}
                    {files.map((file, i) => (
                        <div key={i} className="instructionTab__file">
                            <i className="far fa-file"></i> {file.name} <i
                                className="far fa-trash-alt"
                                onClick={() => handleFileDelete(file.name)}
                            ></i>
                        </div>
                    ))}
                    <div className="inputFile__buttons">
                        <label htmlFor="uploadDocument">
                            Télécharger une pièce-jointe
                        </label>
                        <input
                            type="file"
                            id="uploadDocument"
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
            </div>

            <div className="detailsTab__footer">
                {isLoading
                    ? <Loader
                        type="TailSpin"
                        width={35}
                        height={35}
                        color="#d93943"
                    ></Loader>
                    : <FormButton
                        disabled={isLoading}
                        type="submit"
                        value="Valider"
                        onClick={send}
                    />
                }
            </div>
        </div>
    )
}
export default StructureDocumentsTab;