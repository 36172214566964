/**
 * CONFIG CONSTANTS - Les constantes qui servent à configurer manuellement des composants (notamment ceux qui viennent de librairies)
 */

import { PageNumbers } from 'adel-shared/dist/components/basics/Pagination';

const modalCustomStyles = {
	content: {
		overflow: 'visible',
		minWidth: '500px',
		minHeight: '0'
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, .2)',
		zIndex: 70
	}
};

const modalSmallerCustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		minWidth: '500px',
		maxWidth: '500px',
		padding: 0
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, .2)',
		zIndex: 70
	}
};

const modalMediumCustomStyles = {
	...modalCustomStyles,
	content: {
		minWidth: '700px'
	}
};

const modalMediumExportCustomStyles = {
	...modalCustomStyles,
	content: {
		minWidth: '700px',
		minHeight: '400px'
	}
};

const modalLargeCustomStyles = {
	...modalCustomStyles,
	content: {
		minWidth: '1000px'
	}
};

const modalLargerCustomStyles = {
	...modalCustomStyles,
	content: {
		minWidth: '1300px'
	}
};

// Taux de chagres patronales
const tauxChargesPatronales = 0.6;

// Nom pays France
const nomFrance = "FRANCE METROPOLITAINE";
const idFrance = "3-7tgrikpkZUc";


const modalCustomStylesPage = {
	content: {
		borderRadius: 'none',
		zIndex: 10
	},
	overlay: {
		zIndex: 5
	}
};

const paginationNumbers:PageNumbers = {
	p1: 50,
	p2: 100,
	p3: 200,
	p4: 500,
	p5: 1000
}

const maxTotalFilesSize = '100';
const maxFileSize = '30';
const maxFileSizeMb = 30000000;
const maxTotalFilesSizeMb = 100000000;

export {
	modalCustomStyles,
	modalSmallerCustomStyles,
	modalMediumCustomStyles,
	modalMediumExportCustomStyles,
	modalLargeCustomStyles,
	modalLargerCustomStyles,
	tauxChargesPatronales,
	nomFrance,
	idFrance,
	modalCustomStylesPage,
	paginationNumbers,
	maxTotalFilesSize,
	maxFileSize,
	maxFileSizeMb,
	maxTotalFilesSizeMb
};