import React, { useEffect, useState } from 'react';
import {BOCurrentUserDto, DiscussionMessageDto, StatutDiscussion, DiscussionDto } from '../services/generated/BackOffice-api';
import moment from 'moment';
import MessageSeparateur from 'adel-shared/dist/components/basics/MessageSeparateur';
import Message from 'adel-shared/dist/components/basics/Message';
import { scrollToBottom } from 'adel-shared/dist/utils/functions';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import InputSelect from 'adel-shared/dist/components/basics/InputSelect';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import { updateStatutDiscussion } from '../store/structure-store/actions/structureMessagesActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

interface MessagesContainerProps {
	discussion: DiscussionDto,
	user:BOCurrentUserDto
}

const MessagesContainer: React.FC<MessagesContainerProps> = ({
	discussion,
	user
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [messageStatut, setMessageStatut] = useState<StatutDiscussion>();
	const [discussionDetails, setDiscussionDetails] = useState<DiscussionDto>(discussion);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (discussion) {
			setMessageStatut(discussion.statut);
			setDiscussionDetails(discussion)
			scrollToBottom("messagerie");
		}
	}, [discussion])

	const optionsStatut = () => {
		const allOptions = Object.keys(StatutDiscussion).filter(x => x !== 'None').map(x => ({
			value: StatutDiscussion[x as keyof typeof StatutDiscussion],
			label: t(`statutDiscussion.${StatutDiscussion[x as keyof typeof StatutDiscussion]}`)
		}));
		return allOptions;
	}

	const updateStatut = async () => {
		setIsLoading(true);
		try {
			discussion.id && await updateStatutDiscussion(dispatch, discussion.id, {statut: messageStatut});
			toast.success("Le statut de la discussion a bien été enregistré");
		} catch(error) {
			toast.error("Une erreur est survenue lors du changement de statut");
		}
		setIsLoading(false);
	}

	return (<>
		<div className="messageHeader">
			<div className="messageHeader__item">
				<InputReadOnly
					label="Structure:"
					content={`${discussion.structureUsername || discussion.dossierUsername || '-'}`}
				/>

				<InputReadOnly
					label="Interlocuteur/trice:"
					content={`${discussion.interlocuteur || '-'}`}
				/>
			</div>

			<div className="messageHeader__item">
				<p>Objet: {t(`objetDiscussion.${discussion.objet}`)}</p>

				<div className="messageHeader__button">
					<InputSelect
						name=""
						classname="inputSelect"
						label="Statut"
						options={optionsStatut()}
						onChange={(elem) => {
							setMessageStatut(elem)
						}}
						value={messageStatut}
					/>

					{isLoading
						? <Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
						: <button onClick={updateStatut}>
							<i className="far fa-save"></i>
						</button>
					}
				</div>
			</div>
		</div>

		<div className="messagerie" id="messagerie">
			{user && discussion.messages?.map((m, index) => (
				<div key={m.messageId}>
					{((m.message?.createdAt && index === 0) || 
					(index !== 0 && moment(m.message?.createdAt).format('YYYY-MM-DD') !== moment((discussion.messages as DiscussionMessageDto[])[index - 1]?.message?.createdAt).format('YYYY-MM-DD')))
						&& <MessageSeparateur date={moment(m.message?.createdAt).locale("fr").format("DD MMMM YYYY")} />
					}
					<Message
						createdAt={m.message?.createdAt}
						nom={m.message?.auteur && m.message?.auteur.nom ? m.message?.auteur.nom : ""}
						prenom={m.message?.auteur && m.message?.auteur.prenom ? m.message?.auteur.prenom : ""}
						fromMe={user?.id === m.message?.auteur?.id}
						contenu={m.message?.contenu}
						piecesJointes={m && m.message?.piecesJointes ? m.message?.piecesJointes : []}
					/>
				</div>
			))}
		</div>
	</>);
}

export default MessagesContainer;