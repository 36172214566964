import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDossierSelector } from '../../../../store/store-helpers';
import { uniqueId } from 'lodash';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import ModalAddEvenement from './ModalAddEvenement';
import { formatNumber, formaterDate } from 'adel-shared/dist/utils/functions';
import useValidation from '../../../../custom-hooks/useValidation';
import { getEvenementsFinanciersViewModel } from '../../../../store/dossier-store/actions/dossierDetailsActions';
import { useDispatch } from 'react-redux';

interface EvenementsProps {
	dossierId:string | any;
}

const Evenements: React.FC<EvenementsProps> = ({ dossierId }) => {
	const {dossierMouvements, evenementFinancier} = useDossierSelector();
	const tableId = useRef<string>(uniqueId());
    const [data, setData] = useState<any[]>([]);
	const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
	const dispatch = useDispatch()
	const { getRootValidator } = useValidation();
	const validator = getRootValidator("AddEvenementFinancierDto");

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Type de mouvement", accessor: "typeMouvement", disableSortBy: true },
					{ Header: "Crédit", accessor: "credit", disableSortBy: true, className:'table__cell--montants' },
					{ Header: "Débit", accessor: "debit", disableSortBy: true, className:'table__cell--montants' },
					{ Header: "Date de prise en charge", accessor: "datePriseEnCharge", disableSortBy: true },
					{ Header: "Date de validation", accessor: "dateValidation", disableSortBy: true },
					{ Header: "Date de clôture", accessor: "dateCloture", disableSortBy: true },
					{ Header: "N° Bordereau", accessor: "numero", disableSortBy: true },
					{ accessor: "id" }
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: { hiddenColumns: ["id"] },
			manualSortBy: true
		} as any,
		useSortBy,
		useRowSelect
    );

	useEffect(() => {
		if (dossierMouvements) {
			setData(dossierMouvements.map(mouvement => (
				{
					typeMouvement: mouvement.typeMouvement,
					credit: `${mouvement.credit ? formatNumber(mouvement.credit) : '-'} €`,
					debit: `${mouvement.debit ? formatNumber(mouvement.debit) : '-'} €`,
					datePriseEnCharge: mouvement.datePriseEnCharge ? formaterDate(mouvement.datePriseEnCharge) : '-',
					dateValidation: mouvement.dateValidation ? formaterDate(mouvement.dateValidation) : '-',
					dateCloture: mouvement.dateVirement ? formaterDate(mouvement.dateVirement) : '-',
					numero: mouvement.numeroBordereau,
					id: mouvement.id
				}
			)));
		}
	}, [dossierMouvements]);

	return (<>
		{evenementFinancier?.montant !== null && <div className="commission__contentHeaderButton" onClick={async() => {setOpenModalAdd(true); await getEvenementsFinanciersViewModel(dispatch, dossierId);
}}>
			<label>Ajouter un évènement</label>
			<button className="categorieFormeJuridique-header__icon">
				<i className="far fa-plus"></i>
			</button>
		</div>}
		<Table
			className="table--event"
			data={data}
			getTableProps={getTableProps}
			getTableBodyProps={getTableBodyProps}
			headerGroups={headerGroups}
			rows={rows}
			prepareRow={prepareRow}
			styles={styleTable.onglet}
		/>
		{dossierId && openModalAdd &&
			<ModalAddEvenement
				validator={validator}
				dossierId={dossierId}
				isOpen={openModalAdd}
				setIsOpen={setOpenModalAdd}
			/>
		}
	</>);
}

export default Evenements;