import React, {
    useState,
    useRef,
    useMemo,
    useEffect,
    FunctionComponent,
    ChangeEvent
} from 'react';
import _ from 'lodash';
import {
    useStructureDispatcher,
    useCategorieDossierDispatcher,
    useCommissionDispatcher,
    useCommissionSelector
} from '../../../store/store-helpers';
import { fetchAutocompleteNom as fetchAutocompleteStructure } from '../../../store/structure-store/actions/structureAction';
import { fetchCategoriesDossier as fetchTypesDossier } from '../../../store/categorie-dossier-store/actions/categorieDossierAction';
import { fetchCommissionsListLight } from '../../../store/commission-store/actions/commissionActions';

import Input from 'adel-shared/dist/components/basics/Input';
import { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';

import {
    StatutDossier,
    CategorieDossierDto,
    CommissionStartingDateDto
} from '../../../services/generated/BackOffice-api';
import { useTranslation } from 'react-i18next';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import CheckboxGeneric from '../../basics/CheckboxGeneric';
import Dropdown from '../../basics/Dropdown';
import { IDossierFilters } from '../DossiersListe';
import {
    dateCommissionFilterKey,
    nomFilterKey,
    numeroFilterKey,
    statutFilterKey,
    structureFilterKey,
    typeFilterKey,
    codeFilterKey
} from "../../../constants/filters.constant";

interface FiltersProps {
    hideLabel?: boolean;
    filters: IDossierFilters;
    setFilters: (value: any) => void;
}

const DossierFilters: FunctionComponent<FiltersProps> = ({
    hideLabel = false,
    filters,
    setFilters
}) => {
    const structureDispatch = useStructureDispatcher();
    const categorieDossierDispatch = useCategorieDossierDispatcher();
    const commissionDispatch = useCommissionDispatcher();
    const { t } = useTranslation();
    const refInput = useRef<HTMLInputElement>(null);

    const { commissionsListLight = [] } = useCommissionSelector();

    const debounceStructure = useMemo(() => _.debounce<(value: any) => void>((value) => {
        fetchAutocompleteStructure(structureDispatch, value)().then((result: any) => {
            setStructureList(result.payload);
        })
    }, 1000), []);


    /** Permissions */
    const usePermGetCommissionsStartingDate = useHasPermission("GetCommissionsStartingDate");


    /** Numéro du dossier */
    const searchNumero = (value: string) => {
        setFilters({
            type: 'update',
            payload: {
                [numeroFilterKey]: value
            }
        });
    }

    /** Nom du dossier */
    const searchNom = (value: string) => {
        setFilters({
            type: 'update',
            payload: {
                [nomFilterKey]: value
            }
        });
    }

    /** Code du structure */
    const searchCode = (value: string) => {
        setFilters({
            type: 'update',
            payload: {
                [codeFilterKey]: value
            }
        });
    }
    /** Nom de la structure */
    const [structureList, setStructureList] = useState<string[]>([]);

    const searchStructure = (value: string) => {
        setFilters({
            type: 'update',
            payload: {
                [structureFilterKey]: value
            }
        })
        debounceStructure(value);
    }

    /** Statut du compte */
    const optionsStatut = () => {
        const allOptions: AdelOption<StatutDossier | string>[] = [];

        for (const status in StatutDossier) {
            const statusValue = StatutDossier[status as keyof typeof StatutDossier];
            if (statusValue !== StatutDossier.None) {
                allOptions.push(
                    {
                        value: statusValue,
                        label: t(`dossier.statut.${statusValue}`)
                    }
                )
            }
        }

        return allOptions;
    };

    const onChangeStatut = (elem: any, value: string) => {
        const updatedStatut = [...filters[statutFilterKey] || []];
        const selectedStatut = optionsStatut().find(x => x.value === value)?.value;
        const selectedIndex = updatedStatut.findIndex(e => e === selectedStatut);

        if (selectedIndex === -1 && selectedStatut)
            updatedStatut.push(selectedStatut)
        else
            updatedStatut.splice(selectedIndex, 1);

        setFilters({
            type: 'update',
            payload: {
                [statutFilterKey]: updatedStatut
            }
        });
    }

    /** Types de dossier */
    const [optionsTypes, setOptionsTypes] = useState<CategorieDossierDto[]>([]);

    useEffect(() => {
        fetchCommissionsListLight(commissionDispatch);
        fetchTypesDossier(categorieDossierDispatch)().then(result => {
            setOptionsTypes(result.payload?.categories);
        });
    }, []);

    const onChangeTypes = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        const updatedTypes = [...filters[typeFilterKey] || []];
        const selectedType = optionsTypes.find(x => x.id === id)?.nom;
        const selectedIndex = updatedTypes.findIndex(e => e === selectedType);

        if (selectedIndex === -1 && selectedType)
            updatedTypes.push(selectedType)
        else
            updatedTypes.splice(selectedIndex, 1);

        setFilters({
            type: 'update',
            payload: {
                [typeFilterKey]: updatedTypes
            }
        });
    };

    /** Dates de commission */
    const [optionsDatesCommissions, setOptionsDatesCommissions] = useState<CommissionStartingDateDto[]>([]);

    useEffect(() => {
        if (usePermGetCommissionsStartingDate && commissionsListLight.length > 0) {
            setOptionsDatesCommissions([...optionsDatesCommissions, ...commissionsListLight]);
        }
    }, [usePermGetCommissionsStartingDate, commissionsListLight]);

    const onChangeDatesCommission = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        const updatedDatesCommission = [...filters[dateCommissionFilterKey] || []];
        const selectedDate = commissionsListLight.find(x => x.commissionId === id)?.dateDebut?.toString();
        const selectedIndex = updatedDatesCommission.findIndex(e => e === selectedDate);

        if (selectedIndex === -1 && selectedDate)
            updatedDatesCommission.push(selectedDate)
        else
            updatedDatesCommission.splice(selectedIndex, 1);

        setFilters({
            type: 'update',
            payload: {
                [dateCommissionFilterKey]: updatedDatesCommission
            }
        });
    };

    return (
        <div className="filtres__item">
            {!hideLabel && (
                <label>Recherches avancées :</label>
            )}
            <div className="filtres__search">
                <Input
                    autoComplete="off"
                    name="numero"
                    reference={null}
                    className="filter__input"
                    placeHolder="Numéro de dossier"
                    type="text"
                    maxLength={60}
                    value={filters[numeroFilterKey]}
                    onChange={(value: string) => searchNumero(value)}
                />

                <Input
                    autoComplete="off"
                    name="nom"
                    reference={refInput}
                    className="filter__input"
                    placeHolder="Nom du dossier"
                    type="text"
                    maxLength={60}
                    value={filters[nomFilterKey]}
                    onChange={(value: string) => searchNom(value)}
                />
                <Input name="code"
                    className="filter__input"
                    reference={null}
                    label=""
                    placeHolder="Code compte"
                    type="text"
                    maxLength={11}
                    value={filters[codeFilterKey]}
                    onChange={(value: string) => searchCode(value)}
                />
                <Dropdown
                    label="Types"
                    className="filter__input"
                >
                    <ul>
                        {optionsTypes?.map(x => (
                            <li key={x.id}>
                                <CheckboxGeneric
                                    label={x.nomCourt}
                                    id={x.id || ''}
                                    items={filters[typeFilterKey]}
                                    onChange={onChangeTypes}
                                    checked={filters[typeFilterKey]?.some(y => y === x.nom)}
                                />
                            </li>
                        ))}
                    </ul>
                </Dropdown>

                <Input
                    autoComplete="off"
                    name="structure"
                    reference={refInput}
                    className="filter__input"
                    placeHolder="Nom de la structure"
                    type="text"
                    maxLength={60}
                    value={filters[structureFilterKey]}
                    dataList={structureList}
                    onChange={(value: string) => searchStructure(value)}
                />

                <Dropdown label="Statut" className="filter__input">
                    <ul>
                        {optionsStatut()?.map(x => (
                            <li key={x.value}>
                                <CheckboxGeneric
                                    label={x.label}
                                    id={x.value || ''}
                                    items={filters[statutFilterKey]}
                                    onChange={onChangeStatut}
                                    checked={filters[statutFilterKey]?.some(y => y === x.value)}
                                />
                            </li>
                        ))}
                    </ul>
                </Dropdown>

                {usePermGetCommissionsStartingDate && (
                    <Dropdown
                        label="Commissions"
                        className="filter__input"
                    >
                        <ul>
                            {optionsDatesCommissions.map(x => (
                                <li key={x.commissionId}>
                                    <CheckboxGeneric
                                        label={x.nom}
                                        id={x.commissionId || ''}
                                        items={filters[dateCommissionFilterKey]}
                                        onChange={onChangeDatesCommission}
                                        checked={filters[dateCommissionFilterKey]?.some(y => y === x.dateDebut?.toString())}
                                    />
                                </li>
                            ))}
                        </ul>
                    </Dropdown>
                )}


            </div>
        </div>
    );
}

export default DossierFilters;
