/**
 * FILTERS CONSTANTS - Les constantes à appliquer à l'Api
 */

import { IDossierFilters } from "../components/dossiers/DossiersListe";

/**
 * Le comparateur (@= ou ==) doit directement être inclu dans la variable
 * @= --> CONTAINS
 * == --> STRICTEMENT EGAL
 *
 * Cf. https://github.com/Biarity/Sieve#send-a-request
 *
 */

const nomFilterKey = "Nom@=";
const emailFilterKey = "UserEmail@=";
const statutFilterKey = "Statut==";
const userNameFilterKey = "UserName@=";
const siretFilterKey = "Siret@=";
const responsableFilterKey = "RepresentantFullName@=";
const typeFilterKey = "CategorieNom==";
const versionFilterKey = "Version==";
const numeroFilterKey = "Numero@=";
const codeFilterKey = "CodeStructure@=";
const structureFilterKey = "RaisonSociale@=";
const groupeFilterKey = "Groupe==";
const dateCommissionFilterKey = "DateDebutCommission==";
const caCommissionFilterKey = "CA==";
const searchCommissionMemberFilterKey = "(Nom|Prenom|Email)@=";
const commissionMembrePresenceFilterKey = "Presence==";
const chargeInstructionFilterKey = "ChargeInstructionId@=";
const chargeInstructionNomFilterKey = "ChargeInstructionNom@=";
const chargeInstructionPrenomFilterKey = "ChargeInstructionPrenom@=";
const villeFilterKey = "Ville@=";
const modifiableParStructureFilterKey = "ModifiableParStructure==";
const responsableNomFilterKey = "ResponsableNom@=";
const responsablePrenomFilterKey = "ResponsablePrenom@=";
const dsvFilterKey = "DSV==";
const blocageJuridiqueFilterKey = "BlocageJuridique==";
const dateSoumissionMinFilterKey = "DateSoumission>=";
const dateSoumissionMaxFilterKey = "DateSoumission<=";
const canVersementFilterKey = "canVersement==";
const typeVersementFilterKey = "typeVersement==";
const statutVersementFilterKey = "statutVersement==";
const enCoursValidationDSVFilterKey = "enCoursValidationDSV==";
const valideParDSVFilterKey = "valideParDSV==";
const alerteVerteFilterKey = "AlerteVerte==";
const alerteRougeFilterKey = "AlerteRouge==";
const ribValideFilterKey = "RIBValidation==";
const ribModifiableFilterKey = "RIBModifiableParStructure==";
const blocageAvisFilterKey = "BlocageAvis==";
const representantNomFilterKey = "RepresentantNom@=";
const representanPrenomFilterKey ="RepresentantPrenom@=";
const artisteNomFilterKey = "ArtisteNom@=";
const artistePrenomFilterKey = "ArtistePrenom@=";
const InstructionRole = "chargeInstructionDivisionCulturelle";
const filtersDefault:IDossierFilters = {
	[numeroFilterKey]: '',
	[codeFilterKey]: '',
	[nomFilterKey]: '',
	[siretFilterKey]: '',
	[structureFilterKey]: '',
	[statutFilterKey]: [],
	[typeFilterKey]: [],
	[dateCommissionFilterKey]: [],
	[villeFilterKey]: '',
	[chargeInstructionNomFilterKey]: '',
	[chargeInstructionPrenomFilterKey]: '',
	[responsableNomFilterKey]: '',
	[responsablePrenomFilterKey]: '',
	[representantNomFilterKey]: '',
	[representanPrenomFilterKey]: '',
	[artisteNomFilterKey]: '',
	[artistePrenomFilterKey]: '',
	[typeVersementFilterKey]: undefined,
	[statutVersementFilterKey]: undefined,
	[dateSoumissionMinFilterKey]: undefined,
	[dateSoumissionMaxFilterKey]: undefined,
	[dsvFilterKey]: undefined,
	[blocageJuridiqueFilterKey]: undefined,
	[modifiableParStructureFilterKey]: undefined,
	[blocageAvisFilterKey]: undefined,
	[canVersementFilterKey]: undefined,
	[enCoursValidationDSVFilterKey]: undefined,
	[valideParDSVFilterKey]: undefined,
	[alerteRougeFilterKey]: undefined,
	[alerteVerteFilterKey]: undefined,
	[ribModifiableFilterKey]: undefined,
	[ribValideFilterKey]: undefined,

};
const structureFilterDefault = {
	nom :"",
	siret :"",
    email: "",
	username: "",
    statut: "all",
    responsable: ""
}
const commissionFilterDefault = {
	annee:"all",
    groupe: "all",
	nom: ""
}
export {
	nomFilterKey,
	siretFilterKey,
	emailFilterKey,
	statutFilterKey,
	userNameFilterKey,
	responsableFilterKey,
	typeFilterKey,
	versionFilterKey,
	numeroFilterKey,
	codeFilterKey,
	structureFilterKey,
	groupeFilterKey,
	dateCommissionFilterKey,
	searchCommissionMemberFilterKey,
	caCommissionFilterKey,
	commissionMembrePresenceFilterKey,
	chargeInstructionFilterKey,
	chargeInstructionPrenomFilterKey,
	chargeInstructionNomFilterKey,
	villeFilterKey,
	modifiableParStructureFilterKey,
	responsableNomFilterKey,
	responsablePrenomFilterKey,
	dsvFilterKey,
	blocageJuridiqueFilterKey,
	dateSoumissionMinFilterKey,
	dateSoumissionMaxFilterKey,
	canVersementFilterKey,
	typeVersementFilterKey,
	enCoursValidationDSVFilterKey,
	valideParDSVFilterKey,
	alerteVerteFilterKey,
	alerteRougeFilterKey,
	ribValideFilterKey,
	ribModifiableFilterKey,
	blocageAvisFilterKey,
	representantNomFilterKey,
	artisteNomFilterKey,
	representanPrenomFilterKey,
	artistePrenomFilterKey,
	InstructionRole,
	statutVersementFilterKey,
	filtersDefault,
	structureFilterDefault,
	commissionFilterDefault
};
