import _ from "lodash";
import { UserAgentApplication, AuthError, AuthResponse, AuthenticationParameters } from "msal";
import { axiosInstance } from "../../custom-hooks/useAxios";
import { ApiException, AuthClient } from '../generated/BackOffice-api';

const login = async (onLoginSucceeded: () => void, onLoginError: (error:ApiException) => void) => {
    let loginInProgress = isLoginInProgress(); // Flag pour ne pas interférer avec la gestion de msal.js lors de la redirection depuis Azure AD

    let config = await new AuthClient().getAuthInfo();

    if (!config.clientId || !config.authority || !config.scope) throw "Azure AD configuration could not be retrieved. Please contact your Dev Team.";

    let scopesArray = config.scope.split(' ');

    if (!window || !window.location || !window.location.origin) throw "Could not retrieve current URL. Please contact your Dev Team";

    const myMSALObj = new UserAgentApplication({ auth: { clientId: config.clientId, authority: config.authority, redirectUri: window.location.origin } });

    // Scopes de l'ID Token demandés par l'API du BO
    const getIdTokenRequest: AuthenticationParameters = { scopes: scopesArray, forceRefresh: true };

    // clientid en tant que scope pour pouvoir renouveller l'ID Token
    const renewIdTokenRequest: AuthenticationParameters = { scopes: [config.clientId] };

    if (!loginInProgress) {

        console.debug("acquireTokenSilent");

        // Refresh the idtoken if necessary
        try {

            await myMSALObj.acquireTokenSilent(renewIdTokenRequest);

            console.debug("idtoken refreshed successfully");

            // Retrieve the idtoken with the desired scopes
            try {

                let response = await myMSALObj.acquireTokenSilent(getIdTokenRequest);

                window.sessionStorage.setItem('tokenExpiration', response.idToken.expiration);

                console.debug("acquireTokenSilent success");

                if (!isTokenExpired(response)) {
					handleSuccessfulAuthResponse(response, onLoginSucceeded, onLoginError);
                } else {

                    console.debug("Token expired. This should not be possible but let's try to renew it anyway.");

                    myMSALObj.loginRedirect(getIdTokenRequest);
                }

            } catch (getIdTokenError) {

                // Acquire token silent failure, sending an interactive request
                console.debug("Could not acquire token silently, but don't worry");

                handleError(getIdTokenError, () => { myMSALObj.loginRedirect(getIdTokenRequest) });

            }
        } catch (renewIdTokenError) {

            // Acquire token silent failure, sending an interactive request
            console.debug("Could not renew id token silently, but don't worry");

            handleError(renewIdTokenError, () => { myMSALObj.loginRedirect(getIdTokenRequest) });

        }

    } else {

        console.debug("loginInProgress");

    }

    // Callback appelée après redirection depuis la mire de login Azure AD (loginRedirect)
    function loginRedirectCallback(authErr: AuthError, response?: AuthResponse) {

        console.debug("Redirection after Login Callback called");

        if (authErr) {

            handleError(authErr, () => {
                document.body.innerText = "You are not authorized to connect to this application. Please contact your administrator";
            });

        } else {
            handleSuccessfulAuthResponse(response, onLoginSucceeded, onLoginError);
        }
    }

    myMSALObj.handleRedirectCallback(loginRedirectCallback);

}

export const handleError = (error: any, callback: () => void) => {

    if(error.errorMessage.indexOf("not assigned to a role for the application") !== -1) {

        callback();

    } else if (error.errorMessage.indexOf("interaction_required") !== -1
        || error.errorMessage.indexOf("Send an interactive authorization request") !== -1
        || error.errorMessage.indexOf("User login is required") !== -1) { // NTH: Replace with error.errorCode == "user_login_error" ?

        console.debug(`interaction_required detected. Trying interactive login`);

        callback();

    } else {
        console.error("Unhandled error. Please contact your Dev Team", error);
    }
}

const isTokenExpired = (response: AuthResponse): boolean => {
    let expirationTimestamp = Number(response.idToken.expiration);
    let expirationTimestamMillisecond = expirationTimestamp * 1000;
    let expirationDateUtc = new Date(expirationTimestamMillisecond);
    let nowDateUtc = new Date(Date.now());
    console.debug(`Token expiring at ${expirationDateUtc}`);
    if (nowDateUtc > expirationDateUtc) {
        return true;
    }
    return false;
}

// Appelé après un acquireTokenSilent ou un loginRedirect réussi (via sa callback authCallback)
// permet de conserver le `preferred_name` pour de futures authentifications silencieuses + appeler l'endpoint de login pour recevoir le cookie d'authent du BO
const handleSuccessfulAuthResponse = (response: AuthResponse | undefined, onLoginSucceeded: () => void, onLoginError: (error:ApiException) => void) => {
    if (response && response.idToken && response.idToken.rawIdToken) {
        const client = new AuthClient();
        client.login({
            idToken: response.idToken.rawIdToken
        }).then(() => {
            onLoginSucceeded();
        }).catch(error => {
			onLoginError(error.response)
            console.error("Unhandeld error from BO login API. Please contact your Dev Team", error);
        })
    }
}

const logout = async() => {
    const authClient = new AuthClient('', axiosInstance);
    const config = await authClient.getAuthInfo();

    if (!config.clientId || !config.authority || !config.scope) throw "Azure AD configuration could not be retrieved. Please contact your Dev Team.";

    const myMSALObj = new UserAgentApplication({ auth: { clientId: config.clientId, authority: config.authority, redirectUri: window.location.origin } });

    await authClient.logout();
    await myMSALObj.logout();
}

const isLoginInProgress = (): boolean => {
    if (window.sessionStorage["msal.interaction_status"] === "inProgress") {
        return true;
    }
    return false;
}


let authTokenRequest: Promise<void> | null;

const renewTokenRequest = async() => {
    const authClient = new AuthClient();
    const config = await authClient.getAuthInfo();

    if (!config.clientId || !config.authority || !config.scope) throw "Azure AD configuration could not be retrieved. Please contact your Dev Team.";

    const myMSALObj = new UserAgentApplication({ auth: { clientId: config.clientId, authority: config.authority, redirectUri: window.location.origin } });
    const renewIdTokenRequest: AuthenticationParameters = { scopes: [config.clientId] };

    try {
        const response = await myMSALObj.acquireTokenSilent(renewIdTokenRequest);
        await authClient.login({
            idToken: response.idToken.rawIdToken
        });
    } catch(error) {
        handleError(error, () => myMSALObj.loginRedirect(renewIdTokenRequest));
    }
};

const getAuthToken = async() => {
    if(!authTokenRequest) {
        authTokenRequest = renewTokenRequest();
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
};

const resetAuthTokenRequest = () => {
    authTokenRequest = null;
};

// Refresh token if expired
axiosInstance.interceptors.response.use(response => {
    return response;
}, async function(error) {
    const originalRequest = error.config;
    if(error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await getAuthToken();
        //window.location.href = "/";
        return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
});

export {
    login,
    logout
};