import React, { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CollapsibleSectionProps {
	title: string;
	isOpenByDefault?: boolean ;
	children: ReactElement<any>;
}

const CollapsibleSection: React.SFC<CollapsibleSectionProps> = ({
	title,
	isOpenByDefault,
	children

}) => {
	const [isOpen, setIsOpen] = useState<boolean>(isOpenByDefault ?? false);
	const toggleCollapse = () => {
		setIsOpen(!isOpen);
	}

	return(
		<div className="collapsibleSection">
			<button className="collapsibleSection__button" onClick={toggleCollapse}>
				<h3 className="collapsibleSection__title">
					<span>{title}</span>
					<FontAwesomeIcon icon={!isOpen ? 'chevron-down' : 'chevron-up'} />
				</h3>
			</button>
			<div className="collapsibleSection__contentBGWhite">
				{isOpen && React.cloneElement(children)}
			</div>
		</div>
	);
}

export default CollapsibleSection;