import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import Toggle from 'adel-shared/dist/components/Toggle';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Input from 'adel-shared/dist/components/basics/Input';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { modalMediumCustomStyles } from '../../../constants/config.constant';
import { CategorieDossierDecisionDto, DossierClient, ValidateDossierDto } from '../../../services/generated/BackOffice-api';
import { fetchCommissionDetailsDossiers } from '../../../store/commission-store/actions/commissionActions';
import { getDossierValidationViewModel, validateDossier } from '../../../store/dossier-store/actions/dossierAction';
import { useCommissionDispatcher, useDossierDispatcher, useDossierSelector } from '../../../store/store-helpers';
import ReactDOM from 'react-dom'
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { axiosInstance } from '../../../custom-hooks/useAxios';


interface ModalValidationDossier {
	isOpen: boolean;
	onClose: () => void;
	idComm?:string;
	id?: string | null;
	goToNextDossier: () => void;
	selectedCategorie: string;
	isVoteReadonly: boolean;

}


const ModalValidationDossier: FunctionComponent<ModalValidationDossier> = ({
	isOpen,
	onClose,
	id,
	idComm,
	goToNextDossier,
	selectedCategorie,
	isVoteReadonly
}) => {
	const { control, register, getValues, watch, setValue, triggerValidation, errors } = useForm<ValidateDossierDto>({
		defaultValues: {
			votesPour: 0,
			votesContre: 0,
			votesAbstention: 0,
			montantPropose: 0,
			montantAttribue: 0
		}
	});
	const watchMontant = watch("montantPropose");
	const dispatch = useDossierDispatcher();
	const dispatchCom = useCommissionDispatcher();
	const {dossierValidation, isLoading} = useDossierSelector();
	const [blocageAvis, setBlocageAvis] = useState<boolean>(false);
	const [montantAttribue, setMontantAttribue] = useState<number>(dossierValidation?.montantAttribue || 0);
	const [options, setOptions] = useState<AdelOption<string>[]>([]);
	const [optionsPositive, setOptionsPositive] = useState<AdelOption<string>[]>([]);
	const [optionsNegative, setOptionsNegative] = useState<AdelOption<string>[]>([]);
    
	
	const dossierClient = new DossierClient('', axiosInstance);
	useEffect(() => {
		try {
			(async() => {
				id && await getDossierValidationViewModel(dispatch, id);
				
				const result = await dossierClient.getCategorieDossierDecisions(selectedCategorie);
				const resPos : AdelOption<string>[] = [];
				const resNeg : AdelOption<string>[] = [];
				result.forEach(item => {
					const option = {
					label: item.motivation ?? "",
					value: item.id ?? ""
					};
					item.aideAcceptee ? resPos.push(option) : resNeg.push(option);
				});

				setOptionsPositive(resPos);
				setOptionsNegative(resNeg);
			})();
			
		} catch (error) {
				toast.error(error);
		}
	}, []);

	useEffect(() => {
		if(dossierValidation) {
			setValue([
				{votesPour: dossierValidation.votesPour},
				{votesContre: dossierValidation.votesContre},
				{votesAbstention: dossierValidation.votesAbstention},
				{ montantPropose: parseFloat((dossierValidation.montantPropose || 0).toString().replace(/,/g, '.'))},
				{ montantAttribue: parseFloat((dossierValidation.montantAttribue || 0).toString().replace(',', '.')) },
				{commentaire: dossierValidation.commentaire}
			]);
			setMontantAttribue(dossierValidation.montantAttribue || 0);
			dossierValidation.blocageAvis !== undefined && setBlocageAvis(dossierValidation.blocageAvis);
		}
	}, [dossierValidation]);


	/** Envoi */
	const submit = useCallback(async () => {
		const result = await triggerValidation();

		if(!result) return;

		try {
			const body = {
				votesPour: parseFloat((getValues().votesPour || 0).toString()),
				votesContre: parseFloat((getValues().votesContre || 0).toString()),
				votesAbstention: parseFloat((getValues().votesAbstention || 0).toString()),
				montantCalcule,
				montantPropose: parseFloat((getValues().montantPropose || 0).toString().replace(/,/g, '.')),
				montantAttribue: parseFloat((getValues().montantAttribue || 0).toString().replace(/,/g, '.')),
				commentaire: getValues().commentaire,
				blocageAvis
			}
			if(id && idComm) {
				await validateDossier(dispatch, id, body);
				await fetchCommissionDetailsDossiers(dispatchCom, idComm, selectedCategorie);
			}
			onClose();
			goToNextDossier();
			toast.success("Le dossier a bien été validé");
		} catch(error) {
			if(error.response?.exception?.message) {
				toast.error(error.response.exception.message);
			} else {
				toast.error("Une erreur est survenue lors de la validation du dossier");
			}		}
	}, [
		id,
		blocageAvis
	]);

	/** Montant calculé par Adel */
	const [montantCalcule, setMontantCalcule] = useState<number>(0);

	useEffect(() => {
		const montantPropose: number = getValues().montantPropose || 0;

		if (dossierValidation) {
			const noteAdministrative = dossierValidation.resultatNoteAdministrative != null ? dossierValidation.resultatNoteAdministrative : 10;
			const montantCalcule = (((noteAdministrative / 10)
				* (dossierValidation.sommeDemandee || 0))
				+ parseFloat(montantPropose.toString()))
				/ 2;
			setMontantCalcule(montantCalcule);
		} else return;
	}, [dossierValidation, watchMontant]);

	// const [anneeFiltre, setAnneeFiltre] = useState<string>('commissionFilter.annee');


	// const optionsAnnee = () => {
	// 	let allOptions: AdelOption<number | string>[] = [];
	// 	const currentYear = new Date().getFullYear();
	// 	let startYear = 2010;
	// 	while ( startYear <= currentYear ) {
	// 		allOptions.push({
	// 			value: startYear,
	// 			label: `${startYear}`
	// 		});
	// 		startYear++;
	// 	}
	// 	return [{value: "all", label: "Toutes les années"}, ...allOptions.sort((a:any,b:any) => b.value - a.value)];
	// }
	// const onChangeAnnee = (elem: any) => {
	// 	if(elem !== "all") {
	// 		setAnneeFiltre(elem);
	// 	} else {
	// 		setAnneeFiltre("all");
	// 	}
	// }

	const onChangePosDecision = (value: any) => {
		const selectedOption = optionsPositive.find((option) => option.value == value);
		const commentaire = (getValues().commentaire) ? `${getValues()?.commentaire} \n ${selectedOption?.label}` : `${selectedOption?.label}`
		 setValue('commentaire', commentaire)
		// setOptionsPositive(optionsPositive.filter(option => option.value != value));
	}

	const onChangeNegDecision = (value: any) => {
		const selectedOption = optionsNegative.find((option) => option.value == value);
		const commentaire = (getValues().commentaire) ? `${getValues()?.commentaire} \n ${selectedOption?.label}` : `${selectedOption?.label}`
		 setValue('commentaire', commentaire)
	}

	return (
		<Modal
			isOpen={isOpen}
			style={modalMediumCustomStyles}
			className="normal__modal validationDossierModal"
		>
			<div className="modal__header">
				<h3>Finalisation</h3>
			</div>

			<div className="modal__content" style={{overflowY:'visible'}}>
				<div className="counter">
					<div className="counter__item">
						<input
							name="votesPour"
							className="counter__input"
							maxLength={2}
							type="number"
							ref={register}
							readOnly={isVoteReadonly}
						/>
						<p>Pour</p>
					</div>
					<div className="counter__item">
						<input
							name="votesContre"
							className="counter__input"
							maxLength={2}
							type="number"
							ref={register}
							readOnly={isVoteReadonly}
						/>
						<p>Contre</p>
					</div>
					<div className="counter__item">
						<input
							name="votesAbstention"
							className="counter__input"
							maxLength={2}
							type="number"
							ref={register}
							readOnly={isVoteReadonly}
						/>
						<p>Abstention</p>
					</div>
				</div>

				<div className="validationDossierModal__row">
					<div className="validationDossierModal__item">
						<InputReadOnly
							label="Somme demandée par la structure"
							content={`${dossierValidation?.sommeDemandee != null ? formatNumber(dossierValidation.sommeDemandee) : '-'} €`}
						/>
					</div>
					<div className="validationDossierModal__item">
						<InputReadOnly
							label="Note administrative (sur 10)"
							content={`${dossierValidation?.resultatNoteAdministrative != null ? dossierValidation.resultatNoteAdministrative : '-'}`}
						/>
					</div>
					<div className="validationDossierModal__item">
						<InputReadOnly
							label="Montant calculé par Adel"
							content={`${formatNumber(montantCalcule)} €`}
						/>
					</div>
				</div>
				<div className="validationDossierModal__row">
					<div className="validationDossierModal__item input__euro">
						{!isVoteReadonly ?
							<Input
								label="Somme proposée par la commission"
								name="montantPropose"								
								type='string'								
								max={dossierValidation?.sommeDemandee || 0}
								reference={register({
									validate: {
										GreaterThanOrEqualZero: value => parseFloat((value).toString().replace(/,/g, '.')) >= 0 || "Le montant doit être supérieur ou égal à 0",
										GreaterThanOrEqualValidation: value => parseFloat((value).toString().replace(/,/g, '.')) <= (dossierValidation?.sommeDemandee || 0) || "La somme proposée par la SPEDIDAM doit être inférieure ou égale à la somme demandée par la structure"
									}
								})}
								errors={errors}
								defaultValue={getValues('montantPropose')}

							/> :
							<InputReadOnly
								label="Somme proposée par la commission"
								/**@ts-ignore */

								content={dossierValidation.montantPropose?.toString().replace(/,/g, '.')  && dossierValidation.montantPropose?.toString().replace(/,/g, '.')}
							/>}
					</div>
					<div className="validationDossierModal__item input__euro">
						{!isVoteReadonly ? <Input
							label="Somme attribuée par la commission"
							name="montantAttribue"
							/*type="number"*/
							type='String'

							reference={register({
								validate: {
									GreaterThanOrEqualZero: value => parseFloat((value).toString().replace(/,/g, '.')) >= 0 || "Le montant doit être supérieur ou égal à 0",
									GreaterThanOrEqualValidation: value => parseFloat((value).toString().replace(/,/g, '.')) <= ((dossierValidation?.sommeDemandee || 0) + 0.99) || "La somme attribuée par la SPEDIDAM doit être inférieure ou égale à la somme demandée par la structure + 0.99€"
								}
							})}
							errors={errors}
							//@ts-ignore
							onChange={(ev)=> {setValue('montantAttribue', ev); setMontantAttribue(ev)}}
						/> :
							<InputReadOnly
								label="Somme attribuée par la commission"
								/**@ts-ignore */
								content={parseFloat(dossierValidation.montantAttribue?.toString().replace(/,/g, '.')) && parseFloat(dossierValidation.montantAttribue?.toString().replace(/,/g, '.'))}
							/>}
					</div>
				</div>
				{
					!isVoteReadonly &&
					<div className="modal__item modal__itemSelect--fullWith" style={{ display: 'flex', gap: '10px'}}>
					<div style={{ flex: 1 }}>
						<Controller control={control}
						name="positiveDecision"
						as={({ onChange, value, name }) => (
							<InputSelect<string>
								name={name}
								label="Motivations Pour"
								classname="inputSelect"
								options={optionsPositive}
								onChange={(value) => onChangePosDecision(value)}
								value={value}
								errors={errors}
								placeholder='Motivations de décisions pour'
							/>
						)}
						// rules={validator?.["TypeMouvementId"]}
					/>
					</div>
				<div style={{ flex: 1 }}>
					<Controller control={control}
						name="negativeDecision"
						as={({ onChange, value, name }) => (
							<InputSelect<string>
								name={name}
								label="Motivations Contre"
								classname="inputSelect"
								options={optionsNegative}
								onChange={(value) => onChangeNegDecision(value)}
								value={value}
								errors={errors}
								placeholder='Motivations de décisions contre'
							/>
						)}
						// rules={validator?.["TypeMouvementId"]}
					/>
				</div>
			</div>
			}
				<div className="validationDossierModal__row">
					<div className="validationDossierModal__item">
						<label>Commentaire (facultatif)</label>
						{
							!isVoteReadonly ?
								<textarea className="textarea" maxLength={500} name="commentaire" ref={register} /> :
								<p> {dossierValidation?.commentaire} </p>
						}
					</div>
				</div>
				<div className="validationDossierModal__row">
					<div className="validationDossierModal__item">
						<Toggle
							label="Bloquer l'avis"
							value={blocageAvis}
							disabled={isVoteReadonly}
							setCheck={setBlocageAvis}
						/>
					</div>
				</div>
			</div>

			<div className="modal__footer">
				<FormButton
					type="button"
					value={isVoteReadonly ? "Fermer" : "Annuler"}
					onClick={onClose}
				/>
				{!isVoteReadonly && (isLoading
					? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
					: <FormButton
						type="submit"
						value="Confirmer la validation"
						onClick={submit}
					/>
				)}
			</div>
		</Modal>
	);
}

export default ModalValidationDossier;