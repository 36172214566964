import React, { useEffect } from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useStructureSelector } from '../../../store/store-helpers';
import { FormContextValues, ValidationOptions } from 'react-hook-form';
import { Dictionary } from 'adel-shared/dist/models/Dictionary';
import Input from 'adel-shared/dist/components/basics/Input';

interface AdresseCorrespondantProps {
    form: FormContextValues;
	usePermUpdateStructureDetails: boolean;
	validator: Dictionary<ValidationOptions> | null;
}

const AdresseCorrespondant: React.FC<AdresseCorrespondantProps>  = ({ form, usePermUpdateStructureDetails, validator }) => {	
	const {structureDetails = {}} = useStructureSelector();

	useEffect(() => {
		if(structureDetails?.adresseCorrespondance) {
			form.setValue([
				{line1: structureDetails.adresseCorrespondance.line1},
				{line2: structureDetails.adresseCorrespondance.line2},
				{ville: structureDetails.adresseCorrespondance.ville},
				{codePostal: structureDetails.adresseCorrespondance.codePostal}
			]);
		} else {
			structureDetails?.adresse && form.setValue([
				{line1: structureDetails.adresse.line1},
				{line2: structureDetails.adresse.line2},
				{ville: structureDetails.adresse.ville},
				{codePostal: structureDetails.adresse.codePostal}
			]);
		}
	}, [structureDetails?.adresseCorrespondance, structureDetails?.adresse]);

	return (<>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					{usePermUpdateStructureDetails
						? <Input
							label="Adresse*"
							type="text"
							name="line1"
							reference={validator && form.register(validator["Line1"])}
							errors={form.errors}
						/>
						: <InputReadOnly
							label="Adresse"
							content={structureDetails?.adresseCorrespondance?.line1 || '-'}
						/>
					}
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					{usePermUpdateStructureDetails
						? <Input
							label="Code postal*"
							type="number"
							name="codePostal"
							reference={validator && form.register(validator["CodePostal"])}
							errors={form.errors}
						/>
						: <InputReadOnly
							label="Code postal"
							content={structureDetails?.adresseCorrespondance?.codePostal || '-'}
						/>
					}
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					{usePermUpdateStructureDetails
						? <Input
							label="Ville*"
							type="text"
							name="ville"
							reference={validator && form.register(validator["Ville"])}
							errors={form.errors}
						/>
						: <InputReadOnly
							label="Ville"
							content={structureDetails?.adresseCorrespondance?.ville || '-'}
						/>
					}
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					{usePermUpdateStructureDetails
						? <Input
							label="Complément d'adresse"
							type="text"
							name="line2"
							reference={validator && form.register(validator["Line2"])}
							errors={form.errors}
						/>
						: <InputReadOnly
							label="Complément d'adresse"
							content={structureDetails?.adresseCorrespondance?.line2 || '-'}
						/>
					}
				</div>
			</div>
		</div>
	</>);
}

export default AdresseCorrespondant;