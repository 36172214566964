import Pagination from 'adel-shared/dist/components/basics/Pagination';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import _ from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';
import { paginationNumbers } from '../../../constants/config.constant';
import { DossierHistoryDto, DossierHistoryModel } from '../../../services/generated/BackOffice-api';
import { fetchDossierHistory } from '../../../store/dossier-store/actions/dossierHistoryActions'
import { useDossierDispatcher } from '../../../store/store-helpers';
import { dicoHasModif, hasModif, objectHasModif, renderHistoryDico, renderHistoryObject, renderHistoryValue } from '../../../utils/history';
import { formaterDate, formaterDateWithTime } from 'adel-shared/dist/utils/functions';

interface DossierHistoriqueTabProps {
	id: string;
}

const DossierHistoriqueTab: FunctionComponent<DossierHistoriqueTabProps> = ({ id }) => {
	const dispatch = useDossierDispatcher();
	const [data, setData] = useState<DossierHistoryDto[]>([]);
	const [sort, setSort] = useState<string>("");

	const { t } = useTranslation();

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	useEffect(() => {
		id && fetchDossierHistory(dispatch, id, sort, page, pageSize).then(result => {
			if ((result.number !== undefined && result.totalPageCount !== undefined) && (result.number > result.totalPageCount)) setPage(1);

			const items = result.items?.reduce((accumulator: DossierHistoryDto[], currentValue: DossierHistoryDto) => {
				const existingLine = accumulator.findIndex(
					x => moment(x.dateTime).format('DDMMYYYY') === moment(currentValue.dateTime).format('DDMMYYYY') &&
					x.userFullName === currentValue.userFullName
				);

				if(existingLine > -1) {
					accumulator[existingLine].before = _.merge({}, currentValue.before, accumulator[existingLine].before);
					accumulator[existingLine].after = _.merge({}, currentValue.after, accumulator[existingLine].after);
				} else {
					accumulator.push(currentValue);
				}

				return accumulator;
			}, []);

			setData(items?.map(history => ({
				DateTime: history.dateTime && formaterDate(history.dateTime),
				actions: (history.before && history.after) && {
					before: history.before,
					after: history.after
				},
				User: history.userFullName,
				id: history.id
			})) || []);

			setHasNext(result.hasNext || false);
			setHasPrevious(result.hasPrevious || false);
			setIsFirst(result.isFirst || false);
			setIsLast(result.isLast || false);
			setTotalPageCount(result.totalPageCount || 0);
			setTotalItemCount(result.totalItemCount || 0);
		})
	}, [fetchDossierHistory, page, pageSize, sort]);

	const renderHistorique = (value: { before: DossierHistoryModel, after: DossierHistoryModel }) => {
		const before = value.before;
		const after = value.after;

        // un seul niveau d'objet
        const numero = (before.numero || after.numero) && hasModif("numéro", before.numero, after.numero);
        const description = (before.description || after.description) && hasModif("description", before.description, after.description);
		const note = (before.note || after.note) && hasModif("note", before.note, after.note);
		const dateSoumission = (before.dateSoumission || after.dateSoumission) && hasModif("date de soumission", before.dateSoumission, after.dateSoumission, value => formaterDateWithTime(value));
        const dsv = (before.dsv || after.dsv) && hasModif("Droit exclusif", before.dsv, after.dsv);
        const commentaires = (before.commentaires || after.commentaires) && hasModif("commentaires", before.commentaires, after.commentaires);
        const blocageJuridique = (before.blocageJuridique || after.blocageJuridique) && hasModif("blocage juridique", before.blocageJuridique, after.blocageJuridique);
        const modifiableParStructure = (before.modifiableParStructure || after.modifiableParStructure) && hasModif("modifiable par la structure", before.modifiableParStructure, after.modifiableParStructure);
		const dateProlongation = (before.dateProlongation || after.dateProlongation) && hasModif("date de prolongation", before.dateProlongation, after.dateProlongation, value => formaterDateWithTime(value));

		// budget
        const commentaireBudget = (before.budget?.commentaire || after.budget?.commentaire) && hasModif("commentaire budget", before.budget?.commentaire, after.budget?.commentaire);
        const depensesBudget = (before.budget?.depenses && after.budget?.depenses) && dicoHasModif("depenses budget", before.budget.depenses, after.budget.depenses, value => `${value.champ} (${value.montant} €)`);
        const financementsBudget = (before.budget?.financements && after.budget?.financements) && dicoHasModif("financements budget", before.budget.financements, after.budget.financements, value => `${value.champ} (${value.montant} €)`);
        const documentsBudget = (before.budget?.documents && after.budget?.documents) && dicoHasModif("documents budget", before.budget.documents, after.budget.documents, "fileName");

		// deux niveaux d'objet
        const chargeInstruction = (before.chargeInstruction || after.chargeInstruction) && objectHasModif("chargé d'instruction", before.chargeInstruction, after.chargeInstruction, t);
        const responsable = (before.responsable || after.responsable) && objectHasModif("responsable", before.responsable, after.responsable, t);
        const chiffresCles = (before.chiffresCles || after.chiffresCles) && objectHasModif("chiffres clés", before.chiffresCles, after.chiffresCles, t);

        // ce sont des dicos
        const documentsDossier = (before.documentsDossiers && after.documentsDossiers) && dicoHasModif("documents du dossier", before.documentsDossiers, after.documentsDossiers, "fileName");
		const artistes = (before.artistes && after.artistes) && dicoHasModif("artistes", before.artistes, after.artistes, value => `${value.prenom} ${value.nom}`);
        const enseignants = (before.enseignants && after.enseignants) && dicoHasModif("enseignants", before.enseignants, after.enseignants, value => `${value.prenom} ${value.nom}`);
        const salaries = (before.salaries && after.salaries) && dicoHasModif("salariés", before.salaries, after.salaries, value => `${value.prenom} ${value.nom}`);
        const prestations = (before.prestations && after.prestations) && dicoHasModif("prestations", before.prestations, after.prestations, "typePrestation");
        const descriptionAnswers = (before.descriptionAnswers && after.descriptionAnswers) && dicoHasModif("description", before.descriptionAnswers, after.descriptionAnswers, "question");
        const informationProjectAnswers = (before.informationProjetAnswers && after.informationProjetAnswers) && dicoHasModif("informations projet", before.informationProjetAnswers, after.informationProjetAnswers, "question");

		return (
			<div className="detailsTab__table-row">
				{renderHistoryValue(numero)}
				{renderHistoryValue(description)}
				{renderHistoryValue(note)}
				{renderHistoryValue(dateSoumission)}
				{renderHistoryValue(dsv)}
				{renderHistoryValue(commentaires)}
				{renderHistoryValue(blocageJuridique)}
				{renderHistoryValue(modifiableParStructure)}
				{renderHistoryValue(dateProlongation)}
				{renderHistoryObject(chargeInstruction)}
				{renderHistoryObject(responsable)}
				{renderHistoryValue(commentaireBudget)}
				{renderHistoryDico(depensesBudget)}
				{renderHistoryDico(financementsBudget)}
				{renderHistoryDico(documentsBudget)}
				{renderHistoryObject(chiffresCles)}
				{renderHistoryDico(documentsDossier)}
				{renderHistoryDico(artistes)}
				{renderHistoryDico(enseignants)}
				{renderHistoryDico(salaries)}
				{renderHistoryDico(prestations)}
				{renderHistoryDico(descriptionAnswers)}
				{renderHistoryDico(informationProjectAnswers)}
			</div>
		);
	}

	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Date de création",
						accessor: "DateTime",
						sortType: "basic"
					},
					{
						Header: "Actions des chargés d'instructions",
						accessor: "actions",
						disableSortBy: true,
						Cell: (props:any) => <>
							{
								renderHistorique(props.value)
							}
						</>
					},
					{
						Header: "Modifié par",
						accessor: "User",
						sortType: "basic"
					},
					{
						Header: "Id",
						accessor: "id"
					}
				]
			}
		];
	}, []);

	/** Sort */
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);


	return (
		<div className="detailsTab">
			<Table
				data={data}
				getTableProps={getTableProps}
				getTableBodyProps={getTableBodyProps}
				headerGroups={headerGroups}
				rows={rows}
				prepareRow={prepareRow}
				styles={styleTable.onglet}
			/>
			<Pagination
				hasNext={hasNext}
				hasPrevious={hasPrevious}
				isFirst={isFirst}
				isLast={isLast}
				totalPageCount={totalPageCount}
				totalItemCount={totalItemCount}
				page={setPage}
				pageSize={setPageSize}
				initPageSize={pageSize}
				initPage={page}
				pageNumbers={paginationNumbers}
			/>
		</div>
	);
}

export default DossierHistoriqueTab;