import React, { FunctionComponent } from 'react';
import { TypePoste } from '../../../services/generated/BackOffice-api';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useTranslation } from 'react-i18next';
import { formatPhone } from 'adel-shared/dist/utils/functions';
import { useStructureSelector } from '../../../store/store-helpers';

interface CorrespondantProps {
}

const Correspondant: FunctionComponent<CorrespondantProps> = ({ }) => {
	const { t } = useTranslation();
	const {structureDetails={}} = useStructureSelector();


	return (<>
		<div className="detailsTab__row">
			<div className="detailsTab__content">
				<InputReadOnly
					label="Poste"
					content={(structureDetails.correspondant?.poste && structureDetails.correspondant?.poste !== TypePoste.None) ? t(`common.typePoste.${structureDetails.correspondant.poste}`) : '-'}
				/>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Civilité"
						content={structureDetails.correspondant?.civilite ? t(`common.civilite.${structureDetails.correspondant.civilite}`) : '-'}
					/>
				</div>
				<div className="detailsTab__content">
					<InputReadOnly
						label="Email"
						content={structureDetails.correspondant?.email || '-'}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Prénom"
						content={structureDetails.correspondant?.prenom || '-'}
					/>
				</div>
				<div className="detailsTab__content">
					<InputReadOnly
						label="Téléphone portable"
						content={structureDetails.correspondant?.telephonePortable ? formatPhone(structureDetails.correspondant.telephonePortable) : '-'}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Nom"
						content={structureDetails.correspondant?.nom || '-'}
					/>
				</div>
				<div className="detailsTab__content">
					<InputReadOnly
						label="Téléphone"
						content={structureDetails.correspondant?.telephoneFixe ? formatPhone(structureDetails.correspondant.telephoneFixe) : '-'}
					/>
				</div>
			</div>
		</div>
	</>);
}

export default Correspondant;
