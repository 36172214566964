export var ValidationPropertyType;
(function (ValidationPropertyType) {
    ValidationPropertyType["NotNull"] = "NotNullValidator";
    ValidationPropertyType["NotEmpty"] = "NotEmptyValidator";
    ValidationPropertyType["NotEqual"] = "NotEqualValidator";
    ValidationPropertyType["Email"] = "EmailValidator";
    ValidationPropertyType["Number"] = "NumberValidator";
    ValidationPropertyType["Integer"] = "IntegerValidator";
    ValidationPropertyType["LessThan"] = "LessThanValidator";
    ValidationPropertyType["LessThanOrEqual"] = "LessThanOrEqualValidator";
    ValidationPropertyType["GreaterThan"] = "GreaterThanValidator";
    ValidationPropertyType["GreaterThanOrEqual"] = "GreaterThanOrEqualValidator";
    ValidationPropertyType["Length"] = "LengthValidator";
    ValidationPropertyType["ExactLength"] = "ExactLengthValidator";
    ValidationPropertyType["MinimumLength"] = "MinimumLengthValidator";
    ValidationPropertyType["MaximumLength"] = "MaximumLengthValidator";
    ValidationPropertyType["RegularExpression"] = "RegularExpressionValidator";
    ValidationPropertyType["UniqueCharCountProperty"] = "UniqueCharCountPropertyValidator";
    ValidationPropertyType["AtLeastDigitChar"] = "AtLeastXDigitValidator";
    ValidationPropertyType["AtLeastLowerCaseChar"] = "AtLeastXLowerCaseCharValidator";
    ValidationPropertyType["AtLeastUpperCaseChar"] = "AtLeastXUpperCaseCharValidator";
    ValidationPropertyType["AtLEastSpecialChar"] = "AtLeastXSpecialCharValidator";
    ValidationPropertyType["BeforeNow"] = "BeforeNowValidator";
    ValidationPropertyType["AfterNow"] = "AfterNowValidator";
    ValidationPropertyType["OlderThanDays"] = "OlderThanXDaysValidator";
    ValidationPropertyType["NotOlderThanDays"] = "NotOlderThanXDaysValidator";
    ValidationPropertyType["YoungerThanDays"] = "YoungerThanXDaysValidator";
    ValidationPropertyType["Iban"] = "IbanPropertyValidator";
    ValidationPropertyType["Bic"] = "BicPropertyValidator";
    ValidationPropertyType["Siret"] = "SiretPropertyValidator";
    ValidationPropertyType["Phone"] = "PhoneNumberPropertyValidator";
    ValidationPropertyType["Enum"] = "EnumValidator";
    ValidationPropertyType["AspNetCoreCompatibleEmail"] = "AspNetCoreCompatibleEmailValidator";
    ValidationPropertyType["ChildValidatorAdaptor"] = "ChildValidatorAdaptor";
    ValidationPropertyType["Predicate"] = "PredicateValidator";
    ValidationPropertyType["AsyncPredicate"] = "AsyncPredicateValidator";
})(ValidationPropertyType || (ValidationPropertyType = {}));
