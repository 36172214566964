import React, { useState, useCallback, ChangeEvent, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
interface CheckboxGenericProps extends RouteComponentProps {
	id: string;
	label?: string;
	items?: any[];
	setItems?: (value: any) => void;
	onChange?: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
	checked?: boolean;
}

const CheckboxGeneric: React.FunctionComponent<CheckboxGenericProps> = ({
	id,
	label,
	items,
	setItems,
	onChange,
	checked = false
}) => {
	const [isChecked, setIsChecked] = useState<boolean>(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);

		if(onChange) {
			onChange(e, id);
		} else if(setItems && items) {
			let newArray = [...items];
			let selectedIndex = newArray.findIndex(e => e === id);
			if (selectedIndex === -1)
				newArray.push(id)
			else
				newArray.splice(selectedIndex, 1);
			setItems(newArray);
		}
	}, [setIsChecked, items, setItems, onChange, id]);
 
	return (
		<div className="checkbox table__check">
			<label>
				<input
					name={id} 
					id={id} 
					type="checkbox"
					checked={isChecked}
					onChange={handleChange}
				/>
				<div className="newCheckbox"></div>
				{label}
			</label>
		</div>
	);
};

export default CheckboxGeneric;
