import { useSelector, useDispatch } from "react-redux";
import { CombinedState, Dispatch } from "redux";
import { AdresseState } from "./test-store/states/adresseState";
import { DispatchAdresseAction } from "./test-store/actions/adresseAction";
import { StructureState } from "./structure-store/states/structureState";
import { DossierState } from "./dossier-store/states/dossierState";
import { UserState } from "./user-store/states/userState";
import { DispatchStructureAction, } from "./structure-store/actions/structureAction";
import { DispatchUserAction } from "./user-store/actions/userAction";
import { VueState } from "./vue-store/states/vueState";
import { TemplateState } from "./template-store/states/templateState";
import { DispatchVueAction } from "./vue-store/actions/vueAction";
import { DispatchTemplateAction } from "./template-store/actions/templateAction";
import { CategorieFormeJuridiqueState } from './categorie-forme-juridique-store/states/categorieFormeJuridiqueState';
import { DispatchCategorieFormeJuridiqueAction } from './categorie-forme-juridique-store/actions/categoryFormeJuridiqueAction';
import { DispatchTypeDeDocumentAction } from './type-de-document-store/actions/typeDeDocumentAction';
import { TypeDeDocumentState } from "./type-de-document-store/states/typeDeDocumentState";
import { DispatchCritereDossierAction } from './critere-dossier-store/actions/critereDossierAction';
import { CritereDossierState } from './critere-dossier-store/states/critereDossierState';
import { DispatchCategorieDossierAction } from './categorie-dossier-store/actions/categorieDossierAction';
import { CategorieDossierState } from './categorie-dossier-store/states/categorieDossierState';
import { DispatchPageVitrineAction } from './page-vitrine-store/actions/pageVitrineAction';
import { PageVitrineState } from './page-vitrine-store/states/pageVitrineState';
import { DispatchRemunerationAction } from './remuneration-store/actions/remunerationAction';
import { RemunerationState } from './remuneration-store/states/remunerationState';
import { CommissionState } from './commission-store/states/commissionState';
import { DispatchCommissionAction } from './commission-store/actions/commissionActions';
import { CommissionMembreState } from './commission-membre-store/states/commissionMembreState';
import { DispatchCommissionMembreAction } from './commission-membre-store/actions/commissionMembreActions';
import { DispatchDossierAction } from "./dossier-store/actions/dossierAction";
import { CompteBancaireState } from './compte-bancaire-store/states/compteBancaireState';
import { DispatchCompteBancaireAction } from "./compte-bancaire-store/actions/compteBancaireAction";
import { DispatchBordereauAction } from "./bordereau-store/actions/bordereauActions";
import { BordereauState } from "./bordereau-store/states/bordereauStates";
import { DispatchPageWarningAction } from './page-warning-store/pageWarningAction';
import { PageWarningState } from './page-warning-store/pageWarningState';
import { GeneralState } from "./general-store/states/generalState";
import { GenerationSpedidamState } from "./gestion-entite-store/states/generationSpedidamState";
import { DispatchGenerationSpedidamAction } from "./gestion-entite-store/actions/generationSpedidiamAction";

export const useAdresseSelector = (): Partial<AdresseState> => {
	const adresseSelector = useSelector<CombinedState<{ adresseReducer: Partial<AdresseState> }>, Partial<AdresseState>>((state) => {
		return state.adresseReducer;
	});
	return adresseSelector;
}

export const useStructureSelector = (): Partial<StructureState> => {
	const structureSelector = useSelector<CombinedState<{ structureReducer: Partial<StructureState> }>, Partial<StructureState>>((state) => {
		return state.structureReducer;
	});
	return structureSelector;
}

export const useDossierSelector = (): Partial<DossierState> => {
	const dossierSelector = useSelector<CombinedState<{ dossierReducer: Partial<DossierState> }>, Partial<DossierState>>((state) => {
		return state.dossierReducer;
	});
	return dossierSelector;
}

export const useUserSelector = (): Partial<UserState> => {
	const userSelector = useSelector<CombinedState<{ userReducer: Partial<UserState> }>, Partial<UserState>>((state) => {
		return state.userReducer;
	});
	return userSelector;
}

export const useVueSelector = (): Partial<VueState> => {
	const vueSelector = useSelector<CombinedState<{ vueReducer: Partial<VueState> }>, Partial<VueState>>((state) => {
		return state.vueReducer;
	});
	return vueSelector;
}

export const useTemplateSelector = (): Partial<TemplateState> => {
	const templateSelector = useSelector<CombinedState<{ templateReducer: Partial<TemplateState> }>, Partial<TemplateState>>((state) => {
		return state.templateReducer;
	});
	return templateSelector;
}

export const useCategorieFormeJuridiqueSelector = (): Partial<CategorieFormeJuridiqueState> => {
	const categorieSelector = useSelector<CombinedState<{ categoriesFormeJuridiqueReducer: Partial<CategorieFormeJuridiqueState> }>, Partial<CategorieFormeJuridiqueState>>((state) => {
		return state.categoriesFormeJuridiqueReducer;
	});
	return categorieSelector;
}

export const useTypeDeDocumentSelector = (): Partial<TypeDeDocumentState> => {
	const documentSelector = useSelector<CombinedState<{ typeDeDocumentReducer: Partial<TypeDeDocumentState> }>, Partial<TypeDeDocumentState>>((state) => {
		return state.typeDeDocumentReducer;
	});
	return documentSelector;
}


export const useCritereDossierSelector = (): Partial<CritereDossierState> => {
	const critereSelector = useSelector<CombinedState<{ criteresDossierReducer: Partial<CritereDossierState> }>, Partial<CritereDossierState>>((state) => {
		return state.criteresDossierReducer;
	});
	return critereSelector;
}

export const useCategorieDossierSelector = (): Partial<CategorieDossierState> => {
	const categorieSelector = useSelector<CombinedState<{ categorieDossierReducer: Partial<CategorieDossierState> }>, Partial<CategorieDossierState>>((state) => {
		return state.categorieDossierReducer;
	});
	return categorieSelector;
}

export const usePageVitrineSelector = (): Partial<PageVitrineState> => {
	const pageVitrineSelector = useSelector<CombinedState<{ pageVitrineReducer: Partial<PageVitrineState> }>, Partial<PageVitrineState>>((state) => {
		return state.pageVitrineReducer;
	});
	return pageVitrineSelector;
}

export const useRemunerationSelector = (): Partial<RemunerationState> => {
	const remunerationSelector = useSelector<CombinedState<{ remunerationReducer: Partial<RemunerationState> }>, Partial<RemunerationState>>((state) => {
		return state.remunerationReducer;
	});
	return remunerationSelector;
}

export const useCommissionSelector = (): Partial<CommissionState> => {
	const commissionSelector = useSelector<CombinedState<{ commissionReducer: Partial<CommissionState> }>, Partial<CommissionState>>((state) => {
		return state.commissionReducer;
	});
	return commissionSelector;
}

export const useCommissionMembreSelector = (): Partial<CommissionMembreState> => {
	const commissionMembreSelector = useSelector<CombinedState<{ commissionMembreReducer: Partial<CommissionMembreState> }>, Partial<CommissionMembreState>>((state) => {
		return state.commissionMembreReducer;
	});
	return commissionMembreSelector;
}

export const useCompteBancaireSelector = (): Partial<CompteBancaireState> => {
	const compteBancaireSelector = useSelector<CombinedState<{ compteBancaireReducer: Partial<CompteBancaireState> }>, Partial<CompteBancaireState>>((state) => {
		return state.compteBancaireReducer;
	});
	return compteBancaireSelector;
}

export const useBordereauSelector = (): Partial<BordereauState> => {
	const bordereauSelector = useSelector<CombinedState<{ bordereauReducer: Partial<BordereauState> }>, Partial<BordereauState>>((state) => {
		return state.bordereauReducer;
	});
	return bordereauSelector;
}

export const usePageWarningSelector = (): Partial<PageWarningState> => {
	const pageWarningSelector = useSelector<CombinedState<{ pageWarningReducer: Partial<PageWarningState> }>, Partial<PageWarningState>>((state) => {
		return state.pageWarningReducer;
	});
	return pageWarningSelector;
}

export const useGeneralSelector = (): Partial<GeneralState> => {
	const generalSelector = useSelector<CombinedState<{ generalReducer: Partial<GeneralState> }>, Partial<GeneralState>>((state) => {
		return state.generalReducer;
	});
	return generalSelector;
}

export const useGeneralSpedidamSelector = (): Partial<GenerationSpedidamState> => {
	const generationSpedidamSelector = useSelector<CombinedState<{ generationSpedidamReducer: Partial<GenerationSpedidamState> }>, Partial<GenerationSpedidamState>>((state) => {
		return state.generationSpedidamReducer;
	});
	return generationSpedidamSelector;
}

export const useAdresseDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchAdresseAction>>();
	return dispatcher;
}

export const useStructureDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchStructureAction>>();
	return dispatcher;
}

export const useDossierDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchDossierAction>>();
	return dispatcher;
}

export const useUserDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchUserAction>>();
	return dispatcher;
}

export const useVueDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchVueAction>>();
	return dispatcher;
}

export const useTemplateDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchTemplateAction>>();
	return dispatcher;
}

export const useCategorieFormeJuridiqueDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchCategorieFormeJuridiqueAction>>();
	return dispatcher;
}

export const useTypeDeDocumentDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchTypeDeDocumentAction>>();
	return dispatcher;
}

export const useCritereDossierDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchCritereDossierAction>>();
	return dispatcher;
}

export const useCategorieDossierDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchCategorieDossierAction>>();
	return dispatcher;
}

export const usePageVitrineDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchPageVitrineAction>>();
	return dispatcher;
}

export const useRemunerationDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchRemunerationAction>>();
	return dispatcher;
}

export const useCommissionDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchCommissionAction>>();
	return dispatcher;
}

export const useCommissionMembreDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchCommissionMembreAction>>();
	return dispatcher;
}

export const useCompteBancaireDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchCompteBancaireAction>>();
	return dispatcher;
}

export const useBordereauDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchBordereauAction>>();
	return dispatcher;
}

export const usePageWarningDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchPageWarningAction>>();
	return dispatcher;
}

export const useGestionSpedidamDispatcher = () => {
	const dispatcher = useDispatch<Dispatch<DispatchGenerationSpedidamAction>>();
	return dispatcher;
}