import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { CreateDiscussionDto, DiscussionClient, DiscussionDto, DiscussionMessageDto, FileParameter, StructureClient, UpdateStatutDiscussionDto } from '../../../services/generated/BackOffice-api';
import { DispatchStructureAction, StructureActionsEnum } from './structureAction';

/** Actions */
export const requestStructureMessages = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_MESSAGES_REQUEST,
		payload: {}
	}
}

export const requestPostStructureMessages = () => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_MESSAGES_REQUEST,
		payload: {}
	}
}

export const postStructureMessages = (result: DiscussionDto) => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_MESSAGES_SUCCESS,
		payload: result
	}
}

/** Task */
const discussionClient = new DiscussionClient('', axiosInstance);

export const fetchStructureMessages = async (dispatch: any, id: string, userId?: string) => {
	dispatch(requestStructureMessages());
	try {
		const response = await discussionClient.getDiscussionsStructure(id);
		dispatch({
			type: StructureActionsEnum.GET_STRUCTURE_MESSAGES_SUCCESS,
			payload: {
				structureDiscussions: response,
				structureDiscussionsLu: response.filter(x =>
					x.messages?.filter(m => m.message?.auteur?.id !== userId).every(m => m.estLu)
				).map(x => x.id)
			}
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les discussions");
	}
}

export const createStructureDiscussion = async (dispatch: any, discussionId: string, contenu: CreateDiscussionDto) => {
	dispatch({
		type: StructureActionsEnum.CREATE_STRUCTURE_DISCUSSION_REQUEST,
	});
	try {
		let response = await discussionClient.createDiscussionStructure(discussionId, contenu);
		dispatch({
			type: StructureActionsEnum.CREATE_STRUCTURE_DISCUSSION_SUCCESS,
			payload: {structureDiscussion:  response }
		});
	} catch(error) {
		dispatch({ type: StructureActionsEnum.CREATE_STRUCTURE_DISCUSSION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de créer une discussion");
	}
}

export const sendStructureMessages = async (dispatch: any, discussion: DiscussionDto, contenu: string, doc:FileParameter[]) => {
	dispatch(requestPostStructureMessages());
	try {
		let newMessages = discussion.messages || [];
		let response = await discussionClient.createMessageDiscussion((discussion.id || ''), contenu, doc);
		newMessages.unshift(response);
		dispatch({
			type: StructureActionsEnum.POST_STRUCTURE_MESSAGES_SUCCESS,
			payload: {
				structureMessage:  response,
				structureDiscussion: {...discussion, messages: newMessages }
			}
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible d'envoyer le message");
	}
}

export const sendStructureMultipleMessages = async (dispatch: any, contenu: string, doc:FileParameter[], filters?: any) => {
	const filtersString = Object.entries(filters)
		.filter(([key, value]) => value !== null && value !== undefined && value !== '')
		.map(x => x.join('')).join();
	
	dispatch({ type: StructureActionsEnum.POST_STRUCTURE_MULTIPLE_MESSAGES_REQUEST });
	try {
		await discussionClient.createMultipleStructureDiscussion(filtersString, undefined, undefined, undefined, true, contenu, doc);
		dispatch({ type: StructureActionsEnum.POST_STRUCTURE_MULTIPLE_MESSAGES_SUCCESS });
	} catch(error) {
		dispatch({ type: StructureActionsEnum.POST_STRUCTURE_MULTIPLE_MESSAGES_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible d'envoyer plusieurs messages");
	}
}

export const updateStatutDiscussion = async (dispatch: any, discussionId: string, statut: UpdateStatutDiscussionDto) => {
	dispatch({ type: StructureActionsEnum.UPDATE_STATUT_DISCUSSION_REQUEST });
	try {
		await discussionClient.updateStatutDiscussion(discussionId, statut);
		dispatch({ type: StructureActionsEnum.UPDATE_STATUT_DISCUSSION_SUCCESS });
	} catch(error) {
		dispatch({ type: StructureActionsEnum.UPDATE_STATUT_DISCUSSION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour le statut de discussion");
	}
}

export const setDiscussionLu = async (dispatch: any, discussionId: string) => {
	try {
		await discussionClient.setDiscussionLu(discussionId);
		dispatch({
			type: StructureActionsEnum.UPDATE_STRUCTURE_DISCUSSION_LU_SUCCESS,
			payload: { discussionId }
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre la discussion à lu");
		throw error;
	}
}

export const getObjetsDiscussionsStructure = async (dispatch:Dispatch<DispatchStructureAction>) => {
	try {
		const result = await discussionClient.getObjetsDiscussionsStructure();
		dispatch({
			type: StructureActionsEnum.GET_STRUCTURE_MESSAGES_OBJECT,
			payload: { objectDiscussionStructure: result }
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les objets de discussion structure");
		throw error;
	}
}
