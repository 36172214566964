import { Reducer } from "redux";
import { CategorieDossierState, initialCategorieDossier } from "../states/categorieDossierState";
import { DispatchCategorieDossierAction, CategorieDossierActionsEnum } from "../actions/categorieDossierAction";

export const categorieDossierReducer: Reducer<Partial<CategorieDossierState>, DispatchCategorieDossierAction> = (state = initialCategorieDossier, action) => {
	switch (action.type) {
		case CategorieDossierActionsEnum.GET_CATEGORIES_REQUEST:
			return {
				...state,
				categoriesIsLoading: true
			};
		case CategorieDossierActionsEnum.GET_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: action.payload.categories,
				categoriesIsLoading: false
			};
		default:
			return state;
	}
};
