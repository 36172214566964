import { Action } from 'redux';
import { TypeDeDocumentState } from '../states/typeDeDocumentState';
import { TypeDeDocumentClient, TypeDeDocumentWithCategoriesDto, TypeDeDocumentDto, CreateOrUpdateTypeDeDocumentDto } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';


export enum TypeDeDocumentActionsEnum {
	GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST",
	GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS",
	DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST",
	DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS",
	DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR",
	POST_DOCUMENT_REQUEST = "POST_DOCUMENT_REQUEST",
	POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS",
	POST_DOCUMENT_ERROR = "POST_DOCUMENT_ERROR",
	PUT_DOCUMENT_REQUEST = "PUT_DOCUMENT_REQUEST",
	PUT_DOCUMENT_SUCCESS = "PUT_DOCUMENT_SUCCESS",
	PUT_DOCUMENT_ERROR = "PUT_DOCUMENT_ERROR"
}

export interface DispatchTypeDeDocumentAction extends Action<TypeDeDocumentActionsEnum> {
	payload: TypeDeDocumentState;
}


//get typeDeDocument
export const requestTypeDeDocuments = () => {
	return {
		type: TypeDeDocumentActionsEnum.GET_DOCUMENTS_REQUEST
	}
}

export const getTypeDeDocuments = (result: TypeDeDocumentWithCategoriesDto[]) => {
	return {
		type: TypeDeDocumentActionsEnum.GET_DOCUMENTS_SUCCESS,
		payload: { documents: result }
	}
}

// Delete typeDeDocument
export const sendDeleteTypeDeDocument = () => {
	return {
		type: TypeDeDocumentActionsEnum.DELETE_DOCUMENT_REQUEST
	}
}
export const deletedTypeDeDocument = (result: string) => {
	return {
		type: TypeDeDocumentActionsEnum.DELETE_DOCUMENT_SUCCESS,
		payload: { id: result }
	}
}
export const errorDeletedTypeDeDocument = () => {
	return {
		type: TypeDeDocumentActionsEnum.DELETE_DOCUMENT_ERROR
	}
}

// Send typeDeDocument
export const sendDocument = () => {
	return {
		type: TypeDeDocumentActionsEnum.POST_DOCUMENT_REQUEST

	}
}
export const savedDocument = (result: TypeDeDocumentWithCategoriesDto) => {
	return {
		type: TypeDeDocumentActionsEnum.POST_DOCUMENT_SUCCESS,
		payload: { document: result }
	}
}
export const errorSavedDocument = () => {
	return {
		type: TypeDeDocumentActionsEnum.POST_DOCUMENT_ERROR
	}
}

// Modif document
export const sendModifDocument = () => {
	return {
		type: TypeDeDocumentActionsEnum.PUT_DOCUMENT_REQUEST
	}
}
export const modifiedDocument = (result: TypeDeDocumentWithCategoriesDto) => {
	return {
		type: TypeDeDocumentActionsEnum.PUT_DOCUMENT_SUCCESS,
		payload: { document: result }
	}
}
export const errorModifiedDocument = () => {
	return {
		type: TypeDeDocumentActionsEnum.PUT_DOCUMENT_ERROR
	}
}

/** Tasks */
const client = new TypeDeDocumentClient('', axiosInstance);

//GET
export const fetchTypeDeDocuments = (dispatch: any) => {
	return () => {
		dispatch(requestTypeDeDocuments());
		client.getTypesDeDocument()
			.then((response: TypeDeDocumentWithCategoriesDto[]) => dispatch(getTypeDeDocuments(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les types de document");
		})
	}
}

//DELETE
export const deleteTypeDeDocument = (
	dispatch: any,
	id: string
) => {
	return () => {
		dispatch(sendDeleteTypeDeDocument());
		client.deleteTypeDeDocument(id)
			.then(dispatch(deletedTypeDeDocument(id)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer le document");
				dispatch(errorDeletedTypeDeDocument());
		})
	}
}

//POST
export const saveTypeDeDocument = (
	dispatch: any,
	document: CreateOrUpdateTypeDeDocumentDto
) => {
	return () => {
		dispatch(sendDocument());
		client.createTypeDeDocument(document)
			.then((response: any) => dispatch(savedDocument(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Une erreur est survenue pendant la sauvegarde de document");
				dispatch(errorSavedDocument());
		})
	}
}

// PUT
// TODO : Split modifDocument and modifChoix
export const modifDocument = (
	dispatch: any,
	id: string,
	document: CreateOrUpdateTypeDeDocumentDto,
	parentDocument?: CreateOrUpdateTypeDeDocumentDto // If specified, state will be updated with the parent document (containing the modified version of the choix)
) => {
	return () => {
		dispatch(sendModifDocument());
		client.updateTypeDeDocument(id, document)
			.then((response: any) => dispatch(modifiedDocument(parentDocument ? parentDocument : response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de mettre à jour le document");
				dispatch(errorModifiedDocument());
			})
	}
}