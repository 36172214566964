import { ModeleMessageDto } from '../../../services/generated/BackOffice-api';

export interface TemplateState {
	templates?: ModeleMessageDto[];
	template?: ModeleMessageDto;
	id?: string;
	templateIsLoading: boolean;
	templateDeleteIsLoading: boolean;

}

export const initialTemplateState: TemplateState = {
	templates: [],
	template: { nom: "" },
	id: "",
	templateIsLoading: false,
	templateDeleteIsLoading: false
};