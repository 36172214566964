import { Action } from 'redux';
import { CategorieDossierState } from '../states/categorieDossierState';
import { CategorieDossierClient, CategorieDossierDto } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum CategorieDossierActionsEnum {
	GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST",
	GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS",
}

export interface DispatchCategorieDossierAction extends Action<CategorieDossierActionsEnum> {
	payload: CategorieDossierState;
}

// get all criteres
export const requestCategories = () => {
	return {
		type: CategorieDossierActionsEnum.GET_CATEGORIES_REQUEST
	}
}

export const getCategories = (result: any) => {
	return {
		type: CategorieDossierActionsEnum.GET_CATEGORIES_SUCCESS,
		payload: { categories: result }
	}
}

/** Tasks */
const categorieClient = new CategorieDossierClient('', axiosInstance);



//get critères
export const fetchCategoriesDossier = (
	dispatch: any,
) => {

	return () => {
		dispatch(requestCategories());
		return categorieClient.getCategoriesDossier()
			.then((response: CategorieDossierDto[]) => dispatch(getCategories(response)))
			.catch((error) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les catégories de dossier");
		})
	}
}
