import React from 'react';
import Modal from 'react-modal';
import { modalSmallerCustomStyles } from "../../constants/config.constant";
import FormButton from 'adel-shared/dist/components/basics/FormButton';

interface ModalAttentionModificationEnCoursProps {
    isOpen: boolean;
    close: () => void;
    onValidate: () => void;
}

const ModalAttentionModificationEnCours: React.SFC<ModalAttentionModificationEnCoursProps> = ({
    isOpen,
    close,
    onValidate
}) => {

    return (<Modal
        isOpen={isOpen}
        style={modalSmallerCustomStyles}
    >
        <div className="modal__header">
            <h3>Attention</h3>
        </div>
        <div className="modal__content">
            <h4>Vous avez effectué des changements.</h4>
            <p>Êtes-vous sûr de vouloir poursuivre sans enregistrer ?</p>
        </div>
        <div className="modal__footer">
            <FormButton
                type="button"
                value="Annuler"
                onClick={() => close()}
            />
            <FormButton
                type="submit"
                value="Ignorer"
                onClick={() => onValidate()}
            />
        </div>
    </Modal>);
}

export default ModalAttentionModificationEnCours;