import { Reducer } from "redux";
import { GenerationSpedidamState, initialGenerationDossier } from "../states/generationSpedidamState";
import { DispatchGenerationSpedidamAction, GenerationSpedidamActionsEnum } from "../actions/generationSpedidiamAction";

export const generationSpedidamReducer: Reducer<Partial<GenerationSpedidamState>, DispatchGenerationSpedidamAction> = (state = initialGenerationDossier, action) => {
	switch (action.type) {
		case GenerationSpedidamActionsEnum.GET_GENERATION_SPEDIDAM_REQUEST:
			return {
				...state,
				generationIsLoading: true
			};
		case GenerationSpedidamActionsEnum.GET_GENERATION_SPEDIDAM_SUCCESS:
			return {
				...state,
				generations: action.payload.generations,
				generationIsLoading: false
			};	
		default:
			return state;
	}
};
