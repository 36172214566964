import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellValue, useRowSelect, useSortBy, useTable } from 'react-table';
import { DocumentWithValidationDto, TypeDeDocumentExtraInfoDto, TypeDeDocumentDto } from '../../services/generated/BackOffice-api';

interface DocumentValidationProps {
	documents: DocumentWithValidationDto[];
}

interface DocumentValidationTableData {
	Type?: TypeDeDocumentDto,
	Date?: string;
	Nom?: string;
	Id?: string;
	Uri?: string;
}

const DocumentValidationLight: FunctionComponent<DocumentValidationProps> = ({
	documents
}) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<DocumentValidationTableData[]>([]);

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Type document",
						accessor: "Type",
						disableSortBy: true,
						Cell: ({value}: {value: CellValue<DocumentValidationTableData['Type']>}) => {
							if(value?.isChoix) {
								return (
									<>
										<p>{value.nom?.[i18n.language]}</p>
										{value.extraInfos && value.extraInfos.length > 0 && (
											<ul>
												{value.extraInfos.map((x: TypeDeDocumentExtraInfoDto, i: number) => (
													<li key={i}>
														{x.nom?.[i18n.language]}
													</li>
												))}
											</ul>
										)}
									</>
								);
							} else {
								return value?.nom?.[i18n.language] || t(`documents.${value?.code}`) || '';
							}
						}
					},
					{
						Header: "Date",
						accessor: "Date",
						disableSortBy: true,
						Cell: (value: any) => (
							<span> {value.value && moment(value.value).format('DD/MM/YYYY')} </span>
						)
					},
					{
						Header: "Nom",
						accessor: "Nom",
						disableSortBy: true,
						Cell: (value: any) => (
							<span className="documentsIcon">
								<a target="_blank" href={value.cell.row.values.Uri}>
									<i className="fas fa-file-upload"></i> {value.value}
								</a>
							</span>
						)
					},
					{
						Header: "Id",
						accessor: "Id"
					},
					{
						Header: "Uri",
						accessor: "Uri"
					}
				]
			}
		];
	}, []);

	useEffect(() => {
		if (documents?.length) {
			setData(documents.map(doc => ({
				Type: doc.type,
				Date: doc.extraInfo && doc.extraInfo.length > 0 && doc.extraInfo[0]?.valueType === "date" && doc.extraInfo[0]?.value,
				Nom: doc.fileName,
				Validee: doc.validee,
				Commentaires: doc.commentaires,
				Id: doc.id,
				Uri: doc.uri
			})));
		}
	}, [documents]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id", "Uri"]
			},
			manualSortBy: true
		} as any,
		useSortBy,
		useRowSelect
    );

    return (
        <>
			<Table
				className="table--documentsLight"
                data={data}
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
                styles={styleTable.onglet}
            />
        </>
    );
};

export default DocumentValidationLight;