import { Action, Dispatch } from 'redux';
import { RemunerationState } from '../states/remunerationState';
import {
	RemunerationDto,
	RemunerationClient,
	RemunerationViewModelDto,
	RemunerationDtoPage,
	StyleMusicalDto,
	Emploi,
	TypePrestation,
	RemunerationSortProperty,
	SortDirection
} from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum RemunerationActionsEnum {
	GET_REMUNERATIONS_REQUEST = "GET_REMUNERATIONS_REQUEST",
	GET_REMUNERATIONS_SUCCESS = "GET_REMUNERATIONS_SUCCESS",
	SEND_REMUNERATION_REQUEST = "SEND_REMUNERATION_REQUEST",
	SEND_REMUNERATION_SUCCESS = "SEND_REMUNERATION_SUCCESS",
	SEND_REMUNERATION_ERROR = "SEND_REMUNERATION_ERROR",
	DELETE_REMUNERATION_REQUEST = "DELETE_REMUNERATION_REQUEST",
	DELETE_REMUNERATION_SUCCESS = "DELETE_REMUNERATION_SUCCESS",

	GET_VIEWMODELREMUNERATION_REQUEST = "GET_VIEWMODELREMUNERATION_REQUEST",
	GET_VIEWMODELREMUNERATION_SUCCESS = "GET_VIEWMODELREMUNERATION_SUCCESS",
	
	GET_STYLESMUSICAUX_REQUEST = "GET_STYLESMUSICAUX_REQUEST",
	GET_STYLESMUSICAUX_SUCCESS = "GET_STYLESMUSICAUX_SUCCESS"
}

export interface DispatchRemunerationAction extends Action<RemunerationActionsEnum> {
	payload: RemunerationState;
}

// get all remunerations
export const requestRemunerations = () => {
	return {
		type: RemunerationActionsEnum.GET_REMUNERATIONS_REQUEST
	}
}

export const getRemunerations = (result: RemunerationDtoPage) => {
	return {
		type: RemunerationActionsEnum.GET_REMUNERATIONS_SUCCESS,
		payload: { remunerations: result }
	}
}

// get viewmodel remuneration
export const requestViewModelRemuneration = () => {
	return {
		type: RemunerationActionsEnum.GET_VIEWMODELREMUNERATION_REQUEST
	}
}

export const getViewModelRemuneration = (result: RemunerationViewModelDto[]) => {
	return {
		type: RemunerationActionsEnum.GET_VIEWMODELREMUNERATION_SUCCESS,
		payload: { viewModelRemuneration: result }
	}
}


// Send remuneration
export const sendRemuneration = () => {
	return {
		type: RemunerationActionsEnum.SEND_REMUNERATION_REQUEST
	}
}
export const savedRemuneration = (result: RemunerationDto) => {
	return {
		type: RemunerationActionsEnum.SEND_REMUNERATION_SUCCESS,
		payload: { remuneration: result }
	}
}
export const errorSavedRemuneration = () => {
	return {
		type: RemunerationActionsEnum.SEND_REMUNERATION_ERROR
	}
}

//delete one remuneration
export const requestDeleteRemuneration = () => {
	return {
		type: RemunerationActionsEnum.DELETE_REMUNERATION_REQUEST
	}
}

export const deleteRemuneration = () => {
	return {
		type: RemunerationActionsEnum.DELETE_REMUNERATION_SUCCESS,
	}
}

// get styles musicaux
export const requestStylesMusicaux = () => {
	return {
		type: RemunerationActionsEnum.GET_STYLESMUSICAUX_REQUEST
	}
}

export const getStylesMusicaux = (result: StyleMusicalDto[]) => {
	return {
		type: RemunerationActionsEnum.GET_STYLESMUSICAUX_SUCCESS,
		payload: { stylesMusicaux: result }
	}
}


/** Tasks */
const remunerationClient = new RemunerationClient('', axiosInstance);


//get remunerations
export const fetchRemuneration = (
	dispatch: Dispatch,
	categorieDossierId: string | undefined,
	emploi: Emploi | undefined,
	typePrestation: TypePrestation | undefined,
	styleMusicalId: string | undefined,
	montantMin: number | undefined,
	montantMax: number | undefined,
	page: number | undefined,
	pageSize: number | undefined,
	sortBy?:  RemunerationSortProperty | undefined,
	sortDirection?: SortDirection | undefined,
) => {
	dispatch(requestRemunerations());
	return remunerationClient.getRemunerations(
		categorieDossierId,
		styleMusicalId,
		emploi,
		typePrestation,
		montantMin,
		montantMax,
		sortBy,
		sortDirection,
		page,
		pageSize
	).then(response => {
		dispatch(getRemunerations(response));
		return response;
	}).catch(error => {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les rémunérations");
		
		throw error;
	})
}

//save remunerations 
export const saveRemuneration = (
	dispatch: Dispatch,
	remuneration: RemunerationDto
) => {
	return () => {
		dispatch(sendRemuneration());
		return remunerationClient.createRemuneration(remuneration)
			.then(response => {
				dispatch(savedRemuneration(response));
				toast.success('La rémunération a bien été créée.');
			})
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de créer une rémunération");
			})
	}
}
//delete remuneration
export const delRemuneration = (
	dispatch: Dispatch,
	id: string
) => {
	return () => {
		dispatch(requestDeleteRemuneration());
		return remunerationClient.deleteRemuneration(id)
			.then(() => {
				dispatch(deleteRemuneration())
				toast.success('La rémunération a bien été supprimée.');
			})
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer une rémunération");
			})
	}
}

//get viewmodel
export const getViewModelforRemuneration = (
	dispatch: Dispatch,
) => {
	return () => {
		dispatch(requestViewModelRemuneration());
		return remunerationClient.getRemunerationsViewModel()
			.then(response => dispatch(getViewModelRemuneration(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer le viewmodel e rémunération");
			})
	}
}

//get styles musicaux
export const fetchStylesMusicaux = (
	dispatch: Dispatch,
) => {
	return () => {
		dispatch(requestStylesMusicaux());
		return remunerationClient.getStylesMusicaux()
			.then(response => dispatch(getStylesMusicaux(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les styles musicaux");
			})
	}
}
