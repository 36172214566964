import { Reducer } from "redux";
import { PageWarningActionsEnum, DispatchPageWarningAction } from './pageWarningAction';
import { PageWarningState, initialPageWarningState } from './pageWarningState';

export const pageWarningReducer: Reducer<Partial<PageWarningState>, DispatchPageWarningAction> = (state = initialPageWarningState, action) => {
    const messages = [...state.messages || []];
	switch (action.type) {
		case PageWarningActionsEnum.GET_MESSAGES_ALERTE_REQUEST:
			return { ...state, isLoading: true };
		case PageWarningActionsEnum.GET_MESSAGES_ALERTE_SUCCESS: {
			return {
				...state,
				messages: action.payload.messages,
				isLoading: false
			};
		}
		case PageWarningActionsEnum.GET_MESSAGES_ALERTE_ERROR:
			return { ...state, isLoading: false };
		case PageWarningActionsEnum.GET_MESSAGE_ALERTE_REQUEST:
			return { ...state, isLoading: true };
		case PageWarningActionsEnum.GET_MESSAGE_ALERTE_SUCCESS: {
			return {
				...state,
				message: action.payload.message,
				isLoading: false
			};
		}
		case PageWarningActionsEnum.GET_MESSAGE_ALERTE_ERROR:
			return { ...state, isLoading: false };
		case PageWarningActionsEnum.SEND_MESSAGE_ALERTE_REQUEST:
			return { ...state, isLoading: true }
		case PageWarningActionsEnum.SEND_MESSAGE_ALERTE_SUCCESS: {
			return { ...state, isLoading: false };
		}
		case PageWarningActionsEnum.SEND_MESSAGE_ALERTE_ERROR:
			return { ...state, isLoading: false }
		case PageWarningActionsEnum.DELETE_MESSAGE_ALERTE_REQUEST:
			return { ...state, isLoading: true }
		case PageWarningActionsEnum.DELETE_MESSAGE_ALERTE_SUCCESS:
			return { ...state, isLoading: false, 
                messages: state.messages?.filter(x => x.id !== action.payload.messageId)
			}
		case PageWarningActionsEnum.DELETE_MESSAGE_ALERTE_ERROR:
			return { ...state, isLoading: false }
		default:
			return state;
	}
};
