import { Dictionary } from "adel-shared/dist/models";
import moment from 'moment';
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { AdelDossierClient, UpdateDossierDetailsDto } from '../../../clients/AdelDossierClient';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { RolesValues } from "../../../enums/RolesValues";
import { ArtisteClient, BOCurrentUserDto, BudgetDto, DepenseDto, DescriptionProjetViewModelDto, DocumentValidationDto, DocumentWithValidationDto, DossierClient, DossierDetailsDto, DossierInfosProjetViewModelDto, FinancementDto, LieuDateDetailsDto, Role, StatutDossier } from '../../../services/generated/BackOffice-api';
import { fetchDossierAidesHistory, fetchDossierDetails, getDescriptionProjet, getInfosProjet, updateDossierDocuments } from '../../../store/dossier-store/actions/dossierDetailsActions';
import { useDossierSelector } from '../../../store/store-helpers';
import CollapsibleSection from '../../basics/CollapsibleSection';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import DocumentValidation from '../../document-validation/DocumentValidation';
import Artistes from 'adel-shared/dist/components/DossierDetails/Artistes';
import DeplacementsTable from 'adel-shared/dist/components/DossierDetails/DeplacementsTable';
import BudgetsPrevisionnels from 'adel-shared/dist/components/DossierDetails/BudgetsPrevisionnels';
import Commentaire from 'adel-shared/dist/components/DossierDetails/Commentaires';
import Contenu from 'adel-shared/dist/components/DossierDetails/Contenu';
import Informations from 'adel-shared/dist/components/DossierDetails/Informations';
import InformationsBudget from 'adel-shared/dist/components/DossierDetails/InformationsBudget';
import Programmation from 'adel-shared/dist/components/DossierDetails/Programmation';
import Structure from 'adel-shared/dist/components/DossierDetails/Structure';
import PriseEnChargeModal from "./PriseEnChargeModal";
import InformationsSupplementaires from '../../commissions/dossier-details/InformationsSupplementaires';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import ArtisteModal from 'adel-shared/dist/components/DossierDetails/ArtisteModal';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import Enseignants from 'adel-shared/dist/components/DossierDetails/Enseignants';
import Salaries from 'adel-shared/dist/components/DossierDetails/Salaries';
import ChiffresCles from 'adel-shared/dist/components/DossierDetails/ChiffresCles';
import InfosProjet from 'adel-shared/dist/components/DossierDetails/InfosProjet';
import DescriptionProjet from 'adel-shared/dist/components/DossierDetails/DescriptionProjet';
import SingleDocumentValidationUpdate from '../../document-validation/SingleDocumentValidationUpdate';
import { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import { updateDonneesBancaires, updateRIBValidation } from '../../../store/structure-store/actions/structureAction';
import Loader from 'react-loader-spinner';
import { normalizeDate } from "adel-shared/dist/utils/functions";
import { DossierActionsEnum } from "../../../store/dossier-store/actions/dossierAction";
import { axiosInstance } from "../../../custom-hooks/useAxios";
import { CategorieDossier } from '../../../enums/Dossiers';
import { AnswerType, RoleDto } from "adel-shared/dist/models/generated/BackOffice-api";
import { fetchUser, fetchUserRoles } from "../../../store/user-store/actions/userAction";


interface DossierDetailsTabProps {
    currentUser: BOCurrentUserDto;
	id: string;
}

const DossierDetailsTab: FunctionComponent<DossierDetailsTabProps> = ({
    currentUser,
	id
}) => {
    const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
    const dossierClient = new DossierClient('', axiosInstance);
    const adelDossierClient = new AdelDossierClient('', axiosInstance);
    const artisteClient = new ArtisteClient('', axiosInstance);
    const [dateProlongation, setDateProlongation] = useState<Date | undefined>();
    const [files, setFiles] = useState<File[]>([]);
    const [commentaires, setCommentaires] = useState<string>();
    const [isPriseEnChargeDossierOpen, showPriseEnChargeDossier, closePriseEnChargeDossier] = useBooleanState(false);
	const {
		dossierDetails: details = {},
		isDocValLoading,
		isDossierDetailsLoading,
		dossierAidesHistory
	} = useDossierSelector();
	const canUpdateDossierDocumentsValidation = useHasPermission("UpdateDossierDocumentsValidation");
	const [isOpenModal, openModal, closeModal] = useBooleanState(false);
    const [artisteSelected, setArtisteSelected] = useState<any>();
    const detailsFetched = useRef<boolean>(false);
    const [infosProjet, setInfosProjet] = useState<DossierInfosProjetViewModelDto>();
    const [descrProj, setDescrProj] = useState<DescriptionProjetViewModelDto>();
    const [budget, setBudget] = useState<BudgetDto>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tabIsLoading, setTabIsLoading] = useState<boolean>(false);

	// RIB
    const [ribValidation, setRibValidation] = useState<DocumentWithValidationDto>({});
    const [ribPj, setRibPj] = useState<DocumentWithFile>();

	const [isMemberCommission, setIsMemberCommission] = useState<boolean>(true);
	const [canViewRIB, setCanViewRIB] = useState<boolean>(false);

	useEffect(() => {
		if(details.commentaires)
			setCommentaires(details.commentaires)
		else
			setCommentaires('')
	}, [details.commentaires]);

	useEffect(() => {
		(async () => {
			if(id) {
				setTabIsLoading(true);
				try {
					await fetchDossierDetails(dispatch, id);
					detailsFetched.current = true;
					fetchDossierAidesHistory(dispatch, id);
					const info = await getInfosProjet(id);
					setInfosProjet(info);
					const descr = await getDescriptionProjet(id);
					setDescrProj(descr);
				} finally {
					setTabIsLoading(false);
				}
			}
		})();
	}, [id]);

	const updateUri = () => {		
		let autre = {...descrProj}	
		if (descrProj && descrProj.answers && descrProj.answers['autres-documents']) {
			//@ts-ignore
			autre['autres-documents'] = descrProj && descrProj.answers && descrProj.answers['autres-documents'].map((doc: any) => {
				let uri = details.documents?.find(item => item?.id == doc['autre-document']?.id)?.uri				
				if (uri) {
					doc['autre-document'].uri = uri
				}
				return doc
			})
			setDescrProj(autre)
		}
	}

	useEffect(()=>{
		if (!tabIsLoading) {
			updateUri()
		}
	}, [details.documents, tabIsLoading])


	useEffect(() => {
		if(details.budget && details.budgetViewModel) {
			//setBudget via viewmodel
			const arrayDepenses = details.budgetViewModel.depenses?.reduce((acc:DepenseDto[], curr) => {
				curr.depenses?.map(depViewModel => {
					details.budget?.depenses?.map(dep => {
						if(depViewModel.champ === dep.champ && dep.type === curr.type)
							acc.push(dep)
					})
				});
				return acc;
			}, []);
			const arrayFinances = details.budgetViewModel.financements?.reduce((acc:FinancementDto[], curr) => {
				curr.financements?.map(financeViewModel => {
					details.budget?.financements?.map(finance => {
						if(financeViewModel.champ === finance.champ && finance.type === curr.type)
							acc.push(finance)
					})
				});
				return acc;
			}, []);

			setBudget({
				dossierId: details.budget.dossierId,
				commentaire: details.budget.commentaire,
				documents:details.budget.documents,
				depenses: arrayDepenses,
				financements: arrayFinances
			});
		}
	}, [details.budget, details.budgetViewModel]);


    const [pieceObligatoires, setPieceObligatoires] = useState<DocumentWithValidationDto[]>([]);
    const [pieceJointe, setPieceJointe] = useState<DocumentWithValidationDto[]>([]);

	useEffect(() => {
		if (details.documents) {
			const docO = details.documents.filter(d => d.type?.isRequired) || [];
			const docJ = details.documents.filter(d => !d.type?.isRequired) || [];
			setPieceObligatoires(docO);
			setPieceJointe(docJ);
		}
	}, [details.documents]);


	const handleOpenArtisteDetails = (id:string) => {
		openartiste(id);
	}
	const openartiste = async(id:string) => {
		try {
			if(id) {
				const artiste = await artisteClient.getArtiste(id);
				setArtisteSelected(artiste);
				openModal();
			}
		} catch(error) {
			if(error.response?.exception?.message)
				toast.error(error.response.exception.message);
			else if(error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error("Une erreur est survenue lors de la récupération de l'artiste");
		}
	}



    const handleDateSelected = useCallback((date: Date) => {
        setDateProlongation(date);
    }, [setDateProlongation]);

    const handleCommentaireChange = (e:ChangeEvent<HTMLTextAreaElement>) => {
        setCommentaires(e.target.value);
    };

    const handleDocumentsValidationSubmit = async(documents: DocumentValidationDto[]) => {
		if(details.id) {
			await updateDossierDocuments(dispatch, details.id, { documents });
			toast.success(t('common.success'));
		}
	};

    const handleSubmit = useCallback(() => {
        (async() => {
            const fileDictionary: Dictionary<File> = {};

            const updateDossierDetailsDto: UpdateDossierDetailsDto = {
                dateProlongation: dateProlongation && normalizeDate(new Date(dateProlongation)),
                commentaires,
                documents: details?.documents?.map(x => ({ id: x.id })) ?? []
            };

            files.forEach(file => {
                const escapedFileName = file.name.replace(/\W/g, '');
                updateDossierDetailsDto.documents?.push({
                    partName: escapedFileName,
                });
                fileDictionary[escapedFileName] = file;
            });

			if (pieceObligatoires.length && !pieceObligatoires.find(x => x.validee)) {
				toast.error("Vous devez valider au moins une pièce obligatoire pour valider les détails du dossier") 
				return;
			}

			setIsLoading(true);
            try {

				/** Données bancaires */
				if(details.structure?.id) {
					// Update de la pièce-jointe si jamais elle a été modifiée
					if (ribPj?.file && details.structure?.donneesBancaires) {
						usePermCreateOrUpdateDonneesBancaires && await updateDonneesBancaires(dispatch, details.structure.id, {
							iban: details.structure.donneesBancaires.iban,
							bic: details.structure.donneesBancaires.bic,
							banque: details.structure.donneesBancaires.banque,
							adresse: {
								codePostal:details.structure.donneesBancaires.adresse?.codePostal,
								ville:details.structure.donneesBancaires.adresse?.ville,
								line1:details.structure.donneesBancaires.adresse?.line1,
								line2:details.structure.donneesBancaires.adresse?.line2
							},
							rib: {
								id: ribPj?.id,
								partName: ribPj?.file ? "rib-PJ" : undefined
							}
						}, ribPj.file && {"rib-PJ": ribPj.file});
					}

					// Validation du rib
					usePermUpdateRIBValidation && await updateRIBValidation(dispatch, details.structure.id, {validee: ribValidation?.validee || false, commentaires: ribValidation?.commentaires })
				}

                usePermUpdateDossierDetails && await adelDossierClient.updateDossierDetails(details.id || null, updateDossierDetailsDto, fileDictionary);
				
                toast.success(t('common.success'));
            } catch(error) {
				if(error.response?.exception?.message)
                    toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
                else
                    toast.error(t('common.errors.send'));
            }
			setIsLoading(false);
        })();
    }, [adelDossierClient, details, files, commentaires, toast, moment, ribPj, ribValidation]);

    const handlePrisEnCharge = useCallback(() => {
		(async () => {
			if(!details.id) return;
            try {
                await dossierClient.prendreEnChargeDossier(details.id);
				dispatch({
					type: DossierActionsEnum.PRENDRE_EN_CHARGE_DOSSIER_SUCCESS,
					payload: {chargeInstructionId: currentUser.id}
				});
                toast.success(t('common.success'));
            } catch (error) {
                if(error.response?.exception?.message)
                    toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
                else
                    toast.error(t('common.errors.send'));
            } finally {
                closePriseEnChargeDossier();
            }
        })()
    }, [details.id])

    const datesByPrestation = details.dates?.length ? details.dates.reduce((accumulator: LieuDateDetailsDto[][], currentVal) => {
        const date = accumulator.filter(
            x => x.some(
                y => y.typePrestation === currentVal.typePrestation
            )
        );

        if (date.length) {
            date[0].push(currentVal);
        } else {
            accumulator.push([currentVal]);
        }

        return accumulator;
    }, []) : [];

    useEffect(() => {
        setDateProlongation(details.dateProlongation);
    }, [details.dateProlongation]);

    useEffect(() => {
        if ((
                details.statut === StatutDossier.Soumis ||
                details.statut === StatutDossier.ProgrammeEnCommission ||
                details.statut === StatutDossier.PrisEnCharge
            ) &&
            currentUser.roles?.includes(RolesValues.DivCulturelleCI) &&
            !details.chargeInstructionId &&
            detailsFetched.current
        ) {
            showPriseEnChargeDossier();
        }
    }, [
		details,
		detailsFetched.current,
		currentUser.roles
	]);

	useEffect(() => {
		if(details.structure?.donneesBancaires?.rib) {
			setRibValidation(details.structure.donneesBancaires.rib);
			setRibPj({
				fileName: details.structure.donneesBancaires.rib.fileName || '',
				url: details.structure.donneesBancaires.rib.uri,
				id: details.structure.donneesBancaires.rib.id
			});
		}
	}, [details.structure?.donneesBancaires?.rib]);

	useEffect(() => {
		fetchUser(dispatch)().then((data: any) => {
			const userRoles = data?.payload?.user?.roles;
			const memberCommissionPermission = userRoles?.length == 1 && userRoles.includes("Membre de commission");
			const authRoles: string[] = ["Responsable de service","Chargé d'instruction Division Culturelle"];  
			const memberCanViewRIB = userRoles.some((role:string) => authRoles.includes(role));
			setIsMemberCommission(memberCommissionPermission);
			setCanViewRIB(memberCanViewRIB);
		})
	}, [dispatch]);

	/** Permissions */
	const usePermPrendreEnChargeDossier = useHasPermission("PrendreEnChargeDossier");
	const usePermUpdateDossierDetails = useHasPermission("UpdateDossierDetails");
	const usePermCreateOrUpdateDonneesBancaires = useHasPermission("CreateOrUpdateDonneesBancaires");
	const usePermUpdateRIBValidation = useHasPermission("UpdateRIBValidation");

    return (<>
        <div className="detailsTab dossierDetailsTab">
			{tabIsLoading
			? <div className="dossierDetails__loader">
				<Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
			</div>
			: <>
				<section className="detailsTab__section">
					<CollapsibleSection title="Structure" isOpenByDefault={true}>
						<Structure details={details} />
					</CollapsibleSection>
				</section>
				<section className="detailsTab__section">
					<CollapsibleSection title="Information dossier">
						<>
							<Informations
								details={details}
								dateProlongation={dateProlongation}
								onDateSelected={handleDateSelected}
							/>
							{infosProjet && (
								<InfosProjet
									className="dossier-details"
									showResponsableDossier={false}
									showBlockTitle={false}
									//@ts-ignore
									details={infosProjet}
									lang={i18n.language}
								/>
							)}
						</>
					</CollapsibleSection>
				</section>
				{canViewRIB &&
					<section className="detailsTab__section">
					<CollapsibleSection title="Données bancaires">
							<>
								<div className="detailsTab__row">
										<div className="detailsTab__column">
											<div className="detailsTab__content">
												<InputReadOnly
													label="IBAN"
													content={details.structure?.donneesBancaires?.iban || '-'}
												/>
											</div>
										</div>
									<div className="detailsTab__column">
										<div className="detailsTab__content">
											<InputReadOnly
												label="Domiciliation"
												content={details.structure?.donneesBancaires?.banque || '-'}
											/>
										</div>
									</div>
									<div className="detailsTab__column">
										<div className="detailsTab__content">
											<InputReadOnly
												label="BIC"
												content={details.structure?.donneesBancaires?.bic || '-'}
											/>
										</div>
									</div>
								</div>
								<div className="detailsTab__content">
									<h4>Validation document RIB</h4>
									<SingleDocumentValidationUpdate
										documentWithValidation={ribValidation}
										setDocumentWithValidation={setRibValidation}
										pj={ribPj}
										setPj={setRibPj}
									/>
								</div>
							</>
						</CollapsibleSection>
					</section>
				}
				{pieceObligatoires.length > 0 &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Pièces obligatoires">
							<div className="detailsTab__content">
								<DocumentValidation
									documents={pieceObligatoires}
									canUpdate={canUpdateDossierDocumentsValidation}
									onSubmit={handleDocumentsValidationSubmit}
									isLoading={isDocValLoading || isDossierDetailsLoading}
								/>
							</div>
						</CollapsibleSection>
					</section>
				}
				{pieceJointe.length > 0 &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Pièces jointes">
							<div className="detailsTab__content">
								<DocumentValidation
									documents={pieceJointe}
									canUpdate={canUpdateDossierDocumentsValidation}
									onSubmit={handleDocumentsValidationSubmit}
									isLoading={isDocValLoading || isDossierDetailsLoading}
								/>
							</div>
						</CollapsibleSection>
					</section>
				}

				{(details.categorie?.code != CategorieDossier.Festival && details.categorie?.code != CategorieDossier.SoireeArtistes) ?
					details?.artistes && details.artistes.length > 0 &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Artistes">
							<>
								<Artistes
									details={details}
									isBo
									/**@ts-ignore */
									isResponsableAndMembreCommission={currentUser.roles?.includes(RolesValues.RespCommission) || currentUser.roles?.includes(RolesValues.MembreCommission)}
									openModal={handleOpenArtisteDetails}
								/>
								<ArtisteModal
									artiste={artisteSelected}
									setArtiste={setArtisteSelected}
									dossierCategorie={details.categorie}
									isOpen={isOpenModal}
									close={closeModal}
									isCommissionMember={isMemberCommission}
								/>
							</>
						</CollapsibleSection>
					</section>
				:
				<>
				{details?.artistes && details.artistes.length > 0 &&

					<section className="detailsTab__section">
						<CollapsibleSection title="Artistes engagement direct">
							<>
								<Artistes
									details={details}
									isBo
									isEngagementDirect={true}
									/**@ts-ignore */
									isResponsableAndMembreCommission={currentUser.roles?.includes(RolesValues.RespCommission) || currentUser.roles?.includes(RolesValues.MembreCommission)}
									openModal={handleOpenArtisteDetails}
								/>
								<ArtisteModal
									artiste={artisteSelected}
									setArtiste={setArtisteSelected}
									dossierCategorie={details.categorie}
									isOpen={isOpenModal}
									close={closeModal}
									isCommissionMember={isMemberCommission}
								/>
							</>
						</CollapsibleSection>
					</section>
				}
				{details?.artistes && details.artistes.filter(a => !a.isEngagementDirect).length > 0 &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Artistes issus des contrats de cession">
							<>
								<Artistes
									details={details}
									isBo
									isEngagementDirect={false}
									/**@ts-ignore */
									isResponsableAndMembreCommission={currentUser.roles?.includes(RolesValues.RespCommission) || currentUser.roles?.includes(RolesValues.MembreCommission)}
									openModal={handleOpenArtisteDetails}
								/>
								<ArtisteModal
									artiste={artisteSelected}
									setArtiste={setArtisteSelected}
									dossierCategorie={details.categorie}
									isOpen={isOpenModal}
									close={closeModal}
									isCommissionMember={isMemberCommission}
								/>
							</>
						</CollapsibleSection>
					</section>
				}
				</>
				}
				{datesByPrestation?.map((x, i) => (
					<section key={i} className="detailsTab__section">
						<CollapsibleSection title={`Programmation des ${t(`structure.prestation.${x[0].typePrestation}`)}s (${x.length})`}>
							<Programmation dates={x} categorieDossier={details?.categorie} />
						</CollapsibleSection>
					</section>
				))}
				{(details?.trajets && details.trajets.length > 0) &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Déplacements">
							<DeplacementsTable trajets={details.trajets} />
						</CollapsibleSection>
					</section>
				}
				{details?.salaries && details.salaries.length > 0 &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Salarié">
							<Salaries salarie={details.salaries[0]} />
						</CollapsibleSection>
					</section>
				}
				{details?.enseignants && details.enseignants.length > 0 &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Enseignants">
							<Enseignants enseignants={details.enseignants} />
						</CollapsibleSection>
					</section>
				}
				{details?.chiffresCles &&
					<section className="detailsTab__section">
						<CollapsibleSection title="Chiffres clés">
							<ChiffresCles chiffres={details.chiffresCles} />
						</CollapsibleSection>
					</section>
				}
				<section className="detailsTab__section">
					<CollapsibleSection title="Budgets">
						<>
							<div className="detailsTab__sectionSub">
								<BudgetsPrevisionnels budget={budget} categorie={details.categorie} />
							</div>
							<div className="detailsTab__sectionSub">
								<h4>Informations complémentaires relatives au budget</h4>
								<InformationsBudget budget={details.budget} isDetailDossierTab />
							</div>
							<div>
								<h4>Autres documents complémentaires relatifs à ce budget</h4>

								<div className="detailsTab__content">
									<DocumentValidation
										documents={details.budget?.documents}
										isLoading={isDocValLoading}
										canUpdate={canUpdateDossierDocumentsValidation}
										onSubmit={handleDocumentsValidationSubmit}
									/>
								</div>
							</div>
						</>
					</CollapsibleSection>
				</section>
				<section className="detailsTab__section">
					<CollapsibleSection title="Informations supplémentaires">
						<InformationsSupplementaires
							details={dossierAidesHistory}
							dsv={details.dsv}
						/>
					</CollapsibleSection>
				</section>
				<section className="detailsTab__section">
					<CollapsibleSection title="Description du projet"><>
						<Contenu details={details} />
						{descrProj && <div className="detailsTab__content">
							{/* @ts-ignore */}
							<DescriptionProjet details={descrProj} lang={i18n.language} />
						</div>}
					</></CollapsibleSection>
				</section>
				{currentUser.roles?.includes(RolesValues.RespCommission) && (
					<section className="detailsTab__section">
						<CollapsibleSection title="Commentaire du dossier">
							<Commentaire
								commentaire={commentaires}
								onChange={handleCommentaireChange}
							/>
						</CollapsibleSection>
					</section>
				)}
			{usePermUpdateDossierDetails && <section className="detailsTab__footer">
				{isLoading
						? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
						: <FormButton
							type="submit"
							value="Valider"
							onClick={handleSubmit}
						/>
				}
				</section>}
			</>}
        </div>
		{(usePermPrendreEnChargeDossier) &&
			<PriseEnChargeModal
				isOpen={isPriseEnChargeDossierOpen}
				onCancel={closePriseEnChargeDossier}
				onValidate={handlePrisEnCharge}
			/>
		}
    </>
    );
}

export default DossierDetailsTab;
