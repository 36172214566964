import React, { ChangeEvent, useEffect, useState } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { fetchStructureNote, sendStructureNote } from '../../../store/structure-store/actions/structureNoteActions';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { StructureState } from '../../../store/structure-store/states/structureState';
import Commentaires from 'adel-shared/dist/components/DossierDetails/Commentaires';
import { useStructureSelector } from '../../../store/store-helpers';

interface StructureNoteTabProps {
	structureId: string | undefined;
}

const StructureNoteTab: React.FC<StructureNoteTabProps> = ({ structureId }) => {
	const dispatch = useDispatch();
	const {structureNote, isLoading} = useStructureSelector();
	const [note, setNote] = useState(0);
	const [commentaires, setCommentaires] = useState('');

	useEffect(() => {
		structureId && fetchStructureNote(dispatch, structureId)()
	}, [fetchStructureNote]);

	useEffect(() => {
		if (structureNote?.note === 0)
			setNote(structureNote.note)
		else if(structureNote?.note)
			setNote(structureNote.note)
		
		if (structureNote?.commentaires === "")
			setCommentaires(structureNote.commentaires)
		else if(structureNote?.commentaires)
			setCommentaires(structureNote.commentaires)
	}, [structureNote]);

	const upgradeNote = () => {
		if (note < 20) {
			setNote(note + 4)
		}
	}

	const downgradeNote = () => {
		if (note > 0) {
			setNote(note - 4)
		}
	}

	const send = () => {
		structureId && sendStructureNote(dispatch, structureId, {
			note: note,
			commentaires: commentaires
		})();
	}

	
	/** Permissions */
	const usePermUpdateStructureNote = useHasPermission("UpdateStructureNote");

	return (
		<div className="detailsTab">
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Commentaire général</h3>
				<Commentaires commentaire={commentaires} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentaires(e.target.value)} />
			</div>
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Mettez une note à la structure</h3>
				<div className="detailsTab__item">
					<div className="counter">
						<div className={note > 0 ? "counter__icon" : "counter__icon--disabled"} onClick={downgradeNote}>
							<i className="fas fa-minus-circle"></i>
						</div>
						<div className="counter__item">
							<div className="counter__input">{note}</div>
							<div className="counter__separator"></div>
							<div className="counter__scale">20</div>
						</div>
						<div className={note < 20 ? "counter__icon" : "counter__icon--disabled"} onClick={upgradeNote}>
							<i className="fas fa-plus-circle"></i>
						</div>
					</div>
				</div>
				{note < 0 && note > 20 &&
					<div className="detailsTab__error">
						<i className="far fa-exclamation-triangle"></i>
						<span>La note doit être comprise entre 0 et 20.</span>
					</div>
				}
			</div>
			<div className="detailsTab__footer">
				{isLoading
				&& <Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					></Loader>
				}
				{usePermUpdateStructureNote && <FormButton
					disabled={isLoading && note < 0 && note > 20}
					type="submit"
					value={"Valider"}
					onClick={send}
				/>}
			</div>
		</div>
	);
}

export default StructureNoteTab;