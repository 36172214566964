import React, {FunctionComponent, useEffect, useState} from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { CommissionDossierErrorDto } from '../../../services/generated/BackOffice-api';
import { useSortBy, useTable } from 'react-table';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import NoItemsTable from "../../basics/NoItemsTable";
import { getCommissionDossiersErrors, regenerateCommissionDocuments } from "../../../store/commission-store/actions/commissionActions";
import { useDispatch } from "react-redux";
import FormButton from "adel-shared/dist/components/basics/FormButton";

interface ClotureTabProps extends RouteComponentProps {
}

const ClotureTab: FunctionComponent<ClotureTabProps> = () => {
	const {commissionId} = useParams();
	const dispatch = useDispatch();
	const [dossierErrors, setDossiersErrors] = useState<CommissionDossierErrorDto[]>([]);

    useEffect(() => {
        (async() => {
            const result = await getCommissionDossiersErrors(commissionId);
            setDossiersErrors(result);
        })();
    }, []);

	const columns = React.useMemo(() => {
        return [
            {
                Header: "Table",
                columns: [
                    { Header: "Id", accessor: "id", sortType: "basic" },
                    { Header: "Numéro", accessor: "numero", sortType: "basic" },
                    { Header: "Nom", accessor: "nom", sortType: "basic"},
                    { Header: "Erreur", accessor: "error", sortType: "basic"},
                ]
            }
        ];
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
		{
			columns,
			data: dossierErrors,
			initialState: {
				hiddenColumns: ['id']
			},
		} as any,
		useSortBy
	);

    const handleRegenerateClick = () => {
        regenerateCommissionDocuments(commissionId);
	};

    return (
        <>
            {dossierErrors.length ? (
                <>
                    <Table
                        data={dossierErrors}
                        getTableProps={getTableProps}
                        getTableBodyProps={getTableBodyProps}
                        headerGroups={headerGroups}
                        rows={rows}
                        prepareRow={prepareRow}
                        styles={styleTable.page}
                    />

                    <div className="editCommission__footer">
                        <FormButton
                            type="button"
                            onClick={handleRegenerateClick}
                            value="Regénérer les dossiers"
                        />
                    </div>
                </>
            ) : (
                <NoItemsTable
                    text="Tous les dossiers ont été correctement clôturés."
                />
            )}
        </>
    );
};

export default ClotureTab;