import React, { ReactElement } from 'react';
import clsx from 'clsx';

interface PagesContainerProps {
	title: string;
	additionalHeaderElem?: ReactElement;
	children: ReactElement;
	pageClass?: string;
}

const PagesContainer: React.FunctionComponent<PagesContainerProps> = ({
	title,
	pageClass,
	children,
	additionalHeaderElem
}) => {
	return (
		<div
			className={clsx('page', {
				[`page--${pageClass}`]: pageClass
			})}
		>
			<div className="page__header">
				<h1 className="page-header__title">
					{title}
				</h1>
				{additionalHeaderElem}
			</div>
			<div className="page__content">
				{React.cloneElement(children)}
			</div>
		</div>
	);
}

export default PagesContainer;