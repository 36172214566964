import React, { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import Pagination from "adel-shared/dist/components/basics/Pagination";
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { RouteComponentProps } from '@reach/router';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { CritereDossierDto, CritereDossierLightDto } from '../../../services/generated/BackOffice-api';
import { fetchCritereDossier, fetchCriteresDossier } from '../../../store/critere-dossier-store/actions/critereDossierAction';
import { useCritereDossierSelector, useUserSelector } from '../../../store/store-helpers';
import CriteresDossierModal from './CriteresDossierModal';
import { paginationNumbers } from '../../../constants/config.constant';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface CriteresDossierProps extends RouteComponentProps {
	modalAddCritereIsOpen: boolean;
	setModalAddCritereIsOpen: Dispatch<SetStateAction<boolean>>;
	typeFilter: string;
	versionFilter: string;
}

const CriteresDossierTable: React.FC<CriteresDossierProps> = ({
	modalAddCritereIsOpen,
	setModalAddCritereIsOpen,
	typeFilter,
	versionFilter
}) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const criteresSelector = useCritereDossierSelector();

	const [sortValue, setSortValue] = useState<string>();
	const [sortDirection, setSortDirection] = useState<string>();


	/** Permissions */
	const usePermDeleteCritereDossier = useHasPermission("DeleteCritereDossier");
	const usePermGetCritereDossier = useHasPermission("GetCritereDossier");
	const usePermUpdateCritereDossier = useHasPermission("UpdateCritereDossier");


	//Table value
	const [criteres, setCriteres] = useState<CritereDossierLightDto[]>([]);

	//modales
	const [modalDeleteCritereIsOpen, setModalDeleteCritereIsOpen] = useState<boolean>(false);
	const [modalEditCritereIsOpen, setModalEditCritereIsOpen] = useState<boolean>(false);
	const [modalVisualizeCritereIsOpen, setModalVisualizeCritereIsOpen] = useState<boolean>(false);

	const [data, setData] = useState<any[]>([]);

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	//selectedCritere
	const [selectedCritere, setSelectedCritere] = useState<CritereDossierDto>({});
	const [selectedCritereContenuFR, setSelectedCritereContenuFR] = useState<any>("");
	const [selectedCritereContenuEN, setSelectedCritereContenuEN] = useState<any>("");
	const [selectedCritereDateDebut, setSelectedCritereDateDebut] = useState<Date>(new Date);

	//selector
	const userSelector = useUserSelector();

	/** Get the data for the table */
	useEffect(() => {
		const data = criteres?.map(critere => ({
			id: critere.id,
			Categorie: critere.categorie?.nom,
			document: 'criteres.pdf',
			uris: critere.uris,
			DateDebut: 'À partir du ' + formaterDate(critere.dateDebut),
			estPlanifié: !!critere.dateDebut && new Date(critere.dateDebut).getTime() > new Date().getTime(),
			actions: 'Action 1 + Action 2'
		}));
		setData(data);

	}, [criteres]);

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchCriteresDossier = useMemo(() => _.debounce((typeFilter: string, versionFilter: string, page: number, pageSize: number, sortValue: any, sortDirection: any) => {
		fetchCriteresDossier(dispatch, typeFilter === "all" ? '' : typeFilter, versionFilter, sortValue, sortDirection, page, pageSize)().then((result: any) => {
			if (result?.payload?.criteres?.number > result?.payload?.criteres?.totalPageCount)
				setPage(1);
			setCriteres(result?.payload.criteres.items);
			setHasNext(result?.payload.criteres.hasNext);
			setHasPrevious(result?.payload.criteres.hasPrevious);
			setIsFirst(result?.payload.criteres.isFirst);
			setIsLast(result?.payload.criteres.isLast);
			setTotalPageCount(result?.payload.criteres.totalPageCount);
			setTotalItemCount(result?.payload.criteres.totalItemCount);
		});
	}, 1000), []);

	const refreshCriteresDossier = () => {
		debounceFetchCriteresDossier(typeFilter, versionFilter, page, pageSize, sortValue, sortDirection)
	}

	const getInfoCritere = async (id: any) => {
		usePermGetCritereDossier && await fetchCritereDossier(dispatch, id)();
	}

	useEffect(() => {
		if(criteresSelector.critere) {
			setSelectedCritere(criteresSelector.critere);
			setSelectedCritereContenuEN(criteresSelector.critere.contenu?.en)
			setSelectedCritereContenuFR(criteresSelector.critere.contenu?.fr)
		}
	}, [criteresSelector.critere])

	const deleteCritere = (id: any) => {
		setModalDeleteCritereIsOpen(true);
		getInfoCritere(id);
	}

	const modifCritere = (id: any) => {
		setModalEditCritereIsOpen(true);
		getInfoCritere(id);
	}

	const visualizeCritere = (id: any) => {
		setModalVisualizeCritereIsOpen(true);
		getInfoCritere(id);
	}

	const copyClipboard = (id: string, data: CritereDossierLightDto[], lang: string) => {
		const rowData = data.find(p => p.id == id);
		if (rowData?.uris?.[lang]) {
			navigator.clipboard.writeText(rowData.uris[lang]).then(() => toast.success(<span className={'toast-content'}><i className="fas fa-check-circle fa-2x toast-icon"></i>{t('toast.has-been-copied')}</span>))
		}
	}


	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: t('folders-categories.folder-type'),
						accessor: "Categorie",
						sortType: "basic"

					},
					{
						Header: t('folders-categories.validity-period'),
						accessor: "DateDebut",
						sortType: "basic"

					},
					{
						Header: t('folders-categories.document'),
						accessor: "document",
						disableSortBy: true,
						Cell: (props: any) => (
							<div>
								<span>{props.cell.row.values.document} (FR)
									<i className={"far fa-copy gestionCategoriesDossier__icon"} onClick={() => copyClipboard(props.cell.row.values.id, props.data, "fr")}></i>
								</span>
								<span>{props.cell.row.values.document} (EN)
									<i className={"far fa-copy gestionCategoriesDossier__icon"} onClick={() => copyClipboard(props.cell.row.values.id, props.data, "en")}></i>
								</span>
							</div>
						)
					},
					{
						Header: "",
						accessor: "actions",
						disableSortBy: true,
						Cell: (props: any) => (
							<span>
								{usePermGetCritereDossier && <>
									<i onClick={() => visualizeCritere(props.cell.row.values.id)} className="far fa-eye gestionCategoriesDossier__icon"></i>

									{usePermUpdateCritereDossier && props.cell.row.original.estPlanifié ?
										<i onClick={() => modifCritere(props.cell.row.values.id)} className="far fa-edit gestionCategoriesDossier__icon"></i>
										: <></>
									}

									{usePermDeleteCritereDossier && props.cell.row.original.estPlanifié ?
										<i onClick={() => deleteCritere(props.cell.row.values.id)} data-tip data-for="deleteTip" className="far fa-trash-alt gestionCategoriesDossier__icon"></i>
										: <></>
									}
								</>}
							</span>
						)
					},
					{
						Header: "Id",
						accessor: "id"
					}
				]
			}
		];
	}, [usePermDeleteCritereDossier, usePermGetCritereDossier]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["id"],
				sortBy: [
					{
						id: "Categorie",
						desc: false
					}
				]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSortValue(value[0].id);
				setSortDirection("desc")
			} else {
				setSortValue(value[0].id);
				setSortDirection("asc")
			}
		} else {
			setSortValue("");
			setSortDirection("asc")
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	useEffect(() => {
		setPage(1)
	}, [pageSize, typeFilter, versionFilter])

	//get critères de dossier liste
	useEffect(() => {
		if (userSelector && userSelector.isConnected) {
			debounceFetchCriteresDossier(typeFilter, versionFilter, page, pageSize, sortValue, sortDirection)
		}
	}, [typeFilter, versionFilter, pageSize, page, sortValue, sortDirection, fetchCriteresDossier, userSelector.isConnected]);

	return (
		<>
			<div>
				<Table
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.page}
				/>

				<Pagination
					hasNext={hasNext}
					hasPrevious={hasPrevious}
					isFirst={isFirst}
					isLast={isLast}
					totalPageCount={totalPageCount}
					totalItemCount={totalItemCount}
					page={setPage}
					pageSize={setPageSize}
					initPageSize={pageSize}
					initPage={page}
					pageNumbers={paginationNumbers}
				/>
			</div>
			<CriteresDossierModal
				modalAddCritereIsOpen={modalAddCritereIsOpen}
				setModalAddCritereIsOpen={setModalAddCritereIsOpen}
				modalDeleteCritereIsOpen={modalDeleteCritereIsOpen}
				setModalDeleteCritereIsOpen={setModalDeleteCritereIsOpen}
				modalEditCritereIsOpen={modalEditCritereIsOpen}
				setModalEditCritereIsOpen={setModalEditCritereIsOpen}
				modalVisualizeCritereIsOpen={modalVisualizeCritereIsOpen}
				setModalVisualizeCritereIsOpen={setModalVisualizeCritereIsOpen}
				selectedCritere={selectedCritere}
				setSelectedCritere={setSelectedCritere}
				selectedCritereContenuFR={selectedCritereContenuFR}
				setSelectedCritereContenuFR={setSelectedCritereContenuFR}
				selectedCritereContenuEN={selectedCritereContenuEN}
				setSelectedCritereContenuEN={setSelectedCritereContenuEN}
				criteres={criteres}
				setCriteres={setCriteres}
				refreshCriteresDossier={refreshCriteresDossier}
			/>
		</>
	);
}

export default CriteresDossierTable;