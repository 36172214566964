import { RemunerationDto, RemunerationViewModelDto, StyleMusicalDto } from '../../../services/generated/BackOffice-api';

export interface RemunerationState {
	remunerationsIsLoading: boolean;
	remunerationIsLoading: boolean;
	viewModelRemunerationIsLoading: boolean;
	remunerations?: RemunerationDto[];
	remuneration?: RemunerationDto;
	newRemuneration?: RemunerationDto;
	viewModelRemuneration: RemunerationViewModelDto[];
	stylesMusicaux?: StyleMusicalDto[];
}

export const initialRemuneration: RemunerationState = {
	remunerationsIsLoading: false,
	remunerationIsLoading: false,
	viewModelRemunerationIsLoading: false,
	remunerations: [],
	viewModelRemuneration: [],
	remuneration: {},
	newRemuneration: {},
	stylesMusicaux: []
};