import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams } from '@reach/router';
import { useDossierSelector } from '../../store/store-helpers';
import NoItemsTable from '../basics/NoItemsTable';
import { fetchDossiers } from '../../store/dossier-store/actions/dossierAction';
import DossiersTable from '../dossiers/DossiersTable';
import { paginationNumbers } from '../../constants/config.constant';

interface StructureDossiersListeProps extends RouteComponentProps {
}

const StructureDossiersListe: React.FunctionComponent<StructureDossiersListeProps> = () => {
	const location = useParams();
	const dispatch = useDispatch();
	const {isLoading, listeDossiers} = useDossierSelector();
	const id = location.structureId;

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [sort, setSort] = useState<string>("");

	const debounceFetchDossiers = (page: number, pageSize: number, sort: string) => {
		fetchDossiers(dispatch, false, `StructureId==${id}`, page, pageSize, sort);
	};
	
	/** Get the dossiers list */
	useEffect(() => {
		debounceFetchDossiers(page, pageSize, sort)
	}, [page, pageSize, sort ]);

	return (
		<div className="dossiers structureDossiersListe">
			<div className="commission__header"></div>
			<div className="commission__content">
				<div className="commission__contentHeader">
					<h1 className="commission__contentHeaderTitle">Liste des dossiers de la structure</h1>
				</div>
				<div className="listeDossiers__table">
					{isLoading && <div className="loaderBlock"> <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader> </div> }
					{listeDossiers?.items && listeDossiers.items.length > 0 ? (
						<>
							<DossiersTable
								listeDossiers={listeDossiers}
								setSort={setSort}
								setPage={setPage}
								setPageSize={setPageSize}
								pageSize={pageSize}
								page={page}
								paginationNumbers={paginationNumbers}
							/>
						</>
					) : (
						<NoItemsTable />
					)}
				</div>
			</div>
		</div>
	);
}

export default StructureDossiersListe;