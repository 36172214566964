import { Reducer } from "redux";
import { PageVitrineActionsEnum } from '../actions/pageVitrineAction';
import { DispatchPageVitrineAction } from '../actions/pageVitrineAction';
import { PageVitrineState, initialPageVitrineState } from '../states/pageVitrineState';

export const pageVitrineReducer: Reducer<Partial<PageVitrineState>, DispatchPageVitrineAction> = (state = initialPageVitrineState, action) => {
	switch (action.type) {
		case PageVitrineActionsEnum.GET_PAGES_REQUEST:
			return {
				...state,
				categoriesIsLoading: true
			};
		case PageVitrineActionsEnum.GET_PAGES_SUCCESS:
			return {
				...state,
				pages: action.payload.pages,
				pagesIsLoading: false
			};
		case PageVitrineActionsEnum.SEND_PAGE_REQUEST:
			return {
				...state,
				pageIsLoading: true
			}
		case PageVitrineActionsEnum.SEND_PAGE_SUCCESS: {
			return {
				...state,
				page: action.payload.page,
				pageIsLoading: false
			}
		}
		case PageVitrineActionsEnum.SEND_PAGE_ERROR: {
			return {
				...state,
				pageIsLoading: false
			}
		}
		case PageVitrineActionsEnum.PUT_PAGE_REQUEST:
			return {
				...state,
				pageIsLoading: true
			}
		case PageVitrineActionsEnum.PUT_PAGE_SUCCESS: {
			return {
				...state,
				page: action.payload.page,
				pageIsLoading: false
			}
		}
		case PageVitrineActionsEnum.DELETE_PAGE_REQUEST:
			return {
				...state,
				pageIsLoading: true
			}
		case PageVitrineActionsEnum.DELETE_PAGE_SUCCESS: {
			return {
				...state,
				pageIsLoading: false
			}
		}
		case PageVitrineActionsEnum.POST_REORDER_REQUEST:
			return {
				...state,
				pagesIsLoading: true
			}
		case PageVitrineActionsEnum.POST_REORDER_SUCCESS: {
			return {
				...state,
				pages: action.payload.pages,
				pagesIsLoading: false
			}
		}
		case PageVitrineActionsEnum.POST_ADDFILE_REQUEST:
			return {
				...state,
				fileIsLoading: true
			}
		case PageVitrineActionsEnum.POST_ADDFILE_SUCCESS: {
			return {
				...state,
				file: action.payload.file,
				fileIsLoading: false
			}
		}
		default:
			return state;
	}
};
