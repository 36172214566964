export enum CategorieDossier {
	AidePromotionImage = "PROMO",
	DeplacementInternational = "INT",
	BODramatiqueChoregraphique = "BO",
	SpectacleDramatiqueChoregraphiqueCirque = "DRAMA",
	SpectacleMusical = "MUSICAL",
	Festival = "FEST",
	MusiqueFilm = "FILM",
	Ecole = "ECOLE",
	Fortissimo = "FORTI",
	InteretGeneral = "INTGEN",
	SoireeArtistes = "SOIRART",
	AideSecretariatArtiste = "ASA",
	DiffusionLieu = "DIFFLIEU",
	AideResidences = "AIDERES",
	DiffusionAvignon = "DIFFAVIG",
	AideRemunerationArtiste = "AIDEREMU",
	AideSpecifique = "AIDESPE",
	DiffusionSpectacleVivantLabelisationReseauSPEDIDAM1 = "DIFFSPLAB1",
    DiffusionSpectacleVivantLabelisationReseauSPEDIDAM2 = "DIFFSPLAB2",
    CreationAideSalons = "CREAIDESAL",
    CreationSoutienFestival = "CREASTFEST",
    EducationArtistiqueCulturelleAideActionsEducation = "EACAAEAC",
    DiffusionSpectacleVivantRemisePrixArtistique = "DSVRPADM",
    DiffusionSpectacleVivantRemiseEmissionTeleviseeMusicale = "TVMUSIC"
}
