import { Action } from 'redux';
import { VueState } from '../states/vueState';
import { VueDto, VueClient, VueType } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum VueActionsEnum {
	GET_VUES_REQUEST = "GET_VUES_REQUEST",
	GET_VUES_SUCCESS = "GET_VUES_SUCCESS",
	GET_VUES_ERROR = "GET_VUES_ERROR",
	POST_VUE_REQUEST = "POST_VUE_REQUEST",
	POST_VUE_SUCCESS = "POST_VUE_SUCCESS",
	POST_VUE_ERROR = "POST_VUE_ERROR",
	DELETE_VUE_REQUEST = "DELETE_VUE_REQUEST",
	DELETE_VUE_SUCCESS = "DELETE_VUE_SUCCESS",
	DELETE_VUE_ERROR = "DELETE_VUE_ERROR",
}

export interface DispatchVueAction extends Action<VueActionsEnum> {
	payload: VueState;
}

// récupérer les vues
export const requestVues = () => {
	return {
		type: VueActionsEnum.GET_VUES_REQUEST,
		payload: {}
	}
}

export const getVues = (result: VueDto[]) => {
	return {
		type: VueActionsEnum.GET_VUES_SUCCESS,
		payload: result
	}
}


// ajouer une vue
export const sendVue = () => {
	return {
		type: VueActionsEnum.POST_VUE_REQUEST

	}
}

export const savedVue = (result: VueDto) => {
	return {
		type: VueActionsEnum.POST_VUE_SUCCESS,
		payload: result
	}
}

export const errorSavedVue = () => {
	return {
		type: VueActionsEnum.POST_VUE_ERROR
	}
}


// supprimer une vue
export const sendDeleteVue = () => {
	return {
		type: VueActionsEnum.DELETE_VUE_REQUEST
	}
}

export const deletedVue = (vueId: string) => {
	return {
		type: VueActionsEnum.DELETE_VUE_SUCCESS,
		payload: vueId
	}
}

export const errorDeletedVue = () => {
	return {
		type: VueActionsEnum.DELETE_VUE_ERROR
	}
}



/** Task */
const vueClient = new VueClient('', axiosInstance);

// récupérer les vues
export const fetchVues = (
	dispatch: any,
	type: VueType
) => {

	return () => {
		dispatch(requestVues());
		return vueClient.getVues(type)
			.then((response: VueDto[]) => dispatch(getVues(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les vues");
		})
	}
}

// ajouter une vue
export const saveVue = (
	dispatch: any,
	vue: VueDto
) => {

	return () => {
		dispatch(sendVue());
		return vueClient.createVue(vue)
			.then((response: VueDto) => dispatch(savedVue(response)))
			.catch((error: any) => {
				dispatch(errorSavedVue());
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de créer une vue");
		})
	}
}

// supprimer une vue
export const deleteVue = (
	dispatch: any,
	vueId: string
) => {

	return () => {
		dispatch(sendDeleteVue());
		return vueClient.deleteVue(vueId)
			.then(dispatch(deletedVue(vueId)))
			.catch((error: any) => {
				dispatch(errorDeletedVue());
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer une vue");
		})
	}
}

