import React from 'react';
import ModalValidateCancel from '../../basics/ModalValidateCancel';

interface ModalDeleteChoixProps {
    isOpen: boolean;
    onCancel: () => void;
    onValidate: () => void;
    isLoading?: boolean;
}

const ModalDeleteChoix: React.SFC<ModalDeleteChoixProps> = ({
    isOpen,
    onCancel,
    onValidate,
    isLoading
}) => {
    return (<ModalValidateCancel
        isOpen={isOpen}
        onCancel={onCancel}
        onValidate={onValidate}
        isLoading={isLoading}
        title={"Supprimer le choix selectionné"}
    >
        <div className="modal__item">
            <p>Voulez vous vraiment supprimer le choix sélectionné ?</p>
        </div>
    </ModalValidateCancel>);
}

export default ModalDeleteChoix;