import { Link, RouteComponentProps, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import PagesContainer from '../../../containers/PagesContainer';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import {
	fetchCommission,
	fetchCommissionEnveloppesBudgetaires,
} from '../../../store/commission-store/actions/commissionActions';
import { useDispatch } from 'react-redux';
import { useCommissionSelector } from '../../../store/store-helpers';
import useHasPermission from '../../../custom-hooks/useHasPermission';

import InformationsTab from './InformationsTab';
import BudgetsTab from './BudgetsTab';
import CommissionTab from './CommissionTab';
import SessionCATab from './SessionCATab';
import MembresTab from './MembresTab';
import ClotureTab from './ClotureTab';

interface EditCommissionProps extends RouteComponentProps {
}

const EditCommission: React.FunctionComponent<EditCommissionProps> = () => {
	const {commissionId, locationTab} = useParams();
	const dispatch = useDispatch();
	const {commission = {}} = useCommissionSelector();
	const [tabIndex, setTabIndex] = useState<number>(0);

	useEffect(() => {
		(async() => {
			fetchCommission(dispatch, commissionId);
			getBudget();
		})();

		switch(locationTab) {
			case "informations":
				setTabIndex(0); break;
			case "membres":
				setTabIndex(1); break;
			case "enveloppesbudgetaires":
				setTabIndex(2); break;
			case "commission":
				setTabIndex(3); break;
			case "sessionca":
				setTabIndex(4); break;
			case "cloture":
				setTabIndex(5); break;
		}
	}, []);

	const getBudget = async () => {
		await fetchCommissionEnveloppesBudgetaires(dispatch, commissionId);
	}

	/** Permissions */
	const usePermGetCommissionEnveloppesBudgetaires = useHasPermission("GetCommissionEnveloppesBudgetaires");
	const usePermGetCommissionMembers = useHasPermission("GetCommissionMembers");
	const usePermUpdateCommission = useHasPermission("UpdateCommission");
	const usePermUpdateCommissionCAContent = useHasPermission("UpdateCommissionCAContent");
	const usePermUpdateCommissionContent = useHasPermission("UpdateCommissionContent");
	const usePermGetCommissionDossiersErrors = useHasPermission("GetCommissionDossiersGenerationErrors");

	return(
		<PagesContainer title={commission.nom || ""} pageClass="editCommission">
			<Tabs className="react-tabs--page" selectedIndex={tabIndex} onSelect={setTabIndex}>
				<div className="tabs-container editCommissionTabs">
					<TabList>
						{usePermUpdateCommission && (
							<Tab>
								<Link to={`/Commission/Edit/${commissionId}/informations`}>
									Informations
								</Link>
							</Tab>
						)}
						{usePermGetCommissionMembers && (
							<Tab>
								<Link to={`/Commission/Edit/${commissionId}/membres`}>
									Membres
								</Link>
							</Tab>
						)}
						{usePermGetCommissionEnveloppesBudgetaires && (
							<Tab>
								<Link to={`/Commission/Edit/${commissionId}/enveloppesbudgetaires`}>
									Enveloppes budgétaires
								</Link>
							</Tab>
						)}
						{usePermUpdateCommissionContent && (
							<Tab>
								<Link to={`/Commission/Edit/${commissionId}/commission`}>
									Commission
								</Link>
							</Tab>
						)}
						{/* {usePermUpdateCommissionCAContent && (
							<Tab>
								<Link to={`/Commission/Edit/${commissionId}/sessionca`}>
									Session CA
								</Link>
							</Tab>
						)} */}
						{usePermGetCommissionDossiersErrors && (
							<Tab>
								<Link to={`/Commission/Edit/${commissionId}/cloture`}>
									Clôture
								</Link>
							</Tab>
						)}
					</TabList>
				</div>

				<div className="editCommission__content">
					{usePermUpdateCommission && (
						<TabPanel>
							<InformationsTab
								path="informations"
							/>
						</TabPanel>
					)}
					{usePermGetCommissionMembers && (
						<TabPanel>
							<MembresTab path="membres" />
						</TabPanel>
					)}
					{usePermGetCommissionEnveloppesBudgetaires && (
						<TabPanel>
							<BudgetsTab
								path="enveloppesbudgetaires"
								commission={commission}
								getBudget={getBudget}
							/>
						</TabPanel>
					)}
					{usePermUpdateCommissionContent && (
						<TabPanel>
							<CommissionTab path="commission" />
						</TabPanel>
					)}
					{usePermUpdateCommissionContent && (
						<TabPanel>
							<SessionCATab path="sessionca" />
						</TabPanel>
					)}
					{usePermGetCommissionDossiersErrors && (
						<TabPanel>
							<ClotureTab path="cloture" />
						</TabPanel>
					)}
				</div>
			</Tabs>
		</PagesContainer>
	);
}

export default EditCommission;