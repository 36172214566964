import { Reducer } from "redux";
import { RemunerationState, initialRemuneration } from "../states/remunerationState";
import { DispatchRemunerationAction, RemunerationActionsEnum } from "../actions/remunerationAction";

export const remunerationReducer: Reducer<Partial<RemunerationState>, DispatchRemunerationAction> = (state = initialRemuneration, action) => {
	switch (action.type) {
		case RemunerationActionsEnum.GET_REMUNERATIONS_REQUEST:
			return {
				...state,
				remunerationsIsLoading: true
			};
		case RemunerationActionsEnum.GET_REMUNERATIONS_SUCCESS:
			return {
				...state,
				remunerations: action.payload.remunerations,
				remunerationsIsLoading: false
			};

		case RemunerationActionsEnum.SEND_REMUNERATION_REQUEST:
			return {
				...state,
				remunerationIsLoading: true
			}
		case RemunerationActionsEnum.SEND_REMUNERATION_SUCCESS: {
			return {
				...state,
				remuneration: action.payload.remuneration,
				remunerationIsLoading: false
			}
		}

		case RemunerationActionsEnum.GET_VIEWMODELREMUNERATION_REQUEST:
			return {
				...state,
				viewModelRemunerationIsLoading: true
			};
		case RemunerationActionsEnum.GET_VIEWMODELREMUNERATION_SUCCESS:
			return {
				...state,
				viewModelRemuneration: action.payload.viewModelRemuneration,
				viewModelRemunerationIsLoading: false
			};

		case RemunerationActionsEnum.GET_STYLESMUSICAUX_SUCCESS:
			return {
				...state,
				stylesMusicaux: action.payload.stylesMusicaux
			};
		default:
			return state;
	}
};
