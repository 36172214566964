import React, { useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { VersementArtisteDto, DemandeVersementMasseSalarialeDto } from '../../../services/generated/BackOffice-api';
import { getDemandeVersementMasseSalariale, getVersementDetails, prendreEnChargeDossierVersement, updateDsvVersement, updateVersementStatut } from '../../../store/dossier-store/actions/dossierVersementActions';
import { useDossierDispatcher, useDossierSelector, useUserSelector } from '../../../store/store-helpers';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { VersementInformations, View } from './VersementTab';
import VersementMasseSalariale from './details-versement/VersementMasseSalariale';
import ModalInstructions from './details-versement/ModalInstructions';
import VersementStatut from './details-versement/VersementStatut';
import VersementValidation from './details-versement/VersementValidation';
import VersementDocuments from './details-versement/VersementDocuments';
import VersementInfos from './details-versement/VersementInfos';
import VersementInfosBancaires from './details-versement/VersementInfosBancaires';
import { useForm } from 'react-hook-form';
import { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import { updateDonneesBancaires, updateRIBValidation } from '../../../store/structure-store/actions/structureAction';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { DocumentWithValidationDto, Role } from 'adel-shared/dist/models/generated/BackOffice-api';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { CategorieDossier } from '../../../enums/Dossiers';
import { RolesValues } from '../../../enums/RolesValues';
export interface ContratsCachetsActionPayload {
	artisteId?: string;
	artisteDocument?: {
		type: 'bulletin' | 'contrat',
		file?: VersementArtisteDto['bulletin'] | VersementArtisteDto['contrat'];
	};
}

interface DetailsVersementProps {
	versementInformations?: VersementInformations;
	setCurrentView: (value: View) => void;
	setVersementInformations: (value: VersementInformations | undefined) => void;
	structureId?: string;
}

const DetailsVersement: React.FunctionComponent<DetailsVersementProps> = ({
	versementInformations,
	setCurrentView,
	structureId,
	setVersementInformations
}) => {
	const form = useForm();
	const { user = {} } = useUserSelector();
	const dossierSelector = useDossierSelector();
	const isLoading = dossierSelector.isAgreementLoading;

	const [masseSalariale, setMasseSalariale] = useState<DemandeVersementMasseSalarialeDto>({});
	const [isModalInstructionOpen, openModalInstruction, closeModalInstruction] = useBooleanState(false);
	const [minoration, setMinoration] = useState<boolean>(false);

	const [ribValidation, setRibValidation] = useState<DocumentWithValidationDto>({});
	const [ribPJ, setRibPJ] = useState<DocumentWithFile>();
	const [showDSV, setShowDSV] = useState<boolean>(true);

	const dispatch = useDossierDispatcher();

	let isCIDSV = user.roles && user.roles?.includes(RolesValues.DSVCI) && user.roles?.length === 1

	// (à remettre en cas d'activation du modification du RIB)
	// const usePermCreateOrUpdateDonneesBancaires = useHasPermission("CreateOrUpdateDonneesBancaires");
	const usePermUpdateRIBValidation = useHasPermission("UpdateRIBValidation");
	const useValidateVersement = useHasPermission("UpdateDSVInstructionVersement");

	useEffect(() => {
		(async () => {
			if (versementInformations?.versementId) {
				try {
					await getVersementDetails(dispatch, versementInformations.versementId);
					await getDemandeVersementMasseSalariale(dispatch, versementInformations.versementId);
				} catch (error) {
					toast.error("Une erreur est survenue lors de la récupération des infos du versement")
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (!isCIDSV){
				setShowDSV(false)
			}
	},[]);

	useEffect(() => {
		if (dossierSelector.masseSalarialeVersement) {
			setMasseSalariale(dossierSelector.masseSalarialeVersement);
		}
	}, [dossierSelector.masseSalarialeVersement]);

	useEffect(() => {
		if (dossierSelector?.detailsVersement?.donneesBancaires?.rib)
			setRibValidation(dossierSelector.detailsVersement.donneesBancaires.rib)
		if (dossierSelector?.detailsVersement?.donneesBancaires) {
			form.setValue("iban", dossierSelector.detailsVersement.donneesBancaires.iban);
			form.setValue("domiciliation", dossierSelector.detailsVersement.donneesBancaires.banque);
			form.setValue("bic", dossierSelector.detailsVersement.donneesBancaires.bic);
		}
	}, [dossierSelector?.detailsVersement?.donneesBancaires]);

	useEffect(() => {
		if (dossierSelector.detailsVersement?.statutVersement) {
			form.setValue("versementStatut", dossierSelector.detailsVersement.statutVersement);
		}
	}, [dossierSelector.detailsVersement?.statutVersement]);

	useEffect(() => {
		if (dossierSelector.detailsVersement?.minoration) {
			setMinoration(dossierSelector.detailsVersement.minoration);
		}
	}, [dossierSelector.detailsVersement?.minoration]);

	useEffect(() => {
		if (dossierSelector?.dossierDetails?.structure?.signatureALD) {
			form.setValue("aldSigne", dossierSelector.dossierDetails?.structure?.signatureALD);

		}
	}, [dossierSelector?.dossierDetails?.structure?.signatureALD]);

	useEffect(() => {
		if (versementInformations?.necessiteInstruction)
			openModalInstruction();
	}, [versementInformations]);

	const handlePrisEnCharge = useCallback(() => {
		(async () => {
			versementInformations && prendreEnChargeDossierVersement(dispatch, versementInformations.originalDossierId);
			closeModalInstruction();
		})()
	}, [dossierSelector?.detailsVersement?.id])

	const send = async () => {
		const isValid = await form.triggerValidation();

		if (!isValid) {
			toast.error('Erreur dans le formulaire');
			return;
		}

		if (!ribPJ) {
			toast.error('Vous devez spécifier la pièce-jointe du RIB');
			return;
		}

		const { versementStatut} = form.getValues();

		/** Données bancaires (à remettre en cas d'activation du modification du RIB) */
		// const { iban, domiciliation, bic } = form.getValues();
		
		const versementId = versementInformations?.versementId;
		const versementDossierId = versementInformations?.dossierId;


		if (versementId && structureId && versementDossierId) {
			/** Données bancaires (à remettre en cas d'activation du modification du RIB) */
			// usePermCreateOrUpdateDonneesBancaires && await updateDonneesBancaires(dispatch, structureId, {
			// 	iban,
			// 	bic,
			// 	banque: domiciliation,
			// 	adresse: {
			// 		codePostal: dossierSelector?.detailsVersement?.donneesBancaires?.adresse?.codePostal,
			// 		ville: dossierSelector?.detailsVersement?.donneesBancaires?.adresse?.ville,
			// 		line1: dossierSelector?.detailsVersement?.donneesBancaires?.adresse?.line1,
			// 		line2: dossierSelector?.detailsVersement?.donneesBancaires?.adresse?.line2
			// 	},
			// 	rib: {
			// 		id: ribPJ?.id,
			// 		partName: ribPJ?.file ? "rib-PJ" : undefined
			// 	}
			// }, ribPJ?.file && { "rib-PJ": ribPJ.file });

			/** Validation données bancaires */
			if (dossierSelector.dossierDetails?.structure?.id)
				usePermUpdateRIBValidation && await updateRIBValidation(dispatch, dossierSelector.dossierDetails.structure.id, { validee: ribValidation?.validee || false, commentaires: ribValidation?.commentaires })

			/** Statut */
			await updateVersementStatut(versementId, { statut: versementStatut });

			toast.success('L\'instruction du versement a été faite avec succès.');
		}
	}

	const goBack = () => {
		setCurrentView(View.List);
		setVersementInformations(undefined);
	}

	return (
		<div className="versementTab detailsTab">
			{dossierSelector?.isDossierDetailsLoading ?
				<div className="dossierDetails__loader">
					<Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
				</div> : <><div className="detailsTab__section">
					<div className="navigationFil">
						<span className="navigationFil__item" onClick={goBack}>
							<i className="fas fa-chevron-left"></i> Retour
						</span>
					</div>
					<VersementInfos
						details={dossierSelector?.detailsVersement}
					/>
					<VersementInfosBancaires
						form={form}
						donneesBancaires={dossierSelector?.detailsVersement?.donneesBancaires}
						setRibPJ={setRibPJ}
						ribValidation={ribValidation}
						setRibValidation={setRibValidation}
						ribPJ={ribPJ}
					/>
					<VersementDocuments
						details={dossierSelector?.detailsVersement}
						setMinoration={setMinoration}
					/>
					{versementInformations?.dossierId &&
						<VersementMasseSalariale
							versementInfos={versementInformations}
							dossierSelector={dossierSelector}
							details={dossierSelector?.detailsVersement}
							masseSalariale={masseSalariale}
							setMasseSalariale={setMasseSalariale}
							isDisabled={isCIDSV}
						/>
					}
						<VersementValidation
						versementInfos={versementInformations}
						details={dossierSelector?.detailsVersement}
						/>
						<VersementStatut
						minoration={minoration}
						form={form}
						isDisabled={isCIDSV}
						/>
				</div>

					<ModalInstructions
						isOpen={isModalInstructionOpen}
						onCancel={closeModalInstruction}
						onValidate={handlePrisEnCharge}
					/>

					<div className="detailsTab__footer">
						{isLoading ? (
							<Loader
								type="TailSpin"
								width={35}
								height={35}
								color="#d93943"
							/>
						) : (
							<FormButton
								disabled={isLoading || isCIDSV}
								type="submit"
								value="Valider"
								onClick={send}
							/>
						)}
					</div></>}
		</div>
	);
}

export default DetailsVersement;