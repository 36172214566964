import React from 'react';

import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';

import { VersionsCriteresDossier } from '../../../enums/VersionsCriteresDossier';
import { useTranslation } from 'react-i18next';

interface FiltersProps {
	setType: (value: string) => void;
	setVersion: (value: string) => void;
	typeCritere: string;
	versionCritere: string;
	typeOptions: AdelOption<string>[];
}

const CriteresFilters: React.SFC<FiltersProps> = ({
	setType,
	setVersion,
	typeCritere,
	versionCritere,
	typeOptions

}) => {

	const { t } = useTranslation();

	/** Types de dossier */
	const versionOptions = () => {
		let allOptions: AdelOption<string>[] = [];

		for (let version in VersionsCriteresDossier) {
			const versionValue = VersionsCriteresDossier[version as keyof typeof VersionsCriteresDossier];
			if (versionValue !== VersionsCriteresDossier.None) {
				allOptions.push(
					{
						value: versionValue,
						label: t(`structure.version-critere.${versionValue}`)
					}
				)
			}
		}
		return allOptions;
	};

	const onChangeType = (elem: any) => {
		if (elem !== "all") {
			setType(elem);
		} else {
			setType("all");
		}
	}

	const onChangeVersion = (elem: any) => {
		if (elem !== "all") {
			setVersion(elem);
		} else {
			setVersion("all");
		}
	}

	return (
		<div className="filtres__item">
			<label>Recherches :</label>
			<div className="filtres__search">
				<InputSelect
					name="Type de dossier"
					classname={`filtreItem__inputSelect ${typeCritere !== "all" && "filtreItem__inputSelect--selected"}`}
					options={typeOptions}
					onChange={(elem) => {
						onChangeType(elem)
					}}
					value={typeCritere}
				/>
				<InputSelect
					name="Version"
					classname={`filtreItem__inputSelect ${versionCritere !== "all" && "filtreItem__inputSelect--selected"}`}
					options={versionOptions()}
					onChange={(elem) => {
						onChangeVersion(elem)
					}}
					value={versionCritere}
				/>
			</div>
		</div>
	);
}

export default CriteresFilters;
