import React, { useState, FunctionComponent } from "react";

interface CheckboxSectionProps {
	label?: string;
	value: boolean;
	addToCategoriesDossier?: any;
	id?: string;
	onChange?: (value: boolean) => void;
	name?: string;
	reference?:any;
}

const Checkbox: FunctionComponent<CheckboxSectionProps> = ({
	label,
	value,
	addToCategoriesDossier,
	id,
	onChange,
	name,
	reference
}) => {

	const [isCheck, setIsCheck] = useState<boolean>(value);

	const updateValue = () => {
		if (addToCategoriesDossier) {
			addToCategoriesDossier(id, !isCheck)
		}
		if (!onChange || onChange && onChange(!isCheck)) {
			setIsCheck(!isCheck);
		};
	}

	return (<>
		<div className="checkbox">
			<input name={name} ref={reference} type="checkbox" checked={isCheck} onChange={() => setIsCheck(!isCheck)} />
			<div className="newCheckbox" onClick={updateValue}></div>
			{label && <label>{label}</label>}
		</div>
	</>);
}

export default Checkbox;