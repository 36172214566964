import React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';

import DossierDetailsCommission from './DossierDetailsCommission';
import DetailsCommissionTDB from './DetailsCommissionTDB';

interface CommissionDetailsProps extends RouteComponentProps {
}

const CommissionDetails: React.FC<CommissionDetailsProps> = () => {
	const location = useParams();
	const id = location.commissionId;

    return (
        <div className="details">
			<DossierDetailsCommission id={id} />
			<DetailsCommissionTDB id={id} />
        </div>
    );
};

export default CommissionDetails;