import React, { useState } from 'react';
import VersementListe from './VersementsListe';
import DetailsVersement from './DetailsVersement';
import { StatutDossier, TypeVersement } from '../../../services/generated/BackOffice-api';
interface VersementTabProps {
	dossierId?: string;
	structureId?: string;
}

export enum View {
    List = "List",
    Edit = "Edit"
}

export interface VersementInformations {
	versementId: string;
	necessiteInstruction: boolean;
	dossierId: string;
	originalDossierId:string;
	type: TypeVersement;
    modeVersement?: string
}

const VersementTab: React.FunctionComponent<VersementTabProps> = ({
	dossierId,
	structureId
}) => {

	/** RENDER VIEW */
    const [currentView, setCurrentView] = useState<View>(View.List);
    const [versementInformations, setVersementInformations] = useState<VersementInformations>();


	const renderSwitchView = () => {
        switch (currentView) {
            case View.List:
                return (
                    <VersementListe
                        dossierId={dossierId}
                        setCurrentView={setCurrentView}
                        setVersementInformations={setVersementInformations}
                    />
                );
            case View.Edit:
                return (
                    <DetailsVersement
						versementInformations={versementInformations}
                        setCurrentView={setCurrentView}
                        setVersementInformations={setVersementInformations}
						structureId={structureId}
                    />
                );
        }
    }

	return (<>{renderSwitchView()}</>);
}

export default VersementTab;