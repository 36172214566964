import { VueDto } from '../../../services/generated/BackOffice-api';

export interface VueState {
	vues?: VueDto[];
	vueIsLoading: boolean;
	vueDeleteIsLoading: boolean;

}

export const initialVueState: VueState = {
	vues: [],
	vueIsLoading: false,
	vueDeleteIsLoading: false
};