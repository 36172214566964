import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Link, RouteComponentProps, useParams } from '@reach/router';
import PagesContainer from '../../containers/PagesContainer';
import { useSortBy, useTable } from "react-table";
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import { getBordereaux } from "../../store/bordereau-store/actions/bordereauActions";
import { useDispatch } from 'react-redux';
import { useBordereauSelector } from '../../store/store-helpers';
import { useTranslation } from 'react-i18next';
import NoItemsTable from '../basics/NoItemsTable';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface BordereauxProps extends RouteComponentProps {
}

const Bordereaux: FunctionComponent<BordereauxProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { bordereaux } = useBordereauSelector();
	const { bordereauType } = useParams();

    const data = useMemo(() => {
        return bordereaux?.map(x => ({
            numero: (
                <Link to={`/Comptabilite/Bordereau/${x.typeBordereau}/${x.id}`}>
                    {x.numero}
                </Link>
            ),
			dateReglement: x.dateReglement ? formaterDate(x.dateReglement) : '-',
			dateSignature: x.dateSignature ? formaterDate(x.dateSignature) : '-',
			dateTransmissionBancaire: x.dateTransmissionBancaire ? formaterDate(x.dateTransmissionBancaire) : '-',
			dateTransmissionFax: x.dateTransmissionFax ? formaterDate(x.dateTransmissionFax) : '-',
            statutBordereau: <span className={`table__statutItem table__statut--${x.statutBordereau}`}>{t(`bordereau.statut.${x.statutBordereau}`)}</span>
        }));
    }, [bordereaux]);

    /** React table config */
	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Numéro de bordereau",
						accessor: "numero",
						sortType: "basic"
					},
					{
						Header: "Date de règlement",
						accessor: "dateReglement",
						sortType: "basic"
					},
					{
						Header: "Date de signature",
						accessor: "dateSignature",
						sortType: "basic"
					},
                    {
						Header: "Date de transmission bancaire",
						accessor: "dateTransmissionBancaire",
						sortType: "basic"
					},
                    {
						Header: "Date de transmission fax",
						accessor: "dateTransmissionFax",
						sortType: "basic"
					},
                    {
						Header: "Statut bordereau",
						accessor: "statutBordereau",
						sortType: "basic"
                    },
					{
						Header: "Id",
						accessor: "id"
					}
				]
			}
		];
	}, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["id"]
			}
		} as any,
		useSortBy
	);

    useEffect(() => {
        getBordereaux(dispatch, bordereauType);
    }, [bordereauType]);

    return (
        <PagesContainer
			pageClass="bordereaux"
            title={`Comptabilité - Gestion des bordereaux de ${t(`bordereau.type.${bordereauType}`)}`}
        >
			<div className="tablePage">
				<div className="tableStyle__header"></div>
				{(bordereaux?.length && bordereaux.length > 0) ? (
                <Table
                    data={bordereaux}
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    headerGroups={headerGroups}
                    rows={rows}
                    prepareRow={prepareRow}
                    styles={styleTable.page}
                />
            ) : (
                <NoItemsTable />
            )}
			</div>
        </PagesContainer>
    );
};

export default Bordereaux;