import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import Input from 'adel-shared/dist/components/basics/Input';
import { CreateRelationDto, RelationDto } from '../../services/generated/BackOffice-api';
import { modalCustomStyles } from '../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import { addDossierRelations, deleteDossierRelations, fetchDossierRelations } from '../../store/dossier-store/actions/dossierDetailsActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { addStructureRelations, deleteStructureRelations, fetchStructureRelations } from '../../store/structure-store/actions/structureDetailsActions';
import useHasPermission from '../../custom-hooks/useHasPermission';
import { formaterDate } from 'adel-shared/dist/utils/functions';


interface RelationsProps {
	titleType: string;
	relations: RelationDto[];
	dossierId?: string;
	structureId?:string;
	validator?: any;
	dispatch:any;
	isFromDossier?:boolean;
}

const Relations: React.FunctionComponent<RelationsProps> = ({
	titleType,
	relations,
	dossierId,
	structureId,
	validator,
	dispatch,
	isFromDossier
}) => {
	const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [openModalView, setOpenModalView] = useState<boolean>(false);
	const [selectedRelation, setSelectedRelation] = useState<RelationDto>();
	const [selectedRelationDelete, setSelectedRelationDelete] = useState<string>();

	const { t } = useTranslation();
	const { getValues, triggerValidation, errors, control } = useForm<CreateRelationDto>({});

	const viewRelation = useCallback((relation: RelationDto) => {
		setSelectedRelation(relation);
		setOpenModalView(true);
	}, [setOpenModalView]);

	const addRelation = useCallback(async () => {
		let result = await triggerValidation();
		if(result) {
			if (dossierId) {
				try {
					await addDossierRelations(dispatch, dossierId, getValues());
					setOpenModalAdd(false);
					await fetchDossierRelations(dispatch, dossierId);
				} catch(error) {
					toast.error(t('common.errors.send'));
				}
			} else if (structureId) {
				try {
					await addStructureRelations(dispatch, structureId, getValues());
					setOpenModalAdd(false);
					await fetchStructureRelations(dispatch, structureId);
				} catch(error) {
					toast.error(t('common.errors.send'));
				}
			}
		}
	}, [setOpenModalAdd, structureId]);

	const openDelete = useCallback(async (id:string) => {
		if(id !== "") {
			setOpenModalDelete(true);
			setSelectedRelationDelete(id);
		}
	}, [setOpenModalDelete, setSelectedRelationDelete]);

	const deleteRelation = useCallback(async () => {
		if (dossierId && selectedRelationDelete) {
			try {
				await deleteDossierRelations(dispatch, selectedRelationDelete);
				setOpenModalDelete(false);
				setSelectedRelationDelete(undefined);
				await fetchDossierRelations(dispatch, dossierId);
			} catch(error) {
				toast.error(t('common.errors.send'));
			}
		} else if (structureId && selectedRelationDelete) {
			try {
				await deleteStructureRelations(dispatch, selectedRelationDelete);
				setOpenModalDelete(false);
				setSelectedRelationDelete(undefined);
				
				if (isFromDossier && dossierId) {
					await fetchStructureRelations(dispatch, structureId);
					await fetchDossierRelations(dispatch, dossierId);
				} else {
					await fetchStructureRelations(dispatch, structureId);
				}
			} catch(error) {
				toast.error(t('common.errors.send'));
			}
		}
	}, [setOpenModalDelete, selectedRelationDelete]);
 

	/** Permissions */
	const usePermCreateDossierRelation = useHasPermission("CreateDossierRelation");
	const usePermDeleteRelation = useHasPermission("DeleteRelation");

	return (<>
		<div className="sideInfo sideInfoRelationnel">
			<div className="sideInfo__header">
				<h3>Relationnel {titleType}</h3>
			</div>
			{relations?.length > 0 &&
				<ul>
					{relations.map((r,i) => (
						<li className="sideInfoRelationnel__item" key={`rel-${i}`} >
							<InputReadOnly
								label={`Ajouté par ${r.userFirstName} ${r.userLastName} le ${formaterDate(r.createdAt)}`}
								content={r.titre || "-"}
								theme=""
							/>
							<div className="sideInfo__buttons">
								
							{structureId && isFromDossier
								? <></>
								: <>{usePermDeleteRelation && <i className="fas fa-trash-alt" onClick={() =>{ openDelete(r.id || "") }}></i>}</>
							}
							
							<i className="far fa-eye" onClick={() => viewRelation(r)}></i>
							</div>
						</li>
					))}
				</ul>
			}
			{structureId && isFromDossier
				? <></>
				: <>{usePermCreateDossierRelation &&
					<div className="sideInfo__add" onClick={() => setOpenModalAdd(true)}>Ajouter une relation {titleType}</div>
				}</>
			}
		</div>

		{usePermCreateDossierRelation &&
			<Modal
					isOpen={openModalAdd}
					style={modalCustomStyles}
					className="normal__modal"
				>
					<div className="modal__header">
						<h3>Ajouter une relation</h3>
					</div>
					<div className="modal__content">
						<div className="modal__item">
							<Controller control={control}
								name="titre"
								as={({ onChange, value, name }) => (
									<Input
										name={name}
										type="text"
										label="Titre"
										errors={errors}
										onChange={(value:string) => {
											onChange(value);
										}}
										value={value || ''}
									/>
								)}
								rules={validator && validator["Titre"]}
							/>
						</div>
						<div className="modal__item">
							<div className="input">
								<label className="input__label">Description</label>
							</div>
							<Controller control={control}
								name="description"
								as={({ onChange, value, name }) => (
								<div className="sideInfosModal__textareaItem">
									<textarea
										name={name}
										className="sideInfosModal__textarea"
										onChange={(e) => onChange(e)}
										value={value}
									/>
									{errors &&
										<ErrorMessage errors={errors} name={name}>
											{({ message }) => <p className="input__errorMessage">{message}</p>}
										</ErrorMessage>
									}
								</div>
								)}
								rules={validator && validator["Description"]}
							/>
						</div>
					</div>
					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setOpenModalAdd(false);
							}}
						/>
							<FormButton
							type="submit"
							value="Ajouter"
							onClick={() => addRelation()}
						/>
						
					</div>
			</Modal>
		}
		
		{usePermDeleteRelation &&
			<Modal
				isOpen={openModalDelete}
				style={modalCustomStyles}
				className="normal__modal"
			>
				<div className="modal__header">
					<h3>Supprimer une relation</h3>
				</div>
				<div className="modal__content">
					<div className="modal__item">
						Voulez-vous supprimer une relation ?
					</div>
				</div>
				<div className="modal__footer">
					<FormButton
						type="button"
						value="Annuler"
						onClick={() => {
							setOpenModalDelete(false);
						}}
					/>
						<FormButton
						type="submit"
						value="Supprimer"
						onClick={() => deleteRelation()}
					/>
					
				</div>
			</Modal>
		}

		<Modal
			isOpen={openModalView}
			style={modalCustomStyles}
			className="normal__modal"
		>
			<div className="modal__header">
				<h3>Détails de la relation</h3>
			</div>
			<div className="modal__content">
				<div className="modal__item sideInfosModal__viewItem">
					<div style={{ fontWeight: "bold" }}>
					<InputReadOnly
						label="Titre" 
						content={selectedRelation?.titre || "-"}						
						/>
					</div>
					<div style={{ fontWeight: "bold" }}>
					<InputReadOnly
						label="Date"
							content={moment(selectedRelation?.createdAt).format('DD/MM/YYYY')}
						/>
					</div>
					<div style={{ whiteSpace: "pre-line" }}>
					<InputReadOnly
						label="Description"
						content={selectedRelation?.description || "-"}
						/>
					</div>
				</div>
			</div>
			<div className="modal__footer">
				<div className="sideInfo__buttons">
				{structureId && isFromDossier
								? <></>
								: <>{usePermDeleteRelation && 
							<i style={{ cursor: "pointer" }} className="fas fa-trash-alt" onClick={() => {
								openDelete(selectedRelation?.id || "");
								setOpenModalView(false); setSelectedRelation({})
							}}></i>}</>
							}
				</div>
				<FormButton
					type="submit"
					value="Fermer"
					onClick={() => {setOpenModalView(false); setSelectedRelation({}); }}
				/>
				
			</div>
		</Modal>
	</>);
}

export default Relations;
