import { Action } from 'redux';
import { GenerationSpedidamState } from '../states/generationSpedidamState';
import { GenerationSpedidamClient } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum GenerationSpedidamActionsEnum {
	GET_GENERATION_SPEDIDAM_REQUEST = "GET_GENERATION_SPEDIDAM_REQUEST",
	GET_GENERATION_SPEDIDAM_SUCCESS = "GET_GENERATION_SPEDIDAM_SUCCESS",
	DELETE_GENERATION_SPEDIDAM_SUCCESS = "DELETE_GENERATION_SPEDIDAM_SUCCESS",
}

export interface DispatchGenerationSpedidamAction extends Action<GenerationSpedidamActionsEnum> {
	payload: GenerationSpedidamState;
}

// get all criteres
export const requestGenerationsSpedidam = () => {
	return {
		type: GenerationSpedidamActionsEnum.GET_GENERATION_SPEDIDAM_REQUEST
	}
}

export const getGenerationsSpedidam = (result: any) => {
	return {
		type: GenerationSpedidamActionsEnum.GET_GENERATION_SPEDIDAM_SUCCESS,
		payload: { generations: result }
	}
}

export const deleteGenerationSpedidamSucess = () => {
	return {
		type: GenerationSpedidamActionsEnum.DELETE_GENERATION_SPEDIDAM_SUCCESS,
	}
}

/** Tasks */
const generationClient = new GenerationSpedidamClient('', axiosInstance);

//delete Generation Spedidam
export const deleteGenerationSpedidam = (
	dispatch: any,
	id: string
) => {
	return () => {
		return generationClient.deleteGenerationSpedidam(id)
			.then(() => {
				dispatch(deleteGenerationSpedidamSucess())
				toast.success("L'artiste a été été supprimée.");
			})
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer l'artiste");
			})
		}
	}	

//get critères
export const fetchGenerationsSpedidam = (
	dispatch: any,
	sortBy?: any,
	sortDirection?: any,
	page?: number,
	pageSize?: number,
) => {
	
	return () => {
		dispatch(requestGenerationsSpedidam());
		//@ts-ignore
		return generationClient.getGenerationsSpedidam(sortBy, sortDirection, page, pageSize)
			.then((response: any) => dispatch(getGenerationsSpedidam(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer le récupérer les critères de dossier");
			})
	}
}