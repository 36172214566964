import { Action, Dispatch } from 'redux';
import { CategorieFormeJuridiqueState } from '../states/categorieFormeJuridiqueState';
import { CategorieFormeJuridiqueDto, CategorieFormeJuridiqueClient, FormeJuridiqueDto, FormeJuridiqueClient, UpdateFormeJuridiqueCategorieDto } from '../../../services/generated/BackOffice-api';
import { Dictionary } from 'adel-shared/dist/models';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum CategorieFormeJuridiquerActionsEnum {
	GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST",
	GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS",
	SEND_CATEGORIES_REQUEST = "SEND_CATEGORIES_REQUEST",
	SEND_CATEGORIES_SUCCESS = "SEND_CATEGORIES_SUCCESS",
	ADD_CATEGORIE_REQUEST = "ADD_CATEGORIE_REQUEST",
	ADD_CATEGORIE_SUCCESS = "ADD_CATEGORIE_SUCCESS",
	DELETE_CATEGORIE_REQUEST = "DELETE_CATEGORIE_REQUEST",
	DELETE_CATEGORIE_SUCCESS = "DELETE_CATEGORIE_SUCCESS",
	UPDATE_CATEGORIES_TYPESDEDOCUMENTS_REQUEST = "UPDATE_CATEGORIES_TYPESDEDOCUMENTS_REQUEST",
	UPDATE_CATEGORIES_TYPESDEDOCUMENTS_SUCCESS = "UPDATE_CATEGORIES_TYPESDEDOCUMENTS_SUCCESS",
	UPDATE_CATEGORIES_TYPESDEDOCUMENTS_ERROR = "UPDATE_CATEGORIES_TYPESDEDOCUMENTS_ERROR"
}

export interface DispatchCategorieFormeJuridiqueAction extends Action<CategorieFormeJuridiquerActionsEnum> {
	payload: CategorieFormeJuridiqueState;
}

// get all categories
export const requestCategories = () => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.GET_CATEGORIES_REQUEST
	}
}

export const getCategories = (result: CategorieFormeJuridiqueDto[]) => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.GET_CATEGORIES_SUCCESS,
		payload: { categoriesFormeJuridique: result }
	}
}

// send formes juridiques (erreur de nommage...)
export const requestSendCategories = () => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.SEND_CATEGORIES_REQUEST
	}
}

export const sendCategories = (result: FormeJuridiqueDto[]) => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.SEND_CATEGORIES_SUCCESS,
		payload: { formesJuridiques: result }
	}
}

// add a category
export const requestAddCategorie = () => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.ADD_CATEGORIE_REQUEST
	}
}

export const sendAddCategorie = (result: CategorieFormeJuridiqueDto) => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.ADD_CATEGORIE_SUCCESS,
		payload: result
	}
}

// delete a categorie
export const requestDeleteCategorie = () => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.DELETE_CATEGORIE_REQUEST
	}
}

export const sendDeleteCategorie = (id: string) => {
	return {
		type: CategorieFormeJuridiquerActionsEnum.DELETE_CATEGORIE_SUCCESS,
		payload: id
	}
}



/** Tasks */
const categorieClient = new CategorieFormeJuridiqueClient('', axiosInstance);
const formeJuridiqueClient = new FormeJuridiqueClient('', axiosInstance);

export const fetchCategoriesFormeJuridique = (dispatch: any) => {
	return () => {
		dispatch(requestCategories());
		categorieClient.getCategoriesFormeJuridique()
			.then((response: CategorieFormeJuridiqueDto[]) => dispatch(getCategories(response)))
			.catch((error) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer formes juridiques");
		})
	}
}

export const sendFormesJuridiques = (dispatch: any, body: UpdateFormeJuridiqueCategorieDto[]) => {
	return () => {
		dispatch(requestSendCategories());
		formeJuridiqueClient.updateFormeJuridiqueCategorieMapping(body)
			.then((response: FormeJuridiqueDto[]) => dispatch(sendCategories(response)))
			.catch((error) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les blocages de versement");
		})
	}
}

export const sendCategorie = (dispatch: any, name: string) => {
	return () => {
		dispatch(requestAddCategorie());
		categorieClient.createCategorieFormeJuridique({ nom: name })
			.then((response: CategorieFormeJuridiqueDto) => dispatch(sendAddCategorie(response)))
			.catch((error) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de créer une catégorie");
		})
	}
}

export const deleteCategorie = (dispatch: any, id: string) => {
	return () => {
		dispatch(requestDeleteCategorie());
		categorieClient.deleteCategorieFormeJuridique(id)
			.then(dispatch(sendDeleteCategorie(id)))
			.catch((error) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer une catégorie");
		})
	}
}

export const updateCategoriesFormeJuridiqueTypesDeDocuments = async (
	dispatch: Dispatch<any>, // On est oblig� de faire un any sur le dispatch, du coup on ne peut pas savoir quel est le payload attendu suivant le type de l'action. �a me rend triste...
	categoriesWithAssociatedDocuments: Dictionary<string[]>
) => {
	try {
		dispatch({ type: CategorieFormeJuridiquerActionsEnum.UPDATE_CATEGORIES_TYPESDEDOCUMENTS_REQUEST });
		await categorieClient.updateCategoriesFormeJuridiqueTypesDeDocuments(categoriesWithAssociatedDocuments);
		dispatch({ type: CategorieFormeJuridiquerActionsEnum.UPDATE_CATEGORIES_TYPESDEDOCUMENTS_SUCCESS });
		toast.success('L\'affectation des documents a été mise à jour.');
	} catch (error) {
		dispatch({ type: CategorieFormeJuridiquerActionsEnum.UPDATE_CATEGORIES_TYPESDEDOCUMENTS_ERROR, payload: error });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les blocages de versement");

	}
}