import InputFileSingle from 'adel-shared/dist/components/basics/InputFileSingle';
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import { escapeName } from 'adel-shared/dist/utils/functions';
import clsx from 'clsx';
import { Dictionary, uniqueId } from 'lodash';
import moment from 'moment';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useRowSelect, useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { modalCustomStyles } from '../../../../constants/config.constant';
import { axiosInstance } from '../../../../custom-hooks/useAxios';
import { CategorieDossier } from '../../../../enums/Dossiers';
import {
	VersementArtisteDto,
	DemandeVersementMasseSalarialeDto,
	DocumentValidationDto,
	TypeVersement,
	VersementClient,
	CommissionDto,
	DossierClient,
	ModeVersement
} from '../../../../services/generated/BackOffice-api';
import CheckboxTable from '../../../basics/CheckboxTable';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import ModalCommentaire from '../../../document-validation/ModalCommentaire';
import ModalAddArtist from "./ModalAddArtist";
import { formatNumber } from 'adel-shared/dist/utils/functions';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { VersementInformations } from '../VersementTab';
import Loader from 'react-loader-spinner';
import { AdelVersementClient } from '../../../../clients/AdelVersementClient';
import { updateVersementArtistesValidation } from '../../../../store/versement-store/actions/versementAction';
import { getDemandeVersementMasseSalariale } from '../../../../store/dossier-store/actions/dossierVersementActions';
import { useDossierDispatcher, useDossierSelector } from '../../../../store/store-helpers';
import useHasPermission from '../../../../custom-hooks/useHasPermission';
interface MasseSalarialeTableProps {
	masseSalariale: DemandeVersementMasseSalarialeDto;
	versementInfos: VersementInformations;
	setMasseSalariale: (value: DemandeVersementMasseSalarialeDto) => void;
	isBulletin: () => boolean;
}

const MasseSalarialeTable: React.FunctionComponent<MasseSalarialeTableProps> = ({
	masseSalariale,
	versementInfos,
	setMasseSalariale,
	isBulletin
}) => {
	const { t } = useTranslation();
	const dispatch = useDossierDispatcher();
	const dossierClient = new DossierClient('', axiosInstance);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [idDoc, setIdDoc] = useState<string>();
	const [commDoc, setCommDoc] = useState<string>("");
	const tableId = useRef<string>(uniqueId());
	const [artistSelected, setArtistSelected] = useState<VersementArtisteDto>();
	const {
		dossierDetails: details = {},
	} = useDossierSelector();

	const [isModalDeleteOpen, openModalDelete, closeModalDelete] = useBooleanState(false);
	const [isModalAddOpen, openModalAdd, closeModalAdd] = useBooleanState(false);
	const [isArtistDocLoading, artistDocLoadingTrue, artistDocLoadingFalse] = useBooleanState(false);
	const [isArtistDocVaLidLoading, artistDocVaLidLoadingTrue, artistDocVaLidLoadingFalse] = useBooleanState(false);

	const [artistToDeleteId, setartistToDeleteId] = useState<string>();
	const [data, setData] = useState<any[]>([]);
	const [dossierCommission, setDossierCommission] = useState<CommissionDto>({});

	const [artistesDocumentsFiles, setArtistesDocumentsFiles] = useState<Dictionary<File>>({});
	const [documentsToSendArtistes, setDocumentsToSendArtistes] = useState<DocumentValidationDto[]>([]);

	const versementClient = new VersementClient("", axiosInstance);
	const adelVersementClient = new AdelVersementClient("", axiosInstance);

	const usePermDeleteVersementArtiste = useHasPermission("DeleteVersementArtiste");
	const usePermUpdateArtisteDocument = useHasPermission("UpdateArtisteDocument");
	const usePermUpdateVersementArtistesValidation = useHasPermission("UpdateVersementArtistesValidation");

	/** TABLE */

	const handleFileChange = (file: any, id: string, type: 'bulletin' | 'contrat' | 'bulletinsPaies') => {

		const docsToUpdate = { ...artistesDocumentsFiles };
		const artisteOuEnseignant = masseSalariale?.artistes?.find(x => x.id === id) || masseSalariale?.enseignants?.find(x => x.id === id);

		if (file) {
			docsToUpdate[escapeName(file.fileName + id)] = file.file
		} else {
			//@ts-ignore
			const partNameDoc = escapeName(artisteOuEnseignant?.[type]?.fileName + id || '');
			delete docsToUpdate[partNameDoc];
		}

		setArtistesDocumentsFiles(docsToUpdate);

		if (masseSalariale && masseSalariale.artistes && masseSalariale.artistes.length > 0) {
			let index: any = masseSalariale.artistes.indexOf(artisteOuEnseignant || {})
			if (type === 'bulletin')
				masseSalariale.artistes[index].bulletin = file;
			else if (type === 'contrat')
				masseSalariale.artistes[index].contrat = file;

			setMasseSalariale({ ...masseSalariale, artistes: masseSalariale.artistes });
		} else if (masseSalariale && masseSalariale.enseignants && masseSalariale.enseignants.length > 0) {
			let index: any = masseSalariale.enseignants.indexOf(artisteOuEnseignant || {})

			if (type === 'bulletinsPaies') {
				if (masseSalariale.enseignants[index]?.justificatifRemuneration)
					masseSalariale.enseignants[index].justificatifRemuneration = file;
				else {
					masseSalariale.enseignants[index] = {
						...masseSalariale.enseignants[index],
						justificatifRemuneration: file
					};

				}
				setMasseSalariale({ ...masseSalariale, enseignants: masseSalariale.enseignants });

			}

		}
	};

	const columns = useMemo(() => {
		return  [
			{
				Header: "Table",
				columns:details.categorie?.code !== CategorieDossier.Ecole ? [
					{ Header: "Nom", accessor: "Nom", disableSortBy: true },
					{ Header: "Prénom", accessor: "Prenom", disableSortBy: true },

						/*nooo*/{
						Header: "Emploi", accessor: "Emploi", disableSortBy: true,
						Cell: (value: any) => (<span>{t(`structure.emploi.${value.value}`)}</span>)

					}
					,
					 /*noo*/{ Header: "Dates travaillées", accessor: "Dates", disableSortBy: true }
					,
					{
						Header: "Total salaires bruts", accessor: "SalaireBrut", disableSortBy: true,
						Cell: (value: any) => (<span>{value.value} €</span>)
					},
					{
						Header: "Total chg. patronales", accessor: "ChargesPatronales", disableSortBy: true,
						Cell: (value: any) => (<span>{value.value} €</span>)
					},
					/*noo*/{
						Header: "Contrat", accessor: "Contrat", disableSortBy: true,
						Cell: (value: any) => (
							<InputFileSingle
								labelButton="Télécharger"
								onChange={(file: any) => handleFileChange(file, value.cell.row.values.Id, 'contrat')}
								isRequired={false}
								currentValue={value.cell.row.values.Contrat && {
									id: value.cell.row.values.Contrat.id,
									fileName: value.cell.row.values.Contrat.fileName,
									url: value.cell.row.values.Contrat.uri
								}}
							/>
						)
					},
					/*noo*/{
						Header: "Bulletin", accessor: "Bulletin", width: 200, disableSortBy: true,
						Cell: (value: any) => (
							<InputFileSingle
								labelButton="Télécharger"
								onChange={(file: any) => handleFileChange(file, value.cell.row.values.Id, 'bulletin')}
								isRequired={false}
								currentValue={value.cell.row.values.Bulletin && {
									id: value.cell.row.values.Bulletin.id,
									fileName: value.cell.row.values.Bulletin.fileName,
									url: value.cell.row.values.Bulletin.uri
								}}
							/>
						)
					},
					{
						Header: (
							{ getToggleAllRowsSelectedProps }: any) => {
							const props = getToggleAllRowsSelectedProps();
							return (
								<div className="table__select-all">
									<CheckboxTable
										id={`select-all-${tableId.current}`}
										indeterminate={props.indeterminate}
										onCheck={(id: string, check: boolean, e: ChangeEvent<HTMLInputElement>) => {
											props.onChange(e);
											handleCheck(id, check, true);
										}}
										defaultCheck={props.checked}
									/>
									<span>Valider PJ</span>
								</div>
							)
						},
						accessor: "Validee",
						disableSortBy: true,
						Cell: (value: any) => {
							const props = value.cell.row.getToggleRowSelectedProps();

							if (value.cell.row.values.IdDocument) {
								return (
									<CheckboxTable
										id={value.cell.row.values.IdDocument}
										onCheck={(id: string, check: boolean, e: ChangeEvent<HTMLInputElement>) => {
											props.onChange(e);
											handleCheck(id, check);
										}}
										defaultCheck={documentsToSendArtistes.find(e => e.id === value.cell.row.values.IdDocument)?.validee || props.checked}
									/>)
							} else return <></>
						}
					},
					{
						Header: "",
						accessor: "Commentaires",
						disableSortBy: true,
						Cell: (value: any) => {
							const props = documentsToSendArtistes.find(e => e.id === value.cell.row.values.IdDocument);
							if (value.cell.row.values.IdDocument) {
								return (<span
									className={clsx("commentIcon",
										{ "commentIcon--hasValue": props?.commentaires && props.commentaires !== "" }
									)}
									onClick={() => handleOpenModal(value.cell.row.values.IdDocument, value.value)}
								>
									<i
										className={clsx({
											"far": !props?.commentaires,
											"fas": props?.commentaires
										},
											"fa-message"
										)}
									></i>
								</span>)
							} else return <></>
						}
					},
					{
						Header: "",
						accessor: "Actions",
						disableSortBy: true,
						Cell: (props: any) =>
							<span>
								<i onClick={() => editArtisteAction(props.cell.row.values.Id)} className="far fa-edit gestionCategoriesDossier__icon"></i>
								{usePermDeleteVersementArtiste &&
									<i onClick={() => deleteArtisteAction(props.cell.row.values.Id)} className="far fa-trash-alt gestionCategoriesDossier__icon"></i>}
							</span>
					},
					{
						Header: "Id",
						accessor: "Id"
					},
					{
						Header: "IdDocument",
						accessor: "IdDocument"
					},
					{
						Header: "Uri",
						accessor: "Uri"
					}
				] :  [
					{ Header: "Nom", accessor: "Nom", disableSortBy: true },
					{ Header: "Prénom", accessor: "Prenom", disableSortBy: true },
					{ Header: "Type de contrat", accessor: "TypeDeContrat", disableSortBy: true },
					{
						Header: "Tarif horaire brut", accessor: "HeureBrut", disableSortBy: true,
						Cell: (value: any) => (<span>{value.value} €</span>)
					},
					{
						Header: "Total salaires bruts", accessor: "SalaireBrut", disableSortBy: true,
						Cell: (value: any) => (<span style={{float: 'left'}}>{value.value} €</span>)
					},
					{
						Header: "Total chg. patronales", accessor: "ChargesPatronales", disableSortBy: true,
						Cell: (value: any) => (<span style={{float: 'left'}}>{value.value} €</span>)
					},
					{
						Header: "Id",
						accessor: "Id"
					},
					{
						Header: "IdDocument",
						accessor: "IdDocument"
					},
					{
						Header: "Uri",
						accessor: "Uri"
					}
				]
			}
		] 
	}, [
		documentsToSendArtistes,
		masseSalariale,
		artistesDocumentsFiles,
		usePermDeleteVersementArtiste
	]);

	useEffect(() => {
		let isMounted = true;
	
		(async () => {
			const result = await dossierClient.getDossierCommission(versementInfos.originalDossierId);
			if (isMounted) {
				setDossierCommission(result);
			}
		})();
	
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let data: any = [];
		if (details.categorie?.code != CategorieDossier.Ecole && masseSalariale?.artistes || details.categorie?.code == CategorieDossier.Ecole && masseSalariale?.enseignants) {
			data = details.categorie?.code != CategorieDossier.Ecole ? masseSalariale?.artistes?.map((a: any) => ({
				Id: a.id,
				Nom: a.nom,
				Prenom: a.prenom,
				Dates: a.datesTravailles?.map((d: any) => moment(d).format("DD/MM/YYYY")).join(', '),
				Emploi: a.emploi,
				SalaireBrut: a.totalSalairesBruts ? formatNumber(a.totalSalairesBruts) : '-',
				ChargesPatronales: a.totalCharges ? formatNumber(a.totalCharges) : '-',
				Contrat: a.contrat,
				Bulletin: a.bulletin,
				Validee: a.bulletin ? a.bulletin.validee : a.contrat?.validee,
				Commentaires: isBulletin() ? a?.bulletin?.commentaires : a?.contrat?.commentaires,
				Uri: isBulletin() ? a?.bulletin?.uri : a?.contrat?.uri,
				IdDocument: isBulletin() ? a?.bulletin?.id : a?.contrat?.id
			})) : masseSalariale?.enseignants?.map((a: any) => ({
				Id: a.id,
				Nom: a.nom,
				Prenom: a.prenom,
				Dates: a.datesTravailles?.map((d: any) => moment(d).format("DD/MM/YYYY")).join(', '),
				TypeDeContrat: a.typeContrat,
				SalaireBrut: a.remunerationBruteAnnuelle ? formatNumber(a.remunerationBruteAnnuelle) : '-',
				ChargesPatronales: a.montantChargesPatronales ? formatNumber(a.montantChargesPatronales) : '-',
				HeureBrut: a.tarifHoraireBrut ? formatNumber(a.tarifHoraireBrut) : '-',
				Contrat: a.justificatifRemuneration,
				Bulletin: a.justificatifRemuneration,
				Validee: a.justificatifRemuneration?.validee || false,
				Commentaires: a.justificatifRemuneration?.commentaires || "",
				Uri: a.justificatifRemuneration?.uri || "",
				IdDocument: a.justificatifRemuneration?.id || null
			}));
			var docs: any = details.categorie?.code != CategorieDossier.Ecole ? masseSalariale?.artistes?.map((a: any) => ({
				id: a.bulletin?.id || a.contrat?.id,
				validee: a.bulletin ? a.bulletin.validee : a.contrat?.validee,
				commentaires: isBulletin() ? a?.bulletin?.commentaires : a?.contrat?.commentaires
			})) : masseSalariale?.enseignants?.map((a: any) => ({
				id: a.justificatifRemuneration?.id || null,
				validee: a.justificatifRemuneration?.validee || false,
				commentaires: a.justificatifRemuneration?.commentaires || "",
			}))
			setDocumentsToSendArtistes(docs)
		} else {
			setDocumentsToSendArtistes([]);
		}
		setData(data);
	}, [masseSalariale]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: [
					"Id",
					"Uri",
					"IdDocument",
					versementInfos.type !== TypeVersement.Acompte && "Contrat",
					versementInfos.type !== TypeVersement.Complet && versementInfos.type !== TypeVersement.Solde && "Bulletin"
				]
			},
			manualSortBy: true
		} as any,
		useSortBy,
		useRowSelect
	);

	/** Actions tableau */
	const editArtisteAction = (id: string) => {
		setArtistSelected(masseSalariale?.artistes?.find(a => a.id === id));
		openModalAdd();
	}

	const deleteArtisteAction = (id: string) => {
		setartistToDeleteId(id);
		openModalDelete();
	}
	const deleteArtist = async () => {
		if (artistToDeleteId) {
			try {
				await versementClient.deleteVersementArtiste(artistToDeleteId);
				let newMass = masseSalariale?.artistes?.filter(a => a.id !== artistToDeleteId);
				setMasseSalariale({ ...masseSalariale, artistes: newMass });
				closeModalDelete();
			} catch {
				toast.error(details.categorie?.code === CategorieDossier.Ecole ? "Erreur dans la suppression d'un enseignant" : "Erreur dans la suppression d'un artiste")
			}
		}
	}


	/** COMMNTAIRES DOCUMENT */

	const handleOpenModal = useCallback((id: string, value: string) => {
		const comm = documentsToSendArtistes.find(e => e.id === id)?.commentaires;
		setIdDoc(id);
		if (comm)
			setCommDoc(comm);
		else
			setCommDoc(value);
		setOpenModal(true);
	}, [documentsToSendArtistes, setOpenModal, setIdDoc, setCommDoc]);

	const handleComment = useCallback((id?: string, value?: string) => {
		if (documentsToSendArtistes.find(e => e.id === id)) {
			const entry = documentsToSendArtistes.find(e => e.id === id);
			let newItem: DocumentValidationDto[] = [{
				...entry, commentaires: value
			}];
			let newarray = documentsToSendArtistes.map(e => newItem.find(item => item?.id === e.id) || e);
			setDocumentsToSendArtistes(newarray);
		} else {
			setDocumentsToSendArtistes([...documentsToSendArtistes, {
				id: id,
				commentaires: value
			}]);
		}
	}, [setDocumentsToSendArtistes, documentsToSendArtistes]);

	const handleCheck = useCallback((id?: string, value?: boolean, all?: boolean) => {
		const ref = [...documentsToSendArtistes];

		if (all) {
			setDocumentsToSendArtistes(documentsToSendArtistes.map(x => ({
				id: x.id,
				validee: value
			})));
		} else {
			if (ref.find(e => e.id === id)) {
				const entry = ref.find(e => e.id === id);
				let newItem: DocumentValidationDto[] = [{
					...entry, validee: value
				}]
				let newarray = ref.map(e => newItem.find(item => item?.id === e.id) || e);
				setDocumentsToSendArtistes(newarray);
			} else {
				setDocumentsToSendArtistes([...documentsToSendArtistes, {
					id,
					validee: value
				}]);
			}
		}
	}, [setDocumentsToSendArtistes, documentsToSendArtistes]);

	const handleAddArtist = () => {
		setArtistSelected(undefined);
		openModalAdd();
	}

	const submitArtistDocuments = async () => {
		if (masseSalariale?.artistes && masseSalariale?.artistes?.length > 0 || masseSalariale?.enseignants && masseSalariale?.enseignants?.length > 0) {
			let artistesDocs: any;
			let enseignantsDocs: any;
			if (masseSalariale?.artistes && masseSalariale?.artistes?.length > 0) {
				artistesDocs = masseSalariale.artistes
					.filter(x => artistesDocumentsFiles[escapeName((x.bulletin?.fileName || '') + x.id)] || artistesDocumentsFiles[escapeName((x.contrat?.fileName || '') + x.id)])
					.map(x => {
						if (x.bulletin) {
							return {
								id: x.id,
								bulletin: {
									partName: escapeName((x.bulletin?.fileName || '') + x.id),
									actionType: 'update'

								}
							}
						}
						if (x.contrat) {
							return {
								id: x.id || '',
								contrat: {
									partName: escapeName((x.contrat?.fileName || '') + x.id),
									actionType: 'update'
								}
							}
						}
						return { id: x.id }
					});
			}

			if (masseSalariale?.enseignants && masseSalariale?.enseignants?.length > 0) {
				enseignantsDocs = masseSalariale.enseignants
					.filter(x => artistesDocumentsFiles[escapeName((x.justificatifRemuneration?.fileName || '') + x.id)])
					.map(x => {
						if (x.justificatifRemuneration) {
							return {
								id: x.id || '',
								contrat: {
									partName: escapeName((x.justificatifRemuneration?.fileName || '') + x.id),
									actionType: 'update'
								}
							}
						}
						return { id: x.id }
					});
			}
			artistDocLoadingTrue();
			try {
				await adelVersementClient.updateArtisteDocument(versementInfos.versementId, { versementArtistes: artistesDocs || [], enseignants: enseignantsDocs || [] }, artistesDocumentsFiles);
				await getDemandeVersementMasseSalariale(dispatch, versementInfos.versementId);

				toast.success(details.categorie?.code === CategorieDossier.Ecole ?
					"Les documents des enseignants ont bien été envoyés" :
					"Les documents des artistes ont bien été envoyés"
				);
			} catch (error) {
				if (error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(details.categorie?.code === CategorieDossier.Ecole ?
						"Impossible de mettre à jour les documents des enseignants"
						: "Impossible de mettre à jour les documents des artistes"
					);
			}
			artistDocLoadingFalse();
		}
	}

	const validateArtistDocument = async () => {
		artistDocVaLidLoadingTrue();
		await updateVersementArtistesValidation(versementInfos.versementId, { documents: documentsToSendArtistes.filter(e => e.id) });
		artistDocVaLidLoadingFalse();
	}

	return (<>


		<Table
			className="table--masseSalarialeArtistes"
			data={data}
			getTableProps={getTableProps}
			getTableBodyProps={getTableBodyProps}
			headerGroups={headerGroups}
			rows={rows}
			prepareRow={prepareRow}
			styles={styleTable.onglet}
		/>
		<div className="details__contentFooter">
			{usePermUpdateArtisteDocument &&
				<div className="details__contentFooterItem">
					{isArtistDocLoading ? (
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
					) : (
						details.categorie?.code !== CategorieDossier.Ecole &&
						<FormButton
							type="submit"
							value={"Enregistrer les documents d'artistes"}
							onClick={submitArtistDocuments}
						/>
					)}
				</div>
			}
			{usePermUpdateVersementArtistesValidation &&
				<div className="details__contentFooterItem">
					{isArtistDocVaLidLoading ? (
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
					) : (
						details.categorie?.code !== CategorieDossier.Ecole &&
						<FormButton
							type="submit"
							value={"Enregistrer la validation des documents d'artistes"
							}
							onClick={validateArtistDocument}
						/>
					)}
				</div>
			}
		</div>
		<ModalCommentaire
			id={idDoc}
			setOpenModal={setOpenModal}
			openModal={openModal}
			setCommentToSend={handleComment}
			defaultValue={commDoc}
		/>
		{isModalAddOpen && details.categorie?.code !== CategorieDossier.Ecole &&
			<ModalAddArtist
				isOpen={isModalAddOpen}
				onClose={closeModalAdd}
				versementId={versementInfos.versementId}
				dossierId={versementInfos.dossierId}
				artisteSelected={artistSelected}
				codeCategorie={CategorieDossier.AidePromotionImage}
				setMasseSalariale={setMasseSalariale}
				masseSalariale={masseSalariale}
				commission={dossierCommission}
			/>
		}
		{isModalDeleteOpen &&
			<Modal
				isOpen={isModalDeleteOpen}
				style={modalCustomStyles}
				className="normal__modal"
			>
				<div className="modal__header">
					<h3>{details.categorie?.code === CategorieDossier.Ecole ? 'Supprimer un enseignant' : 'Supprimer un artiste'}</h3>
				</div>
				<div className="modal__content">
					<div className="modal__item">
						{
							details.categorie?.code === CategorieDossier.Ecole ?
								'Voulez-vous supprimer un enseignant ?' :
								'Voulez-vous supprimer un artiste ?'
						}
					</div>
				</div>
				<div className="modal__footer">
					<FormButton
						type="button"
						value="Annuler"
						onClick={closeModalDelete}
					/>
					<FormButton
						type="submit"
						value="Supprimer"
						onClick={deleteArtist}
					/>
				</div>
			</Modal>
		}
	</>);
}

export default MasseSalarialeTable;