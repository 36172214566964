
import { CreateRelationDto, DocumentUploadInfoDto, RelationClient, StructureClient, StructureDetailsDto, UpdateDocumentsValidationDto } from '../../../services/generated/BackOffice-api';
import { StructureActionsEnum } from './structureAction';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { AdelStructureClient } from '../../../clients/AdelStructureClient';
import { Dictionary } from 'adel-shared/dist/models';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { navigate } from '@reach/router';

/** Actions */
export const requestStructureDetails = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_DETAILS_REQUEST,
		payload: {}
	}
}

export const getStructureDetails = (result: StructureDetailsDto) => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_DETAILS_SUCCESS,
		payload: {structureDetails: result}
	}
}

export const errorStructureDetails = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_DETAILS_ERROR
	}
}

/** Task */
const structureClient = new StructureClient('', axiosInstance);
const relationsClient = new RelationClient('', axiosInstance);
const adelStructureClient = new AdelStructureClient('', axiosInstance);

export const fetchStructureDetails = (dispatch: Dispatch, id: string) => {
	dispatch(requestStructureDetails());
	structureClient.getStructureDetails(id)
		.then(response => {
			dispatch(getStructureDetails(response))
			return response;
		})
		.catch(error => {
			dispatch(errorStructureDetails());
			if(error.response?.exception?.message)
				toast.error(error.response.exception.message);
			else if(error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error("Impossible de récupérer les détails de structure");
			throw error;
		});
}

export const updateStructureDetails = async (dispatch:Dispatch, id:string, body:StructureDetailsDto, documents: DocumentUploadInfoDto[] | null | undefined, files:Dictionary<File>| null | undefined) => {
	dispatch({ type: StructureActionsEnum.UPDATE_STRUCTURE_REQUEST });
	try {
		const response = await adelStructureClient.updateStructureDetails(id,
																	body.representantLegal?.email,
																	body.adresseCorrespondance?.line1,
																	body.adresseCorrespondance?.line2,
																	body.adresseCorrespondance?.ville,
																	body.adresseCorrespondance?.codePostal,
																	documents,
																	files);
		dispatch({
			type: StructureActionsEnum.UPDATE_STRUCTURE_SUCCESS,
			payload: { structureDetails: response }
		});
		toast.success("Les informations ont bien été mises à jour");
		return response;
	} catch(error) {
		dispatch({ type: StructureActionsEnum.UPDATE_STRUCTURE_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour les infos de la structure");
	}
}

export const deleteStructureDetails = async (dispatch:Dispatch, id: number) => {
	dispatch({ type: StructureActionsEnum.DELETE_STRUCTURE_REQUEST })
	try {
		const response = await adelStructureClient.deleteStructure(id);
		dispatch({
			type: StructureActionsEnum.DELETE_STRUCTURE_SUCCESS,
			payload: { structureDetails: response }
		});
		toast.success("La structure a été supprimée avec succès");
		navigate("/Gestion/Structures");
		return response;

	} catch (error) {
		dispatch({ type: StructureActionsEnum.DELETE_STRUCTURE_ERROR });
		if (error.response?.exception?.type && error.response?.exception?.type === 'Microsoft.EntityFrameworkCore.DbUpdateException') {
			toast.error("Impossible de supprimer la structure. Il existe des données liées.");
		}	
		else if (error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if (error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de supprimer la structure");
	}
}

export const fetchStructureRelations = async (dispatch: Dispatch, id: string) => {
	dispatch({ type: StructureActionsEnum.GET_STRUCTURE_RELATIONS_REQUEST });
	try {
		let response = await relationsClient.getStructureRelations(id);
		dispatch({
			type: StructureActionsEnum.GET_STRUCTURE_RELATIONS_SUCCESS,
			payload: { structureRelations: response }
		});
	} catch(error) {
		dispatch({ type: StructureActionsEnum.GET_STRUCTURE_RELATIONS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les relations de structure");
	}
}

export const addStructureRelations = async (dispatch: Dispatch, id: string, body: CreateRelationDto) => {
	dispatch({ type: StructureActionsEnum.ADD_STRUCTURE_RELATIONS_REQUEST });
	try {
		let response = await relationsClient.createStructureRelation(id, body);
		dispatch({
			type: StructureActionsEnum.ADD_STRUCTURE_RELATIONS_SUCCESS
		});
	} catch(error) {
		dispatch({ type: StructureActionsEnum.ADD_STRUCTURE_RELATIONS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible d'ajouter la relation'");
	}
}

export const deleteStructureRelations = async (dispatch: Dispatch, id: string) => {
	dispatch({ type: StructureActionsEnum.DELETE_STRUCTURE_RELATIONS_REQUEST });
	try {
		let response = await relationsClient.deleteRelation(id);
		dispatch({
			type: StructureActionsEnum.DELETE_STRUCTURE_RELATIONS_SUCCESS
		});
	} catch(error) {
		dispatch({ type: StructureActionsEnum.DELETE_STRUCTURE_RELATIONS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de supprimer la relation");
	}
}

export const updateStructureDocumentsValidation = async (dispatch: Dispatch, id: string, documents: UpdateDocumentsValidationDto) => {
	dispatch({ type: StructureActionsEnum.PUT_STRUCTURE_DOCUMENTS_VALIDATION_REQUEST });
	try {
		await structureClient.updateStructureDocumentsValidation(id, documents);
		dispatch({
			type: StructureActionsEnum.PUT_STRUCTURE_DOCUMENTS_VALIDATION_SUCCESS
		});
		toast.success("La validation a été enregistrée avec succès")
	} catch(error) {
		dispatch({ type: StructureActionsEnum.PUT_STRUCTURE_DOCUMENTS_VALIDATION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour les documents de validation");
	}
}

export const fetchBalance = async(
	id: string
) => {
	try {
		const result = await structureClient.getStructureBalance(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer la balance");
		throw error;
	}
};