import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { modalSmallerCustomStyles } from '../../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Loader from 'react-loader-spinner';

interface ModalDeleteReaffectationProps {
	isOpen: boolean;
	onCancel: () => void;
	onValidate: () => void;
	isLoading?: boolean;
}

const ModalDeleteReaffectation: FunctionComponent<ModalDeleteReaffectationProps> = ({
	isOpen,
	onCancel,
	onValidate,
	isLoading
}) => {
	return (
        <Modal
            isOpen={isOpen}
            style={modalSmallerCustomStyles}
        >
            <div className="modal__header">
                <h3>Supprimer une réaffectation</h3>
            </div>
            <div className="modal__content">
                <div className="modal__item">
                    <p>Voulez-vous supprimer cette réaffectation ?</p>
                </div>
            </div>
            <div className="modal__footer">
                <FormButton
                    type="button"
                    value="Annuler"
                    onClick={() => onCancel()}
                />
                {isLoading
                    ? <Loader type="TailSpin" width={35} height={35} color="#d93943"></Loader>
                    : <FormButton
                        type="submit"
                        value="Valider"
                        onClick={() => onValidate()}
                    />}
            </div>
        </Modal>
    );
}

export default ModalDeleteReaffectation;