import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import { modalCustomStyles } from '../../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useCommissionSelector } from '../../../store/store-helpers';

interface ModalFigerCommissionProps extends RouteComponentProps {
	id: string;
	setOpenModal: (value: boolean) => void;
	openModal: boolean;
	figerComm: (id:string) =>void;
}

const ModalFigerCommission: React.FunctionComponent<ModalFigerCommissionProps> = ({ id, openModal, setOpenModal, figerComm }) => {
	const commissionSelector = useCommissionSelector();

	const handleFigerSession = () => {
		figerComm(id);
		setOpenModal(false);
	};

	return (
		<Modal
			isOpen={openModal}
			style={modalCustomStyles}
			className="normal__modal membresModal"
		>
			<>
				<div className="modal__header">
					<h3>Figer la liste des dossiers</h3>
				</div>

				<div className="modal__content">
					<div className="input">
						Voulez-vous vraiment figer la liste des dossiers de cette commission ? Cette action déclenche l'envoi des convocations et aucun dossier ne pourra plus être ajouté.
					</div>
				</div>

				<div className="modal__footer">
					<FormButton
						type="button"
						value="Annuler"
						onClick={() => setOpenModal(false)}
					/>
					{
						commissionSelector.isLoading
						? <Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
						: <FormButton
							type="submit"
							value="Figer"
							onClick={handleFigerSession}
						/>

					}
				</div>
			</>
		</Modal>
	);
}

export default ModalFigerCommission;