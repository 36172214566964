import { Reducer } from "redux";
import { TemplateActionsEnum } from '../actions/templateAction';
import { DispatchTemplateAction } from '../actions/templateAction';
import { TemplateState, initialTemplateState } from '../states/templateState';

export const templateReducer: Reducer<Partial<TemplateState>, DispatchTemplateAction> = (state = initialTemplateState, action) => {
	switch (action.type) {
		case TemplateActionsEnum.GET_TEMPLATES_REQUEST:
			return { ...state, templateIsLoading: true };
		case TemplateActionsEnum.GET_TEMPLATES_SUCCESS:
			return {
				...state,
				templateIsLoading: false,
				templates: action.payload.templates
			};
		case TemplateActionsEnum.GET_TEMPLATES_ERROR:
			return { ...state, templateIsLoading: false };
		case TemplateActionsEnum.POST_TEMPLATE_REQUEST:
			return { ...state, templateIsLoading: true };
		case TemplateActionsEnum.POST_TEMPLATE_SUCCESS:
			return {
				...state,
				templateIsLoading: false,
				template: action.payload.template
			};
		case TemplateActionsEnum.POST_TEMPLATE_ERROR:
			return { ...state, templateIsLoading: false };
		case TemplateActionsEnum.DELETE_TEMPLATE_REQUEST:
			return { ...state, templateDeleteIsLoading: true };
		case TemplateActionsEnum.DELETE_TEMPLATE_SUCCESS:
			return {
				...state,
				templateDeleteIsLoading: false,
				id: action.payload.id
			};
		case TemplateActionsEnum.DELETE_TEMPLATE_ERROR:
			return { ...state, templateDeleteIsLoading: false };

		case TemplateActionsEnum.PUT_TEMPLATE_REQUEST:
			return { ...state, templateIsLoading: true };
		case TemplateActionsEnum.PUT_TEMPLATE_SUCCESS:
			return {
				...state,
				templateIsLoading: false,
				template: action.payload.template
			};
		case TemplateActionsEnum.PUT_TEMPLATE_ERROR:
			return { ...state, templateIsLoading: false };
		default:
			return state;
	}
};
