import InputSelect, { AdelOption } from "adel-shared/dist/components/basics/InputSelect";
import ControlledInputRadioButton from "adel-shared/dist/components/basics/ControlledInputRadioButton";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { BOAdelUserDto, CommissionDto, DossierInstructionDto, StatutDossier, SubCategorieDossierDto } from "../../../services/generated/BackOffice-api";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useUserSelector } from '../../../store/store-helpers';
import useHasPermission from "../../../custom-hooks/useHasPermission";

interface GeneralProps {
    commissions: CommissionDto[],
    allowedStatuts: StatutDossier[],
    categories: SubCategorieDossierDto[],
    dossierInstruction: DossierInstructionDto,
    isChargesDinstructionEnabled: boolean,
    onLoaded: () => void;
    control: any;
    errors: any;
    instructionValidator: any;
    pageLoaded: boolean;
}

function arrayToOptions<T>(items: T[], nameEval: string, t?: TFunction): AdelOption<T>[] {
    return items.map(item => {
        return {
            label: eval(nameEval), // Ex: "item.prenom + \" \"+ item.nom"
            value: item
        }
    }) as AdelOption<T>[];
}

const General: React.FunctionComponent<GeneralProps> = (props) => {

    const { t } = useTranslation();
    const userSelector = useUserSelector();

    const [chargesDinstructionOptions, setChargesDinstructionOptions] = useState<AdelOption<BOAdelUserDto>[]>();
    const [commissionsOptions, setCommissionsOptions] = useState<AdelOption<CommissionDto>[]>();
    const [statutsOptions, setStatutsOptions] = useState<AdelOption<StatutDossier>[]>();
    const [categoriesOptions, setStagoriesOptions] = useState<AdelOption<SubCategorieDossierDto>[]>();

    useEffect(() => {
        if (userSelector.users && userSelector.users.length > 0 && props.commissions.length > 0 && props.dossierInstruction !== undefined && props.dossierInstruction.statut && props.onLoaded) {
            if (props.pageLoaded) { console.debug("page is already loaded. Skipping useless re-rendering"); return; }
            setCommissionsOptions(arrayToOptions<CommissionDto>(props.commissions, "item.nom"));
            setStatutsOptions(arrayToOptions<StatutDossier>(props.allowedStatuts, 't(`dossier.statut.${item}`)', t));
            setStagoriesOptions(arrayToOptions<SubCategorieDossierDto>(props.categories, "item.nom"));
			
			setChargesDinstructionOptions(arrayToOptions<BOAdelUserDto>(userSelector.users, "item.prenom + \" \"+ item.nom"));

            const timer = setTimeout(() => props.onLoaded && props.onLoaded(), 0);
            return () => clearTimeout(timer);
        }
    }, [userSelector.users, props.dossierInstruction, props.commissions, props.categories, props.onLoaded])


    /** Permissions */
	const usePermUpdateDossierInstruction = useHasPermission("UpdateDossierInstruction");
    
    return <>
        <div className="detailsTab__row">
            <div className="detailsTab__column">
                <div className="detailsTab__content">
                    <Controller control={props.control}
                        name="chargeInstruction"
                        rules={props.instructionValidator?.["ChargeInstruction"]}
                        as={({ onChange, value, name }) => (
                            <InputSelect<BOAdelUserDto>
                                name={name}
                                label="Chargé d'instruction"
                                classname="inputSelect"
                                options={chargesDinstructionOptions}
                                onChange={(selectedCI) => {
                                    onChange(selectedCI);
                                }}
                                value={value}
                                customMatchValue={(source, target) => source.id === target.id}
                                errors={props.errors}
                                readonly={!props.isChargesDinstructionEnabled}
                                placeholder={t("common.select")}
                                isDisabled={!usePermUpdateDossierInstruction}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="detailsTab__column">
                <div className="detailsTab__content">
                    <Controller control={props.control}
                        name="commission"
                        as={({ onChange, value, name }) => (
                            <InputSelect<CommissionDto>
                                name={name}
                                label="Programmé en commission"
                                classname="inputSelect"
                                options={commissionsOptions}
                                value={value}
                                onChange={(selectedCommission) => {
                                    onChange(selectedCommission);
                                }}
                                customMatchValue={(source, target) => source.id === target.id}
                                errors={props.errors}
                                placeholder={t("common.select")}
                                isDisabled={!usePermUpdateDossierInstruction}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="detailsTab__column">
                <div className="detailsTab__content">
                    <Controller control={props.control}
                        name="statut"
                        rules={{ ...props.instructionValidator?.["Statut"] }}
                        as={({ onChange, value, name }) => (
                            <InputSelect<StatutDossier>
                                name={name}
                                label="Statut"
                                classname="inputSelect"
                                options={statutsOptions}
                                value={value}
                                onChange={(selectedStatut) => {
                                    onChange(selectedStatut);
                                }}
                                errors={props.errors}
                                placeholder={t("common.select")}
                                isDisabled={!usePermUpdateDossierInstruction}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
        <div className="detailsTab__row">
            <div className="detailsTab__column">
                <div className="detailsTab__content">
                    <Controller control={props.control}
                        name="subCategorie"
                        rules={props.instructionValidator?.["SubCategorie"]}
                        as={({ onChange, value, name }) => (
                            <InputSelect<SubCategorieDossierDto>
                                name={name}
                                label="Catégorie"
                                classname="inputSelect"
                                options={categoriesOptions}
                                value={value}
                                onChange={(selectedCategory) => {
                                    onChange(selectedCategory);
                                }}
                                customMatchValue={(source, target) => source.id === target.id}
                                errors={props.errors}
                                placeholder={t("common.select")}
                                isDisabled={!usePermUpdateDossierInstruction}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="detailsTab__column">
                <div className="detailsTab__content">
                    <ControlledInputRadioButton
                        label="Concerne le droit exclusif"
                        name="dsv"
                        control={props.control}
                        value={props.dossierInstruction.dsv ?? false}
                        rules={props.instructionValidator?.["DSV"]}
                        errors={props.errors}
                        isDisabled={!usePermUpdateDossierInstruction}
                    />
                </div>
            </div>
            <div className="detailsTab__column">
                <div className="detailsTab__content">
                    <ControlledInputRadioButton
                        label="Bloquer l'avis"
                        name="blocageAvis"
                        control={props.control}
                        value={props.dossierInstruction.blocageAvis ?? false}
                        rules={props.instructionValidator?.["BlocageAvis"]}
                        errors={props.errors}
                        isDisabled={!usePermUpdateDossierInstruction}
                    />
                </div>
            </div>
        </div>
    </>;
}

export default General;
