import { Reducer } from "redux";
import { DossierCommissionDto, StatutCommission } from '../../../services/generated/BackOffice-api';
import { CommissionActionsEnum, DispatchCommissionAction } from '../actions/commissionActions';
import { CommissionState, initialCommissionState } from '../states/commissionState';

export const commissionReducer: Reducer<Partial<CommissionState>, DispatchCommissionAction> = (state = initialCommissionState, action) => {
	switch (action.type) {
		/** Commissions Liste */
		case CommissionActionsEnum.GET_COMMISSIONS_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.GET_COMMISSIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				commissions: action.payload?.commissions
			};
		case CommissionActionsEnum.GET_COMMISSIONS_ERROR:
			return { ...state, isCommissionListLoading: false };

		case CommissionActionsEnum.GET_COMMISSIONS_LIST_LIGHT_REQUEST:
			return { ...state, isCommissionListLoading: true };
		case CommissionActionsEnum.GET_COMMISSIONS_LIST_LIGHT_SUCCESS:
			return {
				...state,
				isCommissionListLoading: false,
				commissionsListLight: action.payload?.commissionsListLight
			};
		case CommissionActionsEnum.GET_COMMISSIONS_LIST_LIGHT_ERROR:
			return { ...state, isLoading: false };

		case CommissionActionsEnum.UPDATE_COMMISSION_FILTRE_SUCCESS:
			return {
				...state,
				isLoading: false,
				commissionFilter: action.payload
			};
		/** Commissions Détail */
		case CommissionActionsEnum.GET_COMMISSION_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.GET_COMMISSION_SUCCESS:
			return {
				...state,
				isLoading: false,
				commission: action.payload?.commission
			};
		case CommissionActionsEnum.GET_COMMISSION_ERROR:
			return { ...state, isLoading: false };

		/** Commission cloture */
		case CommissionActionsEnum.CLOSE_COMMISSION_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.CLOSE_COMMISSION_SUCCESS:
			return { ...state, isLoading: false };
		case CommissionActionsEnum.CLOSE_COMMISSION_ERROR:
			return { ...state, isLoading: false };

		/** Commission figer */
		case CommissionActionsEnum.FIGER_COMMISSION_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.FIGER_COMMISSION_SUCCESS:
			return { ...state, isLoading: false };
		case CommissionActionsEnum.FIGER_COMMISSION_ERROR:
			return { ...state, isLoading: false };

		/** Commissions détails Dossiers */
		case CommissionActionsEnum.GET_COMMISSION_DETAILS_DOSSIERS_REQUEST:
			return { ...state, isDossierLoading: true, commissionDetailsDossiers: [] };
		case CommissionActionsEnum.GET_COMMISSION_DETAILS_DOSSIERS_SUCCESS:
			return {
				...state,
				isDossierLoading: false,
				commissionDetailsDossiers: action.payload?.commissionDetailsDossiers
			};
		case CommissionActionsEnum.GET_COMMISSION_DETAILS_DOSSIERS_ERROR:
			return { ...state, isDossierLoading: false };

		/** Commissions Dossiers Liste */
		case CommissionActionsEnum.GET_COMMISSION_DOSSIERS_LIST_REQUEST:
			return { ...state, isDossierLoading: true, commissionDossiers: {} };
		case CommissionActionsEnum.GET_COMMISSION_DOSSIERS_LIST_SUCCESS:
			return {
				...state,
				isDossierLoading: false,
				commissionDossiers: action.payload?.commissionDossiers
			};
		case CommissionActionsEnum.GET_COMMISSION_DOSSIERS_LIST_ERROR:
			return { ...state, isDossierLoading: false, commissionDossiers: {} };


		/** Commission Création */
		case CommissionActionsEnum.CREATE_COMMISSION_REQUEST:
			return { ...state, isCreateLoading: true, errorCommissionCode: "" };
		case CommissionActionsEnum.CREATE_COMMISSION_SUCCESS:
			return {
				...state,
				isCreateLoading: false,
				commission: action.payload?.commission,
				errorCommissionCode: ""
			};
		case CommissionActionsEnum.CREATE_COMMISSION_ERROR:
			return { ...state, isCreateLoading: false, errorCommissionCode: action.payload?.errorCommissionCode };

		/** Commission Update */
		case CommissionActionsEnum.PUT_COMMISSION_SUCCESS:
			return {
				...state,
				commission: action.payload?.commission,
				errorCommissionCode: ""
			};

		/** Session CA Liste */
		case CommissionActionsEnum.GET_SESSIONS_CA_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.GET_SESSIONS_CA_SUCCESS:
			return {
				...state,
				isLoading: false,
				sessionsCA: action.payload?.sessionsCA
			};
		case CommissionActionsEnum.GET_SESSIONS_CA_ERROR:
			return { ...state, isLoading: false };
		
		/** Session CA Dossiers */
		case CommissionActionsEnum.GET_SESSION_CA_DOSSIERS_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.GET_SESSION_CA_DOSSIERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				sessionCADossiers: action.payload?.sessionCADossiers
			};
		case CommissionActionsEnum.GET_SESSION_CA_DOSSIERS_ERROR:
			return { ...state, isLoading: false };

		/** Session CA Ajout d'un montant */
		case CommissionActionsEnum.ADD_MONTANT_COMMISSION_CA_REQUEST:
			return { ...state, isMontantLoading: true };
		case CommissionActionsEnum.ADD_MONTANT_COMMISSION_CA_SUCCESS:
			return {
				...state,
				sessionCADossiers: {
					...state.sessionCADossiers,
					items: state.sessionCADossiers?.items?.reduce((acc: DossierCommissionDto[], curr) => {
						if(curr.id === action.payload?.commissionDetailsDossier?.id) {
							acc.push({
								...curr,
								...action.payload?.commissionDetailsDossier
							});
						} else {
							acc.push(curr);
						}
						return acc;
					}, [])
				},
				isMontantLoading: false,
			};
		case CommissionActionsEnum.ADD_MONTANT_COMMISSION_CA_ERROR:
			return { ...state, isMontantLoading: false };

		/** Session CA cloture */
		case CommissionActionsEnum.CLOSE_SESSION_CA_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.CLOSE_SESSION_CA_SUCCESS:
			return { ...state, isLoading: false };
		case CommissionActionsEnum.CLOSE_SESSION_CA_ERROR:
			return { ...state, isLoading: false };

		/** Enveloppes budgetaires */
		case CommissionActionsEnum.GET_COMMISSION_ENVELOPPES_BUDGETAIRES_REQUEST:
			return { ...state, isLoading: true };
		case CommissionActionsEnum.GET_COMMISSION_ENVELOPPES_BUDGETAIRES_SUCCESS:
			return {
				...state,
				commissionEnveloppesBudgetaires: action.payload?.commissionEnveloppesBudgetaires,
				isLoading: false
			};
		case CommissionActionsEnum.GET_COMMISSION_ENVELOPPES_BUDGETAIRES_ERROR:
			return { ...state, isLoading: false };

		// Tableau de bord
		case CommissionActionsEnum.GET_COMMISSION_TABLEAUBORD_REQUEST:
			return { ...state, isTableauBordLoading: true };
		case CommissionActionsEnum.GET_COMMISSION_TABLEAUBORD_SUCCESS:
			return {
				...state,
				commissionTableauBord: action.payload?.commissionTableauBord,
				isTableauBordLoading: false
			};
		case CommissionActionsEnum.GET_COMMISSION_TABLEAUBORD_ERROR:
			return { ...state, isTableauBordLoading: false };


		/** Commission ouvrir */
		case CommissionActionsEnum.OPEN_COMMISSION_REQUEST:
			return { ...state, isOpenCommLoading: true };
		case CommissionActionsEnum.OPEN_COMMISSION_SUCCESS:
			return { ...state, isOpenCommLoading: false };
		case CommissionActionsEnum.OPEN_COMMISSION_ERROR:
			return { ...state, isOpenCommLoading: false };

		case CommissionActionsEnum.CHANGE_COMMISSION_STATUT:
			const items = state.commissions?.items || [];
			const currentCommIndex = items?.findIndex(c => c.id === action.payload?.idCommissionToChange) || -1;

			if (items[currentCommIndex].statut === StatutCommission.Planifiee)
				items[currentCommIndex].statut = StatutCommission.Figee
			else if (items[currentCommIndex].statut === StatutCommission.Figee)
				items[currentCommIndex].statut = StatutCommission.EnCours

			return {
				...state,
				commissions: {
					...state.commissions,
					items
				}
			};

		default:
			return state;
	}
};