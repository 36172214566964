
import { toast } from 'react-toastify';
import { Action, Dispatch } from "redux";
import { axiosInstance } from '../../../custom-hooks/useAxios';
import {
	BordereauClient,
	ClotureBordereauVersementDto,
	CreateRemboursementDto,
	TypeBordereau
} from "../../../services/generated/BackOffice-api";
import { BordereauState } from "../states/bordereauStates";

export enum BordereauActionsEnum {
	GET_BORDEREAUX_SUCCESS = "GET_BORDEREAUX_SUCCESS",
    GET_BORDEREAU_VERSEMENT_SUCCESS = "GET_BORDEREAU_VERSEMENT_SUCCESS",
	GET_BORDEREAU_REAFFECTATION_SUCCESS = "GET_BORDEREAU_REAFFECTATION_SUCCESS",
	DELETE_BORDEREAU_VERSEMENT_MOUVEMENT_SUCCESS = "DELETE_BORDEREAU_VERSEMENT_MOUVEMENT_SUCCESS",
	DELETE_BORDEREAU_REAFFECTATION_MOUVEMENT_SUCCESS = "DELETE_BORDEREAU_REAFFECTATION_MOUVEMENT_SUCCESS",
	GET_BORDEREAU_REAFFECTATION_AMENDABLE_SUCCESS = "GET_BORDEREAU_REAFFECTATION_AMENDABLE_SUCCESS",
	ARCHIVE_BORDEREAU_SUCCESS = "ARCHIVE_BORDEREAU_SUCCESS",
	GET_DEMANDES_REMBOURSEMENT = "GET_DEMANDES_REMBOURSEMENT",
	CREATE_REMBOURSEMENT = "CREATE_REMBOURSEMENT"
}

export interface DispatchBordereauAction extends Action<BordereauActionsEnum> {
	payload: BordereauState;
}

const bordereauClient = new BordereauClient('', axiosInstance);

export const getBordereaux = async(
	dispatch: Dispatch<DispatchBordereauAction>,
    type: TypeBordereau
) => {
	try {
		const result = await bordereauClient.getBordereaux(type);
        dispatch({
            type: BordereauActionsEnum.GET_BORDEREAUX_SUCCESS,
            payload: { bordereaux: result }
        });
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les bordereaux");
		throw error;
	}
};

export const getBordereauVersementPreparatoire = async(
	dispatch?: Dispatch<DispatchBordereauAction>
) => {
    try {
		const result = await bordereauClient.getBordereauVersementPreparatoire();
		if(dispatch) {
			dispatch({
				type: BordereauActionsEnum.GET_BORDEREAU_VERSEMENT_SUCCESS,
				payload: { bordereauVersement: result }
			});
		}
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les bordereaux de versement préparatoire");
		throw error;
	}
};

export const getBordereauVersement = async(
    id: string,
	dispatch?: Dispatch<DispatchBordereauAction>
) => {
	try {
		const result = await bordereauClient.getBordereauVersement(id);
		if(dispatch) {
			dispatch({
				type: BordereauActionsEnum.GET_BORDEREAU_VERSEMENT_SUCCESS,
				payload: { bordereauVersement: result }
			});
		}
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les bordereaux de versement");
		throw error;
	}
};

export const getBordereauReaffectation = async(
    id: string,
	dispatch?: Dispatch<DispatchBordereauAction>
) => {
	try {
		const result = await bordereauClient.getBordereauReaffectation(id);
		if(dispatch) {
			dispatch({
				type: BordereauActionsEnum.GET_BORDEREAU_REAFFECTATION_SUCCESS,
				payload: { bordereauReaffectation: result }
			});
		}
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les bordereaux de réaffectation");
		throw error;
	}
};

export const getBordereauReaffectationAmendable = async(
	dispatch?: Dispatch<DispatchBordereauAction>
) => {
	try {
		const result = await bordereauClient.getBordereauReaffectationAmendable();
		if(dispatch) {
			dispatch({
				type: BordereauActionsEnum.GET_BORDEREAU_REAFFECTATION_AMENDABLE_SUCCESS,
				payload: { bordereauReaffectationAmendable: result }
			});
		}
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les bordereaux de réaffectation amendable");
		throw error;
	}
};

export const clotureBordereauVersement = async(
    id: string,
    body: ClotureBordereauVersementDto
) => {
	try {
		const result = await bordereauClient.clotureBordereauVersement(id, body);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de fermer le bordereau de versement");
		throw error;
	}
};

export const clotureBordereauReaffectation = async() => {
	try {
		const result = await bordereauClient.clotureBordereauReaffectation();
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de fermer le bordereau de réaffectation");
		throw error;
	}
};

export const getBordereauBlocageVersement = async(
	dispatch: Dispatch<DispatchBordereauAction>,
    id: string,
) => {
	try {
		const result = await bordereauClient.getBordereauBlocageVersement(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les blocages de versement");
		throw error;
	}
}

export const removeBlockedVersement = async(
	dispatch: Dispatch<DispatchBordereauAction>,
    bordereauId: string
) => {
	try {
		const result = await bordereauClient.removeBlockedVersement(bordereauId);
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de supprimer le versement bloqué");
		throw error;
	}
};

export const soumissionSignatureBordereau = async(
	dispatch: Dispatch<DispatchBordereauAction>,
	id: string
) => {
	try {
		const result = await bordereauClient.soumissionSignatureBordereau(id);
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de soumettre la signature de bordereau");
		throw error;
	}
};

export const deleteMouvement = async(
	dispatch: Dispatch<DispatchBordereauAction>,
	id: string,
	bordereauType: TypeBordereau
) => {
	try {
		const result = await bordereauClient.deleteMouvement(id);
		const action = `DELETE_BORDEREAU_${bordereauType.toUpperCase()}_MOUVEMENT_SUCCESS`;
		dispatch({
			type: BordereauActionsEnum[action as keyof typeof BordereauActionsEnum],
			payload: { mouvementId: id }
		});
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de supprimer le mouvement");
		throw error;
	}
};

export const archiveBordereau = async(
	dispatch: Dispatch<DispatchBordereauAction>,
	id: string
) => {
	try {
		const result = await bordereauClient.archiveBordereau(id);
		dispatch({
			type: BordereauActionsEnum.ARCHIVE_BORDEREAU_SUCCESS,
			payload: { bordereauId: id }
		});
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible d'archiver le bordereau'");
		throw error;
	}
};

export const getBordereauVersementAsExcel = async(
	id: string
) => {
	try {
		const result = await bordereauClient.getBordereauVersementAsExcel(id);
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer l'Excel de bordereau versement'");
		throw error;
	}
};

export const getExportSEPA = async(
	id: string
) => {
	try {
		const result = await bordereauClient.getExportSEPA(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer l'export SEPA'");
		throw error;
	}
};

export const getBordereauVersementAsTxt = async(
	id: string
) => {
	try {
		const result = await bordereauClient.getBordereauVersementAsTxt(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer le bordereau de versement version texte");
		throw error;
	}
};

export const getBordereauVersementAsPDF = async(
	id: string
) => {
	try {
		const result = await bordereauClient.getBordereauVersementAsPDF(id);
		return result;
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer le bordereau de versement version pdf");
		throw error;
	}
};

export const getMouvementsDemandeRemboursement = async(
	dispatch: Dispatch<DispatchBordereauAction>,
) => {
	try {
		const result = await bordereauClient.getMouvementsDemandeRemboursement();
        dispatch({
            type: BordereauActionsEnum.GET_DEMANDES_REMBOURSEMENT,
            payload: { demandesRemboursement: result }
        });
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les mouvements de demande de remboursement");
		throw error;
	}
};

export const createRemboursement = async(
	dispatch: Dispatch,
	id: string,
	body:CreateRemboursementDto
) => {
	try {
		await bordereauClient.createRemboursement(id, body);
        dispatch({
            type: BordereauActionsEnum.CREATE_REMBOURSEMENT
        });
		await getMouvementsDemandeRemboursement(dispatch);
		toast.success("Le remboursement a bien été pris en compte.")
	} catch(error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Une erreur est survenue dans la création d'un remboursement")
	}
};