import { ValidationPropertyType } from '../validators/ValidationPropertyType';
import { useTranslation } from 'react-i18next';
import { notNullValidate, emailValidate, siretValidate, phoneValidate, ibanValidate, bicRgx, requiredUniqueCharValidate } from '../utils/validatorFunctions';
import { useState } from 'react';
function useValidationBase() {
    const [validationData, setValidationData] = useState(null);
    const { t } = useTranslation();
    const getRootValidator = (selector) => {
        if (!validationData)
            console.debug(`getRootValidator was called when validationData is null`);
        const selectedData = validationData === null || validationData === void 0 ? void 0 : validationData[selector];
        if (selectedData) {
            const result = {};
            const data = selectedData;
            Object.keys(data).forEach(key => {
                result[key] = transformValidations(data[key]);
            });
            return result;
        }
        return null;
    };
    const transformValidations = (validatorInfos) => {
        const validationOptions = {};
        validatorInfos.map((info) => {
            switch (info.name) {
                case ValidationPropertyType.NotNull:
                    // La valeur `false` d'un champs booléen est considéré comme non valide... https://github.com/react-hook-form/react-hook-form/issues/2323
                    validationOptions.validate = { value: value => notNullValidate(value, t('validation-messages.required')) };
                    break;
                case ValidationPropertyType.NotEmpty:
                    validationOptions.required = { value: true, message: t('validation-messages.required') };
                    validationOptions.minLength = { value: 1, message: t('validation-messages.not-empty') };
                    break;
                case ValidationPropertyType.NotEqual:
                    //TODO: Validation NotEqual type
                    // validationOptions.validate = { value: value => notEqualValidate(value, t('validation-messages.not-equal')) };
                    break;
                case ValidationPropertyType.RegularExpression:
                    if (info.Expression) {
                        validationOptions.pattern = { value: new RegExp(info.Expression), message: t('validation-messages.invalid-regexp') };
                    }
                    break;
                case ValidationPropertyType.UniqueCharCountProperty:
                    if (info.RequiredUniqueChars) {
                        validationOptions.validate = { value: value => requiredUniqueCharValidate(value, info.RequiredUniqueChars, t('validation-messages.required-unique-char', { number: info.RequiredUniqueChars })) };
                    }
                    break;
                case ValidationPropertyType.ExactLength:
                    validationOptions.minLength = info.Min && { value: info.Min, message: t('validation-messages.exact-length', { length: info.Min }) };
                    validationOptions.maxLength = info.Max && { value: info.Max, message: t('validation-messages.exact-length', { length: info.Max }) };
                    break;
                case ValidationPropertyType.Email:
                    if (info.Expression) {
                        validationOptions.pattern = { value: new RegExp(info.Expression), message: t('validation-messages.invalid-email') };
                    }
                    break;
                case ValidationPropertyType.MaximumLength:
                    validationOptions.maxLength = info.Max && { value: info.Max, message: t('validation-messages.max-length', { length: info.Max }) };
                    break;
                case ValidationPropertyType.MinimumLength:
                    validationOptions.minLength = info.Min && { value: info.Min, message: t('validation-messages.min-length', { length: info.Min }) };
                    break;
                case ValidationPropertyType.AspNetCoreCompatibleEmail:
                    validationOptions.validate = { value: value => emailValidate(value, t('validation-messages.invalid-email')) };
                    break;
                case ValidationPropertyType.Siret:
                    validationOptions.validate = { value: value => siretValidate(value, t('validation-messages.invalid-siret')) };
                    break;
                case ValidationPropertyType.Phone:
                    validationOptions.validate = { value: value => phoneValidate(value, info.AllowedTypes, t('validation-messages.invalid-not-phone'), t('validation-messages.invalid-phone')) };
                    break;
                case ValidationPropertyType.Iban:
                    validationOptions.validate = { value: value => ibanValidate(value, t('validation-messages.invalid-iban-country'), t('validation-messages.invalid-iban')) };
                    break;
                case ValidationPropertyType.Bic:
                    validationOptions.pattern = { value: new RegExp(bicRgx), message: t('validation-messages.invalid-bic') };
                    break;
                default:
                    break;
            }
            return validationOptions;
        });
        return validationOptions;
    };
    return {
        getRootValidator,
        validationData,
        setValidationData
    };
}
export default useValidationBase;
