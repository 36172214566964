import { RouteComponentProps } from '@reach/router';
import { uniqueId, values } from 'lodash';
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { DepenseDto, DepensesMontantsRealisesDto, DocumentValidationDto, DossierClient } from '../../../../../services/generated/BackOffice-api';
import CheckboxTable from '../../../../basics/CheckboxTable';
import clsx from 'clsx';
import ModalCommentaire from '../../../../document-validation/ModalCommentaire';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import Loader from 'react-loader-spinner';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { updateVersementDepensesValidation } from '../../../../../store/versement-store/actions/versementAction';
import Input from 'adel-shared/dist/components/basics/Input';
import { axiosInstance } from '../../../../../custom-hooks/useAxios';
import { useDossierSelector } from '../../../../../store/store-helpers';
interface DepensesProps extends RouteComponentProps {
	isDepenseAutre: boolean;
	depenses: DepenseDto[];
	versementId: string;
}

const Depenses: React.FunctionComponent<DepensesProps> = ({
	isDepenseAutre,
	depenses,
	versementId
}) => {
	const { t } = useTranslation();
	const dossierClient = new DossierClient('', axiosInstance);
	const dossierSelector = useDossierSelector();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [idDoc, setIdDoc] = useState<string>();
    const [commDoc, setCommDoc] = useState<string>("");
	const tableId = useRef<string>(uniqueId());
    const [data, setData] = useState<any[]>([]);
	const [isLoading, isLoadingTrue, isLoadingFalse] = useBooleanState(false);
	const [isEditingMontantsRealisezLoading, setIsEditingMontantsRealisezLoading] = useState<boolean>(false);
	const [documentsToSendDepenses, setDocumentsToSendDepenses] = useState<DocumentValidationDto[]>([]);
	const [depencesMontantsRealisesToChange, setDepencesMontantsRealisesToChange] = useState<DepensesMontantsRealisesDto[]>([]);
	const [isEditingMontantsRealises, setIsEditingMontantsRealises] = useState<boolean>(false);

	const handleCheck = useCallback((id?:string, value?:boolean, all?: boolean) => {
		const ref = [...documentsToSendDepenses];

		if(all) {
			setDocumentsToSendDepenses(documentsToSendDepenses.map(x => ({
				id: x.id,
				validee: value
			})));
		} else {
			if (ref.find(e => e.id === id)) {
				const entry = ref.find(e => e.id === id);
				let newItem:DocumentValidationDto[] = [{
					...entry, validee: value
				}]
				let newarray = ref.map(e => newItem.find(item => item?.id === e.id) || e);
				setDocumentsToSendDepenses(newarray);
			} else {
				setDocumentsToSendDepenses([...documentsToSendDepenses, {
					id,
					validee: value
				}]);
			}
		}
	}, [setDocumentsToSendDepenses, documentsToSendDepenses]);

	const handleOpenModal = useCallback((id: string, value:string) => {
		const comm = documentsToSendDepenses.find(e => e.id === id)?.commentaires;
		setIdDoc(id);
		if (comm)
			setCommDoc(comm);
		else
			setCommDoc(value);
		setOpenModal(true);
	}, [documentsToSendDepenses, setOpenModal, setIdDoc, setCommDoc]);

	const handleComment = useCallback((id?:string, value?:string) => {
		if (documentsToSendDepenses.find(e => e.id === id)) {
			const entry = documentsToSendDepenses.find(e => e.id === id);
			let newItem:DocumentValidationDto[] = [{
				...entry, commentaires: value
			}];
			let newarray = documentsToSendDepenses.map(e => newItem.find(item => item?.id === e.id) || e);
			setDocumentsToSendDepenses(newarray);
		} else {
			setDocumentsToSendDepenses([...documentsToSendDepenses, {
				id: id,
				commentaires: value
			}]);
		}
	}, [setDocumentsToSendDepenses, documentsToSendDepenses]);

	const handleMontantRealise = useCallback((id?:string, value?:number) => {
		let depense = depencesMontantsRealisesToChange.find(e => e.id === id);
		if (depense !== undefined) {
			let newItem:DepensesMontantsRealisesDto[] = [{
				...depense, montantRealise: value
			}];
			let newarray = depencesMontantsRealisesToChange.map(e => newItem.find(item => item?.id === e.id) || e);
			setDepencesMontantsRealisesToChange(newarray);
		} else {
			setDepencesMontantsRealisesToChange([...depencesMontantsRealisesToChange, {
				id: id,
				montantRealise: value
			}]);
		}
	}, [setDepencesMontantsRealisesToChange, depencesMontantsRealisesToChange]);

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Désignation", accessor: "Nom", disableSortBy: true, className:'table__cell--nom' },
					{ Header: "Nom de la dépense", accessor: "NomDepense", disableSortBy: true, className:'table__cell--montants' },
					{ Header: "Montant prévisionnel", accessor: "MontantPrev", disableSortBy: true, className:'table__cell--montants' },
					{ Header: isDepenseAutre 
							? "Montant de la dépense"
							: "Montant réalisé", 
						accessor: "Montant",
						disableSortBy: true,
						Cell:(value: any) => {
							return isEditingMontantsRealises ?  (
								<>
									<Input
										type="number"
										onBlur={(montant: string, e?: ChangeEvent<HTMLInputElement>)=> {
											handleMontantRealise(value.cell.row.values.Id , +montant);
										} }
										name={`input-${value.cell.row.values.Id}-montant`}
										defaultValue={depencesMontantsRealisesToChange.find(e => e.id === value.cell.row.values.Id)?.montantRealise || value.cell.row.values.Montant}
									/>
								</>
							) : (
								<span>
									{depencesMontantsRealisesToChange.find(e => e.id === value.cell.row.values.Id)?.montantRealise ?
										`${formatNumber(depencesMontantsRealisesToChange.find(e => e.id === value.cell.row.values.Id)?.montantRealise)} €`
										: value.value}
								</span>
								);
						}
					},
					{ Header: "Facture", accessor: "Document", disableSortBy: true,
						Cell: (value: any) => (
							value?.value ? <a href={value.value.uri} target="_blank" >{value.value.fileName}</a> : <>-</>
						)
					},
					{
						Header: (
						{ getToggleAllRowsSelectedProps }: any) => {
							const props = getToggleAllRowsSelectedProps();
							return (
								<div className="table__select-all">
									<CheckboxTable
										id={`select-all-${tableId.current}`}
										indeterminate={props.indeterminate}
										onCheck={(id: string, check: boolean, e: ChangeEvent<HTMLInputElement>) => {
											props.onChange(e);
											handleCheck(id, check, true);
										}}
										defaultCheck={props.checked}
									/>
									<span>Valider PJ</span>
								</div>
							)
						},
						accessor: "Validee",
						disableSortBy: true,
						Cell: (value: any) => {
							const props = value.cell.row.getToggleRowSelectedProps();
							if(value.cell.row.values.IdDocument) {
								return (
									<CheckboxTable
										id={value.cell.row.values.IdDocument}
										onCheck={(id: string, check: boolean, e: ChangeEvent<HTMLInputElement>) => {
											props.onChange(e);
											handleCheck(id, check);
										}}
										defaultCheck={documentsToSendDepenses.find(e => e.id === value.cell.row.values.IdDocument)?.validee || props.checked}
									/>)
							} else return <></>
						}
					},
					{
						Header: "",
						accessor: "Commentaires",
						disableSortBy: true,
						Cell: (value: any) => {
							const props = documentsToSendDepenses.find(e => e.id === value.cell.row.values.IdDocument);
							if(value.cell.row.values.IdDocument) {
								return ( <span
									className={clsx("commentIcon",
										{"commentIcon--hasValue": props?.commentaires && props.commentaires !== "" }
									)}
									onClick={() => handleOpenModal(value.cell.row.values.IdDocument, value.value)}
								>
									<i
										className={clsx({
												"far": !props?.commentaires,
												"fas": props?.commentaires
											},
											"fa-message"
										)}
									></i>
								</span>)
							} else return <></>;
						}
					},
					{
						Header: "IdDocument",
						accessor: "IdDocument"
					},
					{
						Header: "Id",
						accessor: "Id"
					}
				]
			}
		];
	}, [documentsToSendDepenses, isDepenseAutre]);
	
	useEffect(() => {
		if (depenses) {
			const data = depenses.map(d => ({
				Id: d.id,
				Nom: t(`champDepense.${d.champ}`),
				NomDepense: d.intitule || '-',
				MontantPrev: `${d.montant ? formatNumber(d.montant) : '-'} €`,
				Montant: isEditingMontantsRealises ? d.montantRealise : `${d.montantRealise ? formatNumber(d.montantRealise) : '-'} €`,
				Document: d.document,
				Commentaires: d.document?.commentaires,
				Validee: d.document?.validee,
				IdDocument: d.document?.id,
			}));

			setDocumentsToSendDepenses(depenses.map(d => ({
				id: d.document?.id,
				validee: d.document?.validee,
				commentaires: d.document?.commentaires
			})));

			setData(data);
		} else {
			setDocumentsToSendDepenses([]);
		}
	}, [depenses, isEditingMontantsRealises]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: [
					"Id",
					"IdDocument",
					!isDepenseAutre ? "NomDepense" : "MontantPrev"
				]
			},
			manualSortBy: true
		} as any,
		useSortBy,
		useRowSelect
    );

	const submitDepenseDocuments = async() => {
		isLoadingTrue();
		await updateVersementDepensesValidation(versementId, {documents: documentsToSendDepenses.filter(e => e.id)});
		isLoadingFalse();
	}

	const submitDepensesMontantsRealises = async() => {
		setIsEditingMontantsRealisezLoading(true);
		
		dossierSelector.dossierDetails?.id && await dossierClient.updateDossierDepenseMontantRealise(dossierSelector.dossierDetails?.id, {depenses: depencesMontantsRealisesToChange});
		
		setIsEditingMontantsRealises(false);
		setIsEditingMontantsRealisezLoading(false);
	}

	return(<>
		<Table
			className="table--depenses"
			data={data}
			getTableProps={getTableProps}
			getTableBodyProps={getTableBodyProps}
			headerGroups={headerGroups}
			rows={rows}
			prepareRow={prepareRow}
			styles={styleTable.onglet}
		/>
		<div className="details__contentFooter">
			<div className="details__contentFooterItem">
				 {isEditingMontantsRealisezLoading ? (
					 <Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					></Loader>
				 ) : isEditingMontantsRealises ? (
					<button
						type="button"
						onClick={() => submitDepensesMontantsRealises()}
					>
						{t('dossier.depense.enregistrerMontantsRealises')}
					</button>
				) : (
					<button
						type="button"
						onClick={() => setIsEditingMontantsRealises(true)}
					>
						{t('dossier.depense.modifierMontantsRealises')}
					</button>
				)}
			</div>
			<div className="details__contentFooterItem">
				{isLoading ? (
					<Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					></Loader>
				) : (
					<FormButton
						type="submit"
						value="Enregistrer la validation des documents de dépense"
						onClick={submitDepenseDocuments}
					/>
				)}
			</div>
		</div>
		<ModalCommentaire
			id={idDoc}
			setOpenModal={setOpenModal}
			openModal={openModal}
			setCommentToSend={handleComment}
			defaultValue={commDoc}
		/>
	</>);
}

export default Depenses;
