import { IDossierFilters } from '../../../components/dossiers/DossiersListe';
import { artisteNomFilterKey, artistePrenomFilterKey, chargeInstructionNomFilterKey, chargeInstructionPrenomFilterKey, dateCommissionFilterKey, filtersDefault, nomFilterKey, numeroFilterKey, representanPrenomFilterKey, representantNomFilterKey, responsableNomFilterKey, responsablePrenomFilterKey, statutFilterKey, structureFilterKey, typeFilterKey, villeFilterKey } from '../../../constants/filters.constant';
import {
	BOVersementDtoSortedFilteredPage,
	DiscussionDto,
	DiscussionMessageDto,
	DossierAdditionalInfoDto,
	DossierAideHistoriqueDto,
	DossierConventionAndCourrierDto,
	DossierDSVDto,
	DossierDetailsDto,
	DossierInstructionDto,
	DossierStructureRelationsDto,
	DossierValidationViewModelDto,
	SubCategorieDossierDto,
	DossierNotationDto,
	UpdateDossierNoteDto,
	VersementDetailsDto,
	DemandeVersementMasseSalarialeDto,
	SyntheseFinanciereDto,
	DossierMouvementDto,
	TypeMouvementDto,
	BODossierDtoSortedFilteredPage,
	ObjetDiscussion
} from '../../../services/generated/BackOffice-api';

export interface DossierState {
	listeDossiers?:BODossierDtoSortedFilteredPage;
	dossierDetails?: DossierDetailsDto;
	isDossierDetailsLoading?:boolean;
	dossierAdditionalInfo?: DossierAdditionalInfoDto,
	dossierInstruction?: DossierInstructionDto,
	subCategories?: SubCategorieDossierDto[],
	dossierNote?: UpdateDossierNoteDto;
	dossierDSV?: DossierDSVDto;
	isLoading?: boolean;
	isInstructionLoading?: boolean;
	isAgreementLoading?: boolean;
	isRelationsLoading?: boolean;
	dossierRelations?: DossierStructureRelationsDto;
	isAidesLoading?: boolean;
	dossierAidesHistory?: DossierAideHistoriqueDto;
	isDocValLoading?:boolean;
	conventionCourrier?:DossierConventionAndCourrierDto;
	errorCode?:string;
	dossierVersements?: BOVersementDtoSortedFilteredPage;
	dossierValidation?: DossierValidationViewModelDto;
	dossierDiscussions?: DiscussionDto[];
	dossierDiscussionsLu?: string[];
	dossierDiscussionId?: string;
	dossierDiscussion?: DiscussionDto;
	dossierMessage?: DiscussionMessageDto;
	dossierNotation?: DossierNotationDto;
	masseSalariale?: number;
	detailsVersement?: VersementDetailsDto;
	masseSalarialeVersement?: DemandeVersementMasseSalarialeDto;
	dossierSynthese?: SyntheseFinanciereDto;
	dossierMouvements?: DossierMouvementDto[];
	dossierMouvement?: DossierMouvementDto;
	evenementFinancier?: {
		montant?: number;
		montantReaffectation?: number;
		typeMouvements?: TypeMouvementDto[];
	};
	objectDiscussionDossier?: ObjetDiscussion[];
	dossierFilter?: IDossierFilters;
}

export interface DossierPayload {
	listeDossiers?:BODossierDtoSortedFilteredPage;
	dossierDetails?: DossierDetailsDto;
	dossierDocuments?: DossierDetailsDto['documents'];
	isDossierDetailsLoading?:boolean;
	dossierAdditionalInfo?: DossierAdditionalInfoDto,
	dossierInstruction?: DossierInstructionDto,
	subCategories?: SubCategorieDossierDto[],
	dossierNote?: UpdateDossierNoteDto;
	dossierDSV?: DossierDSVDto;
	isLoading?: boolean;
	isInstructionLoading?: boolean;
	isAgreementLoading?: boolean;
	isRelationsLoading?: boolean;
	dossierRelations?: DossierStructureRelationsDto;
	isAidesLoading?: boolean;
	dossierAidesHistory?: DossierAideHistoriqueDto;
	isDocValLoading?:boolean;
	conventionCourrier?:DossierConventionAndCourrierDto;
	errorCode?:string;
	dossierVersements?: BOVersementDtoSortedFilteredPage;
	dossierValidation?: DossierValidationViewModelDto;
	dossierDiscussions?: DiscussionDto[];
	dossierDiscussionsLu?: string[];
	dossierDiscussionId?: string;
	dossierDiscussion?: DiscussionDto;
	dossierMessage?: DiscussionMessageDto;
	dossierNotation?: DossierNotationDto;
	masseSalariale?: number;
	detailsVersement?: VersementDetailsDto;
	masseSalarialeVersement?: DemandeVersementMasseSalarialeDto;
	dossierSynthese?: SyntheseFinanciereDto;
	dossierMouvements?: DossierMouvementDto[];
	dossierMouvement?: DossierMouvementDto;
	evenementFinancier?: {
		montant?: number;
		montantReaffectation?: number;
		typeMouvements?: TypeMouvementDto[];
	};
	objectDiscussionDossier?: ObjetDiscussion[];
	chargeInstructionId?: DossierDetailsDto['chargeInstructionId'];
}

export const initialDossierState: DossierState = {
	listeDossiers: {},
	isLoading: false,
	dossierDetails: {},
	isDossierDetailsLoading: false,
	dossierAdditionalInfo: undefined,
	dossierInstruction: {},
	// subCategories: [],
	masseSalariale: 0,
	dossierNote: {},
	dossierDSV: {},
	dossierRelations: {},
	isRelationsLoading: false,
	isAgreementLoading: false,
	isAidesLoading: false,
	dossierAidesHistory: {},
	isDocValLoading:false,
	conventionCourrier:{},
	isInstructionLoading: false,
	errorCode: undefined,
	dossierVersements: {},
	dossierValidation: {},
	dossierDiscussions: [],
	dossierDiscussionsLu: [],
	dossierDiscussionId: '',
	dossierDiscussion: {},
	dossierMessage: {},
	dossierNotation: {},
	detailsVersement: {},
	masseSalarialeVersement: {},
	dossierSynthese: {},
	dossierMouvements: [],
	dossierMouvement: {},
	evenementFinancier: {},
	objectDiscussionDossier: [],
	dossierFilter: filtersDefault
};