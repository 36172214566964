import WysiwygEditor from 'adel-shared/dist/components/WysiwygEditor';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import React, { SetStateAction, useEffect, useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { RouteComponentProps } from '@reach/router';
import { modalCustomStyles } from '../../../constants/config.constant';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { CommissionDto, CreateCritereDossierDto, CritereDossierDto, CritereDossierLightDto, UpdateCritereDossierDto } from '../../../services/generated/BackOffice-api';
import { fetchCriteresDeDossierCommissions, fetchLastCritereDeDossier } from '../../../store/commission-store/actions/commissionActions';
import { createCritereDossier, delCritereDossier, editCritereDossier } from '../../../store/critere-dossier-store/actions/critereDossierAction';
import { useCategorieDossierSelector, useCommissionSelector, useCritereDossierSelector } from '../../../store/store-helpers';
import CollapsibleSection from '../../basics/CollapsibleSection';

interface CriteresDossierModalProps extends RouteComponentProps {
	modalAddCritereIsOpen: boolean;
	setModalAddCritereIsOpen: Dispatch<SetStateAction<boolean>>;
	modalDeleteCritereIsOpen: boolean;
	setModalDeleteCritereIsOpen: Dispatch<SetStateAction<boolean>>;
	modalEditCritereIsOpen: boolean;
	setModalEditCritereIsOpen: Dispatch<SetStateAction<boolean>>;
	modalVisualizeCritereIsOpen: boolean;
	setModalVisualizeCritereIsOpen: Dispatch<SetStateAction<boolean>>;
	selectedCritere: CritereDossierDto;
	setSelectedCritere: Dispatch<SetStateAction<CritereDossierDto>>;
	selectedCritereContenuFR: string;
	setSelectedCritereContenuFR: Dispatch<SetStateAction<string>>;
	selectedCritereContenuEN: string;
	setSelectedCritereContenuEN:  Dispatch<SetStateAction<string>>;
	criteres: CritereDossierLightDto[];
	setCriteres: Dispatch<SetStateAction<CritereDossierLightDto[]>>;
	refreshCriteresDossier: () => void;
}

const CriteresDossierModal: React.FC<CriteresDossierModalProps> = ({
	modalAddCritereIsOpen,
	setModalAddCritereIsOpen,
	modalDeleteCritereIsOpen,
	setModalDeleteCritereIsOpen,
	modalEditCritereIsOpen,
	setModalEditCritereIsOpen,
	modalVisualizeCritereIsOpen,
	setModalVisualizeCritereIsOpen,
	selectedCritere,
	setSelectedCritere,
	selectedCritereContenuFR,
	setSelectedCritereContenuFR,
	selectedCritereContenuEN,
	setSelectedCritereContenuEN,
	criteres,
	setCriteres,
	refreshCriteresDossier
}) => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	// 	//selector
	const critereSelector = useCritereDossierSelector();
	const categorieSelector = useCategorieDossierSelector();
	const commissionsSelector = useCommissionSelector();

	//categories dossier values
	const [categoriesDossierForCreation, setCategoriesDossierForCreation] = useState<AdelOption<string>[]>([]);
	const [commissionsOptions, setCommissionsOptions] = useState<AdelOption<CommissionDto>[]>();
	const [selectedCommission, setSelectedCommission] = useState<CommissionDto>();


	/** Create new critere */
	const [categorieDossierSelectedIdForCreation, setCategorieDossierSelectedForCreation] = useState<string>();
	const [editorValueContentFR, setEditorValueContentFR] = useState<string>('');
	const [editorValueContentEN, setEditorValueContentEN] = useState<string>('');

	const [critereIsSending, setCritereIsSending] = useState<boolean>(false);

	useEffect(() => {
		let categoriesOptions: AdelOption<string>[] = [];
		categorieSelector.categories?.forEach(category => {
			categoriesOptions.push({
				value: category.id as string,
				label: category.nom as string
			});
		})
		setCategoriesDossierForCreation(categoriesOptions)
	}, [categorieSelector.categories])

	// Know when it's the first time loading (selectedCommission?.id is null)
	// Know when it's an edition (selectedCritere.commission?.nom)
	// Know when it's after the creation / modification (selectedCommission.Id not null)
	useEffect(() => {
		if (commissionsSelector.commissions?.items) {
			const commissionsNonSpecifiques = commissionsSelector.commissions.items.filter(c => !c.commissionSpecifique);
			const commissions = commissionsNonSpecifiques.map(e => ({
				label: e.nom || '',
				value: e
			}));

			if (modalAddCritereIsOpen) {
				// Création
				console.debug("commissions", commissionsNonSpecifiques);
			} else if (modalEditCritereIsOpen) {
				console.debug("selectedCritere", selectedCritere);
				selectedCritere?.commission?.nom && commissions.push({ label: selectedCritere.commission.nom, value: selectedCritere.commission });
				setSelectedCommission(selectedCritere.commission);
			}

			setCommissionsOptions(commissions);
		}
	}, [commissionsSelector.commissions, modalAddCritereIsOpen, modalEditCritereIsOpen]);

	useEffect(() => {
		if (selectedCritere && selectedCritere.categorieId) {
			(async () => {
				try {
					await fetchCriteresDeDossierCommissions(dispatch, selectedCritere.categorieId as string);
				} catch (error) {
					toast.error(t('common.errors.fetch'));
					console.error(error);
				}
			})();
		}
	}, [selectedCritere]);

	useEffect(() => {
		if (categorieDossierSelectedIdForCreation) {
			//TODO: appeler un endpoint qui va récupérer le dernier critère existant
		}
	}, [categorieDossierSelectedIdForCreation])

	const changeCategorieDossierForCreation = async (categoryId: string) => {
		// Récupération de la liste des commissions disponibles pour ce type de dossier
		try {
			setCommissionsOptions(undefined);
			setSelectedCommission({});
			setCommissionsOptions([]);
			await fetchCriteresDeDossierCommissions(dispatch, categoryId);
		} catch (error) {
			toast.error(t('common.errors.fetch'));
		}

		if(usePermGetLastCritereDossier) {
			// Recupération des contenu du dernier critère pour ce type de dossier
			try {
				const result = await fetchLastCritereDeDossier(categoryId);
				if(result.contenu?.fr) {
					setEditorValueContentFR(result.contenu.fr);
				}
				if(result.contenu?.en) {
					setEditorValueContentEN(result.contenu.en);
				}
			} catch(error) {
				setEditorValueContentFR("");
				setEditorValueContentEN("");
				toast.error(t('common.errors.fetch'));
			}
		}

		setCategorieDossierSelectedForCreation(categoryId)
	}

	// Ajouter un critère
	const handleCreateCritere = () => {

		if (selectedCommission?.id) {
			const createCritereDossierDto: CreateCritereDossierDto = {
				categorieId: categorieDossierSelectedIdForCreation,
				contenu: { "fr": editorValueContentFR, "en": editorValueContentEN },
				commissionId: selectedCommission.id
			};

			createCritereDossier(dispatch, createCritereDossierDto)().then((result: any) => {
				toast.success(t('common.success'));
				setModalAddCritereIsOpen(false);
				setSelectedCommission({});
				setCommissionsOptions([]);
				setCategorieDossierSelectedForCreation("");
				refreshCriteresDossier();
			});
		} else {
			toast.error(t('common.errors.send'));
		}
	};

	const handleDeleteCritere = () => {
		delCritereDossier(dispatch, selectedCritere.id)().then((result: any) => {
			const newCriteres = [...criteres]
			let index = -1;
			let i = 0;
			newCriteres.forEach((element) => {
				if (element.id === selectedCritere.id) {
					index = i;
				}
				i++;
			})
			if (index !== -1) {
				newCriteres.splice(index, 1);
				setCriteres(newCriteres)
			}
			setModalDeleteCritereIsOpen(false);
			if (modalEditCritereIsOpen === true) {
				setModalEditCritereIsOpen(false)
			}
		});
	}

	const handleEditCritere = () => {
		const updateCritereDossierDto: UpdateCritereDossierDto = {
			contenu: {
				fr: selectedCritereContenuFR,
				en: selectedCritereContenuEN
			},
			commissionId: selectedCommission?.id
		}
		usePermUpdateCritereDossier && editCritereDossier(dispatch, selectedCritere.id as string, updateCritereDossierDto)().then(() => {
			toast.success(t('common.success'));
			setModalEditCritereIsOpen(false);
			refreshCriteresDossier();
			setSelectedCommission({});
			setCommissionsOptions([]);
		});
	}


	/** Permissions */
	const usePermUpdateCritereDossier = useHasPermission("UpdateCritereDossier");
	const usePermGetLastCritereDossier = useHasPermission("GetLastCritereDossier");

	return (
		<>
			{modalAddCritereIsOpen &&
				<Modal
					isOpen={modalAddCritereIsOpen}
					style={modalCustomStyles}
					className="ajoutCritere__modal"
				>

					<div className="ajoutCritere__wrapper">
						<div className="modal__header">
							<h3>Ajouter un critère de dossier</h3>
						</div>

						<div className="modal__content">
							<div className="detailsTab">
								<div className="detailsTab__section">
									<div className="detailsTab__row">
										<div className="modal__input">
											<InputSelect
												name="categoriesDossier"
												label="Type de dossier"
												classname="inputSelect"
												options={categoriesDossierForCreation}
												onChange={changeCategorieDossierForCreation}
												value={categorieDossierSelectedIdForCreation}
											/>
										</div>

										{categorieDossierSelectedIdForCreation && commissionsOptions ?
											<div className="ajoutCritere__commission">
												<label>Mettre en place ces critères pour la commission du :</label>
												<InputSelect<CommissionDto>
													name={"commission"}
													label={""}
													classname="inputSelect"
													options={commissionsOptions}
													onChange={(selectedCommission) => {
														setSelectedCommission(selectedCommission);
													}}
													placeholder={t("common.select")}
													value={selectedCommission}
													customMatchValue={(source, target) => source.id == target.id}
												/>
												<label>soit pour tout dossier créé à partir du {selectedCommission?.dateDebutSoumission ? new Date(selectedCommission.dateDebutSoumission).toLocaleDateString() : ""}
												</label>
											</div>
											:
											<></>
										}
									</div>
								</div>

								{categorieDossierSelectedIdForCreation ?
									<>
										<div className="detailsTab__section">
											<CollapsibleSection title="Contenu Français" isOpenByDefault={true}>
												<div className="modal__wysiwyg">
													<WysiwygEditor
														editorValueContent={editorValueContentFR}
														setEditorValueContent={setEditorValueContentFR}
													/>
												</div>
											</CollapsibleSection>
										</div>

										<div className="detailsTab__section">
											<CollapsibleSection title="Contenu Anglais" isOpenByDefault={true}>
												<div className="modal__wysiwyg">
													<WysiwygEditor
														editorValueContent={editorValueContentEN}
														setEditorValueContent={setEditorValueContentEN}
													/>
												</div>
											</CollapsibleSection>
										</div>
									</>
									:
									<></>
								}
							</div>
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalAddCritereIsOpen(false);
								setSelectedCommission({});
								setCommissionsOptions([]);
							}}
						/>

						{critereIsSending ?
							<Loader
								type="TailSpin"
								width={35}
								height={35}
								color="#d93943"
							></Loader>
							:
							<FormButton
								type="submit"
								value="Enregistrer"
								disabled={editorValueContentFR === "" || editorValueContentEN === ''}
								onClick={handleCreateCritere}
							/>
						}
					</div>
				</Modal>
			}

			{modalDeleteCritereIsOpen &&
				<Modal
					isOpen={modalDeleteCritereIsOpen}
					style={modalCustomStyles}
					className="normal__modal"
				>
					<div className="modal__header">
						<h3>Supprimer le critère</h3>
					</div>

					<div className="modal__content">
						<div className="modal__item">
							Voulez-vous vraiment supprimer ce critère ?
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalDeleteCritereIsOpen(false);
								setSelectedCommission({});
								setCommissionsOptions([]);
							}}
						/>
						{critereSelector.critereIsLoading ?
							<Loader
								type="TailSpin"
								width={35}
								height={35}
								color="#d93943"
							></Loader>
							:
							<FormButton
								type="submit"
								value="Supprimer"
								onClick={handleDeleteCritere}
							/>
						}
					</div>
				</Modal>
			}

			{modalEditCritereIsOpen &&
				<Modal
					isOpen={modalEditCritereIsOpen}
					style={modalCustomStyles}
					className="ajoutCritere__modal"
				>
					<div className="ajoutCritere__wrapper">
						<div className="modal__header">
							<h3>Modifier le critère</h3>
						</div>

						<div className="modal__content">
							<div className="detailsTab__section">
								<div className="detailsTab__row">
									<div className="modal__input">
										{selectedCritere.categorieId && commissionsOptions ?
											<div className="ajoutCritere__commission">
												<label>Mettre en place ces critères pour la commission du :</label>
												<InputSelect<CommissionDto>
													name="commission"
													label=""
													classname="inputSelect"
													options={commissionsOptions}
													onChange={setSelectedCommission}
													placeholder={t("common.select")}
													value={selectedCommission}
													customMatchValue={(source, target) => source.id == target.id}
												/>
												<label>soit pour tout dossier créé à partir du {selectedCommission?.dateDebutSoumission ? new Date(selectedCommission.dateDebutSoumission).toLocaleDateString() : ""}
												</label>
											</div>
											:
											<></>
										}
									</div>
								</div>
							</div>

							<div className="detailsTab__section">
								<CollapsibleSection title="Contenu Français" isOpenByDefault={true}>
									<div className="modal__wysiwyg">
										<WysiwygEditor
											editorValueContent={selectedCritereContenuFR}
											setEditorValueContent={setSelectedCritereContenuFR}
										/>
									</div>
								</CollapsibleSection>
							</div>

							<div className="detailsTab__section">
								<CollapsibleSection title="Contenu Anglais" isOpenByDefault={true}>
									<div className="modal__wysiwyg">
										<WysiwygEditor
											editorValueContent={selectedCritereContenuEN}
											setEditorValueContent={setSelectedCritereContenuEN}
										/>
									</div>
								</CollapsibleSection>
							</div>
						</div>

						<div className="modal__footer">
							<div className="double_action">
								<FormButton
									type="button"
									value="Annuler"
									onClick={() => {
										setModalEditCritereIsOpen(false);
										setSelectedCommission({});
										setCommissionsOptions([]);
									}}
								/>

								<div className="button_delete">
									<FormButton
										type="submit"
										value="Supprimer"
										onClick={handleDeleteCritere}
									/>
								</div>
							</div>

							{critereSelector.critereIsLoading ?
								<Loader
									type="TailSpin"
									width={35}
									height={35}
									color="#d93943"
								></Loader>
								:
								usePermUpdateCritereDossier && <FormButton
									type="submit"
									value="Modifier"
									disabled={selectedCritereContenuFR === '' || selectedCritereContenuEN === ''}
									onClick={handleEditCritere}
								/>
							}
						</div>
					</div>
				</Modal>
			}

			{modalVisualizeCritereIsOpen &&
				<Modal
					isOpen={modalVisualizeCritereIsOpen}
					style={modalCustomStyles}
					className="ajoutCritere__modal"
				>
					<div className="modal__header">
						<h3>Visualiser le critère</h3>
					</div>

					<div className="modal__content">
						<div className="detailsTab__section">
							<div className="detailsTab__rowBGWhite">
								{selectedCritere.categorieId && commissionsOptions ?
									<div className="ajoutCritere__commission">
										<label>Mettre en place ces critères pour la commission </label>
										<strong>{selectedCommission?.nom}</strong>
										<label> soit pour tout dossier créé à partir du {selectedCommission?.dateDebutSoumission ? new Date(selectedCommission.dateDebutSoumission).toLocaleDateString() : ""}
										</label>
									</div>
									:
									<></>
								}
							</div>
						</div>

						<div className="detailsTab__section">
							<CollapsibleSection title="Contenu Français" isOpenByDefault={true}>
								<div className="modal__wysiwyg">
									<WysiwygEditor
										editorValueContent={selectedCritereContenuFR}
										setEditorValueContent={setSelectedCritereContenuFR}
										isReadonly
									/>
								</div>
							</CollapsibleSection>
						</div>

						<div className="detailsTab__section">
							<CollapsibleSection title="Contenu Anglais" isOpenByDefault={true}>
								<div className="modal__wysiwyg">
									<WysiwygEditor
										editorValueContent={selectedCritereContenuEN}
										setEditorValueContent={setSelectedCritereContenuEN}
										isReadonly
									/>
								</div>
							</CollapsibleSection>
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Retour"
							onClick={() => {
								setModalVisualizeCritereIsOpen(false);
							}}
						/>
					</div>
				</Modal>
			}
		</>

	);
}

export default CriteresDossierModal;