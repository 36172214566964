
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { DossierClient, DossierDSVDto, UpdateDossierDSVDto } from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from './dossierAction';

/** Actions */
export const requestDossierDsv = () => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_DSV_REQUEST,
		payload: {}
	}
}

export const getDossierDsv = (result: DossierDSVDto) => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_DSV_SUCCESS,
		payload: { dossierDSV: result }
	}
}

export const requestPutDossierDsv = () => {
	return {
		type: DossierActionsEnum.PUT_DOSSIER_DSV_REQUEST
	}
}

export const errorDossierDsv = (error:any) => {
	if(error.response?.exception?.message)
		toast.error(error.response.exception.message);
	else if(error.exception?.message)
		toast.error(error.exception.message);
	else
		toast.error("Impossible de mettre à jour le dossier Dsv");
	return {
		type: DossierActionsEnum.PUT_DOSSIER_DSV_ERROR
	}
}

export const errorGetDossierDsv = (error:any) => {
	if(error.response?.exception?.message)
		toast.error(error.response.exception.message);
	else if(error.exception?.message)
		toast.error(error.exception.message);
	else
		toast.error("Impossible de récupérer le dossier Dsv");
	return {
		type: DossierActionsEnum.GET_DOSSIER_DSV_ERROR
	}
}

export const putDossierDsv = (result: DossierDSVDto) => {
	return {
		type: DossierActionsEnum.PUT_DOSSIER_DSV_SUCCESS,
		payload: { dossierDSV: result }
	}
}

/** Task */
const dossierClient = new DossierClient('', axiosInstance);

export const fetchDossierDsv = (dispatch: any, dossierDd: string) => {
	return () => {
		dispatch(requestDossierDsv());
		dossierClient.getDossierDSV(dossierDd)
			.then((response: DossierDSVDto) => dispatch(getDossierDsv(response)))
			.catch((error: any) => dispatch(errorGetDossierDsv(error)))
	}
}

export const sendDossierDsv = (dispatch: any, dossierId: string, value: UpdateDossierDSVDto) => {
	return () => {
		dispatch(requestPutDossierDsv());
		dossierClient.updateDossierDSV(dossierId, value)
			.then((response: DossierDSVDto) => dispatch(putDossierDsv(response)))
			.catch((error: any) => dispatch(errorDossierDsv(error)))
	}
}
