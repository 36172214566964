import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Input from "adel-shared/dist/components/basics/Input";
import Toggle from 'adel-shared/dist/components/Toggle';
import { modalCustomStylesPage } from '../../constants/config.constant';
import Loader from 'react-loader-spinner';
import ModalDeleteMessage from './ModalDeleteMessage';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import clsx from 'clsx';
import { usePageWarningSelector } from '../../store/store-helpers';
import { MessageAlerteDto } from '../../services/generated/BackOffice-api';
import { createOrUpdateMessageAlerte, deleteMessageAlerte } from '../../store/page-warning-store/pageWarningAction';
import { useDispatch } from 'react-redux';
import useHasPermission from '../../custom-hooks/useHasPermission';

interface PageWarningProps {
	modalPageIsOpen: boolean;
	selectedMessage: MessageAlerteDto;
}

const PageWarning: React.FC<PageWarningProps> = ({
	modalPageIsOpen,
	selectedMessage
}) => {
	const [afficher, setAfficher] = useState<boolean>(false);
	const [isOpenModalDelete, openModalDelete, closeModalDelete] = useBooleanState(false);
	const [nom, setNom] = useState<string>("");
	const [message, setMessage] = useState<string>("");

	const { messages, isLoading } = usePageWarningSelector() || {};
	const dispatch = useDispatch();

	const sanitizedNom = nom.trim();
	const sanitizedMessage = message.trim();
	const isFormInvalid = sanitizedNom === '' ||
	sanitizedNom.length > 100 ||
	sanitizedMessage === '' ||
	sanitizedMessage.length > 256;

	const usePermCreateOrUpdateMessageAlerte = useHasPermission("CreateOrUpdateMessageAlerte");

	useEffect(() => {
		if (selectedMessage && Object.keys(selectedMessage).length > 0) {
			selectedMessage.message && setMessage(selectedMessage.message);
			selectedMessage.afficher !== undefined && setAfficher(selectedMessage.afficher);
			selectedMessage.nom && setNom(selectedMessage.nom);
		} else {
			setMessage('');
			setNom('');
			setAfficher(false);
		}
	}, [selectedMessage]);

	const deleteMessage = async() => {
		if(selectedMessage?.id) {
			await deleteMessageAlerte(dispatch, selectedMessage.id);
			closeModalDelete();
		}
	}

	const sendMessage = async() => {
		if(!usePermCreateOrUpdateMessageAlerte)
			return;
		let body:MessageAlerteDto = { nom, message, afficher };
		if(selectedMessage?.id)
			body = {...body, id: selectedMessage.id }
		await createOrUpdateMessageAlerte(dispatch, body);
	}


	return (<>
		{modalPageIsOpen && messages &&
			<Modal isOpen={modalPageIsOpen} style={modalCustomStylesPage} className="pageWarning__modal">
				<div className="pageVitrine__wrapper">
					<div className="modal__header">
						<h3>Administration - Gestion des messages d'alerte</h3>
					</div>
					<div className="modal__scroll">
						<div className="modal__content">
							<div className="detailsTab">
								<div className="detailsTab__row">
									<div className="detailsTab__pageTitle">
										<div className="detailsTab__pageTitleLabel">
											<span>Nom :</span>
										</div>
										<Input
											name="nom"
											type="text"
											value={nom}
											onChange={(value: string) => setNom(value) }
										/>
									</div>
									<div>
										<label>Publier le message :</label>
										<Toggle value={afficher} setCheck={setAfficher} />
									</div>
								</div>
								<div className="detailsTab__row">
									<span className="detailsTab__commentary">Note : Le nom ne sera pas affiché sur le message</span>
								</div>
								<textarea
									name={message}
									onChange={(e) => setMessage(e.target.value)}
									value={message}
								/>
								<div className={clsx("count", {"count--error":message.length > 256})}>
									{message.length}/256
								</div>
								{message.length > 256 &&
									<div className="input__errorMessage">
										Le message ne doit pas faire plus de 256 caractères.
									</div>
								}
							</div>
						</div>

						<div className="modal__footer">
							{selectedMessage?.id ? (
								<FormButton
									type="button"
									value="Supprimer"
									onClick={openModalDelete}
								/>
							) : (
								<div></div>
							)}
							{isLoading ? (
								<Loader
									type="TailSpin"
									width={35}
									height={35}
									color="#d93943"
								/>
							) : ( 
								<FormButton
									type="submit"
									value="Enregistrer"
									disabled={isFormInvalid}
									onClick={sendMessage}
								/>
							)}
						</div>
					</div>
				</div>
			</Modal>
		}
		{isOpenModalDelete &&
			<ModalDeleteMessage
				isOpen={isOpenModalDelete}
				onCancel={closeModalDelete}
				onValidate={deleteMessage}
			/>
		}
	</>);
}

export default PageWarning;
