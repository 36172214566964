import React from 'react';
import { CategorieDossier } from '../../../enums/CategorieDossier';
import InputReadOnly from './../../InputReadOnly';
const ArtisteModalInfos = ({ details, dossierCategorie, isCommissionMember }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nom", content: (details === null || details === void 0 ? void 0 : details.nom) || '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Pr\u00E9nom", content: (details === null || details === void 0 ? void 0 : details.prenom) || '-' }))),
            !isCommissionMember &&
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement(InputReadOnly, { label: "Email", content: (details === null || details === void 0 ? void 0 : details.email) || '-' })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Lieu d'habitation", content: (details === null || details === void 0 ? void 0 : details.lieuHabitation) || '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Emploi", content: (details === null || details === void 0 ? void 0 : details.emploi) || '-' }))),
            (details === null || details === void 0 ? void 0 : details.instrument) && (React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Instrument", content: details.instrument.intitule.fr }))))),
        React.createElement("div", { className: "detailsTab__row" },
            (dossierCategorie.code === CategorieDossier.Fortissimo ||
                dossierCategorie.code === CategorieDossier.SoireeArtistes) && (React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "L'artiste est-il labellisé \"génération spedidam\" ?", content: details.isGenerationSpedidam ? 'Oui' : 'Non' })))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Titulaire", content: details.isTitulaire ? 'Oui' : 'Non' }))))));
};
export default ArtisteModalInfos;
