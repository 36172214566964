import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { DossierClient, DossierNotationDto, DossierNoteDto, UpdateDossierNoteDto } from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from './dossierAction';

/** Actions */
export const requestDossierNote = () => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_NOTE_REQUEST,
		payload: {}
	}
}

export const getDossierNote = (note: UpdateDossierNoteDto) => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_NOTE_SUCCESS,
		payload: { dossierNote: note }
	}
}

export const requestPutDossierNote = () => {
	return {
		type: DossierActionsEnum.PUT_DOSSIER_NOTE_REQUEST
	}
}

export const putDossierNote = (note: UpdateDossierNoteDto) => {
	return {
		type: DossierActionsEnum.PUT_DOSSIER_NOTE_SUCCESS,
		payload: { dossierNote: note }
	}
}

/** Task */
const dossierClient = new DossierClient('', axiosInstance);

export const fetchDossierNote = (dispatch: any, value: string) => {
	return () => {
		dispatch(requestDossierNote());
		dossierClient.getDossierNote(value)
			.then((response: DossierNoteDto) => dispatch(getDossierNote(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer la note");
			})
	}
}

export const sendDossierNote = (dispatch: any, dossierId: string, value: UpdateDossierNoteDto) => {
	return () => {
		dispatch(requestPutDossierNote());
		dossierClient.updateDossierNote(dossierId, value)
			.then(() => {
				dispatch(putDossierNote(value));
				toast.success("La note a bien été mise à jour");
			})
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de mettre à jour la note");
			})
	}
}

export const getDossierNotation = async (
	dispatch: any,
	dossierId: string
) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_NOTATION_REQUEST });
	try {
		let response:DossierNotationDto = await dossierClient.getDossierNotation(dossierId);
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_NOTATION_SUCCESS, payload: {dossierNotation: response} });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_NOTATION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les notations");
		throw error;
	}
}
