import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { useTable, useSortBy } from 'react-table';
import Input from 'adel-shared/dist/components/basics/Input';
import ModalEditMembre from './ModalEditMembre';
import Pagination from 'adel-shared/dist/components/basics/Pagination';
import useValidation from '../../custom-hooks/useValidation';
import { fetchCommissionMembre, fetchCommissionMembres } from '../../store/commission-membre-store/actions/commissionMembreActions';
import { toast } from 'react-toastify';
import { useCommissionMembreDispatcher, useCommissionMembreSelector } from '../../store/store-helpers';
import { CommissionMemberDto } from '../../services/generated/BackOffice-api';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import useHasPermission from '../../custom-hooks/useHasPermission';
import { paginationNumbers } from '../../constants/config.constant';

interface GestionMembresProps extends RouteComponentProps {
}

const GestionMembres: React.FunctionComponent<GestionMembresProps> = () => {
	const { t } = useTranslation();
	const commissionMembreDispatch = useCommissionMembreDispatcher();
	const [modalEdition, setModalEdition] = useState<boolean>(false);
	const [membre, setMembre] = useState<CommissionMemberDto | undefined>(undefined);
	const { getRootValidator } = useValidation();
	const membreValidator = getRootValidator(membre ? "UpdateCommissionMemberDto" : "CreateCommissionMemberDto");
	const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");
	const validators = {...membreValidator, ...adresseValidator};
	const dispatch = useDispatch();
	const commissionMembreSelector = useCommissionMembreSelector();

	/** Table */
	const [data, setData] = useState<any[]>([]);
	const [initalSort, setInitialSort] = useState<string>("Nom,EstActif,Groupe");
	const [sort, setSort] = useState<string>(initalSort);

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	/** Filtres */
	const [searchFiltre, setSearchFiltre] = useState<string>('');

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Nom", accessor: "Nom", sortType: "basic" },
					{ Header: "Prénom", accessor: "Prenom", sortType: "basic" },
					{ Header: "Statut", accessor: "EstActif", sortType: "basic" },
					{ Header: "Groupe", accessor: "Groupe", sortType: "basic" },
					{ Header: "Interne", accessor: "EstInterne", sortType: "basic" },
					{ Header: "Email", accessor: "Email", sortType: "basic" },
					{ Header: "", accessor: "Actions", disableSortBy: true,
						Cell: (value: any) => (
							<span className="commission__tableButtons">
								<div className="commission__tableIcon" onClick={() => { editMembre(value.cell.row.values.Id) }}>
									<i className="far fa-edit"></i>
								</div>
							</span>
					)},
					{ Header: "Id", accessor: "Id" }
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	useEffect(() => {
		(async() => {
			try {
				const result = await fetchCommissionMembres(
					commissionMembreDispatch,
					false,
					searchFiltre,
					sort,
					page,
					pageSize
				)();

				if(!("commissionMembres" in result.payload)) {
					return;
				}

				const commissionMembresPage = result.payload.commissionMembres;

				const data = commissionMembresPage.items?.map(membre => ({
					Nom: membre.nom,
					Prenom: membre.prenom,
					EstActif: <span className={clsx('table__statutItem', {
						'table__statut--active': membre.estActif,
						'table__statut--inactif': !membre.estActif
					})}>
						{membre.estActif ? t(`gestion-membres.status.active`) : t(`gestion-membres.status.inactif`)}
					</span>,
					Groupe: membre.groupe,
					EstInterne: <span className={clsx('table__check', {
						'table__check--valid': membre.estInterne,
						'table__check--invalid': !membre.estInterne
					})}>
						<i className={clsx('fas', {
							'fa-check': membre.estInterne,
							'fa-times': !membre.estInterne
						})}></i>
					</span>,
					Email: membre.email,
					Id: membre.id
				})) || [];

				setData(data);
				setIsFirst(commissionMembresPage.isFirst ?? false);
				setIsLast(commissionMembresPage.isLast ?? false);
				setHasNext(commissionMembresPage.hasNext ?? false);
				setHasPrevious(commissionMembresPage.hasPrevious ?? false);
				setTotalItemCount(commissionMembresPage.totalItemCount ?? 0);
				setTotalPageCount(commissionMembresPage.totalPageCount ?? 0);
			} catch(error) {
				toast.error(t('common.errors.fetch'));
			}
		})();
	}, [page, pageSize, searchFiltre, sort, commissionMembreSelector.commissionMembre]);

	const onChangeSort = (value: any) => {
		setInitialSort('');

		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		if(initalSort && !sortBy.length){
			return;
		}

		onChangeSort(sortBy);
	}, [onChangeSort, sortBy, initalSort]);

	/** Edition commission */
	const editMembre = (id:string) => {
		setModalEdition(true);
		(async() => {
			const result = await fetchCommissionMembre(dispatch, id)();
			
			if('commissionMembre' in result.payload) {
				setMembre(result.payload.commissionMembre);
			}
		})();
	}

	const handleCreate = useCallback(() => {
		setModalEdition(true);
		setMembre(undefined);
	}, [setModalEdition, setMembre]);

	const handleModalClose = useCallback(() => {
		setModalEdition(false);
	}, [setModalEdition])

	const handleSearch = debounce((value: string) => {
		setSearchFiltre(value);
		setPage(1);
	}, 500);



	/** Permissions */
	const usePermCreateCommissionMember = useHasPermission("CreateCommissionMember");

	return (
		<div className="commission">
			<div className="commission__header--noGraph"></div>
			<div className="commission__content">
				<div className="commission__contentHeader">
					<h1 className="commission__contentHeaderTitle">Membres commissions</h1>
					{usePermCreateCommissionMember &&
					<div className="commission__contentHeaderButton" onClick={handleCreate}>
						<label>Créer un nouveau membre</label>
						<button className="categorieFormeJuridique-header__icon">
							<i className="far fa-plus"></i>
						</button>
					</div>}
				</div>
				<div className="commission__filtres">
					<div className="filtres__item">
						<div className="filtres__search">
							<i className="fas fa-search"></i>
							<Input
								autoComplete="off"
								name="recherche"
								className="filter__input"
								placeHolder="Recherche"
								type="text"
								maxLength={120}
								onChange={handleSearch}
							/>
						</div>
					</div>
				</div>
				{data.length > 0 ? (
					<div className="commission__table commission__membresTable">
						<Table
							data={data}
							getTableProps={getTableProps}
							getTableBodyProps={getTableBodyProps}
							headerGroups={headerGroups}
							rows={rows}
							prepareRow={prepareRow}
							styles={styleTable.page}
						/>
						<Pagination
							hasNext={hasNext}
							hasPrevious={hasPrevious}
							isFirst={isFirst}
							isLast={isLast}
							totalPageCount={totalPageCount}
							totalItemCount={totalItemCount}
							page={setPage}
							pageSize={setPageSize}
							initPageSize={pageSize}
							initPage={page}
							pageNumbers={paginationNumbers}
						/>
					</div>
				) : (
					<p className="commission__noResults">Aucun résultats</p>
				)}
				{modalEdition &&
					<ModalEditMembre
						isOpen={modalEdition}
						membre={membre}
						onClose={handleModalClose}
						validator={validators}
					/>
				}
			</div>
		</div>
	);
}

export default GestionMembres;