import React from "react";
import ModalValidateCancel from '../../basics/ModalValidateCancel';

interface PriseEnChargeModalProps {
    isOpen: boolean;
    onCancel: () => void;
    onValidate: () => void;
    isLoading?: boolean;
}

const PriseEnChargeModal: React.FunctionComponent<PriseEnChargeModalProps> = ({
    isOpen,
    onCancel,
    onValidate,
    isLoading
}) => {
    return (
        <ModalValidateCancel
            isOpen={isOpen}
            onCancel={onCancel}
            onValidate={onValidate}
            isLoading={isLoading}
            title="PRISE EN CHARGE DU DOSSIER"
            cancelButtonLabel="Non"
            validateButtonLabel="Oui"
        >
            <div className="modal__item">
                <p>Souhaitez-vous prendre en charge ce dossier ?</p>
            </div>
        </ModalValidateCancel>
    );
}

export default PriseEnChargeModal;