import { Action } from 'redux';
import { TemplateState } from '../states/templateState';
import { ModeleMessageClient, ModeleMessageDto } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum TemplateActionsEnum {
	GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST",
	GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS",
	GET_TEMPLATES_ERROR = "GET_TEMPLATES_ERROR",
	POST_TEMPLATE_REQUEST = "POST_TEMPLATE_REQUEST",
	POST_TEMPLATE_SUCCESS = "POST_TEMPLATE_SUCCESS",
	POST_TEMPLATE_ERROR = "POST_TEMPLATE_ERROR",
	DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST",
	DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS",
	DELETE_TEMPLATE_ERROR = "DELETE_TEMPLATE_ERROR",
	PUT_TEMPLATE_REQUEST = "PUT_TEMPLATE_REQUEST",
	PUT_TEMPLATE_SUCCESS = "PUT_TEMPLATE_SUCCESS",
	PUT_TEMPLATE_ERROR = "PUT_TEMPLATE_ERROR",
}

export interface DispatchTemplateAction extends Action<TemplateActionsEnum> {
	payload: TemplateState;
}

// Get all templates
export const requestTemplates = () => {
	return {
		type: TemplateActionsEnum.GET_TEMPLATES_REQUEST
	}
}
export const getTemplates = (result: ModeleMessageDto[]) => {
	return {
		type: TemplateActionsEnum.GET_TEMPLATES_SUCCESS,
		payload: { templates: result }
	}
}
export const errorTemplates = () => {
	return {
		type: TemplateActionsEnum.GET_TEMPLATES_ERROR
	}
}


// Send template
export const sendTemplate = () => {
	return {
		type: TemplateActionsEnum.POST_TEMPLATE_REQUEST

	}
}
export const savedTemplate = (result: ModeleMessageDto) => {
	return {
		type: TemplateActionsEnum.POST_TEMPLATE_SUCCESS,
		payload: { template: result }
	}
}
export const errorSavedTemplate = () => {
	return {
		type: TemplateActionsEnum.POST_TEMPLATE_ERROR
	}
}


// Delete template
export const sendDeleteTemplate = () => {
	return {
		type: TemplateActionsEnum.DELETE_TEMPLATE_REQUEST
	}
}
export const deletedTemplate = (result: string) => {
	return {
		type: TemplateActionsEnum.DELETE_TEMPLATE_SUCCESS,
		payload: { id: result }
	}
}
export const errorDeletedTemplate = () => {
	return {
		type: TemplateActionsEnum.DELETE_TEMPLATE_ERROR
	}
}



// Modif template
export const sendModifTemplate = () => {
	return {
		type: TemplateActionsEnum.PUT_TEMPLATE_REQUEST
	}
}
export const modifiedTemplate = (result: string) => {
	return {
		type: TemplateActionsEnum.PUT_TEMPLATE_SUCCESS,
		payload: { template: result }
	}
}
export const errorModifiedTemplate = () => {
	return {
		type: TemplateActionsEnum.PUT_TEMPLATE_ERROR
	}
}



/** Tasks */
const modeleMessageClient = new ModeleMessageClient('', axiosInstance);

export const fetchTemplates = (
	dispatch: any,
	type?: any
) => {
	return () => {
		dispatch(requestTemplates());
		modeleMessageClient.getModelesMessage()
			.then((response: ModeleMessageDto[]) => dispatch(getTemplates(response)))
			.catch((error: any) => {
				dispatch(errorTemplates);
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer le template de message");
		})
	}
}

export const saveTemplate = (
	dispatch: any,
	template: ModeleMessageDto
) => {
	return () => {
		dispatch(sendTemplate());
		modeleMessageClient.createModeleMessage(template)
			.then((response: any) => dispatch(savedTemplate(response)))
			.catch((error: any) => {
				dispatch(errorSavedTemplate());
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de sauvegarder le template");
		})
	}
}

export const deleteTemplate = (
	dispatch: any,
	templateId: string
) => {

	return () => {
		dispatch(sendDeleteTemplate());
		modeleMessageClient.deleteModeleMessage(templateId)
			.then(dispatch(deletedTemplate(templateId)))
			.catch((error: any) => {
				dispatch(errorDeletedTemplate());
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer le template de message");
		})
	}
}


// updateModeleMessage

export const modifTemplate = (
	dispatch: any,
	templateId: string,
	template: ModeleMessageDto
) => {
	return () => {
		dispatch(sendModifTemplate());
		modeleMessageClient.updateModeleMessage(templateId, template)
			.then((response: any) => dispatch(modifiedTemplate(response)))
			.catch((error: any) => {
				dispatch(errorModifiedTemplate());
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible mettre à jour le template de message");
		})
	}
}