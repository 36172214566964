import { PageVitrineDto } from "../../../services/generated/BackOffice-api";


export interface PageVitrineState {
	pagesIsLoading: boolean;
	pageIsLoading: boolean;
	pages?: PageVitrineDto[];
	page?: PageVitrineDto;
	newPage?: PageVitrineDto;
	fileIsLoading: boolean;
	file?: string;
}

export const initialPageVitrineState: PageVitrineState = {
	pagesIsLoading: false,
	pageIsLoading: false,
	pages: [],
	page: {},
	newPage: {},
	fileIsLoading: false,
	file: ''
};