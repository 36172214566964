import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { DossierClient } from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from './dossierAction';

/** Task */
const dossierClient = new DossierClient('', axiosInstance);

export const fetchDossierAdditionalInfo = (dispatch: Dispatch, dossierId: string) => {
    dispatch({ type: DossierActionsEnum.GET_DOSSIER_ADDITIONALINFO_REQUEST });
    return dossierClient.getDossierAdditionalInfo(dossierId).then(result => {
        dispatch({ type: DossierActionsEnum.GET_DOSSIER_ADDITIONALINFO_SUCCESS, payload: { dossierAdditionalInfo: result } })
        return result;
    }).catch(error => {
        dispatch({ type: DossierActionsEnum.GET_DOSSIER_ADDITIONALINFO_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les infos additionelles");
        throw error;
    })
};