import React from 'react'
import background1 from '../../../assets/pictos/commission.png';
import background2 from '../../../assets/pictos/projet.png';
import background3 from '../../../assets/pictos/budget.png';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import moment from 'moment';
import { DossierDetailsDto } from '../../../services/generated/BackOffice-api';

interface infocardProps {
    num: number;
    details?: DossierDetailsDto;
    montantAttribue?: number;
}

const SyntheseInfoCard = ({num, details,montantAttribue}: infocardProps) => {

    const usePermGetDossierDetails = useHasPermission("GetDossierDetails");

    let dateDebut = details?.dateDebut;
    let dateFin = details?.dateFin;
    // let budget = details?.budgetViewModel?.montantMaxAide ?? 0;
    let budget = (montantAttribue) 
                ? montantAttribue
                : details?.budget?.financements?.filter(f => f.champ == 'montantDemandeSPEDIDAM')[0].montant ?? 0;
    let commissionDate = details?.commission?.dateDebut;
    let categorie = details?.categorie?.nom;

    let imgSrc;
    let label;
    let contenu;
    switch (num) {
      case 1:
        imgSrc = background1;
        label = 'COMMISSION';
        contenu = (commissionDate) ? moment(commissionDate).format("DD/MM/YYYY") : '-'
        break;
      case 2:
        imgSrc = background2;
        label = 'PROJET';
        contenu = (dateDebut && dateFin) ? 'du '+ moment(dateDebut).format("DD/MM/YYYY") + '\n au ' + moment(dateFin).format("DD/MM/YYYY") : '-'
        break;
      default:
        imgSrc = background3;
        label = (montantAttribue) ?'MONTANT ATTRIBUE' : 'MONTANT DEMANDE';
        contenu = (budget == 0) ? '-' : Intl.NumberFormat('fr-FR',{style : 'currency', currency: 'EUR'}).format(budget);
    }


  
    return (
        <div className="synthese_infocard">
            <div className='infocard_details'>
                <div className='infocard_header'>
                    <div className="infocard_icon">
                        <img src={imgSrc} alt="img" />
                    </div>
                    <div className='infocard_label'>
                        {label}
                    </div>
                </div>
                <div className='infocard_contenu'>
                    {usePermGetDossierDetails &&
                        contenu
                    }
                </div>
                <div className="infocard_separator"></div>
            </div>
            </div>
    );
  };

export default SyntheseInfoCard