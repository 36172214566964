import React, { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import Pagination from "adel-shared/dist/components/basics/Pagination";
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { RouteComponentProps } from '@reach/router';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { GenerationSpedidamClient, GenerationSpedidamDto } from '../../../services/generated/BackOffice-api';
import { fetchGenerationsSpedidam } from '../../../store/gestion-entite-store/actions/generationSpedidiamAction';
import { useGeneralSpedidamSelector, useUserSelector } from '../../../store/store-helpers';
import GenerationSpedidamModal from './GenerationSpedidamModal';
import { paginationNumbers } from '../../../constants/config.constant';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

interface GnenerationSpedidamListProps extends RouteComponentProps {
	modalAddIsOpen: boolean;
	setModalAddIsOpen: Dispatch<SetStateAction<boolean>>;
	typeFilter: string;
	typeMusicalOptions: any
}

const GnenerationSpedidamList: React.FC<GnenerationSpedidamListProps> = ({
	modalAddIsOpen,
	setModalAddIsOpen,
	typeFilter,
	typeMusicalOptions
}) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const generationsSelector = useGeneralSpedidamSelector();

	const [sortValue, setSortValue] = useState<string>();
	const [sortDirection, setSortDirection] = useState<string>();


	/** Permissions */
	const usePermDeactivateGenerationSpedidam = useHasPermission("DeactivateGenerationSpedidam");

	//Table value
	const [generations, setGenerations] = useState<GenerationSpedidamDto[]>([]);
	const [selectedGeneration, setSelectedGeneration] = useState<GenerationSpedidamDto>({});
	const [selectedGenerationId, setSelectedGenerationId] = useState<string>("");
	const [data, setData] = useState<any[]>([]);
	const [modalDeleteGenerationSpedidamIsOpen, setModalDeleteGenerationSpedidamIsOpen] = useState<boolean>(false);
	const [modalEditGenerationSpedidamIsOpen, setModalEditGenerationSpedidamIsOpen] = useState<boolean>(false);

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	//selector
	const userSelector = useUserSelector();
	const generationClient = new GenerationSpedidamClient('', axiosInstance);

	/** Get the data for the table */
	useEffect(() => {
		const data = generations?.map(generation => ({
			id: generation.id,
			nom: generation.nom,
			prenom: generation.prenom,
			montantEnveloppe: generation.montantEnveloppe,
			isActive: generation.isActive,
			styleMusical: generation.styleMusical?.intitule?.fr,
			styleMusicalId: generation.styleMusical?.id,
			commission: generation.commission?.nom,
			commissionValues:{ label: generation.commission?.nom, value: generation.commission},
			commissionId: generation.commission?.id,
			Actions: 'Action 1 + Action 2'
		}));
		setData(data);

	}, [generations]);

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchGenerationSpedidam = useMemo(() => _.debounce((page: number, pageSize: number, sortValue: any, sortDirection: any) => {
		fetchGenerationsSpedidam(dispatch, sortValue, sortDirection, page, pageSize)().then((result: any) => {
			if (result?.payload?.generations?.number > result?.payload?.generations?.totalPageCount)
				setPage(1);
			setGenerations(result?.payload.generations.items);
			setHasNext(result?.payload.generations.hasNext ? true : false);
			setHasPrevious(result?.payload.generations.hasPrevious);
			setIsFirst(result?.payload.generations.isFirst);
			setIsLast(result?.payload.generations.isLast);
			setTotalPageCount(result?.payload.generations.totalPageCount);
			setTotalItemCount(result?.payload.generations.totalItemCount);
		});
	}, 1000), []);

	const refreshGenerationSpedidam = () => {
		debounceFetchGenerationSpedidam(page, pageSize, sortValue, sortDirection)
	}

	const deleteGenerationSpedidam = (id: string) => {		
		setSelectedGenerationId(id)
		setModalDeleteGenerationSpedidamIsOpen(true)
	}

	const handleDeactivate = (id: string) => {
		generationClient.deactivateGenerationSpedidam(id).then(res => {
			setGenerations(prevState => {
				const newState = prevState.map(obj => {
					if (obj.id === id) {
						return { ...obj, isActive: false };
					}
					return obj;
				});
				return newState;
			});
		})
		toast.success("L'artise a bien été désactivé")
	}

	const updateGenerationSpedidam = (generationArtiste: GenerationSpedidamDto) => {
		setSelectedGeneration(generationArtiste)
		setModalEditGenerationSpedidamIsOpen(true)
	}
	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						accessor: "isActive",
						disableSortBy: true,
						Cell: (props: any) => {

							return (<></>)
						}
					},
					{
						Header: t('entity-management.last-name'),
						accessor: "nom",
						disableSortBy: true,
						cell: (props: any) =>
							props.cell.row.values.IsActive ? (
								<><i className="fa fa-circle"></i>{props.cell.row.values.Nom}</>
							) : props.cell.row.values.Nom

					},
					{
						Header: t('entity-management.first-name'),
						accessor: "prenom",
						disableSortBy: true,
					},
					{
						Header: t('entity-management.montant-enveloppe'),
						accessor: "montantEnveloppe",
						disableSortBy: true,
					},
					{
						Header: t('entity-management.style-musique'),
						accessor: "styleMusical",
						disableSortBy: true,
					},
					{
						Header: t('entity-management.commission'),
						accessor: "commission",
						disableSortBy: true,
					},
					{
						Header: "",
						accessor: "actions",
						disableSortBy: true,
						Cell: (props: any) => {														
							return (
								<>
									<span>
										{props.cell.row.values.isActive && <i title="Désactiver" className="fas fa-times-circle" style={{ fontSize: "18", color: '#d93943', cursor: 'pointer' }} aria-hidden="true" onClick={() => {
											handleDeactivate(props.cell.row.original.id)
										}}></i>}

									</span>
									<span>
										<i
											title="Supprimer"
											onClick={() => deleteGenerationSpedidam(props.cell.row.original.id as string)}
											className="far fa-trash-alt gestionCategoriesDossier__icon"
										></i>
									</span>
									{props.cell.row.values.isActive && <span>
										<i
											title="Modifier"
											onClick={() => updateGenerationSpedidam(props.cell.row.original as GenerationSpedidamDto)}
											className="far fa-edit gestionCategoriesDossier__icon"
										></i>
									</span>}
								</>

							)
						}
					},
					{
						Header: "Id",
						accessor: "Id"
					}
				]
			}
		];
	}, [usePermDeactivateGenerationSpedidam]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id"],
				sortBy: [
					{
						id: "Nom",
						desc: false
					}
				]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSortValue(value[0].id);
				setSortDirection("desc")
			} else {
				setSortValue(value[0].id);
				setSortDirection("asc")
			}
		} else {
			setSortValue("");
			setSortDirection("asc")
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	useEffect(() => {
		setPage(1)
	}, [pageSize, typeFilter])

	//get generation spedidam liste
	useEffect(() => {
		if (userSelector && userSelector.isConnected) {
			debounceFetchGenerationSpedidam(page, pageSize, sortValue, sortDirection)
		}
	}, [pageSize, page, sortValue, sortDirection, fetchGenerationsSpedidam, userSelector.isConnected]);

	return (
		<>
			{generationsSelector.generationIsLoading ?
				<div className="loaderBlock">
					<Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					/>
				</div>
				:
				<div>
					<Table
						data={data}
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						headerGroups={headerGroups}
						rows={rows}
						prepareRow={prepareRow}
						styles={styleTable.page}
					/>

					<Pagination
						hasNext={hasNext}
						hasPrevious={hasPrevious}
						isFirst={isFirst}
						isLast={isLast}
						totalPageCount={totalPageCount}
						totalItemCount={totalItemCount}
						page={setPage}
						pageSize={setPageSize}
						initPageSize={pageSize}
						initPage={page}
						pageNumbers={paginationNumbers}
					/>
				</div>}
			<GenerationSpedidamModal
				modalAddIsOpen={modalAddIsOpen}
				setModalAddIsOpen={setModalAddIsOpen}
				selectedGenerationId={selectedGenerationId}
				refreshGenerationSpedidam={refreshGenerationSpedidam}
				typeMusicalOptions={typeMusicalOptions}
				generations={generations}
				setGenerations={setGenerations}
				modalDeleteGenerationSpedidamIsOpen={modalDeleteGenerationSpedidamIsOpen}
				setModalDeleteGenerationSpedidamIsOpen={setModalDeleteGenerationSpedidamIsOpen}
				selectedGeneration={selectedGeneration}
				modalEditGenerationSpedidamIsOpen={modalEditGenerationSpedidamIsOpen}
				setModalEditGenerationSpedidamIsOpen={setModalEditGenerationSpedidamIsOpen}
			/>
		</>
	);
}

export default GnenerationSpedidamList;