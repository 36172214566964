import React, { FunctionComponent, useState, useMemo } from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { Link } from '@reach/router';
import moment from 'moment';
import { useDossierSelector } from '../../../store/store-helpers';

interface InformationsDernierDossierProps {
}

const InformationsDernierDossier: FunctionComponent<InformationsDernierDossierProps> = ({
}) => {
	const { dossierAidesHistory } = useDossierSelector();

    return (<>
        <div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<div className="inputReadOnly">
						<label>Numéro</label>
						<p><a href={`/Dossier/${dossierAidesHistory?.dernierDossierId}`} target="_blank" rel="noopener noreferrer">{dossierAidesHistory?.numero || '-'}</a></p>
					</div>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<div className="inputReadOnly">
						<label>Nom</label>
						<p><a href={`/Dossier/${dossierAidesHistory?.dernierDossierId}`} target="_blank" rel="noopener noreferrer">{dossierAidesHistory?.nom || '-'}</a></p>
					</div>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Dates de commission"
						content={`Du ${dossierAidesHistory?.dateDebutCommission ? moment(dossierAidesHistory?.dateDebutCommission).format("DD/MM/YYYY") : "-"}
								au ${dossierAidesHistory?.dateFinCommission ? moment(dossierAidesHistory?.dateFinCommission).format("DD/MM/YYYY") : "-"}`}
					/>
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant accordé"
						content={`${dossierAidesHistory?.montantAccorde ? formatNumber(dossierAidesHistory?.montantAccorde) : "-"} €`}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant versé"
						content={`${dossierAidesHistory?.montantVerse ? formatNumber(dossierAidesHistory?.montantVerse) : '-'} €`}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant réaffecté"
						content={`${dossierAidesHistory?.montantReaffecte ? formatNumber(dossierAidesHistory?.montantReaffecte) : '-'} €`}
					/>
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Base de calcul"
						content={dossierAidesHistory?.baseCalculPrevisionnelle?.toString() || '-'}
					/>
				</div>
			</div>
		</div>
    </>);
}

export default InformationsDernierDossier;