import React from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useDossierSelector } from '../../../store/store-helpers';
import { formatNumber } from 'adel-shared/dist/utils/functions';


const DossierAccepte: React.FunctionComponent = () => {
    const {
        dossierAdditionalInfo: {
            dernierDossierAccepte: dossier = {}
        } = {}
    } = useDossierSelector();

    return (
        <div className="sideInfo">
            <div className="sideInfo__header2">
                <h3>Dernier dossier accepté</h3>
            </div>

			<div className="sideInfo__item">
                <InputReadOnly
                    label="N° dossier"
                    content={dossier.numero || '-'}
                    theme="light"
                />

                <InputReadOnly
                    label="Nom du dossier"
                    content={dossier.nom || '-'}
                    theme="light"
                />

                <InputReadOnly
                    label="Montant attribué"
                    content={`${formatNumber(dossier.montantAttribue)} €` || '-'}
                    theme="light"
                />

                <InputReadOnly
                    label="Solde de dossier"
                    content={`${formatNumber(dossier.solde)} €` || '-'}
                    theme="light"
                />

                <InputReadOnly
                    label="Réaffectation"
                    content={`${formatNumber(dossier.reaffectation)} €` || '-'}
                    theme="light"
                />

                <InputReadOnly
                    label="% MS réalisée"
                    content={dossier.pourcentageRealise ? `${Math.round(dossier.pourcentageRealise * 100) / 100} %` : '-'}
                    theme="light"
                />
            </div>
        </div>
    );
}

export default DossierAccepte;