import React from 'react';
import { useDossierSelector } from '../../../../store/store-helpers';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { formatNumber, formaterDate } from 'adel-shared/dist/utils/functions';
interface SyntheseProps {
}

const Synthese: React.FC<SyntheseProps> = ({ }) => {
	const { dossierSynthese } = useDossierSelector();

	return (<>
		<div className="detailsTab__row detailsTab__row--start">
			<div className="detailsTab__column">
				<table className="budget__table">
					<tbody>
						<tr>
							<td>Montant demandé</td>
							<td>{dossierSynthese?.montantDemande && formatNumber(dossierSynthese.montantDemande) || '-'} €</td>
						</tr>
						<tr>
							<td>Montant attribué</td>
							<td>{dossierSynthese?.montantAttribue && formatNumber(dossierSynthese.montantAttribue)|| '-'} €</td>
						</tr>
						<tr>
							<td>Pourcentage aide en vigueur</td>
							<td>{dossierSynthese?.pourcentageAide && formatNumber(dossierSynthese.pourcentageAide*100) || '-'} %</td>
						</tr>
						{dossierSynthese?.montantARembourser ?
							<tr>
								<td>Montant à rembourser</td>
								<td>{dossierSynthese.montantARembourser && formatNumber(dossierSynthese.montantARembourser)|| '-'} €</td>
							</tr> : <tr><td></td><td></td></tr>
						}
						{dossierSynthese?.montantRembourse ?
							<tr>
								<td>Montant remboursé</td>
								<td>{dossierSynthese.montantRembourse && formatNumber(dossierSynthese.montantRembourse)|| '-'} €</td>
							</tr> : <tr><td></td><td></td></tr>
						}
						<tr>
							<td>Montant calculé</td>
							<td>{dossierSynthese?.montantCalcule && formatNumber(dossierSynthese.montantCalcule)|| '-'} €</td>
						</tr>
						<tr>
							<td>SOLDE</td>
							<td>{dossierSynthese?.solde && Number(dossierSynthese.solde)|| '-'} €</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="detailsTab__column">
				<table className="budget__table">
					<tbody>
						<tr>
							<td>Date de soumission du dossier</td>
							<td>{dossierSynthese?.dateSoumission ? formaterDate(dossierSynthese?.dateSoumission) : '-'}</td>
						</tr>
						<tr>
							<td>Date de fin de commission</td>
							<td>{dossierSynthese?.dateFinCommission ? formaterDate(dossierSynthese?.dateFinCommission) : '-'}</td>
						</tr>
						<tr>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>Date de la demande</td>
							<td>{dossierSynthese?.dateDemande ? formaterDate(dossierSynthese?.dateDemande) : '-'}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		{dossierSynthese?.penalite &&
			<div className="detailsTab__row">
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Pénalité"
							content={`${dossierSynthese.penalite}` || '-'}
						/>
					</div>
				</div>
			</div>
		}
	</>);
}

export default Synthese;