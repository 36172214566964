import React, { ReactNode } from "react";
import clsx from 'clsx';

interface DisclaimerProps {
    children: ReactNode;
    className?: string;
}

const Disclaimer: React.FunctionComponent<DisclaimerProps> = ({
    children,
    className
}) => {
    return (
        <div className={clsx('disclaimer-container', className)}>
            <div className="disclaimer-content">
                <i className="fas fa-exclamation-triangle"></i>
                <div className="disclaimer__content">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Disclaimer;