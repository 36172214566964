import { useEffect, useState } from 'react';
import { useUserSelector } from '../store/store-helpers';

const useHasPermission = (action: string) => {
    const {
        user = {},
        permissions = {}
    } = useUserSelector();
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        if(!permissions[action]) {
            return;
        }

        if(permissions[action].length) {
            setHasPermission(permissions[action].some(x => user.roles?.includes(x)) || false);
        } else {
            setHasPermission(true);
        }
    }, [
        permissions,
        user.roles
    ]);

    return hasPermission;
};

export default useHasPermission;