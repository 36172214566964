
import { StructureClient } from '../../../services/generated/BackOffice-api';
import { StructureActionsEnum } from './structureAction';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

/** Actions */
export const requestStructureHistory = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_HISTORY_REQUEST,
		payload: {}
	}
}

export const getStructureHistory = (result: number) => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_HISTORY_SUCCESS,
		payload: { structureHistory: result }
	}
}


/** Task */
const structureClient = new StructureClient('', axiosInstance);

export const fetchStructureHistory = (
	dispatch: any,
	structureId: string,
	sorts: string,
	page: number = 1,
	pageSize: number = 10
) => {

	const filters = '';
	const disablePagination = false;

	return () => {
		dispatch(requestStructureHistory());
		return structureClient.getStructureHistory(filters, sorts, page, pageSize, disablePagination, structureId)
			.then((response: any) => dispatch(getStructureHistory(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer l'historique");
			});
	}
}

