import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';

interface ModalCommentaireProps extends RouteComponentProps {
	id?: string;
	setOpenModal: (value: boolean) => void;
	openModal: boolean;
	setCommentToSend:(id?:string, value?:string) => void;
	defaultValue:string;
}

const ModalCommentaire: React.FunctionComponent<ModalCommentaireProps> = ({
	id,
	openModal,
	setOpenModal,
	setCommentToSend,
	defaultValue = ''
}) => {
	const [commentaire, setCommentaire] = useState<string>('');

	useEffect(() => {
		setCommentaire(defaultValue);
	}, [defaultValue]);

	const handleAddClick = () => {
		setCommentToSend(id, commentaire);
		setOpenModal(false);
	};

	const handleCancelClick = () => {
		setOpenModal(false);
		setCommentaire(defaultValue);
	};

	return (
		<Modal
			isOpen={openModal}
			style={modalCustomStyles}
			className="normal__modal membresModal"
		>
			<>
				<div className="modal__header">
					<h3>Ajout d'un commentaire</h3>
				</div>
				<div className="modal__content">
					<div className="input">
						<div >
							<textarea 
								onChange={(e) => setCommentaire(e.target.value)}
								className="textarea"
								value={commentaire}
							></textarea>
						</div>
					</div>
				</div>
				<div className="modal__footer">
					<FormButton
						type="button"
						value="Annuler"
						onClick={handleCancelClick}
					/>
					<FormButton
						type="submit"
						value="Ajouter"
						onClick={handleAddClick}
					/>
				</div>
			</>
		</Modal>
	);
}

export default ModalCommentaire;