import { CompteBancaireDto } from '../../../services/generated/BackOffice-api';

export interface CompteBancaireState {
	comptesBancaires?: CompteBancaireDto[];
	compteBancaire?: CompteBancaireDto;
	compteBancaireId?: string;
}

export const initialCompteBancaireState: CompteBancaireState = {
	comptesBancaires: [],
	compteBancaire: {},
	compteBancaireId: ''
};