import React from "react";
const Toggle = ({ label, value = false, disabled = false, name, setCheck }) => {
    const updateValue = () => {
        !disabled && setCheck(!value);
    };
    return (React.createElement("div", { className: "toggle" },
        label && React.createElement("label", null, label),
        React.createElement("input", { type: "checkbox", checked: value, onChange: updateValue, name: name, disabled: disabled }),
        React.createElement("div", { className: "toggle__item", onClick: updateValue },
            React.createElement("div", { className: "toggle__item-button" },
                React.createElement("i", { className: `fas fa-${value ? "circle-check" : "x"}` })))));
};
export default Toggle;
