import { MessageAlerteDto } from '../../services/generated/BackOffice-api';

export interface PageWarningState {
	isLoading: boolean;
	messages?: MessageAlerteDto[];
	message?: MessageAlerteDto;
	newMessage?: MessageAlerteDto;
	messageId?:string;
}

export const initialPageWarningState: PageWarningState = {
	isLoading: false,
	messages: [],
	message: {},
	newMessage: {},
	messageId: ''
};