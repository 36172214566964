import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Input from 'adel-shared/dist/components/basics/Input';
import InputCalendar from 'adel-shared/dist/components/basics/InputCalendar';
import InputRadioYesNo from 'adel-shared/dist/components/basics/InputRadioYesNo';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { fetchCategoriesDossier } from '../../../store/categorie-dossier-store/actions/categorieDossierAction';
import { useCategorieDossierSelector } from '../../../store/store-helpers';
import { useCommissionSelector } from '../../../store/store-helpers';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { GroupeCommission, CreateOrUpdateCommissionDto } from '../../../services/generated/BackOffice-api';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { navigate, RouteComponentProps } from '@reach/router';
import { updateCommission } from '../../../store/commission-store/actions/commissionActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { normalizeDate, formaterDate } from 'adel-shared/dist/utils/functions';
import useValidation from '../../../custom-hooks/useValidation';

interface InformationsTabProps extends RouteComponentProps {
}

const InformationsTab: React.FunctionComponent<InformationsTabProps> = () => {
	const { getRootValidator } = useValidation();
	const validator = getRootValidator("CreateOrUpdateCommissionDto") || {};
	const { getValues, setValue, triggerValidation, errors, control, watch } = useForm<any>({ defaultValues: {
		decalageGroupes: false
	}});
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const dateDebut = watch('dateDebut');
	const commissionSpecifique = watch('commissionSpecifique');

	/** Catégories */
	const [categorieOptions, setCategorieOptions] = useState<AdelOption<string>[]>([]);
	const categorieSelector = useCategorieDossierSelector();
	const commissionCategories = categorieSelector.categories;
	const {commission} = useCommissionSelector();

	useEffect(() => {
		fetchCategoriesDossier(dispatch)();
	}, []);

	useEffect(() => {
		const dossierCategories = commission?.categoriesDossier?.map(cd => ({
			label: cd.nom,
			value: cd.id
		}));

		setValue([
			{ nom: commission?.nom },
			{ dateDebut: moment(commission?.dateDebut).toDate() },
			{ dateFin: moment(commission?.dateFin).toDate() },
			{ dateFinSoumission: moment(commission?.dateFinSoumission).toDate() },
			{ groupe: commission?.groupe },
			{ commissionSpecifique: commission?.commissionSpecifique },
			{ commissionSpecifiqueCommentaire: commission?.commissionSpecifiqueCommentaire },
			{ visibleCalendrier: commission?.visibleCalendrier },
			{ categorieDossierIds: dossierCategories },
		]);

		if(commission?.dateCA) {
			setValue('dateCA', moment(commission?.dateCA).toDate());
		}
	}, [commission, categorieOptions]);

	useEffect(() => {
		if(commissionSpecifique) {
			setValue('commissionSpecifiqueCommentaire', commission?.commissionSpecifiqueCommentaire);
		}
	}, [commissionSpecifique]);

	useEffect(() => {
		const categories = commissionCategories?.map(category => ({
			value: category.id || '',
			label: category.nom || ''
		})) || [];
		setCategorieOptions(categories);
	}, [commissionCategories]);



	/** Options select */
	const groupeOptions = () => {
		const options = Object.entries(GroupeCommission)
		.filter(([,value]) => value !== GroupeCommission.None && value !== GroupeCommission.Suppleant)
		.map(([label, value]) => ({
			value,
			label
		}));
		return options;
	}

	const handleDateDebutSelected = (date: Date, onChange: (date: Date) => void) => {
		onChange(date);
		if(!getValues().dateFin || moment(date).isAfter(getValues().dateFin)) {
			setValue('dateFin', date);
		}
	};

	const handleDateFinSelected = (date: Date, onChange: (date: Date) => void) => {
		onChange(date);
		if(!getValues().dateDebut || moment(date).isBefore(getValues().dateDebut)) {
			setValue('dateDebut', date);
		}
	};

	useEffect(() => {
		if(dateDebut && !commissionSpecifique) {
			setValue('nom', `Commission du ${formaterDate(dateDebut)}`);
		}
		else {
			setValue('nom', commission?.nom || '');
		}
	}, [commission, dateDebut, commissionSpecifique]);

	const handleSubmit = async() => {
		const result = await triggerValidation();

		if(!result || !commission?.id) return;

		const body: CreateOrUpdateCommissionDto = { 
			...getValues(),
			dateDebut: normalizeDate(getValues().dateDebut),
			dateFin: normalizeDate(getValues().dateFin),
			dateFinSoumission: normalizeDate(getValues().dateFinSoumission),
			categorieDossierIds: getValues().categorieDossierIds?.map((cd: AdelOption<string>) => cd.value)
		};

		if(getValues().dateCA) {
			body.dateCA = normalizeDate(getValues().dateCA);
		}

		try {
			await updateCommission(dispatch, commission.id, body);
			toast.success(t('common.success'));
		} catch(error) {
			if(error.response?.exception?.message) {
				toast.error(error.response.exception.message);
			} else {
				toast.error(t('common.errors.send'));
			}
		}
	};

	if(!validator) return null;

	return(
		<section className="informationsTab information">
			<div className="information__item information__calendar">
				<div className="calendar__periode">
					<h6>Période de la commission*</h6>

					<div className="calendar__periodeContent">
						<Controller control={control}
							name="dateDebut"
							as={({ onChange, value, name }) => (
								<InputCalendar
									name={name}
									defaultDate={value}
									errors={errors}
									onDateSelected={(date: Date) => handleDateDebutSelected(date, onChange)}
									unlock
								/>
							)}
							rules={{
								required: {
									value: true,
									message: "Ce champ est obligatoire."
								}
							}}
						/>

						<span>au</span>

						<Controller control={control}
							name="dateFin"
							as={({ onChange, value, name }) => (
								<InputCalendar
									name={name}
									defaultDate={value}
									errors={errors}
									onDateSelected={(date: Date) => handleDateFinSelected(date, onChange)}
									unlock
								/>
							)}
							rules={validator["DateFin"]}
						/>
					</div>
				</div>

				<div className="calendar__soumission">
					<h6>Dernier jour pour la soumission d’un dossier*</h6>
					<Controller control={control}
							name="dateFinSoumission"
							as={({ onChange, value, name }) => (
								<InputCalendar
									name={name}
									defaultDate={value}
									errors={errors}
									onDateSelected={onChange}
									unlock
								/>
							)}
							rules={{
								required: { value: true, message: "Ce champ est obligatoire."},
								validate: { lessThanDateDebut : value => value.getTime() < getValues().dateDebut.getTime() || "La date du dernier jour pour la soumission doit être inférieure à la date de début." }
							}}
						/>
				</div>

				{/* <div className="calendar__CA">
					<h6>Date de session CA</h6>
					<Controller control={control}
						name="dateCA"
						as={({ onChange, value, name }) => (
							<InputCalendar
								name={name}
								errors={errors}
								defaultDate={value}
								onDateSelected={onChange}
								unlock
								position="right-bottom"
							/>
						)}
						rules={{
							validate: { moreThanDateDebut : value => !value || (value && (value.getTime() > getValues().dateDebut.getTime())) || "La date CA doit être supérieure à la date de début." }
						}}
					/>
				</div> */}
			</div>

			<div className="information__item information__groupe">
				<Controller control={control}
					name="groupe"
					as={({ onChange, value, name }) => (
						<InputSelect<GroupeCommission>
							name={name}
							label="Groupe rattaché*"
							classname="inputSelect"
							options={groupeOptions()}
							errors={errors}
							onChange={onChange}
							value={value}
							placeholder={t("common.select")}
						/>
					)}
					rules={validator["Groupe"]}
				/>
				<Controller control={control}
					name="visibleCalendrier"
					as={({ onChange, name }) => (
						<InputRadioYesNo
							name={name}
							label="Visible sur le calendrier"
							onChange={onChange}
							value={!getValues().commissionSpecifique}
						/>
					)}
					rules={validator["VisibleCalendrier"]}
				/>
			</div>

			<div className="information__item information__specifique">
				<Controller control={control}
					name="commissionSpecifique"
					as={({ onChange, name }) => (
						<InputRadioYesNo
							name={name}
							label="Commission spécifique"
							onChange={(info) => {
								onChange(info);
								setValue([{visibleCalendrier: !info}]);
							}}
							value={getValues().commissionSpecifique}
						/>
					)}
				/>
				<Controller control={control}
					name="nom"
					as={({ onChange, value, name }) => (
						<Input
							name={name}
							type="text"
							label="Libellé de la commission"
							readOnly={!getValues().commissionSpecifique}
							errors={errors}
							onChange={onChange}
							value={value}
						/>
					)}
					rules={validator["Nom"]}
				/>
				{commissionSpecifique ? (
					<Controller control={control}
						name="commissionSpecifiqueCommentaire"
						as={({ onChange, value, name }) => (
							<Input
								name={name}
								type="text"
								maxLength={50}
								label="Commission spécifique détail"
								onChange={onChange}
								value={value}
								errors={errors}
							/>
						)}
						rules={validator["CommissionSpecifiqueCommentaire"]}
					/>
				) : (
					<Controller control={control}
						name="decalageGroupes"
						as={({ onChange, checked, name }) => (
							<InputRadioYesNo
								name={name}
								label="Décaler le groupe des commissions suivantes"
								onChange={onChange}
								value={checked}
							/>
						)}
						rules={validator?.["DecalageGroupes"]}
					/>
				)}
			</div>

			<div className="information__item information__categorie">
				<Controller control={control}
					name="categorieDossierIds"
					as={({ onChange, value, name }) => (
						<InputSelect<any> // string[]
							name={name}
							label="Catégorie de dossiers pris en charge par cette commission*"
							classname="inputSelect inputSelect--fullWidth inputSelect--multi"
							options={categorieOptions}
							errors={errors}
							onChange={onChange}
							value={value}
							isMulti
							selectAll="Toutes les catégories"
							placeholder={t("common.select")}
						/>
					)}
					rules={validator["CategorieDossierIds"]}
				/>
			</div>

			<div className="editCommission__footer">
				<FormButton
					className="button__cancel"
					type="button"
					value="Annuler"
					onClick={() => navigate("/Commission/ListeCommissions")}
				/>
				<FormButton
					type="submit"
					value="Enregistrer"
					onClick={handleSubmit}
				/>
			</div>
		</section>
	);
}

export default InformationsTab;