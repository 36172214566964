import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { modalCustomStyles } from "../../../../constants/config.constant";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { CommissionMemberDto } from '../../../../services/generated/BackOffice-api';
import { CommissionMembre } from '../MembresTab';
interface MembreAddTabProps {
	isOpen: boolean;
    onClose: () => void;
    onSubmit: (membre: CommissionMemberDto | null) => void;
    membres: CommissionMemberDto[];
    membersToExclude: CommissionMembre[];
}

const MembreAddTab: FunctionComponent<MembreAddTabProps> = ({
    isOpen,
    onClose,
    onSubmit,
    membres,
    membersToExclude
}) => {
    const [options, setOptions] = useState<AdelOption<CommissionMemberDto>[]>([]);
    const [membre, setMembre] = useState<CommissionMemberDto | null>(null);

    useEffect(() => {
        setOptions(membres.filter(x => !membersToExclude.some(y => y.commissionMemberId === x.id)).map(x => ({
			label: `${x.prenom} ${x.nom} - groupe ${x.groupe?.toUpperCase()}`,
			value: x
		})) || []);
    }, [membres, membersToExclude]);

    const handleCancel = () => {
        setMembre(null);
        onClose();
    };

    const handleSubmit = () => {
        setMembre(null);
        onSubmit(membre);
    };

    return (
        <Modal
            isOpen={isOpen}
            style={modalCustomStyles}
            className="normal__modal"
        >
            <div className="modal__header">
                <h3>Ajouter un membre</h3>
            </div>
            
            <div className="modal__content">
                <div className="modalMembreTab__select">
                    <InputSelect
                        label="Membre"
                        name="membre"
                        classname="inputSelect"
                        options={options}
                        onChange={(elem) => {
                            setMembre(elem)
                        }}
                        value={membre}
                        isSearchable
                    />
                </div>
            </div>

            <div className="modal__footer">
				<FormButton
					type="button"
					value="Annuler"
					onClick={handleCancel}
				/>
				<FormButton
					type="submit"
					value="Valider"
					onClick={handleSubmit}
				/>
			</div>
        </Modal>
    );
};

export default MembreAddTab;