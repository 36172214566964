import React, { FunctionComponent } from 'react';
import ModalValidateCancel from "../../../basics/ModalValidateCancel";

interface ModalInstructionsProps {
    isOpen: boolean;
    onCancel: () => void;
    onValidate: () => void;
    isLoading?: boolean;
}

const ModalInstructions: FunctionComponent<ModalInstructionsProps> = ({
    isOpen,
    onCancel,
    onValidate,
    isLoading,
}) => {
    return (
        <ModalValidateCancel
            isOpen={isOpen}
            onCancel={onCancel}
            onValidate={onValidate}
            isLoading={isLoading}
            title="PRISE EN CHARGE DU VERSEMENT"
            cancelButtonLabel="Non"
            validateButtonLabel="Oui"
        >
            <div className="modal__item">
                <p>Souhaitez-vous prendre en charge le dossier de ce versement ?</p>
            </div>
        </ModalValidateCancel>
    )
}

export default ModalInstructions;