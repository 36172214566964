import { CategorieFormeJuridiqueDto, FormeJuridiqueDto } from '../../../services/generated/BackOffice-api';

export interface CategorieFormeJuridiqueState {
	categoriesIsLoading: boolean;
	categoriesFormeJuridique?: CategorieFormeJuridiqueDto[];
	newCategorie?: CategorieFormeJuridiqueDto;
	formesJuridiques?: FormeJuridiqueDto[];
}

export const initialCategorieFormeJuridique: CategorieFormeJuridiqueState = {
	categoriesIsLoading: false,
	categoriesFormeJuridique: [],
	newCategorie: {},
	formesJuridiques: []
};
