import { Reducer } from "redux";
import { AdresseState, initialAdresseState } from '../states/adresseState';
import { DispatchAdresseAction, AdresseActionsEnum } from '../actions/adresseAction';

export const adresseReducer: Reducer<Partial<AdresseState>, DispatchAdresseAction> = (state = initialAdresseState, action) => {
    switch (action.type) {
        case AdresseActionsEnum.GET_ADRESSE:
            return state;
        case AdresseActionsEnum.UPDATE_ADRESSE:
            return { ...state, adresse: action.payload.adresse };
        default:
            return state;
    }
};
