import { merge } from "lodash";
import { Reducer } from "redux";
import { DiscussionDto, UpdateStructureNoteDto } from '../../../services/generated/BackOffice-api';
import { StructureActionsEnum } from '../actions/structureAction';
import { DispatchStructureAction } from '../actions/structureAction';
import { StructureState, initialStructureState } from '../states/structureState';

export const structureReducer: Reducer<Partial<StructureState>, DispatchStructureAction> = (state = initialStructureState, action) => {
	switch (action.type) {
		case StructureActionsEnum.GET_STRUCTURE_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_STRUCTURE_SUCCESS:
			return {
				...state,
				isLoading: false,
				structures: action.payload.structures
			};
		case StructureActionsEnum.UPDATE_STRUCTURE_FILTRE_SUCCESS:
			return {
				...state,
				isLoading: false,
				structureFilter: action.payload
			};
		case StructureActionsEnum.UPDATE_STRUCTURE_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.UPDATE_STRUCTURE_SUCCESS:
			return { ...state, isLoading: false,
				structureDetails: action.payload.structureDetails
			};
		case StructureActionsEnum.UPDATE_STRUCTURE_ERROR:
			return { ...state, isLoading: false };

		case StructureActionsEnum.GET_STRUCTURE_ERROR:
			return { ...state, isLoading: false };
		case StructureActionsEnum.GET_AUTOCOMPLETE_NOM_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_AUTOCOMPLETE_NOM_SUCCESS:
			return {
				...state,
				isLoading: false,
				nomList: action.payload.nomList
			};
		case StructureActionsEnum.GET_AUTOCOMPLETE_EMAIL_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_AUTOCOMPLETE_EMAIL_SUCCESS:
			return {
				...state,
				isLoading: false,
				mailList: action.payload.emailList
			};
		case StructureActionsEnum.GET_AUTOCOMPLETE_RESPONSABLE_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_AUTOCOMPLETE_RESPONSABLE_SUCCESS:
			return {
				...state,
				isLoading: false,
				responsableList: action.payload.responsableList
			};
			
		case StructureActionsEnum.GET_AUTOCOMPLETE_SIRET_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_AUTOCOMPLETE_RESPONSABLE_SUCCESS:
			return {
				...state,
				isLoading: false,
				responsableList: action.payload.responsableList
			};

		/** Details */
		case StructureActionsEnum.GET_STRUCTURE_DETAILS_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_STRUCTURE_DETAILS_SUCCESS:
			return {
				...state,
				isLoading: false,
				structureDetails: action.payload.structureDetails
			};
		case StructureActionsEnum.GET_STRUCTURE_DETAILS_ERROR:
			return { ...state, isLoading: false };


		/** Relations */
		case StructureActionsEnum.GET_STRUCTURE_RELATIONS_REQUEST:
			return { ...state, isRelationsLoading: true };
		case StructureActionsEnum.GET_STRUCTURE_RELATIONS_SUCCESS:
			return {
				...state,
				isRelationsLoading: false,
				structureRelations: action.payload.structureRelations
			};
		case StructureActionsEnum.GET_STRUCTURE_RELATIONS_ERROR:
			return { ...state, isRelationsLoading: false };

		case StructureActionsEnum.ADD_STRUCTURE_RELATIONS_REQUEST:
			return { ...state, isRelationsLoading: true };
		case StructureActionsEnum.ADD_STRUCTURE_RELATIONS_SUCCESS:
			return { ...state, isRelationsLoading: false };
		case StructureActionsEnum.ADD_STRUCTURE_RELATIONS_ERROR:
			return { ...state, isRelationsLoading: false };

		case StructureActionsEnum.DELETE_STRUCTURE_RELATIONS_REQUEST:
			return { ...state, isRelationsLoading: true };
		case StructureActionsEnum.DELETE_STRUCTURE_RELATIONS_SUCCESS:
			return { ...state, isRelationsLoading: false };
		case StructureActionsEnum.DELETE_STRUCTURE_RELATIONS_ERROR:
			return { ...state, isRelationsLoading: false };



		/** Instruction */
		case StructureActionsEnum.GET_STRUCTURE_INSTRUCTION_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_STRUCTURE_INSTRUCTION_SUCCESS:
			return {
				...state,
				isLoading: false,
				structureInstruction: action.payload.structureInstruction
			};
		case StructureActionsEnum.GET_STRUCTURE_INSTRUCTION_ERROR:
			return { ...state, isLoading: false };

		case StructureActionsEnum.POST_STRUCTURE_INSTRUCTION_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.POST_STRUCTURE_INSTRUCTION_SUCCESS:
			return {
				...state,
				structureInstruction: {
					...state.structureInstruction,
					...action.payload.structureInstruction,
					documents: action.payload.structureInstruction?.documents?.filter(x => x.id).map(x => ({
						...state.structureInstruction?.documents?.find(y => y.id === x.id)
					}))
				},
				structureInstructionFiles: action.payload.structureInstructionFiles,
				isLoading: false,
			};
		case StructureActionsEnum.POST_STRUCTURE_INSTRUCTION_ERROR:
			return { ...state, isLoading: false };
			
        /** Documents */
		case StructureActionsEnum.GET_STRUCTURE_DOCUMENTS_REQUEST:
			return { ...state, isLoading: true };
	    case StructureActionsEnum.GET_STRUCTURE_DOCUMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				structureDocuments: action.payload.structureDocuments
			};
		case StructureActionsEnum.GET_STRUCTURE_DOCUMENTS_ERROR:
			return { ...state, isLoading: false };

        case StructureActionsEnum.POST_STRUCTURE_DOCUMENTS_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.POST_STRUCTURE_DOCUMENTS_SUCCESS:
			return {
				...state,
				structureDocuments: {
					...state.structureDocuments,
					...action.payload.structureDocuments,
					documents: action.payload.structureDocuments?.documents?.filter(x => x.id).map(x => ({
						...state.structureDocuments?.documents?.find(y => y.id === x.id)
					}))
				},
				structureDocumentsFiles: action.payload.structureDocumentsFiles,
				isLoading: false,
			};
		case StructureActionsEnum.POST_STRUCTURE_DOCUMENTS_ERROR:
			return { ...state, isLoading: false };
		case StructureActionsEnum.RESET_FILES:
			return { ...state, structureDocumentsFiles: [], structureInstructionFiles: [] };
		/** Note */
		case StructureActionsEnum.GET_STRUCTURE_NOTE_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_STRUCTURE_NOTE_SUCCESS:
			let note:UpdateStructureNoteDto;
			if(action.payload.structureNote && Object.keys(action.payload.structureNote).length > 0)
				note = action.payload.structureNote
			else
				note = {
					note: 0,
					commentaires: ''
				}
			return {
				...state,
				isLoading: false,
				structureNote: note
			};
		case StructureActionsEnum.GET_STRUCTURE_NOTE_ERROR:
			return { ...state, isLoading: false };

		case StructureActionsEnum.POST_STRUCTURE_NOTE_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.POST_STRUCTURE_NOTE_SUCCESS:
			return {
				...state,
				isLoading: false,
				structureNote: action.payload.structureNote
			};
		case StructureActionsEnum.POST_STRUCTURE_NOTE_ERROR:
			return { ...state, isLoading: false };

		/** Messages */
		case StructureActionsEnum.GET_STRUCTURE_MESSAGES_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.GET_STRUCTURE_MESSAGES_SUCCESS:
			return {
				...state,
				isLoading: false,
				structureDiscussions: action.payload.structureDiscussions,
				structureDiscussionsLu: action.payload.structureDiscussionsLu
			};
		case StructureActionsEnum.GET_STRUCTURE_MESSAGES_ERROR:
			return { ...state, isLoading: false };

		case StructureActionsEnum.POST_STRUCTURE_MESSAGES_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.POST_STRUCTURE_MESSAGES_SUCCESS:
			return {
				...state,
				isLoading: false,
				structureMessage: action.payload.structureMessage,
				structureDiscussion: action.payload.structureDiscussion
				
			};
		case StructureActionsEnum.POST_STRUCTURE_MESSAGES_ERROR:
			return { ...state, isLoading: false };

		case StructureActionsEnum.POST_STRUCTURE_MULTIPLE_MESSAGES_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.POST_STRUCTURE_MULTIPLE_MESSAGES_SUCCESS:
			return { ...state, isLoading: false };
		case StructureActionsEnum.POST_STRUCTURE_MULTIPLE_MESSAGES_ERROR:
			return { ...state, isLoading: false };

		case StructureActionsEnum.GET_STRUCTURE_MESSAGES_OBJECT:
			return { ...state, objectDiscussionStructure: action.payload.objectDiscussionStructure };


		/** Documents validation */
		case StructureActionsEnum.PUT_STRUCTURE_DOCUMENTS_VALIDATION_REQUEST:
			return { ...state, isDocValLoading: true };
		case StructureActionsEnum.PUT_STRUCTURE_DOCUMENTS_VALIDATION_SUCCESS:
			return {
				...state,
				isDocValLoading: false
			};
		case StructureActionsEnum.PUT_STRUCTURE_DOCUMENTS_VALIDATION_ERROR:
			return { ...state, isDocValLoading: false };
		
		/** Discussion */
		case StructureActionsEnum.CREATE_STRUCTURE_DISCUSSION_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.CREATE_STRUCTURE_DISCUSSION_SUCCESS:
			let d:DiscussionDto[] = state.structureDiscussions || [];
			let newDiscussion = [...d, (action.payload.structureDiscussion as DiscussionDto)]
			return {
				...state,
				isLoading: false,
				structureDiscussion: action.payload.structureDiscussion,
				structureDiscussions: newDiscussion.reverse()
			};
		case StructureActionsEnum.CREATE_STRUCTURE_DISCUSSION_ERROR:
			return { ...state, isLoading: false };

		case StructureActionsEnum.UPDATE_STATUT_DISCUSSION_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.UPDATE_STATUT_DISCUSSION_SUCCESS:
			return { ...state, isLoading: false };
		case StructureActionsEnum.UPDATE_STATUT_DISCUSSION_ERROR:
			return { ...state, isLoading: false };
		case StructureActionsEnum.UPDATE_STRUCTURE_DISCUSSION_LU_SUCCESS:
			if(action.payload.structureDiscussionId) {
				return {
					...state,
					structureDiscussionsLu: [
						...state.structureDiscussionsLu || [],
						action.payload.structureDiscussionId
					]
				};
			}
			return state;

		case StructureActionsEnum.POST_STRUCTURE_NOTE_PRO_REQUEST:
			return { ...state, isLoading: true };
		case StructureActionsEnum.POST_STRUCTURE_NOTE_PRO_SUCCESS:
			return { ...state, isLoading: false };
		case StructureActionsEnum.POST_STRUCTURE_NOTE_PRO_ERROR:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};
