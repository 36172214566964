import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken} from "axios";
import { Dictionary } from "adel-shared/dist/models/Dictionary";
import {
    Unit,
    UpdateVersementArtisteDocumentDto
} from "../services/generated/BackOffice-api";

export class AdelVersementClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    /**
     * @param versementArtistes (optional) 
     * @param documents (optional) 
     * @return Success
     */
    updateArtisteDocument(versementId: string | null, body: any, files: Dictionary<File>, cancelToken?: CancelToken | undefined): Promise<Unit> {
        let url_ = this.baseUrl + "/api/v1/Versement/{versementId}/contratscachets/artiste/document";

        if (versementId === undefined || versementId === null)
            throw new Error("The parameter 'versementId' must be defined.");
        url_ = url_.replace("{versementId}", encodeURIComponent("" + versementId));
        url_ = url_.replace(/[?&]$/, "");    
            
        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

		let options_ = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		} as AxiosRequestConfig;

        return this.instance.put(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processUpdateArtisteDocument(_response);
        });
    }

    protected processUpdateArtisteDocument(response: AxiosResponse): Promise<Unit> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404  = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<Unit>(<any>null);
    }
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

const appendFileDictionary = (formData: FormData, files: Dictionary<File>) => {
    for (let key in files) {
        if (files.hasOwnProperty(key)) {
            formData.append(key, files[key]);
        }
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}