import { Reducer } from "redux";
import { CompteBancaireActionsEnum, DispatchCompteBancaireAction } from "../actions/compteBancaireAction";
import { CompteBancaireState, initialCompteBancaireState } from "../states/compteBancaireState";

export const compteBancaireReducer: Reducer<Partial<CompteBancaireState>, DispatchCompteBancaireAction> = (state = initialCompteBancaireState, action) => {
    const comptesBancaires = [...state.comptesBancaires || []];
    const currentCompte = action.payload?.compteBancaire;
    switch(action.type) {
        case CompteBancaireActionsEnum.GET_COMPTES_BANCAIRES_SUCCESS:
            return {
                ...state,
                comptesBancaires: action.payload.comptesBancaires
            };
        case CompteBancaireActionsEnum.CREATE_COMPTE_BANCAIRE_SUCCESS:
            if(currentCompte) {
                comptesBancaires.push(currentCompte);
            }
            return {
                ...state,
                comptesBancaires
            };
        case CompteBancaireActionsEnum.UPDATE_COMPTE_BANCAIRE_SUCCESS:
            if(currentCompte) {
                const updatedCompteIndex = comptesBancaires.findIndex(x => x.id === currentCompte.id);
                comptesBancaires[updatedCompteIndex] = currentCompte;
            }
            return {
                ...state,
                comptesBancaires
            };
        case CompteBancaireActionsEnum.DELETE_COMPTE_BANCAIRE_SUCCESS:
            return {
                ...state,
                comptesBancaires: state.comptesBancaires?.filter(x => x.id !== action.payload.compteBancaireId)
            };
        default:
            return state;
    }
};