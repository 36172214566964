import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Input from 'adel-shared/dist/components/basics/Input';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { modalMediumCustomStyles } from '../../constants/config.constant';
import useValidation from '../../custom-hooks/useValidation';
import { CompteBancaireDto } from '../../services/generated/BackOffice-api';
import { createOrUpdateCompteBancaires } from '../../store/compte-bancaire-store/actions/compteBancaireAction';

interface ModalAddCompteProps {
	compte?: CompteBancaireDto;
	isOpen: boolean;
	onClose: () => void;
}

const ModalAddCompte: React.FunctionComponent<ModalAddCompteProps> = ({
	compte,
	isOpen,
	onClose
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { getValues, register, errors, triggerValidation } = useForm<CompteBancaireDto>({
		defaultValues: compte
	});
	const { getRootValidator } = useValidation();
	const validator = getRootValidator("CompteBancaireDto");

	const submit = async () => {
		const isValid = await triggerValidation();

		if(!isValid) return;

		try {
			const body = getValues();
			let successMsg = 'Le compte bancaire a été ajouté.';

			if(compte?.id) {
				body.id = compte.id;
				successMsg = 'Le compte bancaire a été mis à jour.';
			}

			await createOrUpdateCompteBancaires(dispatch, body, compte?.id);
			toast.success(successMsg);
		} catch(error) {
			if(error.response?.exception?.message) {
				toast.error(error.response.exception.message);
			} else {
				toast.error(t('common.errors.send'));
			}
		}
		onClose();
	}

	return (
		<Modal isOpen={isOpen} style={modalMediumCustomStyles} className="normal__modal compteBancaireSpedidamModal">
			<div className="modal__header">
				<h3>{compte?.id ? "Modifier un compte bancaire" : "Ajouter un compte bancaire"}</h3>
			</div>
			<div className="modal__content">
				<div className="customModal__item">
					<Input
						name="beneficiaire"
						type="text"
						label="Bénéficiaire"
						errors={errors}
						reference={validator && register(validator["Beneficiaire"])}
					/>
					<Input
						name="banque"
						type="text"
						label="Banque"
						errors={errors}
						reference={validator && register(validator["Banque"])}
					/>
				</div>
				<div className="customModal__item">
					<Input
						name="agence"
						type="text"
						label="Agence"
						errors={errors}
						reference={validator && register(validator["Agence"])}
					/>
					<Input
						name="iban"
						type="text"
						label="IBAN"
						errors={errors}
						reference={validator && register(validator["Iban"])}
					/>
				</div>
				<div className="customModal__item">
					<Input
						name="bic"
						type="text"
						label="BIC"
						errors={errors}
						reference={validator && register(validator["Bic"])}
					/>
					<Input
						name="domiciliation"
						type="text"
						label="Domiciliation"
						errors={errors}
						reference={validator && register(validator["Domiciliation"])}
					/>
				</div>
				<div className="customModal__item">
					<Input
						name="nomCompletDevise"
						type="text"
						label="Nom complet de la devise"
						errors={errors}
						reference={validator && register(validator["NomComplet"])}
					/>
					<Input
						name="symboleDevise"
						type="text"
						label="Symbole de la devise"
						errors={errors}
						reference={validator && register(validator["Symbole"])}
					/>
				</div>
				<div className="customModal__item">
					<Input
						name="codeJournal"
						type="text"
						label="Code journal"
						errors={errors}
						reference={validator && register(validator["CodeJournal"])}
					/>
					<Input
						name="codeBanque"
						type="text"
						label="Code banque"
						errors={errors}
						reference={validator && register(validator["CodeBanque"])}
					/>
				</div>
				<div className="customModal__item">
					<Input
						name="identificationProprietaire"
						type="text"
						label="Identification propriétaire"
						errors={errors}
						reference={validator && register(validator["IdentificationProprietaire"])}
					/>
				</div>
			</div>
			<div className="modal__footer">
				<FormButton
					type="button"
					value="Annuler"
					onClick={onClose}
				/>
				<FormButton
					type="submit"
					value={compte?.id ? "Modifier" : "Ajouter"}
					onClick={submit}
				/>
			</div>
		</Modal>
	);
}

export default ModalAddCompte;
