import React, { ReactElement, FunctionComponent } from 'react';
import Modal from 'react-modal';
import { modalMediumExportCustomStyles } from "../../constants/config.constant";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Loader from 'react-loader-spinner';

interface ModalValidateCancelMediumProps {
    isOpen: boolean;
    onCancel: () => void;
    onValidate: () => void;
    children: ReactElement<any>;
    title?: string;
    validateButtonLabel?: string;
    cancelButtonLabel?: string;
    isLoading?: boolean;
}

const ModalValidateCancelMedium: FunctionComponent<ModalValidateCancelMediumProps> = ({
    isOpen,
    onCancel,
    onValidate,
    children,
    title,
    validateButtonLabel,
    cancelButtonLabel,
    isLoading
}) => {
    return (
        <Modal
        isOpen={isOpen}
        style={modalMediumExportCustomStyles}
        >
                <div className="modal__header">
                    <h3>{title ?? "Attention"}</h3>
                </div>
                <div className="modal__content">
                    {React.cloneElement(children)}
                </div>
                <div className="modal__footer">
                    <FormButton
                        type="button"
                        value={cancelButtonLabel ?? "Annuler"}
                        onClick={() => onCancel()}
                    />
                    {isLoading
                        ? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
                        : <FormButton
                            type="submit"
                            value={validateButtonLabel ?? "Enregistrer"}
                            onClick={() => onValidate()}
                        />}
                </div>
        </Modal>
    );
}

export default ModalValidateCancelMedium;