import Pagination from 'adel-shared/dist/components/basics/Pagination';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { RouteComponentProps, useParams } from '@reach/router';
import { DossierCommissionDto, StatutDossier } from '../../../services/generated/BackOffice-api';
import { fetchCommission, fetchCommissionDossiersListe } from '../../../store/commission-store/actions/commissionActions';
import { useCommissionSelector } from '../../../store/store-helpers';
import Checkbox from '../../basics/Checkbox';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import ModalValidateCancel from '../../basics/ModalValidateCancel';
import { programmerEnCommissionDossier } from '../../../store/dossier-store/actions/dossierAction';
import { toast } from 'react-toastify';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { StatutCommission } from 'adel-shared/dist/models/generated/BackOffice-api';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { paginationNumbers } from '../../../constants/config.constant';

interface ListeDossiersCommissionProps extends RouteComponentProps {
}

type StatutDossierFilter = {
	[key in StatutDossier]?: boolean;
}

const ListeDossiersCommission: React.FunctionComponent<ListeDossiersCommissionProps> = () => {
	const { t } = useTranslation();
	const location = useParams();
	const dispatch = useDispatch();
	const id = location.commissionId;
	const {
		commissionDossiers,
		commission,
		isDossierLoading
	} = useCommissionSelector();

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	/** Permissions */
	const usePermProgrammerEnCommissionDossier = useHasPermission("ProgrammerEnCommissionDossier");

	/** Table */
	const [data, setData] = useState<Array<any>>([]); // TODO: autre type que any
	const [sort, setSort] = useState<string>("");

	/** Filtres */
	const [dossierCA, setDossierCA] = useState<boolean>(false);
	const [dossierStatut, setDossierStatut] = useState<StatutDossierFilter>({
		[StatutDossier.ProgrammeEnCommission]: false,
		[StatutDossier.HorsCriteres]: false
	});

	const [isOpenForceModal, openForceModal, closeForceModal] = useBooleanState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [currentDossier, setCurrentDossier] = useState<DossierCommissionDto>();

	useEffect(() => {
		if(id) {
			fetchCommission(dispatch, id);
		}
	}, []);
	
	const getCommissionDossiersListe = (id:string) => {
		const statuts = Object.entries(dossierStatut)
		.filter(([key, value]) => value === true)
		.map(([key, value]) => key)
		.join('|');

		if(id) {
			fetchCommissionDossiersListe(dispatch, id, "", false, page, pageSize, sort, statuts, dossierCA);
		}
	}

	useEffect(() => {
		getCommissionDossiersListe(id);
	}, [fetchCommissionDossiersListe, page, pageSize, sort, dossierStatut, dossierCA]);

	useEffect(() => {
		if (commissionDossiers?.number &&
			commissionDossiers?.totalPageCount &&
			(commissionDossiers?.number > commissionDossiers?.totalPageCount)) {
			setPage(1);
		}
		setHasNext(commissionDossiers?.hasNext ?? false);
		setHasPrevious(commissionDossiers?.hasPrevious ?? false);
		setIsFirst(commissionDossiers?.isFirst ?? false);
		setIsLast(commissionDossiers?.isLast ?? false);
		setTotalPageCount(commissionDossiers?.totalPageCount ?? 0);
		setTotalItemCount(commissionDossiers?.totalItemCount ?? 0);

		if (commissionDossiers && commissionDossiers.items) {
			const data = commissionDossiers.items.map(dossier => ({
				Statut: <span data-statut={dossier.statut} className={`table__statutItem table__statut--${dossier.statut}`}>{t(`dossier.statut.${dossier.statut}`)}</span>,
				Numero: dossier.numero,
				RaisonSociale: dossier.raisonSociale,
				Nom: <a rel="" target="_blank" href={`/Dossier/${dossier.id}`}>{dossier.nom}</a>,
				ChargeInstructionNom: `${dossier.chargeInstructionNom} ${dossier.chargeInstructionPrenom}`,
				CategorieNom: dossier.categorieNom,
				CA: <span className={clsx("table__check", { "table__check--valid": dossier.ca }, { "table__check--invalid": !dossier.ca })}><i className={clsx("fas", { "fa-check": dossier.ca }, { "fa-times": !dossier.ca })}></i></span>,
				MontantDemande: `${(dossier.montantDemande || dossier.statut === StatutDossier.Refuse) ? formatNumber(dossier.montantDemande) : '-'} €`,
				MontantAttribue: `${(dossier.montantAttribue || dossier.statut === StatutDossier.Refuse) ? formatNumber(dossier.montantAttribue) : '-'} €`,
				MontantCA: `${(dossier.montantCA || dossier.statut === StatutDossier.Refuse) ? formatNumber(dossier.montantCA) : '-'} €`,
				Id: dossier.id
			}));
			setData(data);
		}
	}, [commissionDossiers]);

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Statut", accessor: "Statut", sortType: "basic" },
					{ Header: "N° dossier", accessor: "Numero", sortType: "basic" },
					{ Header: "Nom structure", accessor: "RaisonSociale", sortType: "basic" },
					{ Header: "Nom dossier", accessor: "Nom", sortType: "basic" },
					{ Header: "Chargé d'instruction", accessor: "ChargeInstructionNom", sortType: "basic" },
					{ Header: "Catégorie", accessor: "CategorieNom", sortType: "basic" },
					{ Header: "Dossier CA", accessor: "CA", sortType: "basic" },
					{ Header: "Montant demandé", accessor: "MontantDemande", sortType: "basic" },
					{ Header: "Montant accordé", accessor: "MontantAttribue", sortType: "basic" },
					{ Header: "Montant CA", accessor: "MontantCA", sortType: "basic" },
					{ accessor: "Actions", disableSortBy: true,
						Cell: (value: any) => (
							usePermProgrammerEnCommissionDossier &&
							value.cell.row.values.Statut.props['data-statut'] === StatutDossier.HorsCriteres &&
							commission?.statut !== StatutCommission.Terminee
						) && (
							<FormButton
								type="button"
								value="Forcer"
								onClick={() => handleProgrammerEnCommission(value.cell.row.values.Id, value)}
							/>
						)
					},
					{ Header: "Id", accessor: "Id" }
				]
			}
		];
	}, [commission]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	const  handleProgrammerEnCommission = async(id: string, value: any) => {
	    setCurrentDossier(value?.rows?.find((item: any) => item.values.Id === id)?.values);
	};
	useEffect(() => {
		if(currentDossier != undefined && !isOpenForceModal){
			openForceModal();
		}
	}, [currentDossier]);
	const onCancelForceModal = () => {
		closeForceModal();
	};

	const onValidateForceModal = async() => {
		//@ts-ignore
		if(!currentDossier?.Id) {
			return;
		}

		setIsLoading(true);
		try {
			if(usePermProgrammerEnCommissionDossier && id) {
				//@ts-ignore
				await programmerEnCommissionDossier(currentDossier.Id);
				await fetchCommission(dispatch, id);
				await getCommissionDossiersListe(id);
			}
			toast.success(t('common.success'));
		} catch(error) {
			toast.error(t('common.errors.send'));
		}
		setIsLoading(false);
		closeForceModal();
	};

	return (
		<div className="commission listeDossiersCommission">
			<div className="commission__header"></div>
			<div className="commission__content">
				<div className="commission__contentHeader">
					<h1 className="commission__contentHeaderTitle">Liste des dossiers de la commission</h1>
				</div>
				<div className="commission__filtres">
					<div className="filtres__item">
						<label>Filtres :</label>
						<div className="filtres__search">
							<Checkbox
								label="Dossiers programmés"
								value={dossierStatut[StatutDossier.ProgrammeEnCommission] ?? false}
								onChange={(value: boolean) : boolean => {
									setDossierStatut({...dossierStatut, [StatutDossier.ProgrammeEnCommission]: value});
									return true;
								}}
							/>
							{/* <Checkbox
								label="Dossiers CA"
								value={dossierCA}
								onChange={(value: boolean) : boolean => {
									setDossierCA(value);
									return true;
								}}
							/> */}
							<Checkbox
								label="Dossiers hors critères"
								value={dossierStatut[StatutDossier.HorsCriteres] ?? false}
								onChange={(value: boolean) : boolean => {
									setDossierStatut({...dossierStatut, [StatutDossier.HorsCriteres]: value});
									return true;
								}}
							/>
						</div>
					</div>
				</div>
				<div className="listeDossiers__table">
					{isDossierLoading
						? <Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
						: <>
							<Table
								data={data}
								getTableProps={getTableProps}
								getTableBodyProps={getTableBodyProps}
								headerGroups={headerGroups}
								rows={rows}
								prepareRow={prepareRow}
								styles={styleTable.page}
							/>
							<Pagination
								hasNext={hasNext}
								hasPrevious={hasPrevious}
								isFirst={isFirst}
								isLast={isLast}
								totalPageCount={totalPageCount}
								totalItemCount={totalItemCount}
								page={setPage}
								pageSize={setPageSize}
								initPageSize={pageSize}
								initPage={page}
								pageNumbers={paginationNumbers}
							/>
							{usePermProgrammerEnCommissionDossier &&
								<ModalValidateCancel
									isOpen={isOpenForceModal}
									onCancel={onCancelForceModal}
									onValidate={onValidateForceModal}
									validateButtonLabel="Continuer"
									title="Attention"
									isLoading={isLoading}
								>
									<div className="modal__item">
										{/* @ts-ignore */}
										<p>Le statut du dossier « {currentDossier?.Nom?.props?.children} » va changer en « {t(`dossier.statut.${StatutDossier.ProgrammeEnCommission}`)} ».</p>
									</div>
								</ModalValidateCancel>
							}
						</>
					}
				</div>
			</div>
		</div>
	);
}

export default ListeDossiersCommission;