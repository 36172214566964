
import { DossierClient, DossierHistoryDtoSortedFilteredPage } from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from './dossierAction';
import { toast } from 'react-toastify';
import { Dispatch, Action } from 'redux';
import { DossierState } from '../../dossier-store/states/dossierState';
import { axiosInstance } from '../../../custom-hooks/useAxios';
export interface DispatchDossierAction extends Action<DossierActionsEnum> {
	payload: DossierState;
}

/** Actions */
export const requestDossierHistory = () => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_HISTORY_REQUEST,
		payload: { isLoading: true }
	}
}

export const getDossierHistory = (result: DossierHistoryDtoSortedFilteredPage) => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_HISTORY_SUCCESS,
		payload: { isLoading: true, dossierHistory: result }
	}
}


/** Task */
const dossierClient = new DossierClient('', axiosInstance);

export const fetchDossierHistory = (
	dispatch: Dispatch<DispatchDossierAction>,
	dossierId: string,
	sorts: string,
	page: number = 1,
	pageSize: number = 10
) => {
	const filters = '';
	const disablePagination = false;

	dispatch(requestDossierHistory());
	return dossierClient.getDossierHistory(filters, sorts, page, pageSize, disablePagination, dossierId)
		.then(response => {
			dispatch(getDossierHistory(response));
			return response;
		})
		.catch(error => {
			if(error.response?.exception?.message)
				toast.error(error.response.exception.message);
			else if(error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error("Impossible de récupérer l'historique");
			throw error;
		});
}

