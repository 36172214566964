import React, { FunctionComponent, useEffect } from 'react';
import { TypePoste } from '../../../services/generated/BackOffice-api';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useTranslation } from 'react-i18next';
import { formatPhone } from 'adel-shared/dist/utils/functions';
import { useStructureSelector } from '../../../store/store-helpers';
import { Dictionary } from 'adel-shared/dist/models/Dictionary';
import { FormContextValues, ValidationOptions } from 'react-hook-form';
import Input from 'adel-shared/dist/components/basics/Input';

interface RepresensantLegalProps {
    form: FormContextValues;
	usePermUpdateStructureDetails: boolean;
	validator:Dictionary<ValidationOptions> | null;
}

const RepresensantLegal: FunctionComponent<RepresensantLegalProps>  = ({form, usePermUpdateStructureDetails, validator}) => {
	const { t } = useTranslation();
	const {structureDetails={}} = useStructureSelector();
	
	useEffect(() => {
		structureDetails?.representantLegal?.email && form.setValue("email", structureDetails.representantLegal.email)
	}, [structureDetails?.representantLegal]);

	return (<>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Poste"
						content={structureDetails.representantLegal?.poste !== TypePoste.None ? t(`common.typePoste.${structureDetails.representantLegal?.poste}`) : '-'}
					/>
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Civilité"
						content={structureDetails.representantLegal?.civilite ? t(`common.civilite.${structureDetails.representantLegal.civilite}`) : '-'}
					/>
				</div>
				<div className="detailsTab__content">
				{usePermUpdateStructureDetails 
					? <Input
						label="Email*"
						type="text"
						name="email"
						reference={validator && form.register(validator["RepresentantLegalEmail"])}
						errors={form.errors}
					/>
					: <InputReadOnly
						label="Email"
						content={structureDetails.representantLegal?.email ? structureDetails.representantLegal.email : '-'}
					/>}
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Prénom"
						content={structureDetails.representantLegal?.prenom ? structureDetails.representantLegal.prenom : '-'}
					/>
				</div>
				<div className="detailsTab__content">
					<InputReadOnly
						label="Téléphone portable"
						content={structureDetails.representantLegal?.telephonePortable ? formatPhone(structureDetails.representantLegal.telephonePortable) : '-'}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Nom"
						content={structureDetails.representantLegal?.nom ? structureDetails.representantLegal.nom : '-'}
					/>
				</div>
				<div className="detailsTab__content">
					<InputReadOnly
						label="Téléphone fixe"
						content={structureDetails.representantLegal?.telephoneFixe ? formatPhone(structureDetails.representantLegal.telephoneFixe) : '-'}
					/>
				</div>
			</div>
		</div>
	</>);
}

export default RepresensantLegal;
