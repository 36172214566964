import React, { useState, useEffect, MouseEvent, useRef, Dispatch } from "react";
import { Link } from "@reach/router";
import { useDispatch } from 'react-redux';
import { fetchPageVitrine, reorderPage } from "../../store/page-vitrine-store/actions/pageVitrineAction";
import PageVitrine from "../page-vitrine/PageVitrine";
import PageWarning from "../page-warning/PageWarning";
import { useNavigationControl } from "../../custom-hooks/useNavigationControl";
import useHasPermission from '../../custom-hooks/useHasPermission';
import { MessageAlerteDto, PageVitrineDto, RoleClient, TypeBordereau } from "../../services/generated/BackOffice-api";
import { getAllMessageAlerte } from '../../store/page-warning-store/pageWarningAction';
import { usePageWarningSelector } from '../../store/store-helpers';
import clsx from "clsx";
import { toggleMenu } from "../../store/general-store/actions/generalAction";
import { axiosInstance } from "../../custom-hooks/useAxios";
import { toast } from "react-toastify";
import { DispatchUserAction, fetchUser, fetchUserPermissions, fetchUserRoles, getUserRoles } from "../../store/user-store/actions/userAction";

interface MenuBurgerProps {
	showPagesVitrines: () => void;
	showPagesWarning: () => void;
	isOpenPagesVitrines: boolean;
	isOpenPagesWarning: boolean;
}

interface MenuType {
	label: string;
	link: string;
}

const MenuBurger: React.FunctionComponent<MenuBurgerProps> = ({
	showPagesVitrines,
	isOpenPagesVitrines,
	isOpenPagesWarning,
	showPagesWarning
}) => {
	const [gestion, setGestion] = useState(false);
	const [commission, setCommission] = useState(false);
	const [comptabilite, setComptabilite] = useState(false);
	const [administration, setAdministration] = useState(false);

	//cms pages
	const [pages, setPages] = useState<PageVitrineDto[]>([]);
	const [pageClickedInfo, setPageClickedInfo] = useState<PageVitrineDto>({});

	const pageWarningSelector = usePageWarningSelector();
	const [selectedMessage, setSelectedMessage] = useState<MessageAlerteDto>({});

	//drag & drop
	const [elemSelectedId, setElemSelectedId] = useState<string>("");
	const [targetSelectedId, setTargetSelectedId] = useState<string>("");

	const { allowNavigation } = useNavigationControl();

	const [modalAttentionModificationEnCoursIsOpen, setModalAttentionModificationEnCoursIsOpen] = useState<boolean>(false)
	const [pageHasBeenModified, setPageHasBeenModified] = useState<boolean>(false)
	const navigationCallback = useRef<() => void>(() => {});

	const dispatch = useDispatch();

	const usePermGetStructures = useHasPermission("GetStructures");
	const usePermGetDossiers = useHasPermission("GetDossiers");
	const usePermGetCommissions = useHasPermission("GetCommissions");
	const usePermGetSessionsCA = useHasPermission("GetSessionsCA");
	const usePermGetCommissionsMember = useHasPermission("GetCommissionsMember");
	const usePermGetBordereaux = useHasPermission("GetBordereaux")
	const usePermCompteBancaire = useHasPermission("GetAllComptesBancaires");
	const usePermDossierNonSolde = useHasPermission("GetDossierNonSoldeAsExcel");
	const usePermGetUsers = useHasPermission("GetUsers");
	const usePermCreatePageVitrine = useHasPermission("CreatePageVitrine");
	const usePermCreateCritereDossier = useHasPermission("CreateCritereDossier");
	const usePermUpdateCategoriesFormeJuridiqueTypesDeDocuments = useHasPermission("UpdateCategoriesFormeJuridiqueTypesDeDocuments");
	const usePermGetTemplateByCode = useHasPermission("GetTemplateByCode");
	const usePermCreateOrUpdateMessageAlerte = useHasPermission("CreateOrUpdateMessageAlerte");
	const usePermGetExportArtistAsExcel = useHasPermission("GetExportArtistAsExcel");

	// const [isResponsableService, setIsResponsableService] = useState<boolean>(false);

	useEffect(() => {
		getAllMessageAlerte(dispatch);
	}, []);

	// useEffect(() => {
	// 	fetchUser(dispatch)().then((data: any) => {
	// 		const userRoles = data?.payload?.user?.roles;
	// 		const responsableServicePermission =  userRoles.includes("Responsable de service");
	// 		setIsResponsableService(responsableServicePermission);
	// 	})
	// }, [dispatch]);

	useEffect(() => {
		if(usePermCreatePageVitrine) {
			fetchPageVitrine(dispatch)().then(result => {
				setPages(result?.payload.pages)
				if (result?.payload.pages.length > 0) {
					setPageClickedInfo(result?.payload.pages[0])
				}
			});
		}
	}, [usePermCreatePageVitrine]);

	useEffect(() => {
		if (pageWarningSelector?.messages) {
			setSelectedMessage(pageWarningSelector.messages[0])
		}
	}, [pageWarningSelector?.messages]);

	const updatePagesList = () => {
		fetchPageVitrine(dispatch)().then(result => {
			setPages(result?.payload.pages)
		})
	}

	const handlePageNavigation = (item?: PageVitrineDto) => {
		if (pageHasBeenModified) {
			setModalAttentionModificationEnCoursIsOpen(true);
			navigationCallback.current = () => {
				setPageHasBeenModified(false);
				setPageClickedInfo(item || {});
			}
			return false;
		}
		else {
			setPageClickedInfo(item || {});
			return true;
		}
	}

	const handlePageWarningNavigation = (item?: MessageAlerteDto) => {
		setSelectedMessage(item || {});
	}

	const navStyle = (e: MouseEvent<HTMLElement>) => {
		const activeLink = document.querySelector(".active");

		if (activeLink !== null) {
			activeLink.classList.remove("active");
		}
		e.currentTarget.classList.add("active");
	}


	
	const [managementList, setManagementList] = useState<MenuType[]>([]);
	const [commissionList, setCommissionList] = useState<MenuType[]>([]);
	const [accountingList, setAccountingList] = useState<MenuType[]>([]);
	const [adminList, setAdminList] = useState<MenuType[]>([]);

	useEffect(() => {
		const menuManagementList = [];
		const menuCommissionList = [];
		const menuAccountingList = [];
		const menuAdminList = [];

		/** LISTE DES PAGES PRINCIPALES */
		if(usePermGetDossiers)
			menuManagementList.push({ label: 'Dossiers', link: '' })
		
		if(usePermGetStructures)
			menuManagementList.push({ label: 'Structures', link: 'Structures' })

		setManagementList(menuManagementList);
		
		/** LISTE DES COMMISSIONS */
		if(usePermGetCommissions)
			menuCommissionList.push({ label: 'Liste des commissions', link: 'ListeCommissions' })
		// if(usePermGetSessionsCA)
		// 	menuCommissionList.push({ label: 'Liste des sessions CA', link: 'SessionCA' })
		if(usePermGetCommissionsMember)
			menuCommissionList.push({ label: 'Liste des membres de commission', link: 'ListeMembresCommission' })

		setCommissionList(menuCommissionList);

		/** COMPTABILITE */
		if(usePermGetBordereaux)
			menuAccountingList.push(
				{ label: 'Gestion des bordereaux de versement', link: `Bordereaux/${TypeBordereau.Versement}` },
				{ label: 'Gestion des bordereaux de réaffectation', link: `Bordereaux/${TypeBordereau.Reaffectation}` },
			);

		if(usePermDossierNonSolde)
			menuAccountingList.push({ label: 'Balance à date', link: 'Balance' })

		if(usePermCompteBancaire)
			menuAccountingList.push({ label: 'Comptes bancaires de la Spedidam', link: 'ComptesBancairesSpedidam' })
		if(usePermCompteBancaire)
			menuAccountingList.push({ label: 'Liste des demandes de remboursement', link: 'ListeDemandesRemboursement' })

		setAccountingList(menuAccountingList);

		/** ADMINISTRATION */
		if(usePermCreateCritereDossier)
			menuAdminList.push({ label: 'Gestion des critères et rémunérations', link: 'GestionCriteresRemunerations' });

		if(usePermUpdateCategoriesFormeJuridiqueTypesDeDocuments)
			menuAdminList.push({ label: 'Gestion des documents administratifs', link: 'GestionDocumentsAdministratifs' });
		
		if(usePermCreatePageVitrine)
			menuAdminList.push({ label: 'Gestion des pages vitrines', link: '' });

		if(usePermGetUsers)
			menuAdminList.push({ label: 'Gestion des utilisateurs', link: 'GestionUtilisateurs' });

		if(usePermGetTemplateByCode)
			menuAdminList.push({ label: 'Gestion des lettres', link: 'GestionLettres' });

		if(usePermCreateOrUpdateMessageAlerte)
			menuAdminList.push({ label: "Gestion des messages d'alerte", link: '' });
		
		if(usePermGetTemplateByCode)
			menuAdminList.push({ label: 'Gestion des entités', link: 'GestionEntite' });

		if(usePermGetExportArtistAsExcel)
			menuAdminList.push({ label: 'Export informations des artistes', link: 'Export' });
		
		setAdminList(menuAdminList);
	}, [
		usePermGetDossiers,
		usePermGetCommissions,
		usePermGetSessionsCA,
		usePermGetCommissionsMember,
		usePermCompteBancaire,
		usePermGetExportArtistAsExcel
	]);

	const onDragOver = (event: MouseEvent<HTMLElement>) => {
		if (event.currentTarget.id) {
			// By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element
			event.preventDefault();
			setTargetSelectedId(event.currentTarget.id)
		}
	}

	const onDragStart = (event: MouseEvent<HTMLElement>) => {
		setElemSelectedId(event.currentTarget.id)
		event.currentTarget.style.opacity = "0.4";
	}

	const onDragEnd = (event: MouseEvent<HTMLElement>) => {
		event.currentTarget.style.opacity = "1";
	}

	const onDrop = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.currentTarget.style.opacity = "1";
		updateReorder()
	}

	const updateReorder = () => {
		const pagesId = pages.map(elem => elem.id)

		//on supprime la page de la liste
		let index = -1;
		let i = 0;
		pagesId.forEach(element => {
			if (element === elemSelectedId) {
				index = i;
			}
			i++;
		})
		if (index !== -1) {
			pagesId.splice(index, 1);
		}

		//on ajoute la page à la bonne position dans la liste
		let indexAjout = -1;
		let iAjout = 0;
		pagesId.forEach(element => {
			if (element === targetSelectedId) {
				indexAjout = iAjout;
			}
			iAjout++;
		})
		if (indexAjout !== -1) {
			if (indexAjout >= index) {
				pagesId.splice(indexAjout + 1, 0, elemSelectedId);
			} else {
				pagesId.splice(indexAjout, 0, elemSelectedId);
			}
		}

		reorderPage(dispatch, pagesId)().then(() => {
			updatePagesList()
		})
	}

	return (
		<div className="sideMenu">
			{!isOpenPagesVitrines && !isOpenPagesWarning 
				&& <>
				{managementList.length > 0 && (
					<nav className="sideMenu__item">
						<div className={clsx('sideMenu__title', {
							'sideMenu__title--isActive': gestion
						})}>
							<h3>
								<i className="fas fa-folders"></i>
								gestion
							</h3>
						</div>
						<ul>
							{managementList.map((item, idx) => (
								<li
									key={idx}
									onClick={e => {
										navStyle(e);
										dispatch(toggleMenu());
									}}
								>
									<Link to={`/Gestion/${item.link}`}
										className="navLinkList"
										onClick={() => {
											setGestion(true);
											setCommission(false);
											setComptabilite(false);
											setAdministration(false);
										}}
									>
										{item.label}
									</Link>
								</li>
							))}
						</ul>
					</nav>
				)}
				{commissionList.length > 0 && (
					<nav className="sideMenu__item">
						<div className={clsx('sideMenu__title', {
							'sideMenu__title--isActive': commission
						})}>
							<h3>
								<i className="fas fa-users"></i>
								commission
							</h3>
						</div>
						<ul>
							{commissionList.map((item, idx) => (
								<li
									key={idx}
									onClick={e => {
										navStyle(e);
										dispatch(toggleMenu());
									}}
								>
									<Link to={`/Commission/${item.link}`}
										onClick={() => {
											setGestion(false);
											setCommission(true);
											setComptabilite(false);
											setAdministration(false);
										}}
									>
										{item.label}
									</Link>
								</li>
							))}
						</ul>
					</nav>
				)}
				{accountingList.length > 0 && (
					<nav className="sideMenu__item">
						<div className={clsx('sideMenu__title', {
							'sideMenu__title--isActive': comptabilite
						})}>
							<h3>
								<i className="fas fa-balance-scale"></i>
								comptabilité
							</h3>
						</div>
						<ul>
							{accountingList.map((item, idx) => (
								<li key={idx}
									onClick={e => {
										navStyle(e);
										dispatch(toggleMenu());
									}}
								>
									<Link to={`/Comptabilite/${item.link}`}
										onClick={() => {
											setGestion(false);
											setCommission(false);
											setComptabilite(true);
											setAdministration(false);
										}}
									>
										{item.label}
									</Link>
								</li>
							))}
						</ul>
					</nav>
				)}
				{adminList.length > 0 && (
					<nav className="sideMenu__item">
						<div className={clsx('sideMenu__title', {
							'sideMenu__title--isActive': administration
						})}>
							<h3>
								<i className="fas fa-receipt"></i>
								administration
							</h3>
						</div>
						<ul>
							{adminList.map((item, idx) => (
								<li
									key={idx}
									onClick={e => {
										navStyle(e);
										if (item.label === 'Gestion des pages vitrines') {
											showPagesVitrines();
										} else if(item.label === "Gestion des messages d'alerte") {
											showPagesWarning();
										} else {
											dispatch(toggleMenu());
										}
									}}
								>
									{item.label !== 'Gestion des pages vitrines' ?
										<Link to={`/Administration/${item.link}`}
											onClick={() => {
												setGestion(false);
												setComptabilite(false);
												setAdministration(true);
											}}
										>
											{item.label}
										</Link>
										:
										<div style={{ cursor: "pointer" }}>
											{item.label}
										</div>}
									<span className="navList__link"></span>
								</li>
							))}
						</ul>
					</nav>
				)}
			</>}

			{isOpenPagesVitrines && 
				<nav className="sideMenu__item">
					<div className={clsx('sideMenu__title', {
						'sideMenu__title--isActive': pages
					})}>
						<h3>Pages</h3>
					</div>

					{pages.length > 0 && (
						<ul className="sideMenu__container">
							{pages.map(item => (
								<li
									id={item.id}
									draggable="true"
									onDragOver={e => onDragOver(e)}
									onDragStart={e => onDragStart(e)}
									onDrop={e => onDrop(e)}
									onDragEnd={e => onDragEnd(e)}
									className={item === pageClickedInfo ? "sideMenu__listItem--active box" : "sideMenu__listItem box"}
									key={item.id}
									onClick={() => handlePageNavigation(item)}
								>
									<div className="sideMenu__page">
										<i className="fas fa-grip-lines"></i>
										<span className="navList__link">{item.nom?.fr}</span>
									</div>
									<span className="sideMenu__statut">{item.statut}</span>
								</li>
							))}
						</ul>
					)}
					<div className="sideMenu__add">
						<i onClick={() => handlePageNavigation()} className="fas fa-plus-circle"></i>
					</div>
				</nav>
			}

			{isOpenPagesWarning && 
				<nav className="sideMenu__item sideMenu__itemWarning">
					<div className={clsx('sideMenu__title', {
						'sideMenu__title--isActive': pageWarningSelector?.messages
					})}>
						<h3>Warnings</h3>
					</div>

					{pages.length > 0 && (
						<ul className="sideMenu__container">
							{pageWarningSelector?.messages?.map(item => (
								<li
									id={item.id}
									className={item === selectedMessage ? "sideMenu__listItem--active box" : "sideMenu__listItem box"}
									key={item.id}
									onClick={() => handlePageWarningNavigation(item)}
								>
									<div className="sideMenu__page">
										{item.afficher &&
											<i className="fas fa-circle"></i>
										}
										<span className="navList__link">{item.nom}</span>
									</div>
								</li>
							))}
						</ul>
					)}
					<div className="sideMenu__add">
						<i onClick={() => handlePageWarningNavigation()} className="fa fa-plus-circle"></i>
					</div>
				</nav>
			}

			{usePermCreatePageVitrine && (
				<PageVitrine
					pages={pages}
					setPages={setPages}
					pageClickedInfo={pageClickedInfo}
					setPageClickedInfo={setPageClickedInfo}
					modalPageIsOpen={isOpenPagesVitrines}
					setPageHasBeenModified={setPageHasBeenModified}
					modalAttentionModificationEnCoursIsOpen={modalAttentionModificationEnCoursIsOpen}
					setModalAttentionModificationEnCoursIsOpen={setModalAttentionModificationEnCoursIsOpen}
					navigationCallback={navigationCallback.current}
					allowNavigation={allowNavigation}
				/>
			)}
			<PageWarning
				modalPageIsOpen={isOpenPagesWarning}
				selectedMessage={selectedMessage}
			/>
		</div>
	)
}

export default MenuBurger;