import React, { FunctionComponent } from 'react';
import { BOAdelUserDto } from '../../services/generated/BackOffice-api';
import { Controller, useForm, ValidationOptions } from 'react-hook-form';

import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import Input from 'adel-shared/dist/components/basics/Input';
import InputRadioYesNo from 'adel-shared/dist/components/basics/InputRadioYesNo';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { Dictionary } from 'adel-shared/dist/models';
import moment from 'moment';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useTranslation } from 'react-i18next';


interface ModalAjoutUserProps {
	onClose: () => void;
	user?: BOAdelUserDto;
	validator: Dictionary<ValidationOptions> | null;
	inputRoleOptions: AdelOption<string>[];
	onSubmit: (body: any, user?: BOAdelUserDto) => void;
}

const ModalEditUser: FunctionComponent<ModalAjoutUserProps> = ({
	onClose,
	user,
	validator,
	inputRoleOptions,
	onSubmit
}) => {
	const { t } = useTranslation();

	const onChangeRoles = (value:any) => {
		const newArray = value.filter((v:any) => v.value !== "all");
		setValue("roles", newArray);
	}

	const { getValues, triggerValidation, errors, control, setValue } = useForm<any>({
		defaultValues: {
			roles: user?.roles?.map(x => ({
				label: x.name,
				value: x.id
			})),
			nom: user?.nom,
			prenom: user?.prenom,
			email: user?.email,
			estActif: user?.estActif ?? true
		}
	});

	const handleSubmit = async() => {
		const result = await triggerValidation();
	
		if(!result) {
			return;
		}

		const body = {
			prenom: getValues().prenom,
			nom: getValues().nom,
			email: getValues().email,
			roles: getValues().roles.map((x: any) => x.value),
			estActif: getValues().estActif
		};

		onSubmit(body, user);
	};

	const formatDate = (date: Date) => {
		return moment(date).format('DD.MM.YY [à] HH:mm');
	};

	return (
		<section className="user__modal customModal customModal--fixed">
			<div className="customModal__card">
				<div className="customModal__header">
					<h2>{user ? "Modifier un utilisateur" : "Ajouter un utilisateur"}</h2>
				</div>

				<div className="customModal__main">
					{user && (
						<>
							<div className="customModal__item">
								<div className="input">
									<InputReadOnly
										label="Login"
										content={user.userName || ''}
									/>
								</div>
								<div className="input">
									<InputReadOnly
										label="Date de création"
										content={user.createdAt ? formatDate(user.createdAt) : '-'}
									/>
								</div>
							</div>
							<div className="customModal__item">
								<div className="input">
									<InputReadOnly
										label="Date d'activation"
										content={user.dateActivation ? formatDate(user.dateActivation) : '-'}
									/>
								</div>
								<div className="input">
									<InputReadOnly
										label="Date de modification"
										content={user.modifiedAt ? formatDate(user.modifiedAt) : '-'}
									/>
								</div>
							</div>
							<div className="customModal__item">
								<div className="input">
									<InputReadOnly
										label="Date de désactivation"
										content={user.dateDesactivation ? formatDate(user.dateDesactivation) : '-'}
									/>
								</div>
							</div>
						</>
					)}
					<div className="customModal__item">
						<Controller control={control}
							name="roles"
							as={({ value, name }) => (
								<InputSelect<string>
									name={name}
									label="Rôles*"
									classname="inputSelect inputSelect--multi"
									options={inputRoleOptions}
									errors={errors}
									isMulti
									onChange={onChangeRoles}
									value={value}
									placeholder={t("common.select")}
								/>
							)}
							rules={validator?.["Roles"]}
						/>

						<Controller
							control={control}
							name="nom"
							as={({ onChange, value, name }) => (
								<Input
									name={name}
									type="text"
									label="Nom*"
									errors={errors}
									onChange={onChange}
									value={value}
								/>
							)}
							rules={validator?.["Nom"]}
						/>
					</div>

					<div className="customModal__item">
						<Controller
							control={control}
							name="prenom"
							as={({ onChange, value, name }) => (
								<Input
									name={name}
									type="text"
									label="Prénom*"
									errors={errors}
									onChange={onChange}
									value={value}
								/>
							)}
							rules={validator?.["Prenom"]}
						/>

						<Controller
							control={control}
							name="email"
							as={({ onChange, value, name }) => (
								<Input
									name={name}
									type="text"
									label="Email*"
									errors={errors}
									onChange={onChange}
									value={value}
								/>
							)}
							rules={validator?.["Email"]}
						/>
					</div>

					<div className="customModal__item">
						<Controller
							control={control}
							name="estActif"
							as={({ onChange, name, checked }) => (
								<InputRadioYesNo
									name={name}
									label="Statut"
									radioLabels={['Activé', 'Désactivé']}
									onChange={onChange}
									value={checked}
								/>
							)}
						/>
					</div>
				</div>
				<div className="customModal__footer">
					<FormButton
						type="button"
						value="Annuler"
						onClick={onClose}
					/>

					<FormButton
						type="submit"
						value="Valider"
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</section>
	);
}

export default ModalEditUser;