
import { DocumentUploadInfoDto, StructureClient, StructureDocumentsDto } from '../../../services/generated/BackOffice-api';
import { AdelStructureClient } from '../../../clients/AdelStructureClient';
import { StructureActionsEnum } from './structureAction';
import { toast } from 'react-toastify';
import { Dictionary } from "adel-shared/dist/models/Dictionary";
import { Dispatch } from 'redux';
import { axiosInstance } from '../../../custom-hooks/useAxios';

/** Actions */
export const requestStructureDocuments = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_DOCUMENTS_REQUEST,
		payload: {}
	}
}

export const getStructureDocuments = (structureDocuments: StructureDocumentsDto) => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_DOCUMENTS_SUCCESS,
		payload: { structureDocuments }
	}
}

export const requestPostStructureDocuments= () => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_DOCUMENTS_REQUEST,
		payload: {}
	}
}

export const errorPostStructureDocuments = () => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_DOCUMENTS_ERROR,
		payload: {}
	}
}

export const postStructureDocuments = (structureDocuments: StructureDocumentsDto, structureDocumentsFiles?: File[]) => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_DOCUMENTS_SUCCESS,
		payload: {
			structureDocuments,
			structureDocumentsFiles
		}
	}
}
export const resetDocumentsFiles= () => {
	return {
		type: StructureActionsEnum.RESET_FILES,
		payload: {}
	}
}

/** Task */
const structureClient = new StructureClient('', axiosInstance);
const adelDossierClient = new AdelStructureClient('', axiosInstance);

export const fetchStructureDocuments = (dispatch: Dispatch, id: string) => {
	return () => {
		dispatch(requestStructureDocuments());
		return structureClient.getStructureDocuments(id)
			.then(response => dispatch(getStructureDocuments(response)))
			.catch(error => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les documents de structure");
		})
	}
}

export const sendStructureDocuments = async(
	dispatch: Dispatch,
	structureId: string,
	documents?: DocumentUploadInfoDto[],
	files?: Dictionary<File>,
	originalFiles?: File[]
) => {
	dispatch(requestPostStructureDocuments());	
	try {		
		await adelDossierClient.UpdateStructureDocuments(structureId, documents, files);
		dispatch(postStructureDocuments(
			{
				documents,
			},
			originalFiles
		));
	} catch(error) {
		dispatch(errorPostStructureDocuments());
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour les documents de structure");
		throw error;
	}
}
