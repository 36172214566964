import { Reducer } from "redux";
import { DispatchUserAction, UserActionsEnum } from "../actions/userAction";
import { UserState, initialUserState } from "../states/userState";

export const userReducer: Reducer<Partial<UserState>, DispatchUserAction> = (state = initialUserState, action) => {
    switch (action.type) {
        case UserActionsEnum.CONNECT_USER:
            return {
                ...state,
                isConnected: action.payload?.isConnected
            };
        case UserActionsEnum.GET_USER_SUCCESS:
            return { ...state, user: action.payload?.user };
        case UserActionsEnum.GET_USERS_SUCCESS:
            return { ...state, users: action.payload?.users };
        case UserActionsEnum.GET_ROLES_SUCCESS:
                return { ...state, roles: action.payload?.roles };

        /** Création utilisateur */
		case UserActionsEnum.CREATE_USER_REQUEST:
			return { ...state, isCreateLoading: true, errorUserCode: "" };
		case UserActionsEnum.CREATE_USER_SUCCESS:
			return {
				...state,
				isCreateLoading: false,
				errorUserCode: ""
			};
		case UserActionsEnum.CREATE_USER_ERROR:
            return { ...state, isCreateLoading: false, errorUserCode: action.payload?.errorUserCode };
		case UserActionsEnum.UPDATE_USER_SUCCESS:
			const currState = {
				...state,
				updatedUser: action.payload?.updatedUser
			}
			if(action.payload?.updatedUser?.id === currState.user?.id && currState.user?.roles) {
				const currentRoles:string[] = action.payload?.updatedUser?.roles?.reduce((acc:string[],curr) => {
					curr?.name && acc.push(curr.name);
					return acc;
				}, []) || [];
				currState.user.roles = currentRoles;
			}
			return currState;

        /** Permissions */
        case UserActionsEnum.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: action.payload?.permissions
            };

        default:
            return state;
    }
};
