import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Controller, useForm } from 'react-hook-form';
import InputCalendar from 'adel-shared/dist/components/basics/InputCalendar';
import ModalValidateCancelMedium from '../basics/ModalValidateCancelMedium';

interface ModalExportArtistPageVitrineProps  extends RouteComponentProps {
	isOpen: boolean;
	isLoading?: boolean;
	onCancel: () => void;
	onValidate: (data: any) => void;
}

const ModalExportArtistPageVitrine: React.SFC<ModalExportArtistPageVitrineProps> = ({
	isOpen,
	isLoading,
	onCancel,
	onValidate,
}) => {

	const {watch, control, getValues, setValue, register, errors} = useForm();
	const handleSubmit = () => {
        onValidate(getValues());
    };
	return (
		<ModalValidateCancelMedium
			isOpen={isOpen}
			onCancel={onCancel}
			onValidate={handleSubmit}
			isLoading={isLoading}
			title="Exporter les artistes"
			validateButtonLabel="Exporter"
		>
			<div className="modal__item">
								<div>
                                	<label>Date d'export des artistes du:</label>
								</div>
							<div className="input" style={{display: 'flex', justifyContent: 'space-between',alignContent: 'stretch'}}>
								<div className="dateRange" style={{minWidth: '300px'}}>
                                    <Controller
                                        control={control}
                                        name="dateDebutExport"
                                        as={({name, onChange, value}) => (
                                            <InputCalendar
                                                defaultDate={value}
                                                onDateSelected={onChange}
                                                name={name}
                                                maxDate={undefined}
                                            />
                                        )}
                                    />
								</div>
								<div>
									 au :
								</div>
								<div className="dateRange" style={{minWidth: '300px'}}>
										<Controller
											control={control}
											name="dateFinExport"
											as={({name, onChange, value}) => (
												<InputCalendar
													defaultDate={value}
													onDateSelected={onChange}
													name={name}
													minDate={undefined}
												/>
											)}
										/>
								</div>
							</div>

			</div>
		</ModalValidateCancelMedium>
	);
}

export default ModalExportArtistPageVitrine;