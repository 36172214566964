import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Logo from "../../assets/logo/logo_spedidam_white_bg.png";
import {BOCurrentUserDto, DossierDetailsDto } from '../../services/generated/BackOffice-api';
import { useDispatch } from 'react-redux';
import { getMessageAlerte } from '../../store/page-warning-store/pageWarningAction';
import { useDossierSelector, useGeneralSelector, usePageWarningSelector } from '../../store/store-helpers';
import { logout } from "../../services/Auth/AuthProvider";
import clsx from "clsx";
import { UserActionsEnum } from "../../store/user-store/actions/userAction";

interface TopBarProps {
	user: BOCurrentUserDto;
	onBurgerClick: () => void;
}

const TopBar: React.FunctionComponent<TopBarProps> = ({
	user,
	onBurgerClick
}) => {
	const {isMenuOpen} = useGeneralSelector();
	const { message = {} } = usePageWarningSelector();
	const dispatch = useDispatch();

	const {
		dossierDetails: details = {},
		dossierRelations,
	} = useDossierSelector();

	const [dossierInfo, setDossierInfo] = useState<DossierDetailsDto>({})
	useEffect(() => {
		setDossierInfo(details)
	},[details])

	
	useEffect(() => {
		(async() => {
			await getMessageAlerte(dispatch);
		})();
	}, []);

	const handleLogout = () => {
		dispatch({type: UserActionsEnum.LOGOUT_USER});
		logout();
	};

	return (
		<>
			<div className={clsx('menuBurger__nav', {
				'menuBurger__cross': isMenuOpen
			})}>
				<input
					type="checkbox"
					className="menuBurger__checkbox"
					onClick={onBurgerClick}
				/>
				<span className="top"></span>
				<span className="middle"></span>
				<span className="bottom"></span>
			</div>
			<div className="topBar">
				{ dossierInfo ?
					<>
						<div className="topbar_details">
							{/* <h2 className="details-header-titles__subtitle">Nom du dossier</h2> */}
							{(dossierInfo.numero && dossierInfo.categorie) && (
								<p>N°{dossierInfo.numero} / {dossierInfo.categorie?.nom}</p>
							)}
							<h1 className="dossierInfo-header-titles__title">{dossierInfo.nom}</h1>
						</div>

						<Link to="/">
							<img src={Logo} alt="Logo Spedidam" />
						</Link>
					</>
				:
					<>
						<div className="topBar__left">
							<div className="topBar__item">
								<Link to="/">
									<img src={Logo} alt="Logo Spedidam" />
								</Link>
							</div>
						</div>
						{message?.message && (
							<div className="topBar__messageWarning">
								<i className="far fa-exclamation-triangle" /> {message.message}
							</div>
						)}
					</>
				}

				{user && (
					<div className="topBar__right">
						<div className="topBar__item">
							<p>Bonjour {user.prenom} {user.nom}</p>
						</div>
						<button type="button" onClick={handleLogout}>
							Se déconnecter
						</button>
					</div>
				)}
			</div>
		</>
	)
}

export default TopBar;