import React from 'react';
import InputReadOnly from '../InputReadOnly';
import InputCalendar from '../basics/InputCalendar';
import moment from 'moment';
import { formatPhone, formaterDate } from '../../utils/functions';
const Informations = ({ details, dateProlongation, onDateSelected }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Num\u00E9ro r\u00E9f\u00E9rence dossier", content: (details === null || details === void 0 ? void 0 : details.numero) || '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Type de dossier", content: ((_a = details === null || details === void 0 ? void 0 : details.categorie) === null || _a === void 0 ? void 0 : _a.nom) || '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nom du projet", content: (details === null || details === void 0 ? void 0 : details.nom) || '-' })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Civilit\u00E9 du responsable", content: ((_b = details === null || details === void 0 ? void 0 : details.responsable) === null || _b === void 0 ? void 0 : _b.civilite) || '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nom du responsable", content: ((_c = details === null || details === void 0 ? void 0 : details.responsable) === null || _c === void 0 ? void 0 : _c.nom) || '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Pr\u00E9nom du responsable", content: ((_d = details === null || details === void 0 ? void 0 : details.responsable) === null || _d === void 0 ? void 0 : _d.prenom) || '-' })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Email du responsable", content: ((_e = details === null || details === void 0 ? void 0 : details.responsable) === null || _e === void 0 ? void 0 : _e.email) || '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "T\u00E9l\u00E9phone fixe du responsable", content: ((_f = details === null || details === void 0 ? void 0 : details.responsable) === null || _f === void 0 ? void 0 : _f.telephoneFixe) ? formatPhone(details.responsable.telephoneFixe) : '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "T\u00E9l\u00E9phone portable du responsable", content: ((_g = details === null || details === void 0 ? void 0 : details.responsable) === null || _g === void 0 ? void 0 : _g.telephonePortable) ? formatPhone(details.responsable.telephonePortable) : '-' })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Votre projet se d\u00E9roule", content: (details === null || details === void 0 ? void 0 : details.dateDebut) && (details === null || details === void 0 ? void 0 : details.dateFin) ? `du ${formaterDate(details.dateDebut)} au ${formaterDate(details.dateFin)}` : '-' }))),
            onDateSelected && (React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement("label", null, "Date de prolongation"),
                    React.createElement(InputCalendar, { label: "", minDate: moment(details === null || details === void 0 ? void 0 : details.dateFin).add(1, 'days').toDate(), defaultDate: dateProlongation && moment(dateProlongation).toDate(), onDateSelected: onDateSelected, unlock: true })))))));
};
export default Informations;
