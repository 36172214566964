import React, { useState, useEffect, useMemo, FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { useTable, useSortBy } from 'react-table';

import { fetchUsersByRole, fetchUserRoles, updateUser, createUser } from '../../store/user-store/actions/userAction';
import { useUserSelector } from '../../store/store-helpers';
import { BOAdelUserDto } from '../../services/generated/BackOffice-api';

import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import ModalEditUtilisateur from './ModalEditUtilisateur';
import useValidation from '../../custom-hooks/useValidation';
import { toast } from 'react-toastify';
import NoItemsTable from '../basics/NoItemsTable';

interface GestionUtilisateursProps extends RouteComponentProps {
}

const GestionUtilisateurs: FunctionComponent<GestionUtilisateursProps> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userSelector = useUserSelector();
	const { getRootValidator } = useValidation();
	const userValidator = getRootValidator("CreateUserDto");

	/** Recherche */
	const [userSearch, setUserSearch] = useState<string>("");

	/** Filtres */
	const [rolesFiltre, setRolesFiltre] = useState<string>('');
	const [inputRoleOptions, setInputRoleOptions] = useState<AdelOption<string>[]>([]);

	/** Modal utilisateur */
	const [isModalAddOpen, setIsModalAddOpen] = useState<boolean>(false);

	/** Modification utilisateur */
	const [changeUser, setChangeUser] = useState<BOAdelUserDto>();

	/** Table */
	const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		const users = userSelector?.users?.map(user => ({
			Nom: user.nom,
			Prenom: user.prenom,
			Email: user.userName,
			Role: user.roles?.map(x => x.name).join(', '),
			Statut: user.estActif ? (
				<span className="table__statutItem table__statut--enabled">Activé</span>
			) : (
				<span className="table__statutItem table__statut--disabled">Désactivé</span>
			),
			Id: user.id
		})) || [];

		setData(users);
	}, [userSelector.users])

	useEffect(() => {
		if (userSelector.roles) {
			let userRoles = userSelector.roles.map(userRole => ({
				label: userRole.name || '',
				value: userRole.id || ''
			}));

			userRoles.unshift({
				label: 'Tous les rôles',
				value: 'all'
			});

			setInputRoleOptions(userRoles);
		}
	}, [userSelector.roles]);

	useEffect(() => {
        (async () => {
            await fetchUsersByRole(dispatch, [rolesFiltre], userSearch);
		})();
	}, [dispatch, userSearch, rolesFiltre]);

	useEffect(() => {
		fetchUserRoles(dispatch)
	}, [dispatch]);

	const handleSearch = debounce((value: string) => {
		setUserSearch(value);
	}, 500);

	const handleAddUser = () => {
		setIsModalAddOpen(true);
		setChangeUser(undefined);
	};

	const handleEditUser = (id: string) => {
		setIsModalAddOpen(true);
		setChangeUser(userSelector.users?.find(x => x.id === id));
	};

	const handleSubmit = async(body: any, user?: BOAdelUserDto) => {
		if(user?.id) {
			await updateUser(user.id, body, dispatch);
			toast.success(t('common.success'));
		} else {
			await createUser(dispatch, body);
			toast.success(t('common.success'));
		}
		setIsModalAddOpen(false);

		await fetchUsersByRole(dispatch, [rolesFiltre], userSearch);
	};

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Nom", accessor: "Nom", sortType: "basic" },
					{ Header: "Prénom", accessor: "Prenom", sortType: "basic" },
					{ Header: "Email", accessor: "Email", sortType: "basic" },
					{ Header: "Rôle", accessor: "Role", sortType: "basic" },
					{ Header: "Statut", accessor: "Statut", sortType: "basic" },
					{ Header: "", accessor: "Actions", disableSortBy: true,
						Cell: (value: any) => (
							<span onClick={() => handleEditUser(value.cell.row.values.Id)} className="commission__tableButtons" role="button">
								<i className="far fa-edit"></i>
							</span>
					)},
					{ Header: "Id", accessor: "Id" }
				]
			}
		];
	}, [userSelector.users]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id", "StatutOrigin"],
			}
		} as any,
		useSortBy
	);

	return (
		<div className="gestionUser tablePage">
			<div className="tablePage__header upperTable">
				<h1>Administration - Gestion des utilisateurs</h1>
			</div>

			<div className="tableStyle">
				<div className="gestionUser__header tableStyle__header">
					<div className="filtres__item">
						<div className="filtres__search">
							<i className="fas fa-search"></i>

							<Input
								autoComplete="off"
								name="recherche"
								className="filter__input"
								placeHolder="Recherche"
								type="text"
								maxLength={120}
								onChange={handleSearch}
							/>
						</div>

						<InputSelect
							name="roles"
							classname=""
							options={inputRoleOptions}
							onChange={(elem) => {
								setRolesFiltre(elem)
							}}
							value={rolesFiltre}
						/>
					</div>

					<div className="tableStyle__addButton"
						onClick={handleAddUser}
					>
						<label>Ajouter un utilisateur</label>

						<button>
							<i className="far fa-plus"></i>
						</button>
					</div>
				</div>

				{userSelector?.users && userSelector.users.length === 0 ? (
					<NoItemsTable />
				) : (
					<Table
						data={data}
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						headerGroups={headerGroups}
						rows={rows}
						prepareRow={prepareRow}
						styles={styleTable.page}
					/>
				)}
			</div>

			{isModalAddOpen &&
				<ModalEditUtilisateur
					onClose={() => setIsModalAddOpen(false)}
					user={changeUser}
					validator={userValidator}
					inputRoleOptions={inputRoleOptions}
					onSubmit={handleSubmit}
				/>
			}
		</div>
	);
}

export default GestionUtilisateurs;