import { CategorieDossierDto, CritereDossierDto } from '../../../services/generated/BackOffice-api';

export interface CritereDossierState {
	criteresIsLoading: boolean;
	critereIsLoading: boolean;
	criteres?: CritereDossierDto[];
	critere?: CritereDossierDto;
	newCritere?: CritereDossierDto;
}

export const initialCritereDossier: CritereDossierState = {
	criteresIsLoading: false,
	critereIsLoading: false,
	criteres: [],
	critere: {},
	newCritere: {},
};