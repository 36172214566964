import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { BOVersementDtoSortedFilteredPage, DossierClient, DossierConventionAndCourrierDto, UpdateDSVInstructionVersementDto, UpdateStatutVersementDto, VersementClient } from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from './dossierAction';

/** Task */
const dossierClient = new DossierClient('', axiosInstance);
const versementClient = new VersementClient('', axiosInstance);

export const putDossierAgreement = async (dispatch: any, dossierId: string, value: Date) => {
	dispatch({ type: DossierActionsEnum.PUT_DOSSIER_AGREEMENT_REQUEST });
	try {
		await dossierClient.updateDossierAgreement(dossierId, value);
		dispatch({ type: DossierActionsEnum.PUT_DOSSIER_AGREEMENT_SUCCESS });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.PUT_DOSSIER_AGREEMENT_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour l'agrément de dossier");
		throw error;
	}
}

export const getDossierVersement = async (dispatch: any, dossierId: string) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_CONVENTION_COURRIER_REQUEST });
	try {
		let response:DossierConventionAndCourrierDto = await dossierClient.getDossierVersement(dossierId);
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_CONVENTION_COURRIER_SUCCESS, payload: {conventionCourrier: response} });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_CONVENTION_COURRIER_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer le versement de dossier");
		throw error;
	}
}

export const getDossierVersements = async (
	dispatch: any,
	dossierId: string,
	sort?: string
) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_VERSEMENTS_REQUEST });
	try {
		let response:BOVersementDtoSortedFilteredPage = await dossierClient.getDossierVersements("", sort, 1, 10, true, dossierId);
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_VERSEMENTS_SUCCESS, payload: {dossierVersements: response} });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_VERSEMENTS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les versements");
		throw error;
	}
}

export const updateDsvVersement = async (
	dispatch: any,
	versementId: string,
	body: UpdateDSVInstructionVersementDto
) => {
	dispatch({ type: DossierActionsEnum.PUT_INSTRUCTION_VERSEMENT_DSV_REQUEST });
	try {
		await versementClient.updateDSVInstructionVersement(versementId, body);
		dispatch({ type: DossierActionsEnum.PUT_INSTRUCTION_VERSEMENT_DSV_SUCCESS });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.PUT_INSTRUCTION_VERSEMENT_DSV_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour l'instruction de versement Dsv");
		throw error;
	}
}

export const updateVersementStatut = async(
	versementId: string,
	body: UpdateStatutVersementDto
) => {
	try {
		await versementClient.updateStatutDemandeVersement(versementId, body);
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour le statut du versement");
		throw error;
	}
};

export const recalculMasseSalariale = async (
	dispatch: any,
	versementId: string
) => {
	try {
		const response = await versementClient.getRecalculMasseSalariale(versementId);
		dispatch({ type: DossierActionsEnum.RECALCUL_MASSE_SALARIALE_SUCCESS, payload: {masseSalariale: response} });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.RECALCUL_MASSE_SALARIALE_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors du calcul de la masse salariale.");
		throw error;
	}
}

export const getVersementDetails = async (
	dispatch: any,
	versementId: string
) => {
	dispatch({ type: DossierActionsEnum.GET_VERSEMENT_DETAILS_REQUEST, payload: {} });
	try {
		const response = await versementClient.getVersementDetails(versementId);
		dispatch({ type: DossierActionsEnum.GET_VERSEMENT_DETAILS_SUCCESS, payload: {detailsVersement: response} });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_VERSEMENT_DETAILS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les détails de versement");
		throw error;
	}
}

export const getDemandeVersementMasseSalariale = async (
	dispatch: any,
	versementId: string
) => {
	try {
		const response = await versementClient.getDemandeVersementMasseSalariale(versementId);
		dispatch({ type: DossierActionsEnum.GET_VERSEMENT_MASSE_SALARIALE_SUCCESS, payload: {masseSalarialeVersement: response} });
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_VERSEMENT_MASSE_SALARIALE_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer la masse salariale");
		throw error;
	}
}


export const prendreEnChargeDossierVersement = async (
	dispatch: any,
	dossierId: string
) => {
	try {
		await dossierClient.prendreEnChargeDossierVersement(dossierId);
		dispatch({ type: DossierActionsEnum.PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_SUCCESS });
		toast.success("Pris en charge");
	} catch(error) {
		dispatch({ type: DossierActionsEnum.PRENDRE_EN_CHARGE_DOSSIER_VERSEMENT_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors de la prise en charge du dossier");
		error.title && toast.error(error.title);
	}
}
