import React, { FunctionComponent } from "react";
import NoFolder from "../../assets/img/undraw-not-found.png";

interface NoItemsTableProps {
    text?: string;
}

const NoItemsTable: FunctionComponent<NoItemsTableProps> = ({
    text = "Aucune donnée disponible dans le tableau."
}) => {
    return (
        <div className="noFolder">
            <div className="noFolder__item">
                <img src={NoFolder} alt="" />
                <p>{text}</p>
            </div>
        </div>
    );
};

export default NoItemsTable;