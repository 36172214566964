import { Reducer } from "redux";
import { VueActionsEnum } from '../actions/vueAction';
import { DispatchVueAction } from '../actions/vueAction';
import { VueState, initialVueState } from '../states/vueState';

export const vueReducer: Reducer<Partial<VueState>, DispatchVueAction> = (state = initialVueState, action) => {
	switch (action.type) {
		case VueActionsEnum.GET_VUES_REQUEST:
			return { ...state, vueIsLoading: true };
		case VueActionsEnum.GET_VUES_SUCCESS:
			return {
				...state,
				vueIsLoading: false,
				vues: action.payload.vues
			};
		case VueActionsEnum.GET_VUES_ERROR:
			return { ...state, vueIsLoading: false };
		case VueActionsEnum.POST_VUE_REQUEST:
			return { ...state, vueIsLoading: true };
		case VueActionsEnum.POST_VUE_SUCCESS:
			return {
				...state,
				vueIsLoading: false,
				vues: action.payload.vues
			};
		case VueActionsEnum.POST_VUE_ERROR:
			return { ...state, vueIsLoading: false };
		case VueActionsEnum.DELETE_VUE_REQUEST:
			return { ...state, vueDeleteIsLoading: true };
		case VueActionsEnum.DELETE_VUE_SUCCESS:
			return { ...state, vueDeleteIsLoading: false };
		case VueActionsEnum.DELETE_VUE_ERROR:
			return { ...state, vueDeleteIsLoading: false };

		default:
			return state;
	}
};
