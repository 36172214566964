import React, { useCallback } from 'react';

import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import Input from 'adel-shared/dist/components/basics/Input';

import { useTranslation } from 'react-i18next';
import { TypePrestation, Emploi, Groupe } from '../../../services/generated/BackOffice-api';

interface FiltersProps {
	setTypeDossier: (value: string) => void;
	setTypeArtiste: (value: string) => void;
	setTypeSession: (value: string) => void;
	setTypeMusique: (value: string) => void;
	setMontantMinimum: (value: number) => void;
	typeDossier: string;
	typeArtiste: string;
	typeSession: string;
	typeMusique: string;
	montantMinimum?: number;
	typeDossierOptions: AdelOption<string>[];
	typeMusiqueOptions: AdelOption<string>[];
}

const RemunerationsFilters: React.FunctionComponent<FiltersProps> = ({
	setTypeDossier,
	setTypeArtiste,
	setTypeSession,
	setTypeMusique,
	setMontantMinimum,
	typeDossier,
	typeArtiste,
	typeSession,
	typeMusique,
	montantMinimum,
	typeDossierOptions,
	typeMusiqueOptions
}) => {
	const { t } = useTranslation();

	/** Options */
	const typeSessionOptions = () => {
		let allOptions: AdelOption<string>[] = [];

		for (let session in TypePrestation) {
			const sessionValue = TypePrestation[session as keyof typeof TypePrestation];
			if (sessionValue !== TypePrestation.None) {
				allOptions.push(
					{
						value: sessionValue,
						label: t(`structure.prestation.${sessionValue}`)
					}
				)
			}
		}
		allOptions.push({ value: "all", label: t(`structure.prestation.all`)});
		return allOptions;
	};

	const typeArtisteOptions = () => {
		let allOptions: AdelOption<string>[] = [];

		for (let artiste in Groupe) {
			const artisteValue = Groupe[artiste as keyof typeof Groupe];
			if (artisteValue !== Groupe.None) {
				allOptions.push(
					{
						value: artisteValue,
						label: t(`structure.groupes.${artisteValue}`)
					}
				)
			}
		}
		allOptions.push({ value: "all", label: t(`structure.groupes.all`)});
		return allOptions;
	};



	/** On change */
	const onChangeTypeDossier = (elem: any) => {
		if (elem !== "all") {
			setTypeDossier(elem);
		} else {
			setTypeDossier("all");
		}
	}

	const onChangeTypeArtiste = (elem: any) => {
		if (elem !== "all") {
			setTypeArtiste(elem);
		} else {
			setTypeArtiste("all");
		}
	}

	const onChangeTypeSession = (elem: any) => {
		if (elem !== "all") {
			setTypeSession(elem);
		} else {
			setTypeSession("all");
		}
	}

	const onChangeTypeMusique = (elem: any) => {
		if (elem !== "all") {
			setTypeMusique(elem);
		} else {
			setTypeMusique("all");
		}
	}

	const handleMontantMinChange = useCallback((value: string) => {
		setMontantMinimum(parseFloat(value));
	}, [setMontantMinimum]);

	return (
		<div className="filtres__item">
			<label>Recherches :</label>
			<div className="filtres__search">
				<InputSelect
					name="Type de dossier"
					classname={`filtreItem__inputSelect ${typeDossier !== "all" && "filtreItem__inputSelect--selected"}`}
					options={typeDossierOptions}
					onChange={(elem) => {
						onChangeTypeDossier(elem)
					}}
					value={typeDossier}
				/>
				<InputSelect
					name="Type d'artiste"
					classname={`filtreItem__inputSelect ${typeArtiste !== "all" && "filtreItem__inputSelect--selected"}`}
					options={typeArtisteOptions()}
					onChange={(elem) => {
						onChangeTypeArtiste(elem)
					}}
					value={typeArtiste}
				/>
				<InputSelect
					name="Type de session"
					classname={`filtreItem__inputSelect ${typeSession !== "all" && "filtreItem__inputSelect--selected"}`}
					options={typeSessionOptions()}
					onChange={(elem) => {
						onChangeTypeSession(elem)
					}}
					value={typeSession}
				/>
				<Input
					name="montant minimum"
					className="filter__input"
					placeHolder="Montant minimum"
					type="number"
					maxLength={60}
					value={montantMinimum}
					onChange={handleMontantMinChange}
				/>
			</div>
		</div>
	);
}

export default RemunerationsFilters;
