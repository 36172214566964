import { Action } from 'redux';
import { GeneralState } from '../states/generalState';

export enum GeneralActionsEnum {
	TOGGLE_MENU = "TOGGLE_MENU",
	CLOSE_MENU = "CLOSE_MENU"
}

export interface DispatchGeneralAction extends Action<GeneralActionsEnum> {
	payload: GeneralState;
}

export const toggleMenu = () => {
	return {
		type: GeneralActionsEnum.TOGGLE_MENU
	}
}

export const closeMenu = () => {
	return {
		type: GeneralActionsEnum.CLOSE_MENU
	}
}