import React, { FunctionComponent, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import { RouteComponentProps } from '@reach/router';
import { modalLargerCustomStyles } from "../../../constants/config.constant";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import BordereauxTabs from '../../gestion-bordereaux/BordereauxTabs';
import { clotureBordereauReaffectation, getBordereauReaffectationAmendable } from '../../../store/bordereau-store/actions/bordereauActions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useBordereauSelector } from '../../../store/store-helpers';

interface ModalValidationReaffectations extends RouteComponentProps {
	isOpen: boolean;
	onClose: () => void;
}

const ModalValidationReaffectations: FunctionComponent<ModalValidationReaffectations> = ({
	isOpen,
	onClose
}) => {
    const dispatch = useDispatch();
    const { bordereauReaffectationAmendable = {} } = useBordereauSelector();

	useEffect(() => {
        (async() => {
			await getBordereauReaffectationAmendable(dispatch);
        })();
    }, []);

	const handleClotureClick = async() => {
        try {
            await clotureBordereauReaffectation();
			onClose();
            toast.success('Le bordereau a été clôturé avec succès.');
        } catch {
            toast.error('Une erreur s\'est produite pendant la clôture du bordereau.');
        }
    };

	/** React table config */
	const columns = React.useMemo(() => {
		return  [
            {
                Header: "Numéro de dossier",
                accessor: "dossierNumero",
                sortType: "basic"
            },
            {
                Header: "Dossier",
                accessor: "dossierNom",
                sortType: "basic"
            },
            {
                Header: "Bénéficiaire",
                accessor: "beneficiaire",
                sortType: "basic"
            },
            {
                Header: "Code structure",
                accessor: "userName",
                sortType: "basic"
            },
            {
                Header: "Montant accordé",
                accessor: "montantAccorde",
                sortType: "basic",
				className:'table__cell--montants'
            },
            {
                Header: "Montant réaffecté",
                accessor: "montantReaffecte",
                sortType: "basic",
				className:'table__cell--montants'
            },
            {
                Header: "Motif",
                accessor: "motif",
                sortType: "basic"
            }
        ];
	}, []);



	return (
		<Modal
			isOpen={isOpen}
			style={modalLargerCustomStyles}
			className="normal__modal modal__bordereaux-versement"
		>
			<div className="modal__header">
				<h3>Valider les réaffectations</h3>
			</div>

			<div className="modal__content">
				<BordereauxTabs
                    bordereau={bordereauReaffectationAmendable}
                    tableColumns={columns}
                    onCloture={handleClotureClick}
                />
			</div>

			<div className="modal__footer">
				<FormButton
					type="button"
					value="Fermer"
					onClick={onClose}
				/>
				<div></div>
			</div>
		</Modal>
	);
}

export default ModalValidationReaffectations;