import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { modalLargerCustomStyles } from '../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { DoublonArtisteDto } from '../../services/generated/BackOffice-api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface ModalDoublonArtisteLieuDateProps {
	isOpen: boolean;
	closeModal: () => void;
	doublons: DoublonArtisteDto[];
}

const ModalDoublonArtisteLieuDate: FunctionComponent<ModalDoublonArtisteLieuDateProps> = ({
	isOpen,
	closeModal,
	doublons
}) => {
	const { t } = useTranslation();
	const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		if(!doublons.length) {
			return;
		}

		setData(doublons.map(x => ({
			...x,
			numeroDossier: (
				<a href={`/Dossier/${x.dossierId}`} target="_blank">
					{x.numeroDossier}
				</a>
			),
			prestation: t(`structure.prestation.${x.prestation?.toLowerCase()}`),
			dates: x.dates?.map(y => moment(y).format('DD/MM/YYYY')).join(', ')
		})));
	}, [doublons]);

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Numéro dossier",
						accessor: "numeroDossier",
						disableSortBy: true
					},
					{
						Header: "Nom dossier",
						accessor: "nomDossier",
						disableSortBy: true
					},
					{
						Header: "Type dossier",
						accessor: "categorie",
						disableSortBy: true
					},
					{
						Header: "Nom structure",
						accessor: "nomStructure",
						disableSortBy: true
					},
					{
						Header: "Nom",
						accessor: "nom",
						disableSortBy: true
					},
					{
						Header: "Prénom",
						accessor: "prenom",
						disableSortBy: true
					},
					{
						Header: "Date(s) commune(s)",
						accessor: "dates",
						disableSortBy: true
					},
					{
						Header: "Type prestation",
						accessor: "typePrestation",
						disableSortBy: true
					},
					{
						Header: "Prestation",
						accessor: "prestation",
						disableSortBy: true
					},
					{
						Header: "Adresse",
						accessor: "adresse",
						disableSortBy: true
					}
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			manualSortBy: true
		} as any,
		useSortBy,
		useRowSelect
    );

    return (
		<Modal isOpen={isOpen} style={modalLargerCustomStyles} className="normal__modal doublonsModal">
			<div className="modal__header">
				<h3>Doublons "Artiste/Lieu/Date"</h3>
			</div>
			<div className="modal__content modal__content--scroll">
				<Table
					className="table--doublons"
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.onglet}
				/>
			</div>
			<div className="modal__footer">
				<FormButton
					type="button"
					value="Fermer"
					onClick={closeModal}
				/>
			</div>
		</Modal>
	)
};

export default ModalDoublonArtisteLieuDate;