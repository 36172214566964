import { Action, Dispatch } from 'redux';
import { CommissionClient, DossierClient, UpdateDossierMontantCADto } from '../../../services/generated/BackOffice-api';
import { CommissionState } from '../states/commissionState';
import { CommissionActionsEnum } from './commissionActions';
import { toast } from 'react-toastify';
import { nomFilterKey } from "../../../constants/filters.constant";
import { axiosInstance } from '../../../custom-hooks/useAxios';

export interface DispatchCommissionAction extends Action<CommissionActionsEnum> {
	payload: CommissionState;
}

const commissionClient = new CommissionClient('', axiosInstance);
const dossierClient = new DossierClient('', axiosInstance);

export const fetchSessionsCA = async (
	dispatch: Dispatch,
	disablePagination: boolean = false,
	page: number = 1,
	pageSize: number = 3,
	sort?: string
) => {
	const filters: string[] = [];

	dispatch({ type: CommissionActionsEnum.GET_SESSIONS_CA_REQUEST });
	try {
		let response = await commissionClient.getSessionsCA("", sort, page, pageSize, disablePagination);
		dispatch({ type: CommissionActionsEnum.GET_SESSIONS_CA_SUCCESS, payload: { sessionsCA: response } });
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.GET_SESSIONS_CA_ERROR });
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les sessions CA");
		throw "error";
	}
}

export const fetchSessionCADossiers = async (
	dispatch: Dispatch,
	commissionId: string,
	nomFiltre: string,
	page: number = 1,
	pageSize: number = 3,
	disablePagination: boolean = false,
	sort?: string
) => {
	const filters: string[] = [];
	if(nomFiltre)
		filters.push(`${nomFilterKey}${nomFiltre}`);

	dispatch({ type: CommissionActionsEnum.GET_SESSION_CA_DOSSIERS_REQUEST });
	try {
		let response = await commissionClient.getSessionCADossiers(commissionId, "", sort, page, pageSize, disablePagination);
		dispatch({ type: CommissionActionsEnum.GET_SESSION_CA_DOSSIERS_SUCCESS, payload: { sessionCADossiers: response } });
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.GET_SESSION_CA_DOSSIERS_ERROR });
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les dossiers de la session CA");
		throw "error";
	}
}


export const sendMontantCA = async (
	dispatch: Dispatch,
	idDossier: string,
	body: UpdateDossierMontantCADto
) => {
	dispatch({ type: CommissionActionsEnum.ADD_MONTANT_COMMISSION_CA_REQUEST });
	try {
		await dossierClient.updateDossierMontantCA(idDossier, body);
		dispatch({
			type: CommissionActionsEnum.ADD_MONTANT_COMMISSION_CA_SUCCESS,
			payload: {
				commissionDetailsDossier: {
					id: idDossier,
					montantCA: body.montant
				}
			}
		});
		toast.success("L'opération a été effectuée avec succès.");
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Une erreur est survenue durant la mise à jour des données.");
		dispatch({ type: CommissionActionsEnum.ADD_MONTANT_COMMISSION_CA_ERROR });
		throw "error";
	}
}

export const closeSessionCA = async (
	dispatch: Dispatch,
	id: string
) => {
	dispatch({ type: CommissionActionsEnum.CLOSE_SESSION_CA_REQUEST });

	try {	
		await commissionClient.closeSessionCA(id);
		dispatch({ type: CommissionActionsEnum.CLOSE_SESSION_CA_SUCCESS })
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.CLOSE_SESSION_CA_ERROR });

		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de fermer la commission");
		throw "error";
	}
}
