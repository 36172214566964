import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { Dictionary } from 'lodash';
import { StatutStructure, DocumentUploadInfoDto, CreateOrUpdateAdresseDto, StructureDetailsDto, Unit } from '../services/generated/BackOffice-api';

export interface CreateOrUpdateDonneesBancairesDto {
    iban?: string | undefined;
    bic?: string | undefined;
    banque?: string | undefined;
    adresse?: CreateOrUpdateAdresseDto;
	rib?: DocumentUploadInfoDto;
}
export class AdelStructureClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    deleteStructure(structureId: number, cancelToken?: CancelToken | undefined): Promise<Unit> {
        let url_ = this.baseUrl + "/api/v1/Structure/{structureId}/delete";
        if (structureId === undefined || structureId === null)
            throw new Error("The parameter 'structureId' must be defined.");
        url_ = url_.replace("{structureId}", encodeURIComponent("" + structureId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "DELETE",
            url: url_,
            headers: {
                "Accept": "application/json"
            },
            cancelToken
        };

        return this.instance.request(options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processDeleteStructure(_response);
        });

    }

    protected processDeleteStructure(response: AxiosResponse): Promise<Unit> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = resultData200;
            return Promise.resolve<Unit>(result200);

        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<Unit>(<any>null);
    }

	updateStructureDetails(structureId:string,
							email: string | null | undefined,
							line1: string | null | undefined,
							line2: string | null | undefined,
							ville: string | null | undefined,
							codePostal: string | null |undefined,
							documents: DocumentUploadInfoDto[] | null | undefined,
							files: Dictionary<File> | null | undefined): Promise<StructureDetailsDto> {
        let url_ = this.baseUrl + "/api/v1/Structure/{structureId}/details";
		 if (structureId === undefined || structureId === null)
            throw new Error("The parameter 'structureId' must be defined.");
        url_ = url_.replace("{structureId}", encodeURIComponent("" + structureId));
        url_ = url_.replace(/[?&]$/, "");

		const body = {
			representantLegalEmail: email,
			adresseCorrespondance: {
				line1,
				line2,
				codePostal,
				ville
			},
			documents
		}

        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        if(files) {
            appendFileDictionary(formData, files);
        }

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
		};

		return this.instance.put(url_, formData, options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdateStructureDetails(_response);
        });
    }

	protected processUpdateStructureDetails(response: AxiosResponse): Promise<StructureDetailsDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404  = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<StructureDetailsDto>(<any>null);
    }


    /**
     * @param statut (optional) 
     * @param categoriesDossierSelectionnees (optional) 
     * @param documents (optional) 
     * @param files (optional) 
     * @return Success
     */
     updateStructureInstruction(structureId: string | null, statut: StatutStructure | undefined, categoriesDossierSelectionnees: string[] | null | undefined, documents: DocumentUploadInfoDto[] | null | undefined, files: Dictionary<File> | null | undefined) : Promise<void> {
        let url_ = this.baseUrl + "/api/v1/Structure/{structureId}/instruction";
        if (structureId === undefined || structureId === null)
            throw new Error("The parameter 'structureId' must be defined.");
        url_ = url_.replace("{structureId}", encodeURIComponent("" + structureId));
        url_ = url_.replace(/[?&]$/, "");

        const body = {
			statut,
			categoriesDossierSelectionnees,
			documents
		}
		let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        if(files) {
            appendFileDictionary(formData, files);
        }
        
        const options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        return this.instance.post(url_, formData, options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdateStructureInstruction(_response);
        });
    }

    /**
     * @param documents (optional) 
     * @param files (optional) 
     * @return Success
     */
     UpdateStructureDocuments(structureId: string | null, documents: DocumentUploadInfoDto[] | null | undefined, files: Dictionary<File> | null | undefined) : Promise<void> {
        let url_ = this.baseUrl + "/api/v1/Structure/{structureId}/documentsComplementaire";
        
        if (structureId === undefined || structureId === null)
            throw new Error("The parameter 'structureId' must be defined.");
        url_ = url_.replace("{structureId}", encodeURIComponent("" + structureId));
        url_ = url_.replace(/[?&]$/, "");

        const body = {
			documents
		}
		let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        if(files) {
            appendFileDictionary(formData, files);
        }
        
        const options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        return this.instance.post(url_, formData, options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdateStructureDocuments(_response);
        });
    }
    protected processUpdateStructureDocuments(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 204) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);

        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404  = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);

        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400  = _responseText;
            result400 = resultData400;
            return throwException("Bad Request", status, _responseText, _headers, result400);

        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }
    protected processUpdateStructureInstruction(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 204) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404  = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400  = _responseText;
            result400 = resultData400;
            return throwException("Bad Request", status, _responseText, _headers, result400);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }


	/** Données bancaires */
	createOrUpdateDonneesBancaires(structureId:string, body: CreateOrUpdateDonneesBancairesDto | undefined , documents?: Dictionary<File> | undefined): Promise<void> {
		let url_ = this.baseUrl + "/api/v1/Structure/{structureId}/donneesBancaires";
		 if (structureId === undefined || structureId === null)
            throw new Error("The parameter 'structureId' must be defined.");
        url_ = url_.replace("{structureId}", encodeURIComponent("" + structureId));
        url_ = url_.replace(/[?&]$/, "");

        const formData = new FormData();
        formData.set("json", JSON.stringify(body));

		appendFileDictionary(formData, documents || {});

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
		};

		return this.instance.put(url_, formData, options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processCreateOrUpdateDonneesBancaires(_response);
        });
    }

    protected processCreateOrUpdateDonneesBancaires(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

const appendFileDictionary = (formData: FormData, files: Dictionary<File>) => {
    for (let key in files) {
        if (files.hasOwnProperty(key)) {
            formData.append(key, files[key]);
        }
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}