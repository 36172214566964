import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTypeDeDocumentSelector, useTypeDeDocumentDispatcher } from '../../../store/store-helpers';
import { saveTypeDeDocument, modifDocument } from '../../../store/type-de-document-store/actions/typeDeDocumentAction';
import {
	TypeDeDocumentWithCategoriesDto,
	CreateOrUpdateTypeDeDocumentDto,
	TypeDeDocumentExtraInfoValueType,
	TypeDeDocumentExtraInfoDto,
	TypeDeDocumentDto
} from '../../../services/generated/BackOffice-api';
import Modal from 'react-modal';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Loader from 'react-loader-spinner';
import { modalCustomStyles } from '../../../constants/config.constant';
import Input from 'adel-shared/dist/components/basics/Input';
import Toggle from 'adel-shared/dist/components/Toggle';
import useValidation from '../../../custom-hooks/useValidation';
import { useForm, ValidationOptions, ValidateResult } from 'react-hook-form';
import EditDocumentInfoSaisies from './EditDocumentInfoSaisies';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';


interface EditDocumentForm {
	nameFR: string,
	nameEN: string,
	dureeValiditeMonths: string,
	dureeValiditeLabelFR: string,
	dureeValiditeLabelEN: string,
	// Note: Hidden values are used as `infos saisies` are "dynamics". They follow the following format
	// infoSaisieCode__X__
	// infoSaisieLabel__X__FR
	// infoSaisieLabel__X__EN
	// Where __X__ correspond to the `info saisie` index (so usually 1, 2 or 3)
}

const editDocumentFormDefault: EditDocumentForm = {
	nameFR: "",
	nameEN: "",
	dureeValiditeMonths: "",
	dureeValiditeLabelFR: "",
	dureeValiditeLabelEN: ""
};

enum ExtraInfoType {
	DUREE_VALIDITE = "date",
	INFO_SAISIE = "string"
}

interface EditDocumentProps extends RouteComponentProps {
	isOpen: boolean,
	setIsOpen: Dispatch<SetStateAction<boolean>>,
	//pour savoir si on est en creation(POST) ou edition(PUT)
	isCreation: boolean
	//si jamais on est en edition, pour remplir la modal avec les infos qu'on possède deja
	existingDocumentOrChoix?: TypeDeDocumentWithCategoriesDto,
	isChoix?: boolean;
	parentDocument?: TypeDeDocumentWithCategoriesDto;
	onCancel?: () => void;
}

const EditDocument: React.FC<EditDocumentProps> = ({
	isOpen,
	setIsOpen,
	isCreation,
	isChoix = false,
	existingDocumentOrChoix,
	parentDocument,
	onCancel
}) => {
	const {documentsIsLoading} = useTypeDeDocumentSelector();
	const documentDispatch = useTypeDeDocumentDispatcher(); // VFA : Actions should probably be done outside of the editor ?
	const { getRootValidator } = useValidation();
	const documentValidator = getRootValidator("CreateOrUpdateTypeDeDocumentDto") || {};
	const { register, getValues, setValue, triggerValidation, errors } = useForm<EditDocumentForm>({ defaultValues: editDocumentFormDefault });

	// new document infos
	const [documentId, setDocumentId] = useState<string | undefined>("");
	const [hasChoix, showChoix, hideChoix] = useBooleanState(false);
	const [hasDureeValidite, setHasDureeValidite] = useState<boolean>(true);
	const [hasPieceJointe, setDocumentPieceJointe] = useState<boolean>(false);
	const [hasInfoSaisie, setDocumentInfoSaisie] = useState<boolean>(true);
	const [infoSaisieCount, setInfoSaisieCount] = useState<number>(1); // Define the number of pair of `infos saisie` inputs displayed

	//edit : save info we already know and can't change from modal
	const [choix, setChoix] = useState<TypeDeDocumentWithCategoriesDto[]>([]);
	const [code, setCode] = useState('');
	const [expirationRelativeTo, setExpirationRelativeTo] = useState('');
	const [codeDate, setCodeDate] = useState('');

	useEffect(() => {
		// Pour l'édition d'un document ou d'un choix de document
		if (existingDocumentOrChoix) {
			setDocumentId(existingDocumentOrChoix.id as string)
		} else if (parentDocument) { // Si un document parent est précisé, on prend son id pour lui ajouter un choix
			setDocumentId(parentDocument.id);
		}

		if (existingDocumentOrChoix) {
			console.debug("editing document", existingDocumentOrChoix);
			initializeStateWithDocument(existingDocumentOrChoix);
		}
	}, [
		parentDocument,
		existingDocumentOrChoix,
		isChoix
	])

	// Initialize State, usually with existing document when in `edition` mode
	const initializeStateWithDocument = (existingDocument: TypeDeDocumentWithCategoriesDto) => {
		setCode(existingDocument.code as string)
		setTimeout(() => setValue("nameFR", existingDocument.nom?.fr ?? "", false)); // react-hook-form initialization hack https://stackoverflow.com/a/59547360
		setTimeout(() => setValue("nameEN", existingDocument.nom?.en ?? "", false));
		existingDocument.hasChoix ? showChoix() : hideChoix();
		setDocumentPieceJointe(existingDocument.hasAttachment as boolean);
		setChoix(existingDocument.choix as TypeDeDocumentWithCategoriesDto[]);
		if (existingDocument.extraInfos && existingDocument.extraInfos?.length > 0) {
			// Handle FIRST duree validite
			let existingDureeValidite = existingDocument.extraInfos.filter(info => info.valueType === (ExtraInfoType.DUREE_VALIDITE as string))[0];

			if (existingDureeValidite) {
				setCodeDate(existingDureeValidite.code as string);
				setTimeout(() => setValue("dureeValiditeLabelFR", existingDureeValidite.nom?.fr ?? "", false));
				setTimeout(() => setValue("dureeValiditeLabelEN", existingDureeValidite.nom?.en ?? "", false));
				setExpirationRelativeTo(existingDocument.expirationRelativeTo as string)
				if (existingDocument.expirationPeriod && existingDocument.expirationPeriod !== '') {
					var matches = existingDocument.expirationPeriod.match(/(\d+)/); // VFA : C'est vraiment suffisant pour interpreter une période ? sur le FO on a utilisé iso8601-duration pour ça
					if (matches) {
						let matchedValue = matches[0] as string;
						setTimeout(() => setValue("dureeValiditeMonths", matchedValue));
					}
				}
			} else {
				setHasDureeValidite(false);
			}

			// Handle many info saisie
			let existingInfoSaisies = existingDocument.extraInfos.filter(info => info.valueType === (ExtraInfoType.INFO_SAISIE as string));

			if (existingInfoSaisies.length > 0) {
				setDocumentInfoSaisie(true);
			} else {
				setDocumentInfoSaisie(false);
			}

			for (let infoSaisieIndex = 0; infoSaisieIndex < existingInfoSaisies.length; infoSaisieIndex++) {
				setInfoSaisieCount(infoSaisieIndex + 1); // Define how many pair of saisieValues field you will display

				setTimeout(() => {
					setValue(`infoSaisieCode${infoSaisieIndex + 1}`, existingInfoSaisies[infoSaisieIndex].code ?? "", false);
					setValue(`infoSaisieLabel${infoSaisieIndex + 1}FR`, existingInfoSaisies[infoSaisieIndex].nom?.fr ?? "", false);
					setValue(`infoSaisieLabel${infoSaisieIndex + 1}EN`, existingInfoSaisies[infoSaisieIndex].nom?.en ?? "", false);
				});
			}

		} else {
			setHasDureeValidite(false);
			setDocumentInfoSaisie(false);
		}
	}

	// Note: Only 1 date and multiple values are handled
	const getExtraInfos = (formValues: EditDocumentForm): TypeDeDocumentExtraInfoDto[] => {
		let extraInfos: TypeDeDocumentExtraInfoDto[] = [];

		if (formValues.dureeValiditeLabelFR) {
			extraInfos.push({
				code: isCreation ? generateDocumentCode(formValues.nameFR, "date") : (codeDate ? codeDate : generateDocumentCode(formValues.nameFR, "date")),
				nom: {
					"fr": formValues.dureeValiditeLabelFR,
					"en": formValues.dureeValiditeLabelEN
				},
				valueType: TypeDeDocumentExtraInfoValueType.Date
			});
		}

		// Handle dynamic multiple `infoSaisies` values
		let index = 1;
		while ((formValues as any)[`infoSaisieLabel${index}FR`]) {
			let documentCode = (formValues as any)[`infoSaisieCode${index}`];
			if (!documentCode) {
				documentCode = generateDocumentCode(formValues.nameFR, `saisie${index}`);
			}
			extraInfos.push({
				code: documentCode,
				nom: {
					"fr": (formValues as any)[`infoSaisieLabel${index}FR`],
					"en": (formValues as any)[`infoSaisieLabel${index}EN`]
				},
				valueType: TypeDeDocumentExtraInfoValueType.String
			})
			index++;
		}

		return extraInfos;
	}

	const handleSubmitModifications = async () => {

		let result = await triggerValidation();

		if (!result) { console.warn(`Validation failed`, result); return; }

		let formValues = getValues();

		//on crée l'objet à envoyer
		let editedDocumentOrChoix: CreateOrUpdateTypeDeDocumentDto = {
			id: existingDocumentOrChoix?.id,
			code: isCreation ? generateDocumentCode(formValues.nameFR, "nom") : code,
			nom: {
				"fr": formValues.nameFR,
				"en": formValues.nameEN,
			},
			hasAttachment: hasPieceJointe,
			hasChoix: hasChoix,
			isChoix: isChoix,
			choix: isCreation ? [] : choix?.map(c => ({ id: c.id } as CreateOrUpdateTypeDeDocumentDto)),
			extraInfos: getExtraInfos(formValues) //on check les info à mettre suivant ce qui a été rempli dans la modal
		}

		if (hasDureeValidite && !hasChoix) {
			editedDocumentOrChoix.expirationPeriod = `P${formValues.dureeValiditeMonths}M`;
			editedDocumentOrChoix.expirationRelativeTo = isCreation || !expirationRelativeTo ? generateDocumentCode(formValues.nameFR, "date") : expirationRelativeTo;
		}

		// VFA: Move submit logic to parent `DocumentAffectationTab` to keep this as a `dumb component` and not a `half container`
		if (isCreation) {
			if (!isChoix) { // it's a document
				saveTypeDeDocument(documentDispatch, editedDocumentOrChoix)()
			} else { // it's a choix
				if (!parentDocument) throw "Tentative d'ajout d'un nouveau choix, mais aucun Type de Document parent n'a été précisé. Please contact your dev team.";
				if (!parentDocument.choix) {
					parentDocument.choix = [editedDocumentOrChoix];
				} else {
					// Strip existing choix from all properties except Id
					parentDocument.choix = parentDocument.choix.map(choix => ({ id: choix.id } as TypeDeDocumentDto));
					parentDocument.choix = [...parentDocument.choix, editedDocumentOrChoix];
				}
				modifDocument(documentDispatch, documentId as string, parentDocument)()
			}
		} else { // it's an edit
			if (!isChoix) { // it's a document
				modifDocument(documentDispatch, documentId as string, editedDocumentOrChoix)()
			} else { // it's a choix
				// We need to handle the state part and update it with your newly modified choice
				// Find currently editing document in parentDocument.choix and remove it
				// Add new choix
				if (!parentDocument) throw "Tentative de modification d'un choix, mais aucun Type de Document parent n'a été précisé. Please contact your dev team.";
				if (!parentDocument.choix) throw "Tentative de modification d'un choix, mais le Type de Document parent ne possède aucun choix. Please contact your dev team.";

				let currentlyEditingChoixWasFound = parentDocument.choix.find(choix => choix.id === documentId);
				if (!currentlyEditingChoixWasFound) throw "Tentative de modification d'un choix, mais il n'a pas pu être trouvé parmis les choix du Type de Document parent. Please contact your dev team.";

				let choixWithoutCurrentDocument = parentDocument.choix.filter(choix => choix.id !== documentId)
				parentDocument.choix = [...choixWithoutCurrentDocument, { id: documentId, ...editedDocumentOrChoix }];
				// TODO : Probably need to define a order to keep them organized

				// Modifiying a choix is straightforward, just call the API
				modifDocument(documentDispatch, documentId as string, editedDocumentOrChoix, parentDocument)()
			}
		}
		setIsOpen(false);
	}

	const generateDocumentCode = (documentName: string, suffix: string) => {
		return documentName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'| /g, "") + suffix
	}

	const notEmptyValidation: ValidationOptions = {
		validate: (data: string): ValidateResult => {
			if (!data) { return "Ce champ est obligatoire." }
			return true;
		}
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				style={modalCustomStyles}
				className="normal__modal"
			>
				<div className="modal__header">
					<h3>
						{isCreation ? 'Ajouter' : 'Modifier'} {isChoix ? 'un choix' : 'un document'}
					</h3>
				</div>
				<div className="modal__content modal__content--scroll">
					<div className="modal__item">
						<Input
							name="nameFR"
							reference={register(documentValidator["Nom"])}
							errors={errors}
							label="Nom du document FR"
							type="text"
						/>
					</div>
					<div className="modal__item">
						<Input
							name="nameEN"
							reference={register(documentValidator["Nom"])}
							errors={errors}
							label="Nom du document EN"
							type="text"
						/>
					</div>
					{!isChoix && // TODO: À remplacer par des Radio Button pour différencier des Toggles Buttons ? à voir avec HTR
						<div className="modal__item">
							<label>Ajouter une liste de choix</label>
							<div className="modal__radioContainer">
								<div className="modal__radio">
									<div className="radio">
										<input type="radio" color="red" checked={hasChoix} onChange={showChoix} />
										<div className="newRadio" onClick={showChoix}></div>
									</div>
									<div>Oui</div>
								</div>
								<div className="modal__radio">
									<div className="radio">
										<input type="radio" checked={!hasChoix} onChange={hideChoix}></input>
										<div className="newRadio" onClick={hideChoix}></div>
									</div>
									<div>Non</div>
								</div>
							</div>
						</div>
					}
					{(!hasChoix || isChoix) && (
						<>
							<div className="modal__item">
								<Toggle
									label="Ajouter une durée de validité"
									value={hasDureeValidite}
									setCheck={setHasDureeValidite}
								/>
							</div>
							{hasDureeValidite && <>
								<div className="modal__item">
									<Input
										name="dureeValiditeMonths"
										reference={register(documentValidator["ExpirationPeriod"])}
										errors={errors}
										label="Nombre de mois"
										type="number"
									/>
								</div>
								<div className="modal__item">
									<Input
										name="dureeValiditeLabelFR"
										reference={register(notEmptyValidation)}
										errors={errors}
										label="Libellé du champs date FR"
										type="text"
									/>
								</div>
								<div className="modal__item">
									<Input
										name="dureeValiditeLabelEN"
										reference={register(notEmptyValidation)}
										errors={errors}
										label="Libellé du champs date EN"
										type="text"
									/>
								</div>
							</>}
							<div className="modal__item">
								<Toggle
									label="Ajouter une pièce jointe"
									value={hasPieceJointe}
									setCheck={setDocumentPieceJointe}
								/>
							</div>
							<div className="modal__item">
								<Toggle
									label="info de saisie"
									value={hasInfoSaisie}
									setCheck={setDocumentInfoSaisie}
								/>
							</div>
							{hasInfoSaisie && <>
								<EditDocumentInfoSaisies
									register={register}
									labelsValidation={notEmptyValidation}
									errors={errors}
									count={infoSaisieCount}
								/>
								<button className="modal__icon-button" onClick={() => setInfoSaisieCount(infoSaisieCount + 1)}>
									<i className="fa fa-plus-circle" aria-hidden="true"></i>
								</button>
								<button disabled={infoSaisieCount <= 1} className="modal__icon-button" onClick={() => setInfoSaisieCount(infoSaisieCount - 1)}>
									<i className="fa fa-minus-circle" aria-hidden="true"></i>
								</button>
							</>}
						</>
					)}
				</div>
				<div className="modal__footer">
					<FormButton
						type="button"
						value="Annuler"
						onClick={() => {
							setIsOpen(false);
							onCancel && onCancel();
						}}
					/>
					{documentsIsLoading ? (
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						/>
					) : (
						<FormButton
							type="submit"
							value={isCreation ? "Valider" : "Modifier"}
							onClick={handleSubmitModifications}
						/>
					)}
				</div>
			</Modal>
		</>
	);
}

export default EditDocument;