import React, {
    FunctionComponent,
    useEffect,
    useRef,
    useState
} from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import {
    BordereauVersementDto,
    BordereauVersementMouvementDto,
    StatutBordereau,
    SubCategorieDossierDto,
    CompteBancaireDto,
    TypeBordereau,
    BordereauReaffectationDto,
    BordereauReaffectationMouvementDto
} from "../../services/generated/BackOffice-api";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import NoItemsTable from "../basics/NoItemsTable";
import { useSortBy, useTable } from "react-table";
import { fetchSubCategories } from "../../store/dossier-store/actions/dossierAction";
import { useDispatch } from "react-redux";
import useHasPermission from "../../custom-hooks/useHasPermission";
import Modal from 'react-modal';
import useBooleanState from "adel-shared/dist/custom-hooks/useBooleanState";
import { modalCustomStyles } from '../../constants/config.constant';
import { toast } from "react-toastify";
import { Link } from "@reach/router";
import { Controller, FormContextValues } from "react-hook-form";
import InputSelect, { AdelOption } from "adel-shared/dist/components/basics/InputSelect";
import InputCalendar from "adel-shared/dist/components/basics/InputCalendar";
import { deleteMouvement } from "../../store/bordereau-store/actions/bordereauActions";
import Grid from '@material-ui/core/Grid';
import { getTotalMouvements } from "./BordereauUtils";
import { useUserSelector } from "../../store/store-helpers";
import { formatNumber } from 'adel-shared/dist/utils/functions';


type BordereauMouvementDto = BordereauVersementMouvementDto | BordereauReaffectationMouvementDto;
interface BordereauxTabsProps {
    bordereau: BordereauVersementDto | BordereauReaffectationDto;
    tableColumns: Array<Object>;
    form?: FormContextValues;
    comptesBancaireOptions?: AdelOption<CompteBancaireDto['id']>[];
    onSignature?: () => void;
    onCloture?: () => void;
    onArchive?: () => void;
    onExport?: () => void;
	onExportSepa?: () => void;
	onExportBordereauPdf?: () => void;
	onExportBordereauTxt?: () => void;
}

const BordereauxTabs: FunctionComponent<BordereauxTabsProps> = ({
    bordereau,
    tableColumns,
    form,
    comptesBancaireOptions,
    onSignature,
    onCloture,
    onArchive,
    onExport,
	onExportSepa,
	onExportBordereauPdf,
	onExportBordereauTxt
}) => {
    const dispatch = useDispatch();
    const { user = {} } = useUserSelector();
    const usePermClotureVersement = useHasPermission('ClotureBordereauVersement');
    const usePermClotureReaffectation = useHasPermission('ClotureBordereauReaffectation');
    const usePermSignature = useHasPermission('SoumissionSignatureBordereau');
    const userPermArchive = useHasPermission('ArchiveBordereau');
    const usePermExport = useHasPermission('GetBordereauVersementAsExcel');
    const usePermExportSepa = useHasPermission('GetExportSEPA');
    const usePermExportBordereauTxt = useHasPermission('GetBordereauVersementAsTxt');
    const usePermExportBordereauPdf = useHasPermission('GetBordereauVersementAsPDF');
    const usePermDeleteMouvement = useHasPermission('DeleteMouvement');
    const [tabIndex, setTabIndex] = useState<number>(0);
	const [data, setData] = useState<any>([]);
    const [dossierSubCategories, setDosssierSubCategories] = useState<SubCategorieDossierDto[]>([]);
	const [isModalDeleteOpen, openModalDelete, closeModalDelete] = useBooleanState(false);
    const mouvementToDeleteId = useRef<string>('');
    const [comptesBancaireOptionsFiltre, setComptesBancaireOptionsFiltre] = useState<any>(comptesBancaireOptions);

  console.log(comptesBancaireOptions)
    const handleDeleteMouvementClick = (id: string) => {
        openModalDelete();
        mouvementToDeleteId.current = id;
    };

    const columns = React.useMemo(() => {
        const columns = [...tableColumns];
        let hasPermToDelete = false;

        if(user.roles) {
            if(bordereau.typeBordereau === TypeBordereau.Versement) {
                if(
                    bordereau.statutBordereau === StatutBordereau.Preparatoire &&
                    user.roles.some(x =>
                        x === 'Gérant' ||
                        x === 'Chargé d\'instruction Division Culturelle' ||
                        x === 'Chargé d\'instruction Spectacle Vivant (DSV)'
                    )
                ) {
                    hasPermToDelete = true;
                }

                if(
                    bordereau.statutBordereau === StatutBordereau.Amendable &&
                    user.roles.some(x => x === 'Gérant')
                ) {
                    hasPermToDelete = true;
                }
            } else if(bordereau.typeBordereau === TypeBordereau.Reaffectation) {
                if(bordereau.statutBordereau === StatutBordereau.Preparatoire) {
                    hasPermToDelete = true;
                }

                if(
                    bordereau.statutBordereau === StatutBordereau.Amendable &&
                    user.roles.some(x =>
                        x === 'Gérant' ||
                        x === 'Responsable de commissions'
                    )
                ) {
                    hasPermToDelete = true;
                }
            }
        }

       if(usePermDeleteMouvement && hasPermToDelete) {
            columns.push({
                Header: "",
                accessor: "actions",
                disableSortBy: true,
                Cell: (props: any) => (
                    <i className="far fa-trash-alt" role="button" onClick={() => handleDeleteMouvementClick(props.cell.row.values.id)}></i>
                )
            });
        }

        columns.push({
            Header: "Id",
            accessor: "id"
        });

        return [{
            Header: "Table",
            columns
        }];
    }, [
        bordereau,
        usePermDeleteMouvement,
        user.roles
    ]);

    const handleTabSelect = (index: number) => {
        setTabIndex(index);
        setData(
            getMouvementsBySubCategory(dossierSubCategories[index].id)
        );
    };

    const getMouvementsBySubCategory = (subCategoryId?: string) => {
        const mouvements = (bordereau.mouvements as any[])
        ?.filter(x => x.dossierSubCategorie?.id === subCategoryId)
        .map(x => ({
            ...x,
            dossierNumero: (
                <Link to={`/Dossier/${x.dossierId}`}>
                    {x.dossierNumero}
                </Link>
            ),
            dossierNom: (
                <Link to={`/Dossier/${x.dossierId}`}>
                    {x.dossierNom}
                </Link>
            )
        })) || [];

        switch(bordereau.typeBordereau) {
            case TypeBordereau.Versement:
                return (mouvements as BordereauVersementMouvementDto[])
                    .map(x => ({
                        ...x,
                        validIBAN: (x.validIBAN) ? 'RIB Validé' : 'RIB non validé',
                        montant: `${formatNumber(x.montant)} €`
                    })) || [];
            case TypeBordereau.Reaffectation:
                return (mouvements as BordereauReaffectationMouvementDto[])
                    ?.filter(x => x.dossierSubCategorie?.id === subCategoryId)
                    .map(x => ({
                        ...x,
                        montantAccorde: `${formatNumber(x.montantAccorde)} €`,
                        montantReaffecte: `${formatNumber(x.montantReaffecte)} €`,
                    })) || [];
            default:
                return [];
        }
    };

    const getTotalDossiers = (mouvements: BordereauMouvementDto[] = []) => {
        const dossiers = (mouvements as any[]).reduce((acc: string[], currentVal: BordereauMouvementDto) => {
            if(currentVal.dossierId && !acc.includes(currentVal.dossierId)) {
                acc.push(currentVal.dossierId);
            }
            return acc;
        }, []);
        return dossiers.length;
    };

    const getInfosTab = (mouvements: BordereauMouvementDto[] = []) => {
        const nbDossiers = getTotalDossiers(mouvements);

        if(nbDossiers < 1) {
            return `(${nbDossiers})`;
        }

        const montantTotal = getTotalMouvements(mouvements);

        return `(${nbDossiers} / ${formatNumber(montantTotal)} €)`;
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["id"]
			}
		} as any,
		useSortBy
	);

    useEffect(() => {
        (async() => {
            const result = await fetchSubCategories(dispatch);
            setDosssierSubCategories(result);
        })();
    }, []);

    useEffect(() => {
        let options: any = comptesBancaireOptions && comptesBancaireOptions.length > 0 ? [...comptesBancaireOptions.filter(cb => cb.label != 'BRED')] : []
        options.unshift(comptesBancaireOptions?.find(cb => cb.label == "BRED"))
        setComptesBancaireOptionsFiltre(options)
        if(options?.length && form) {
            form.setValue('compteBancaireId', options[0]?.value);
        }
    }, [
        comptesBancaireOptions,
        form,
        tabIndex
    ]);

    useEffect(() => {
        if(bordereau.id && dossierSubCategories.length) {
            setData(
                getMouvementsBySubCategory(dossierSubCategories[tabIndex]?.id)
            );
        }
    }, [
        bordereau,
        dossierSubCategories
    ]);

    const handleRemoveMouvement = async() => {
        try{
            const bordereauType = bordereau.typeBordereau || TypeBordereau.None;
            await deleteMouvement(dispatch, mouvementToDeleteId.current, bordereauType);
            closeModalDelete();
            toast.success('Le mouvement a été supprimé avec succès.');
        } catch {
            closeModalDelete();
            toast.error('Une erreur s\'est produite pendant la suppression du mouvement.');
        }
    };

    return (
        <>
            <Tabs className="react-tabs--page" selectedIndex={tabIndex} onSelect={handleTabSelect}>
                <div className="tabs-container">
                    <TabList>
                        {dossierSubCategories.map(sc => (
                            <Tab key={sc.id}>
                                {sc.nom} {
                                    getInfosTab(
                                        (bordereau.mouvements as any[])
                                        ?.filter(x => x.dossierSubCategorie?.id === sc.id)
                                    )
                                }
                            </Tab>
                        ))}
                    </TabList>
                </div>

                {dossierSubCategories.map(sc => (
                    <TabPanel key={sc.id}>
                        <div className="content">
                            {data.length > 0 ? (
                                <>
                                    <Table
                                        data={data}
                                        getTableProps={getTableProps}
                                        getTableBodyProps={getTableBodyProps}
                                        headerGroups={headerGroups}
                                        rows={rows}
                                        prepareRow={prepareRow}
                                        styles={styleTable.page}
                                    />

                                    <div className="total">
                                        <span>Total</span>
                                        <span>
                                            {formatNumber(getTotalMouvements(
                                                (bordereau.mouvements as any[])
                                                ?.filter(x => x.dossierSubCategorie?.id === sc.id
                                            )))} €
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <NoItemsTable
                                    text="Aucun mouvement dans cette catégorie de dossier."
                                />
                            )}
                        </div>
                    </TabPanel>
                ))}
            </Tabs>

            {/* TODO : Passer les différentes actions dans le composant parent */}
            <Grid className="footer" container spacing={3} alignItems="flex-end">
                {(
                    onCloture &&
                    usePermClotureVersement &&
                    bordereau.statutBordereau === StatutBordereau.Amendable &&
                    form?.control
                ) && (
                    <>
                        <Grid item>
                            <Controller
                                control={form.control}
                                name="compteBancaireId"
                                as={({ name, onChange, value }) => (
                                    <InputSelect
                                        label="Compte bancaire"
                                        options={comptesBancaireOptionsFiltre}
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <div className="input">
                                <label>Date règlement :</label>
                                <Controller
                                    control={form.control}
                                    name="dateReglement"
                                    as={({ name, onChange, value }) => (
                                        <InputCalendar
                                            className="inputFile__calendar--basic"
                                            onDateSelected={onChange}
                                            name={name}
                                            defaultDate={value}
                                            unlock
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item>
                            <FormButton
                                type="button"
                                value="Clôturer le bordereau"
                                onClick={onCloture}
                            />
                        </Grid>
                    </>
                )}

                {(
                    onCloture &&
                    usePermClotureReaffectation &&
                    bordereau.statutBordereau === StatutBordereau.Amendable &&
                    !form
                ) && (
                    <FormButton
                        type="button"
                        value="Clôturer le bordereau"
                        onClick={onCloture}
                    />
                )}

                {(
                    onSignature &&
                    usePermSignature &&
                    bordereau.statutBordereau !== StatutBordereau.Amendable &&
                    bordereau.statutBordereau !== StatutBordereau.Verse
                ) && (
                    <Grid item>
                        <FormButton
                            type="button"
                            value="Soumettre à la signature"
                            onClick={onSignature}
                        />
                    </Grid>
                )}

                {(
                    onExport &&
                    bordereau.statutBordereau === StatutBordereau.Amendable &&
                    usePermExport
                ) && (
                    <Grid item>
                        <FormButton
                            type="button"
                            value="Exporter"
                            onClick={onExport}
                        />
                    </Grid>
                )}

                {(
                    onArchive &&
                    userPermArchive &&
                    bordereau.statutBordereau === StatutBordereau.Verse
                ) && (
                    <Grid item>
                        <FormButton
                            type="button"
                            value="Archiver"
                            onClick={onArchive}
                        />
                    </Grid>
               )}
			   {(
					onExportSepa &&
					usePermExportSepa &&
					bordereau.statutBordereau === StatutBordereau.Verse
				) && (
					<Grid item>
						<FormButton
							type="button"
							value="Export SEPA"
							onClick={onExportSepa}
						/>
					</Grid>
				)}
				{(
					onExportBordereauPdf &&
					usePermExportBordereauPdf &&
					bordereau.statutBordereau === StatutBordereau.Verse
				) && (
						<Grid item>
							<FormButton
								type="button"
								value="Bordereau de virement"
								onClick={onExportBordereauPdf}
							/>
					</Grid>
				)}
				{(
					onExportBordereauTxt &&
					usePermExportBordereauTxt &&
					bordereau.statutBordereau === StatutBordereau.Verse
				) && (
					<Grid item>
						<FormButton
							type="button"
							value="Export comptable"
							onClick={onExportBordereauTxt}
						/>
					</Grid>
				)}
            </Grid>

            <Modal isOpen={isModalDeleteOpen} style={modalCustomStyles} className="normal__modal">
                <div className="modal__header">
                    <h3>Supprimer un mouvement</h3>
                </div>
                <div className="modal__content">
                    <div className="modal__item">
                        Voulez-vous supprimer ce mouvement ?
                    </div>
                </div>
                <div className="modal__footer">
                    <FormButton type="button" value="Annuler" onClick={closeModalDelete} />
                    <FormButton type="submit" value="Supprimer" onClick={handleRemoveMouvement} />
                </div>
            </Modal>
        </>
    )
};

export default BordereauxTabs;