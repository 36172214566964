import React, { FunctionComponent, useState, useMemo } from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { DossierAideHistoriqueDto } from '../../../services/generated/BackOffice-api';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { useDossierSelector } from '../../../store/store-helpers';
import { CategorieDossier } from '../../../enums/Dossiers';

interface InformationsSupplementairesProps {
    details?: DossierAideHistoriqueDto;
	dsv?: boolean;
}

const InformationsSupplementaires: FunctionComponent<InformationsSupplementairesProps> = ({
    details = {}, dsv
}) => {
	
const {
	dossierDetails = {},
} = useDossierSelector();
    return (<>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label={
							dossierDetails.categorie?.code === CategorieDossier.Ecole ?
							"Nombre total de cachets enseignants" :
							"Nombre total de cachets artistes"}
						content={`${details?.nombreTotalCachets || 0}`}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Pourcentage d’aide demandée par rapport au budget projet"
						content={details.pourcentageAide ? `${formatNumber(details.pourcentageAide * 100 || 0)} %` : '-'}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier en N-7"
						content={`${details.aidesN7 ? formatNumber(details.aidesN7) : '-'} €` }
					/>
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier en N-6"
						content={`${details.aidesN6 ? formatNumber(details.aidesN6) : '-'} €`}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier en N-5"
						content={`${details.aidesN5 ? formatNumber(details.aidesN5) :'-'} €`}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier en N-4"
						content={`${details.aidesN4 ? formatNumber(details.aidesN4) :'-'} €`}
					/>
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier en N-3"
						content={`${details.aidesN3 ? formatNumber(details.aidesN3) : '-'} €`}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier en N-2"
						content={`${details.aidesN2 ? formatNumber(details.aidesN2) : '-'} €`}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier en N-1"
						content={`${details.aidesN1 ? formatNumber(details.aidesN1) : '-'} €`}
					/>
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Montant de l’aide accordée sur une même catégorie de dossier à l'année courante"
						content={`${details.aidesN0 ? formatNumber(details.aidesN0) : '-'} €`}
					/>
				</div>
			</div>
		</div>

		{dsv && details.dossierDSVInfos && <>
			<div className="detailsTab__row">
				<div className="detailsTab__content">
					<h4>Informations droit exclusif</h4>
				</div>
			</div>
			<div className="detailsTab__row">
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Instruction dossier en cours"
							content={details.dossierDSVInfos.instructionDossierEnCours ? "Oui" : "Non"}
						/>
					</div>
				</div>
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Signature ALD"
							content={details.dossierDSVInfos.signatureALD ? "Oui": "Non"}
						/>
					</div>
				</div>
				<div className="detailsTab__column">
					<div className="detailsTab__content">
						<InputReadOnly
							label="Proposition de montant"
							content={`${details.dossierDSVInfos.propositionMontant ? formatNumber(details.dossierDSVInfos.propositionMontant) : '-'} €`}
						/>
					</div>
				</div>
			</div>
			<div className="detailsTab__row">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Commentaire"
						content={details.dossierDSVInfos.commentaires || '-'}
					/>
				</div>
			</div>
		</>}
    </>);
}

export default InformationsSupplementaires;