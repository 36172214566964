import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { modalLargeCustomStyles } from '../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { DoublonApiDto } from '../../services/generated/BackOffice-api';
import { useDossierSelector } from '../../store/store-helpers';

interface ModalDoublonArtisteAPIProps {
	isOpen: boolean;
	closeModal: () => void;
	doublons: DoublonApiDto[];
}

const ModalDoublonArtisteAPI: FunctionComponent<ModalDoublonArtisteAPIProps> = ({
	isOpen,
	closeModal,
	doublons
}) => {
	const { dossierDetails: dossier = {} } = useDossierSelector();
	const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		(async() => {
			if(!dossier.id) {
				return;
			}

			setData(doublons.map(x => ({
				...x,
				numeroDossier: (
					<a href={`/Dossier/${x.dossierId}`} target="_blank">
						{x.numeroDossier}
					</a>
				)
			})));
		})();
	}, [doublons]);

	const columns = useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: "Artiste",
						accessor: "artiste",
						disableSortBy: true
					},
					{
						Header: "Numéro dossier",
						accessor: "numeroDossier",
						disableSortBy: true
					},
					{
						Header: "Nom dossier",
						accessor: "nomDossier",
						disableSortBy: true
					},
					{
						Header: "Nom Structure",
						accessor: "nomStructure",
						disableSortBy: true
					},
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			manualSortBy: true
		} as any,
		useSortBy,
		useRowSelect
    );

    return (
		<Modal isOpen={isOpen} style={modalLargeCustomStyles} className="normal__modal doublonsModal">
			<div className="modal__header">
				<h3>Doublons "Artiste API"</h3>
			</div>
			<div className="modal__content">
				<Table
					className="table--doublons"
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.onglet}
				/>
			</div>
			<div className="modal__footer">
				<FormButton
					type="button"
					value="Fermer"
					onClick={closeModal}
				/>
			</div>
		</Modal>
	)
};

export default ModalDoublonArtisteAPI;