import Toggle from 'adel-shared/dist/components/Toggle';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Input from 'adel-shared/dist/components/basics/Input';
import InputRadioYesNo from "adel-shared/dist/components/basics/InputRadioYesNo";
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { RouteComponentProps } from '@reach/router';
import { modalCustomStyles } from "../../constants/config.constant";
import { axiosInstance } from '../../custom-hooks/useAxios';
import { ICommissionMembreModel } from "../../models/commission/ICommissionMembreModel";
import { AdresseClient, Civilite, CommissionMemberDto, Emploi, GeoAdresseDto, GroupeCommission, PaysClient, PaysDto, StyleMusicalDto } from '../../services/generated/BackOffice-api';
import { createCommissionMembre, updateCommissionMembre } from '../../store/commission-membre-store/actions/commissionMembreActions';
import { fetchStylesMusicaux } from '../../store/remuneration-store/actions/remunerationAction';
import { useRemunerationSelector } from '../../store/store-helpers';

interface ModalEditMembreProps extends RouteComponentProps {
	isOpen: boolean;
	onClose: () => void;
	validator: any;
	membre?: CommissionMemberDto;
}

const ModalEditMembre: React.FunctionComponent<ModalEditMembreProps> = ({
	isOpen,
	onClose,
	validator,
	membre
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const remunerationSelector = useRemunerationSelector();
	const {
		register,
		getValues,
		setValue,
		triggerValidation,
		errors,
		control,
		watch
	} = useForm<ICommissionMembreModel>({
		defaultValues: { interne: false }
	});
	const paysClient = new PaysClient('', axiosInstance);
	const adresseClient = new AdresseClient('', axiosInstance);
	const {emploi, ville, codePostal} = watch(['emploi', 'ville', 'codePostal']);

	const showStylesMusicaux =
		emploi === Emploi.Chanteur ||
		emploi === Emploi.Choriste ||
		emploi === Emploi.ChefOrchestre ||
		emploi === Emploi.Musicien;

	useEffect(() => {
		fetchStylesMusicaux(dispatch)();
		fetchPays();
	}, []);

	useEffect(() => {
		if(!membre) return;

		setValue([
			{ civilite: membre.civilite },
			{ nom: membre.nom },
			{ prenom: membre.prenom },
			{ email: membre.email },
			{ groupe: membre.groupe },
			{ emploi: membre.emploi },
			{ typeMusical: membre.styleMusical?.id },
			{ telephoneFixe: membre.telephoneFixe },
			{ telephoneMobile: membre.telephoneMobile },
			{ interne: membre.estInterne },
			{ pays: membre.pays?.id },
			{ line1: membre.adresse?.line1 },
			{ line2: membre.adresse?.line2 },
			{ codePostal: membre.adresse?.codePostal },
			{ ville: membre.adresse?.ville },
			{ estActif: membre.estActif },
			{ notes: membre.note },
		]);

		setAdresseId(membre.adresseLabelId || "");
		setAdresseLabel(membre.adresseLabel || "");
	}, [membre])

	useEffect(() => {
		if(showStylesMusicaux && membre?.styleMusical?.id) {
			setValue('typeMusical', membre.styleMusical.id);
		} else {
			setValue('typeMusical', undefined);
		}
	}, [membre, showStylesMusicaux]);

	/** Options des select */
	const optionsGroupe = () => {
		let allOptions: AdelOption<GroupeCommission>[] = [];
		for(let group in GroupeCommission) {
			if (group !== "None") {
				allOptions.push({
					value: GroupeCommission[group as keyof typeof GroupeCommission],
					label: group
				})
			}
		}
		return allOptions;
	}
	const optionsCivilite = () => {
		let allOptions: AdelOption<Civilite>[] = [];
		for(let c in Civilite) {
			if(c !== "None")
			allOptions.push({
				value: Civilite[c as keyof typeof Civilite],
                label: t(`common.civilite.${Civilite[c as keyof typeof Civilite]}`)
			})
		}
		return allOptions;
	}
	const optionsEmploi = () => {
		let allOptions: AdelOption<Emploi>[] = [];
		for(let e in Emploi) {
			if (e !== "None") {
				allOptions.push({
					value: Emploi[e as keyof typeof Emploi],
					label: t(`structure.emploi.${Emploi[e as keyof typeof Emploi]}`)
				})
			}
		}
		return allOptions;
	}

	/** Get Pays Options */
	const [paysOptions, setPaysOptions] = useState<AdelOption<PaysDto["id"]>[]>([]);
	const fetchPays = async () => {
		const result = await paysClient.getPays();
		const options = result
			.filter(x => x.id && x.nom)
			.map(element => ({
				value: element.id as string,
				label: element.nom as string
			}));
		setPaysOptions(options);
	}

	/** Adresse search */
	const [adresseId, setAdresseId] = useState<string>("");
	const [adresseLabel, setAdresseLabel] = useState<string>("");
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);
	const searchAdresse = (input: string) => {
		if (!!input) {
			try {
				adresseClient.searchAdresse(`${ville} ${input}`, codePostal).then(results => {
					setGeoAdresse(results);
				});
				onAdresseChange(input);
			}
			catch (error) {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer l'adresse'");
			}
		}
	}

	const onAdresseChange = (label: string) => {
		const selectedAdresse = geoAdresse.find(adresse => adresse.label === label);

		if (selectedAdresse) {
			setValue([
				{ "line1": `${selectedAdresse.numero} ${selectedAdresse.rue}` },
				{ "codePostal": selectedAdresse.codePostal },
				{ "ville": selectedAdresse.ville }
			]);

			setAdresseId(selectedAdresse.id || "");
			setAdresseLabel(selectedAdresse.label || "");
		}
	}

	const [typeMusicalOptions, setTypeMusicalOptions] = useState<AdelOption<string>[]>([]);
	useEffect(() => {
		if (remunerationSelector.stylesMusicaux) {
			const musiques = remunerationSelector.stylesMusicaux
				.filter(x => x.id && x.intitule?.fr)
				.map(x => ({
					value: x.id as string,
					label: x.intitule?.fr as string
				}));
			setTypeMusicalOptions(musiques);
		}
	}, [remunerationSelector.stylesMusicaux])



	/** Envoi */
	const submit = useCallback(async () => {
		let result = await triggerValidation();

		if(!result) return;

		const body = {
			civilite: getValues().civilite,
			nom: getValues().nom,
			prenom: getValues().prenom,
			email: getValues().email,
			groupe: getValues().groupe,
			estInterne: getValues().interne,
			emploi: getValues().emploi,
			styleMusicalId: getValues().typeMusical,
			telephoneFixe: getValues().telephoneFixe,
			telephoneMobile: getValues().telephoneMobile,
			adresse: {
				line1: getValues().line1,
				line2: getValues().line2,
				codePostal: getValues().codePostal,
				ville: getValues().ville
			},
			adresseLabelId: adresseId,
			adresseLabel: adresseLabel,
			paysId: getValues().pays,
			note: getValues().notes
		};

		if(membre?.id) {
			try {
				await updateCommissionMembre(dispatch, membre.id, {...body, estActif: getValues().estActif}, onClose)();
				toast.success(t('common.success'));
			} catch(error) {
				toast.error(t('common.errors.send'));
			}
		} else {
			try {
				await createCommissionMembre(dispatch, body, onClose)();
				toast.success(t('common.success'));
			} catch(error) {
				toast.error(t('common.errors.send'));
			}
		}
	}, [
		membre,
		dispatch,
		adresseId,
		adresseLabel,
		updateCommissionMembre,
		createCommissionMembre,
		onClose,
		triggerValidation
	]);


	return (
		<Modal
			isOpen={isOpen}
			style={modalCustomStyles}
			className="normal__modal membresModal"
		>
			<div className="modal__header">
				<h3>{membre ? "Modifier un membre" : "Ajouter un nouveau membre"}</h3>
			</div>

			<div className="modal__content">
				<div className="membresModal__row">
					<div className="membresModal__item">
						<Controller control={control}
							name="civilite"
							as={({ onChange, value, name }) => (
								<InputSelect<Civilite>
									name={name}
									label="Civilité*"
									classname="inputSelect"
									options={optionsCivilite()}
									errors={errors}
									onChange={(civ) => {
										onChange(civ);
									}}
									value={value}
									placeholder={t("common.select")}
								/>
							)}
							rules={validator?.["Civilite"]}
						/>
					</div>
					<div className="membresModal__item">
						<Input
							label="Nom*"
							type="text"
							name="nom"
							reference={validator && register(validator["Nom"])}
							errors={errors}
						/>
					</div>
					<div className="membresModal__item">
						<Input
							label="Prénom*"
							type="text"
							name="prenom"
							reference={validator && register(validator["Prenom"])}
							errors={errors}
						/>
					</div>
				</div>
				<div className="membresModal__row">
					<div className="membresModal__item">
						<Input
							label="Email*"
							type="text"
							name="email"
							reference={validator && register(validator["Email"])}
							errors={errors}
						/>
					</div>
					<div className="membresModal__item">
						<Controller control={control}
							name="groupe"
							as={({ onChange, value, name }) => (
								<InputSelect<GroupeCommission>
									name={name}
									label="Groupe*"
									classname="inputSelect"
									options={optionsGroupe()}
									errors={errors}
									onChange={(civ) => {
										onChange(civ);
									}}
									value={value}
									placeholder={t("common.select")}
								/>
							)}
							rules={validator && validator["Groupe"]}
						/>
					</div>
					<div className="membresModal__item">
						<Controller control={control}
							name="interne"
							as={({ onChange, name }) => (
								<InputRadioYesNo
									name={name}
									label="Interne*"
									onChange={(interne) => {
										onChange(interne);
									}}
									value={getValues().interne}
								/>
							)}
							rules={validator && validator["Interne"]}
						/>
					</div>
				</div>
				<div className="membresModal__row">
					<div className="membresModal__item">
						<Controller control={control}
							name="emploi"
							as={({ onChange, value, name }) => (
								<InputSelect<Emploi>
									name={name}
									label="Profession*"
									classname="inputSelect"
									options={optionsEmploi()}
									errors={errors}
									onChange={(e) => {
										onChange(e);
									}}
									value={value}
									placeholder={t("common.select")}
								/>
							)}
							rules={validator && validator["Emploi"]}
						/>
					</div>
					{showStylesMusicaux && (
						<div className="membresModal__item">
							<Controller control={control}
								name="typeMusical"
								as={({ onChange, value, name }) => (
									<InputSelect<StyleMusicalDto["id"]>
										name={name}
										label="Style de musique*"
										classname="inputSelect"
										options={typeMusicalOptions}
										errors={errors}
										onChange={(e) => {
											onChange(e);
										}}
										value={value}
										placeholder={t("common.select")}
									/>
								)}
								rules={validator?.["StyleMusicalId"]}
							/>
						</div>
					)}
					<div className="membresModal__item"></div>
				</div>
				<div className="membresModal__row">
					<div className="membresModal__item">
						<Input
							label="Téléphone fixe"
							type="text"
							name="telephoneFixe"
							reference={validator && register(validator["TelephoneFixe"])}
							errors={errors}
						/>
					</div>
					<div className="membresModal__item">
						<Input
							label="Téléphone portable"
							type="text"
							name="telephoneMobile"
							reference={validator && register(validator["TelephoneMobile"])}
							errors={errors}
						/>
					</div>
					<div className="membresModal__item">
						<Controller
							control={control}
							name="pays"
							as={({ onChange, value, name }) => (
								<InputSelect<PaysDto["id"]>
									name={name}
									label="Pays*"
									classname="inputSelect"
									options={paysOptions}
									errors={errors}
									onChange={(p) => {
										onChange(p);
									}}
									value={value}
									isSearchable={true}
									placeholder={t("common.select")}
								/>
							)}
							rules={validator?.["PaysId"]}
						/>
					</div>
				</div>
				<div className="membresModal__row">
					<div className="membresModal__item">
						<Input
							label="Ville*"
							type="text"
							name="ville"
							reference={validator && register(validator["Ville"])}
							errors={errors}
						/>
					</div>
					<div className="membresModal__item">
						<Input
							label="Code postal*"
							type="text"
							name="codePostal"
							reference={validator && register(validator["CodePostal"])}
							maxLength={5}
							errors={errors}
						/>
					</div>
					<div className="membresModal__item">
						<Input
							label="N° et nom de rue*"
							type="text"
							name="line1"
							reference={validator && register(validator["Line1"])}
							dataList={geoAdresse.map(adresse => adresse.label)}
							onChange={debounce((value: string) => searchAdresse(value), 1000)}
							errors={errors}
						/>
					</div>
				</div>
				<div className="membresModal__row">
					<div className="membresModal__item">
						<Input
							label="Complément d’adresse"
							type="text"
							name="line2"
							reference={validator && register(validator["Line2"])}
							errors={errors}
						/>
					</div>

					{membre ? (
						<div className="membresModal__item">
							<Controller control={control}
								name="estActif"
								as={({ onChange, name }) => (
									<Toggle
										name={name}
										label="Activer le compte"
										setCheck={onChange}
										value={getValues().estActif}
									/>
								)}
								rules={validator?.["EstActif"]}
							/>
						</div>
					) : (
						<div className="membresModal__item"></div>
					)}
					<div className="membresModal__item"></div>
				</div>
				<div className="membresModal__notes">
					<div className="input">
						<label className="input__label">Notes</label>
					</div>
					<Controller control={control}
						name="notes"
						as={({ onChange, value, name }) => (<>
							<textarea
								name={name}
								className="membresModal__textarea"
								onChange={(e) => onChange(e)}
								value={value}
							/>
							{errors &&
								<ErrorMessage errors={errors} name={name}>
									{({ message }) => <p className="input__errorMessage">{message}</p>}
								</ErrorMessage>
							}
						</>)}
						rules={validator && validator["Notes"]}
					/>
				</div>
			</div>

			<div className="modal__footer">
				<FormButton
					type="button"
					value="Annuler"
					onClick={onClose}
				/>
				<FormButton
					type="submit"
					value="Valider"
					onClick={submit}
				/>
			</div>
		</Modal>
	);
}

export default ModalEditMembre;