import React, { useEffect, useState, useCallback } from 'react';
import { fetchTemplates, saveTemplate, deleteTemplate, modifTemplate } from '../../../store/template-store/actions/templateAction';
import { ModeleMessageDto, DiscussionDto, BOCurrentUserDto, StatutDiscussion } from '../../../services/generated/BackOffice-api';
import Loader from 'react-loader-spinner';
import { useTemplateSelector, useTemplateDispatcher, useDossierSelector, useUserSelector } from '../../../store/store-helpers';
import { modalCustomStyles } from '../../../constants/config.constant';
import { fetchDossierMessages, sendDossierMessages } from '../../../store/dossier-store/actions/dossierMessagesActions';
import Toggle from 'adel-shared/dist/components/Toggle';
import Modal from 'react-modal';
import { scrollToBottom } from 'adel-shared/dist/utils/functions';

import DossierMessagesList from './DossierMessagesList';
import MessagesContainer from '../../../containers/MessagesContainer';
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import WysiwygEditor from 'adel-shared/dist/components/WysiwygEditor';

interface DossierMessagesTabProps {
	dossierId: string;
	currentUser: BOCurrentUserDto;
}

const DossierMessagesTab: React.FC<DossierMessagesTabProps> = ({
	dossierId,
	currentUser
}) => {
	const dispatch = useDispatch();
	const me = useUserSelector();

	useEffect(() => {
		if(dossierId) {
			fetchDossierMessages(dispatch, dossierId, me.user?.id);
		}
	}, []);

	const dossierSelector = useDossierSelector();
	const { user } = useUserSelector();
	const templateSelector = useTemplateSelector();
	const templateDispatch = useTemplateDispatcher();
	const [templates, setTemplates] = useState<ModeleMessageDto[]>([]);

	const [selectedDiscussion, setSelectedDiscussion] = useState<DiscussionDto>();

	const editorDefaultEmptyValue = "<p><br></p>"

	const saveTemplateOption = [
		{
			value: "default",
			label: "Afficher un template",
			icon: "",
			button: <></>,
			hasClass: ""
		}
	];

	/** Modale ajout de discussion */
	const [modalNewMsg, setModalNewMsg] = useState<boolean>(false);

	/** Templates */
	const [templatesOptions, setTemplatesOptions] = useState<AdelOption<string>[]>(saveTemplateOption);
	const [templateSelected, setTemplateSelected] = useState<string>("default");


	//utile pour la modif du template
	const [currentTemplateValue, setCurrentTemplateValue] = useState();
	const [currentTemplate, setCurrentTemplate] = useState<ModeleMessageDto>({})
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [templateName, setTemplateName] = useState<string>("");
	const [templateToDeleteSelected, setTemplateToDeleteSelected] = useState<ModeleMessageDto>();
	const [templateEstPartage, setTemplateEstPartage] = useState<boolean>(false);


	/** Modal */
	const [activateModal, setActivateModal] = useState<boolean>(false);
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [activateModalDeleteTemplate, setActivateModalDeleteTemplate] = useState<boolean>(false);
	const [modalDeleteTemplateIsOpen, setModalDeleteTemplateIsOpen] = useState<boolean>(false);
	const [activateModalModifTemplate, setActivateModalModifTemplate] = useState<boolean>(false);
	const [modalModifTemplateIsOpen, setModalModifTemplateIsOpen] = useState<boolean>(false);



	/** Wysiwyg coontent */
	const [editorValueContent, setEditorValueContent] = useState<string>('');
	const [files, setFiles] = useState<Array<File>>([]);
	const [canSend, setCanSend] = useState<boolean>(false);

	useEffect(() => {
		if (files.length > 0 || editorValueContent !== '' && editorValueContent !== editorDefaultEmptyValue) {
			setCanSend(true);
		}

		if (files.length == 0 && editorValueContent === '' || editorValueContent === editorDefaultEmptyValue) {
			setCanSend(false);
		}

	}, [editorValueContent, files])


	useEffect(() => {
		if (dossierSelector.dossierDiscussions && dossierSelector.dossierDiscussions.length > 0) {
			if (dossierSelector.dossierDiscussion && dossierSelector.dossierDiscussion.id) {
				setSelectedDiscussion(dossierSelector.dossierDiscussion);
			} else {
				setSelectedDiscussion(dossierSelector.dossierDiscussions[0]);
			}
		}
	}, [dossierSelector.dossierDiscussions, dossierSelector.dossierDiscussion]);


	const submitMessage = async () => {
		if (selectedDiscussion) {
			setIsLoading(true);
			try {
				await sendDossierMessages(templateDispatch, 
					selectedDiscussion, 
					editorValueContent ? editorValueContent : "<p></p>",
					files.map(file => { return {
						data: file,
						fileName: file.name
				}}));
				fetchDossierMessages(dispatch, dossierId, user?.id);

			} catch(error) {
				toast.error("Une erreur est survenue à l'envoi du message");
			}
			setIsLoading(false);
			setEditorValueContent("");
			setFiles([]);
			scrollToBottom("messagerie");
		}
	}

	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
		if (value && value.target && value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
			setFiles([...files, value.target.files[0]]);
		}
	}

	const deleteFile = (file: File) => {
		if (files.length > 0) {
			let array = files.filter(f => f.name !== file.name);
			return setFiles(array);
		}
	}



	/************************ TEMPLATES *************/

	useEffect(() => {
		if (templateSelector.templates) {
			setTemplates(templateSelector.templates);
			templateOptionsList(templateSelector.templates);
		}
	}, [templateSelector.templates]);

	/** Get the vues list */
	const templateOptionsList = (templates: ModeleMessageDto[]) => {
		let options = saveTemplateOption;
		if (templates && templates.length > 0) {
			templates.map(elem =>
				options.push(
					{
						value: elem.contenu ? elem.contenu : '',
						label: elem.nom ? elem.nom : '',
						icon: elem.estPartage ? "fas fa-share" : '',
						button: <button onClick={() => {
							handleDeleteTemplateModalOpener(elem);
						}}><i className="far fa-trash-alt"></i></button>,
						hasClass: '',
					}
				)
			);
		}
		setTemplatesOptions(options);
	};

	//handle le changement d'options dans le select (select de templates)
	const handleChange = useCallback((selectedOption) => {
		if (selectedOption == "default") {
			setEditorValueContent("");
			setCurrentTemplate({})
		} else {
			//on met la value dans editorValueContent pour la passer à l'enfant
			setEditorValueContent(selectedOption);

			//le temps que le template soit mis dans jodit et dans le useState currentTemplate. Comme ça le boutton modifier le template ne s'affiche plus temporairement
			setCurrentTemplate({})

			//on stocke la value du template sélectionner (pour remettre la main sur le template dans un useEffect)
			setCurrentTemplateValue(selectedOption)
		}
	}, []);

	const openModalCreateTemplate = () => {
		setModalIsOpen(true);
		setActivateModal(true);
	}

	//on retrouve le template associé à la current value selectionné dans le select template
	useEffect(() => {
		templates.forEach(item => {
			if (item.contenu === currentTemplateValue) {
				const newArray = item
				setCurrentTemplate(newArray)
			}
		})

	}, [currentTemplateValue, templates]);

	// Get all templates
	useEffect(() => {
		dossierId && fetchTemplates(templateDispatch)();
	}, []);

	// Save template
	const createTemplate = () => {

		const newTemplate = {
			"nom": templateName,
			"contenu":
				editorValueContent
		}

		saveTemplate(templateDispatch, newTemplate)();

		if (!templateSelector.templateIsLoading) {
			setModalIsOpen(false);
			setTemplateSelected(newTemplate.contenu);
		}
	}

	useEffect(() => {
		if (templateSelector.template) {
			let newTemplatesArray = templates;
			//existe deja si template s'actualise via le PUT. N'existe pas si template s'actualise via le POST
			//On fait un check pour pas dubliquer l'option dans la liste d'option
			let alreadyExist = false;
			newTemplatesArray.map((elem) => {
				if (elem.id === templateSelector.template?.id) {
					alreadyExist = true;
					elem.contenu = templateSelector.template?.contenu;
				}
			})
			if (alreadyExist === false) {
				newTemplatesArray.push(templateSelector.template);
			}

			setTemplates(newTemplatesArray);
			setCurrentTemplate(templateSelector.template);
			templateOptionsList(newTemplatesArray);
			// setEditorValueContent(templateSelector.template.contenu as string)
		}
	}, [templateSelector.template]);

	const openModalModifTemplate = () => {
		setModalModifTemplateIsOpen(true);
		setActivateModalModifTemplate(true);
	}

	const modificationTemplate = () => {
		const newTemplate = {
			"nom": currentTemplate.nom,
			"contenu":
				editorValueContent,
			"estPartage": currentTemplate.estPartage
		}

		modifTemplate(templateDispatch, currentTemplate.id as string, newTemplate)();

		if (!templateSelector.templateIsLoading) {
			setModalModifTemplateIsOpen(false);
		}
	}
	
	// Delete template
	const handleDeleteTemplateModalOpener = useCallback((template: any) => {
		setTemplateToDeleteSelected(template);
		setModalDeleteTemplateIsOpen(true);
		setActivateModalDeleteTemplate(true);
	}, []);

	useEffect(() => {
		if (templateSelector.id) {
			let newVueArray = templates.filter(item => item.id !== templateSelector.id)
			setTemplates(newVueArray);
			templateOptionsList(newVueArray);
		}
	}, [templateSelector.id]);

	const handleDeleteTemplate = () => {
		deleteTemplate(templateDispatch, templateToDeleteSelected?.id ? templateToDeleteSelected?.id : '')()

		if (!templateSelector.templateDeleteIsLoading) {
			setModalDeleteTemplateIsOpen(false);
			setEditorValueContent("");
		}
	};

	/****************** */
	return (
		<div className="detailsTab">
			<div className="messagerie__display">
				<DossierMessagesList
					discussions={dossierSelector.dossierDiscussions || []}
					setModalNewMsg={setModalNewMsg}
					modalNewMsg={modalNewMsg}
					selectedDiscussion={selectedDiscussion}
					setSelectedDiscussion={setSelectedDiscussion}
					dossierId={dossierId}
				/>

				<div className="detailsTab__section">
					{selectedDiscussion && (
						<>
							<MessagesContainer
								discussion={selectedDiscussion}
								user={currentUser}
							/>

							{selectedDiscussion.statut !== StatutDiscussion.Termine && (
								<div >
									<div className="templates">
										<div className="templates__title">Templates</div>
										<InputSelect
											name="vueSelect"
											classname="filtres__inputSelect inputSelect--margin"
											options={templatesOptions}
											onChange={handleChange}
											value={templateSelected}
										/>

										{/* Ajout template  */}
										<button disabled={editorValueContent === '' || editorValueContent === editorDefaultEmptyValue} className="templates__button" onClick={() => openModalCreateTemplate()}>Nouveau template</button>
										{currentTemplate.contenu && currentTemplate.contenu !== editorValueContent && editorValueContent !== '' && editorValueContent !== 'defaut' && editorValueContent !== editorDefaultEmptyValue ?
											<button className="templates__button" onClick={() => openModalModifTemplate()}>Modifier le template</button>
										:
											<></>
										}
									</div>
									<div>
										<div>
											<WysiwygEditor
												editorValueContent={editorValueContent}
												setEditorValueContent={setEditorValueContent}
											/>
										</div>
									</div>
									{activateModal &&
										<Modal isOpen={modalIsOpen} style={modalCustomStyles} className="normal__modal">
											<div className="modal__header">
												<h3>Enregistrer le template courant</h3>
											</div>
											<div className="modal__content">
												<div className="modal__item">
													<Input
														name="newVue"
														label='Nom de mon nouveau template'
														type='text'
														onChange={(value: string) => setTemplateName(value)}
													/>
												</div>
												<div className="modal__item">
													<Toggle
														label="Partager mon template"
														value={templateEstPartage}
														setCheck={setTemplateEstPartage}
													/>
												</div>
											</div>
											<div className="modal__footer">
												<FormButton type="button" value="Annuler" onClick={() => setModalIsOpen(false)} />
												{templateSelector.templateIsLoading
													? <Loader
														type="TailSpin"
														width={35}
														height={35}
														color="#d93943"
													></Loader>
													: <FormButton type="submit" value="Enregistrer" onClick={() => createTemplate()} />
												}
											</div>
										</Modal>
									}
									{activateModalDeleteTemplate &&
										<Modal
											isOpen={modalDeleteTemplateIsOpen}
											style={modalCustomStyles}
											className="normal__modal"
										>
											<div className="modal__header">
												<h3>Supprimer le template</h3>
											</div>
											<div className="modal__content">
												<div className="modal__item">
													Voulez-vous vraiment supprimer le template "{templateToDeleteSelected?.nom}" ?
												</div>
											</div>
											<div className="modal__footer">
												<FormButton
													type="button"
													value="Annuler"
													onClick={() => {
														setModalDeleteTemplateIsOpen(false);
													}}
												/>
												{
													templateSelector.templateDeleteIsLoading
														? <Loader
															type="TailSpin"
															width={35}
															height={35}
															color="#d93943"
														></Loader>
														: <FormButton
															type="submit"
															value="Supprimer"
															onClick={() => handleDeleteTemplate()}
														/>
												}
											</div>
										</Modal>
									}
									{activateModalModifTemplate &&
										<Modal
											isOpen={modalModifTemplateIsOpen}
											style={modalCustomStyles}
											className="normal__modal"
										>
											<div className="modal__header">
												<h3>Enregistrer les modifications du template courant</h3>
											</div>
											<div className="modal__content">
												<div className="modal__item">
													<p>Voulez vous enregistrer les modifications apportées au template sélectionné ?</p>
												</div>
											</div>
											<div className="modal__footer">
												<FormButton
													type="button"
													value="Annuler"
													onClick={() => {
														setModalModifTemplateIsOpen(false);
													}}
												/>
												{
													templateSelector.templateIsLoading
														? <Loader
															type="TailSpin"
															width={35}
															height={35}
															color="#d93943"
														></Loader>
														: <FormButton
															type="submit"
															value="Enregistrer"
															onClick={modificationTemplate}
														/>
												}
											</div>
										</Modal>
									}
									<div className="messagerieFooter">
										{files && files.length > 0
											? <div className="messagerieFooter__files">
												<label>Pièces jointes :</label>
												{files.map((file, i) => {
													return <span key={i}>
														{file.name}
														<i className="far fa-trash-alt" onClick={() => deleteFile(file)}></i>
														{files.length === 1 || files.indexOf(file) === files.length - 1 ? '' : ', '}
													</span>
												})
												}
											</div>
											: <div></div>
										}
										<div className="messagerieFooter__buttons">
											{isLoading
													? <Loader
														type="TailSpin"
														width={35}
														height={35}
														color="#d93943"
													></Loader>
											: <>
												<div className="inputFile__singleButton">
													<label htmlFor="uploadDocument">
														<i className="fas fa-paperclip"></i>
													</label>
													<input
														// ref={register(customFileValidation)}
														id="uploadDocument"
														type="file"
														onChange={handleFileChange}
													/>
												</div>
												<FormButton
													disabled={!canSend}
													type="submit"
													value="Envoyer"
													onClick={submitMessage}
												/>
											</>}
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default DossierMessagesTab;