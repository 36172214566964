import React, { FunctionComponent, useEffect } from 'react';
import PagesContainer from '../../containers/PagesContainer';
import { toast } from 'react-toastify';
import BordereauxTabs from './BordereauxTabs';
import {
    clotureBordereauReaffectation,
    getBordereauReaffectation
} from '../../store/bordereau-store/actions/bordereauActions';
import { useDispatch } from 'react-redux';
import { useBordereauSelector } from '../../store/store-helpers';
import { useTranslation } from 'react-i18next';
import { getTotalMouvements } from './BordereauUtils';
import { navigate } from '@reach/router';
import { TypeBordereau } from '../../services/generated/BackOffice-api';

interface BordereauReaffectationProps {
    bordereauId: string;
}

const BordereauReaffectation: FunctionComponent<BordereauReaffectationProps> = ({
    bordereauId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { bordereauReaffectation = {} } = useBordereauSelector();

    /** React table config */
	const columns = React.useMemo(() => {
		return  [
            {
                Header: "Numéro de dossier",
                accessor: "dossierNumero",
                sortType: "basic"
            },
            {
                Header: "Dossier",
                accessor: "dossierNom",
                sortType: "basic"
            },
            {
                Header: "Bénéficiaire",
                accessor: "beneficiaire",
                sortType: "basic"
            },
            {
                Header: "Code structure",
                accessor: "userName",
                sortType: "basic"
            },
            {
                Header: "Montant accordé",
                accessor: "montantAccorde",
                sortType: "basic",
				className:'table__cell--montants'
            },
            {
                Header: "Montant réaffecté",
                accessor: "montantReaffecte",
                sortType: "basic",
				className:'table__cell--montants'
            },
            {
                Header: "Motif",
                accessor: "motif",
                sortType: "basic"
            }
        ];
	}, []);

    useEffect(() => {
        (async() => {
            await getBordereauReaffectation(bordereauId, dispatch);
        })();
    }, []);

    const handleClotureClick = async() => {
        try {
            await clotureBordereauReaffectation();
			navigate(`/Comptabilite/Bordereaux/${TypeBordereau.Reaffectation}`);
            toast.success('Le bordereau a été clôturé avec succès.');
        } catch {
            toast.error('Une erreur s\'est produite pendant la clôture du bordereau.');
        }
    };

    return (
        <PagesContainer
            title={`Comptabilité - Gestion du bordereau de réaffectation ${bordereauReaffectation.statutBordereau ? t(`bordereau.statut.${bordereauReaffectation?.statutBordereau}`) : ''}`}
            additionalHeaderElem={
                <>
                    <div className="bordereau-number">
                        Numéro du bordereau : {bordereauReaffectation.numero}
                    </div>
                    <div className="total-amount">
                        Montant total actuel : {(Math.round(getTotalMouvements(bordereauReaffectation.mouvements) * 100) / 100).toString().replace('.', ',')} €
                    </div>
                </>
            }
            pageClass="bordereaux-versement"
        >
            <>
                <BordereauxTabs
                    bordereau={bordereauReaffectation}
                    tableColumns={columns}
                    onCloture={handleClotureClick}
                />
            </>
        </PagesContainer>
    )
};

export default BordereauReaffectation;