import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import {
	AddEvenementFinancierDto,
	CreateRelationDto,
	DossierClient,
	DossierDetailsDto,
	RelationClient,
	UpdateDocumentsValidationDto
} from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from './dossierAction';

/** Actions */
export const requestDossierDetails = () => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_DETAILS_REQUEST,
		payload: {}
	}
}

export const cleanupDossierDetails = () => {
	return {
		type: DossierActionsEnum.CLEANUP_DOSSIER_REQUEST,
		payload: {}
	}
}

export const getDossierDetails = (result: DossierDetailsDto) => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_DETAILS_SUCCESS,
		payload: {dossierDetails: result}
	}
}

export const errorDossierDetails = () => {
	return {
		type: DossierActionsEnum.GET_DOSSIER_DETAILS_ERROR
	}
}

/** Task */
const dossierClient = new DossierClient('', axiosInstance);
const relationsClient = new RelationClient('', axiosInstance);

export const fetchDossierDetails = async (dispatch: Dispatch, id: string) => {
	dispatch(requestDossierDetails());
	try {
		const result = await dossierClient.getDossierDetails(id);
		dispatch(getDossierDetails(result));
	} catch(error) {
		dispatch(errorDossierDetails());
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les détails de ce dossier");
	}
}

export const getInfosProjet = async (id: string) => {
	try {
		const result = await dossierClient.getInfosProjetViewModel(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les infos projet de ce dossier");
		return;
	}
}

export const getDescriptionProjet = async (id: string) => {
	try {
		const result = await dossierClient.getDescriptionProjetViewModel(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les infos de description de ce dossier");
		return;
	}
}

export const fetchDossierRelations = async (dispatch: Dispatch, id: string) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_RELATIONS_REQUEST });
	try {
		const response = await relationsClient.getDossierRelations(id);
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_RELATIONS_SUCCESS,
			payload: { dossierRelations: response }
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_RELATIONS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les relations de dossier");
	}
}

export const addDossierRelations = async (dispatch: Dispatch, id: string, body: CreateRelationDto) => {
	dispatch({ type: DossierActionsEnum.ADD_DOSSIER_RELATIONS_REQUEST });
	try {
		const response = await relationsClient.createDossierRelation(id,body);
		dispatch({
			type: DossierActionsEnum.ADD_DOSSIER_RELATIONS_SUCCESS
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.ADD_DOSSIER_RELATIONS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible d'ajouter la relation");
	}
}

export const deleteDossierRelations = async (dispatch: Dispatch, id: string) => {
	dispatch({ type: DossierActionsEnum.DELETE_DOSSIER_RELATIONS_REQUEST });
	try {
		await relationsClient.deleteRelation(id);
		dispatch({
			type: DossierActionsEnum.DELETE_DOSSIER_RELATIONS_SUCCESS
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.DELETE_DOSSIER_RELATIONS_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de supprimer la relation");
	}
}

export const fetchDossierAidesHistory = async (dispatch: Dispatch, id: string) => {
	dispatch({ type: DossierActionsEnum.GET_DOSSIER_AIDES_HISTORY_REQUEST });
	try {
		const response = await dossierClient.getDossierAidHistory(id);
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_AIDES_HISTORY_SUCCESS,
			payload: { dossierAidesHistory: response }
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.GET_DOSSIER_AIDES_HISTORY_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer l'historique d'aides");
	}
}

export const updateDossierDocuments = async (dispatch: Dispatch, id: string, documentsDto: UpdateDocumentsValidationDto) => {
	dispatch({ type: DossierActionsEnum.PUT_DOSSIER_DOCUMENTS_VALIDATION_REQUEST });
	try {
		await dossierClient.updateDossierDocumentsValidation(id, documentsDto);
		dispatch({
			type: DossierActionsEnum.PUT_DOSSIER_DOCUMENTS_VALIDATION_SUCCESS,
			payload: {
				dossierDocuments: documentsDto.documents
			}
		});
	} catch(error) {
		dispatch({ type: DossierActionsEnum.PUT_DOSSIER_DOCUMENTS_VALIDATION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour les documents");
	}
}

export const getDossierSyntheseFinanciere = async (dispatch: Dispatch, id: string) => {
	try {
		const response = await dossierClient.getDossierSyntheseFinanciere(id);
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_SYNTHESE_FINANCIERE,
			payload: {dossierSynthese: response }
		});
	} catch(error) {
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_SYNTHESE_FINANCIERE,
			payload: {dossierSynthese: null }
		});
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue durant la récupération de la synthèse financière.")
	}
}

export const getDossierMouvements = async (dispatch: Dispatch, id: string) => {
	try {
		const response = await dossierClient.getDossierMouvements(id);
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_MOUVEMENTS,
			payload: {dossierMouvements: response }
		});
	} catch(error) {
		dispatch({
			type: DossierActionsEnum.GET_DOSSIER_MOUVEMENTS,
			payload: {dossierMouvements: null }
		});
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue durant la récupération des mouvements.")
	}
}

export const getEvenementsFinanciersViewModel = async(dispatch: Dispatch, id: string) => {
	try {
		const response = await dossierClient.getEvenementsFinanciersViewModel(id);
		dispatch({
			type: DossierActionsEnum.GET_EVENEMENTS_FINANCIERS_SUCCESS,
			payload: { evenementFinancier: response }
		});
	} catch(error) {
		dispatch({
			type: DossierActionsEnum.GET_EVENEMENTS_FINANCIERS_ERROR,
			payload: { montantReaffectation: null, evenementFinancier: null }
		});
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les évènements financiers");
		throw error;
	}
};

export const ajoutEvenementFinancier = async (dispatch: Dispatch, id: string, body: AddEvenementFinancierDto) => {
	try {
		dispatch({ type: DossierActionsEnum.AJOUT_EVENEMENT_FINANCIER_REQUEST });
		const response = await dossierClient.ajoutEvenementFinancier(id, body);
		dispatch({
			type: DossierActionsEnum.AJOUT_EVENEMENT_FINANCIER_SUCCESS,
			payload: { dossierMouvement: response }
		});
		await getDossierMouvements(dispatch, id);

	} catch(error) {
		dispatch({ type: DossierActionsEnum.AJOUT_EVENEMENT_FINANCIER_ERROR, payload: { dossierMouvement: null } });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors de l'ajout d'évènement financier.");
		throw "error";
	}
}
