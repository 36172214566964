import { Action } from 'redux';
import {
	CategorieDossierClient,
	CommissionClient,
	CommissionDto,
	CommissionDtoSortedFilteredPage,
	CreateOrUpdateCommissionDto,
	StatutCommission,
	UpdateCommissionEnveloppesBudgetairesDto,
	UpdateCommissionCAContentDto,
	UpdateCommissionContentDto,
	UpdateCommissionMemberListDto,
	ProblemDetailsCode
} from '../../../services/generated/BackOffice-api';
import { CommissionState } from '../states/commissionState';
import {
	caCommissionFilterKey,
	groupeFilterKey,
	nomFilterKey,
	statutFilterKey,
	typeFilterKey,
	commissionMembrePresenceFilterKey
} from "../../../constants/filters.constant";
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';


export enum CommissionActionsEnum {
	// Commission list
	GET_COMMISSIONS_REQUEST = "GET_COMMISSIONS_REQUEST",
	GET_COMMISSIONS_SUCCESS = "GET_COMMISSIONS_SUCCESS",
	GET_COMMISSIONS_ERROR = "GET_COMMISSIONS_ERROR",
	
	GET_COMMISSIONS_LIST_LIGHT_REQUEST = "GET_COMMISSIONS_LIST_LIGHT_REQUEST",
	GET_COMMISSIONS_LIST_LIGHT_SUCCESS = "GET_COMMISSIONS_LIST_LIGHT_SUCCESS",
	GET_COMMISSIONS_LIST_LIGHT_ERROR = "GET_COMMISSIONS_LIST_LIGHT_ERROR",

	// Commission filter	
	UPDATE_COMMISSION_FILTRE_SUCCESS = "UPDATE_COMMISSION_FILTRE_SUCCESS",

	// Commission item
	GET_COMMISSION_REQUEST = "GET_COMMISSION_REQUEST",
	GET_COMMISSION_SUCCESS = "GET_COMMISSION_SUCCESS",
	GET_COMMISSION_ERROR = "GET_COMMISSION_ERROR",
	CLOSE_COMMISSION_REQUEST = "CLOSE_COMMISSION_REQUEST",
	CLOSE_COMMISSION_SUCCESS = "CLOSE_COMMISSION_SUCCESS",
	CLOSE_COMMISSION_ERROR = "CLOSE_COMMISSION_ERROR",
	
	FIGER_COMMISSION_REQUEST = "FIGER_COMMISSION_REQUEST",
	FIGER_COMMISSION_SUCCESS = "FIGER_COMMISSION_SUCCESS",
	FIGER_COMMISSION_ERROR = "FIGER_COMMISSION_ERROR",

	PUT_COMMISSION_REQUEST = "PUT_COMMISSION_REQUEST",
	PUT_COMMISSION_SUCCESS = "PUT_COMMISSION_SUCCESS",
	PUT_COMMISSION_ERROR = "PUT_COMMISSION_ERROR",

	// Dossiers list inside commission
	GET_COMMISSION_DETAILS_DOSSIERS_REQUEST = "GET_COMMISSION_DETAILS_DOSSIERS_REQUEST",
	GET_COMMISSION_DETAILS_DOSSIERS_SUCCESS = "GET_COMMISSION_DETAILS_DOSSIERS_SUCCESS",
	GET_COMMISSION_DETAILS_DOSSIERS_ERROR = "GET_COMMISSION_DETAILS_DOSSIERS_ERROR",

	// Commission dossiers list
	GET_COMMISSION_DOSSIERS_LIST_REQUEST = "GET_COMMISSION_DOSSIERS_LIST_REQUEST",
	GET_COMMISSION_DOSSIERS_LIST_SUCCESS = "GET_COMMISSION_DOSSIERS_LIST_SUCCESS",
	GET_COMMISSION_DOSSIERS_LIST_ERROR = "GET_COMMISSION_DOSSIERS_LIST_ERROR",

	// Créer commission
	CREATE_COMMISSION_REQUEST = "CREATE_COMMISSION_REQUEST",
	CREATE_COMMISSION_SUCCESS = "CREATE_COMMISSION_SUCCESS",
	CREATE_COMMISSION_ERROR = "CREATE_COMMISSION_ERROR",

	// Membres
	GET_COMMISSION_MEMBRES_REQUEST = "GET_COMMISSION_MEMBRES_REQUEST",
	GET_COMMISSION_MEMBRES_SUCCESS = "GET_COMMISSION_MEMBRES_SUCCESS",
	GET_COMMISSION_MEMBRES_ERROR = "GET_COMMISSION_MEMBRES_ERROR",

	// Liste des dossiers commission
	GET_DOSSIERS_COMMISSION_REQUEST = "GET_DOSSIERS_COMMISSION_REQUEST",
	GET_DOSSIERS_COMMISSION_SUCCESS = "GET_DOSSIERS_COMMISSION_SUCCESS",
	GET_DOSSIERS_COMMISSION_ERROR = "GET_DOSSIERS_COMMISSION_ERROR",

	// Liste des sessions CA
	GET_SESSIONS_CA_REQUEST = "GET_SESSIONS_CA_REQUEST",
	GET_SESSIONS_CA_SUCCESS = "GET_SESSIONS_CA_SUCCESS",
	GET_SESSIONS_CA_ERROR = "GET_SESSIONS_CA_ERROR",
	GET_SESSION_CA_DOSSIERS_REQUEST = "GET_SESSION_CA_DOSSIERS_REQUEST",
	GET_SESSION_CA_DOSSIERS_SUCCESS = "GET_SESSION_CA_DOSSIERS_SUCCESS",
	GET_SESSION_CA_DOSSIERS_ERROR = "GET_SESSION_CA_DOSSIERS_ERROR",
	ADD_MONTANT_COMMISSION_CA_REQUEST = "ADD_MONTANT_COMMISSION_CA_REQUEST",
	ADD_MONTANT_COMMISSION_CA_SUCCESS = "ADD_MONTANT_COMMISSION_CA_SUCCESS",
	ADD_MONTANT_COMMISSION_CA_ERROR = "ADD_MONTANT_COMMISSION_CA_ERROR",
	CLOSE_SESSION_CA_REQUEST = "CLOSE_SESSION_CA_REQUEST",
	CLOSE_SESSION_CA_SUCCESS = "CLOSE_SESSION_CA_SUCCESS",
	CLOSE_SESSION_CA_ERROR = "CLOSE_SESSION_CA_ERROR",

	// Enveloppes budgetaires
	GET_COMMISSION_ENVELOPPES_BUDGETAIRES_REQUEST = "GET_COMMISSION_ENVELOPPES_BUDGETAIRES_REQUEST",
	GET_COMMISSION_ENVELOPPES_BUDGETAIRES_SUCCESS = "GET_COMMISSION_ENVELOPPES_BUDGETAIRES_SUCCESS",
	GET_COMMISSION_ENVELOPPES_BUDGETAIRES_ERROR = "GET_COMMISSION_ENVELOPPES_BUDGETAIRES_ERROR",

	PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_REQUEST = "PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_REQUEST",
	PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_SUCCESS = "PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_SUCCESS",
	PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_ERROR = "PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_ERROR",
	
	// Contenu commission
	PUT_COMMISSION_CONTENT_REQUEST = "PUT_COMMISSION_CONTENT_REQUEST",
	PUT_COMMISSION_CONTENT_SUCCESS = "PUT_COMMISSION_CONTENT_SUCCESS",
	PUT_COMMISSION_CONTENT_ERROR = "PUT_COMMISSION_CONTENT_ERROR",

	// Contenu commission CA
	PUT_COMMISSION_CA_CONTENT_REQUEST = "PUT_COMMISSION_CA_CONTENT_REQUEST",
	PUT_COMMISSION_CA_CONTENT_SUCCESS = "PUT_COMMISSION_CA_CONTENT_SUCCESS",
	PUT_COMMISSION_CA_CONTENT_ERROR = "PUT_COMMISSION_CA_CONTENT_ERROR",
	OPEN_COMMISSION_CA_REQUEST = "OPEN_COMMISSION_CA_REQUEST",
	OPEN_COMMISSION_CA_SUCCESS = "OPEN_COMMISSION_CA_SUCCESS",
	OPEN_COMMISSION_CA_ERROR = "OPEN_COMMISSION_CA_ERROR",

	// Tableau de bord
	GET_COMMISSION_TABLEAUBORD_REQUEST = "GET_COMMISSION_TABLEAUBORD_REQUEST",
	GET_COMMISSION_TABLEAUBORD_SUCCESS = "GET_COMMISSION_TABLEAUBORD_SUCCESS",
	GET_COMMISSION_TABLEAUBORD_ERROR = "GET_COMMISSION_TABLEAUBORD_ERROR",

	
	// Ouvrir commission
	OPEN_COMMISSION_REQUEST = "OPEN_COMMISSION_REQUEST",
	OPEN_COMMISSION_SUCCESS = "OPEN_COMMISSION_SUCCESS",
	OPEN_COMMISSION_ERROR = "OPEN_COMMISSION_ERROR",
	CHANGE_COMMISSION_STATUT = "CHANGE_COMMISSION_STATUT"
}

export interface DispatchCommissionAction extends Action<CommissionActionsEnum> {
	payload?: CommissionState;
}

export const requestCommissions = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSIONS_REQUEST,
	}
}

export const getCommissions = (result: CommissionDtoSortedFilteredPage) => {
	return {
		type: CommissionActionsEnum.GET_COMMISSIONS_SUCCESS,
		payload: { commissions: result }
	}
}

export const errorCommissions = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSIONS_ERROR,
	}
}

/***/

export const requestCreateCommission = () => {
	return {
		type: CommissionActionsEnum.CREATE_COMMISSION_REQUEST,
	}
}

export const createCommissionAction = (result: CommissionDto) => {
	return {
		type: CommissionActionsEnum.CREATE_COMMISSION_SUCCESS,
		payload: { commission: result }
	}
}

export const errorCreateCommission = (result: string) => {
	return {
		type: CommissionActionsEnum.CREATE_COMMISSION_ERROR,
		payload: { errorCommissionCode: result }
	}
}

/***/

export const requestCommission = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSION_REQUEST,
	}
}

export const getCommission = (result: CommissionDto) => {
	return {
		type: CommissionActionsEnum.GET_COMMISSION_SUCCESS,
		payload: { commission: result }
	}
}

export const errorCommission = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSION_ERROR,
	}
}

/***/

export const requestCommissionDossiers = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSION_DOSSIERS_LIST_REQUEST,
	}
}

/***/
export const requestCommissionUpdate = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_REQUEST,
	}
}

export const updateCommissionAction = (result: CommissionDto) => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_SUCCESS,
		payload: { commission: result }
	}
}

export const errorUpdateCommission = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_ERROR,
	}
}



/***/
export const requestCommissionEnveloppesBudgetairesUpdate = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_REQUEST
	}
}

export const updateCommissionEnveloppesBudgetairesAction = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_SUCCESS,
	}
}

export const errorUpdateCommissionEnveloppesBudgetaires = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_ENVELOPPES_BUDGETAIRES_ERROR,
	}
}

/***/
export const requestCommissionContentUpdate = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_CONTENT_REQUEST
	}
}

export const updateCommissionContentAction = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_CONTENT_SUCCESS
	}
}

export const errorUpdateCommissionContent = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_CONTENT_ERROR
	}
}

/***/
export const requestCommissionCAContentUpdate = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_CA_CONTENT_REQUEST
	}
}

export const updateCommissionCAContentAction = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_CA_CONTENT_SUCCESS
	}
}

export const errorUpdateCommissionCAContent = () => {
	return {
		type: CommissionActionsEnum.PUT_COMMISSION_CA_CONTENT_ERROR
	}
}

/***/
export const requestCommissionMembres = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSION_MEMBRES_REQUEST
	}
}

export const getCommissionMembres = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSION_MEMBRES_SUCCESS
	}
}

export const errorCommissionMembres = () => {
	return {
		type: CommissionActionsEnum.GET_COMMISSION_MEMBRES_ERROR
	}
}

/** Task */
const commissionClient = new CommissionClient('', axiosInstance);
const categorieDossierClient = new CategorieDossierClient('', axiosInstance);

export const fetchCriteresDeDossierCommissions = async (dispatch: Dispatch<DispatchCommissionAction>, categorieId: string) => {
	dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_REQUEST, payload: {} });
	try {
		let result = await categorieDossierClient.getCritereDeDossierCommissions(categorieId);
		dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_SUCCESS, payload: { commissions: { items: result } } })
	} catch (error) {
		dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_ERROR, payload: {} });
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les budgets du tableau de bord");
		throw "error";
	}
}

export const fetchLastCritereDeDossier = async (categorieId: string) => {
	try {
		let result = await categorieDossierClient.getLastCritereDossier(categorieId);
		return result;
	} catch (error) {
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer le dernier critère de dossier");
		throw "error";
	}
}

export const fetchDossierCommissions = async (dispatch: Dispatch<DispatchCommissionAction>, dossierId: string) => {
	dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_REQUEST, payload: {} });
	try {
		let result = await commissionClient.getCommissionsForDossier(dossierId);
		dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_SUCCESS, payload: { commissions: { items: result } } })
	} catch (error) {
		dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_ERROR,  payload: {} });
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les dossiers de commission");
		throw "error";
	}
}

export const fetchCommissions = (
	dispatch: any,
	disablePagination: boolean = false,
	year?: number,
	groupe?: string,
	nom?: string,
	page: number = 1,
	pageSize: number = 3,
	sort?: string,
	statut?: StatutCommission
) => {
	const filters: string[] = [];

	/**
	 * Le comparateur (@= ou ==) doit directement être inclu dans la variable
	 * @= --> CONTAINS
	 * == --> STRICTEMENT EGAL
	 *
	 * Cf. https://github.com/Biarity/Sieve#send-a-request
	 *
	 */

	if (groupe) {
		filters.push(`${groupeFilterKey}${groupe}`);
	}
	if (nom) {
		filters.push(`${nomFilterKey}${nom}`);
	}
	if (statut) {
		filters.push(`${statutFilterKey}${statut}`)
	}

	return () => {
		dispatch(requestCommissions());

		// (filters: string | null | undefined,
		// sorts: string | null | undefined,
		// page: number | null | undefined,
		// pageSize: number | null | undefined,
		// disablePagination: boolean | undefined,
		// year: number | null | undefined)
		return commissionClient.getCommissions(filters.join(), sort, page, pageSize, disablePagination, year)
			.then((response: CommissionDtoSortedFilteredPage) => dispatch(getCommissions(response)))
			.catch((error: any) => {
				dispatch(errorCommissions());

				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer les commissions");
			});
	}
}

export const fetchCommissionsListLight = async (
	dispatch: any,
) => {
	dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_LIST_LIGHT_REQUEST });
	try {
		let response = await commissionClient.getCommissionsStartingDate();
		dispatch({
			type: CommissionActionsEnum.GET_COMMISSIONS_LIST_LIGHT_SUCCESS,
			payload: { commissionsListLight: response }
		});
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.GET_COMMISSIONS_LIST_LIGHT_ERROR });
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer la liste de commissions");
	}
}

export const createCommission = (
	dispatch: Dispatch<DispatchCommissionAction>,
	body: CreateOrUpdateCommissionDto,
	setModalAjout: (value: boolean) => void
) => {
	return () => {
		dispatch(requestCreateCommission());

		return commissionClient.createCommission(body)
			.then((response: CommissionDto) => {
				dispatch(createCommissionAction(response));
				setModalAjout(false);
			})
			.catch((error: any) => {
				dispatch(errorCreateCommission(error.response?.data?.code));
				
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de créer la commission");
			});
	}
}

export const fetchCommissionDossiersListe = async (
	dispatch: Dispatch<DispatchCommissionAction>,
	idCommission: string,
	categorieId: string,
	disablePagination: boolean = false,
	page: number,
	pageSize: number,
	sort?: string,
	statut?: string,
	ca?: boolean
) => {
	const filters: string[] = [];

	if (categorieId) {
		filters.push(`${typeFilterKey}${categorieId}`)
	}

	if (statut) {
		filters.push(`${statutFilterKey}${statut}`)
	}

	if (ca === true) {
		filters.push(`${caCommissionFilterKey}${ca}`)
	}

	dispatch({ type: CommissionActionsEnum.GET_COMMISSION_DOSSIERS_LIST_REQUEST, payload: {} });
	try {
		const result = await commissionClient.getCommissionListingDossiers(idCommission, filters.join(), sort, page, pageSize, disablePagination);
		dispatch({ type: CommissionActionsEnum.GET_COMMISSION_DOSSIERS_LIST_SUCCESS, payload: { commissionDossiers: result } })
	} catch (error) {
		dispatch({ type: CommissionActionsEnum.GET_COMMISSION_DOSSIERS_LIST_ERROR, payload: {} });
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer la liste des dossiers de cette commission");
		throw "error";
	}
}
export const updateFilter = (result: any) => {
	return {
		type: CommissionActionsEnum.UPDATE_COMMISSION_FILTRE_SUCCESS,
		payload: result
	}
}
export const fetchCommissionDetailsDossiers = async(
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string,
	categorie?: string
) => {
	try {
		const result = await commissionClient.getCommissionDossiers(id, categorie === 'all' ? undefined : categorie);
		dispatch({
			type: CommissionActionsEnum.GET_COMMISSION_DETAILS_DOSSIERS_SUCCESS,
			payload: {
				commissionDetailsDossiers: result
			}
		})
		return result;
	} catch (error) {
		dispatch({ type: CommissionActionsEnum.GET_COMMISSION_DETAILS_DOSSIERS_ERROR, payload: {
			commissionDetailsDossiers: []
		} });
		toast.error("Impossible de récupérer la liste des dossiers de cette commission");
		throw error;
	}
};

export const fetchCommission = async(
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string
) => {
	dispatch(requestCommission());
	try {
		const result = await commissionClient.getCommission(id);
		dispatch(getCommission(result));
	} catch(error) {
		dispatch(errorCommission());
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer la commission");
		throw "error";
	}
}

export const closeCommission = async (
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string,
	commentaire?: string
) => {
	dispatch({ type: CommissionActionsEnum.CLOSE_COMMISSION_REQUEST });
	try {
		await commissionClient.closeCommission(id, {commentaire: commentaire});
		dispatch({ type: CommissionActionsEnum.CLOSE_COMMISSION_SUCCESS });
		toast.success("La commission a bien été clôturée");
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.CLOSE_COMMISSION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
		 	toast.error("Impossible de clôturer la commission");
		throw "error";
	}
}

export const figerCommission = async (dispatch: Dispatch<DispatchCommissionAction>, id: string) => {
	dispatch({ type: CommissionActionsEnum.FIGER_COMMISSION_REQUEST });
	try {
		await commissionClient.figeCommissionStatut(id);
		dispatch({ type: CommissionActionsEnum.FIGER_COMMISSION_SUCCESS });
		dispatch({ type: CommissionActionsEnum.CHANGE_COMMISSION_STATUT, payload: { idCommissionToChange: id } });

		toast.success("La commission a bien été figée");
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.FIGER_COMMISSION_ERROR });
		if (error.response && error.response.code === ProblemDetailsCode.CommissionsMembersAreNotSet)
			toast.error("Il n'y a aucun membre présent pour cette commission");
		else if (error.response && error.response.code === ProblemDetailsCode.DateFinSoumissionMustBePassed)
			toast.error("Vous ne pouvez pas figer cette commission, car la date de dépôt n'est pas encore passée");	
		else if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de figer la commission");
		throw "error";
	}
}



export const updateCommission = async (
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string,
	body: CreateOrUpdateCommissionDto
) => {
	dispatch(requestCommissionUpdate());
	try {
		const result = await commissionClient.updateCommission(id, body);
		dispatch(updateCommissionAction(result));
	} catch(error) {
		dispatch(errorUpdateCommission());
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de mettre à jour la commission");
		throw error;
	}
}

export const fetchCommissionEnveloppesBudgetaires = async(
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string
) => {
	dispatch({ type: CommissionActionsEnum.GET_COMMISSION_ENVELOPPES_BUDGETAIRES_REQUEST });
	try {
		const result = await commissionClient.getCommissionEnveloppesBudgetaires(id);
		dispatch({
			type: CommissionActionsEnum.GET_COMMISSION_ENVELOPPES_BUDGETAIRES_SUCCESS,
			payload: { commissionEnveloppesBudgetaires: result }
		});
		return result;
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.GET_COMMISSION_ENVELOPPES_BUDGETAIRES_ERROR });
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les enveloppes budgétaires");
		throw "error";
	}
}

export const updateCommissionEnveloppesBudgetaires = async(
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string,
	body: UpdateCommissionEnveloppesBudgetairesDto
) => {
	dispatch(requestCommissionEnveloppesBudgetairesUpdate());
	try {
		await commissionClient.updateCommissionEnveloppesBudgetaires(id, body);
		dispatch(updateCommissionEnveloppesBudgetairesAction());
		toast.success("L'enveloppe budgétaire a bien été envoyée");
	} catch(error) {
		dispatch(errorUpdateCommissionEnveloppesBudgetaires());
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.success("Impossible de mettre à jour l'enveloppe budgétaire");
		throw "error";
	}
}

export const updateCommissionContent = async (
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string,
	body: UpdateCommissionContentDto
) => {
	dispatch(requestCommissionContentUpdate());
	try {
		await commissionClient.updateCommissionContent(id, body);
		dispatch(updateCommissionContentAction());
	} catch(error) {
		dispatch(errorUpdateCommissionContent());
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de mettre à jour la commission");
		throw "error";
	}
}

export const updateCommissionCAContent = async (
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string,
	body: UpdateCommissionCAContentDto
) => {
	dispatch(requestCommissionCAContentUpdate());
	try {
		await commissionClient.updateCommissionCAContent(id, body);
		dispatch(updateCommissionCAContentAction());
	} catch(error) {
		dispatch(errorUpdateCommissionCAContent());
		
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de mettre à jour les commissions CA");
		throw "error";
	}
}

export const fetchCommissionMembres = async (
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string
) => {
	dispatch(requestCommissionMembres());
	try {
		const result = await commissionClient.getCommissionMembers(id);
		dispatch(getCommissionMembres());
		return result;
	} catch(error) {
		dispatch(errorCommissionMembres());
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les membres de commission");
		throw "error";
	}
}

export const updateCommissionMembres = async(
	dispatch: Dispatch<DispatchCommissionAction>,
	id: string,
	body: UpdateCommissionMemberListDto
) => {
	dispatch(requestCommissionMembres());
	try {
		await commissionClient.updateCommissionMemberList(id, body);
	} catch(error) {
		dispatch(errorCommissionMembres());
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de mettre à jour la liste des membres de commission");
		throw "error";
	}
};

export const fetchSigningSheets = async(
	id: string,
	disablePagination: boolean = true,
	page?: number,
	pageSize?: number,
	sort?: string,
	presences?: string
) => {
	const filters: string[] = [];

	/**
	 * Le comparateur (@= ou ==) doit directement être inclu dans la variable
	 * @= --> CONTAINS
	 * == --> STRICTEMENT EGAL
	 *
	 * Cf. https://github.com/Biarity/Sieve#send-a-request
	 *
	 */

	if(presences) {
		filters.push(`${commissionMembrePresenceFilterKey}${presences}`);
	}

	try {
		const result = await commissionClient.getCommissionSigningSheetsPdf(id, filters.join(), sort, page, pageSize, disablePagination);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les pdf des feuilles de signatures de commission");
		throw "error";
	}
};

export const fetchCommissionTableauBord = async (dispatch: any, id: string) => {
	dispatch({ type: CommissionActionsEnum.GET_COMMISSION_TABLEAUBORD_REQUEST });
	try {
		let response = await commissionClient.getCommissionTableauBord(id);
		dispatch({
			type: CommissionActionsEnum.GET_COMMISSION_TABLEAUBORD_SUCCESS,
			payload: { commissionTableauBord: response }
		});
		return response;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les budgets du tableau de bord");
		
		dispatch({ type: CommissionActionsEnum.GET_COMMISSION_TABLEAUBORD_ERROR });
		throw "error";
	}
}


export const openCommission = async (dispatch: any, id: string, isDemarrer?:boolean) => {
	dispatch({ type: CommissionActionsEnum.OPEN_COMMISSION_REQUEST });
	try {
		const result = await commissionClient.openCommission(id);
		if (isDemarrer) {
			dispatch({ type: CommissionActionsEnum.CHANGE_COMMISSION_STATUT, payload: { idCommissionToChange: id } });
		}
		dispatch({ type: CommissionActionsEnum.OPEN_COMMISSION_SUCCESS });
		return result;
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.OPEN_COMMISSION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible d'ouvrir la commission");
		throw "error";
	}
}

export const openSessionCA = async (dispatch: any, id: string) => {
	dispatch({ type: CommissionActionsEnum.OPEN_COMMISSION_CA_REQUEST });
	try {
		const result = await commissionClient.openSessionCA(id);
		dispatch({ type: CommissionActionsEnum.OPEN_COMMISSION_CA_SUCCESS });
		return result;
	} catch(error) {
		dispatch({ type: CommissionActionsEnum.OPEN_COMMISSION_CA_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible d'ouvrir la session CA");
		throw "error";
	}
}

export const getCommissionHasParticipation = async(id: string) => {
	try {
        const result = await commissionClient.getCommissionHasParticipation(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer les membres de cette commission");
		throw error;
	}
};

export const getCommissionDossiersCountForCourriers = async(id: string) => {
	try {
        const result = await commissionClient.getCommissionDossiersCountForCourriers(id);
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		 else
			toast.error("Impossible de récupérer le nombre de cdossiers");
		throw error;
	}
};

export const getCommissionDossiersErrors = async(id: string) => {
	try {
		return await commissionClient.getCommissionDossiersGenerationErrors(id);
	} catch(error) {
		toast.error("Impossible de récupérer les erreurs de génération des dossiers");
		throw error;
	}
};

export const regenerateCommissionDocuments = async(id: string) => {
	try {
		const result = await commissionClient.regenerateCommissionDocuments(id);
		toast.success("La regénération des documents en erreur est en cours");
		return result;
	} catch(error) {
		toast.error("Impossible de regénérer les documents de la commission");
		throw error;
	}
};