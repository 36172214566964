import React, { ReactElement, useEffect } from 'react';
import { Link } from "@reach/router";
import { fetchDossierAdditionalInfo } from '../store/dossier-store/actions/dossierAdditionalInfoAction';
import { fetchStructureDetails } from '../store/structure-store/actions/structureDetailsActions';
import { useDispatch } from 'react-redux';
import { useDossierSelector, useGeneralSelector } from '../store/store-helpers';
import Disclaimer from '../components/basics/Disclaimer';
import DossiersBloques from '../components/structures/side-infos/DossiersBloques';
import DossierAccepte from '../components/structures/side-infos/DossierAccepte';
import DossiersNonSoldes from '../components/structures/side-infos/DossiersNonSoldes';
import Doublons from '../components/doublons/Doublons';
import { DossierDetailsDto, RelationDto, StructureDetailsDto } from '../services/generated/BackOffice-api';
import Relations from '../components/relations/Relations';
import useHasPermission from '../custom-hooks/useHasPermission';
import Logo from "../assets/logo/logo_spedidam_white_bg.png";
import { useState } from 'react';
import { cleanupDossierDetails } from '../store/dossier-store/actions/dossierDetailsActions';

interface DetailsContainerProps {
	children: ReactElement<any>;
	hasSoumissionStatus?: boolean;
	header: ReactElement;
	dossierId?: string;
	structureId?: string;
	relationsDossier?: RelationDto[];
	relationsStructure?: RelationDto[];
	validatorRelation?: any;
	isFromDossier?: boolean;
}

const DetailsContainer: React.FC<DetailsContainerProps> = ({
	header,
	hasSoumissionStatus = false,
	children,
	dossierId,
	structureId,
	relationsDossier,
	relationsStructure,
	validatorRelation,
	isFromDossier
}) => {
	const dispatch = useDispatch();
	const {
		dossierAdditionalInfo: {
			autreDossiersBloques,
			autreDossiersNonSoldes,
			dernierDossierAccepte
		} = {}
	} = useDossierSelector();

	/** Permissions */
	const usePermGetDossierAdditionalInfo = useHasPermission("GetDossierAdditionalInfo");
	const usePermGetDossierRelations = useHasPermission("GetDossierRelations");
	const usePermGetStructureRelations = useHasPermission("GetStructureRelations");

	useEffect(() => {
	
		// Cleanup function when the component unmounts
		return () => {
		  // Unload or clean up data when the component unmounts
		  // For example, cancel any pending requests or clear data
		  dispatch(cleanupDossierDetails())
		};
	  }, []);
	  
	useEffect(() => {
		if (structureId)
			fetchStructureDetails(dispatch, structureId);
	}, []);

	useEffect(() => {
		(async () => {
			if (isFromDossier && dossierId && usePermGetDossierAdditionalInfo)
				await fetchDossierAdditionalInfo(dispatch, dossierId)
		})();
	}, [usePermGetDossierAdditionalInfo]);

	const [show, toggleShow] = React.useState(true);

	return (
		<div className="details">
			<div className="details__main">
				<div className="details-main__header">
					<div>
						<div className="details-header__titles">
							{header}
						</div>
							<Link to="/">
								<img className="details-header__logo" src={Logo} alt="Spedidam" />
							</Link>
					</div>
					<div>
						{
							hasSoumissionStatus &&
							<div className="details-main__status">
								Soumis
							</div>
						}
					</div>
				</div>
				<div className="details-main__content">
					{React.cloneElement(children)}
				</div>
			</div>

		<div className='details__side_container'>
			<button
				onClick={() => toggleShow(!show)}
			>
					{show ? '>>' : '<<'}
			</button>
				{show && <div className="details__side">
					{(usePermGetDossierAdditionalInfo && autreDossiersBloques && autreDossiersBloques.length > 0) &&
						<Disclaimer>
							<div className="disclaimer__intitule">Le dossier fait l’objet d’un blocage juridique.</div>
							<div className="disclaimer__row">Numéro de dossier : <Link to={`/Dossier/${autreDossiersBloques[0]?.id}`}>{autreDossiersBloques[0]?.numero}</Link></div>
							<div className="disclaimer__row">Nom du dossier : {autreDossiersBloques[0]?.nom}</div>
							<div className="disclaimer__row">Type de dossier : {autreDossiersBloques[0]?.categorieNom}</div>
						</Disclaimer>
					}

					{/** TODO: Brancher ça !!!
					<div className="detailsSide__header">
						<i className="far fa-exclamation-triangle"></i>
						<p>Cette structure a bénéficié d’une aide dans cette catégorie.</p>
					</div>
				**/}

					{isFromDossier && <>
						<Doublons />
						{/* <span className="separator"></span> */}
					</>}

					{usePermGetDossierRelations && relationsDossier &&
						<Relations
							titleType="dossier"
							relations={relationsDossier}
							dossierId={dossierId}
							validator={validatorRelation}
							dispatch={dispatch}
							isFromDossier={isFromDossier}
						/>
					}
					<span className={"separator"}></span>

					{usePermGetStructureRelations && relationsStructure &&
						<Relations
							titleType="structure"
							relations={relationsStructure}
							structureId={structureId}
							dossierId={isFromDossier && dossierId || undefined}
							validator={validatorRelation}
							dispatch={dispatch}
							isFromDossier={isFromDossier}
						/>
					}

					{usePermGetDossierAdditionalInfo && (
						<>
							{(autreDossiersNonSoldes && autreDossiersNonSoldes.length > 0) && (
								<>
									<span className="separator"></span>
									<DossiersNonSoldes />
								</>
							)}

							{(autreDossiersBloques && autreDossiersBloques.length > 0) && (
								<>
									<span className="separator"></span>
									<DossiersBloques />
								</>
							)}

							{dernierDossierAccepte && (
								<>
									<span className="separator"></span>
									<DossierAccepte />
								</>
							)}
						</>
					)}
				</div>}
			</div>

</div>
	)
}

export default DetailsContainer;

