import { CategorieDossierDto } from '../../../services/generated/BackOffice-api';

export interface CategorieDossierState {
	categoriesIsLoading: boolean;
	categories?: CategorieDossierDto[];

}

export const initialCategorieDossier: CategorieDossierState = {
	categoriesIsLoading: false,
	categories: []
};