
import { DocumentDto, DocumentUploadInfoDto, StatutStructure, StructureClient, StructureInstructionDto } from '../../../services/generated/BackOffice-api';
import { AdelStructureClient } from '../../../clients/AdelStructureClient';
import { StructureActionsEnum } from './structureAction';
import { toast } from 'react-toastify';
import { Dictionary } from "adel-shared/dist/models/Dictionary";
import { Dispatch } from 'redux';
import { axiosInstance } from '../../../custom-hooks/useAxios';

/** Actions */
export const requestStructureInstruction = () => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_INSTRUCTION_REQUEST,
		payload: {}
	}
}

export const getStructureInstruction = (structureInstruction: StructureInstructionDto) => {
	return {
		type: StructureActionsEnum.GET_STRUCTURE_INSTRUCTION_SUCCESS,
		payload: { structureInstruction }
	}
}

export const requestPostStructureInstruction = () => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_INSTRUCTION_REQUEST,
		payload: {}
	}
}

export const errorPostStructureInstruction = () => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_INSTRUCTION_ERROR,
		payload: {}
	}
}

export const postStructureInstruction = (structureInstruction: StructureInstructionDto, structureInstructionFiles?: File[]) => {
	return {
		type: StructureActionsEnum.POST_STRUCTURE_INSTRUCTION_SUCCESS,
		payload: {
			structureInstruction,
			structureInstructionFiles
		}
	}
}
export const resetInstructionFiles= () => {
	return {
		type: StructureActionsEnum.RESET_FILES,
		payload: {}
	}
}
/** Task */
const structureClient = new StructureClient('', axiosInstance);
const adelDossierClient = new AdelStructureClient('', axiosInstance);

export const fetchStructureInstruction = (dispatch: Dispatch, id: string) => {
	return () => {
		dispatch(requestStructureInstruction());
		return structureClient.getStructureInstruction(id)
			.then(response => dispatch(getStructureInstruction(response)))
			.catch(error => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer l'instruction de structure");
		})
	}
}

export const sendStructureInstruction = async(
	dispatch: Dispatch,
	structureId: string,
	statut?: StatutStructure,
	categoriesDossierSelectionnees?: string[],
	documents?: DocumentUploadInfoDto[],
	files?: Dictionary<File>,
	originalFiles?: File[]
) => {
	dispatch(requestPostStructureInstruction());
	try {
		await adelDossierClient.updateStructureInstruction(structureId, statut, categoriesDossierSelectionnees, documents, files);
		dispatch(postStructureInstruction(
			{
				statut,
				categoriesDossierSelectionnees,
				documents,
			},
			originalFiles
		));
	} catch(error) {
		dispatch(errorPostStructureInstruction());
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour l'instruction de structure");
		throw error;
	}
}
