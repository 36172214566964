import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { BudgetByCategorieDto, CommissionTableauBordDto, StatutCommission } from '../../../services/generated/BackOffice-api';
import { fetchCommission, fetchCommissionTableauBord } from '../../../store/commission-store/actions/commissionActions';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { useCommissionSelector } from '../../../store/store-helpers';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import ModalCloturerCommission from '../modal/ModalCloturerCommission';
import ModalValidationReaffectations from './ModalValidationReaffectations';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';


interface DetailsCommissionTDBProps {
	id: string;
}

const DetailsCommissionTDB: React.FC<DetailsCommissionTDBProps> = ({id}) => {
	const dispatch = useDispatch();
    const [openClotureCommission, setOpenClotureCommission] = useState<boolean>(false);
	const {
		commission = {},
		commissionDetailsDossiers: commissionDossiers = [],
		commissionTableauBord = {},
		isTableauBordLoading
	} = useCommissionSelector();

	const [
		isOpenValidationReaffectations,
		openValidationReaffectations,
		closeValidationReaffectations
	] = useBooleanState(false);


	useEffect(() => {
		(async () => {
			try {
				await fetchCommission(dispatch, id);
				const comm = await fetchCommissionTableauBord(dispatch, id);
			} catch(error) {
				console.error(error);
			}
		})();
	}, [fetchCommission, fetchCommissionTableauBord]);

	/** Permissions */
	const usePermGetCommissionTableauBord = useHasPermission("GetCommissionTableauBord");
	const usePermCreateCloseCommission = useHasPermission("CloseCommission");
	const usePermGetBordereauReaffectationAmendable = useHasPermission("GetBordereauReaffectationAmendable");

    return (
        <div className="details__side tdb">
			<h3>{commission.nom}</h3>
			{usePermGetCommissionTableauBord && (
				<>
					{isTableauBordLoading ? (
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						/>
					) : (
						<div className="details__sideContent">
							<div className="infos">
								<div>Budget total : { commissionTableauBord.budgetTotal ? formatNumber(commissionTableauBord.budgetTotal) : '-' } €</div>
								<div>Budget restant : { commissionTableauBord.budgetRestant ? formatNumber(commissionTableauBord.budgetRestant) : '-'} €</div>
							</div>
							{commissionTableauBord.budgetByCategories?.map((item, i) => (
								<div className="chart" key={i}>
									<p>{formatNumber(item.suiviAideParPourcentageAttribution || 0)} % <span className="chart__name">{item.categorieName}</span></p>
									<div className="chart__item">
											<div 
												className={clsx("chart__bar", 
													{ "chart__bar--valid": ((item.suiviAideParPourcentageAttribution || 0) <= (item.pourcentageAttribution || 0)) }, 
													{ "chart__bar--invalid": ((item.suiviAideParPourcentageAttribution || 0) > (item.pourcentageAttribution || 0)) }
												)}
												style={{width: item.suiviAideParPourcentageAttribution + "%"}}
											></div>
											<div className="chart__indicator" style={{left: item.pourcentageAttribution + "%"}}></div>
										</div>
								</div>
							))}
						</div>
					)}

					{(commission.statut === StatutCommission.EnCours || commission.statut === StatutCommission.CaEnCours) && (
						<div className="details__sideContent">
							<FormButton
								type="button"
								value="Valider les réaffectations"
								onClick={openValidationReaffectations}
							/>
						</div>
					)}

					{(
						usePermCreateCloseCommission
						&& (commission.statut === StatutCommission.EnCours || commission.statut === StatutCommission.CaEnCours)
						&& commissionDossiers.length > 0
						&& !commissionDossiers.find(d => !d.valideEnCommission)
					 ) && (
						<div className="details__sideContent">
							<FormButton 
								type="button"
								value="Clôturer l'étude des dossiers"
								onClick={() => setOpenClotureCommission(true)}
							/>
						</div>
					)}
				</>
			)}
			{usePermCreateCloseCommission &&
				<ModalCloturerCommission
					id={id}
					setOpenModal={setOpenClotureCommission}
					openModal={openClotureCommission}
				/>
			}
			{usePermGetBordereauReaffectationAmendable &&
			isOpenValidationReaffectations && 
			(commission?.statut === StatutCommission.EnCours || commission?.statut === StatutCommission.CaEnCours) && (
                <ModalValidationReaffectations
                    isOpen={isOpenValidationReaffectations}
                    onClose={closeValidationReaffectations}
                />
            )}
        </div>
    );
};

export default DetailsCommissionTDB;