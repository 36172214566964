import React, { FunctionComponent, useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import PagesContainer from '../../containers/PagesContainer';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { useSortBy, useTable } from 'react-table';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import ModalAddRemboursement from './ModalAddRemboursement';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import useValidation from '../../custom-hooks/useValidation';
import { getMouvementsDemandeRemboursement } from '../../store/bordereau-store/actions/bordereauActions';
import { useBordereauSelector } from '../../store/store-helpers';
import { formatNumber, formaterDate } from 'adel-shared/dist/utils/functions';
import NoItemsTable from '../basics/NoItemsTable';
interface ListeDemandesRemboursementProps extends RouteComponentProps {
}

const ListeDemandesRemboursement: FunctionComponent<ListeDemandesRemboursementProps> = () => {
    const dispatch = useDispatch();
	const {demandesRemboursement} = useBordereauSelector();

	const { getRootValidator } = useValidation();
	const validator = getRootValidator("CreateRemboursementDto");
	const [data, setData] = useState<any[]>([]);
	const [idMouvement, setIdMouvement] = useState<string>();
	const [isOpenModal, openModal, closeModal] = useBooleanState(false);

	useEffect(() => {
		getMouvementsDemandeRemboursement(dispatch);
	}, []);

    /** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Code structure", accessor: "code", disableSortBy: true },
					{ Header: "Nom structure", accessor: "nom", disableSortBy: true },
					{ Header: "N° dossier", accessor: "numeroDossier", disableSortBy: true,
						Cell: (value: any) => (
							<span><a href={`/Dossier/${value.cell.row.values.dossierId}`} target="_blank">{value.value}</a></span>
						)
					},
					{ Header: "Nom dossier", accessor: "nomDossier", disableSortBy: true },
					{ Header: "Type dossier", accessor: "type", disableSortBy: true },
					{ Header: "Montant à rembourser", accessor: "montant", disableSortBy: true, className:'table__cell--montants' },
					{ Header: "Somme déjà remboursée", accessor: "somme", disableSortBy: true, className:'table__cell--montants' },
					{ Header: "Reste à rembourser", accessor: "reste", disableSortBy: true, className:'table__cell--montants' },
					{ Header: "Date", accessor: "date", disableSortBy: true },
					{ Header: "Nom chargé d'instruction", accessor: "nomChargeInstruction", disableSortBy: true },
					{ Header: "", accessor: "actions", disableSortBy: true,
						Cell: (value: any) => (
							<span className="commission__tableButtons">
								<FormButton
									type="button"
									value="Ajouter"
									onClick={() => handleAdd(value.cell.row.values.id)}
								/>
							</span>
						)
					},
					{ Header: "Id", accessor: "id" },
					{ Header: "dossierId", accessor: "dossierId" }
				]
			}
		];
	}, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["id", "dossierId"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

    useEffect(() => {
		if(demandesRemboursement) {
			setData(demandesRemboursement.map(d => (
				{
					id: d.id,
					dossierId: d.dossierId,
					code: d.structureCode,
					nom: d.structureNom,
					numeroDossier: d.dossierNumero,
					nomDossier: d.dossierNom,
					type: d.categorie?.nom,
					montant: `${d.montant ? formatNumber(d.montant) : '-'} €`,
					reste: `${d.reste ? formatNumber(d.reste) : '-'} €`,
					date: d.dateDemande ? formaterDate(d.dateDemande) : '-',
					nomChargeInstruction: `${d.chargeInstruction?.nom} ${d.chargeInstruction?.prenom}`,
					somme: `${d.sommeDejaRemboursee ? formatNumber(d.sommeDejaRemboursee) : '-'} €`
				}
			)));
		}
    }, [demandesRemboursement]);


	// Ajout de remboursement
	const handleAdd = (id:string) => {
		setIdMouvement(id);
		openModal();
	}

    return (
		<PagesContainer title="Comptabilité - Liste des demandes de remboursement" pageClass="liste-remboursement">
			<>
				{data.length ? (
					<div className="tablePage">
						<div className="tableStyle__header"></div>
						<div className="remoubrsement__table">
							<div className="remoubrsement__tableWrapper">
								<Table
									data={data}
									getTableProps={getTableProps}
									getTableBodyProps={getTableBodyProps}
									headerGroups={headerGroups}
									rows={rows}
									prepareRow={prepareRow}
									styles={styleTable.page}
								/>
							</div>
						</div>
					</div>
				) : (
					<NoItemsTable />
				)}
				{isOpenModal &&
					<ModalAddRemboursement
						idMouvement={idMouvement}
						setIdMouvement={setIdMouvement}
						isOpen={isOpenModal}
						closeModal={closeModal}
						validator={validator}
					/>
				}
			</>
    	</PagesContainer>
	)
};

export default ListeDemandesRemboursement;