import { GenerationSpedidamDto } from '../../../services/generated/BackOffice-api';

export interface GenerationSpedidamState {
	generationIsLoading: boolean;
	generations?: GenerationSpedidamDto[];
}

export const initialGenerationDossier: GenerationSpedidamState = {
	generationIsLoading: false,
	generations: [],
};