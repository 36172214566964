import React from 'react';
import Input from 'adel-shared/dist/components/basics/Input';
import { DonneesBancairesDto } from '../../../../services/generated/BackOffice-api';
import { FormContextValues } from 'react-hook-form';
import useValidation from '../../../../custom-hooks/useValidation';
import { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import SingleDocumentValidationUpdate from '../../../document-validation/SingleDocumentValidationUpdate';
import { DocumentWithValidationDto } from 'adel-shared/dist/models/generated/BackOffice-api';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
interface VersementInfosBancairesProps {
    form: FormContextValues;
    donneesBancaires?: DonneesBancairesDto;
	setRibPJ: (value:DocumentWithFile|undefined) => void;
	ribPJ?: DocumentWithFile;
	ribValidation: DocumentWithValidationDto;
	setRibValidation: (value:DocumentWithValidationDto) => void
}

const VersementInfosBancaires: React.FunctionComponent<VersementInfosBancairesProps> = ({
    form,
    donneesBancaires,
	setRibPJ,
	ribPJ,
	ribValidation,
	setRibValidation
}) => {
    /** Données bancaires (à remettre en cas d'activation du modification du RIB) */
    // const { getRootValidator } = useValidation();
    // const donneesBancairesValidator = getRootValidator("CreateOrUpdateDonneesBancairesDto");
  
    return (
        <section className="detailsTab__section">
            <h3 className="detailsTab__title">Coordonnées bancaires</h3>
            <div className="detailsTab__row">
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        {/* Données bancaires (à remettre en cas d'activation du modification du RIB)
                         <Input
                            label="IBAN"
                            reference={donneesBancairesValidator && form.register(donneesBancairesValidator['Iban'])}
                            name="iban"
                            type="text"
                            errors={form.errors}
                            defaultValue={donneesBancaires?.iban}
                        /> */}
                        <InputReadOnly
                            label="IBAN"
                            content={donneesBancaires?.iban || '-'}
                        />
                    </div>
                </div>
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        {/* <Input
                            label="Domiciliation"
                            reference={donneesBancairesValidator && form.register(donneesBancairesValidator['Banque'])}
                            name="domiciliation"
                            type="text"
                            errors={form.errors}
                            defaultValue={donneesBancaires?.banque}
                        /> */}
                        <InputReadOnly
                            label="Domiciliation"
                            content={donneesBancaires?.banque || "-"}
                        />
                    </div>
                </div>
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        {/* <Input
                            label="BIC"
                            reference={donneesBancairesValidator && form.register(donneesBancairesValidator['Bic'])}
                            name="bic"
                            type="text"
                            errors={form.errors}
                            defaultValue={donneesBancaires?.bic}
                        /> */}
                        <InputReadOnly
                            label="BIC"
                            content={donneesBancaires?.bic || "-"}
                        />
                    </div>
                </div>
            </div>
			<div className="detailsTab__content">
				<SingleDocumentValidationUpdate
					documentWithValidation={ribValidation}
					setDocumentWithValidation={setRibValidation}
					pj={ribPJ}
					setPj={setRibPJ}
				/>
			</div>
        </section>
    )
};

export default VersementInfosBancaires;