import React from 'react'

interface StepsProps {
    number_step: number
	text: string
    currentStatus? : boolean
    color: string
}

const SyntheseStep = ({number_step, text, currentStatus,color}: StepsProps ) => {
    return (
        currentStatus ?
            <div className='step__selected'>
                    <svg className='step_icon' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="black">
                    <circle cx="16" cy="16" r="14" fill="white" stroke={color} strokeWidth="4" />
                        <text x="50%" y="50%" strokeWidth="1px" textAnchor="middle" alignmentBaseline="middle">{number_step}</text>
                    </svg>
                <span className='step_text'>
                    {text}
                </span>
            </div>
        :
            <div className='step'>
                    <svg className='step_icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black">
                        <circle cx="12" cy="12" r="11" fill="white" stroke="#D8DED5" strokeWidth="2" />
                        <text x="50%" y="50%" strokeWidth="1px" textAnchor="middle" alignmentBaseline="middle">{number_step}</text>
                    </svg>
                <span className='step_text'>
                    {text}
                </span>
            </div>
    )
}

export default SyntheseStep