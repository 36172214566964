import React, { useEffect, useMemo, useState } from 'react';
import { navigate, Router } from "@reach/router"
// import './sass/App.scss';
import "./components/fontawesome/Fontawesome";
import Loader from 'react-loader-spinner';
import { login } from './services/Auth/AuthProvider';
import Modal from 'react-modal';
import { useUserDispatcher, useUserSelector } from './store/store-helpers';
import { fetchUserPermissions, UserActionsEnum } from './store/user-store/actions/userAction';
import Toaster from './containers/Toaster';
import { useNavigationControl } from './custom-hooks/useNavigationControl';

import StructureDetails from './components/structures/StructureDetails';
import DossierDetails from './components/dossiers/DossierDetails';
import Menu from './components/menu/Menu';
import StructuresTable from './components/structures/StructuresTable';
import DossiersListe from './components/dossiers/DossiersListe';
import CategorieFormeJuridique from './components/categorie-forme-juridique/CategorieFormeJuridique';
import Commissions from './components/commissions/Commissions';
import EditCommission from './components/commissions/edit-commission/EditCommission';
import ListeDossiersCommission from './components/commissions/liste-dossiers-commission/ListeDossiersCommission';
import ListeDossiersSessionCA from './components/commissions/liste-dossiers-ca/ListeDossiersSessionCA';
import ListeSessionCA from './components/commissions/liste-dossiers-ca/ListeSessionCA';
import GestionCriteresRemunerations from './components/gestion-criteres-remunerations/GestionCriteresRemunerations';
import GestionMembres from './components/gestion-membres/GestionMembres';
import CommissionDetails from './components/commissions/dossier-details/CommissionDetails';
import GestionUtilisateurs from './components/gestion-utilisateurs/GestionUtilisateurs';
import Bordereaux from './components/gestion-bordereaux/Bordereaux';
import Bordereau from './components/gestion-bordereaux/Bordereau';
import ComptesBancairesSpedidam from './components/comptes-bancaires-sped/ComptesBancairesSpedidam';
import ListeDemandesRemboursement from './components/liste-demandes-remboursement/ListeDemandesRemboursement';
import Lettres from './components/lettres/Lettres';
import Unauthorized from './components/unauthorized/Unauthorized';
import Balance from './components/Balance';
import CommissionExports from './components/commissions/exports/CommissionExports';
import StructureDossiersListe from './components/structures/StructureDossiersListe';
import GestionEntite from './components/gestion-entites/GestionEntite';
import ModalExportArtistPageVitrine from './components/page-vitrine/ModalExportArtistPageVitrine';
import { toast } from 'react-toastify';
import { ArtisteClient } from './services/generated/BackOffice-api';
import moment from 'moment';
import { downloadFileFromDataURI } from '../../ClientApp/src/utils/functions';


const App = () => {
	const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false);

	const [environment, setEnvironment] = useState<string>('');

	const isLocalhost = Boolean(
		window.location.hostname === 'localhost' ||
		  // [::1] is the IPv6 localhost address.
		  window.location.hostname === '[::1]' ||
		  // 127.0.0.0/8 are considered localhost for IPv4.
		  window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		  )
	  );

	useEffect(() => {
		const url = window.location.href
		if (isLocalhost){
			setEnvironment('.dev')
		}else{
			const link = url.substring(0,url.indexOf('.net'))
			console.log(link)
			if (link.indexOf('dev') != -1){
				setEnvironment('.dev')	
			}else if (link.indexOf('int') != -1){
				setEnvironment('.int')
			}else if (link.indexOf('prod') != -1){
				setEnvironment('')
			}
		}
	},[]);
	
	// console.log("environment:" ,environment)

	useEffect(() => {
		import('./sass/App'+environment+'.scss');
	},[environment])
	const dispatchUserAction = useUserDispatcher();
	const user = useUserSelector();
	useNavigationControl(); // DO NOT REMOVE THESE LINES AS IT IS NECESSARY TO INITIALIZE THE CUSTOM HOOK;

	useEffect(() => {
		login(() => {
			dispatchUserAction({ type: UserActionsEnum.CONNECT_USER, payload: { isConnected: true } })
			fetchUserPermissions(dispatchUserAction);
		}, (value) => {
			if(value.status === 401)
				setIsUnauthorized(true)
		});
	
		Modal.setAppElement("#app"); // Lie la modal au container le plus haut de l'app
	}, [])

	const [modalDeletePageVitrineIsOpen, setModalDeletePageVitrineIsOpen] = useState<boolean>(true)
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleArtistExport = async(data: any) => {
		try {
			setIsLoading(true);
			const client = new ArtisteClient();
			const dateDebut = moment(data?.dateDebutExport).format();
			const dateFin = moment(data?.dateFinExport).format();
			const result = await client.getExportArtistAsExcel(dateDebut,dateFin);
			downloadFileFromDataURI(result);
			setIsLoading(false);
			toast.success('Fichier exporté avec succès.');
			setTimeout(() => {
					navigate("/");
			},1000);
		} catch {
			toast.error('Un erreur est survenue pendant le téléchargement de l\'export des artistes.');
		}
	};
	return (
		<div id="app">
			{user.isConnected
				? <>
					<Menu />

					<Router className="app__container">
						<DossiersListe path="/*" />
						<StructureDetails path="/Structure/:structureId" />
						<StructuresTable path='/Gestion/Structures' />
						<StructureDossiersListe path='/Gestion/Structure/Dossiers/:structureId' />
						<ListeDossiersCommission path="/Commission/Dossiers/:commissionId" />
						<ListeSessionCA path="/Commission/SessionCA" />
						<ListeDossiersSessionCA path="/Commission/DossiersSessionCA/:commissionId" />
						<DossierDetails path="/Dossier/:dossierId" />
						<CommissionDetails path="/Commission/:commissionId" />
						<CommissionExports path="/Commission/:commissionId/Exports" />
						<Commissions path='/Commission/ListeCommissions' />
						<EditCommission path='/Commission/Edit/:commissionId/:locationTab' />
						<GestionMembres path='/Commission/ListeMembresCommission' />
						<CategorieFormeJuridique path='/Administration/GestionDocumentsAdministratifs' />
						<GestionCriteresRemunerations path='/Administration/GestionCriteresRemunerations' />
						<GestionUtilisateurs path='/Administration/GestionUtilisateurs' />
						<Lettres path='/Administration/GestionLettres' />
						<Bordereaux path='/Comptabilite/Bordereaux/:bordereauType' />
						<Bordereau path='/Comptabilite/Bordereau/:bordereauType/:bordereauId' />
                        <GestionEntite path='/Administration/GestionEntite' />
						<Balance path='/Comptabilite/Balance' />
						<ComptesBancairesSpedidam path='/Comptabilite/ComptesBancairesSpedidam' />
						<ListeDemandesRemboursement path="/Comptabilite/ListeDemandesRemboursement" />
						<ModalExportArtistPageVitrine path='/Administration/Export' 
							isOpen={true}
							isLoading={isLoading}
							onCancel={() => {
								setModalDeletePageVitrineIsOpen(false); 
								navigate("/");
							}}
							onValidate={(data) => handleArtistExport(data)}
							/>
					</Router>
				</>
				: isUnauthorized
					? <Unauthorized />
					: <div className="loaderPage">
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
					</div>
			}
			<Toaster />
		</div>
	);
}

export default App;