import axios from 'axios';
import { useEffect } from 'react';

export const axiosInstance = axios.create();

export const useAxios = () => {
    useEffect(() => {
        if (axiosInstance && ((axiosInstance.interceptors.response as any).handlers as any[]).length < 1) {
            
        }
	}, []) // To be called only once

	return axiosInstance;
}
