import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useForm, Controller, ValidationOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { Dictionary } from 'lodash';
import { useDispatch } from 'react-redux';
import { createRemboursement } from '../../store/bordereau-store/actions/bordereauActions';

interface ModalAddRemboursementProps {
	idMouvement?:string;
	setIdMouvement:(value:string | undefined) => void;
	isOpen: boolean;
	closeModal: () => void;
	validator: Dictionary<ValidationOptions> | null;
}

const ModalAddRemboursement: React.FunctionComponent<ModalAddRemboursementProps> = ({
	idMouvement,
	setIdMouvement,
	isOpen,
	closeModal,
	validator
}) => {
	const form = useForm();
	const dispatch = useDispatch();

	const submit = async () => {
		let result = await form.triggerValidation();
		if(result && idMouvement) {
			await createRemboursement(dispatch, idMouvement, {montant: form.getValues("montant")});
			setIdMouvement(undefined);
			closeModal();
		}
	}

	return (
	<Modal isOpen={isOpen} style={modalCustomStyles} className="normal__modal fluxModal" >
		<div className="modal__header">
			<h3>Ajouter un remboursement</h3>
		</div>
		<div className="modal__content">
			<Input
				name="montant"
				type="number"
				label="Montant remboursé"
				errors={form.errors}
				reference={validator && form.register(validator["Montant"])}
			/>
		</div>
		<div className="modal__footer">
			<FormButton
				type="button"
				value="Annuler"
				onClick={() => { closeModal() }}
			/>
			<FormButton
				type="submit"
				value="Ajouter"
				onClick={submit}
			/>
		</div>
	</Modal>);
}

export default ModalAddRemboursement;
