import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTypeDeDocumentSelector, useTypeDeDocumentDispatcher, useCategorieFormeJuridiqueDispatcher } from '../../../store/store-helpers';
import { fetchTypeDeDocuments, deleteTypeDeDocument, modifDocument } from '../../../store/type-de-document-store/actions/typeDeDocumentAction';
import { TypeDeDocumentWithCategoriesDto, TypeDeDocumentDto, CategorieFormeJuridiqueDto } from '../../../services/generated/BackOffice-api';
import Checkbox from '../../basics/Checkbox';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import EditDocument from './EditDocument';
import { updateCategoriesFormeJuridiqueTypesDeDocuments } from '../../../store/categorie-forme-juridique-store/actions/categoryFormeJuridiqueAction';
import { Dictionary } from 'adel-shared/dist/models';
import { getIntegerIdFromSecureId } from 'adel-shared/dist/utils/getIntegerIdFromSecureId';
import { toast } from 'react-toastify';
import ModalDeleteDocument from './ModalDeleteDocument';
import ModalDeleteChoix from './ModalDeleteChoix';
import { useNavigationControl } from '../../../custom-hooks/useNavigationControl';

// https://stackoverflow.com/a/2328902
const sortDocument = (documentA: TypeDeDocumentWithCategoriesDto, documentB: TypeDeDocumentWithCategoriesDto): number => {
	let va = documentA.id ? getIntegerIdFromSecureId(documentA.id) : "";
	let vb = documentB.id ? getIntegerIdFromSecureId(documentB.id) : "";

	let result = va > vb;

	return result ? 1 : (va === vb ? 0 : -1);
}

interface DocumentAffectationTabProps extends RouteComponentProps {
	modalAddDocumentIsOpen: boolean,
	setModalAddDocumentIsOpen: Dispatch<SetStateAction<boolean>>,
	preventExitWithoutSaving: () => void,
	categoriesList: CategorieFormeJuridiqueDto[]
}

const DocumentAffectationTab: React.SFC<DocumentAffectationTabProps> = ({
	modalAddDocumentIsOpen,
	setModalAddDocumentIsOpen,
	preventExitWithoutSaving,
	categoriesList
}) => {
	const { allowNavigation } = useNavigationControl();

	const documentSelector = useTypeDeDocumentSelector();
	const documentDispatch = useTypeDeDocumentDispatcher();
	const categoriesDispatch = useCategorieFormeJuridiqueDispatcher();

	const [documents, setDocuments] = useState<TypeDeDocumentWithCategoriesDto[]>([]);
	const [selectedDocument, setSelectedDocument] = useState<TypeDeDocumentWithCategoriesDto>();
	const [selectedParentDocument, setSelectedParentDocument] = useState<TypeDeDocumentWithCategoriesDto>();

	// Add Document modal is manipulated from the bottom in the parent component
	const [modalEditDocumentIsOpen, setModalEditDocumentIsOpen] = useState<boolean>(false);
	const [modalDeleteDocumentIsOpen, setModalDeleteDocumentIsOpen] = useState<boolean>(false);
	const [modalAddChoixIsOpen, setModalAddChoixIsOpen] = useState<boolean>(false);
	const [modalEditChoixIsOpen, setModalEditChoixIsOpen] = useState<boolean>(false);
	const [modalDeleteChoixIsOpen, setModalDeleteChoixIsOpen] = useState<boolean>(false);

	useEffect(() => {
		fetchTypeDeDocuments(documentDispatch)();
	}, []);

	useEffect(() => {
		if (documentSelector.documents) {
			setDocuments(documentSelector.documents.sort(sortDocument)); // /!\ Same sort should always be applied on document retrieval and category/documenttype association to make sure list order doesn't change
		}
	}, [documentSelector.documents]);

	// VFA : Hum... Pour supprimer le document on pourrait juste utiliser la fonction `filter` ?
	// De plus à minima la propriété devrait s'appeler `deletedDocumentId` ?
	useEffect(() => {
		if (documentSelector.id) {
			//on supprime le nouveau document de la liste des documents pour un aperçu direct de la suppression
			let index = -1;
			let i = 0;
			let listDocuments = [...documents]
			listDocuments.forEach((element) => {
				if (element.id === documentSelector.id) {
					index = i;
				}
				i++;
			})
			if (index !== -1) {
				listDocuments.splice(index, 1);
				setDocuments(listDocuments);
			}
		}
	}, [documentSelector.id])

	// VFA Pareil sceptique de l'approche. Quid de 2 propriétés `addedDocument` et `editedDocument` ?
	// Je trouve le code vraiment difficile à lire (inutilement complexe ?)
	useEffect(() => {
		if (documentSelector.document) {
			//besoin de verifier si l'actualisation provient d'un ajout ou d'une modification d'un document
			//on ajoute le nouveau document à la liste des documentts pour un affichage instantané
			//on supprime le nouveau document de la liste des documents pour un aperçu direct de la suppression
			let index = -1;
			let positionElem = 0;
			let listDocuments = [...documents]
			listDocuments.forEach((element, i) => {
				//si l'element est deja présent alors c'est une edition d'un document
				if (element.id === documentSelector.document?.id) {
					index = 1;
					positionElem = i;
				}
			})
			//si index === -1 alors c'est un ajout
			if (index === -1) {
				listDocuments.push(documentSelector.document)
			} else {
				listDocuments[positionElem] = {
					...documentSelector.document,
					categories: listDocuments[positionElem].categories
				}
			}
			setDocuments(listDocuments);
		}
	}, [documentSelector.document])

	const openEditDocumentModal = (document: TypeDeDocumentWithCategoriesDto) => {
		setSelectedDocument(document);
		setModalEditDocumentIsOpen(true);
	}

	const openDeleteDocumentModal = (document: TypeDeDocumentWithCategoriesDto) => {
		//on sauvegarde l'id pour y acceder via la modal de delete
		setSelectedDocument(document);

		//on ouvre la modal
		setModalDeleteDocumentIsOpen(true);
	}

	const opendAddChoixModal = (parentDocument: TypeDeDocumentWithCategoriesDto) => {
		setSelectedDocument(undefined);
		setSelectedParentDocument(parentDocument);
		setModalAddChoixIsOpen(true);
	}

	const openEditChoixModal = (parentDocument: TypeDeDocumentWithCategoriesDto, choix: TypeDeDocumentWithCategoriesDto) => {
		setSelectedParentDocument(parentDocument);
		setSelectedDocument(choix);
		setModalEditChoixIsOpen(true);
	}

	const openDeleteChoixModal = (parentDocument: TypeDeDocumentWithCategoriesDto, choix: TypeDeDocumentWithCategoriesDto) => {
		setSelectedParentDocument(parentDocument);
		setSelectedDocument(choix);
		setModalDeleteChoixIsOpen(true);
	}

	const handleDeleteTypeDeDocument = () => {
		deleteTypeDeDocument(documentDispatch, selectedDocument?.id as string)()

		if (!documentSelector.documentDeleteIsLoading) { // VFA : Pourquoi cete vérification qui n'est pas censée être possible ?
			setModalDeleteDocumentIsOpen(false);
		}
	}

	const handleDeleteChoix = () => {
		// Update parent document and remove current choix
		if (!selectedParentDocument) throw "Tentative d'ajout de suppression d'un choix, mais aucun Type de Document parent n'a été précisé. Please contact your dev team.";
		if (!selectedParentDocument.choix) throw "Tentative de suppression d'un choix, mais le Type de Document parent ne possède aucun choix. Please contact your dev team.";
		let choixExceptSelectedChoix = selectedParentDocument?.choix?.filter(choix => choix.id !== selectedDocument?.id)
			.map(choix => ({ id: choix.id } as TypeDeDocumentDto));
		selectedParentDocument.choix = choixExceptSelectedChoix;
		modifDocument(documentDispatch, selectedParentDocument.id as string, selectedParentDocument)();

		// Delete TypeDeDocument du choix
		deleteTypeDeDocument(documentDispatch, selectedDocument?.id as string)()
		setModalDeleteChoixIsOpen(false);
	}

	const updateCategoriesDuDocument = (documentId: string | undefined, categoryId: string | undefined, shouldAdd: boolean): boolean => {

		if (!documentId || !categoryId) throw "no documentId or categoryId specified when trying to update categories of a document";

		// Find modified document and change its category (add or remove)

		let { categories, ...selectedDocumentWithoutCategories } = documents.filter(doc => doc.id === documentId)[0];

		if (documentIsSupposedToHaveChoixDefinedButDoesnt(selectedDocumentWithoutCategories)) {
			toast.error("Un type de document avec choix doit comporter au moins un choix avant d'être affecté à une catégorie");
			return false;
		}

		let documentsWithoutSelectedDocument = documents.filter(doc => doc.id !== documentId);

		if (shouldAdd) {

			if (!categories) categories = [];
			const selectedCategory = categoriesList?.filter(cat => cat.id === categoryId)[0];
			if (!selectedCategory) throw `Could not find selected category id ${categoryId}`;
			categories = [...categories, selectedCategory];

		} else { // Should remove

			if (!categories) throw `Trying to remove category Id ${categoryId} but could not find any categories associated with document Id ${documentId}`;
			categories = categories?.filter(cat => cat.id !== categoryId);

		}

		setDocuments([...documentsWithoutSelectedDocument,
		{
			...selectedDocumentWithoutCategories,
			categories: categories
		}].sort(sortDocument))

		preventExitWithoutSaving();

		return true;
	}

	const sendCategoriesUpdate = async () => {
		// Make a dictionary of all categories, with categoryId as the key, and array of documentId as value
		let categoriesAssociations: Dictionary<string[]> = {};

		categoriesList?.forEach(category => {

			if (!category.id) { console.warn("Encountered a category without id which should not be possible. Contact your Dev Team"); return; }

			// Get all document associated with the category
			let associatedDocumentsId = documents.filter(document => {
				if (!document.id) { console.warn("Encountered a document without id which should not be possible. Contact your Dev Team"); return; }
				if (document.categories?.some(documentCategory => { return documentCategory.id === category.id; })) {
					return true;
				}
				return false;
			}).map(document => document.id as string); // We check that id is not null in `documents.filter` so we are sure the id can only be a `string` and never `undefined`

			categoriesAssociations[category.id] = associatedDocumentsId;
		})

		await updateCategoriesFormeJuridiqueTypesDeDocuments(categoriesDispatch, categoriesAssociations);
		allowNavigation();
	}

	const documentIsSupposedToHaveChoixDefinedButDoesnt = (document: TypeDeDocumentWithCategoriesDto): boolean => {
		if (document.hasChoix && (!document.choix || document.choix.length <= 0)) {
			return true;
		}
		return false;
	}

	const renderCheckBox = (document: TypeDeDocumentWithCategoriesDto, category: CategorieFormeJuridiqueDto) => {
		if (!document.id) { console.warn("document.id not found. Skipping"); return; }
		let checkBoxValue = !document.categories ? false : document.categories?.some(documentCategory => documentCategory.id === category.id);

		return (
			<div className="documentAffectationTab__categorie" key={category.id}>
				<Checkbox
					value={checkBoxValue}
					onChange={(value: boolean): boolean => {
						if (updateCategoriesDuDocument(document.id, category.id, value)) {
							return true;
						}
						return false;
					}}
				/>
			</div>
		);
	}

	return (
		<>
			<div className="documentAffectationTab">
				<div className="documentAffectationTab__header">
					<div className="documentAffectationTab__titleLeft">
						Associer les documents à une catégorie
					</div>
					<div className="documentAffectationTab__categories">
						{categoriesList?.map(category => (
							<div key={category.id} className="documentAffectationTab__categorieTitle">
								{category.nom}
							</div>
						))}
					</div>
				</div>
				{documents?.map((document, index) => (
					<div key={index} className="documentAffectationTab__content">
						<div className="documentAffectationTab__infoLeft">
							<div className="documentAffectationTab__buttons">
								<i onClick={() => openEditDocumentModal(document)} className="fas fa-edit"></i>
								<i onClick={() => openDeleteDocumentModal(document)} className="fas fa-trash-alt"></i>
							</div>
							<div>
								{document.nom?.fr}

								{document.hasChoix && document.choix?.length === 0 && (
									<div className="documentAffectationTab__warnings">
										<i className="fas fa-exclamation-triangle documentAffectationTab__warningsIcon"></i>										
										<div className="documentAffectationTab__tooltip">Vous devez ajouter des choix pour finaliser votre document. Ou modifier votre sélection de "Ajouter une liste de choix" dans l'édition de votre document.</div>
									</div>
								)}

								{document.hasChoix && (
									<div className="documentAffectationTab__addChoix">
										{document.choix?.map((choix, index) => (
											<div className="documentAffectationTab-addChoix__item" key={choix.id ?? `${document.id}-choix${index}`}>
												<i onClick={() => openEditChoixModal(document, choix)} className="fas fa-edit"></i>
												<i onClick={() => openDeleteChoixModal(document, choix)} className="fas fa-trash-alt"></i>
												Choix {index + 1} : {choix.nom?.fr}
											</div>)
										)}
										<div className="documentAffectationTab-addChoix__button"
											onClick={() => opendAddChoixModal(document)}>
											<i className="fas fa-plus"></i>Ajouter un choix
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="documentAffectationTab__categories">
							{categoriesList?.map(category => renderCheckBox(document, category))}
						</div>
					</div>
				))}
			</div>
			{
				modalAddDocumentIsOpen &&
				<EditDocument
					isOpen={modalAddDocumentIsOpen}
					setIsOpen={setModalAddDocumentIsOpen}
					//mettre un boolean dans un useState // VFA : Kézako ?
					isCreation={true}
				/>
			} {
				modalEditDocumentIsOpen &&
				<EditDocument
					isOpen={modalEditDocumentIsOpen}
					setIsOpen={setModalEditDocumentIsOpen}
					//mettre un boolean dans un useState
					isCreation={false}
					existingDocumentOrChoix={selectedDocument}
				/>
			} {
				<ModalDeleteDocument
					isOpen={modalDeleteDocumentIsOpen}
					onCancel={() => setModalDeleteDocumentIsOpen(false)}
					onValidate={handleDeleteTypeDeDocument}
					isLoading={documentSelector.documentDeleteIsLoading}
				/>
			} { // Ajout ou édition d'un choix
				<EditDocument
					isOpen={modalAddChoixIsOpen || modalEditChoixIsOpen}
					setIsOpen={(isOpen) => { setModalAddChoixIsOpen(isOpen); setModalEditChoixIsOpen(isOpen) }}
					existingDocumentOrChoix={selectedDocument}
					parentDocument={selectedParentDocument}
					isChoix={true}
					isCreation={modalAddChoixIsOpen}
					onCancel={() => { setSelectedDocument(undefined); setSelectedParentDocument(undefined) }}
				/>
			} {
				<ModalDeleteChoix
					isOpen={modalDeleteChoixIsOpen}
					onCancel={() => setModalDeleteChoixIsOpen(false)}
					onValidate={handleDeleteChoix}
					isLoading={documentSelector.documentDeleteIsLoading}
				/>
			}
			<div className="categorieFormeJuridique__footer">
				<FormButton
					disabled={documentSelector.documentsIsLoading}
					type="submit"
					value="Valider"
					onClick={sendCategoriesUpdate}
				/>
			</div>
		</>
	);
}

export default DocumentAffectationTab;