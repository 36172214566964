import Toggle from "adel-shared/dist/components/Toggle";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorMessage, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import useValidation from "../../../custom-hooks/useValidation";
import { AdelUserDto, CommissionDto, DocumentDto, DossierInstructionDto, ProblemDetails, Role, StatutDossier, SubCategorieDossierDto } from "../../../services/generated/BackOffice-api";
import { useCommissionSelector, useDossierSelector, useUserSelector } from "../../../store/store-helpers";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import General from "./General";
import { fetchDossierInstruction, sendDossierInstruction } from '../../../store/dossier-store/actions/dossierInstructionActions';
import { fetchUserRoles, fetchUsersInstructionByRole } from "../../../store/user-store/actions/userAction";
import { useTranslation } from "react-i18next";
import { UpdateDossierInstructionDto } from "../../../clients/AdelDossierClient";
import { Dictionary } from "adel-shared/dist/models";
import { toast } from "react-toastify";
import { fetchSubCategories } from "../../../store/dossier-store/actions/dossierAction";
import { fetchDossierCommissions } from "../../../store/commission-store/actions/commissionActions";
import Loader from "react-loader-spinner";
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { RolesValues } from "../../../enums/RolesValues";
import { InstructionRole } from "../../../constants/filters.constant";
import WysiwygEditor from "adel-shared/dist/components/WysiwygEditor";

export interface InstructionForm {
    commentairesInstruction: string;
    motif: string;
    dsv: boolean;
    blocageAvis: boolean;
    chargeInstruction?: AdelUserDto;
    commission: CommissionDto;
    statut: StatutDossier;
    subCategorie?: SubCategorieDossierDto;
}

interface DossierInstructionProps {
    dossierId: string
}

const DossierInstructionTab: React.FunctionComponent<DossierInstructionProps> = (props) => {
    const dossierSelector = useDossierSelector();
    const commissionsSelector = useCommissionSelector();
    const userSelector = useUserSelector();
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [commissions, setCommissions] = useState<CommissionDto[]>();
    const [categories, setCategories] = useState<SubCategorieDossierDto[]>();
    const [dossierInstruction, setDossierInstruction] = useState<DossierInstructionDto>({});
    const [blocageJuridiqueInitialState, setBlocageJuridiqueInitialState] = useState<boolean>();
    const [files, setFiles] = useState<File[]>([]);

    const { getRootValidator } = useValidation();
    const instructionValidator = getRootValidator("UpdateDossierInstructionDto");
    const { register, getValues, setValue, triggerValidation, control, errors } = useForm<InstructionForm>();
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const RoleSecureId = userSelector.roles?.find(role => role.role == InstructionRole)?.id
    
    const [commentaire, setCommentaire] = useState<string>("");

    const initPage = async () => {
        try {
            await fetchDossierInstruction(dispatch, props.dossierId);
            await fetchUsersInstructionByRole(dispatch, [RoleSecureId], "", true);
            await fetchSubCategories(dispatch);
            await fetchDossierCommissions(dispatch, props.dossierId);
        } catch (error) {
            toast.error(t('common.errors.fetch'));
        }
    }

    useEffect(() => {
        (async () => {
            RoleSecureId && await initPage();
        })()
    }, [RoleSecureId]);
    useEffect(() => {
		fetchUserRoles(dispatch)
	}, [dispatch]);
	useEffect(() => {
        if (dossierSelector.dossierInstruction && !pageLoaded) {
            setBlocageJuridiqueInitialState(dossierSelector.dossierInstruction.blocageJuridique);
            setDossierInstruction(dossierSelector.dossierInstruction);
            setCommentaire(dossierSelector.dossierInstruction.commentairesInstruction ?? "");
            setValue("motif", dossierSelector.dossierInstruction.motifBlocageJuridique ?? "");
        }
    }, [dossierSelector.dossierInstruction])

    useEffect(() => {
        if (dossierSelector.subCategories && commissionsSelector.commissions && !pageLoaded) {
            setCategories(dossierSelector.subCategories);
            setCommissions(commissionsSelector.commissions.items);
        }
    }, [dossierSelector.subCategories, commissionsSelector.commissions])

    const isChargesDinstructionEnabled: boolean = useMemo(() => {
        if (dossierInstruction?.statut !== StatutDossier.ProgrammeEnCommission) return true;
        return false;
    }, [dossierInstruction?.statut]);

    const onChangeBlocage = () => {
        setDossierInstruction({ 
			...dossierInstruction,
			blocageJuridique: !dossierInstruction.blocageJuridique
		});
		setValue("motif", "");
    }

    const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        if (value && value.target && value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
            let escapedName = value.target.files[0].name.replace(/\W/g, '');
            if (files && files.findIndex(file => {
                let comparedEscapedName = file.name.replace(/\W/g, '');
                if (comparedEscapedName === escapedName) return true;
            }) > -1) {
                toast.error(t('dossier.errors.fileExisting'))
            } else {
                setFiles([...files, value.target.files[0]]);
            }
        } else {
            console.error("file added error");
        }
    }

    const handleFileDelete = (fileName: string) => {
        setFiles(files.filter(file => file.name !== fileName));
    }

    const handleExistingFileDelete = (documentId: string | undefined) => {
        if (documentId === undefined) throw "No document id was found on selected document."
        setDossierInstruction({ ...dossierInstruction, documents: dossierInstruction?.documents?.filter(document => document.id !== documentId) });
    }

    const submitDossierInstruction = async () => {
        const formValues = getValues();
        formValues.commentairesInstruction = commentaire;   
        const isFormValid = await triggerValidation();
        if (!isFormValid) {
            toast.error(t('common.errors.form'));
            return;
        }
        if(formValues.statut === StatutDossier.ProgrammeEnCommission && formValues.chargeInstruction == undefined){
            toast.error("Impossible de programmer en commission un dossier sans chargé d'instruction rattaché.");
        }
        const updateDossierInstructionDto: UpdateDossierInstructionDto = {
            statut: formValues.statut,
            chargeInstructionId: formValues.chargeInstruction?.id,
            subCategorieId: formValues.subCategorie?.id,
            commissionId: formValues.commission?.id,
            dsv: formValues.dsv,
            blocageAvis: formValues.blocageAvis,
            blocageJuridique: dossierInstruction?.blocageJuridique,
            motifBlocageJuridique: formValues.motif,

            ribModifiableParStructure: dossierInstruction?.ribModifibiableParStructure,
            modifiableParStructure: dossierInstruction?.modifiableParStructure,
            commentairesInstruction: formValues.commentairesInstruction,
            documents: dossierInstruction?.documents?.map(document => ({ id: document.id } as DocumentDto )) || []
        };
        const fileDictionary: Dictionary<File> = {};

        files.forEach((file) => {
            const escapedFileName = file.name.replace(/\W/g, '');
            updateDossierInstructionDto.documents.push({
                partName: escapedFileName,
            });
            fileDictionary[escapedFileName] = file;
        });

        try {
            usePermUpdateDossierInstruction && await sendDossierInstruction(dispatch, props.dossierId, updateDossierInstructionDto, fileDictionary);
            toast.success(t('common.success'));
            setPageLoaded(false);
            setFiles([]);
            const timer = setTimeout(async () => await initPage(), 0);
            return () => clearTimeout(timer);
        } catch (error) {
			let pb = (error.response.data || error.response) as ProblemDetails;

            if (error?.response?.exception?.type.includes('CommissionWithoutSubCategoryException')) {
                toast.error(error?.response?.exception.message);
            }             
            if(pb?.code &&
                (pb.code === 'dateDebutDossierMustBeSuperiorToDateFinCommission' ||
                pb.code === 'invalidStatutCommission' ||
                pb.code === 'invalidStatutDossierToChangeCommission')
            ) {
                toast.error(t(`dossier.instruction.errors.${pb.code}`));
            } else {
                toast.error(t('common.errors.send'));
            }
		}
    }

    const onGeneralLoaded = () => {
        if (dossierSelector && dossierSelector.dossierInstruction) {
            if (dossierSelector.dossierInstruction?.chargeInstruction) setValue("chargeInstruction", dossierSelector.dossierInstruction?.chargeInstruction);
            if (dossierSelector.dossierInstruction?.commission) setValue("commission", dossierSelector.dossierInstruction?.commission);
            if (dossierSelector.dossierInstruction?.statut) setValue("statut", dossierSelector.dossierInstruction?.statut);
            if (dossierSelector.dossierInstruction?.subCategorie) setValue("subCategorie", dossierSelector.dossierInstruction?.subCategorie);
            setValue("dsv", dossierSelector.dossierInstruction?.dsv ?? false);
            setValue("blocageAvis", dossierSelector.dossierInstruction?.blocageAvis ?? false);
            setPageLoaded(true);
        }
    }

	const customValidate = (value: string, minLengthErrorMessage: string) => {
        if (blocageJuridiqueInitialState && dossierInstruction.blocageJuridique === false && value.length < 20) 
			return minLengthErrorMessage;
    }


	/** Permissions */
	const usePermUpdateDossierInstruction = useHasPermission("UpdateDossierInstruction");

    return <div className="instructionTab">
        {!dossierSelector.isInstructionLoading
		? <>
            <div className="detailsTab__section">
                <div className="collapsibleSection">
                    <h3 className="instructionTab__title">
                        <span>Général</span>
                    </h3>
					{commissions && commissions.length > 0 && categories && dossierInstruction?.allowedStatuts &&
						<General
                        commissions={commissions}
                        allowedStatuts={dossierInstruction.allowedStatuts}
                        categories={categories}
                        dossierInstruction={dossierInstruction}
                        isChargesDinstructionEnabled={isChargesDinstructionEnabled}
                        control={control}
                        onLoaded={onGeneralLoaded}
                        errors={errors}
                        instructionValidator={instructionValidator}
                        pageLoaded={pageLoaded}
                    />
					}
                    
                </div>
            </div>
            <div className="detailsTab__section">
                <div className="collapsibleSection">
                    <h3 className="instructionTab__title">
                        <span className="instructionTab__blocage">Blocage juridique {
							blocageJuridiqueInitialState && !dossierInstruction.blocageJuridique
							&& "(déblocage juridique)"
						}</span>
                        <Toggle
                            label=""
                            value={dossierInstruction.blocageJuridique}
                            setCheck={onChangeBlocage}
                            disabled={!usePermUpdateDossierInstruction}
                        />
                    </h3>
                    <div className="detailsTab__row">
                        <div className="instructionTab__noColumn">
                            <div className="detailsTab__content">
								<label className="input__label">Commentaires *</label>
								<textarea className="instructionTab__motif"
										name="motif"
										ref={
											register({
												required: { value: blocageJuridiqueInitialState || dossierInstruction.blocageJuridique === true, message: "Ce champ est obligatoire."},
												maxLength: { value: 500, message: t('validation-messages.max-length', {length:500})},
												validate: { value: (value) => customValidate(value, t('validation-messages.min-length', {length:20})) } 
											})
										}
                                        readOnly={!usePermUpdateDossierInstruction}
								></textarea>
								{errors &&
									<ErrorMessage errors={errors} name={"motif"}>
										{({ message }) => <p className="input__errorMessage">{message}</p>}
									</ErrorMessage>
								}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="detailsTab__section">
                <div className="collapsibleSection">
                    <h3 className="instructionTab__title">
                        <span>Pièces-jointes complémentaires</span>
                    </h3>
                    {dossierInstruction?.documents?.map(document => {
                        return <div key={document.id} className="instructionTab__file">
                            <a target="_blank" href={document && document.uri ? document.uri : ''}><i className="fas fa-file-upload"></i> {document.fileName}</a> <i className="far fa-trash-alt" onClick={() => handleExistingFileDelete(document.id)}></i>
                        </div>
                    })}
                    {files.map(file => {
                        return <div key={file.name}>
                            <i className="far fa-file"></i> {file.name} <i className="far fa-trash-alt" onClick={() => handleFileDelete(file.name)}></i>
                        </div>
                    })}
                    <div className="inputFile__buttons">
                        <label htmlFor="uploadDocument">Télécharger une pièce-jointe</label>
                        <input
                            type="file"
                            id="uploadDocument"
                            onChange={handleFileChange}
                            disabled={!usePermUpdateDossierInstruction}
                        />
                    </div>
                </div>
            </div>
            <div className="detailsTab__section">
                <div className="collapsibleSection">
                    <h3 className="instructionTab__title">
                        <span>Commentaire général</span>
                    </h3>
					{//TODO: useperm role juridique
						<div className="detailsTab__row">
							<div className="instructionTab__noColumn">
								<div className="detailsTab__content">
                                        <WysiwygEditor
                                            editorValueContent={commentaire}
                                            setEditorValueContent={setCommentaire}
                                            isReadonly={!usePermUpdateDossierInstruction}
                                        />
									{errors &&
										<ErrorMessage errors={errors} name="commentairesInstruction">
											{({ message }) => <p className="input__errorMessage">{message}</p>}
										</ErrorMessage>
									}
								</div>
							</div>
						</div>
					}
                </div>
            </div>
            <div className="detailsTab__footer">
                {usePermUpdateDossierInstruction && <FormButton
                    disabled={false}
                    type="submit"
                    value="Valider"
                    onClick={submitDossierInstruction}
                />}
            </div>

        </> : <Loader
            type="TailSpin"
            width={35}
            height={35}
            color="#d93943"
        ></Loader>}
    </div>;
}

export default DossierInstructionTab;