import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Input from "adel-shared/dist/components/basics/Input";
import InputSelect from "adel-shared/dist/components/basics/InputSelect";
import { useDispatch } from 'react-redux';
import { usePageVitrineSelector } from "../../store/store-helpers";
import { editPageVitrine, savePage, delPageVitrine, addPageVitrineFile } from "../../store/page-vitrine-store/actions/pageVitrineAction";
import CollapsibleSection from "../basics/CollapsibleSection";
import Loader from "react-loader-spinner";
import ModalDeletePageVitrine from './ModalDeletePageVitrine';
import ModalAttentionModificationEnCours from "./ModalAttentionModificationEnCours";
import { FileParameter, PageVitrineDto, StatutPageVitrine } from '../../services/generated/BackOffice-api';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import WysiwygEditor from "adel-shared/dist/components/WysiwygEditor";
import { modalCustomStylesPage } from '../../constants/config.constant';

interface PageVitrineSectionProps {
	pages: PageVitrineDto[];
	setPages: (value: PageVitrineDto[]) => void;
	pageClickedInfo?: PageVitrineDto;
	modalPageIsOpen: boolean;
	setPageClickedInfo: (value: PageVitrineDto) => void;
	setPageHasBeenModified: (value: boolean) => void;
	modalAttentionModificationEnCoursIsOpen: boolean;
	setModalAttentionModificationEnCoursIsOpen: (value: boolean) => void;
	navigationCallback: () => void;
	allowNavigation: () => void;
}

const editorCustomButtons = [
	{
		name: 'commissions',
		tooltip: 'Liste des commissions',
		exec: function (editor: any) {
			editor.s.insertHTML('{{commissions}}');
		}
	}
];

const PageVitrine: React.FunctionComponent<PageVitrineSectionProps> = ({
	pageClickedInfo,
	modalPageIsOpen,
	pages,
	setPages,
	setPageClickedInfo,
	setPageHasBeenModified,
	modalAttentionModificationEnCoursIsOpen,
	setModalAttentionModificationEnCoursIsOpen,
	navigationCallback,
	allowNavigation,
}) => {
	const { i18n, t } = useTranslation();
	const [modalDeletePageVitrineIsOpen, setModalDeletePageVitrineIsOpen] = useState<boolean>(false)

	//valeurs dans la modale
	const [nomFR, setNomFR] = useState<string>("");
	const [contenuFR, setContenuFR] = useState<string>("");
	const [nomEN, setNomEN] = useState<string>("");
	const [contenuEN, setContenuEN] = useState<string>("");
	const [statutPage, setStatutPage] = useState<StatutPageVitrine>(StatutPageVitrine.Draft);
	const [documentVitrine, setDocumentVitrine] = useState<FileParameter>();

	//selector
	const { pageIsLoading } = usePageVitrineSelector();

	const dispatch = useDispatch();

	const statutOptions = Object.values(StatutPageVitrine).filter(s => s !== StatutPageVitrine.None).map(s => ({
		label: s,
		value: s
	}));

	useEffect(() => {
		if (pageClickedInfo?.nom && pageClickedInfo.contenu && pageClickedInfo.statut) {
			setContenuFR(pageClickedInfo.contenu.fr)
			setNomFR(pageClickedInfo.nom.fr)
			setContenuEN(pageClickedInfo.contenu.en)
			setNomEN(pageClickedInfo.nom.en)
			setStatutPage(pageClickedInfo.statut)
		} else {
			setContenuFR('');
			setNomFR('');
			setContenuEN('');
			setNomEN('');
			setStatutPage(StatutPageVitrine.Draft);
		}
	}, [pageClickedInfo?.id]);

	useEffect(() => {
		const regex = /<[^>]*>/g;
		if (
			(contenuFR && contenuFR.replace(regex, '') !== pageClickedInfo?.contenu?.fr.replace(regex, '')) ||
			(contenuEN && contenuEN.replace(regex, '') !== pageClickedInfo?.contenu?.en.replace(regex, ''))
		) {
			setPageHasBeenModified(true);
		}
	}, [contenuFR, contenuEN]);

	const handleFileDelete = () => {
        setDocumentVitrine({data: undefined, fileName: ""});
    };

	const linkFiles = (file: any, lang: string) => {
		var div = document.createElement('div');
		var a = document.createElement('a')
		var linkText = document.createTextNode(file.name);
		a.appendChild(linkText);
		div.appendChild(a);
		a.title = file.name;
		a.href = file.link;
		a.target = '_blank';
		if (lang === 'FR') {
			setContenuFR(contenuFR + div.innerHTML)
		} else {
			setContenuEN(contenuEN + div.innerHTML)
		}
	}

	const editPage = (id: string) => {
		const body = {
			nom: {
				fr: nomFR,
				en: nomEN
			},
			statut: statutPage,
			contenu: {
				fr: contenuFR,
				en: contenuEN
			}
		}

		documentVitrine && addPageVitrineFile(dispatch, documentVitrine)().then(result => {
			Object.assign(documentVitrine.data, { link: result.payload.file });
			linkFiles(documentVitrine.data, "FR")
		});

		editPageVitrine(dispatch, id, body)().then(result => {

			//on met à jour la page de la liste
			let index = -1;
			let i = 0;
			const updatedPages = [...pages]
			updatedPages.forEach((element) => {
				if (element.id === id) {
					index = i;
				}
				i++;
			})
			if (index !== -1) {
				updatedPages[index] = result.payload.page
				setPages(updatedPages);
			}
			// on réinitialise le boolean qui check les modifs
			setPageHasBeenModified(false)
		})
	}

	const deletePage = () => {
		if(!pageClickedInfo?.id) return;

		delPageVitrine(dispatch, pageClickedInfo.id)().then(() => {

			//on supprime la page de la liste
			let index = -1;
			let i = 0;
			const updatedPages = [...pages]
			updatedPages.forEach((element) => {
				if (element.id === pageClickedInfo.id) {
					index = i;
				}
				i++;
			})
			if (index !== -1) {
				updatedPages.splice(index, 1);
				setPages(updatedPages);
			}

			if (updatedPages.length > 0) {
				setPageClickedInfo(updatedPages[0])
			}
			setModalDeletePageVitrineIsOpen(false)
		})
	}

	const leaveCreation = () => {
		if(pages[0]) {
			setPageClickedInfo(pages[0])
		}
	}

	const createPage = () => {
		const body = {
			nom: {
				fr: nomFR,
				en: nomEN
			},
			statut: statutPage,
			contenu: {
				fr: contenuFR,
				en: contenuEN
			}
		}

		documentVitrine && addPageVitrineFile(dispatch, documentVitrine)().then(result => {
			Object.assign(documentVitrine.data, { link: result.payload.file });
			linkFiles(documentVitrine.data, "FR")
		});

		savePage(dispatch, body).then(result => {
			setPages([...pages, result]);
			setPageClickedInfo(result);
			toast.success(t('toast.page-vitrine-create'));
		}).catch(error => {
			const errorCode = error.response.code;
			if(errorCode && i18n.exists(`errorApi.${errorCode}`)) {
				toast.error(t(`errorApi.${errorCode}`));
			} else {
				toast.error('Une erreur est survenue pendant la création de la page vitrine.');
			}
		});
	}

	const AddFile = (value: React.ChangeEvent<HTMLInputElement>) => {
		if (value && value.target && value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
			let formData:FileParameter = {
				data: value.target.files[0],
				fileName: value.target.files[0].name
			};
			setDocumentVitrine(formData);
		}
	}

	const onWarningModalValidated = () => {
		setModalAttentionModificationEnCoursIsOpen(false);
		allowNavigation();
		navigationCallback();
	}

	return (
		<>
			{
				modalPageIsOpen && pages &&
				<Modal
					isOpen={modalPageIsOpen}
					style={modalCustomStylesPage}
					className="pageVitrine__modal"
				>
					<div className="pageVitrine__wrapper">
						<div className="modal__header">
							<h3>Administration - Gestion des pages vitrines</h3>
						</div>

						<div className="modal__scroll">
							<div className="modal__content">
								<div className="detailsTab">
									<div className="detailsTab__section">

										<CollapsibleSection title="Contenu Français" isOpenByDefault={true}>
											<>
												<div className="detailsTab__row" style={{ marginTop: "30px", marginBottom: "20px", height: "60px" }}>
													<div className="detailsTab__pageTitle">
														<div className="detailsTab__pageTitleLabel">
															<span>Titre de la page en français :</span>
														</div>
														<Input
															name="nomFR"
															type="text"
															value={nomFR}
															onChange={(value: string) => {
																setNomFR(value)
																setPageHasBeenModified(true)
															}}
														/>
													</div>

													<div>
														<label>Statut :</label>

														<InputSelect
															classname="inputSelectPage"
															name="statut"
															options={statutOptions}
															onChange={elem => {
																setStatutPage(elem)
																setPageHasBeenModified(true)
															}}
															value={statutPage}
														/>
													</div>
												</div>
												<div className="detailsTab__row">
													<span className="detailsTab__commentary">Note : Le titre ne sera pas affiché sur le message</span>
												</div>
												<div className="detailsTab_wysiwyg">
													<WysiwygEditor
														editorValueContent={contenuFR}
														setEditorValueContent={setContenuFR}
														customButtons={editorCustomButtons}
													/>
												</div>
											</>
										</CollapsibleSection>
									</div>

									<div className="detailsTab__section">
										<CollapsibleSection title="Contenu Anglais" isOpenByDefault={true}>
											<>
												<div className="detailsTab__row" style={{ marginTop: "30px", marginBottom: "20px" }}>
													<div className="detailsTab__pageTitle">
														<div className="detailsTab__pageTitleLabel">
															<span>Titre de la page en anglais :</span>
														</div>

														<Input
															name="nomEN"
															type="text"
															value={nomEN}
															onChange={(value: string) => {
																setNomEN(value)
																setPageHasBeenModified(true)
															}}
														/>
													</div>
												</div>
												<div className="detailsTab__row">
													<span className="detailsTab__commentary">Note : Le titre ne sera pas affiché sur le message</span>
												</div>
												<div className="detailsTab_wysiwyg">
													<WysiwygEditor
														editorValueContent={contenuEN}
														setEditorValueContent={setContenuEN}
														customButtons={editorCustomButtons}
													/>
												</div>
											</>
										</CollapsibleSection>
										
									</div>
									
									<div className="detailsTab__section">
										<div className="detailsTab__inputFile">
											{!(documentVitrine as FileParameter)?.data
												? 
													<div className="inputFile__singleButton">
														<label htmlFor="uploadDocument"><i className="fas fa-paperclip"></i></label>
														<input
															id="uploadDocument"
															type="file"
															onChange={(value) => {
																AddFile(value);
																setPageHasBeenModified(true);
															}}
														/>
													</div>
												: <div>{(documentVitrine as FileParameter)?.fileName} <i className="far fa-trash-alt" onClick={handleFileDelete}></i></div>
											}
										</div>
									</div>
								</div>
							</div>

							<div className="modal__footer">
								{pageClickedInfo?.id ? (
									<FormButton
										type="button"
										value="Supprimer"
										onClick={() => setModalDeletePageVitrineIsOpen(true)}
									/>
								) : (
									<FormButton
										type="button"
										value="Annuler"
										onClick={leaveCreation}
									/>
								)}

								{pageIsLoading ?
									<Loader
										type="TailSpin"
										width={35}
										height={35}
										color="#d93943"
									></Loader>
									:
									<FormButton
										type="submit"
										value="Enregistrer"
										disabled={nomFR === '' || nomEN === '' || contenuFR === '' || contenuEN === ''}
										onClick={() => {
											if (pageClickedInfo?.id) {
												editPage(pageClickedInfo.id)
											} else {
												createPage()
											}
										}}
									/>
								}
							</div>
						</div>
					</div>
				</Modal>
			} {
				<ModalDeletePageVitrine
					isOpen={modalDeletePageVitrineIsOpen}
					onCancel={() => setModalDeletePageVitrineIsOpen(false)}
					onValidate={() => deletePage()}
				/>
			}
			{
				<ModalAttentionModificationEnCours
					isOpen={modalAttentionModificationEnCoursIsOpen}
					close={() => setModalAttentionModificationEnCoursIsOpen(false)}
					onValidate={onWarningModalValidated}
				/>
			}
		</>
	);
}

export default PageVitrine;