import React, { useState, useEffect } from 'react';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { useTable, useSortBy, useExpanded } from 'react-table';
import { fetchBalance } from '../../../store/structure-store/actions/structureDetailsActions';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { Link } from '@reach/router';
import moment from 'moment';
import NoItemsTable from '../../basics/NoItemsTable';

interface StructureBalanceProps {
	structureId: string;
}

const StructureBalanceTab: React.FunctionComponent<StructureBalanceProps> = ({
	structureId
}) => {
	const [data, setData] = useState<Array<any>>([]);

	useEffect(() => {
		(async() => {
			const result = await fetchBalance(structureId);
			setData(result.map(x => ({
				nom: (
					<Link to={`/Dossier/${x.id}`}>
						{x.nom}
					</Link>
				),
				solde: `${formatNumber(x.solde)} €`,
				subRows: x.mouvements?.map(y => ({
					date: moment(y.dateReglement).format('DD/MM/YYYY'),
					nom: y.intitule,
					credit: `${formatNumber(y.credit)} €`,
					debit: `${formatNumber(y.debit)} €`,
				})),
				id: x.id
			})));
		})();
	}, []);

	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						id: "expander",
						disableSortBy: true,
						width: 30,
						Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
							<span {...getToggleAllRowsExpandedProps()} title={isAllRowsExpanded ? 'Cacher les mouvements' : 'Voir les mouvements'}>
								{isAllRowsExpanded ? (
									<i className="far fa-chevron-down" aria-hidden="true"></i>
								) : (
									<i className="far fa-chevron-right" aria-hidden="true"></i>
								)}
							</span>
						),
						Cell: ({ row }: any) =>
							row.canExpand ? (
								<span {...row.getToggleRowExpandedProps()} title={row.isExpanded ? 'Cacher les mouvements' : 'Voir les mouvements'}>
									{row.isExpanded ? (
										<i className="far fa-chevron-down" aria-hidden="true"></i>
									) : (
										<i className="far fa-chevron-right" aria-hidden="true"></i>
									)}
								</span>
							) : null
					},
					{
						Header: "Date",
						accessor: "date",
						sortType: "basic"
					},
					{
						Header: "Intitulé",
						accessor: "nom",
						sortType: "basic"
					},
					{
						Header: "Débit",
						accessor: "debit",
						sortType: "basic"
					},
					{
						Header: "Crédit",
						accessor: "credit",
						sortType: "basic"
					},
					{
						Header: "Solde",
						accessor: "solde",
						sortType: "basic"
					},
					{
						Header: "Id",
						accessor: "id"
					}
				]
			}
		];
	}, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["id"]
			}
		},
		useSortBy,
		useExpanded
	);

	return (
		<div className="detailsTab">
			<div className="detailsTab__section">
				{data.length ? (
					<Table
						data={data}
						getTableProps={getTableProps}
						getTableBodyProps={getTableBodyProps}
						getRowProps={(row: any) => ({
							style: {
								background: row.depth === 1 ? 'rgba(0,0,0,.1)' : 'white',
							},
						})}
						headerGroups={headerGroups}
						rows={rows}
						prepareRow={prepareRow}
						styles={styleTable.onglet}
					/>
				) : (
					<NoItemsTable />
				)}
			</div>
		</div>
	);
}

export default StructureBalanceTab;