import { generalReducer } from './general-store/reducers/generalReducer';
import { adresseReducer } from './test-store/reducers/adresseReducer'
import { vueReducer } from './vue-store/reducers/vueReducer'
import { userReducer } from './user-store/reducers/userReducer'
import { templateReducer } from './template-store/reducers/templateReducer'
import { categorieDossierReducer } from './categorie-dossier-store/reducers/categorieDossierReducer'
import { criteresDossierReducer } from './critere-dossier-store/reducers/critereDossierReducer'
import { remunerationReducer } from './remuneration-store/reducers/remunerationReducer'
import { structureReducer } from './structure-store/reducers/structureReducer'
import { dossierReducer } from './dossier-store/reducers/dossierReducer'
import { categoriesFormeJuridiqueReducer } from './categorie-forme-juridique-store/reducers/categorieFormeJuridiqueReducer'
import { typeDeDocumentReducer } from './type-de-document-store/reducers/typeDeDocumentReducer';
import { pageVitrineReducer } from './page-vitrine-store/reducers/pageVitrineReducer';
import { commissionReducer } from './commission-store/reducers/commissionReducer';
import { commissionMembreReducer } from './commission-membre-store/reducers/commissionMembreReducer';
import { compteBancaireReducer } from './compte-bancaire-store/reducers/compteBancaireReducer';
import { bordereauReducer } from './bordereau-store/reducers/bordereauReducer';
import { generationSpedidamReducer } from './gestion-entite-store/reducers/generationSpedidamReducer';
import { combineReducers } from 'redux'
import { pageWarningReducer } from './page-warning-store/pageWarningReducer';
import { UserActionsEnum } from './user-store/actions/userAction';

const appReducer = combineReducers({
	generalReducer,
	adresseReducer,
	structureReducer,
	dossierReducer,
	userReducer,
	vueReducer,
	templateReducer,
	categoriesFormeJuridiqueReducer,
	typeDeDocumentReducer,
	criteresDossierReducer,
	categorieDossierReducer,
	pageVitrineReducer,
	remunerationReducer,
	commissionReducer,
	commissionMembreReducer,
	compteBancaireReducer,
	bordereauReducer,
	pageWarningReducer,
	generationSpedidamReducer
})

const rootReducer: typeof appReducer = (state, action) => {
	if (action.type === UserActionsEnum.LOGOUT_USER) {
		state = undefined;
	}
  
	return appReducer(state, action);
}

export default rootReducer;