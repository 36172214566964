import { Action, Dispatch } from 'redux';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { PageWarningState } from './pageWarningState';
import { MessageAlerteBOClient, MessageAlerteClient, MessageAlerteDto } from '../../services/generated/BackOffice-api';
import { axiosInstance } from '../../custom-hooks/useAxios';

export enum PageWarningActionsEnum {
	GET_MESSAGES_ALERTE_REQUEST = "GET_MESSAGES_ALERTE_REQUEST",
	GET_MESSAGES_ALERTE_SUCCESS = "GET_MESSAGES_ALERTE_SUCCESS",
	GET_MESSAGES_ALERTE_ERROR = "GET_MESSAGES_ALERTE_ERROR",
	GET_MESSAGE_ALERTE_REQUEST = "GET_MESSAGE_ALERTE_REQUEST",
	GET_MESSAGE_ALERTE_SUCCESS = "GET_MESSAGE_ALERTE_SUCCESS",
	GET_MESSAGE_ALERTE_ERROR = "GET_MESSAGE_ALERTE_ERROR",
	SEND_MESSAGE_ALERTE_REQUEST = "SEND_MESSAGE_ALERTE_REQUEST",
	SEND_MESSAGE_ALERTE_SUCCESS = "SEND_MESSAGE_ALERTE_SUCCESS",
	SEND_MESSAGE_ALERTE_ERROR = "SEND_MESSAGE_ALERTE_ERROR",
	DELETE_MESSAGE_ALERTE_REQUEST = "DELETE_MESSAGE_ALERTE_REQUEST",
	DELETE_MESSAGE_ALERTE_SUCCESS = "DELETE_MESSAGE_ALERTE_SUCCESS",
	DELETE_MESSAGE_ALERTE_ERROR = "DELETE_MESSAGE_ALERTE_ERROR"
}

export interface DispatchPageWarningAction extends Action<PageWarningActionsEnum> {
	payload: PageWarningState;
}

const messageAlerteClient = new MessageAlerteClient;

export const getAllMessageAlerte = async(dispatch: Dispatch) => {
	dispatch({ type: PageWarningActionsEnum.GET_MESSAGES_ALERTE_REQUEST });
	try {
		const response = await messageAlerteClient.getAllMessageAlerte();
		dispatch({ type: PageWarningActionsEnum.GET_MESSAGES_ALERTE_SUCCESS, payload: {messages: response} });
	} catch(error) {
		dispatch({ type: PageWarningActionsEnum.GET_MESSAGES_ALERTE_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors de la récupération des messages d'alerte");
	}
};

export const getMessageAlerte = async(dispatch: Dispatch) => {
	dispatch({ type: PageWarningActionsEnum.GET_MESSAGE_ALERTE_REQUEST });
	try {
		const response = await messageAlerteClient.getMessageAlerte();
		dispatch({ type: PageWarningActionsEnum.GET_MESSAGE_ALERTE_SUCCESS, payload: {message: response} });
	} catch(error) {
		dispatch({ type: PageWarningActionsEnum.GET_MESSAGE_ALERTE_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors de la récupération du message d'alerte");
	}
};


const messageAlerteBOClient = new MessageAlerteBOClient('', axiosInstance);

export const createOrUpdateMessageAlerte = async(dispatch: Dispatch, body: MessageAlerteDto) => {
	dispatch({ type: PageWarningActionsEnum.SEND_MESSAGE_ALERTE_REQUEST });
	try {
		const response = await messageAlerteBOClient.createOrUpdateMessageAlerte(body);
		dispatch({ type: PageWarningActionsEnum.SEND_MESSAGE_ALERTE_SUCCESS });
		getAllMessageAlerte(dispatch);
		getMessageAlerte(dispatch);
		toast.success("Le message d'alerte a été créé avec succès.");
	} catch(error) {
		dispatch({ type: PageWarningActionsEnum.SEND_MESSAGE_ALERTE_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors de l'édition du message d'alerte");
	}
};

export const deleteMessageAlerte = async(dispatch: Dispatch, id: string) => {
	dispatch({ type: PageWarningActionsEnum.DELETE_MESSAGE_ALERTE_REQUEST });
	try {
		await messageAlerteBOClient.deleteMessageAlerte(id);
		dispatch({ type: PageWarningActionsEnum.DELETE_MESSAGE_ALERTE_SUCCESS, payload: {messageId: id} });
		toast.success("Le message d'alerte a été supprimé avec succès.");
	} catch(error) {
		dispatch({ type: PageWarningActionsEnum.DELETE_MESSAGE_ALERTE_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Une erreur est survenue lors de la suppression du message d'alerte");
	}
};