import React from "react";
import PagesContainer from "../../containers/PagesContainer";
import NoFolder from "../../assets/img/undraw-not-found.png";

const Unauthorized:React.FC = () => {
    return (
        <PagesContainer title="Accès non autorisé">
			<div className="noFolder">
				<div className="noFolder__item">
					<img src={NoFolder} alt="" />
					<p>Vous n'avez pas accès à la plateforme.<br/>Veuillez contacter un administrateur pour réactiver votre compte.</p>
				</div>
			</div>
		</PagesContainer>
    );
}

export default Unauthorized;