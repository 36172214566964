import { commissionFilterDefault } from '../../../constants/filters.constant';
import {
	CommissionCADtoSortedFilteredPage,
	CommissionDto,
	CommissionDtoSortedFilteredPage, 
	CommissionStartingDateDto, 
	CommissionTableauBordDto, 
	DossierCommissionDto, 
	DossierCommissionDtoSortedFilteredPage,
	EnveloppesBudgetairesDto
} from '../../../services/generated/BackOffice-api';

export interface CommissionState {
	isLoading?: boolean;
	commissions?: CommissionDtoSortedFilteredPage;
	isCreateLoading? :boolean;
	commission?: CommissionDto;
	errorCommissionCode?:string;
	isMontantLoading?: boolean;
	sessionsCA?: CommissionCADtoSortedFilteredPage;
	sessionCADossiers?: DossierCommissionDtoSortedFilteredPage;
	membresCommission?: any[]; //TODO: mettre vrai type
	commissionDossiers?: DossierCommissionDtoSortedFilteredPage;
	commissionDetailsDossiers?: DossierCommissionDto[];
	commissionDetailsDossier?: DossierCommissionDto;
	isDossierLoading?: boolean;
	commissionEnveloppesBudgetaires?: EnveloppesBudgetairesDto;
	commissionsListLight?: CommissionStartingDateDto[];
	isCommissionListLoading?: boolean;
	isTableauBordLoading?: boolean;
	commissionTableauBord?: CommissionTableauBordDto;
	isOpenCommLoading?:boolean;
	idCommissionToChange?:string;
	commissionFilter?: any
}

export const initialCommissionState: CommissionState = {
	isLoading: false,
	commissions: {},
	isCreateLoading: false,
	commission: {},
	isMontantLoading: false,
	sessionsCA: {},
	sessionCADossiers: {},
	errorCommissionCode: "",
	membresCommission: [],
	commissionDossiers: {},
	commissionDetailsDossiers: [],
	isDossierLoading: false,
	commissionEnveloppesBudgetaires: {},
	isCommissionListLoading: false,
	commissionsListLight: [],
	commissionTableauBord: {},
	isTableauBordLoading: false,
	isOpenCommLoading: false,
	idCommissionToChange: '',
	commissionFilter: commissionFilterDefault
};