import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { RouteComponentProps, Link } from "@reach/router";
import PagesContainer from "../../containers/PagesContainer";
import { ObjetDiscussion, StructureDto, VueDto, VueType } from '../../services/generated/BackOffice-api';
import { useDispatch } from 'react-redux';
import { fetchStructures, updateFilter } from '../../store/structure-store/actions/structureAction';
import { fetchVues, sendVue, saveVue, deleteVue } from '../../store/vue-store/actions/vueAction';
import { useTable, useSortBy } from 'react-table';
import { useStructureSelector, useUserSelector, useVueDispatcher, useVueSelector } from '../../store/store-helpers';

import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Filters from "../structures/structures-list/Filters";
import { nomFilterKey, emailFilterKey, statutFilterKey, userNameFilterKey, responsableFilterKey, siretFilterKey } from "../../constants/filters.constant";
import { modalCustomStyles, paginationNumbers } from "../../constants/config.constant";

import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from "adel-shared/dist/components/basics/Table";
import Pagination from "adel-shared/dist/components/basics/Pagination";
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import Toggle from 'adel-shared/dist/components/Toggle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { sendStructureMultipleMessages } from '../../store/structure-store/actions/structureMessagesActions';
import WysiwygEditor from 'adel-shared/dist/components/WysiwygEditor';
import { formaterDate } from 'adel-shared/dist/utils/functions';


const StructuresTable = (props: RouteComponentProps) => {
	const userSelector = useUserSelector();
	const {isLoading, structureFilter} = useStructureSelector();
	const [structures, setStructures] = useState<StructureDto[]>([]);
	const [vues, setVues] = useState<VueDto[]>([]);
	const [data, setData] = useState<Array<any>>([]);
	const [sort, setSort] = useState<string>("");
	const { t } = useTranslation();

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);

	/** Filtres vars */
	const [usernameFilter, setUsernameFilter] = useState<string>(structureFilter.username);
	const [nomFilter, setNomFilter] = useState<string>(structureFilter.nom);
	const [siretFilter, setSiretFilter] = useState<string>(structureFilter.siret);
	const [emailFilter, setEmailFilter] = useState<string>(structureFilter.email);
	const [statutFilter, setStatutFilter] = useState<string>(structureFilter.statut);
	const [responsableFilter, setResponsableFilter] = useState<string>(structureFilter.responsable);

	/** Vues vars */
	const [vueName, setVueName] = useState<string>("");
	const [vueEstPartagee, setVueEstPartagee] = useState<boolean>(false);
	const saveVueOptionDefault = [
		{
			value: "Enregistrer",
			label: "Enregistrer la vue courante",
			icon: "far fa-plus",
			button: <></>,
			hasClass: "inputSelect__option--red"
		},
		{
			value: "default",
			label: "Vue par défaut"
		}
	];
	const [vuesOptions, setVuesOptions] = useState<AdelOption<string>[]>(saveVueOptionDefault);
	const [vueSelected, setVueSelected] = useState<string>("default");
	const [vueToDeleteSelected, setVueToDeleteSelected] = useState<VueDto>();

	/** selectors & dispatch */	
	const vueSelector = useVueSelector();
	const dispatch = useDispatch();
	const vueDispatch = useVueDispatcher();

	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "Nom de la structure", accessor: "Nom", sortType: "basic" },
					{ Header: "Code postal", accessor: "CodePostal", sortType: "basic" },
					{ Header: "Code compte", accessor: "UserName", sortType: "basic" },
					{ Header: "Date de la demande", accessor: "Date", sortType: "basic" },
					{ Header: "Statut", accessor: "Statut", sortType: "basic" },
					{ Header: "Dossiers", accessor: "DossiersCount", sortType: "basic",
						Cell: (value: any) => {
							if(value.value === 0)
								return value.value
							else
								return <a href={`/Gestion/Structure/Dossiers/${value.cell.row.values.Id}`} target="_blank" rel="noopener noreferrer">{value.value}</a>
						}
					},
					{ accessor: "Id" }
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchStructures = useMemo(() => _.debounce((nomFilter: string, siretFilter: string, emailFilter: string, statutFilter: string, usernameFilter: string, responsableFilter: string, page: number, pageSize: number, sort: string) => {
		fetchStructures(dispatch, false, nomFilter, siretFilter, emailFilter, statutFilter == "all" ? "" : statutFilter, usernameFilter, responsableFilter, page, pageSize, sort)().then((result: any) => {
			if (result?.payload?.number > result?.payload?.totalPageCount)
				setPage(1);
			setStructures(result?.payload?.items);
			setHasNext(result?.payload?.hasNext);
			setHasPrevious(result?.payload?.hasPrevious);
			setIsFirst(result?.payload?.isFirst);
			setIsLast(result?.payload?.isLast);
			setTotalPageCount(result?.payload?.totalPageCount);
			setTotalItemCount(result?.payload?.totalItemCount);
		});
	}, 1000), []);

	/** Get the structures list */
	useEffect(() => {
		if (userSelector && userSelector.isConnected) {
			debounceFetchStructures(nomFilter, siretFilter, emailFilter, statutFilter, usernameFilter, responsableFilter, page, pageSize, sort)
		}
	}, [nomFilter, siretFilter, emailFilter, statutFilter, usernameFilter, responsableFilter, page, pageSize, sort, fetchStructures, userSelector.isConnected]);


	/** Get the data for the table */
	useEffect(() => {
		const data = structures?.map(structure => ({
			Nom: (<Link 
					to={`/Structure/${structure.id}`} 
					onClick={()=> dispatch(updateFilter(
						{	
							nom: nomFilter, 
							username: usernameFilter, 
							email: emailFilter, 
							statut: statutFilter,
							responsable: responsableFilter
						})
					)}>{structure.nom}</Link>),
			UserName: structure.userName,
			CodePostal: structure.codePostal,
			 Statut: <span className={`table__statutItem table__statut--${structure.isModified ? 'activePartial' : structure.statut}`}>{t(`structure.status.${structure.statut}`)}</span>,
			Date: formaterDate(structure.date),
			DossiersCount: structure.dossiersCount,
			Id: structure.id
		}));
		setData(data);
	}, [structures]);


	/** Get the vues list */
	useEffect(() => {
		if (userSelector && userSelector.isConnected) {
			fetchVues(vueDispatch, VueType.Structure)().then((result: any) => {
				setVues(result?.payload);
				vueOptionsList(result?.payload);
			});
		}
	}, [fetchVues, userSelector.isConnected]);

	const vueOptionsList = (vues: VueDto[]) => {
		let options = saveVueOptionDefault;
		if (vues.length > 0) {
			vues.map(vue =>
				options.push(
					{
						value: vue.filtre ? vue.filtre : '',
						label: vue.nom ? vue.nom : '',
						icon: vue.estPartagee ? "fas fa-share" : '',
						button: <div className="filtres__inputSelectButtons">
							<button onClick={() => { handleMessageModalOpener(vue) }}><i className="far fa-envelope"></i></button>
							<button onClick={() => { handleDeleteVueModalOpener(vue) }}><i className="far fa-trash-alt"></i></button>
						</div>,
						hasClass: '',
					}
				)
			);
		}
		setVuesOptions(options);
	};


	/** Modal */
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [modalDeleteVueIsOpen, setModalDeleteVueIsOpen] = useState<boolean>(false);
	const [modalMessageVueIsOpen, setModalMessageVueIsOpen] = useState<boolean>(false);

	const resetFilterFields = () => {
		setNomFilter("");
		setEmailFilter("");
		setUsernameFilter("");
		setStatutFilter("all");
		setResponsableFilter("");
		setSiretFilter("")
	}

	// Ajouter une vue
	const handleSendVue = () => {
		if (vueName && (nomFilter || emailFilter || statutFilter || usernameFilter || responsableFilter)) {
			const vueToSend: VueDto = {
				type: VueType.Structure,
				nom: vueName,
				estPartagee: vueEstPartagee,
				filtre: `${nomFilterKey}${nomFilter},${emailFilterKey}${emailFilter},${statutFilterKey}${statutFilter},${userNameFilterKey}${usernameFilter},${responsableFilterKey}${responsableFilter}`
			}

			saveVue(vueDispatch, vueToSend)().then((result: any) => {
				let vuesArray = vues;
				vuesArray.push(result?.payload);

				setVues(vuesArray);
				vueOptionsList(vuesArray);
				setVueSelected(result.payload.filtre);
				setVueEstPartagee(false);
			});

			if (!vueSelector.vueIsLoading) {
				setModalIsOpen(false);
			}
		}
	};

	// Supprimer une vue
	const handleDeleteVueModalOpener = useCallback((selectedVue) => {
		if (selectedVue) {
			setVueToDeleteSelected(selectedVue);
			setModalDeleteVueIsOpen(true);
		}
	}, []);

	const handleDeleteVue = () => {
		deleteVue(vueDispatch, vueToDeleteSelected?.id ? vueToDeleteSelected?.id : '')().then(() => {
			let newVueArray = vues.filter(vue => vue.id !== vueToDeleteSelected?.id)
			setVues(newVueArray);
			vueOptionsList(newVueArray);
		});

		if (!vueSelector.vueDeleteIsLoading) {
			setVueSelected("default");
			resetFilterFields();
			setModalDeleteVueIsOpen(false);
		}
	};
	
	// Envoyer un message groupé
	const handleMessageModalOpener = useCallback((selectedVue) => {
		if (selectedVue) {
			setVueSelected(selectedVue);
			setModalMessageVueIsOpen(true);
		}
	}, []);

	const resetMessageModal = () => {
		setModalMessageVueIsOpen(false);
		setEditorValueContent('');
		setFiles([]);
	}

	const handleSendMessage = async () => {
		try {
			await sendStructureMultipleMessages(
				dispatch,
				editorValueContent ? editorValueContent : "<p></p>",
				files.map(file => { return {
					data: file,
					fileName: file.name
				}}),
				{
					[emailFilterKey]: emailFilter,
					[nomFilterKey]: nomFilter,
					[responsableFilterKey]: responsableFilter,
					[userNameFilterKey]: usernameFilter,
					[siretFilterKey]: siretFilterKey,
					[statutFilterKey]: statutFilter === "all" ? "" : statutFilter
				}
			);

		} catch(error) {
			toast.error("Une erreur est survenue à l'envoi du message");
		}
		resetMessageModal();
	}

	const [editorValueContent, setEditorValueContent] = useState<string>('');
	const [files, setFiles] = useState<Array<File>>([]);
	const [messageObjectSelect, setMessageObjectSelect] = useState<ObjetDiscussion>(ObjetDiscussion.InformationGeneral);
	const optionsUtilisateur = () => {
		const allOptions = Object.keys(ObjetDiscussion).filter(x => x !== 'None').map(x => ({
			value: ObjetDiscussion[x as keyof typeof ObjetDiscussion],
			label: t(`objetDiscussion.${x}`)
		}));
		return allOptions;
	}
	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
		if (value && value.target && value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
			setFiles([...files, value.target.files[0]]);
		}
	}
	const deleteFile = (file: File) => {
		if (files.length > 0) {
			let array = files.filter(f => f.name !== file.name);
			return setFiles(array);
		}
	}


	// Modifier les champs quand on a sélectionné un filtre
	const handleInputSelectChange = (selectedOption: any) => {
		if (selectedOption == "Enregistrer") {
			setModalIsOpen(true);
		} else if (selectedOption == "default") {
			resetFilterFields();
		} else {
			const filters = selectedOption.split(',');
			resetFilterFields();

			filters.map((filter: string) => {

				if (filter.match(new RegExp(`^${nomFilterKey}`))) {
					setNomFilter(filter.substring(nomFilterKey.length));
				}

				if (filter.match(new RegExp(`^${emailFilterKey}`))) {
					setEmailFilter(filter.substring(emailFilterKey.length));
				}

				if (filter.match(new RegExp(`^${statutFilterKey}`))) {
					setStatutFilter(filter.substring(statutFilterKey.length));

				}

				if (filter.match(new RegExp(`^${userNameFilterKey}`))) {
					setUsernameFilter(filter.substring(userNameFilterKey.length));
				}

				if (filter.match(new RegExp(`^${responsableFilterKey}`))) {
					setResponsableFilter(filter.substring(responsableFilterKey.length));
				}
				if (filter.match(new RegExp(`^${siretFilterKey}`))) {
					setSiretFilter(filter.substring(siretFilterKey.length));
				}
			});
		}
	};


	return (
		<PagesContainer title="Gestion - Structures">
			<>
				<div className="filtres">
					<Filters
						nom={nomFilter}
						email={emailFilter}
						statut={statutFilter}
						username={usernameFilter}
						responsable={responsableFilter}
						siret={siretFilter}
						setNom={setNomFilter}
						setEmail={setEmailFilter}
						setStatut={setStatutFilter}
						setUsername={setUsernameFilter}
						setResponsable={setResponsableFilter}
						setSiret={setSiretFilter}
					/>

					{
						<InputSelect
							name="vueSelect"
							classname="filtres__inputSelect inputSelect--large inputSelect--right"
							options={vuesOptions}
							onChange={handleInputSelectChange}
							value={vueSelected}
						/>
					}
				</div>

				{isLoading &&
					<div className="loaderBlock">
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						></Loader>
					</div>
				}

				<Table
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.page}
				/>

				<Pagination
					hasNext={hasNext}
					hasPrevious={hasPrevious}
					isFirst={isFirst}
					isLast={isLast}
					totalPageCount={totalPageCount}
					totalItemCount={totalItemCount}
					page={setPage}
					pageSize={setPageSize}
					initPageSize={pageSize}
					initPage={page}
					pageNumbers={paginationNumbers}
				/>

				<Modal isOpen={modalIsOpen} style={modalCustomStyles} className="normal__modal" >
					<div className="modal__header"><h3>Enregistrer la vue courante</h3></div>
					<div className="modal__content">
						<div className="modal__item">
							<Input name="newVue" label={'Nom de ma nouvelle vue'} type={'text'} onChange={(value: string) => setVueName(value)} />
						</div>
						<div className="modal__item">
							<Toggle label="Partager ma vue" value={vueEstPartagee} setCheck={setVueEstPartagee} />
						</div>
					</div>
					<div className="modal__footer">
						<FormButton type="button" value="Annuler" onClick={() => { setModalIsOpen(false) }} />
						{vueSelector.vueIsLoading
							? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
							: <FormButton type="submit" value="Enregistrer" onClick={handleSendVue} />
						}
					</div>
				</Modal>
					
				<Modal isOpen={modalDeleteVueIsOpen} style={modalCustomStyles} className="normal__modal" >
					<div className="modal__header"><h3>Supprimer la vue</h3></div>
					<div className="modal__content">
						<div className="modal__item">
							Voulez-vous vraiment supprimer la vue "{vueToDeleteSelected?.nom}" ?
						</div>
					</div>
					<div className="modal__footer">
						<FormButton type="button" value="Annuler" onClick={() => { setModalDeleteVueIsOpen(false) }} />
						{vueSelector.vueDeleteIsLoading
							? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
							: <FormButton type="submit" value="Supprimer" onClick={handleDeleteVue} />
						}
					</div>
				</Modal>

				<Modal isOpen={modalMessageVueIsOpen} style={modalCustomStyles} className="normal__modal modal--large" >
					<div className="modal__header"><h3>Démarrer une discussion de masse</h3></div>
					<div className="modal__content">
						<div className="modal__item modal__itemSelect">
							<InputSelect name="" classname="inputSelect" label="Type d'objet"
								readonly={true}
								options={optionsUtilisateur()}
								onChange={(elem) => { setMessageObjectSelect(elem) }}
								value={messageObjectSelect}
							/>
						</div>
						<div className="modal__item">
							<WysiwygEditor
								editorValueContent={editorValueContent}
								setEditorValueContent={setEditorValueContent}
							/>
						</div>
						<div className="modal__item item--flexBetween">
							{files && files.length > 0
								? <div className="modal__itemFiles">
									<label>Pièces jointes :</label>
									{files.map((file, i) => {
										return <span key={i}>
											{file.name}
											<i className="far fa-trash-alt" onClick={() => deleteFile(file)}></i>
											{files.length === 1 || files.indexOf(file) === files.length - 1 ? '' : ', '}
										</span>
									})}
								</div>
								: <div></div>
							}
							<div className="inputFile__singleButton">
								<label htmlFor="uploadDocument"><i className="fas fa-paperclip"></i></label>
								<input id="uploadDocument" type="file" onChange={handleFileChange} />
							</div>
						</div>
					</div>
					<div className="modal__footer">
						<FormButton type="button" value="Annuler" onClick={resetMessageModal} />
						{isLoading
							? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
							: <FormButton type="submit" value="Envoyer" onClick={handleSendMessage} />
						}
					</div>
				</Modal>
			</>
		</PagesContainer>
	);
}

export default StructuresTable;