import { Reducer } from "redux";
import { GeneralState, initialGeneralState } from '../states/generalState';
import { DispatchGeneralAction, GeneralActionsEnum } from '../actions/generalAction';

export const generalReducer: Reducer<Partial<GeneralState>, DispatchGeneralAction> = (state = initialGeneralState, action) => {
    switch (action.type) {
        case GeneralActionsEnum.TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen: state.isMenuOpen ? false : true
            };
        case GeneralActionsEnum.CLOSE_MENU:
            return {
                ...state,
                isMenuOpen: false
            }
        default:
            return state;
    }
};
