import { TFunction } from 'i18next';
import React from 'react';
import { capitalize, downloadFileFromUri } from "./functions"

export interface HistoryElement {
	label: string;
	data?: any;
}

export const hasModif = (key: string, before: any, after: any, display?: (obj: any) => string) => {
    const displayValue = display == null ? (obj: any) => (obj + "") : display;

    if (!before) return `<b>${displayValue(after)}</b> a été ajouté dans <b>${capitalize(key)}</b>`;
    if (!after) return `<b>${displayValue(before)}</b> a été retiré de <b>${capitalize(key)}</b>`;

    return `<b>${capitalize(key)}</b> a été modifié (avant: <b>${displayValue(before)}</b> / après: <b>${displayValue(after)}</b>)`;
};

export const objectHasModif = (name: string, before: any, after: any, t: TFunction, array: string[] = []) => {
    // si tout un objet a été ajouté, les champs remplis ne sont pas précisés
    if (before == null) array.push(`<b>${name}</b> a été ajouté`);
    if (after == null) array.push(`<b>${name}</b> a été retiré`);

    if (before != null && after != null) {
        const allKeys = new Set(Object.keys(before).concat(Object.keys(after)));

        for (const key of allKeys) {
            const valueBefore = before[key];
            const valueAfter = after[key];

            if (valueBefore != null && valueAfter != null) {
                array.push(`<b>${capitalize(t(`history.${key}`))}</b> a été modifié de <b>${capitalize(key)}</b> (avant: <b>${valueBefore}</b> / après: <b>${valueAfter}</b>)`);
            } else if (valueBefore != null) {
                array.push(`<b>${capitalize(t(`history.${key}`))}</b> a été retiré de <b>${capitalize(key)}</b>`);
            } else if (valueAfter != null) {
                array.push(`<b>${capitalize(t(`history.${key}`))}</b> a été ajouté dans <b>${capitalize(key)}</b> : <b>${valueAfter}</b>`);
            }
        }
    }

    return  [...new Set(array)];
};

export const dicoHasModif = (name: string, before: { [key: string]: any; }, after: { [key: string]: any; }, display: string | ((obj: any) => string), array: HistoryElement[] = []) => {
    const displayValue = display == null ? (obj: any) => (obj + "") : (typeof display === "string" ? (obj: any) => (obj && obj[display]) : display);
    const allKeys = new Set(Object.keys(before).concat(Object.keys(after)));

    for (const key of allKeys) {
        const valueBefore = before[key];
        const valueAfter = after[key];

        if (valueBefore == null) {
            array.push({ label:`<b>${displayValue(valueAfter)}</b> a été rajouté dans <b>${capitalize(name)}</b>`, data: valueAfter });
        } else {
            array.push({ label:`<b>${displayValue(valueBefore)}</b> a été retiré de <b>${capitalize(name)}</b>`, data: valueBefore });
        }
    }

    return  [...new Set(array)];
};

export const renderHistoryValue = (value: any): JSX.Element => {
    return value ? (
        <p dangerouslySetInnerHTML={{__html: value}}></p>
    ) : <></>;
};

export const renderHistoryObject = (values: string[] | undefined): JSX.Element[] => {
    return values && values.length > 0 ? values.map((item, i) => (
        <p dangerouslySetInnerHTML={{__html: item}} key={i}></p>
    )) : [];
};

export const renderHistoryDico = (values: HistoryElement[] | undefined): JSX.Element[] => {
    return values && values.length > 0 ? values.map((item, i) => {
        if (item.data?.uri) {
            return (
                <p key={i}>
                    <a href={item.data.uri} target="_blank" onClick={e => { e.preventDefault(); downloadFileFromUri(item.data.uri, item.data.fileName); }}>
                        <span dangerouslySetInnerHTML={{__html: item.label}}></span>
                    </a>
                </p>
            );
        }

        return (<p dangerouslySetInnerHTML={{__html: item.label}} key={i}></p>);
    }) : [];
};
