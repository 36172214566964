import Input from 'adel-shared/dist/components/basics/Input';
import Pagination from 'adel-shared/dist/components/basics/Pagination';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { RouteComponentProps, navigate, useParams, Link } from '@reach/router';
import { modalCustomStyles, paginationNumbers } from '../../../constants/config.constant';
import { StatutCommission } from '../../../services/generated/BackOffice-api';
import { closeSessionCA, fetchSessionCADossiers, sendMontantCA } from '../../../store/commission-store/actions/commissionCAActions';
import { useCommissionSelector } from '../../../store/store-helpers';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';

interface ListeDossiersSessionCAProps extends RouteComponentProps {
}

const ListeDossiersSessionCA: React.FunctionComponent<ListeDossiersSessionCAProps> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { getValues, setValue, control, errors } = useForm<any>();
	const location = useParams();
	const id = location.commissionId;
	

	/** Permissions */
	const usePermUpdateDossierMontantCA = useHasPermission("UpdateDossierMontantCA");
	const usePermCloseSessionCA = useHasPermission("CloseSessionCA");


	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [disablePagination, setDisablePagination] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);
	const [isMontantLoading, setIsMontantLoading] = useState<boolean>(false);

	/** Table */
	const {sessionCADossiers, isDossierLoading, isLoading} = useCommissionSelector();
	const [data, setData] = useState<Array<any>>([]);
	const [sort, setSort] = useState<string>("");
	const {dateClotureCommissionCA, statutCommission} = sessionCADossiers?.items?.[0] || {};

	useEffect(() => {
		if (sessionCADossiers?.items) {
			if (sessionCADossiers.number &&
			sessionCADossiers.totalPageCount &&
			(sessionCADossiers.number > sessionCADossiers.totalPageCount)) {
				setPage(1);
			}
			setHasNext(sessionCADossiers.hasNext ?? false);
			setHasPrevious(sessionCADossiers.hasPrevious ?? false);
			setIsFirst(sessionCADossiers.isFirst ?? false);
			setIsLast(sessionCADossiers.isLast ?? false);
			setTotalPageCount(sessionCADossiers.totalPageCount ?? 0);
			setTotalItemCount(sessionCADossiers.totalItemCount ?? 0);

			setData(sessionCADossiers.items.map(com => ({
				Numero: com.numero,
				RaisonSociale: com.raisonSociale,
				Nom: com.nom,
				MontantDemande: com.montantDemande ? formatNumber(com.montantDemande) + ' €' : '- €',
				MontantAttribue: com.montantAttribue,
				MontantCA: com.montantCA,
				Id: com.id,
				valideEnCommission: com.valideEnCommission,
				StatutCommission: com.statutCommission
			})));
		}
	}, [sessionCADossiers]);

	useEffect(() => {
		if(data.length && sessionCADossiers?.items?.length) {
			setValue(sessionCADossiers.items.map(com => ({
				[`${com.id}`]: com.montantCA	
			})));
		}
	}, [
		data,
		sessionCADossiers
	]);

	/** Envoi de l'input d'un montant */
	const submit = async (id: string) => {
		try {
			await sendMontantCA(dispatch, id, { montant: parseFloat(getValues(id)) });
		} catch(error) {
		}
	};

	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "N° dossier", accessor: "Numero", sortType: "basic" },
					{ Header: "Nom structure", accessor: "RaisonSociale", sortType: "basic" },
					{ Header: "Nom dossier", accessor: "Nom", sortType: "basic",
						Cell: (value:any) => {
							if(value.cell.row.values.StatutCommission !== StatutCommission.Planifiee && value.cell.row.values.StatutCommission !== StatutCommission.None )
								return <Link to={`/Commission/${id}#${value.cell.row.values.Id}`}>{value.value}</Link>
							return value.value;
						}
					},
					{ Header: "Montant demandé", accessor: "MontantDemande", sortType: "basic" },
					{ Header: "Montant accordé", accessor: "MontantAttribue", sortType: "basic",
						Cell: (value:any) => {
							return <span>{value.value ? formatNumber(value.value) + ' €' : '- €'}</span>
						}
					},
					{ Header: "Montant CA", accessor: "MontantCA", disableSortBy: true,
						Cell: (value: any) => (
							value.cell.row.values.valideEnCommission && (
								<span className="commissionTable__input">
									{(
										usePermUpdateDossierMontantCA &&
										!dateClotureCommissionCA &&
										value.cell.row.values.MontantAttribue > 0
									) ? (
										<Controller
											control={control}
											name={value.cell.row.values.Id}
											as={({name, onChange, value}) => (
												<Input
													name={name}
													onChange={onChange}
													value={value}
													label=""
													type="number"
													errors={errors}
												/>
											)}
										/>
									) : (
										<>{value.value}</>
									)}
								</span>
						))},
					{ Header: "", accessor: "Actions", disableSortBy: true,
						Cell: (value: any) => (
							(
								usePermUpdateDossierMontantCA &&
								!dateClotureCommissionCA &&
								value.cell.row.values.valideEnCommission
							) && (
								<span className="commission__tableButtons">
									{isMontantLoading ? (
										<Loader
											type="TailSpin"
											width={35}
											height={35}
											color="#d93943"
										/>
									) : (
										<FormButton
											type="button"
											value="Valider"
											onClick={() => submit(value.cell.row.values.Id)}
										/>
									)}
								</span>
							)
					)},
					{ accessor: "Id" },
					{ accessor: "valideEnCommission" },
					{ accessor: "StatutCommission" }
				]
			}
		];
	}, [
		usePermUpdateDossierMontantCA,
		isMontantLoading,
		dateClotureCommissionCA
	]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id", "valideEnCommission", "StatutCommission"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);



	/** Filtres */
	const [nomFiltre, setNomFiltre] = useState<string>("");



	/** Cloture session */
	const [isModalOpen, showModal, closeModal] = useBooleanState();
	const cloturerSession = useCallback(async () => {
		try {
			await closeSessionCA(dispatch, id);
			navigate('/Commission/SessionCA');
		} catch (error) {
		}
	}, [closeSessionCA, navigate, dispatch]);



	/** Get commissions */
	useEffect(() => {
		(async () => {
			await debounceFetchSessionsCA(nomFiltre, page, pageSize, sort);
		})()
	}, [nomFiltre, page, pageSize, sort]);

	const debounceFetchSessionsCA  = useMemo(() => _.debounce(async (nomFiltre:string, page:number, pageSize:number, sort:string) => {
		try {
			await fetchSessionCADossiers(dispatch, id, nomFiltre, page, pageSize, disablePagination, sort);
		} catch (error) {
			toast.error(t('common.errors.fetch'));
		}
	}, 1000), []);

	return (
		<div className="commission listeDossiersCA">
			<div className="commission__header"></div>
			<div className="commission__content">
				<div className="commission__contentHeader">
					<h1 className="commission__contentHeaderTitle">Liste des dossiers de la session CA</h1>
					{(
						usePermCloseSessionCA &&
						!sessionCADossiers?.items?.some(d => d.montantCA === null) &&
						!sessionCADossiers?.items?.some(d => !d.valideEnCommission) &&
						!dateClotureCommissionCA &&
						(
							statutCommission === StatutCommission.EnCours || 
							statutCommission === StatutCommission.CaEnCours
						)
					) && (
						<FormButton type="button" value="Clôturer la session" onClick={showModal} />
					)}
				</div>
				<div className="commission__filtres">
					<div className="filtres__item">
						<div className="filtres__search">
							<i className="fas fa-search"></i>
							<Input
								autoComplete="off"
								name="recherche"
								reference={null}
								className="filter__input"
								placeHolder="Recherche"
								type="text"
								maxLength={120}
								onChange={(value: string) => setNomFiltre(value)}
							/>
						</div>
					</div>
				</div>
				<div className="listeDossiersCA__table">
					{isDossierLoading ? (
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						/>
					) : (
						<>
							<Table
								data={data}
								getTableProps={getTableProps}
								getTableBodyProps={getTableBodyProps}
								headerGroups={headerGroups}
								rows={rows}
								prepareRow={prepareRow}
								styles={styleTable.page}
							/>
							<Pagination
								hasNext={hasNext}
								hasPrevious={hasPrevious}
								isFirst={isFirst}
								isLast={isLast}
								totalPageCount={totalPageCount}
								totalItemCount={totalItemCount}
								page={setPage}
								pageSize={setPageSize}
								initPageSize={pageSize}
								initPage={page}
								pageNumbers={paginationNumbers}
							/>
						</>
					)}
				</div>
				{usePermCloseSessionCA &&
					<Modal
						isOpen={isModalOpen}
						style={modalCustomStyles}
						className="normal__modal membresModal"
					>
						<>
							<div className="modal__header">
								<h3>Attention</h3>
							</div>

							<div className="modal__content">
								Souhaitez-vous clôturer la session ?
							</div>

							<div className="modal__footer">
								<FormButton
									type="button"
									value="Annuler"
									onClick={closeModal}
								/>
								{isLoading ? (
									<Loader
										type="TailSpin"
										width={35}
										height={35}
										color="#d93943"
									/>
								) : (
									<FormButton
										type="submit"
										value="Continuer"
										onClick={cloturerSession}
									/>
								)}
							</div>
						</>
					</Modal>
				}
			</div>
		</div>
	);
}

export default ListeDossiersSessionCA;
