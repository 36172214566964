import React from 'react';
import Input from 'adel-shared/dist/components/basics/Input';
import { ValidationOptions } from 'react-hook-form';

interface EditDocumentInfoSaisiesProps {
    register: any;
    errors: any;
    count: number;
    labelsValidation: ValidationOptions;
}

const EditDocumentInfoSaisies: React.SFC<EditDocumentInfoSaisiesProps> = ({
    register,
    errors,
    count,
    labelsValidation
}) => {

    const infoSaisieInputs = [];

    // ADD '' or '- 1' to label
    const getLabelSuffix = (count: number, index: number) => {
        if (count <= 1) {
            return '';
        } else {
            return `- ${index}`;
        }
    }

    for (let i = 1; i <= count; i++) {
        infoSaisieInputs.push(<span key={i}>
            <input type="hidden" name={`infoSaisieCode${i}`} ref={register} />
            <div className="modal__item">
                <Input
                    name={`infoSaisieLabel${i}FR`}
                    reference={register(labelsValidation)}
                    errors={errors}
                    label={`Libellé du champs de saisie FR ${getLabelSuffix(count, i)}`}
                    type={'text'}
                />
            </div>
            <div className="modal__item">
                <Input
                    name={`infoSaisieLabel${i}EN`}
                    reference={register(labelsValidation)}
                    errors={errors}
                    label={`Libellé du champs de saisie EN ${getLabelSuffix(count, i)}`}
                    type={'text'}
                />
            </div>
        </span>)
    }

    return (<>{infoSaisieInputs}</>);
}

export default EditDocumentInfoSaisies;