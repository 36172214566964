import React, { useState, useEffect, FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';
import PagesContainer from "../../containers/PagesContainer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useRemunerationSelector } from '../../store/store-helpers';
import GnenerationSpedidamList from './generation-spedidam/GnenerationSpedidamList';
import { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { useDispatch } from 'react-redux';
import { fetchStylesMusicaux } from '../../store/remuneration-store/actions/remunerationAction';
import { StyleMusicalDto } from '../../services/generated/BackOffice-api';
import useHasPermission from '../../custom-hooks/useHasPermission';


interface GestionEntite extends RouteComponentProps {
}
const GestionEntite: FunctionComponent<GestionEntite> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //selector
    const remunerationSelector = useRemunerationSelector();
    const [tabIndex, setTabIndex] = useState<number>(0);

    const [typeMusicalOptions, setTypeMusicalOptions] = useState<AdelOption<string>[]>([]);
    const [modalAddIsOpen, setModalAddIsOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchStylesMusicaux(dispatch)();
    }, [])

    useEffect(() => {
        if (remunerationSelector.stylesMusicaux) {
            let filteredStylesMusicaux =  [...remunerationSelector.stylesMusicaux as StyleMusicalDto[]].filter(type => type.code?.includes('classique') || type.code?.includes('jazz') || type.code?.includes('variete'))
            let musiques: any = [...filteredStylesMusicaux as StyleMusicalDto[]];
            musiques.forEach(function (element: any) {
                element.value = element.id;
                element.label = element.intitule.fr;
            })      
            
            setTypeMusicalOptions(musiques);
        }
    }, [remunerationSelector.stylesMusicaux])

    const openAddModal = () => {
        setModalAddIsOpen(true);
    }
    
    /** Permissions */
    const usePermCreateGenerationsSpedidam = useHasPermission("CreateGenerationSpedidam");
    const usePermGetGenerationsSpedidam = useHasPermission("GetGenerationsSpedidam");
    return (
        <PagesContainer title="Administration - Gestion des entités" pageClass="categoriesFormeJuridique">
            <Tabs className="react-tabs--page" onSelect={index => setTabIndex(index)}>
                <div className="gestionCategoriesDossier__header">
                    <TabList>
                        {usePermGetGenerationsSpedidam &&
                            <Tab>{t('entity-management.tabs.spedidam-generation')}</Tab>
                        }
                    </TabList>
                    {usePermGetGenerationsSpedidam && tabIndex === 0 && (
                        <div className="gestionCategoriesDossier__filters_and_button">                      
                            {usePermCreateGenerationsSpedidam &&
                                <div className="categorieFormeJuridique-header__button" onClick={openAddModal}>
                                    <label>Ajouter un nouvel artiste</label>
                                    <button className="categorieFormeJuridique-header__icon">
                                        <i className="far fa-plus"></i>
                                    </button>
                                </div>
                            }
                        </div>
                    )}                  
                </div>
                {usePermGetGenerationsSpedidam &&
                    <TabPanel>
                        <GnenerationSpedidamList
                            modalAddIsOpen={modalAddIsOpen}
                            setModalAddIsOpen={setModalAddIsOpen}
                            typeFilter={"all"}
                            typeMusicalOptions={typeMusicalOptions}
                        />
                    </TabPanel>
                }
            </Tabs>
        </PagesContainer>
    );
}
export default GestionEntite;