import React, { useState, useEffect } from "react";
import { Controller, ErrorMessage } from "react-hook-form";
const ControlledInputRadioButton = (props) => {
    const [isChecked, setIsChecked] = useState(props.value);
    useEffect(() => {
        setIsChecked(props.value);
    }, [props.value]);
    const handleClick = (value) => {
        if (!props.isDisabled) {
            setIsChecked(value);
        }
    };
    return (React.createElement("div", { className: "inputGroup" },
        React.createElement("label", { className: "input__label" }, props.label),
        React.createElement(Controller, { name: props.name, control: props.control, rules: props.rules, defaultValue: props.value, as: ({ onChange }) => (React.createElement("div", { className: "inputRadioButton" },
                React.createElement("label", { htmlFor: props.name, className: "inputRadioButton__label", onClick: () => {
                        onChange(true);
                        handleClick(true);
                    } },
                    isChecked ? React.createElement("i", { className: "fa fa-check-circle" }) : React.createElement("i", { className: "far fa-circle" }),
                    " Oui"),
                React.createElement("label", { htmlFor: props.name, className: "inputRadioButton__label", onClick: () => {
                        onChange(false);
                        handleClick(false);
                    } },
                    !isChecked ? React.createElement("i", { className: "fa fa-check-circle" }) : React.createElement("i", { className: "far fa-circle" }),
                    " Non"))) }),
        props.errors &&
            React.createElement(ErrorMessage, { errors: props.errors, name: props.name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message))));
};
export default ControlledInputRadioButton;
