import React, { FunctionComponent } from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import BordereauVersement from './BordereauVersement';
import { TypeBordereau } from '../../services/generated/BackOffice-api';
import BordereauReaffectation from './BordereauReaffectation';

interface BordereauProps extends RouteComponentProps {
}

const Bordereau: FunctionComponent<BordereauProps> = () => {
    const { bordereauType, bordereauId } = useParams();

    switch(bordereauType) {
        case TypeBordereau.Versement:
            return (
                <BordereauVersement
                    bordereauId={bordereauId}
                />
            );
        case TypeBordereau.Reaffectation:
            return (
                <BordereauReaffectation
                    bordereauId={bordereauId}
                />
            );
        default:
            return null;
    }
};

export default Bordereau;