import React, { ChangeEvent, useEffect, useState } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { fetchDossierNote, sendDossierNote } from '../../../store/dossier-store/actions/dossierNoteActions';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useDossierSelector } from '../../../store/store-helpers';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import Commentaires from 'adel-shared/dist/components/DossierDetails/Commentaires';

interface DossierNoteTabProps {
	dossierId: string | undefined;
}

const DossierNoteTab: React.FC<DossierNoteTabProps> = ({ dossierId }) => {
	const {dossierNote, isLoading} = useDossierSelector();
	const dispatch = useDispatch();
	const [commentaires, setCommentaires] = useState('');
	const [note, setNote] = useState(0);

	useEffect(() => {
		dossierId && fetchDossierNote(dispatch, dossierId)()
	}, [fetchDossierNote]);

	useEffect(() => {
		if (dossierNote?.note === 0)
			setNote(dossierNote.note)
		else if(dossierNote?.note)
			setNote(dossierNote.note)
		
		if (dossierNote?.commentairesNote === "")
			setCommentaires(dossierNote.commentairesNote)
		else if(dossierNote?.commentairesNote)
			setCommentaires(dossierNote.commentairesNote)

	}, [dossierNote]);

	
	const upgradeNote = () => {
		if (note < 20) {
			setNote(note + 4)
		}
	}

	const downgradeNote = () => {
		if (note > 0) {
			setNote(note - 4)
		}
	}

	const send = () => {
		dossierId && sendDossierNote(dispatch, dossierId, {
			note: note,
			commentairesNote: commentaires
		})();
	}

	
	/** Permissions */
	const usePermUpdateDossierNote = useHasPermission("UpdateDossierNote");

	return (
		<div className="detailsTab">
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Commentaire général</h3>
				<Commentaires commentaire={commentaires} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentaires(e.target.value)} />
			</div>
			<div className="detailsTab__section">
				<h3 className="detailsTab__title">Mettez une note au dossier</h3>
				<div className="detailsTab__item">
					<div className="counter">
						<div className={note > 0 ? "counter__icon" : "counter__icon--disabled"} onClick={downgradeNote}>
							<i className="fas fa-minus-circle"></i>
						</div>
						<div className="counter__item">
							<div className="counter__input">{note}</div>
							<div className="counter__separator"></div>
							<div className="counter__scale">20</div>
						</div>
						<div className={note < 20 ? "counter__icon" : "counter__icon--disabled"} onClick={upgradeNote}>
							<i className="fas fa-plus-circle"></i>
						</div>
					</div>
				</div>
				{note >= 0 && note <= 20 ?
					<></>
					:
					<div className="detailsTab__error">
						<i className="far fa-exclamation-triangle"></i>
						<span>La note doit être comprise entre 0 et 20.</span>
					</div>
				}
			</div>
			<div className="detailsTab__footer">
				{isLoading
				&& <Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					></Loader>
				}
				{usePermUpdateDossierNote && <FormButton
					disabled={!isLoading && note >= 0 && note <= 20 ? false : true}
					type="submit"
					value={"Valider"}
					onClick={send}
				/>}
			</div>
		</div>
	);
}

export default DossierNoteTab;