import React, { useEffect, useState } from 'react';
import { TypePrestation } from '../../models/generated/BackOffice-api';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import ArtisteModalInfos from './artisteModal/ArtisteModalInfos';
import ArtisteModalMontantsTotaux from './artisteModal/ArtisteModalMontantsTotaux';
import ArtisteModalTable from './artisteModal/ArtisteModalTable';
import ArtisteModalTableSalaire from './artisteModal/ArtisteModalTableSalaire';
const ArtisteModal = ({ artiste, setArtiste, dossierCategorie, isOpen, isCommissionMember, close }) => {
    const { t } = useTranslation();
    const closing = () => {
        setArtiste(undefined);
        close();
    };
    const [allCachets, setAllCachets] = useState([]);
    const [salaires, setSalaires] = useState([]);
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);
    useEffect(() => {
        if (!artiste)
            return;
        const allCachets = artiste.cachets.reduce((accumulator, currentValue) => {
            let montantTotal;
            switch (currentValue.typePrestation) {
                case TypePrestation.Concert:
                    montantTotal = artiste.montantChargeConcert;
                    break;
                case TypePrestation.Representation:
                    montantTotal = artiste.montantChargeRepresentations;
                    break;
                case TypePrestation.Repetition:
                    montantTotal = artiste.montantChargeRepetitions;
                    break;
                case TypePrestation.Enregistrement:
                    montantTotal = artiste.montantChargeEnregistrement;
                    break;
            }
            const artisteWithType = accumulator.find(e => e.type === currentValue.typePrestation);
            if (artisteWithType)
                artisteWithType.cachets.push(currentValue);
            else
                accumulator.push({ type: currentValue.typePrestation, cachets: [currentValue], montantTotal });
            return accumulator;
        }, []) || [];
        setAllCachets(allCachets);
        const salairesTriDatesByType = artiste.salaires.reduce((accumulator, currentValue) => {
            const datesByPresta = currentValue.dates.reduce((acc, curr) => {
                const salaireWithType = acc.find(e => e.typePrestation === curr.typePrestation);
                if (salaireWithType)
                    salaireWithType.dates.push(curr.date);
                else
                    acc.push({ typePrestation: curr.typePrestation, dates: [curr.date] });
                return acc;
            }, []) || [];
            accumulator.push({
                id: currentValue.id,
                montantBrut: currentValue.montantBrut,
                tauxChargesPatronales: currentValue.tauxChargesPatronales,
                datesByType: datesByPresta
            });
            return accumulator;
        }, []) || [];
        setSalaires(salairesTriDatesByType);
    }, [artiste]);
    return (React.createElement(Modal, { isOpen: isOpen, style: {
            content: {
                overflow: 'visible',
                minWidth: '1000px'
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, .2)',
                zIndex: 10
            }
        }, className: "normal__modal artisteModal" },
        React.createElement("div", { className: "modal__header" },
            React.createElement("h3", null, "D\u00E9tails d'un artiste")),
        artiste && React.createElement(React.Fragment, null,
            React.createElement("div", { className: "modal__content" },
                React.createElement("div", { className: "artisteModal__section" },
                    React.createElement("h4", null, "Informations"),
                    React.createElement(ArtisteModalInfos, { details: artiste, dossierCategorie: dossierCategorie, isCommissionMember: isCommissionMember })),
                React.createElement("div", { className: "artisteModal__section" },
                    React.createElement("h4", null, "Montants totaux"),
                    React.createElement(ArtisteModalMontantsTotaux, { artiste: artiste })),
                allCachets.map((c, i) => React.createElement("div", { className: "artisteModal__section", key: 'cachet-' + i },
                    React.createElement("h4", null,
                        "Type ",
                        t(`dossier.prestation.${c.type}`)),
                    React.createElement(ArtisteModalTable, { data: c.cachets, montantTotal: c.montantTotal }))),
                (salaires === null || salaires === void 0 ? void 0 : salaires.length) > 0 &&
                    React.createElement("div", { className: "artisteModal__section" },
                        React.createElement("h4", null, "Salaire mensualis\u00E9"),
                        React.createElement(ArtisteModalTableSalaire, { salaires: salaires, montantChargeSalaire: artiste.montantChargeSalaire }))),
            React.createElement("div", { className: "modal__footer" },
                React.createElement("button", { type: "button", onClick: closing }, "Fermer")))));
};
export default ArtisteModal;
