import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import clsx from 'clsx';
import useOnClickOutside from 'use-onclickoutside';
import useHasPermission from '../../custom-hooks/useHasPermission';
import { StatutCommission } from '../../services/generated/BackOffice-api';
import ModalCloturerCommission from './modal/ModalCloturerCommission';
import ModalFigerCommission from './modal/ModalFigerCommission';

interface CommissionMenuTableProps extends RouteComponentProps {
	id: string;
	statut: StatutCommission;
	startComm: (id:string) => void;
	figerComm: (id:string) => void;
	dateDebut: Date;
	handleRefreshCommissionsList: () => void;
}

const CommissionMenuTable: React.FunctionComponent<CommissionMenuTableProps> = ({
	id,
	statut,
	startComm,
	figerComm,
	dateDebut,
	handleRefreshCommissionsList
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const refMenuWrapper = useRef() as React.MutableRefObject<HTMLInputElement>;
	const refMenu = useRef<HTMLDivElement>(null);
	useOnClickOutside(refMenuWrapper, () => setIsOpen(false));

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openFigerModal, setOpenFigerModal] = useState<boolean>(false);
	const [isTopPosition, setTopPosition] = useState<boolean>(false);

	const toggleOpen = useCallback(() => {
		setIsOpen(!isOpen);
	}, [setIsOpen,isOpen]);


	const handleOpen = () => {
		startComm(id);
		setIsOpen(false);
	};

	/** Permissions */
	const usePermFigeCommissionStatut = useHasPermission("FigeCommissionStatut");
	const usePermCloseCommission = useHasPermission("CloseCommission");
	const usePermGetCommissionDossiers = useHasPermission("GetCommissionDossiers");
	const usePermOpenCommission = useHasPermission("OpenCommission");

	useEffect(() => {
		const menuElem = refMenu.current;
		if(
			menuElem &&
			(menuElem.getBoundingClientRect().top + menuElem.offsetHeight) > window.innerHeight
		) {
			setTopPosition(true);
		}
	}, [isOpen]);

	return(
		<div className="commission__tableSelect" ref={refMenuWrapper}>
			<div className="commission__tableIcon" onClick={toggleOpen}>
				<i className="fas fa-ellipsis-v"></i>
			</div>
			{
				isOpen &&
				<div
					className={clsx(
						"commission__tableSelectContent",
						isTopPosition && 'commission__tableSelectContent--top'
					)}
					ref={refMenu}
				>
					<ul>
						<li>
							<Link to={`/Commission/Edit/${id}/informations`}>
								<i className="fas fa-edit"></i> Modifier
							</Link>
						</li>
						{usePermCloseCommission && (statut === StatutCommission.EnCours || statut === StatutCommission.CaEnCours) &&
							<li>
								<div onClick={() => setOpenModal(true)}><i className="fas fa-check"></i> Clôturer l'étude des dossiers</div>
							</li>
						}
						{usePermFigeCommissionStatut && (statut !== StatutCommission.EnCours && statut !== StatutCommission.Figee && statut !== StatutCommission.Terminee) &&
							<li>
								<div onClick={() => setOpenFigerModal(true)}><i className="fas fa-snowflake"></i> Figer la liste des dossiers</div>
							</li>
						}
						{usePermGetCommissionDossiers &&
							<li>
								<Link to={`/Commission/Dossiers/${id}`}>
									<i className="fas fa-eye"></i> Visualiser la liste des dossiers
								</Link>
							</li>
						}
						{usePermOpenCommission && (statut === StatutCommission.Figee) && 
						 new Date().getTime() >= new Date(dateDebut).getTime() &&
							<li>
								<div onClick={handleOpen}>
									<i className="far fa-folder-open"></i> Démarrer la commission 
								</div>
							</li>
						}
						<li>
							<Link to={`/Commission/${id}/Exports`}>
								<i className="far fa-arrow-to-bottom"></i> Exports
							</Link>
						</li>
					</ul>
				</div>
			}
			{usePermCloseCommission &&
				<ModalCloturerCommission
					id={id}
					setOpenModal={setOpenModal}
					openModal={openModal}
					handleRefreshCommissionsList={handleRefreshCommissionsList}
				/>
			}

			{usePermFigeCommissionStatut &&
				<ModalFigerCommission
					id={id}
					setOpenModal={setOpenFigerModal}
					openModal={openFigerModal}
					figerComm={figerComm}
				/>
			}
		</div>
	);
}

export default CommissionMenuTable;
