import React, { useCallback, useEffect, useState } from 'react';
import CollapsibleSection from '../../basics/CollapsibleSection';
import Juridique from './Juridique';
import RepresensantLegal from './RepresensantLegal';
import AdresseSiege from './AdresseSiege';
import Correspondant from './Correspondant';
import AdresseCorrespondant from './AdresseCorrespondant';
import { DocumentUploadInfoDto, DocumentValidationDto, DocumentWithValidationDto, StructureDetailsDto } from '../../../services/generated/BackOffice-api';
import DocumentValidationEditable from '../../document-validation/DocumentValidationEditable';
import { fetchStructureDetails, updateStructureDetails, updateStructureDocumentsValidation, deleteStructureDetails } from '../../../store/structure-store/actions/structureDetailsActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useStructureSelector } from '../../../store/store-helpers';
import Loader from 'react-loader-spinner';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import useHasPermission from '../../../custom-hooks/useHasPermission';
import { useForm, ValidationOptions } from 'react-hook-form';
import { Dictionary } from 'adel-shared/dist/models/Dictionary';
import { modalCustomStyles } from '../../../constants/config.constant';
import Modal from 'react-modal';

interface StructureDetailsTabProps {
	id: string;
	canUpdateDocumentsValidation: boolean;
	validator: Dictionary<ValidationOptions> | null;
	adresseValidator: Dictionary<ValidationOptions> | null;
}

export interface DocumentValidationDtoExtends extends DocumentWithValidationDto {
	partname?: string;
	file?: File;
	extraInfos?: { [key: string]: any; };
}

const StructureDetailsTab: React.FunctionComponent<StructureDetailsTabProps> = ({
	canUpdateDocumentsValidation, id, validator, adresseValidator
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const form = useForm();
	const { isDocValLoading, isLoading } = useStructureSelector();
	const { structureDetails } = useStructureSelector();
	const [documentsValidationToSend, setDocumentsValidationToSend] = useState<DocumentValidationDtoExtends[]>([]);
	const [selectedStructureDelete, setSelectedStructureDelete] = useState<string>();
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

	useEffect(() => {
		if (!id) return;
		fetchStructureDetails(dispatch, id);
	}, [id]);

	const usePermUpdateStructureDetails = useHasPermission("UpdateStructureDetails");

	const openDelete = useCallback(async (strcutureId: string) => {

		if (strcutureId !== "") {
			setOpenModalDelete(true);
			setSelectedStructureDelete(id);
		}

	}, [setOpenModalDelete, setSelectedStructureDelete]);

	const deleteStructure = useCallback(async () => {
		if (id) {
			try {
				await deleteStructureDetails(dispatch, Number(id.split('-')[0]));
				setOpenModalDelete(false);
				setSelectedStructureDelete(undefined);				
			} catch (error) {
				toast.error(t('common.errors.send'));
			}
		}
	}, [setOpenModalDelete, selectedStructureDelete]);

	const handleSubmit = async () => {
		const isValid = await form.triggerValidation();

		if(isValid) {
			let body:StructureDetailsDto;
			try {
				body = {
					representantLegal: {
						email: form.getValues("email") ?? structureDetails?.representantLegal?.email
					},
					adresseCorrespondance: {
						line1: form.getValues("line1") ?? structureDetails?.adresse?.line1,
						line2: form.getValues("line2") ?? structureDetails?.adresse?.line2,
						ville: form.getValues("ville") ?? structureDetails?.adresse?.ville,
						codePostal: form.getValues("codePostal") ?? structureDetails?.adresse?.codePostal,
					}
				}

				const docToSend:DocumentValidationDtoExtends[] = documentsValidationToSend.map(d => {
					if(d.id)
						return {
							id: d.id,
							typeId: d.type?.id,
						}
					return {
						partname: d.partname,
						typeId: d.type?.id,
						extraInfos: d.extraInfos
					}
				}) || [];



				const fileToSend:Dictionary<File> = Object.assign({}, ...documentsValidationToSend.map(d => {
					if(d.partname && d.file)
						return {[d.partname]: d.file}
					return;
				}));

				const newDetails = await updateStructureDetails(dispatch, id, body, docToSend, fileToSend);
				const validation:DocumentValidationDto[] = documentsValidationToSend.map(d => {
					const index = newDetails?.documents?.findIndex(item => item.type?.id === d.type?.id);
					if(!d.id && index && newDetails)
						return {
							id: newDetails.documents?.[index].id,
							validee: d.validee,
							commentaires: d.commentaires
						}
					return {
						id: d.id,
						validee: d.validee,
						commentaires: d.commentaires
					};
				}) || [];
				if(canUpdateDocumentsValidation) {
					await updateStructureDocumentsValidation(dispatch, id, {documents: validation });
					await fetchStructureDetails(dispatch, id);
				}
			} catch(error) {
			}
		}
	}


	/** Documents validation */
	const [pieceObligatoires, setPieceObligatoires] = useState<DocumentWithValidationDto[]>([]);
	const [pieceJointe, setPieceJointe] = useState<DocumentWithValidationDto[]>([]);

	useEffect(() => {
		if (structureDetails?.documents) {
			const docO = structureDetails.documents.filter(d => d.type?.isRequired) || [];
			const docJ = structureDetails.documents.filter(d => !d.type?.isRequired) || [];
			setPieceObligatoires(docO);
			setPieceJointe(docJ);
			setDocumentsValidationToSend(docO);
		}
	}, [structureDetails?.documents]);

	return (
		<div className="detailsTab">
			<div className="detailsTab__section">
				<CollapsibleSection title="Structure juridique" isOpenByDefault={true}>
					<Juridique />
				</CollapsibleSection>
			</div>
			<div className="detailsTab__section">
				<CollapsibleSection title="Informations sur le représentant légal">
					<RepresensantLegal form={form} usePermUpdateStructureDetails={usePermUpdateStructureDetails} validator={validator} />
				</CollapsibleSection>
			</div>
			<div className="detailsTab__section">
				<CollapsibleSection title="Adresse du siège">
					<AdresseSiege />
				</CollapsibleSection>
			</div>
			{structureDetails?.correspondant &&
				<div className="detailsTab__section">
					<CollapsibleSection title="Correspondant administratif">
						<Correspondant />
					</CollapsibleSection>
				</div>
			}
			<div className="detailsTab__section">
				<CollapsibleSection title="Adresse de correspondance">
					<AdresseCorrespondant form={form} usePermUpdateStructureDetails={usePermUpdateStructureDetails} validator={adresseValidator} />
				</CollapsibleSection>
			</div>
			{pieceObligatoires.length > 0 &&
				<div className="detailsTab__section">
					<CollapsibleSection title="Pièces obligatoires">
						<div className="detailsTab__content">
							<DocumentValidationEditable
								documents={pieceObligatoires}
								documentsToSend={documentsValidationToSend}
								setDocumentsToSend={setDocumentsValidationToSend}
								canUpdateDocumentsValidation={canUpdateDocumentsValidation}
							/>
						</div>
					</CollapsibleSection>
				</div>
			}
			{pieceJointe.length > 0 &&
				<div className="detailsTab__section">
					<CollapsibleSection title="Pièces jointes">
						<div className="detailsTab__content">
							<DocumentValidationEditable
								documents={pieceJointe}
								documentsToSend={documentsValidationToSend}
								setDocumentsToSend={setDocumentsValidationToSend}
								canUpdateDocumentsValidation={canUpdateDocumentsValidation}
							/>
						</div>
					</CollapsibleSection>
				</div>
			}
			{usePermUpdateStructureDetails && <section>
				<span className="detailsTab__footer">
				{isLoading
					? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
					: <FormButton
						type="submit"
						value="Valider"
						onClick={handleSubmit}
					/>
					}
				</span>
				<FormButton
					type="button"
					value="Supprimer"
					onClick={() => { openDelete(id || "") }}
				/>
			</section>}
			{usePermUpdateStructureDetails &&
				<Modal
					isOpen={openModalDelete}
					style={modalCustomStyles}
					className="normal__modal"
				>
					<div className="modal__header">
						<h3>Supprimer une structure</h3>
					</div>
					<div className="modal__content">
						<div className="modal__item">
							Voulez-vous supprimer la structure ?
						</div>
					</div>
					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setOpenModalDelete(false);
							}}
						/>
						<FormButton
							type="submit"
							value="Supprimer"
							onClick={() => deleteStructure()}
						/>

					</div>
				</Modal>
			}
		</div>
	);
}

export default StructureDetailsTab;
