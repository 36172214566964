import { globalHistory, NavigateOptions } from "@reach/router"

var navigationAllowed: boolean = true;
var onNavigateCallback: (callback: () => void) => void;

export const useNavigationControl = () => {

    const originalNavigate = globalHistory.navigate;

    globalHistory.navigate = (to: any, options?: NavigateOptions<{}>): Promise<void> => {

        if (navigationAllowed) {
            originalNavigate(to, options);
        } else {
            console.debug("Direct navigation not Allowed");
            if (onNavigateCallback) {
                onNavigateCallback(() => {
                    console.debug(`onNavigateCallback called. navigationAllowed="${navigationAllowed}"`);
                    if(navigationAllowed) {
                        originalNavigate(to, options);
                    }
                });
            } else {
                console.warn("direct navigation not Allowed but no callback was specified.");
            }
        }
        return Promise.resolve();
    };

    const blockNavigation = (callback?: (navigationCallback: () => void) => void): void => {
        console.debug("blocking Navigation");
        navigationAllowed = false;
        if (callback) onNavigateCallback = callback;
    }

    const allowNavigation = () => {
        console.debug("allowing Navigation");
        navigationAllowed = true;
    }

    const isNavigationAllowed = (): boolean => {
        return navigationAllowed;
    }

    return { blockNavigation, allowNavigation, isNavigationAllowed };
}
