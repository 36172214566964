import { Action } from 'redux';
import { AdresseState } from '../states/adresseState';

export enum AdresseActionsEnum {
    GET_ADRESSE = "GET_ADRESSE",
    UPDATE_ADRESSE = "UPDATE_ADRESSE"
}

export interface DispatchAdresseAction extends Action<AdresseActionsEnum> {
    payload: AdresseState;
}
