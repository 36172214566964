import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useForm, Controller, ValidationOptions, ValidateResult } from 'react-hook-form';
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { useDossierSelector } from '../../../../store/store-helpers';
import Loader from 'react-loader-spinner';
import { ajoutEvenementFinancier } from '../../../../store/dossier-store/actions/dossierDetailsActions';
import { AddEvenementFinancierDto, EvenementFinancierViewModelDto, TypeMouvementDto } from '../../../../services/generated/BackOffice-api';
import { useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';

interface ModalAddEvenementProps {
	isOpen: boolean;
	dossierId: string;
	setIsOpen: (value:boolean) => void;
	validator: Dictionary<ValidationOptions> | null;
}

const selectTypeMouvementMontant = (typeMouvement?: TypeMouvementDto, evtFinancier?:EvenementFinancierViewModelDto) => {
	if(typeMouvement?.code && evtFinancier) {
		switch(typeMouvement.code) {
			case 'reaffectation':
				return evtFinancier.montantReaffectation;
			case 'versement-complet':
				return evtFinancier.montant;
			case 'annulation-reaffectation':
				return evtFinancier.montantAnnulationReaffectation
			case 'virement-rejete':
				return evtFinancier.montantVirementRejete
		}
	}
}

const ModalAddEvenement: React.FunctionComponent<ModalAddEvenementProps> = ({
	isOpen,
	dossierId,
	setIsOpen,
	validator
}) => {
	const {
		isLoading,
		evenementFinancier: evtFinancier = {}
	} = useDossierSelector();
	const dispatch = useDispatch();
	const {
		control,
		register,
		setValue,
		triggerValidation,
		getValues,
		errors,
		watch
	} = useForm<AddEvenementFinancierDto>({ 
		defaultValues: {
			typeMouvementId: evtFinancier.typeMouvements?.[0]?.id,
			montant: selectTypeMouvementMontant(evtFinancier.typeMouvements?.[0], evtFinancier)
		}
	});
	const typeMouvementId = watch('typeMouvementId');
    const [options, setOptions] = useState<AdelOption<string>[]>([]);

	useEffect(() => {
		if (evtFinancier.typeMouvements) {
			const allOpt:AdelOption<string>[] = evtFinancier.typeMouvements.map(x => ({
				value: x.id || '',
				label: x.intitule || ''
			}));
			setOptions(allOpt);
		}
	}, [evtFinancier.typeMouvements]);

	useEffect(() => {
		const newMontant = selectTypeMouvementMontant(evtFinancier.typeMouvements?.find(x => x.id === typeMouvementId), evtFinancier);
		setValue('montant', newMontant);
	}, [
		evtFinancier.typeMouvements,
		typeMouvementId
	]);

	const submit = async() => {
		const isValid = await triggerValidation();

		if(isValid) {
			await ajoutEvenementFinancier(dispatch, dossierId, getValues());
			setIsOpen(false);
		}
	}

	const notEmptyValidation: ValidationOptions = {
		validate: (data: string): ValidateResult => {

			const typeId = Number(typeMouvementId?.split('-')[0])
			if (!data && typeId === 6) { return "Ce champ est obligatoire pour la réaffectation." }
			return true;
		}
	};

	return (<Modal isOpen={isOpen} style={modalCustomStyles} className="normal__modal fluxModal">
		<div className="modal__header">
			<h3>Ajouter un évènement</h3>
		</div>
		<div className="modal__content">
			<div className="modal__item modal__itemSelect--fullWith">
				<Controller control={control}
					name="typeMouvementId"
					as={({ onChange, value, name }) => (
						<InputSelect<string>
							name={name}
							label="Type"
							classname="inputSelect"
							options={options}
							onChange={(value) => onChange(value)}
							value={value}
							errors={errors}
						/>
					)}
					rules={validator?.["TypeMouvementId"]}
				/>
			</div>
			<div className="modal__item">
				<Input
					name="motif"
					type="text"
					label="Motif"
					errors={errors}
					reference={register(notEmptyValidation)}				
					/>
			</div>
			<div className="modal__item">
				<Input
					name="montant"
					type="number"
					label="Montant"
					errors={errors}
					reference={validator?.['Montant'] ? register(validator['Montant']) : register()}
				/>
			</div>
		</div>
		<div className="modal__footer">
			<FormButton
				type="button"
				value="Annuler"
				onClick={() => {
					setIsOpen(false);
				}}
			/>
			{isLoading
				? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
				: <FormButton
					type="submit"
					value={"Ajouter"}
					onClick={submit}
				/>
			}
				
		</div>
	</Modal>);
}

export default ModalAddEvenement;
