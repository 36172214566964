import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import PagesContainer from '../../containers/PagesContainer';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { useSortBy, useTable } from 'react-table';
import ModalAddCompte from './ModalAddCompte';
import { modalCustomStyles } from '../../constants/config.constant';
import Modal from 'react-modal';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { CompteBancaireDto } from '../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getAllComptesBancaires, deleteCompteBancaire } from '../../store/compte-bancaire-store/actions/compteBancaireAction';
import { useCompteBancaireSelector } from '../../store/store-helpers';
import useHasPermission from '../../custom-hooks/useHasPermission';

interface ComptesBancairesSpedidamProps extends RouteComponentProps {
}

const ComptesBancairesSpedidam: FunctionComponent<ComptesBancairesSpedidamProps> = () => {
	const dispatch = useDispatch();
	const [data, setData] = useState<CompteBancaireDto[]>([]);
	
	const [isModalAddOpen, openModalAdd, closeModalAdd] = useBooleanState(false);
	const [isModalDeleteOpen, openModalDelete, closeModalDelete] = useBooleanState(false);

	const compteToDeleteId = useRef<string>('');
	const [compte, setCompte] = useState<CompteBancaireDto>();
	const compteBancaireSelector = useCompteBancaireSelector();
	const usePermCreateUpdateCompte = useHasPermission('CreateOrUpdateCompteBancaire');
	const usePermDeleteCompte = useHasPermission('DeleteCompteBancaire');

    /** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: "IBAN", accessor: "iban", disableSortBy: true },
					{ Header: "Banque", accessor: "banque", disableSortBy: true },
					{ Header: "Agence", accessor: "agence", disableSortBy: true },
					{ Header: "BIC", accessor: "bic", disableSortBy: true },
					{ Header: "", accessor: "actions", disableSortBy: true,
						Cell: (props: any) => (
							<span>
								{usePermCreateUpdateCompte && (
									<i onClick={() => editCompte(props.cell.row.values.id)} className="far fa-edit gestionCategoriesDossier__icon"></i>
								)}
								{usePermDeleteCompte && (
									<i onClick={() => deleteCompteAction(props.cell.row.values.id)} className="far fa-trash-alt gestionCategoriesDossier__icon"></i>
								)}
							</span>
						)
					},
					{ Header: "Id", accessor: "id" }
				]
			}
		];
	}, [
		data,
		usePermCreateUpdateCompte,
		usePermDeleteCompte
	]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

    useEffect(() => {
        (async() => {
			const result = await getAllComptesBancaires(dispatch);
			setData(result);
        })();
    }, []);

	useEffect(() => {
		(async() => {
			const result = await getAllComptesBancaires();
			setData(result);
		})();
	}, [compteBancaireSelector.comptesBancaires]);
	
	const handleAdd = () => {
		openModalAdd();
		setCompte(undefined);
	}

	const editCompte = (id: string) => {
		openModalAdd();
		setCompte(data.find(x => x.id === id));
	};

	const deleteCompteAction = (id: string) => {
		compteToDeleteId.current = id;
		openModalDelete();
	}

	const deleteCompte = async() => {
		try {
			await deleteCompteBancaire(dispatch, compteToDeleteId.current);
			toast.success('Le compte bancaire a bien été supprimé.');
		} catch {
			toast.error('Une erreur est survenue pendant la suppression du compte bancaire.');
		}
		closeModalDelete();
	}

    return (
		<PagesContainer title="Comptabilité - Comptes bancaires de la Spedidam" pageClass="comptes-bancaires">
			<div className="tablePage">
				<div className="tableStyle__header">
					{usePermCreateUpdateCompte && (
						<div className="tableStyle__addButton" onClick={handleAdd} >
							<label>Ajouter un compte bancaire</label>
							<button>
								<i className="far fa-plus"></i>
							</button>
						</div>
					)}
				</div>
				<Table
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.page}
				/>
				{isModalAddOpen && (
					<ModalAddCompte
						compte={compte}
						isOpen={isModalAddOpen}
						onClose={closeModalAdd}
					/>
				)}
				<Modal isOpen={isModalDeleteOpen} style={modalCustomStyles} className="normal__modal">
					<div className="modal__header">
						<h3>Supprimer un compte bancaire</h3>
					</div>
					<div className="modal__content">
						<div className="modal__item">
							Voulez-vous supprimer ce compte ?
						</div>
					</div>
					<div className="modal__footer">
						<FormButton type="button" value="Annuler" onClick={closeModalDelete} />
						<FormButton type="submit" value="Supprimer" onClick={deleteCompte} />
					</div>
				</Modal>
			</div>
		</PagesContainer>
	)
};

export default ComptesBancairesSpedidam;