import InputSelect from 'adel-shared/dist/components/basics/InputSelect';
import React from 'react';
import { useEffect } from 'react';
import { Controller, FormContextValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StatutVersement, VersementDetailsDto } from '../../../../services/generated/BackOffice-api';


interface VersementStatutProps {
	form: FormContextValues;
	minoration: boolean;
	isDisabled?:boolean;
}

const VersementStatut: React.FunctionComponent<VersementStatutProps> = ({
	form,
	minoration,
	isDisabled
}) => {
	const { t } = useTranslation();
	const { control } = form;
  
	const statutOptions =  Object.values(StatutVersement).filter(value => 
		value === StatutVersement.Soumis ||
		value === StatutVersement.PriseEnCharge ||
		value === StatutVersement.Valide ||
		value === StatutVersement.Modification || 
		value === StatutVersement.Reaffectation || 
		(control.getValues().versementStatut == StatutVersement.Verse && value === StatutVersement.Verse)
	).map(value => ({
		value,
		label: t(`versements.statut.${value}`)
	}));

	return (
		<section className="versementTab__statut">
			<h3 className="detailsTab__title">Statut de versement</h3>
			<div className="versementTab__statutContent">
				<div className="checkbox table__check">
					<label>
						<input
							name="minoration" 
							id="minoration"
							type="checkbox"
							checked={minoration}
							disabled
						/>
						<div className="newCheckbox"></div>
						Minoration de 20% (absence de logo et/ou affiches sur le lieu de diffusion)
					</label>
				</div>
				
				<Controller control={control}
					name="versementStatut"
					as={({ onChange, value, name }) => (
						<InputSelect
							name={name}
							label="Statut versement"
							classname="inputSelect inputSelect--fullWidth"
							options={statutOptions}
							onChange={onChange}
							value={value}
							placeholder={t("common.select")}
							isDisabled={isDisabled}
						/>
					)}
				/>
			</div>
		</section>
	);
}

export default VersementStatut;