import { CommissionMemberDto, CommissionMemberDtoSortedFilteredPage } from '../../../services/generated/BackOffice-api';

export interface CommissionMembreState {
	isLoading?: boolean;
	commissionMembres?: CommissionMemberDtoSortedFilteredPage;
	isCreateLoading? :boolean;
	commissionMembre?: CommissionMemberDto;
}

export const initialCommissionMembreState: CommissionMembreState = {
	isLoading: false,
	commissionMembres: {},
	isCreateLoading: false,
	commissionMembre: {}
};