import { Attachment, FileResponse } from "../services/generated/BackOffice-api";

export const downloadFileFromUri = (uri: string, fileName?: string | undefined, download: boolean = true) => {
    if (download) {
        const link = document.createElement('a');
        if (fileName) {
            link.download = fileName;
        }
        link.href = uri;
        link.click();
    } else {
        window.open(uri);
    }
};

export const downloadFile = (response: FileResponse, download: boolean = true) => {
    const file = new Blob([response.data], {
        type: response.headers && response.headers['content-type']
    });

    const fileURL = URL.createObjectURL(file);

    downloadFileFromUri(fileURL, response.fileName, download);
};

export const downloadFileFromDataURI = (response: Attachment, download: boolean = true) => {
    const fileURL = `data:${response.contentType};base64,${response.content}`;

    downloadFileFromUri(fileURL, response.fileName, download);
};

export const capitalize = (s: string) => {
    if (typeof s !== 'string') return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
};
