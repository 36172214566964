import Input from 'adel-shared/dist/components/basics/Input';
import InputCalendar from 'adel-shared/dist/components/basics/InputCalendar';
import InputSelect from 'adel-shared/dist/components/basics/InputSelect';
import { merge } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { modalCustomStyles } from "../../../constants/config.constant";
import { StatutVersement, TypeVersement } from '../../../services/generated/BackOffice-api';
import CollapsibleSection from '../../basics/CollapsibleSection';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputRadioButton from 'adel-shared/dist/components/basics/InputRadioButton';
import { IDossierFilters } from '../DossiersListe';
import {
	blocageJuridiqueFilterKey,
	canVersementFilterKey,
	chargeInstructionNomFilterKey,
	chargeInstructionPrenomFilterKey,
	dateSoumissionMinFilterKey,
	dateSoumissionMaxFilterKey,
	dsvFilterKey,
	enCoursValidationDSVFilterKey,
	modifiableParStructureFilterKey,
	responsableNomFilterKey,
	responsablePrenomFilterKey,
	typeVersementFilterKey,
    statutVersementFilterKey,
	valideParDSVFilterKey,
	villeFilterKey,
	alerteVerteFilterKey,
	alerteRougeFilterKey,
	ribValideFilterKey,
	ribModifiableFilterKey,
	blocageAvisFilterKey,
	representantNomFilterKey,
	representanPrenomFilterKey,
	artisteNomFilterKey,
	artistePrenomFilterKey,
    filtersDefault
} from "../../../constants/filters.constant";
import { updateFilter } from '../../../store/dossier-store/actions/dossierAction';
import { useDispatch } from 'react-redux';

interface DossierFiltersModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: any) => void;
	setFilters: (value:any) => void;
	filters: IDossierFilters;
}


const DossierFiltersModal: FunctionComponent<DossierFiltersModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
	setFilters,
	filters
}) => {
	const { t } = useTranslation();
    const dispatch = useDispatch()
    
    const initValues = () => {
        return {
            chargeInstructionNom: filters[chargeInstructionNomFilterKey],
            chargeInstructionPrenom: filters[chargeInstructionPrenomFilterKey],
            artisteNom: filters[artisteNomFilterKey],
            artistePrenom: filters[artistePrenomFilterKey],
            ville: filters[villeFilterKey],
            representantLegalNom: filters[representantNomFilterKey],
            representantLegalPrenom: filters[representanPrenomFilterKey],
            responsableNom: filters[responsableNomFilterKey],
            responsablePrenom: filters[responsablePrenomFilterKey],
            typeVersement: filters[typeVersementFilterKey]?.map(v => {
                return {
                    value: TypeVersement[v as keyof typeof TypeVersement],
                    label: t(`common.typesVersements.${v.toLowerCase()}`)
                }
            }) || undefined,
            statutVersement: filters[statutVersementFilterKey]?.map(v => {
                return {
                    value: StatutVersement[v as keyof typeof StatutVersement],
                    label: t(`versements.statut.${v.toLowerCase()}`)
                }
            }) || undefined,
            dateSoumissionMin: filters[dateSoumissionMinFilterKey],
            dateSoumissionMax: filters[dateSoumissionMaxFilterKey],
            dsv: filters[dsvFilterKey],
            blocageJuridique: filters[blocageJuridiqueFilterKey],
            modifiableParStructure: filters[modifiableParStructureFilterKey],
            blocageAvis: filters[blocageAvisFilterKey],
            canVersement: filters[canVersementFilterKey],
            enCoursValidationDSV: filters[enCoursValidationDSVFilterKey],
            valideParDSV: filters[valideParDSVFilterKey],
            alerteRouge: filters[alerteRougeFilterKey],
            alerteVerte: filters[alerteVerteFilterKey],
            ribModifiable: filters[ribModifiableFilterKey],
            ribValide: filters[ribValideFilterKey],
        }
    }

    const resetValues = () => {
        Object.keys(getValues()).map((key: string) => setValue(key, undefined))
    }
    
    const { watch, control, getValues, setValue, register, errors } = useForm({
        defaultValues: initValues()
    });

    //@ts-ignore
    const dateSoumissionMin:Date = watch('dateSoumissionMin');
    //@ts-ignore
    const dateSoumissionMax:Date = watch('dateSoumissionMax');

    const styles = merge(
        {
            overlay: {
                display: isOpen ? 'block' : 'none'
            }
        },
        modalCustomStyles
    );

    const getTypeDemandeOptions = useMemo(() => {
        let types : any = [];
         Object.entries(TypeVersement).map(([key, value]) =>{
            if(value != TypeVersement.None){                
                types.push({value,
                        label: t(`common.typesVersements.${value}`)
                    })}});
        return types;
    }, []);

    const getStatutDemandeOptions = useMemo(() => {
        let statuts : any = [];
         Object.entries(StatutVersement).map(([key, value]) =>{
            if(value != StatutVersement.None){                
                statuts.push({value,
                        label: t(`common.statutsVersements.${value}`)
                    })}});
        return statuts;   
    }, []);

    const handleSubmit = () => {
        onSubmit(getValues());
    };

    return (
        <Modal
            isOpen
            style={styles}
            className="normal__modal dossiersFiltres__modal"
        >
            <div className="modal__header">
                <h3>Options avancées</h3>
            </div>

            <div className="modal__content">
            <CollapsibleSection title="Recherche paiement" isOpenByDefault={true}>
                    <>
                    	<div className="formRow">
							<Controller
                                control={control}
                                name="canVersement"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Convention horodatée</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                            <Controller
                                control={control}
                                name="typeVersement"
                                as={({name, onChange, value}) => (
                                    <InputSelect<string>
										classname="inputSelect inputSelect--multi"
                                        label="Type de demande"
                                        name={name}
										isMulti={true}
                                        onChange={onChange}
                                        options={getTypeDemandeOptions}
                                        value={value}
                                    />
                                )}
                            />
							<Controller
                                control={control}
                                name="enCoursValidationDSV"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>En cours de validation par droit exclusif</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="formRow">
							<Controller
                                control={control}
                                name="valideParDSV"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Versement validé par droit exclusif</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />                            
                            <Controller
                                control={control}
                                name="statutVersement"
                                as={({name, onChange, value}) => 
                                    {
                                        return (<InputSelect<string>
										classname="inputSelect inputSelect--multi"
                                        label="Statut de versement"
                                        name={name}
                                        isMulti={true}
                                        onChange={onChange}
                                        options={getStatutDemandeOptions}
                                        value={value}
                                    />)}
                                }
                            />
                        </div>
                    </>
                </CollapsibleSection>
                <CollapsibleSection title="Recherche avancée" isOpenByDefault={true}>
                    <div>
                        <div className="formRow">
                            <Input
                                label="Nom du chargé d'instruction"
                                type="text"
                                name="chargeInstructionNom"
                                reference={register}
                                errors={errors}
                            />
                            <Input
                                label="Prénom du chargé d'instruction"
                                type="text"
                                name="chargeInstructionPrenom"
                                reference={register}
                                errors={errors}
                            />
							<Controller
                                control={control}
                                name="blocageAvis"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Blocage avis</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="formRow">
                            <Input
                                label="Nom du représentant légal"
                                type="text"
                                name="representantLegalNom"
                                reference={register}
                                errors={errors}
                            />
                            <Input
                                label="Prénom du représentant légal"
                                type="text"
                                name="representantLegalPrenom"
                                reference={register}
                                errors={errors}
                            />
							<Controller
                                control={control}
                                name="dsv"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Concerne le droit exclusif</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="formRow">
                            <Input
                                label="Nom du responsable dossier"
                                type="text"
                                name="responsableNom"
                                reference={register}
                                errors={errors}
                            />
                            <Input
                                label="Prénom du responsable dossier"
                                type="text"
                                name="responsablePrenom"
                                reference={register}
                                errors={errors}
                            />
							<Controller
                                control={control}
                                name="blocageJuridique"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Blocage juridique</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="formRow">
							<div></div>
							<div></div>
							{/** TODO: Décommenter quand le back sera fait pour la recherche d'artiste */}
                            {/* <Input
                                label="Nom de l'artiste"
                                type="text"
                                name="artisteNom"
                                reference={register}
                                errors={errors}
                            />
                            <Input
                                label="Prénom de l'artiste"
                                type="text"
                                name="artistePrenom"
                                reference={register}
                                errors={errors}
                            /> */}
							<Controller
                                control={control}
                                name="modifiableParStructure"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Dossier modifiable par la structure</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="formRow">
                            <Input
                                label="Ville du siège social"
                                type="text"
                                name="ville"
                                reference={register}
                                errors={errors}
                            />
                            <div className="input">
                                <label>Date de soumission</label>
                                <div className="dateRange">
                                    <Controller
                                        control={control}
                                        name="dateSoumissionMin"
                                        as={({name, onChange, value}) => (
                                            <InputCalendar
                                                defaultDate={value}
                                                onDateSelected={onChange}
                                                name={name}
                                                maxDate={dateSoumissionMax || undefined}
                                            />
                                        )}
                                    />
                                    <span>au</span>
                                    <Controller
                                        control={control}
                                        name="dateSoumissionMax"
                                        as={({name, onChange, value}) => (
                                            <InputCalendar
                                                defaultDate={value}
                                                onDateSelected={onChange}
                                                name={name}
                                                minDate={dateSoumissionMin || undefined}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <Controller
                                control={control}
                                name="ribValide"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>RIB validé</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />

                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false|null'}
                                                value="false|null"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="formRow">
							<Controller
                                control={control}
                                name="alerteVerte"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Alerte verte</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
							<Controller
                                control={control}
                                name="alerteRouge"
                                as={({name, onChange, value}) => (
                                    <div className="input">
                                        <label>Alerte rouge</label>
                                        <div className="InputRadioYesNo">
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.yes")}
                                                id={`${name}-yes`}
                                                onChange={onChange}
                                                checked={value === 'true'}
                                                value="true"
                                            />
                                            <InputRadioButton
                                                name={name}
                                                label={t("common.no")}
                                                id={`${name}-no`}
                                                onChange={onChange}
                                                checked={value === 'false'}
                                                value="false"
                                            />
                                        </div>
                                    </div>
                                )}
                            />
							<div></div>
                        </div>
                    </div>
                </CollapsibleSection>               
            </div>

            <div className="modal__footer">
                <FormButton
                    type="button"
                    value="Annuler"
                    onClick={onClose}
                />
                <div>
                    <FormButton
                        className="button__reinitialization"
                        type="button"
                        value="Réinitialiser"
                        onClick={async() => {
                            setFilters({type: 'reset', payload: filtersDefault}); 
                            resetValues()  
                            dispatch(updateFilter(filtersDefault))
						}}
                    />

                    <FormButton
                        type="submit"
                        value="Rechercher"
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DossierFiltersModal;