import { Action } from 'redux';
import { CritereDossierState } from '../states/critereDossierState';
import { DossierClient, CreateCritereDossierDto, UpdateCritereDossierDto } from '../../../services/generated/BackOffice-api';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

export enum CritereDossierActionsEnum {
	GET_CRITERES_REQUEST = "GET_CRITERES_REQUEST",
	GET_CRITERES_SUCCESS = "GET_CRITERES_SUCCESS",
	GET_CRITERE_REQUEST = "GET_CRITERE_REQUEST",
	GET_CRITERE_SUCCESS = "GET_CRITERE_SUCCESS",
	PUT_CRITERE_REQUEST = "PUT_CRITERE_REQUEST",
	PUT_CRITERE_SUCCESS = "PUT_CRITERE_SUCCESS",
	SEND_CRITERE_REQUEST = "SEND_CRITERE_REQUEST",
	SEND_CRITERE_SUCCESS = "SEND_CRITERE_SUCCESS",
	SEND_CRITERE_ERROR = "SEND_CRITERE_ERROR",
	DELETE_CRITERE_REQUEST = "DELETE_CRITERE_REQUEST",
	DELETE_CRITERE_SUCCESS = "DELETE_CRITERE_SUCCESS",
}

export interface DispatchCritereDossierAction extends Action<CritereDossierActionsEnum> {
	payload: CritereDossierState;
}

// get all criteres
export const requestCriteres = () => {
	return {
		type: CritereDossierActionsEnum.GET_CRITERES_REQUEST
	}
}

export const getCriteres = (result: any) => {
	return {
		type: CritereDossierActionsEnum.GET_CRITERES_SUCCESS,
		payload: { criteres: result }
	}
}


//get one critere
export const requestCritere = () => {
	return {
		type: CritereDossierActionsEnum.GET_CRITERE_REQUEST
	}
}

export const getCritere = (result: any) => {
	return {
		type: CritereDossierActionsEnum.GET_CRITERE_SUCCESS,
		payload: { critere: result }
	}
}

// Send critere
export const sendCritere = () => {
	return {
		type: CritereDossierActionsEnum.SEND_CRITERE_REQUEST
	}
}
export const savedCritere = (result: CreateCritereDossierDto) => {
	return {
		type: CritereDossierActionsEnum.SEND_CRITERE_SUCCESS,
		payload: { critere: result }
	}
}
export const errorSavedCritere = (error:any) => {
	if(error.response?.exception?.message)
		toast.error(error.response.exception.message);
	else if(error.exception?.message)
		toast.error(error.exception.message);
	else
		toast.error("Impossible de créer le critère de dossier");
	return {
		type: CritereDossierActionsEnum.SEND_CRITERE_ERROR
	}
}
//put one critere
export const requestPutCritere = () => {
	return {
		type: CritereDossierActionsEnum.PUT_CRITERE_REQUEST
	}
}

export const putCritere = (result: any) => {
	return {
		type: CritereDossierActionsEnum.PUT_CRITERE_SUCCESS,
		payload: { critere: result }
	}
}

//delete one critere
export const requestDeleteCritere = () => {
	return {
		type: CritereDossierActionsEnum.DELETE_CRITERE_REQUEST
	}
}

export const deleteCritere = (result: any) => {
	return {
		type: CritereDossierActionsEnum.DELETE_CRITERE_SUCCESS,
	}
}


/** Tasks */
const critereClient = new DossierClient('', axiosInstance);



//get critères
export const fetchCriteresDossier = (
	dispatch: any,
	categorieId?: any,
	version?: any,
	sortBy?: any,
	sortDirection?: any,
	page: number = 1,
	pageSize: number = 3,
) => {

	return () => {
		dispatch(requestCriteres());
		return critereClient.getCriteresDossier(categorieId, version, sortBy, sortDirection, page, pageSize)
			.then((response: any) => dispatch(getCriteres(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer le récupérer les critères de dossier");
			})
	}
}

//get critère
export const fetchCritereDossier = (
	dispatch: any,
	id: string
) => {
	return () => {
		dispatch(requestCritere());
		return critereClient.getCritereDossier(id)
			.then((response) => dispatch(getCritere(response)))
			.catch((error) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de récupérer le critère de dossier");
			})
	}
}

//save critere
export const createCritereDossier = (
	dispatch: any,
	critere: CreateCritereDossierDto
) => {
	return () => {
		dispatch(sendCritere());
		return critereClient.createCritereDossier(critere)
			.then((response: any) => dispatch(savedCritere(response)))
			.catch((error: any) => dispatch(errorSavedCritere(error)))
	}
}

//put critère
export const editCritereDossier = (
	dispatch: any,
	id: string,
	critere: UpdateCritereDossierDto
) => {

	return () => {
		dispatch(requestPutCritere());
		return critereClient.updateCritereDossier(id, critere)
			.then((response: any) => dispatch(putCritere(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else if(error.message)
					toast.error(error.message);
				else
					toast.error("Impossible d'éditer le critère de dossier'");
				return Promise.reject(error);
			})
	}
}

//delete critère
export const delCritereDossier = (
	dispatch: any,
	id: any
) => {

	return () => {
		dispatch(requestDeleteCritere());
		return critereClient.deleteCritereDossier(id)
			.then((response: any) => dispatch(deleteCritere(response)))
			.catch((error: any) => {
				if(error.response?.exception?.message)
					toast.error(error.response.exception.message);
				else if(error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error("Impossible de supprimer le critère de dossier");
			})
	}
}
