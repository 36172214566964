import React, { useEffect, useState } from 'react'
import SyntheseInfoCard from './SyntheseInfoCard'
import SyntheseIcon from './SyntheseIcon'
import SyntheseStep from './SyntheseStep'
import { useDossierSelector } from '../../../store/store-helpers'
import { getDossierSyntheseFinanciere } from '../../../store/dossier-store/actions/dossierDetailsActions'
import { useParams } from '@reach/router'
import { useDispatch } from 'react-redux'
import { StatutDossier } from '../../../services/generated/BackOffice-api'

const SyntheseDossier = () => {
  const {
    dossierDetails: details = {},
    dossierRelations,
    dossierSynthese
  } = useDossierSelector();
  const dispatch = useDispatch();
  const location = useParams();
	const id = location.dossierId;
  
  const [statut,setStatut] = useState(StatutDossier.None)
  useEffect(() => {
    setStatut(details.statut || StatutDossier.None)
  },[details])

  const [montantAttribue, setMontantAttribue] = useState(0)
  useEffect(() => {
    setMontantAttribue(dossierSynthese?.montantAttribue || 0)
  }, [dossierSynthese])

	useEffect(() => {
		(async() => {
			if(id && (statut == StatutDossier.Accepte || statut == StatutDossier.Solde || statut == StatutDossier.Renonciation)) {
				await getDossierSyntheseFinanciere(dispatch, id);
			}
		})();
	}, [statut]);

  let currentStatusText = details?.statut ?? '';
  
  const categorie = details?.categorie

  let categories = new Map();
  categories.set("MUSICAL", {imageName:"image_mus1", color: "#9ECBC5"});
  categories.set("FEST",{imageName:"image_fest", color: "#84D0F0"});
  categories.set("CHORE",{imageName:"image_chore", color: "#E96B6B"});
  categories.set("DRAMA",{imageName:"image_drama", color: "#6FCF97"});
  categories.set("ECOLE",{imageName:"image_ecole", color: "#FFDBCE"});
  categories.set("INT",{imageName:"image_int", color: "#5B788C"});
  categories.set("MUSICAL1",{imageName:"image_mus", color: "#FBC068"});
  categories.set("PROMO",{imageName:"image_api", color: "#B1D328"});
  categories.set("BO",{imageName:"image_bo", color: "#9B51E0"});

  
  let key = categorie?.code ?? ""
    
  const [imageName, setImageName] = useState<string>() 
  const [color, setColor] = useState<string>("#FFF")

  useEffect(() => {
      (categories.get(key)?.imageName) ?
      setImageName(categories.get(key)?.imageName) :
      setImageName("image_mus1")
  },[categories.get(key)?.imageName])

  useEffect(() => {
      (categories.get(key)?.color) ?
      setColor(categories.get(key)?.color) :
      setColor("#9ECBC5")
  },[categories.get(key)])

  const statusList : string[] = ['Soumis','Pris en charge','Programmé','Accepté','Versement demandé','Soldé'];

  const statutDossier: string[] = 
  [ 'soumis',
    'prisEnCharge',
    'programmeEnCommission',
    'accepte',
    'brouillon',
    'solde', 
    'none', 
    'sansSuite',
    'horsCriteres',
    'renonciation',
    'refuse',
    'reaffecte']

    const statutDossierDisplay: string[] = 
    [ 'Soumis',
      'Pris En Charge',
      'Programmé En Commission',
      'Accepté',
      'Brouillon',
      'Soldé', 
      'None', 
      'Sans Suite',
      'Hors Critères',
      'Renonciation',
      'Refusé',
      'Réaffecté']

    const statutDossierIndex: string[] = 
    [ '1',
      '2',
      '3',
      '4',
      '0',
      '5', 
      '0', 
      '0',
      '0',
      '7',
      '8',
      '6']
  
  const[currentIndex, setCurrentIndex] = useState(0);
  const [showSteps, setShowSteps] = useState(true);
  useEffect(() => {
    setCurrentIndex(statutDossier.indexOf(currentStatusText));
  },[details]);

  useEffect(() => {
    setShowSteps(currentIndex <= 3 || currentIndex == 5)
  },[currentIndex]);

  return (
    details &&
    <div className='synthese_container'>
      <div className="left_container">
        <SyntheseIcon categorie={categorie} imageName={imageName} color={color} />
        <div className='synthese_separator' />

        <div className='steps_container'>
          {showSteps 
          ?
          <>
            <div className='synthese_separator2' />
            <div className='synthese_steps' />
          
            {statusList.map((status, index) => {
              return (
                <SyntheseStep key={index} number_step={index + 1} text={status} currentStatus={index == currentIndex} color={color} />
              );
            })
            }
            </>
            : 
              <SyntheseStep number_step={Number(statutDossierIndex[currentIndex])} text={statutDossierDisplay[currentIndex]} 
              currentStatus={true} color={color} />
            }
        </div>
        </div>
            
      <div className="right_container">
        <SyntheseInfoCard num={1} details={details} />
        <SyntheseInfoCard num={2} details={details} />
        <SyntheseInfoCard num={3} details={details} montantAttribue={montantAttribue} />
      </div>
    </div>
  );
}

export default SyntheseDossier