import {
    BordereauDto,
    BordereauVersementDto,
    BordereauReaffectationDto,
    DemandeRemboursementMouvementDto
} from '../../../services/generated/BackOffice-api';

export interface BordereauState {
    bordereaux?: BordereauDto[];
    bordereauVersement?: BordereauVersementDto;
    bordereauReaffectation?: BordereauReaffectationDto;
	bordereauReaffectationAmendable?: BordereauReaffectationDto;
    mouvementId?: string;
    bordereauId?: string;
	demandesRemboursement?: DemandeRemboursementMouvementDto[];
}

export const initialBordereauState: BordereauState = {
    bordereaux: [],
    bordereauVersement: {},
    bordereauReaffectation: {},
	bordereauReaffectationAmendable: {},
    mouvementId: '',
    bordereauId: '',
	demandesRemboursement: []
};