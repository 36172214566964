import React from 'react';
import ModalValidateCancel from '../../basics/ModalValidateCancel';

interface ModalDeleteDocumentProps {
    isOpen: boolean;
    onCancel: () => void;
    onValidate: () => void;
    isLoading?: boolean;
}

const ModalDeleteDocument: React.SFC<ModalDeleteDocumentProps> = ({
    isOpen,
    onCancel,
    onValidate,
    isLoading
}) => {
    return (<ModalValidateCancel
        isOpen={isOpen}
        onCancel={onCancel}
        onValidate={onValidate}
        isLoading={isLoading}
        title={"Supprimer le document selectionné"}
    >
        <div className="modal__item">
            <p>Voulez vous vraiment supprimer le document sélectionné ?</p>
        </div>
    </ModalValidateCancel>);
}

export default ModalDeleteDocument;