import React, { useState, useEffect, FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';
import PagesContainer from "../../containers/PagesContainer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import CriteresFilters from './criteres-dossier/CriteresFilters';
import RemunerationsFilters from './rémunerations/RemunerationsFilters';
import { useCategorieDossierSelector, useRemunerationSelector } from '../../store/store-helpers';
import CriteresDossier from './criteres-dossier/CriteresDossier';
import { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { fetchCategoriesDossier } from '../../store/categorie-dossier-store/actions/categorieDossierAction';
import { useDispatch } from 'react-redux';
import Remunerations from './rémunerations/Remunerations';
import { getViewModelforRemuneration, fetchStylesMusicaux } from '../../store/remuneration-store/actions/remunerationAction';
import { StyleMusicalDto } from '../../services/generated/BackOffice-api';
import useHasPermission from '../../custom-hooks/useHasPermission';



interface GestionCriteresRemunerations extends RouteComponentProps {
}

const GestionCriteresRemunerations: FunctionComponent<GestionCriteresRemunerations> = () => {

	const { t } = useTranslation();
	const dispatch = useDispatch();

	//selector
	const categorieSelector = useCategorieDossierSelector();
	const remunerationSelector = useRemunerationSelector();

	const [tabIndex, setTabIndex] = useState<number>(0);

	// filtres critères
	const [typeFilterCriteres, setTypeFilterCriteres] = useState<string>("all");
	const [versionFilterCriteres, setVersionFilterCriteres] = useState<string>("current");

	const [typeOptions, setTypeOptions] = useState<AdelOption<string>[]>([]);
	const [typeMusicalOptions, setTypeMusicalOptions] = useState<AdelOption<string>[]>([]);


	// filtres rémunerations
	const [categorieIdFilterRemunerations, setCategorieIdFilterRemunerations] = useState<string>("all"); // type dossier
	const [emploiFilterRemunerations, setEmploiFilterRemunerations] = useState<string>("all"); // type artiste
	const [typePrestationFilterRemunerations, setTypePrestationFilterRemunerations] = useState<string>("all"); // type session
	const [styleMusicalIdIdFilterRemunerations, setStyleMusicalIdFilterRemunerations] = useState<string>("all"); // type musique
	const [montantMinFilterRemunerations, setMontantMinFilterRemunerations] = useState<number>();
	const [montantMaxFilterRemunerations, setMontantMaxFilterRemunerations] = useState<number>();


	//modales critères
	const [modalAddCritereIsOpen, setModalAddCritereIsOpen] = useState<boolean>(false);

	//modales Remuneration
	const [modalAddRemunerationIsOpen, setModalAddRemunerationIsOpen] = useState<boolean>(false);

	useEffect(() => {
		fetchCategoriesDossier(dispatch)();
		fetchStylesMusicaux(dispatch)();
	}, [])

	useEffect(() => {
		if(categorieSelector && categorieSelector.categories && categorieSelector.categories.length > 0) {
			let categories: any = [...categorieSelector.categories as any];
			categories.forEach(function (element: any) {
				element.value = element.id;
				element.label = element.nom;
			})
			categories.unshift(
				{
					value: "all",
					label: "Tous les types de dossier"
				})
			setTypeOptions(categories)
		}
	}, [categorieSelector.categories])

	useEffect(() => {
		if (remunerationSelector.stylesMusicaux) {
			let musiques: any = [...remunerationSelector.stylesMusicaux as StyleMusicalDto[]];
			musiques.forEach(function (element: any) {
				element.value = element.id;
				element.label = element.intitule.fr;
			})
			musiques.unshift({
				value: "all",
				label: "Tous les styles musicaux"
			})
			setTypeMusicalOptions(musiques);
		}
	}, [remunerationSelector.stylesMusicaux])

	const openAddCritereModal = () => {
		setModalAddCritereIsOpen(true);
	}

	const openAddRemunerationModal = () => {
		setModalAddRemunerationIsOpen(true);
	}



	/** Permissions */
	const usePermCreateCritereDossier = useHasPermission("CreateCritereDossier");
	const usePermGetCriteresDossier = useHasPermission("GetCriteresDossier");

	return (
		<PagesContainer title="Administration - Gestion des critères et rémunérations" pageClass="categoriesFormeJuridique">
			<Tabs className="react-tabs--page" onSelect={index => setTabIndex(index)}>
				<div className="gestionCategoriesDossier__header">
					<TabList>
						{usePermGetCriteresDossier &&
							<Tab>{t('folders-categories.tabs.folders-criterias')}</Tab>
						}
						<Tab>{t('folders-categories.tabs.remunerations')}</Tab>
					</TabList>
					{usePermGetCriteresDossier && tabIndex === 0 && (
						<div className="gestionCategoriesDossier__filters_and_button">
							<div className="filtres__criteres">
								<CriteresFilters
									typeOptions={typeOptions}
									setType={setTypeFilterCriteres}
									setVersion={setVersionFilterCriteres}
									typeCritere={typeFilterCriteres}
									versionCritere={versionFilterCriteres}
								/>
							</div>
							{usePermCreateCritereDossier &&
								<div className="categorieFormeJuridique-header__button" onClick={openAddCritereModal}>
									<label>Ajouter un nouveau critère</label>
									<button className="categorieFormeJuridique-header__icon">
										<i className="far fa-plus"></i>
									</button>
								</div>
							}
						</div>
					)}
					{tabIndex === 1 && (
						<div className="gestionCategoriesDossier__filters_and_button">
							<div className="filtres__criteres">
								<RemunerationsFilters
									setTypeDossier={setCategorieIdFilterRemunerations}
									setTypeArtiste={setEmploiFilterRemunerations}
									setTypeSession={setTypePrestationFilterRemunerations}
									setTypeMusique={setStyleMusicalIdFilterRemunerations}
									setMontantMinimum={setMontantMinFilterRemunerations}
									typeDossier={categorieIdFilterRemunerations}
									typeArtiste={emploiFilterRemunerations}
									typeSession={typePrestationFilterRemunerations}
									typeMusique={styleMusicalIdIdFilterRemunerations}
									montantMinimum={montantMinFilterRemunerations}
									typeDossierOptions={typeOptions}
									typeMusiqueOptions={typeMusicalOptions}
								/>
							</div>
							<div className="categorieFormeJuridique-header__button" onClick={openAddRemunerationModal}>
								<label>Ajouter un nouveau tarif</label>
								<button className="categorieFormeJuridique-header__icon"><i className="far fa-plus"></i></button>
							</div>
						</div>
					)}
				</div>

				{usePermGetCriteresDossier &&
					<TabPanel>
						<CriteresDossier
							modalAddCritereIsOpen={modalAddCritereIsOpen}
							setModalAddCritereIsOpen={setModalAddCritereIsOpen}
							typeFilter={typeFilterCriteres}
							versionFilter={versionFilterCriteres}
						/>
					</TabPanel>
				}

				<TabPanel>
					<Remunerations
						isActive={tabIndex === 1}
						modalAddRemunerationIsOpen={modalAddRemunerationIsOpen}
						setModalAddRemunerationIsOpen={setModalAddRemunerationIsOpen}
						categorieIdFilterRemunerations={categorieIdFilterRemunerations}
						emploiFilterRemunerations={emploiFilterRemunerations}
						typePrestationFilterRemunerations={typePrestationFilterRemunerations}
						styleMusicalIdIdFilterRemunerations={styleMusicalIdIdFilterRemunerations}
						montantMinFilterRemunerations={montantMinFilterRemunerations}
						montantMaxFilterRemunerations={montantMaxFilterRemunerations}
					/>
				</TabPanel>
			</Tabs>
		</PagesContainer>
	);
}

export default GestionCriteresRemunerations;