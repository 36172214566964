import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from "@reach/router";
import { useParams } from "@reach/router";
import { useDispatch } from 'react-redux';

import { fetchStructureInstruction, resetInstructionFiles } from '../../store/structure-store/actions/structureInstructionActions';
import { fetchStructureRelations } from '../../store/structure-store/actions/structureDetailsActions';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import DetailsContainer from "../../containers/DetailsContainer";
import StructureDetailsTab from "./details-tab/StructureDetailsTab";
import StructureInstructionTab from "./instruction-tab/StructureInstructionTab";
import StructureHistoriqueTab from "./historique-tab/StructureHistoriqueTab";
import StructureMessagesTab from "./message-tab/StructureMessagesTab";
import StructureNoteTab from "./note-tab/StructureNoteTab";
import { useStructureSelector, useUserSelector } from '../../store/store-helpers';
import useValidation from '../../custom-hooks/useValidation';
import useHasPermission from '../../custom-hooks/useHasPermission';
import StructureBalanceTab from './balance-tab/StructureBalanceTab';
import StructureDocumentsTab from './document-tab/StructureDocumentsTab';
import { fetchStructureDocuments, resetDocumentsFiles } from '../../store/structure-store/actions/structureDocumentsActions';

interface StructureDetailsProps extends RouteComponentProps {
}

const StructureDetails: React.FC<StructureDetailsProps> = () => {
	const dispatch = useDispatch();
	const location = useParams();
	const id = location.structureId;

	const { getRootValidator } = useValidation();
	const validator = getRootValidator("UpdateStructureDetailsDto");
	const relationValidator = getRootValidator("CreateRelationDto");
	const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");
	const {structureDetails, structureRelations} = useStructureSelector();


	/** Permissions */
	const usePermGetStructureDetails = useHasPermission("GetStructureDetails");
	const usePermGetStructureHistory = useHasPermission("GetStructureHistory");
	const usePermGetStructureInstruction = useHasPermission("GetStructureInstruction");
	const usePermGetStructureNote = useHasPermission("GetStructureNote");
	const canUpdateDocumentsValidation = useHasPermission("UpdateStructureDocumentsValidation");
	const usePermGetStructureBalance = useHasPermission("GetStructureBalance");


    const { user = {} } = useUserSelector();

	useEffect(() => {
		if(!id) return;
		fetchStructureRelations(dispatch, id);
		fetchStructureInstruction(dispatch, id)();
		fetchStructureDocuments(dispatch, id)();
		dispatch(resetDocumentsFiles())
		dispatch(resetInstructionFiles())
	}, [id]);

	return (
		<DetailsContainer
			header={
				<>
					<h2 className="details-header-titles__subtitle">Détails de la structure</h2>
					<h1 className="details-header-titles__title">{structureDetails?.raisonSociale}</h1>
				</>
			}
			dossierId="6-2iUTO5_wwIa4"
			structureId={id}
			relationsStructure={structureRelations}
			validatorRelation={relationValidator}
			isFromDossier={false}
		>
			<>
				<div className="navigationFil">
            	    <Link className="navigationFil__item" to="/Gestion/Structures">
             	       <i className="fas fa-chevron-left"></i>
             	       Retour à la liste des structures
              	 	</Link>
         	   </div>
				<Tabs className="react-tabs--details">
					<TabList>
						{usePermGetStructureDetails && <Tab>Détails</Tab>}
						{usePermGetStructureInstruction && <Tab>Instruction</Tab>}
						{usePermGetStructureHistory && <Tab>Historique</Tab>}
						{usePermGetStructureBalance && <Tab>Balance</Tab>}
						<Tab>Messages</Tab>
						{usePermGetStructureNote && <Tab>Évaluation relations structure</Tab>}
						<Tab>Documents</Tab>
					</TabList>

					{usePermGetStructureDetails && (
						<TabPanel>
							<StructureDetailsTab
								id={id}
								canUpdateDocumentsValidation={canUpdateDocumentsValidation}
								validator={validator}
								adresseValidator={adresseValidator}
							/>
						</TabPanel>
					)}
					{usePermGetStructureInstruction && (
						<TabPanel>
							<StructureInstructionTab
								structureId={id}
							/>
						</TabPanel>
					)}
					{usePermGetStructureHistory && (
						<TabPanel>
							<StructureHistoriqueTab id={id} />
						</TabPanel>
					)}
					{usePermGetStructureBalance && (
						<TabPanel>
							<StructureBalanceTab
								structureId={id}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<StructureMessagesTab
							structureId={id}
							currentUser={user}
						/>
					</TabPanel>
					{usePermGetStructureNote && (
						<TabPanel>
							<StructureNoteTab
								structureId={id}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<StructureDocumentsTab
							structureId={id}
						/>
					</TabPanel>
				</Tabs>
		    </>
		</DetailsContainer>
	);
}

export default StructureDetails;
