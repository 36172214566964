import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputRadioYesNo from 'adel-shared/dist/components/basics/InputRadioYesNo';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { VersementDetailsDto } from '../../../../services/generated/BackOffice-api';
import { updateDsvVersement } from '../../../../store/dossier-store/actions/dossierVersementActions';
import { useDossierDispatcher, useDossierSelector } from '../../../../store/store-helpers';
import { VersementInformations } from '../VersementTab';


interface VersementValidationProps {
	versementInfos?: VersementInformations;
	signatureALD?: boolean;
	details?: VersementDetailsDto;
}

const VersementValidation: React.FunctionComponent<VersementValidationProps> = ({
	versementInfos,
	details
}) => {
	const form = useForm();
	const { setValue, control } = form;

	const [ isLoading, setIsLoading ] = useState<Boolean>();
	const {dossierDetails} = useDossierSelector();
	const dispatch = useDossierDispatcher();

	useEffect(() => {
		if(details) {
			setValue([
				{ 'enCoursValidationDSV': details.enCoursValidationDSV || false },
				{ 'aldSigne': details.aldSigne || false },
				{ 'versementValide': details.valideParDSV || false },
				{ 'commentairesDSV': details.commentairesDSV },
			]);
		}
	}, [details]);


	const send = async () => {
		setIsLoading(true);
		const isValid = await form.triggerValidation();

		if(!isValid) {
			toast.error('Erreur dans le formulaire');

			setIsLoading(false);
			return;
		}

		/** Dsv */
		if(dossierDetails?.dsv) {
			const { aldSigne, enCoursValidationDSV, versementValide, commentairesDSV } = form.getValues();

			versementInfos && await updateDsvVersement(dispatch, versementInfos?.versementId, {
				aldSigne,
				enCoursValidationDSV,
				valideParDSV: versementValide,
				commentairesDSV
			});

			toast.success('Les autres informations ont été enregistrées avec succès');
		}
		setIsLoading(false);
	}

	return (
		<div className="versementTab__validation">
			{dossierDetails?.dsv &&
			<section>
				<h3 className="detailsTab__title">Autres informations</h3>
				<div className="versementTab__validationCheckbox">
					<Controller control={control}
						name="enCoursValidationDSV"
						as={({ onChange, name, checked }) => (
							<InputRadioYesNo
								name={name}
								label="En cours de validation droit exclusif"
								onChange={onChange}
								value={checked}
							/>
						)}
					/>
					
					<Controller control={control}
						name="aldSigne"
						as={({ onChange, name, checked }) => (
							<InputRadioYesNo
								name={name}
								label="ALD signé"
								onChange={onChange}
								value={checked}
							/>
						)}
					/>
					
					<Controller control={control}
						name="versementValide"
						as={({ onChange, name, checked }) => (
							<InputRadioYesNo
								name={name}
								label="Versement validé"
								onChange={onChange}
								value={checked}
							/>
						)}
					/>
				</div>
				<div className="versementTab__validationTextarea">
					<div className="input">
						<label className="input__label">Commentaires droit exclusif</label>
					</div>
					<textarea
						ref={form.register()}
						name="commentairesDSV"
						defaultValue={details?.commentairesDSV || ''}
					></textarea>
				</div>
				<div className="detailsTab__footer">
				{isLoading ? (
					<Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					/>
				) : (
					<FormButton
						disabled={isLoading}
						type="submit"
						value="Enregistrer les autres informations"
						onClick={send}
					/>
				)}
			</div>
			</section>}
			{/* TODO: Possiblement unecessary
			<section>
				<div className="versementTab__validationBlocage">
					<h3 className="detailsTab__title">Blocage juridique</h3>
					<Toggle
						value={isBlocked}
						setCheck={setIsBlocked}
						disabled={editBlocked}
					/>
					{editBlocked && <div className="versementTab__tooltip">Vous ne pouvez pas modifier le blocage à ce stade.</div>}
				</div>
				<div className="versementTab__validationCommentaireBlocage">
					<label className="input__label">Commentaire</label>
					<div></div>
				</div>
			</section> */}
		</div>
	);
}

export default VersementValidation;