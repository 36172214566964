import React, {
	useState,
	useEffect,
	SetStateAction,
	Dispatch as ReactDispatch
} from 'react';
import { RouteComponentProps } from '@reach/router';
import { modalMediumCustomStyles } from '../../../constants/config.constant';
import Modal from 'react-modal';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Loader from 'react-loader-spinner';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import Input from 'adel-shared/dist/components/basics/Input';
import { useCategorieDossierSelector, useRemunerationSelector } from '../../../store/store-helpers';
import { useDispatch } from 'react-redux';
import { delRemuneration, saveRemuneration } from '../../../store/remuneration-store/actions/remunerationAction';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Emploi, RemunerationDto, TypePrestation } from '../../../services/generated/BackOffice-api';

const categoriesSelectStyles = {
	option: (provided: any) => ({
		...provided,
		whiteSpace: 'pre-line'
	})
};
interface RemunerationForm {
	emploi: string;
	typePrestation: string;
	styleMusicalId: string;
	estSalaire: string;
	categorieDossierId: string;
	critereDossierId: string;
	montantMinimum: string;
}
interface RemunerationsModalProps extends RouteComponentProps {
	modalAddRemunerationIsOpen: boolean;
	setModalAddRemunerationIsOpen: ReactDispatch<SetStateAction<boolean>>;
	modalDeleteRemunerationIsOpen: boolean;
	setModalDeleteRemunerationIsOpen: ReactDispatch<SetStateAction<boolean>>;
	selectedRemunerationId: string;
	setSelectedRemunerationId: ReactDispatch<SetStateAction<string>>;
	remunerations: RemunerationDto[];
	setRemunerations: ReactDispatch<SetStateAction<RemunerationDto[]>>;
	updateRemunerations: () => void;
}

const RemunerationsModal: React.FunctionComponent<RemunerationsModalProps> = ({
	modalAddRemunerationIsOpen,
	setModalAddRemunerationIsOpen,
	modalDeleteRemunerationIsOpen,
	setModalDeleteRemunerationIsOpen,
	selectedRemunerationId,
	remunerations,
	setRemunerations,
	updateRemunerations
}) => {
	const { t } = useTranslation(); 
	const dispatch = useDispatch();
	const { getValues, setValue, control, register, watch } = useForm<RemunerationForm>({
		defaultValues: {
			categorieDossierId: '',
			emploi: '',
			typePrestation: '',
			styleMusicalId: '',
			estSalaire: "false",
			critereDossierId: '',
			montantMinimum: ''
		}
	});
	const {
		categorieDossierId,
		critereDossierId,
		montantMinimum
	} = watch([
		'categorieDossierId',
		'critereDossierId',
		'montantMinimum'
	]);

	const {viewModelRemuneration, remunerationIsLoading} = useRemunerationSelector();
	const {categories = []} = useCategorieDossierSelector();

	const [categoriesDossierForCreation, setCategoriesDossierForCreation] = useState<AdelOption<string>[]>([]);
	const [emploiForCreation, setEmploiForCreation] = useState<AdelOption<string>[]>([]);
	const [optionPrestationForCreation, setOptionPrestationForCreation] = useState<AdelOption<string>[]>([])
	const [stylesMusicauxForCreation, setStylesMusicauxForCreation] = useState<AdelOption<string>[]>([]);
	console.log('tt', categoriesDossierForCreation)

	const [remunerationIsSending, setRemunerationIsSending] = useState<boolean>(false);
	const [criteresPlanifies, setCriteresPlanifies] = useState<AdelOption<string>[]>([]);

	const [estSalaire, setEstSalaire] = useState<boolean>(false);

	// on check le type de dossier choisi pour proposer les autres options
	useEffect(() => {
		if (categorieDossierId && viewModelRemuneration) {
			//on réinitialise nos input
			setValue([
				{emploi: ''},
				{typePrestation: ''},
				{styleMusicalId: ''},
				{montantMinimum: ''}
			]);

			//on rerempli nos options pour chaque select
			viewModelRemuneration.map(elem => {
				if (categorieDossierId === elem.categorieDossier?.id) {
					if (elem.mensualisation) {
						setEstSalaire(true);
					} else {
						setEstSalaire(false)
					}

					const emplois = elem.emplois?.map(item => ({
						value: item,
						label: t(`groupesEmploi.${item}`)
					})) || [];
					setEmploiForCreation(emplois);

					const prestas = elem.prestations?.map(item => ({
						value: item,
						label: t(`shared.${item}`)
					})) || [];
					setOptionPrestationForCreation(prestas);

					const styles = elem.stylesMusicaux?.map(item => ({
						value: `${item.id}`,
						label: `${item.intitule?.fr}`
					})) || [];
					setStylesMusicauxForCreation(styles);

					const criteresPlanifies = elem.criteresDossier?.map(item => ({
						label: `Critère planifié au ${moment(item.dateDebut).format('DD/MM/YYYY')}`,
						value: `${item.id}`
					})) || [];

					setCriteresPlanifies(criteresPlanifies);
				}
			})
		}
	}, [
		categorieDossierId,
		viewModelRemuneration
	])

	useEffect(() => {
		if(categories?.length) {
			setCategoriesDossierForCreation(categories.map(c => {
				const opt: AdelOption<string> = {
					label: `${c.nom}`,
					value: `${c.id}`
				};

				if(!viewModelRemuneration?.some(x => x.criteresDossier && x.criteresDossier.length && c.id === x.categorieDossier?.id)) {
					opt.label = `${opt.label}
						(Aucun critères planifiés)`;
					opt.disabled = true;
				}

				return opt;
			}));
		}
	}, [
		categories,
		viewModelRemuneration
	]);
	console.log('hh', categories, viewModelRemuneration)

	// Ajouter une rémuneration
	const handleSendRemuneration = () => {
		const {
			emploi,
			typePrestation,
			estSalaire,
			styleMusicalId,
			montantMinimum,
			...values
		} = getValues();
		const createRemunerationDto = {
			...values,
			emploi: emploi == "" ? Emploi.None as Emploi :  emploi as Emploi,
			typePrestation: typePrestation || null as (TypePrestation | null),
			estSalaire: estSalaire === "false" ? false : true,
			montantMinimum: parseFloat(montantMinimum)
		};

		setRemunerationIsSending(true);
        //@ts-ignore
		saveRemuneration(dispatch, createRemunerationDto)().then(() => {
			setRemunerationIsSending(false);
			updateRemunerations()
			setModalAddRemunerationIsOpen(false);
			setEstSalaire(false);
		});
	};

	const handleDeleteRemuneration = () => {
		delRemuneration(dispatch, selectedRemunerationId)().then(() => {
			setRemunerations(remunerations.filter(x => x.id !== selectedRemunerationId));
			setModalDeleteRemunerationIsOpen(false);
		});
	}

	return (
		<>
			{
				modalAddRemunerationIsOpen &&
				<Modal
					isOpen={modalAddRemunerationIsOpen}
					style={modalMediumCustomStyles}
					className="ajoutTarif__modal"
				>
					<div className="ajoutCritere__wrapper">
						<div className="modal__header">
							<h3>Ajouter un nouveau tarif</h3>
						</div>

						<div className="modal__content">
							<div className="modal__grid">
								<div className="modal__input">
									<Controller
										control={control}
										name="categorieDossierId"
										as={({ name, onChange, value }) => (
											<InputSelect
												name={name}
												label="Type de dossier"
												classname="inputSelect"
												styles={categoriesSelectStyles}
												options={categoriesDossierForCreation}
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</div>

								{categorieDossierId && (
									<>
										{(emploiForCreation && emploiForCreation.length > 0) && (
											<div className="modal__input">
												<Controller
													control={control}
													name="emploi"
													as={({onChange, value, name}) => (
														<InputSelect
															name={name}
															label="Type de l'artiste"
															classname="inputSelect"
															options={emploiForCreation}
															onChange={onChange}
															value={value}
														/>
													)}
												/>
											</div>
										)}

										{(optionPrestationForCreation && optionPrestationForCreation.length > 0) && (
											<div className="modal__input">
												<Controller
													control={control}
													name="typePrestation"
													as={({onChange, name, value}) => (
														<InputSelect
															name={name}
															label="Type de session"
															classname="inputSelect"
															options={optionPrestationForCreation}
															onChange={onChange}
															value={value}
														/>
													)}
												/>
											</div>
										)}
										
										{estSalaire && (
											<div className="modal__input">
												<Controller control={control}
													name="estSalaire"
													as={({ onChange, value, name }) => (
														<InputSelect<string>
															name={name}
															label="Type de montant"
															classname="inputSelect"
															options={[
																{
																	label: "Cachet",
																	value: "false"
																},
																{
																	label: "Mensualisation",
																	value: "true"
																}
															]}
															onChange={onChange}
															value={value}
														/>
													)}
												/>
											</div>
										)}

										{criteresPlanifies.length > 0 && (
											<div className="modal__input">
												<Controller control={control}
													name="critereDossierId"
													as={({ onChange, value, name }) => (
														<InputSelect<string>
															name={name}
															label="Critères planifiés"
															classname="inputSelect"
															options={criteresPlanifies}
															onChange={onChange}
															value={value}
														/>
													)}
												/>
											</div>
										)}

										<div className="modal__input">
											<Input
												name="montantMinimum"
												label="Montant minimum"
												type="number"
												reference={register()}
											/>
										</div>
									</>
								)}
							</div>
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalAddRemunerationIsOpen(false);
							}}
						/>

						{remunerationIsSending ? (
							<Loader
								type="TailSpin"
								width={35}
								height={35}
								color="#d93943"
							/>
						) : (
							<FormButton
								type="submit"
								value="Enregistrer"
								disabled={
									!categorieDossierId
									|| !montantMinimum
									|| !critereDossierId
								}
								onClick={handleSendRemuneration}
							/>
						)}
					</div>
				</Modal>
			}
			{modalDeleteRemunerationIsOpen &&
				<Modal
					isOpen={modalDeleteRemunerationIsOpen}
					style={modalMediumCustomStyles}
					className="normal__modal"
				>
					<div className="modal__header">
						<h3>Supprimer le tarif</h3>
					</div>

					<div className="modal__content">
						<div className="modal__item">
							Voulez-vous vraiment supprimer ce tarif ?
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalDeleteRemunerationIsOpen(false);
							}}
						/>
						{remunerationIsLoading ? (
							<Loader
								type="TailSpin"
								width={35}
								height={35}
								color="#d93943"
							/>
						) : (
							<FormButton
								type="submit"
								value="Supprimer"
								onClick={handleDeleteRemuneration}
							/>
						)}
					</div>
				</Modal>
			}
		</>

	);
}

export default RemunerationsModal;
