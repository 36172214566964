import React, { useState, useEffect } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCommissionSelector } from "../../../store/store-helpers";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { toast } from "react-toastify";
import { updateCommissionCAContent } from "../../../store/commission-store/actions/commissionActions";
import WysiwygEditor from "adel-shared/dist/components/WysiwygEditor";

const SessionCATab: React.FunctionComponent<RouteComponentProps> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [pointsDivers, setPointsDivers] = useState<string>("");

	const commissionSelector = useCommissionSelector();
	const commission = commissionSelector.commission;

	useEffect(() => {
		if(commission?.pointsDiversCA) {
			setPointsDivers(commission.pointsDiversCA);
		}
	}, [commission]);

	const handleSubmit = async() => {
		if(!commission?.id) return;

		try {
			await updateCommissionCAContent(dispatch, commission.id, { pointsDivers });
			toast.success(t(`common.success`));
		} catch(error) {
			toast.error(t(`errorApi.${error.response.data.code}`));
		}
	};

	return (
		<section className="commissionTab">
			<div className="input">
				<label>Points divers</label>
				<WysiwygEditor
					editorValueContent={pointsDivers}
					setEditorValueContent={setPointsDivers}
				/>
			</div>
			<div className="editCommission__footer">
				<FormButton
					className="button__cancel"
					type="button"
					value="Annuler"
					onClick={() => navigate("/Commission/ListeCommissions")}
				/>
				<FormButton
					type="submit"
					value="Enregistrer"
					onClick={handleSubmit}
				/>
			</div>
		</section>
	);
};

export default SessionCATab;
