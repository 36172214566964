import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from "@reach/router";
import { useParams } from "@reach/router";
import { useDispatch } from 'react-redux';

import { cleanupDossierDetails, fetchDossierRelations, getDossierMouvements, getDossierSyntheseFinanciere, getEvenementsFinanciersViewModel } from '../../store/dossier-store/actions/dossierDetailsActions';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import DetailsContainer from "../../containers/DetailsContainer";
import DossierDetailsTab from "./details-tab/DossierDetailsTab";
import DossierInstructionTab from "./instruction-tab/DossierInstructionTab";
import DossierHistoriqueTab from './historique-tab/DossierHistoriqueTab';
import DossierNoteTab from './note-tab/DossierNoteTab';
import VersementTab from './versement-tab/VersementTab';
import DossierDsvTab from './dsv-tab/DossierDsvTab';
import { useDossierSelector, useUserSelector } from '../../store/store-helpers';
import { StatutDossier } from '../../services/generated/BackOffice-api';
import useValidation from '../../custom-hooks/useValidation';
import useHasPermission from '../../custom-hooks/useHasPermission';
import DossierMessagesTab from './message-tab/DossierMessagesTab';
import FluxTab from './flux-tab/FluxTab';
import SyntheseDossier from './synthese/SyntheseDossier';

interface DossierDetailsProps extends RouteComponentProps {
}

const DossierDetails: React.FunctionComponent<DossierDetailsProps> = () => {
	const dispatch = useDispatch();
	const location = useParams();
	const id = location.dossierId;
	const {
		dossierDetails: details = {},
		dossierRelations,
	} = useDossierSelector();
    const { user = {} } = useUserSelector();

	const { getRootValidator } = useValidation();
	const dsvValidator = getRootValidator("UpdateDossierDSVDto");
	const relationValidator = getRootValidator("CreateRelationDto");
	
	/** Permissions */
	const usePermGetDossierDetails = useHasPermission("GetDossierDetails");
	const usePermGetDossierDSV = useHasPermission("GetDossierDSV");
	const usePermGetDossierInstruction = useHasPermission("GetDossierInstruction");
	const usePermGetDossierHistory = useHasPermission("GetDossierHistory");
	const usePermGetDossierRelations = useHasPermission("GetDossierRelations");
	const usePermGetDossierNote = useHasPermission("GetDossierNote");

	useEffect(() => {
		(async () => {
			if(!id || !usePermGetDossierRelations) return;
			await fetchDossierRelations(dispatch, id);
		})();
	}, [id, usePermGetDossierRelations]);
	
	const hasFluxTab = details.statut === StatutDossier.Accepte 
	|| details.statut === StatutDossier.Solde 
	|| details.statut === StatutDossier.Renonciation
	|| details.statut === StatutDossier.Reaffecte;

	useEffect(() => {
	
		// Cleanup function when the component unmounts
		return () => {
		  // Unload or clean up data when the component unmounts
		  // For example, cancel any pending requests or clear data
		  dispatch(cleanupDossierDetails())
		};
	  }, []);
	  
	return (
		<DetailsContainer
			dossierId={id}
			structureId={details.structure?.id}
			header={
				<>
					{(details.numero && details.categorie) && (
						<p>N°{details.numero} / {details.categorie?.nom}</p>
						)}
					<h1 className="details-header-titles__title">{details.nom}</h1>
				</>
			}
			relationsDossier={dossierRelations?.dossierRelations}
			relationsStructure={dossierRelations?.structureRelations}
			validatorRelation={relationValidator}
			isFromDossier
		>
			<>
			    <div className="navigationFil">
                    <Link className="navigationFil__item" to="/Gestion/">
                        <i className="fas fa-chevron-left"></i>
                        Retour à la liste des dossiers
                    </Link>
                </div>

				<div className="row">
					<SyntheseDossier />
				</div>

				<Tabs className="react-tabs--details">
					<TabList>
						{usePermGetDossierDetails && <Tab>Détails</Tab>}
						{usePermGetDossierInstruction && <Tab>Instruction</Tab>}
						{usePermGetDossierDSV && details.dsv && <Tab>Droit Exclusif</Tab>}
						{usePermGetDossierHistory && <Tab>Historique</Tab>}
						<Tab>Versement</Tab>
						{hasFluxTab && 
							<Tab>Flux financiers</Tab>
						}
						<Tab>Messages</Tab>
						{usePermGetDossierNote && <Tab>Note</Tab>}
					</TabList>

					{usePermGetDossierDetails && 
						<TabPanel>
							<DossierDetailsTab
								currentUser={user}
								id={id}
							/>
						</TabPanel>
					}
					{usePermGetDossierInstruction &&
						<TabPanel>
							<DossierInstructionTab dossierId={id} />
						</TabPanel>
					}
					{usePermGetDossierDSV && details.dsv &&
						<TabPanel>
							<DossierDsvTab dossierId={id} validator={dsvValidator} />
						</TabPanel>
					}
					{usePermGetDossierHistory &&
						<TabPanel>
							<DossierHistoriqueTab id={id} />
						</TabPanel>
					}
					<TabPanel>
						<VersementTab dossierId={id} structureId={details.structure?.id} />
					</TabPanel>
					{hasFluxTab &&
						<TabPanel>
							<FluxTab dossierId={id} />
						</TabPanel>
					}
					{<TabPanel>
						<DossierMessagesTab dossierId={id}  currentUser={user} />
					</TabPanel>
					}
					{usePermGetDossierNote &&
						<TabPanel>
							<DossierNoteTab dossierId={id} />
						</TabPanel>
					}
				</Tabs>
			</>
		</DetailsContainer>
	);
}

export default DossierDetails;