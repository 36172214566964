import React from 'react';
import moment from 'moment';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { VersementDetailsDto } from '../../../../services/generated/BackOffice-api';
import { formatNumber } from 'adel-shared/dist/utils/functions';

interface VersementInfosProps {
    details?: VersementDetailsDto;
}

const VersementInfos: React.FunctionComponent<VersementInfosProps> = ({
    details
}) => {
    return (
        <section className="detailsTab__section">
            <div className="detailsTab__row">
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Numéro référence dossier"
                            content={details?.numero || '-'}
                        />
                    </div>
                </div>
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Type de dossier"
                            content={details?.categorieDossier || '-'}
                        />
                    </div>
                </div>
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Nom du projet"
                            content={details?.nom || '-'}
                        />
                    </div>
                </div>
            </div>
            <div className="detailsTab__row">
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Base de calcul de la SPEDIDAM"
                            content={details?.baseCalcul ? `${formatNumber(details.baseCalcul)} €` : '-'}
                        />
                    </div>
                </div>
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Montant demandé à la SPEDIDAM"
                            content={details?.montantDemande ? `${formatNumber(details.montantDemande)} €` : '-'}
                        />
                    </div>
                </div>
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Montant accordé en commission"
                            content={details?.montantAttribue ? `${formatNumber(details.montantAttribue)} €` : '-'}
                        />
                    </div>
                </div>
            </div>
            <div className="detailsTab__row">
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Votre projet se déroule"
                            content={details?.dateProjetDebut && details?.dateProjetFin ? `du ${moment(details.dateProjetDebut).format('DD/MM/YYYY')} au ${moment(details.dateProjetFin).format('DD/MM/YYYY')}` : '-'}
                        />
                    </div>
                </div>
                {details?.dateProlongation && (	
                    <div className="detailsTab__column">
                        <div className="detailsTab__content">
                            <InputReadOnly
                                label="Date de prolongation"
                                content={moment(details.dateProlongation).format('DD/MM/YYYY')}
                            />
                        </div>
                    </div>
                )}
				
                <div className="detailsTab__column">
                    <div className="detailsTab__content">
                        <InputReadOnly
                            label="Montant restant à verser"
                            content={details?.aideAVerse ? `${formatNumber(details.aideAVerse)} €` : '-'}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
};

export default VersementInfos;