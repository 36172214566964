import {
	BOCurrentUserDto,
	RoleDto,
	BOAdelUserDto
} from '../../../services/generated/BackOffice-api';

export interface Permission {
	[key: string]: string[];
}
export interface UserState {
	isConnected?: boolean;
	user?:BOCurrentUserDto;
	updatedUser?:BOAdelUserDto;
	users?: BOAdelUserDto[];
	roles?: RoleDto[];
	isCreateLoading? :boolean;
	errorUserCode?: string;
	permissions?: Permission;
}

export const initialUserState: UserState = {
	isConnected: false,
	user: {},
	updatedUser: {},
	users: [],
	roles: [],
	permissions: {},
	isCreateLoading: false,
	errorUserCode: "",
};
