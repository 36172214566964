
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { VersementClient, DocumentValidationDto, UpdateDocumentsValidationDto } from '../../../services/generated/BackOffice-api';

/** Task */
const versementClient = new VersementClient('', axiosInstance);

export const updateVersementDocumentsValidation = async(
    id: string,
    documents: DocumentValidationDto[]
) => {
	try {
        const result = await versementClient.updateVersementDocumentsValidation(id, { documents });
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour la validation de documents");
		throw error;
	}
};

export const updateVersementArtistesValidation = async(id:string, body:UpdateDocumentsValidationDto) => {
	try {
        const result = await versementClient.updateVersementArtistesValidation(id, body);
		toast.success("La validation des documents des artistes a bien été envoyée");
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour la validation d'artistes");
		throw error;
	}
}

export const updateVersementDepensesValidation = async(id:string, body:UpdateDocumentsValidationDto) => {
	try {
        const result = await versementClient.updateVersementDepensesValidation(id, body);
		toast.success("La validation des dépenses de versement a bien été envoyée");
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de mettre à jour la validation des dépenses de versement");
		throw error;
	}
}
