import { DossierClient } from '../../../services/generated/BackOffice-api';
import { DossierActionsEnum } from './dossierAction';
import { UpdateDossierInstructionDto, AdelDossierClient } from "../../../clients/AdelDossierClient";
import { Dictionary } from 'adel-shared/dist/models';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../custom-hooks/useAxios';

/** Task */
const dossierClient = new DossierClient('', axiosInstance);
const adelDossierClient = new AdelDossierClient('', axiosInstance);

export const fetchDossierInstruction = async (dispatch: any, dossierId: string) => {
    dispatch({ type: DossierActionsEnum.GET_DOSSIER_INSTRUCTION_REQUEST });
    try {
        let result = await dossierClient.getDossierInstruction(dossierId);
        dispatch({ type: DossierActionsEnum.GET_DOSSIER_INSTRUCTION_SUCCESS, payload: { dossierInstruction: result } });
    } catch (error) {
        dispatch({ type: DossierActionsEnum.GET_DOSSIER_INSTRUCTION_ERROR });
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer l'instruction de dossier");
        throw error;
    }
}

export const sendDossierInstruction = async (dispatch: any, dossierId: string, updateDossierInstructionDto: UpdateDossierInstructionDto, files: Dictionary<File>) => {
    dispatch({ type: DossierActionsEnum.PUT_DOSSIER_INSTRUCTION_REQUEST });
    try {
        let result = await adelDossierClient.updateDossierInstruction(dossierId, updateDossierInstructionDto, files);
        dispatch({ type: DossierActionsEnum.PUT_DOSSIER_INSTRUCTION_SUCCESS, payload: { dossierInstruction: result } })
    } catch (error) {
        dispatch({ type: DossierActionsEnum.PUT_DOSSIER_INSTRUCTION_ERROR, payload: {errorCode: error.code } });
		// ERRORS géré là où on appelle cette fonction
        throw error;
    }
}
