import React, {
	useState,
	useEffect,
	useMemo,
	FunctionComponent,
	Dispatch as ReactDispatch,
	SetStateAction
} from 'react';
import { RouteComponentProps } from '@reach/router';
import RemunerationsModal from './RemunerationsModal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchRemuneration, getViewModelforRemuneration } from '../../../store/remuneration-store/actions/remunerationAction';
import _ from 'lodash';
import { useRemunerationSelector, useUserSelector } from '../../../store/store-helpers';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { useSortBy, useTable } from 'react-table';
import Pagination from 'adel-shared/dist/components/basics/Pagination';
import {
	Emploi,
	RemunerationDto,
	TypePrestation
} from '../../../services/generated/BackOffice-api';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { Dispatch as ReduxDispatch } from 'redux';
import { paginationNumbers } from '../../../constants/config.constant';


interface RemunerationsProps extends RouteComponentProps {
	isActive: boolean;
	modalAddRemunerationIsOpen: boolean;
	setModalAddRemunerationIsOpen: ReactDispatch<SetStateAction<boolean>>;
	categorieIdFilterRemunerations: string;
	emploiFilterRemunerations: string;
	typePrestationFilterRemunerations: string;
	styleMusicalIdIdFilterRemunerations: string;
	montantMinFilterRemunerations?: number;
	montantMaxFilterRemunerations?: number;
}

const Remunerations: FunctionComponent<RemunerationsProps> = ({
	isActive,
	modalAddRemunerationIsOpen,
	setModalAddRemunerationIsOpen,
	categorieIdFilterRemunerations,
	emploiFilterRemunerations,
	typePrestationFilterRemunerations,
	styleMusicalIdIdFilterRemunerations,
	montantMinFilterRemunerations,
	montantMaxFilterRemunerations,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {isConnected: isConnectedUser} = useUserSelector();
	const {viewModelRemuneration} = useRemunerationSelector();

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(false);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [totalItemCount, setTotalItemCount] = useState<number>(0);
	const [sortValue, setSortValue] = useState<string>();
	const [sortDirection, setSortDirection] = useState<string>();


	//Table value
	const [remunerations, setRemunerations] = useState<RemunerationDto[]>([]);
	const [data, setData] = useState<Array<any>>([]);

	//modales 
	const [modalDeleteRemunerationIsOpen, setModalDeleteRemunerationIsOpen] = useState<boolean>(false);

	//selectedRemuneration
	const [selectedRemunerationId, setSelectedRemunerationId] = useState<string>('');

	useEffect(() => {
		getViewModelforRemuneration(dispatch)();
	}, []);

	/** Get the data for the table */
	useEffect(() => {
		if (remunerations) {
			const data = remunerations.map(remuneration => {
				const dataObj = {
					Id: remuneration.id,
					CategorieDossier: remuneration.categorieDossier?.nom,
					Emploi: remuneration.emploi && t(`groupesEmploi.${remuneration.emploi}`),
					TypePrestation: remuneration.typePrestation && t(`shared.${remuneration.typePrestation}`),
					MontantMinimum: `${formatNumber(remuneration.montantMinimum)} €`,
					Actions: <></>
				};

				if(
					!viewModelRemuneration?.some(x => 
						x.categorieDossier?.id === remuneration.categorieDossier?.id &&
						x.criteresDossier?.some(y =>
							y.dateDebut && new Date(y.dateDebut).getTime() <= new Date().getTime()
						)
					)
					|| true // TODO : A retirer après correction des dates de critères dossier en back
				) {
					dataObj.Actions = (
						<span>
							<i
								onClick={() => deleteRemuneration(remuneration.id as string)}
								className="far fa-trash-alt gestionCategoriesDossier__icon"
							></i>
						</span>
					);
				}

				return dataObj;
			});
			setData(data);
		}
	}, [
		remunerations,
		viewModelRemuneration
	]);

	const deleteRemuneration = (id: string) => {
		setSelectedRemunerationId(id)
		setModalDeleteRemunerationIsOpen(true)
	}

	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: t('folders-categories.type-dossier'),
						accessor: "CategorieDossier",
						sortType: "basic",

					},
					{
						Header: t('folders-categories.type-artist'),
						accessor: "Emploi",
						sortType: "basic",

					},
					{
						Header: t('folders-categories.type-session'),
						accessor: "TypePrestation",
						sortType: "basic",

					},
					{
						Header: t('folders-categories.montant-min'),
						accessor: "MontantMinimum",
						sortType: "basic",
					},
					{
						Header: "",
						accessor: "Actions",
						disableSortBy: true
					},
					{
						Header: "Id",
						accessor: "Id"
					}
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSortValue(value[0].id);
				setSortDirection("desc")
			} else {
				setSortValue(value[0].id);
				setSortDirection("asc")
			}
		} else {
			setSortValue("");
			setSortDirection("desc")
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	useEffect(() => {
		setPage(1)
	}, [
		pageSize,
		categorieIdFilterRemunerations,
		emploiFilterRemunerations,
		typePrestationFilterRemunerations,
		montantMinFilterRemunerations,
		styleMusicalIdIdFilterRemunerations
	])

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchRemuneration = useMemo(() => _.debounce((
		dispatch: ReduxDispatch, 
		categorieIdFilterRemunerations: string,
		emploiFilterRemunerations: string,
		typePrestationFilterRemunerations: string,
		styleMusicalIdIdFilterRemunerations: string,
		montantMinFilterRemunerations: number | undefined,
		montantMaxFilterRemunerations: number | undefined,
		page: number,
		pageSize: number,
		sortValue: any,
		sortDirection: any
	) => {
		fetchRemuneration(
			dispatch,
			categorieIdFilterRemunerations,
			emploiFilterRemunerations as Emploi,
			typePrestationFilterRemunerations as TypePrestation,
			styleMusicalIdIdFilterRemunerations,
			montantMinFilterRemunerations,
			montantMaxFilterRemunerations,
			page,
			pageSize,
			sortValue,
			sortDirection
		).then((result) => {
			//TODO: Uncomment juste ça quand Back sera fait
			if(result && result.items) {
				if (result.number && result.totalPageCount && result.number > result.totalPageCount)
					setPage(1);
				setRemunerations(result.items);
				setHasNext(result.hasNext || false);
				setHasPrevious(result.hasPrevious || false);
				setIsFirst(result.isFirst || false);
				setIsLast(result.isLast || false);
				setTotalPageCount(result.totalPageCount || 0);
				setTotalItemCount(result.totalItemCount || 0);
			}
		});
	}, 1000), []);

	//get rémunerations liste
	useEffect(() => {
		if (isConnectedUser && isActive) {
			debounceFetchRemuneration(
				dispatch,
				categorieIdFilterRemunerations === "all" ? '' : categorieIdFilterRemunerations === "all" ? '' : categorieIdFilterRemunerations,
				emploiFilterRemunerations === "all" ? '' : emploiFilterRemunerations,
				typePrestationFilterRemunerations === "all" ? '' : typePrestationFilterRemunerations,
				styleMusicalIdIdFilterRemunerations === "all" ? '' : styleMusicalIdIdFilterRemunerations,
				montantMinFilterRemunerations,
				montantMaxFilterRemunerations,
				page,
				pageSize,
				sortValue,
				sortDirection
			)
		}
	}, [
		categorieIdFilterRemunerations,
		emploiFilterRemunerations,
		typePrestationFilterRemunerations,
		styleMusicalIdIdFilterRemunerations,
		sortValue,
		page,
		pageSize,
		montantMinFilterRemunerations,
		montantMaxFilterRemunerations,
		fetchRemuneration,
		isConnectedUser
	]);

	const updateRemunerations = () => {
		debounceFetchRemuneration(
			dispatch,
			categorieIdFilterRemunerations === "all" ? '' : categorieIdFilterRemunerations === "all" ? '' : categorieIdFilterRemunerations,
			emploiFilterRemunerations === "all" ? '' : emploiFilterRemunerations,
			typePrestationFilterRemunerations === "all" ? '' : typePrestationFilterRemunerations,
			styleMusicalIdIdFilterRemunerations === "all" ? '' : styleMusicalIdIdFilterRemunerations,
			montantMinFilterRemunerations,
			montantMaxFilterRemunerations,
			page,
			pageSize,
			sortValue,
			sortDirection
		)
	}

	return (
		<>
			<Table
				data={data}
				getTableProps={getTableProps}
				getTableBodyProps={getTableBodyProps}
				headerGroups={headerGroups}
				rows={rows}
				prepareRow={prepareRow}
				styles={styleTable.page}
			/>
			<Pagination
				hasNext={hasNext}
				hasPrevious={hasPrevious}
				isFirst={isFirst}
				isLast={isLast}
				totalPageCount={totalPageCount}
				totalItemCount={totalItemCount}
				page={setPage}
				pageSize={setPageSize}
				initPageSize={pageSize}
				initPage={page}
				pageNumbers={paginationNumbers}
			/>
			<RemunerationsModal
				modalAddRemunerationIsOpen={modalAddRemunerationIsOpen}
				setModalAddRemunerationIsOpen={setModalAddRemunerationIsOpen}
				modalDeleteRemunerationIsOpen={modalDeleteRemunerationIsOpen}
				setModalDeleteRemunerationIsOpen={setModalDeleteRemunerationIsOpen}
				selectedRemunerationId={selectedRemunerationId}
				setSelectedRemunerationId={setSelectedRemunerationId}
				remunerations={remunerations}
				setRemunerations={setRemunerations}
				updateRemunerations={updateRemunerations}
			/>
		</>
	);
}

export default Remunerations;