import { toast } from 'react-toastify';
import { Action, Dispatch } from "redux";
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { CompteBancaireClient, CompteBancaireDto } from "../../../services/generated/BackOffice-api";
import { CompteBancaireState } from "../states/compteBancaireState";

export enum CompteBancaireActionsEnum {
	GET_COMPTES_BANCAIRES_SUCCESS = "GET_COMPTES_BANCAIRES_SUCCESS",
	CREATE_COMPTE_BANCAIRE_SUCCESS = "CREATE_COMPTE_BANCAIRE_SUCCESS",
	UPDATE_COMPTE_BANCAIRE_SUCCESS = "UPDATE_COMPTE_BANCAIRE_SUCCESS",
	DELETE_COMPTE_BANCAIRE_SUCCESS = "DELETE_COMPTE_BANCAIRE_SUCCESS"
}

export interface DispatchCompteBancaireAction extends Action<CompteBancaireActionsEnum> {
	payload: CompteBancaireState;
}

const compteBancaireClient = new CompteBancaireClient('', axiosInstance);

export const getAllComptesBancaires = async(
	dispatch?: Dispatch
) => {
	try {
		const result = await compteBancaireClient.getAllComptesBancaires();
		if(dispatch) {
			dispatch({
				type: CompteBancaireActionsEnum.GET_COMPTES_BANCAIRES_SUCCESS,
				payload: { comptesBancaires: result }
			});
		}
		return result;
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de récupérer les comptes bancaires");
		throw error;
	}
};

export const createOrUpdateCompteBancaires = async(
	dispatch: Dispatch,
	body: CompteBancaireDto,
	id?: string
) => {
	try {
		await compteBancaireClient.createOrUpdateCompteBancaire(body);
		dispatch({
			type: id ? CompteBancaireActionsEnum.UPDATE_COMPTE_BANCAIRE_SUCCESS : CompteBancaireActionsEnum.CREATE_COMPTE_BANCAIRE_SUCCESS,
			payload: { compteBancaire: body }
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de créer ou mettre à jour le compte bancaire");
		throw error;
	}
};

export const deleteCompteBancaire = async(
	dispatch: Dispatch,
	id: string
) => {
	try {
		await compteBancaireClient.deleteCompteBancaire(id);
		dispatch({
			type: CompteBancaireActionsEnum.DELETE_COMPTE_BANCAIRE_SUCCESS,
			payload: { compteBancaireId: id }
		});
	} catch(error) {
		if(error.response?.exception?.message)
			toast.error(error.response.exception.message);
		else if(error.exception?.message)
			toast.error(error.exception.message);
		else
			toast.error("Impossible de supprimer le compte bancaire");
		throw error;
	}
};