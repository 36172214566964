import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useHasPermission from '../../../../custom-hooks/useHasPermission';
import { DocumentValidationDto, VersementDetailsDto } from '../../../../services/generated/BackOffice-api';
import { updateVersementDocumentsValidation } from '../../../../store/versement-store/actions/versementAction';
import DocumentValidation from '../../../document-validation/DocumentValidation';

interface VersementDocumentsProps {
    details?: VersementDetailsDto;
	setMinoration: (value:boolean) => void;
}

const VersementDocuments: React.FunctionComponent<VersementDocumentsProps> = ({
    details,
	setMinoration
}) => {
    const { t } = useTranslation();
	const canUpdateVersementDocumentsValidation = useHasPermission("UpdateVersementDocumentsValidation");

    const handleSubmit = async(documents: DocumentValidationDto[]) => {
        if (details?.id) {
			const result = await updateVersementDocumentsValidation(details?.id, documents);
			toast.success("La validation des documents a bien été prise en compte.");
			setMinoration(result);
        }
    };

    return (
        <section className="detailsTab__section">
            <h3 className="detailsTab__title">Documents joints</h3>

			{details?.documents &&
				<DocumentValidation
					documents={details.documents}
					canUpdate={canUpdateVersementDocumentsValidation}
					onSubmit={handleSubmit}
				/>
			}
        </section>
    )
};

export default VersementDocuments;