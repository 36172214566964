import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import React, { SetStateAction, useEffect, useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { modalCustomStyles } from '../../../constants/config.constant';
import { CommissionDto, CreateGenerationSpedidamDto, GenerationSpedidamClient, GenerationSpedidamDto } from '../../../services/generated/BackOffice-api';
import Input from 'adel-shared/dist/components/basics/Input';
import { axiosInstance } from '../../../custom-hooks/useAxios';
import { modalMediumCustomStyles } from '../../../constants/config.constant';
import { deleteGenerationSpedidam } from '../../../store/gestion-entite-store/actions/generationSpedidiamAction';

interface CriteresDossierModalProps extends RouteComponentProps {
	modalAddIsOpen: boolean;
	setModalAddIsOpen: Dispatch<SetStateAction<boolean>>;	
	refreshGenerationSpedidam: () => void;
	typeMusicalOptions: any,
	modalDeleteGenerationSpedidamIsOpen: boolean,
	setModalDeleteGenerationSpedidamIsOpen:  Dispatch<SetStateAction<boolean>>,
	selectedGenerationId: string,
	generations: GenerationSpedidamDto[],
	setGenerations: any,
	selectedGeneration: GenerationSpedidamDto,
	modalEditGenerationSpedidamIsOpen: boolean,
	setModalEditGenerationSpedidamIsOpen: Dispatch<SetStateAction<boolean>>,
}

const CriteresDossierModal: React.FC<CriteresDossierModalProps> = ({
	modalAddIsOpen,
	setModalAddIsOpen,
	typeMusicalOptions,
	refreshGenerationSpedidam,
	modalDeleteGenerationSpedidamIsOpen,
	setModalDeleteGenerationSpedidamIsOpen,
	selectedGenerationId,
	generations,
	setGenerations,
	selectedGeneration,
	modalEditGenerationSpedidamIsOpen,
	setModalEditGenerationSpedidamIsOpen
}) => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	//Init states
	const [commissionsOptions, setCommissionsOptions] = useState<AdelOption<CommissionDto>[]>();
	const [values, setValues] = useState<CreateGenerationSpedidamDto>({
		nom: "",
		prenom: "",
		styleMusicalId: undefined,
		commissionId: undefined,
		montantEnveloppe: undefined,
		isActive: true,
	})

	const generationClient = new GenerationSpedidamClient('', axiosInstance);

	useEffect(() => {
		generationClient.getGenerationSpedidamCommissions().then(res => {
			const commissions = res.map(e => ({
				label: e.nom || '',
				value: e
			}));
			setCommissionsOptions(commissions)
		})
	}, [])

	useEffect(() => {
		if(modalEditGenerationSpedidamIsOpen){
			setValues(selectedGeneration)
		}
	}, [modalEditGenerationSpedidamIsOpen, selectedGeneration])

	useEffect(() => {		
		setValues({
			nom: "",
			prenom: "",
			styleMusicalId: undefined,
			commissionId: undefined,
			montantEnveloppe: undefined,
			isActive: true
		})
	}, [modalAddIsOpen])


	// Ajouter un artiste generation spedidam
	const handleCreateGenrationSpedidam = () => {
		generationClient.createGenerationSpedidam(values)
		setModalAddIsOpen(false)
		refreshGenerationSpedidam()
	};

	// Modifier un artiste generation spedidam
	const handleUpdateGenrationSpedidam = () => {
		generationClient.updateGenerationSpedidam(selectedGeneration.id, {
			nom: values.nom,
			prenom: values.prenom,
			styleMusicalId: values.styleMusicalId,
			//@ts-ignore
			commissionId: values.commissionId,
			montantEnveloppe: values.montantEnveloppe
		})
		setModalEditGenerationSpedidamIsOpen(false)
		refreshGenerationSpedidam()
	};

	/** change */

	const handleChange = (name: string, value: any) => {
		setValues((prev: any) => { return { ...prev, [name]: value } })
	}
	
	const handleDeleteGenerationSpedidam = () => {	
		deleteGenerationSpedidam(dispatch, selectedGenerationId)().then(() => {
			setGenerations(generations.filter(x => x.id !== selectedGenerationId))});
		setModalDeleteGenerationSpedidamIsOpen(false);		
	}
	
	return (
		<>
			{modalAddIsOpen &&
				<Modal
					isOpen={modalAddIsOpen}
					style={modalCustomStyles}
					className="ajoutCritere__modal"
				>

					<div className="ajoutCritere__wrapper">
						<div className="modal__header">
							<h3>Ajouter un Artiste (Génération Spedidam)</h3>
						</div>

						<div className="modal__content">
							<div className="detailsTab">
								<div className="detailsTab__section">
									<div className="detailsTab__row">
										<div className="modal__input" style={{ width: '90%' }}>
											<Input
												name="nom"
												label="Nom"
												type="text"
												maxLength={15}
												onChange={(ev) => handleChange("nom", ev)}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input" style={{ width: '90%' }}>
											<Input
												name="prenom"
												label="Prenom"
												type="text"
												maxLength={15}
												onChange={(ev) => handleChange("prenom", ev)}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input">
											<InputSelect
												name="styleMusicalId"
												label="Style musicale"
												classname="inputSelect"
												options={typeMusicalOptions}
												onChange={(ev) => handleChange("styleMusicalId", ev)}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input">
											<InputSelect
												name="commissionId"
												label="Commission"
												classname="inputSelect"
												options={commissionsOptions}
												onChange={(ev) => handleChange("commissionId", ev.id)}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input">
											<Input
												name="montantEnveloppe"
												label="Montant enveloppe"
												type="number"
												maxLength={15}
												onChange={(ev) => handleChange("montantEnveloppe", ev)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalAddIsOpen(false);
							}}
						/>

						<FormButton
							type="submit"
							value="Enregistrer"
							disabled={Object.values(values).some(x => x == undefined || x == null || x == '')}
							onClick={handleCreateGenrationSpedidam}
						/>
					</div>
				</Modal>
			}
			{modalDeleteGenerationSpedidamIsOpen &&
				<Modal
					isOpen={modalDeleteGenerationSpedidamIsOpen}
					style={modalMediumCustomStyles}
					className="normal__modal"
				>
					<div className="modal__header">
						<h3>Supprimer l'artiste</h3>
					</div>

					<div className="modal__content">
						<div className="modal__item">
							Voulez-vous vraiment supprimer cet artiste ?
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalDeleteGenerationSpedidamIsOpen(false);
							}}
						/>
							<FormButton
								type="submit"
								value="Supprimer"
								onClick={handleDeleteGenerationSpedidam}
							/>
					</div>
				</Modal>
			}
			{modalEditGenerationSpedidamIsOpen &&
				<Modal
					isOpen={modalEditGenerationSpedidamIsOpen}
					style={modalCustomStyles}
					className="ajoutCritere__modal"
				>

					<div className="ajoutCritere__wrapper">
						<div className="modal__header">
							<h3>Modifier un Artiste (Génération Spedidam)</h3>
						</div>

						<div className="modal__content">
							<div className="detailsTab">
								<div className="detailsTab__section">
									<div className="detailsTab__row">
										<div className="modal__input" style={{ width: '90%' }}>
											<Input
												name="nom"
												label="Nom"
												type="text"
												defaultValue={values.nom}
												maxLength={15}
												onChange={(ev) => handleChange("nom", ev)}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input" style={{ width: '90%' }}>
											<Input
												name="prenom"
												label="Prenom"
												type="text"
												defaultValue={values.prenom}
												maxLength={15}
												onChange={(ev) => handleChange("prenom", ev)}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input">
											<InputSelect
												name="styleMusicalId"
												label="Style musicale"
												classname="inputSelect"
												value={values.styleMusicalId}
												options={typeMusicalOptions}
												onChange={(ev) => handleChange("styleMusicalId", ev)}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input">
											<InputSelect
												name="commissionId"
												label="Commission"
												classname="inputSelect"
												//@ts-ignore
												value={selectedGeneration.commissionValues}
												options={commissionsOptions}
												onChange={(ev) => {handleChange("commissionId", ev.id)}}
											/>
										</div>
									</div>
									<div className="detailsTab__row">
										<div className="modal__input">
											<Input
												name="montantEnveloppe"
												label="Montant enveloppe"
												defaultValue={values.montantEnveloppe}
												type="number"
												maxLength={15}
												onChange={(ev) => handleChange("montantEnveloppe", ev)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="modal__footer">
						<FormButton
							type="button"
							value="Annuler"
							onClick={() => {
								setModalEditGenerationSpedidamIsOpen(false);
							}}
						/>

						<FormButton
							type="submit"
							value="Enregistrer"
							disabled={Object.values(values).some(x => x == undefined || x == null || x == '')}
							onClick={handleUpdateGenrationSpedidam}
						/>
					</div>
				</Modal>
			}
		</>

	);
}

export default CriteresDossierModal;