import React, { useState, useEffect, useMemo, FunctionComponent } from "react";
import { useSortBy, useTable } from 'react-table';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import MembreCheckboxTab from './MembreCheckboxTab';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { CommissionMembre } from '../MembresTab';
import { CommissionMemberDto } from "../../../../services/generated/BackOffice-api";

interface MembreTableTabProps {
	title: string;
	dataArray: CommissionMembre[];
	index: number;
	openAddModal: (tableId: string, membersToExclude: CommissionMembre[]) => void;
	openDeleteModal: (tableId: string, memberToDelete: CommissionMemberDto & CommissionMembre | null, membersToExclude: CommissionMembre[]) => void;
	tableId: string;
	onCheck: (tableId: string, membreId: string, isChecked: boolean) => void;
}

export interface MembrePresent {
	id?: string;
	present?: boolean;
}

const MembreTableTab: FunctionComponent<MembreTableTabProps> = ({
	dataArray,
	title,
	index,
	openAddModal,
	openDeleteModal,
	tableId,
	onCheck
}) => {
	const [data, setData] = useState<Array<any>>([]);
	const [sort, setSort] = useState<string>("");

	useEffect(() => {
		const array = dataArray?.map((data, i, src) => {
			const replacedMember = src.find(x => x.commissionMemberId === data.commissionMemberReplacedId);
			const replacingMember = src.find(x => x.commissionMemberId === data.commissionMemberReplacingId);
			let remplacementCol = '-';
			
			if(replacedMember) {
				remplacementCol = `remplace ${replacedMember.prenom} ${replacedMember.nom}`;
			}

			if(replacingMember) {
				remplacementCol = `remplacé par ${replacingMember.prenom} ${replacingMember.nom}`;
			}

			return {
				Nom: data.nom,
				Prenom: data.prenom,
				Id: data.commissionMemberId,
				Groupe: <span className="table__groupe">{data.groupe}</span>,
				Mail: data.email,
				Remplacement: remplacementCol,
				CommissionMemberReplacingId: data.commissionMemberReplacingId,
				Present: data.presence
			};
		});

		setData(array);
	}, [dataArray]);

	const columns = useMemo(() => {
		return [{
			Header: "Table",
			columns: [
				{ Header: "Nom", accessor: "Nom", sortType: "basic" },
				{ Header: "Prénom", accessor: "Prenom", sortType: "basic" },
				{ Header: "Mail", accessor: "Mail", sortType: "basic" },
				{ Header: "Groupe", accessor: "Groupe", sortType: "basic" },
				{ Header: "Présent", accessor: "Present", disableSortBy: true,
					Cell: (value: any) => (
						<MembreCheckboxTab
							idMembre={value.cell.row.values.Id}
							checked={value.cell.row.values.Present}
							index={index}
							tableId={tableId}
							onCheck={onCheck}
							disabled={value.cell.row.values.CommissionMemberReplacingId ? true : false}
						/>
					)
				},
				{ Header: "Remplacement", accessor: "Remplacement", sortType: "basic" },
				{ Header: "", accessor: "Actions", disableSortBy: true,
					Cell: (value: any) => (
						<span className="membreTable__button" onClick={() => openDeleteModal(tableId, dataArray.find(x => x.commissionMemberId === value.cell.row.values.Id) || null, dataArray)}>
							<i className="far fa-trash-alt"></i>
						</span>
				)},
				{ Header: "CommissionMemberReplacingId", accessor: "CommissionMemberReplacingId" },
				{ Header: "Id", accessor: "Id" }
			]
		}];
	}, [index, dataArray, tableId]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id", "CommissionMemberReplacingId"],
			}
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

	return (
		<div className="membreTable">
			<div className="membreTable__header">
				<h3>{title}</h3>
				<FormButton
					icon="far fa-plus"
					type="button"
					value="Ajouter un membre"
					onClick={() => { openAddModal(tableId, dataArray)}}
				/>
			</div>
			{data.length > 0 && (
				<Table
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					getRowProps={(row: any) => ({
						className: row.values.CommissionMemberReplacingId && 'table__row--highlight'
					})}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.page}
				/>
			)}
		</div>
	);
};

export default MembreTableTab;
