import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { modalLargeCustomStyles } from "../../../constants/config.constant";
import { DossierDetailsDto, StatutCommission } from '../../../services/generated/BackOffice-api';
import { getDossierNotation } from '../../../store/dossier-store/actions/dossierNoteActions';
import { useCommissionSelector, useDossierSelector } from '../../../store/store-helpers';
import { updateStructureNotePro } from '../../../store/structure-store/actions/structureNoteActions';

interface ModalAideDecisionProps extends RouteComponentProps {
	isOpen: boolean;
	onClose: () => void;
	detailsDossier?:DossierDetailsDto;
}

const ModalAideDecision: React.FunctionComponent<ModalAideDecisionProps> = ({
	isOpen,
	onClose,
	detailsDossier
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const dossierSelector = useDossierSelector();
	const commissionSelector = useCommissionSelector();
	const [totaux, setTotaux] = useState<number>();
	const [notePro, setNotePro] = useState<number>();

	useEffect(() => {
		(async () => {
			detailsDossier && detailsDossier.id && await getDossierNotation(dispatch, detailsDossier.id);
		})();
	}, [detailsDossier]);

	useEffect(() => {
		if (dossierSelector.dossierNotation) {
			const total = (dossierSelector.dossierNotation.noteAnciennete?.points || 0)
							+ (dossierSelector.dossierNotation.noteDossier?.points || 0)
							+ (dossierSelector.dossierNotation.noteEnregistrement?.points || 0)
							+ (dossierSelector.dossierNotation.noteProfessionnalisme?.points || 0)
							+ (dossierSelector.dossierNotation.noteStructure?.points || 0)
							+ (dossierSelector.dossierNotation.noteStylesMusicaux?.points || 0);

			setNotePro(dossierSelector.dossierNotation.noteProfessionnalisme?.points);
			setTotaux(total);
		}
	}, [dossierSelector.dossierNotation]);

	const addNotePoint = useCallback(() => {
		if (notePro) {
			if (notePro < 20) {
				setNotePro(notePro + 4);
			}
		} else {
			setNotePro(4);
		}
	}, [notePro]);

	const removeNotePoint = useCallback(() => {
		if (notePro && notePro > 0) {
			setNotePro(notePro - 4);
		}
	}, [notePro]);

	const submitNotePro = async () => {
		if(commissionSelector?.commission?.statut === StatutCommission.EnCours || commissionSelector?.commission?.statut === StatutCommission.CaEnCours) {
			if (notePro !== undefined) {
				try {
					detailsDossier?.structure?.id && await updateStructureNotePro(dispatch, detailsDossier.structure.id, {notePro: notePro});
					setNotePro(undefined);
					onClose();
				} catch(error) {
					console.error(error)
				}
			} else {
				onClose();
			}
		} else return;
	};

	const closing = () => {
		onClose();
		setNotePro(undefined);
	}


	return (
		<Modal
			isOpen={isOpen}
			style={modalLargeCustomStyles}
			className="normal__modal aideDecisionModal"
		>
			<div className="modal__header">
				<h3>Informations complémentaires et validations de l'aide</h3>
			</div>

			<div className="modal__content">
				<div className="aideDecisionModal__content">
					<h3>{detailsDossier?.nom}</h3>

					<div className="aideDecisionModal__section">
						<div className="aideDecisionModal__row">
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Type de dossier"
									content={detailsDossier?.categorie?.nom || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Numéro de dossier"
									content={detailsDossier?.numero || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Nom du président ou gérant"
									content={detailsDossier?.structure?.representantLegal?.nom + ' ' + detailsDossier?.structure?.representantLegal?.prenom || '-'}
								/>
							</div>
						</div>
						<div className="aideDecisionModal__row">
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Nom de la structure"
									content={detailsDossier?.structure?.raisonSociale || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Nom de dossier"
									content={detailsDossier?.nom || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Type de structure"
									content={detailsDossier?.structure?.formeJuridique?.nom || '-'}
								/>
							</div>
						</div>
					</div>

					<div className="aideDecisionModal__section">
						<div className="aideDecisionModal__row">
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Nombre d'artistes"
									content={`${detailsDossier?.artistes?.length}` || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Commentaire"
									content={detailsDossier?.commentaires ||'-'}
								/>
							</div>
							<div className="aideDecisionModal__item"></div>
						</div>
					</div>

					<div className="aideDecisionModal__section">
						<div className="aideDecisionModal__row">
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label={`Ancienneté${dossierSelector.dossierNotation?.noteAnciennete?.maxPoints ? ` (sur ${dossierSelector.dossierNotation?.noteAnciennete?.maxPoints})` : ''}`}
									content={dossierSelector.dossierNotation?.noteAnciennete?.points?.toString() || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label={`Note du dossier${dossierSelector.dossierNotation?.noteDossier?.maxPoints ? ` (sur ${dossierSelector.dossierNotation?.noteDossier?.maxPoints})` : ''}`}
									content={dossierSelector.dossierNotation?.noteDossier?.points?.toString() || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label={`Note de la structure${dossierSelector.dossierNotation?.noteStructure?.maxPoints ? ` (sur ${dossierSelector.dossierNotation?.noteStructure?.maxPoints})` : ''}`}
									content={dossierSelector.dossierNotation?.noteStructure?.points?.toString() || '-'}
								/>
							</div>
						</div>
						<div className="aideDecisionModal__row">
							<div className="aideDecisionModal__item">
								<div className="inputReadOnly light">
									<label>Note de professionnalisme de la structure (sur 20)</label>
									<p>
										<i className={clsx("far fa-minus-circle", {"isDisabled": !notePro })} onClick={removeNotePoint} />
										{ (notePro || notePro === 0 ) ? notePro : "-" }
										<i className={clsx("fas fa-plus-circle", {"isDisabled": notePro === 20})} onClick={addNotePoint} />
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="aideDecisionModal__section">
						<div className="aideDecisionModal__row">
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label="Totaux"
									content={totaux?.toString() || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label={`Résultat de la note administrative${dossierSelector.dossierNotation?.totalNoteAdministrativeSansCoeff?.maxPoints ? ` (sur ${dossierSelector.dossierNotation?.totalNoteAdministrativeSansCoeff?.maxPoints})` : ''}`}
									content={dossierSelector.dossierNotation?.totalNoteAdministrativeSansCoeff?.points?.toString() || '-'}
								/>
							</div>
							<div className="aideDecisionModal__item">
								<InputReadOnly
									label={`Résultat de la note administrative avec coef correcteur${dossierSelector.dossierNotation?.totalNoteAdministrative?.maxPoints ? ` (sur ${dossierSelector.dossierNotation?.totalNoteAdministrative?.maxPoints})` : ''}`}
									content={dossierSelector.dossierNotation?.totalNoteAdministrative?.points?.toString() || '-'}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal__footer">
				<FormButton
					type="button"
					value="Annuler"
					onClick={closing}
				/>
				{(commissionSelector?.commission?.statut === StatutCommission.EnCours || commissionSelector?.commission?.statut === StatutCommission.CaEnCours)
					&& <FormButton
						type="submit"
						value="Valider"
						onClick={submitNotePro}
					/>
				}
			</div>
		</Modal>
	);
}

export default ModalAideDecision;