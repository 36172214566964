import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState, FunctionComponent, useReducer } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from "@reach/router";
import { paginationNumbers } from "../../constants/config.constant";
import {
	blocageJuridiqueFilterKey,
	canVersementFilterKey,
	chargeInstructionNomFilterKey,
	chargeInstructionPrenomFilterKey,
	dateCommissionFilterKey,
	dateSoumissionMinFilterKey,
	dateSoumissionMaxFilterKey,
	dsvFilterKey,
	enCoursValidationDSVFilterKey,
	modifiableParStructureFilterKey,
	nomFilterKey,
	numeroFilterKey,
	responsableNomFilterKey,
	responsablePrenomFilterKey,
	statutFilterKey,
	structureFilterKey,
	typeFilterKey,
	typeVersementFilterKey,
	statutVersementFilterKey,
	valideParDSVFilterKey,
	villeFilterKey,
	alerteVerteFilterKey,
	alerteRougeFilterKey,
	ribValideFilterKey,
	ribModifiableFilterKey,
	representantNomFilterKey,
	representanPrenomFilterKey,
	blocageAvisFilterKey,
	artisteNomFilterKey,
	artistePrenomFilterKey,
	siretFilterKey,
	codeFilterKey

} from "../../constants/filters.constant";
import PagesContainer from "../../containers/PagesContainer";
import { fetchDossiers, getDossiersFilteredAsExcel } from '../../store/dossier-store/actions/dossierAction';
import { useDossierSelector, useUserSelector} from '../../store/store-helpers';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import NoItemsTable from '../basics/NoItemsTable';
import DossierFilters from "./dossiers-list/DossierFilters";
import DossierFiltersModal from "./dossiers-list/DossierFiltersModal";
import { toast } from 'react-toastify';
import DossiersTable from './DossiersTable';
import { capitalizeFirstLetter } from 'adel-shared/dist/utils/functions';
import DossierVues from './vues/DossierVues';
import { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';

export interface IDossierFilters {
	[numeroFilterKey]?: string;
	[nomFilterKey]?: string;
	[siretFilterKey]?: string;
	[structureFilterKey]?: string;
	[statutFilterKey]?: string[];
	[typeFilterKey]?: string[];
	[dateCommissionFilterKey]?: string[];
	[villeFilterKey]?: string;
	[chargeInstructionNomFilterKey]?: string,
	[chargeInstructionPrenomFilterKey]?: string,
	[modifiableParStructureFilterKey]?: string,
	[responsableNomFilterKey]?: string;
	[responsablePrenomFilterKey]?: string;
	[dsvFilterKey]?: boolean | string | undefined;
	[blocageJuridiqueFilterKey]?: boolean | string | undefined;
	[dateSoumissionMinFilterKey]?: string | undefined;
	[dateSoumissionMaxFilterKey]?: string | undefined;
	[canVersementFilterKey]?: boolean | string | undefined;
	[typeVersementFilterKey]?: string[] |undefined;
	[statutVersementFilterKey]?: string[] |undefined;
	[enCoursValidationDSVFilterKey]?: boolean | string | undefined;
	[valideParDSVFilterKey]?: boolean | string | undefined;
	[alerteVerteFilterKey]?: boolean | string | undefined;
	[alerteRougeFilterKey]?: boolean | string | undefined;
	[ribValideFilterKey]?: boolean | string;
	[ribModifiableFilterKey]?: boolean | string | undefined;
	[blocageAvisFilterKey]?: boolean | string | undefined;
	[representantNomFilterKey]?: string;
	[representanPrenomFilterKey]?: string;
	[artisteNomFilterKey]?: string;
	[artistePrenomFilterKey]?: string;
	[codeFilterKey]?: string;
}

export interface FiltersAction {
	type: string;
	payload?: IDossierFilters;
}

export function getSerializedFilters(filters: IDossierFilters) {
	return Object.entries(filters)
		.filter(([,value]) => value !== null && value !== undefined && value !== '')
		.map(([key, value]) => {
			if(Array.isArray(value)) {
				return `${key}${value.join('|')}`;
			}
			return `${key}${value}`;
		}).join();
}



function filtersReducer(state: IDossierFilters, action: FiltersAction) {
	switch(action.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'reset':
			return action.payload;
		default:
			return state;
	}
}

const DossiersListe: FunctionComponent<RouteComponentProps> = () => {
	const { isConnected: isUserConnected } = useUserSelector();
	const [sort, setSort] = useState<string>("");

	/** useStates for Pagination  */
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);

	/** selectors & dispatch */
	const {isLoading, listeDossiers, dossierFilter} = useDossierSelector();
	
	/** Filtres vars */
	//@ts-ignore
	const [filters, setFilters] = useReducer(filtersReducer, dossierFilter);

    useEffect(()=> {
    if (dossierFilter) {
		//@ts-ignore
		setFilters(dossierFilter)
	}
	},[])

	const dispatch = useDispatch();

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchDossiers = useMemo(() => _.debounce((
		filters: IDossierFilters,
		page: number,
		pageSize: number,
		sort: string
	) => {
		fetchDossiers(
			dispatch,
			false,
			getSerializedFilters(filters),
			page,
			pageSize,
			sort
		);
	}, 1000), []);

	/** Get the dossiers list */
	useEffect(() => {
		if (isUserConnected) {
			debounceFetchDossiers(filters, page, pageSize, sort)
		}
	}, [
		filters,
		page,
		pageSize,
		sort,
		isUserConnected
	]);


	/** Modal Filters */
	const [modalFiltersIsOpen, setModalFiltersIsOpen] = useState<boolean>(false);

	const handleAdvancedFiltersClick = useCallback(() => {
		setModalFiltersIsOpen(true);
	}, [setModalFiltersIsOpen]);

	const handleModalFiltersClose = useCallback(() => {
		setModalFiltersIsOpen(false);
	}, [setModalFiltersIsOpen])
	
	const handleModalFiltersSubmit = (values: any) => {
		const updatedAdvancedFilters = {
			[villeFilterKey]: values.ville,
			[chargeInstructionNomFilterKey]: values.chargeInstructionNom,
			[chargeInstructionPrenomFilterKey]: values.chargeInstructionPrenom,
			[modifiableParStructureFilterKey]: values.modifiableParStructure,
			[responsableNomFilterKey]: values.responsableNom,
			[responsablePrenomFilterKey]: values.responsablePrenom,
			[dsvFilterKey]: values.dsv,
			[blocageJuridiqueFilterKey]: values.blocageJuridique,
			[dateSoumissionMinFilterKey]: values.dateSoumissionMin && moment(values.dateSoumissionMin).format(),
			[dateSoumissionMaxFilterKey]: values.dateSoumissionMax && moment(values.dateSoumissionMax).format(),
			[canVersementFilterKey]: values.canVersement,
			[typeVersementFilterKey]: values.typeVersement?.map((v:AdelOption<string>) => capitalizeFirstLetter(v.value)),
			[statutVersementFilterKey]: values.statutVersement?.map((v:AdelOption<string>) => capitalizeFirstLetter(v.value)),
			[enCoursValidationDSVFilterKey]: values.enCoursValidationDSV,
			[valideParDSVFilterKey]: values.valideParDSV,
			[alerteVerteFilterKey]: values.alerteVerte,
			[alerteRougeFilterKey]: values.alerteRouge,
			[ribValideFilterKey]: values.ribValide,
			[blocageAvisFilterKey]: values.blocageAvis,
			[representantNomFilterKey]: values.representantLegalNom,
			[representanPrenomFilterKey]: values.representantLegalPrenom,
			[artisteNomFilterKey]: values.artisteNom,
			[artistePrenomFilterKey]: values.artistePrenom,
		};
		handleModalFiltersClose();
		//@ts-ignore
		setFilters({
			type: 'update',
			payload: updatedAdvancedFilters
		});
		fetchDossiers(
			dispatch,
			false,
			getSerializedFilters({...filters, ...updatedAdvancedFilters}),
			page,
			pageSize,
			sort,
			values.artisteNom,
			values.artistePrenom
		);
	};

	const handleDossierExport = async() => {
		try {
			await getDossiersFilteredAsExcel(getSerializedFilters({...filters}), sort);
		} catch {
			toast.error('Un erreur est survenue pendant le téléchargement de l\'export des dossiers.');
		}
	};

	return (
		<PagesContainer title="Gestion - Dossiers">
			<div className="dossierTable">
                <label className="filtres__label">Recherches avancées :</label>
				<div className="filtres">
					<div className="filtres__content">
						<DossierFilters
							hideLabel
							filters={filters}
							setFilters={setFilters}
						/>

						<button className="filtres__button" onClick={handleAdvancedFiltersClick}>
							<i className="far fa-plus"></i>
							Option avancées
						</button>
					</div>
					<DossierVues
						setFilters={setFilters}
						filters={filters}
						isLoading={isLoading}
					/>
				</div>

				{isLoading && (
					<div className="loaderBlock">
						<Loader
							type="TailSpin"
							width={35}
							height={35}
							color="#d93943"
						/>
					</div>
				)}
				{listeDossiers?.items && listeDossiers.items.length > 0 ? (
					<>
						<DossiersTable
							listeDossiers={listeDossiers}
							setSort={setSort}
							filters={filters}
							setPage={setPage}
							setPageSize={setPageSize}
							pageSize={pageSize}
							page={page}
							paginationNumbers={paginationNumbers}
						/>
						<FormButton
							className="button--dossiersExport"
							type="button"
							onClick={handleDossierExport}
							value="Exporter les dossiers"
						/>
					</>
				) : (
					<NoItemsTable />
				)}

				<DossierFiltersModal
					isOpen={modalFiltersIsOpen}
					onClose={handleModalFiltersClose}
					onSubmit={handleModalFiltersSubmit}
					filters={filters}
					setFilters={setFilters}
				/>
			</div>
		</PagesContainer>
	);
}

export default DossiersListe;