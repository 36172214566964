import { Reducer } from "redux";
import { CategorieFormeJuridiqueState, initialCategorieFormeJuridique } from "../states/categorieFormeJuridiqueState";
import { DispatchCategorieFormeJuridiqueAction, CategorieFormeJuridiquerActionsEnum } from "../actions/categoryFormeJuridiqueAction";

export const categoriesFormeJuridiqueReducer: Reducer<Partial<CategorieFormeJuridiqueState>, DispatchCategorieFormeJuridiqueAction> = (state = initialCategorieFormeJuridique, action) => {
    switch (action.type) {
        case CategorieFormeJuridiquerActionsEnum.GET_CATEGORIES_REQUEST:
			return { ...state,
				categoriesIsLoading: true
			};
		case CategorieFormeJuridiquerActionsEnum.GET_CATEGORIES_SUCCESS:
			return {
				...state,
				categoriesFormeJuridique: action.payload.categoriesFormeJuridique,
				categoriesIsLoading: false
			};
		case CategorieFormeJuridiquerActionsEnum.SEND_CATEGORIES_REQUEST:
			return { ...state,
				categoriesIsLoading: true
			};
		case CategorieFormeJuridiquerActionsEnum.SEND_CATEGORIES_SUCCESS:
			return {
				...state,
				formesJuridiques: action.payload.formesJuridiques,
				categoriesIsLoading: false
			};
		case CategorieFormeJuridiquerActionsEnum.ADD_CATEGORIE_REQUEST:
			return { ...state,
				categoriesIsLoading: true
			};
		case CategorieFormeJuridiquerActionsEnum.ADD_CATEGORIE_SUCCESS:
			return {
				...state,
				newCategorie: action.payload,
				categoriesIsLoading: false
			};
		case CategorieFormeJuridiquerActionsEnum.DELETE_CATEGORIE_REQUEST:
			return { ...state,
				categoriesIsLoading: true
			};
		case CategorieFormeJuridiquerActionsEnum.DELETE_CATEGORIE_SUCCESS:
			return {
				...state,
				categoriesIsLoading: false
			};
		case CategorieFormeJuridiquerActionsEnum.UPDATE_CATEGORIES_TYPESDEDOCUMENTS_REQUEST:
			return {
				...state,
				categoryDocumentsAssociationLoading: true
			};
		case CategorieFormeJuridiquerActionsEnum.UPDATE_CATEGORIES_TYPESDEDOCUMENTS_SUCCESS:
		case CategorieFormeJuridiquerActionsEnum.UPDATE_CATEGORIES_TYPESDEDOCUMENTS_ERROR:
			return {
				...state,
				categoryDocumentsAssociationLoading: false
			};
        default:
            return state;
    }
};
