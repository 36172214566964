import React from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useDossierSelector } from '../../../store/store-helpers';


const DossiersBloques: React.FunctionComponent = () => {
    const { dossierAdditionalInfo = {} } = useDossierSelector();

    return (
        <div className="sideInfo">
            <div className="sideInfo__header">
                <h3>Dossiers bloqués</h3>
            </div>

            <ul>
                {dossierAdditionalInfo.autreDossiersBloques?.map(item => (
                    <li key={item.id}>
                        {item.nom}

                        <div className="sideInfo__item">
                            <InputReadOnly
                                label="Numéro de dossier"
                                content={item.numero || '-'}
                                theme="light"
                            />

                            <InputReadOnly
                                label="Type de dossier"
                                content={item.categorieNom || '-'}
                                theme="light"
                            />
                        </div>
                    </li>
                ))}
			</ul>
        </div>
    );
}

export default DossiersBloques;