import React, { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { formatNumber } from '../../../utils/functions';
import Table, { styleTable } from '../../basics/Table';
const ArtisteModalTable = ({ data, montantTotal }) => {
    const columns = useMemo(() => {
        return [
            {
                Header: "Table",
                Footer: "Table",
                columns: [
                    {
                        Header: "Montant cachet unitaire",
                        Footer: () => {
                            const totalMontant = React.useMemo(() => data.reduce((acc, current) => acc + (current.montantBrut * current.count), 0), [data]);
                            return React.createElement(React.Fragment, null,
                                "Total montant : ",
                                formatNumber(totalMontant),
                                " \u20AC");
                        },
                        Cell: (value) => React.createElement("span", null,
                            formatNumber(value.value),
                            " \u20AC"),
                        accessor: "montantBrut",
                        disableSortBy: true
                    },
                    {
                        Header: "Quantité",
                        Footer: (value) => {
                            const totalQuantite = React.useMemo(() => value.rows.reduce((sum, row) => row.values.count + sum, 0), [value.rows]);
                            return React.createElement(React.Fragment, null,
                                "Total quantit\u00E9 : ",
                                formatNumber(totalQuantite));
                        },
                        accessor: "count",
                        disableSortBy: true
                    },
                    {
                        Header: "Taux de charges patronales",
                        Footer: () => {
                            return React.createElement(React.Fragment, null,
                                "Montant total des charges patronales : ",
                                formatNumber(montantTotal),
                                " \u20AC");
                        },
                        Cell: (value) => {
                            return React.createElement(React.Fragment, null, Math.round(value.value * 10000) / 100);
                        },
                        accessor: "tauxChargesPatronales",
                        disableSortBy: true
                    }
                ]
            }
        ];
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow, state } = useTable({
        columns,
        data,
        initialState: {},
        manualSortBy: true
    }, useSortBy);
    return (React.createElement(Table, { data: data, getTableProps: getTableProps, getTableBodyProps: getTableBodyProps, headerGroups: headerGroups, footerGroups: footerGroups, rows: rows, prepareRow: prepareRow, styles: styleTable.page }));
};
export default ArtisteModalTable;
