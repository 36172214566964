import React, { FunctionComponent, useState } from "react";
import { RouteComponentProps } from '@reach/router';
import FormButton from "adel-shared/dist/components/basics/FormButton";
import { ComptabiliteClient } from "../services/generated/BackOffice-api";
import PagesContainer from "../containers/PagesContainer";
import InputCalendar from "adel-shared/dist/components/basics/InputCalendar";
import { normalizeDate } from "adel-shared/dist/utils/functions";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { downloadFileFromDataURI } from "../utils/functions";
import { axiosInstance } from "../custom-hooks/useAxios";

interface BalanceProps extends RouteComponentProps {
}

const Balance: FunctionComponent<BalanceProps> = () => {
    const { t } = useTranslation();
    const comptabiliteClient = new ComptabiliteClient('', axiosInstance);
    const [dateDossiersNonSolde, setDateDossiersNonSolde] = useState<Date>(new Date());

    const handleExportDossiersNonSoldeClick = async() => {
        try {
            const result = await comptabiliteClient.getDossierNonSoldeAsExcel(normalizeDate(dateDossiersNonSolde));
            downloadFileFromDataURI(result);
        } catch(error) {
			if(error.response?.exception?.message)
				toast.error(error.response.exception.message);
			else if(error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error("Impossible de récupérer l'Excel des dossiers non soldés");
        }
    };

    const handleDateDossiersNonSoldeChange = (date: Date) => {
        setDateDossiersNonSolde(date);
    };

    return (
        <PagesContainer
            title="Comptabilité - Balance à date"
            pageClass="comptabilite"
        >
            <Grid
                className="exportDossierNonSolde"
                container
                alignItems="flex-end"
                spacing={3}
            >
                <Grid item>
                    <div className="input">
                        <label>Sélectionner une date :</label>
                        <InputCalendar
                            className="inputFile__calendar--basic"
                            onDateSelected={handleDateDossiersNonSoldeChange}
                            defaultDate={dateDossiersNonSolde}
                            unlock
                        />
                    </div>
                </Grid>
                <Grid item>
                    <FormButton
                        type="button"
                        value="Exporter"
                        onClick={handleExportDossiersNonSoldeClick}
                    />
                </Grid>
            </Grid>
        </PagesContainer>
    );
}

export default Balance;
