import { filtersDefault, structureFilterDefault } from '../../../constants/filters.constant';
import { StructureDto, StructureDetailsDto, StructureInstructionDto, DocumentDto, RelationDto, DiscussionDto, DiscussionMessageDto, UpdateStructureNoteDto, ObjetDiscussion, StructureDocumentsDto } from '../../../services/generated/BackOffice-api';

export interface StructureState {
	structures?: StructureDto[];
	structureDetails?: StructureDetailsDto;
	structureInstruction?: StructureInstructionDto;
	structureInstructionFiles?: File[];
	structureNote?: UpdateStructureNoteDto;
	structureDiscussions?: DiscussionDto[];
	structureDiscussionsLu?: string[];
	structureDiscussionId?: string;
	structureDiscussion?: DiscussionDto;
	structureMessage?: DiscussionMessageDto;
	structureHistory?: any;
	isLoading?: boolean;
	nomList?: string[];
	emailList?: string[];
	responsableList?: string[];
	isRelationsLoading?: boolean;
	structureRelations?: RelationDto[];
	isDocValLoading?: boolean;
	objectDiscussionStructure?: ObjetDiscussion[];
	structureDocuments?: StructureDocumentsDto | StructureInstructionDto;
	structureDocumentsFiles?: File[];
	structureFilter?: any;
}

export const initialStructureState: StructureState = {
	isLoading: false,
	structures: [],
	structureDetails: {},
	structureInstruction: {},
	structureInstructionFiles: [],
	structureDocumentsFiles: [],
	structureDocuments: {},
	structureNote: {},
	structureDiscussions: [],
	structureDiscussionsLu: [],
	structureDiscussionId: '',
	structureDiscussion: {},
	structureMessage: {},
	structureHistory: [],
	nomList: [],
	emailList: [],
	responsableList: [],
	isRelationsLoading: false,
	structureRelations: [],
	isDocValLoading: false,
	objectDiscussionStructure: [],
	structureFilter: structureFilterDefault
};
