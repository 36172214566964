import React from 'react'
import { CategorieDossierDto } from '../../../services/generated/BackOffice-api';
interface leftContainerProps {
    categorie: CategorieDossierDto | undefined;
    color: string;
    imageName?: string;
}

// interface Icon {
//     imageName: string,
//     color: string
// }

const SyntheseIcon = ({categorie,color,imageName}: leftContainerProps) => {

    let bg = categorie?.code && imageName&& require('../../../assets/pictos/' + imageName + '.png');
    return (
        (bg) ?
        <div className='picto_categorie'>
            <div className='synthese_icon'
                style={{
                    background: `url(${bg}) no-repeat center`,
                    width: "80px",
                    height: '80px',
                    fill: '#FFF',
                    strokeWidth: '6px',
                    stroke:'#9ECBC5',
		            marginTop: "30px"
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <circle cx="40" cy="40" r="37" fill="none" stroke={color ?? "#FFF"} strokeWidth="6" />
            </svg>
        </div><div className='cat_label'>
                {categorie?.nomPicto}
            </div>
        </div>
        :<div></div>
    )
}

export default SyntheseIcon