import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiException, DocumentUploadInfoDto, StatutDossier } from "../services/generated/BackOffice-api";
import { Dictionary } from "adel-shared/dist/models/Dictionary";

export interface UpdateDossierInstructionDto {
    statut?: StatutDossier;
    chargeInstructionId?: string;
    commissionId?: string;
    dsv?: boolean;
    blocageJuridique?: boolean;
    blocageAvis?: boolean;
    motifBlocageJuridique?: string;
    ribModifiableParStructure?: boolean;
    modifiableParStructure?: boolean;
    commentairesInstruction?: string;
    documents: DocumentUploadInfoDto[];
    subCategorieId?: string;
}
export interface UpdateDossierDetailsDto {
    dateProlongation?: Date | string;
    commentaires?: string;
    documents?: DocumentUploadInfoDto[];
}

export class AdelDossierClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    /**
     * @param body (optional)
     * @return Success
     */
    updateDossierInstruction(dossierId: string | null, body: UpdateDossierInstructionDto | undefined, files: Dictionary<File>): Promise<void> {
        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/instruction";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

		return this.instance.put(url_, formData, options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdateDossierInstruction(_response);
        });
    }

    protected processUpdateDossierInstruction(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 204) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }

    /**
     * @param body (optional) 
     * @return Success
     */
    updateDossierDetails(dossierId: string | null, body: UpdateDossierDetailsDto | undefined, files?: Dictionary<File>): Promise<void> {
        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        if(files) 
            appendFileDictionary(formData, files);

        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/details";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

		return this.instance.put(url_, formData, options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdateDossierDetails(_response);
        });
    }

    protected processUpdateDossierDetails(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 204) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }
}

const appendFileDictionary = (formData: FormData, files: Dictionary<File>) => {
    for (let key in files) {
        if (files.hasOwnProperty(key)) {
            formData.append(key, files[key]);
        }
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}
