import React from 'react';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { useStructureSelector } from '../../../store/store-helpers';

interface AdresseSiegeProps {
}

const AdresseSiege: React.FC<AdresseSiegeProps>  = ({ }) => {
	const {structureDetails={}} = useStructureSelector();

	return (<>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Adresse"
						content={structureDetails.adresse?.line1 || '-'}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Code postal"
						content={structureDetails.adresse?.codePostal || '-'}
					/>
				</div>
			</div>
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Ville"
						content={structureDetails.adresse?.ville || '-'}
					/>
				</div>
			</div>
		</div>
		<div className="detailsTab__row">
			<div className="detailsTab__column">
				<div className="detailsTab__content">
					<InputReadOnly
						label="Complément d'adresse"
						content={structureDetails.adresse?.line2 || '-'}
					/>
				</div>
			</div>
		</div>
	</>);
}

export default AdresseSiege;
