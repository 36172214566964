import { calculateMod } from './../utils/functions';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
const euroPrefix = [
    'AT',
    'BE',
    'CY',
    'DE',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PT',
    'SI',
    'SK'
];
export const bicRgx = new RegExp('^[a-z0-9]{4}[a-z]{2}[a-z0-9]{2}([a-z0-9]{3})?$', 'i');
export const notNullValidate = (value, message) => {
    if (value === undefined || value === null)
        return message;
};
export const emailValidate = (value, message) => {
    const index = value.indexOf('@');
    if (index > 0 && index !== value.length - 1 && index === value.lastIndexOf('@')) {
        return;
    }
    return message;
};
export const siretValidate = (value, message) => {
    const siret = value.trim();
    if (siret.length === 0 || !siret)
        return;
    if (siret.length !== 14)
        return message;
    if (!siret.split('').every(x => !isNaN(parseInt(x, 10))))
        return message;
};
export const ibanValidate = (iban, messageCountry, message) => {
    const prefix = iban.substring(0, 2);
    if (!euroPrefix.includes(prefix)) {
        return messageCountry;
    }
    let trimIban = Array.from(iban.replace(/[- ]/g, ''));
    const fourcharacters = trimIban.splice(0, 4);
    trimIban.push(...fourcharacters);
    const converted = Array.from(trimIban).map(x => isNaN(parseInt(x, 10)) ? x.charCodeAt(0) - 55 : x).join('');
    if (calculateMod(converted, 97) === 1)
        return;
    return message;
};
const parsePhoneNumberType = (value) => {
    switch (value === null || value === void 0 ? void 0 : value.toUpperCase()) {
        case "FIXED_LINE": return PhoneNumberType.FIXED_LINE;
        case "MOBILE": return PhoneNumberType.MOBILE;
        case "FIXED_LINE_OR_MOBILE": return PhoneNumberType.FIXED_LINE_OR_MOBILE;
        case "TOLL_FREE": return PhoneNumberType.TOLL_FREE;
        case "PREMIUM_RATE": return PhoneNumberType.PREMIUM_RATE;
        case "SHARED_COST": return PhoneNumberType.SHARED_COST;
        case "VOIP": return PhoneNumberType.VOIP;
        case "PERSONAL_NUMBER": return PhoneNumberType.PERSONAL_NUMBER;
        case "PAGER": return PhoneNumberType.PAGER;
        case "UAN": return PhoneNumberType.UAN;
        case "VOICEMAIL": return PhoneNumberType.VOICEMAIL;
        case "UNKNOWN": return PhoneNumberType.UNKNOWN;
        default: return PhoneNumberType.UNKNOWN;
    }
};
export const phoneValidate = (value, allowedTypes, messageNotPhone, message) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        const number = phoneUtil.parseAndKeepRawInput(value, 'FR');
        const valid = phoneUtil.isPossibleNumber(number);
        if (valid) {
            if (allowedTypes) {
                const numberType = phoneUtil.getNumberType(number);
                const allowedPhoneNumberTypes = allowedTypes.map(parsePhoneNumberType);
                if (!allowedPhoneNumberTypes.includes(numberType)) {
                    return message;
                }
            }
            return;
        }
        else {
            return message;
        }
    }
    catch (error) {
        return messageNotPhone;
    }
};
export const requiredUniqueCharValidate = (value, validator, messageError) => {
    const array = Array.from(value);
    const distinctChar = [...new Set(array)];
    if (distinctChar.length > validator) {
        return;
    }
    return messageError;
};
